import { TCountry } from '../storageSlice';

export const countries: { [countryCode: string]: TCountry } = {
  AD: {
    code3: 'AND',
    code: 'AD',
    region: 'Europe',
    borders: ['FR', 'ES'],
    subregion: 'Southern Europe',
    codeN: '020',
    capital: ['Andorra la Vella'],
    inCountryList: ['UN'],
    name: 'Andorra',
    languages: {
      cat: 'Catalan',
    },
    flag: '🇦🇩',
    callingCode: ['376'],
    currency: ['EUR'],
    latlng: [42.5, 1.5],
    paths: [],
  },
  AE: {
    subregion: 'Western Asia',
    latlng: [24, 54],
    codeN: '784',
    borders: ['OM', 'SA'],
    inCountryList: ['UN'],
    code: 'AE',
    currency: ['AED'],
    languages: {
      ara: 'Arabic',
    },
    capital: ['Abu Dhabi'],
    code3: 'ARE',
    name: 'United Arab Emirates',
    region: 'Asia',
    callingCode: ['971'],
    flag: '🇦🇪',
    paths: [],
  },
  AF: {
    flag: '🇦🇫',
    capital: ['Kabul'],
    currency: ['AFN'],
    region: 'Asia',
    languages: {
      prs: 'Dari',
      tuk: 'Turkmen',
      pus: 'Pashto',
    },
    code3: 'AFG',
    latlng: [33, 65],
    subregion: 'Southern Asia',
    code: 'AF',
    callingCode: ['93'],
    codeN: '004',
    inCountryList: ['UN'],
    borders: ['IR', 'PK', 'TM', 'UZ', 'TJ', 'CN'],
    name: 'Afghanistan',
    paths: [],
  },
  AG: {
    code3: 'ATG',
    region: 'Americas',
    currency: ['XCD'],
    borders: [],
    latlng: [17.05, -61.8],
    subregion: 'Caribbean',
    inCountryList: ['UN'],
    callingCode: ['1268'],
    capital: ["Saint John's"],
    codeN: '028',
    flag: '🇦🇬',
    name: 'Antigua and Barbuda',
    code: 'AG',
    languages: {
      eng: 'English',
    },
    paths: [],
  },
  AI: {
    name: 'Anguilla',
    flag: '🇦🇮',
    code: 'AI',
    region: 'Americas',
    paths: [],
  },
  AL: {
    borders: ['ME', 'GR', 'MK'],
    subregion: 'Southern Europe',
    languages: {
      sqi: 'Albanian',
    },
    name: 'Albania',
    flag: '🇦🇱',
    region: 'Europe',
    latlng: [41, 20],
    codeN: '008',
    code: 'AL',
    callingCode: ['355'],
    code3: 'ALB',
    capital: ['Tirana'],
    currency: ['ALL'],
    inCountryList: ['UN'],
    paths: [],
  },
  AM: {
    code3: 'ARM',
    borders: ['AZ', 'GE', 'IR', 'TR'],
    capital: ['Yerevan'],
    currency: ['AMD'],
    callingCode: ['374'],
    codeN: '051',
    latlng: [40, 45],
    languages: {
      hye: 'Armenian',
    },
    flag: '🇦🇲',
    code: 'AM',
    subregion: 'Western Asia',
    inCountryList: ['UN'],
    region: 'Asia',
    name: 'Armenia',
    paths: [],
  },
  AO: {
    flag: '🇦🇴',
    capital: ['Luanda'],
    code: 'AO',
    subregion: 'Middle Africa',
    inCountryList: ['UN'],
    languages: {
      por: 'Portuguese',
    },
    currency: ['AOA'],
    borders: ['CG', 'CD', 'ZM', 'NA'],
    region: 'Africa',
    latlng: [-12.5, 18.5],
    codeN: '024',
    name: 'Angola',
    code3: 'AGO',
    callingCode: ['244'],
    paths: [],
  },
  AQ: {
    codeN: '010',
    subregion: '',
    capital: [''],
    flag: '🇦🇶',
    latlng: [-90, 0],
    callingCode: [],
    languages: {},
    name: 'Antarctica',
    code: 'AQ',
    borders: [],
    region: 'Antarctic',
    currency: [],
    inCountryList: ['UN'],
    code3: 'ATA',
    paths: [],
  },
  AR: {
    callingCode: ['54'],
    borders: ['BO', 'BR', 'CL', 'PY', 'UY'],
    subregion: 'South America',
    latlng: [-34, -64],
    code: 'AR',
    languages: {
      spa: 'Spanish',
      grn: 'Guaraní',
    },
    capital: ['Buenos Aires'],
    inCountryList: ['UN'],
    name: 'Argentina',
    region: 'Americas',
    currency: ['ARS'],
    code3: 'ARG',
    codeN: '032',
    flag: '🇦🇷',
    paths: [],
  },
  AT: {
    latlng: [47.33333333, 13.33333333],
    currency: ['EUR'],
    code: 'AT',
    capital: ['Vienna'],
    borders: ['CZ', 'DE', 'HU', 'IT', 'LI', 'SK', 'SI', 'CH'],
    codeN: '040',
    code3: 'AUT',
    inCountryList: ['UN'],
    region: 'Europe',
    subregion: 'Western Europe',
    languages: {
      bar: 'Austro-Bavarian German',
    },
    flag: '🇦🇹',
    name: 'Austria',
    callingCode: ['43'],
    paths: [],
  },
  AU: {
    codeN: '036',
    inCountryList: ['UN'],
    region: 'Oceania',
    code3: 'AUS',
    currency: ['AUD'],
    callingCode: ['61'],
    name: 'Australia',
    code: 'AU',
    latlng: [-27, 133],
    capital: ['Canberra'],
    languages: {
      eng: 'English',
    },
    borders: [],
    flag: '🇦🇺',
    subregion: 'Australia and New Zealand',
    paths: [],
  },
  AW: {
    region: 'Americas',
    flag: '🇦🇼',
    name: 'Aruba',
    code: 'AW',
    paths: [],
  },
  AX: {
    code: 'AX',
    name: 'Aland Islands',
    flag: '🇦🇽',
    region: 'Europe',
    paths: [],
  },
  AZ: {
    callingCode: ['994'],
    codeN: '031',
    capital: ['Baku'],
    name: 'Azerbaijan',
    latlng: [40.5, 47.5],
    languages: {
      aze: 'Azerbaijani',
      rus: 'Russian',
    },
    currency: ['AZN'],
    flag: '🇦🇿',
    subregion: 'Western Asia',
    region: 'Asia',
    borders: ['AM', 'GE', 'IR', 'RU', 'TR'],
    code3: 'AZE',
    inCountryList: ['UN'],
    code: 'AZ',
    paths: [],
  },
  BA: {
    name: 'Bosnia and Herzegovina',
    languages: {
      srp: 'Serbian',
      hrv: 'Croatian',
      bos: 'Bosnian',
    },
    latlng: [44, 18],
    subregion: 'Southern Europe',
    borders: ['HR', 'ME', 'RS'],
    capital: ['Sarajevo'],
    flag: '🇧🇦',
    code: 'BA',
    code3: 'BIH',
    region: 'Europe',
    callingCode: ['387'],
    codeN: '070',
    inCountryList: ['UN'],
    currency: ['BAM'],
    paths: [],
  },
  BB: {
    latlng: [13.16666666, -59.53333333],
    code: 'BB',
    inCountryList: ['UN'],
    region: 'Americas',
    borders: [],
    flag: '🇧🇧',
    capital: ['Bridgetown'],
    callingCode: ['1246'],
    languages: {
      eng: 'English',
    },
    currency: ['BBD'],
    name: 'Barbados',
    code3: 'BRB',
    subregion: 'Caribbean',
    codeN: '052',
    paths: [],
  },
  BD: {
    latlng: [24, 90],
    codeN: '050',
    borders: ['MM', 'IN'],
    languages: {
      ben: 'Bengali',
    },
    code: 'BD',
    region: 'Asia',
    code3: 'BGD',
    flag: '🇧🇩',
    inCountryList: ['UN'],
    callingCode: ['880'],
    name: 'Bangladesh',
    subregion: 'Southern Asia',
    capital: ['Dhaka'],
    currency: ['BDT'],
    paths: [],
  },
  BE: {
    name: 'Belgium',
    code: 'BE',
    callingCode: ['32'],
    borders: ['FR', 'DE', 'LU', 'NL'],
    codeN: '056',
    latlng: [50.83333333, 4],
    code3: 'BEL',
    flag: '🇧🇪',
    inCountryList: ['UN'],
    subregion: 'Western Europe',
    region: 'Europe',
    capital: ['Brussels'],
    currency: ['EUR'],
    languages: {
      nld: 'Dutch',
      fra: 'French',
      deu: 'German',
    },
    paths: [],
  },
  BF: {
    currency: ['XOF'],
    borders: ['BJ', 'CI', 'GH', 'ML', 'NE', 'TG'],
    code: 'BF',
    latlng: [13, -2],
    code3: 'BFA',
    capital: ['Ouagadougou'],
    subregion: 'Western Africa',
    inCountryList: ['UN'],
    region: 'Africa',
    callingCode: ['226'],
    codeN: '854',
    languages: {
      fra: 'French',
    },
    flag: '🇧🇫',
    name: 'Burkina Faso',
    paths: [],
  },
  BG: {
    languages: {
      bul: 'Bulgarian',
    },
    name: 'Bulgaria',
    subregion: 'Eastern Europe',
    code: 'BG',
    borders: ['GR', 'MK', 'RO', 'RS', 'TR'],
    code3: 'BGR',
    inCountryList: ['UN'],
    codeN: '100',
    callingCode: ['359'],
    flag: '🇧🇬',
    latlng: [43, 25],
    capital: ['Sofia'],
    currency: ['BGN'],
    region: 'Europe',
    paths: [],
  },
  BH: {
    languages: {
      ara: 'Arabic',
    },
    latlng: [26, 50.55],
    code: 'BH',
    name: 'Bahrain',
    inCountryList: ['UN'],
    code3: 'BHR',
    callingCode: ['973'],
    flag: '🇧🇭',
    borders: [],
    currency: ['BHD'],
    subregion: 'Western Asia',
    capital: ['Manama'],
    codeN: '048',
    region: 'Asia',
    paths: [],
  },
  BI: {
    region: 'Africa',
    codeN: '108',
    subregion: 'Eastern Africa',
    latlng: [-3.5, 30],
    capital: ['Bujumbura'],
    currency: ['BIF'],
    inCountryList: ['UN'],
    code3: 'BDI',
    code: 'BI',
    callingCode: ['257'],
    languages: {
      fra: 'French',
      run: 'Kirundi',
    },
    flag: '🇧🇮',
    borders: ['CD', 'RW', 'TZ'],
    name: 'Burundi',
    paths: [],
  },
  BJ: {
    latlng: [9.5, 2.25],
    capital: ['Porto-Novo'],
    flag: '🇧🇯',
    callingCode: ['229'],
    region: 'Africa',
    name: 'Benin',
    languages: {
      fra: 'French',
    },
    inCountryList: ['UN'],
    code3: 'BEN',
    subregion: 'Western Africa',
    codeN: '204',
    code: 'BJ',
    borders: ['BF', 'NE', 'NG', 'TG'],
    currency: ['XOF'],
    paths: [],
  },
  BL: {
    name: 'Saint Barthélemy',
    code: 'BL',
    region: 'Americas',
    flag: '🇧🇱',
    paths: [],
  },
  BN: {
    name: 'Brunei',
    region: 'Asia',
    languages: {
      msa: 'Malay',
    },
    borders: ['MY'],
    inCountryList: ['UN'],
    code: 'BN',
    currency: ['BND'],
    flag: '🇧🇳',
    capital: ['Bandar Seri Begawan'],
    callingCode: ['673'],
    latlng: [4.5, 114.66666666],
    codeN: '096',
    code3: 'BRN',
    subregion: 'South-Eastern Asia',
    paths: [],
  },
  BO: {
    subregion: 'South America',
    code: 'BO',
    codeN: '068',
    latlng: [-17, -65],
    flag: '🇧🇴',
    borders: ['AR', 'BR', 'CL', 'PY', 'PE'],
    code3: 'BOL',
    capital: ['Sucre'],
    inCountryList: ['UN'],
    region: 'Americas',
    name: 'Bolivia',
    callingCode: ['591'],
    currency: ['BOB'],
    languages: {
      spa: 'Spanish',
      que: 'Quechua',
      grn: 'Guaraní',
      aym: 'Aymara',
    },
    paths: [],
  },
  BQ: {
    name: 'Bonaire',
    code: 'BQ',
    region: 'Americas',
    flag: '🇧🇶',
    paths: [],
  },
  BR: {
    name: 'Brazil',
    borders: ['AR', 'BO', 'CO', 'GF', 'GY', 'PY', 'PE', 'SR', 'UY', 'VE'],
    flag: '🇧🇷',
    region: 'Americas',
    code: 'BR',
    code3: 'BRA',
    codeN: '076',
    latlng: [-10, -55],
    callingCode: ['55'],
    subregion: 'South America',
    currency: ['BRL'],
    languages: {
      por: 'Portuguese',
    },
    inCountryList: ['UN'],
    capital: ['Brasília'],
    paths: [],
  },
  BS: {
    latlng: [24.25, -76],
    languages: {
      eng: 'English',
    },
    region: 'Americas',
    subregion: 'Caribbean',
    code3: 'BHS',
    flag: '🇧🇸',
    code: 'BS',
    capital: ['Nassau'],
    currency: ['BSD'],
    codeN: '044',
    borders: [],
    inCountryList: ['UN'],
    name: 'Bahamas',
    callingCode: ['1242'],
    paths: [],
  },
  BT: {
    name: 'Bhutan',
    latlng: [27.5, 90.5],
    code: 'BT',
    codeN: '064',
    currency: ['BTN', 'INR'],
    subregion: 'Southern Asia',
    inCountryList: ['UN'],
    code3: 'BTN',
    flag: '🇧🇹',
    callingCode: ['975'],
    languages: {
      dzo: 'Dzongkha',
    },
    capital: ['Thimphu'],
    region: 'Asia',
    borders: ['CN', 'IN'],
    paths: [],
  },
  BW: {
    codeN: '072',
    code3: 'BWA',
    languages: {
      eng: 'English',
      tsn: 'Tswana',
    },
    latlng: [-22, 24],
    callingCode: ['267'],
    inCountryList: ['UN'],
    subregion: 'Southern Africa',
    flag: '🇧🇼',
    currency: ['BWP'],
    capital: ['Gaborone'],
    name: 'Botswana',
    region: 'Africa',
    code: 'BW',
    borders: ['NA', 'ZA', 'ZM', 'ZW'],
    paths: [],
  },
  BY: {
    region: 'Europe',
    subregion: 'Eastern Europe',
    latlng: [53, 28],
    borders: ['LV', 'LT', 'PL', 'RU', 'UA'],
    codeN: '112',
    currency: ['BYN'],
    capital: ['Minsk'],
    code: 'BY',
    code3: 'BLR',
    flag: '🇧🇾',
    name: 'Belarus',
    inCountryList: ['UN'],
    callingCode: ['375'],
    languages: {
      bel: 'Belarusian',
      rus: 'Russian',
    },
    paths: [],
  },
  BZ: {
    capital: ['Belmopan'],
    code: 'BZ',
    callingCode: ['501'],
    inCountryList: ['UN'],
    borders: ['GT', 'MX'],
    flag: '🇧🇿',
    languages: {
      bjz: 'Belizean Creole',
      eng: 'English',
      spa: 'Spanish',
    },
    subregion: 'Central America',
    codeN: '084',
    region: 'Americas',
    code3: 'BLZ',
    currency: ['BZD'],
    latlng: [17.25, -88.75],
    name: 'Belize',
    paths: [],
  },
  CA: {
    currency: ['CAD'],
    name: 'Canada',
    flag: '🇨🇦',
    code3: 'CAN',
    capital: ['Ottawa'],
    latlng: [60, -95],
    code: 'CA',
    inCountryList: ['UN'],
    codeN: '124',
    borders: ['US'],
    callingCode: ['1'],
    subregion: 'North America',
    region: 'Americas',
    languages: {
      fra: 'French',
      eng: 'English',
    },
    paths: [],
  },
  CD: {
    borders: ['AO', 'BI', 'CF', 'CG', 'RW', 'SS', 'TZ', 'UG', 'ZM'],
    languages: {
      lin: 'Lingala',
      fra: 'French',
      lua: 'Tshiluba',
      kon: 'Kikongo',
      swa: 'Swahili',
    },
    codeN: '180',
    callingCode: ['243'],
    flag: '🇨🇩',
    latlng: [0, 25],
    currency: ['CDF'],
    capital: ['Kinshasa'],
    code3: 'COD',
    inCountryList: ['UN'],
    subregion: 'Middle Africa',
    name: 'DR Congo',
    code: 'CD',
    region: 'Africa',
    paths: [],
  },
  CF: {
    callingCode: ['236'],
    code: 'CF',
    inCountryList: ['UN'],
    subregion: 'Middle Africa',
    languages: {
      sag: 'Sango',
      fra: 'French',
    },
    currency: ['XAF'],
    codeN: '140',
    flag: '🇨🇫',
    capital: ['Bangui'],
    latlng: [7, 21],
    code3: 'CAF',
    region: 'Africa',
    name: 'Central African Republic',
    borders: ['CM', 'TD', 'CD', 'CG', 'SS', 'SD'],
    paths: [],
  },
  CG: {
    callingCode: ['242'],
    capital: ['Brazzaville'],
    currency: ['XAF'],
    flag: '🇨🇬',
    latlng: [-1, 15],
    inCountryList: ['UN'],
    code: 'CG',
    name: 'Republic of the Congo',
    code3: 'COG',
    subregion: 'Middle Africa',
    codeN: '178',
    borders: ['AO', 'CM', 'CF', 'CD', 'GA'],
    languages: {
      lin: 'Lingala',
      fra: 'French',
      kon: 'Kikongo',
    },
    region: 'Africa',
    paths: [],
  },
  CH: {
    languages: {
      roh: 'Romansh',
      fra: 'French',
      gsw: 'Swiss German',
      ita: 'Italian',
    },
    code3: 'CHE',
    name: 'Switzerland',
    inCountryList: ['UN'],
    code: 'CH',
    latlng: [47, 8],
    borders: ['AT', 'FR', 'IT', 'LI', 'DE'],
    region: 'Europe',
    callingCode: ['41'],
    flag: '🇨🇭',
    codeN: '756',
    subregion: 'Western Europe',
    capital: ['Bern'],
    currency: ['CHF'],
    paths: [],
  },
  CI: {
    code: 'CI',
    capital: ['Yamoussoukro'],
    codeN: '384',
    inCountryList: ['UN'],
    currency: ['XOF'],
    flag: '🇨🇮',
    code3: 'CIV',
    languages: {
      fra: 'French',
    },
    latlng: [8, -5],
    borders: ['BF', 'GH', 'GN', 'LR', 'ML'],
    subregion: 'Western Africa',
    callingCode: ['225'],
    name: 'Ivory Coast',
    region: 'Africa',
    paths: [],
  },
  CK: {
    region: 'Oceania',
    latlng: [-21, -160],
    code: 'CK',
    capital: ['Avarua'],
    code3: 'COK',
    callingCode: ['682'],
    name: 'Cook Islands',
    inCountryList: ['NewZeland'],
    borders: [],
    flag: '🇨🇰',
    subregion: 'Australia and New Zealand',
    codeN: '184',
    languages: {
      mri: 'Māori',
      eng: 'English',
    },
    currency: ['NZD'],
    paths: [],
  },
  CL: {
    name: 'Chile',
    currency: ['CLP'],
    inCountryList: ['UN'],
    borders: ['AR', 'BO', 'PE'],
    languages: {
      spa: 'Spanish',
    },
    capital: ['Santiago'],
    latlng: [-30, -71],
    subregion: 'South America',
    codeN: '152',
    callingCode: ['56'],
    code: 'CL',
    flag: '🇨🇱',
    code3: 'CHL',
    region: 'Americas',
    paths: [],
  },
  CM: {
    callingCode: ['237'],
    codeN: '120',
    code: 'CM',
    subregion: 'Middle Africa',
    region: 'Africa',
    languages: {
      fra: 'French',
      eng: 'English',
    },
    latlng: [6, 12],
    currency: ['XAF'],
    borders: ['CF', 'TD', 'CG', 'GQ', 'GA', 'NG'],
    inCountryList: ['UN'],
    code3: 'CMR',
    name: 'Cameroon',
    flag: '🇨🇲',
    capital: ['Yaoundé'],
    paths: [],
  },
  CN: {
    borders: [
      'AF',
      'BT',
      'MM',
      'HK',
      'IN',
      'KZ',
      'NP',
      'KP',
      'KG',
      'LA',
      'MO',
      'MN',
      'PK',
      'RU',
      'TJ',
      'VN',
    ],
    subregion: 'Eastern Asia',
    languages: {
      zho: 'Chinese',
    },
    code3: 'CHN',
    currency: ['CNY'],
    flag: '🇨🇳',
    latlng: [35, 105],
    region: 'Asia',
    code: 'CN',
    capital: ['Beijing'],
    codeN: '156',
    name: 'China',
    callingCode: ['86'],
    inCountryList: ['UN'],
    paths: [],
  },
  CO: {
    flag: '🇨🇴',
    region: 'Americas',
    latlng: [4, -72],
    code: 'CO',
    borders: ['BR', 'EC', 'PA', 'PE', 'VE'],
    languages: {
      spa: 'Spanish',
    },
    code3: 'COL',
    capital: ['Bogotá'],
    codeN: '170',
    callingCode: ['57'],
    subregion: 'South America',
    currency: ['COP'],
    inCountryList: ['UN'],
    name: 'Colombia',
    paths: [],
  },
  CR: {
    code3: 'CRI',
    languages: {
      spa: 'Spanish',
    },
    subregion: 'Central America',
    name: 'Costa Rica',
    latlng: [10, -84],
    callingCode: ['506'],
    region: 'Americas',
    flag: '🇨🇷',
    capital: ['San José'],
    borders: ['NI', 'PA'],
    codeN: '188',
    code: 'CR',
    currency: ['CRC'],
    inCountryList: ['UN'],
    paths: [],
  },
  CU: {
    callingCode: ['53'],
    name: 'Cuba',
    languages: {
      spa: 'Spanish',
    },
    borders: [],
    subregion: 'Caribbean',
    currency: ['CUC', 'CUP'],
    code3: 'CUB',
    region: 'Americas',
    flag: '🇨🇺',
    codeN: '192',
    capital: ['Havana'],
    latlng: [21.5, -80],
    code: 'CU',
    inCountryList: ['UN'],
    paths: [],
  },
  CV: {
    codeN: '132',
    capital: ['Praia'],
    subregion: 'Western Africa',
    inCountryList: ['UN'],
    languages: {
      por: 'Portuguese',
    },
    region: 'Africa',
    flag: '🇨🇻',
    borders: [],
    code: 'CV',
    currency: ['CVE'],
    callingCode: ['238'],
    name: 'Cape Verde',
    latlng: [16, -24],
    code3: 'CPV',
    paths: [],
  },
  CW: {
    region: 'Americas',
    flag: '🇨🇼',
    code: 'CW',
    name: 'Curaçao',
    paths: [],
  },
  CY: {
    latlng: [35, 33],
    subregion: 'Eastern Europe',
    currency: ['EUR'],
    name: 'Cyprus',
    region: 'Europe',
    languages: {
      ell: 'Greek',
      tur: 'Turkish',
    },
    code3: 'CYP',
    borders: [],
    codeN: '196',
    callingCode: ['357'],
    capital: ['Nicosia'],
    flag: '🇨🇾',
    code: 'CY',
    inCountryList: ['UN'],
    paths: [],
  },
  CZ: {
    callingCode: ['420'],
    name: 'Czechia',
    codeN: '203',
    currency: ['CZK'],
    code3: 'CZE',
    flag: '🇨🇿',
    languages: {
      slk: 'Slovak',
      ces: 'Czech',
    },
    capital: ['Prague'],
    code: 'CZ',
    region: 'Europe',
    inCountryList: ['UN'],
    borders: ['AT', 'DE', 'PL', 'SK'],
    subregion: 'Eastern Europe',
    latlng: [49.75, 15.5],
    paths: [],
  },
  DE: {
    inCountryList: ['UN'],
    latlng: [51, 9],
    callingCode: ['49'],
    borders: ['AT', 'BE', 'CZ', 'DK', 'FR', 'LU', 'NL', 'PL', 'CH'],
    codeN: '276',
    languages: {
      deu: 'German',
    },
    flag: '🇩🇪',
    name: 'Germany',
    subregion: 'Western Europe',
    code3: 'DEU',
    region: 'Europe',
    code: 'DE',
    capital: ['Berlin'],
    currency: ['EUR'],
    paths: [],
  },
  DJ: {
    currency: ['DJF'],
    capital: ['Djibouti'],
    languages: {
      fra: 'French',
      ara: 'Arabic',
    },
    code3: 'DJI',
    latlng: [11.5, 43],
    code: 'DJ',
    flag: '🇩🇯',
    borders: ['ER', 'ET', 'SO'],
    name: 'Djibouti',
    region: 'Africa',
    inCountryList: ['UN'],
    codeN: '262',
    subregion: 'Eastern Africa',
    callingCode: ['253'],
    paths: [],
  },
  DK: {
    flag: '🇩🇰',
    code3: 'DNK',
    inCountryList: ['UN'],
    code: 'DK',
    codeN: '208',
    latlng: [56, 10],
    currency: ['DKK'],
    languages: {
      dan: 'Danish',
    },
    callingCode: ['45'],
    region: 'Europe',
    borders: ['DE'],
    subregion: 'Northern Europe',
    capital: ['Copenhagen'],
    name: 'Denmark',
    paths: [],
  },
  DM: {
    code3: 'DMA',
    code: 'DM',
    inCountryList: ['UN'],
    languages: {
      eng: 'English',
    },
    capital: ['Roseau'],
    currency: ['XCD'],
    flag: '🇩🇲',
    callingCode: ['1767'],
    subregion: 'Caribbean',
    name: 'Dominica',
    codeN: '212',
    latlng: [15.41666666, -61.33333333],
    borders: [],
    region: 'Americas',
    paths: [],
  },
  DO: {
    flag: '🇩🇴',
    subregion: 'Caribbean',
    code: 'DO',
    name: 'Dominican Republic',
    capital: ['Santo Domingo'],
    region: 'Americas',
    latlng: [19, -70.66666666],
    code3: 'DOM',
    languages: {
      spa: 'Spanish',
    },
    currency: ['DOP'],
    inCountryList: ['UN'],
    callingCode: ['1809', '1829', '1849'],
    codeN: '214',
    borders: ['HT'],
    paths: [],
  },
  DZ: {
    inCountryList: ['UN'],
    capital: ['Algiers'],
    code: 'DZ',
    borders: ['TN', 'LY', 'NE', 'EH', 'MR', 'ML', 'MA'],
    region: 'Africa',
    languages: {
      ara: 'Arabic',
    },
    flag: '🇩🇿',
    latlng: [28, 3],
    subregion: 'Northern Africa',
    callingCode: ['213'],
    currency: ['DZD'],
    code3: 'DZA',
    name: 'Algeria',
    codeN: '012',
    paths: [],
  },
  EC: {
    callingCode: ['593'],
    name: 'Ecuador',
    currency: ['USD'],
    inCountryList: ['UN'],
    latlng: [-2, -77.5],
    languages: {
      spa: 'Spanish',
    },
    code3: 'ECU',
    region: 'Americas',
    borders: ['CO', 'PE'],
    codeN: '218',
    subregion: 'South America',
    capital: ['Quito'],
    flag: '🇪🇨',
    code: 'EC',
    paths: [],
  },
  EE: {
    callingCode: ['372'],
    name: 'Estonia',
    codeN: '233',
    capital: ['Tallinn'],
    inCountryList: ['UN'],
    currency: ['EUR'],
    region: 'Europe',
    code: 'EE',
    latlng: [59, 26],
    languages: {
      est: 'Estonian',
    },
    borders: ['LV', 'RU'],
    code3: 'EST',
    flag: '🇪🇪',
    subregion: 'Northern Europe',
    paths: [],
  },
  EG: {
    code: 'EG',
    codeN: '818',
    flag: '🇪🇬',
    name: 'Egypt',
    code3: 'EGY',
    callingCode: ['20'],
    borders: ['IL', 'LY', 'PS', 'SD'],
    latlng: [27, 30],
    languages: {
      ara: 'Arabic',
    },
    region: 'Africa',
    inCountryList: ['UN'],
    subregion: 'Northern Africa',
    currency: ['EGP'],
    capital: ['Cairo'],
    paths: [],
  },
  ER: {
    region: 'Africa',
    languages: {
      ara: 'Arabic',
      tir: 'Tigrinya',
      eng: 'English',
    },
    latlng: [15, 39],
    code: 'ER',
    borders: ['DJ', 'ET', 'SD'],
    name: 'Eritrea',
    currency: ['ERN'],
    code3: 'ERI',
    callingCode: ['291'],
    subregion: 'Eastern Africa',
    codeN: '232',
    inCountryList: ['UN'],
    capital: ['Asmara'],
    flag: '🇪🇷',
    paths: [],
  },
  ES: {
    languages: {
      spa: 'Spanish',
    },
    subregion: 'Southern Europe',
    flag: '🇪🇸',
    borders: ['AD', 'FR', 'GI', 'PT', 'MA'],
    callingCode: ['34'],
    region: 'Europe',
    code3: 'ESP',
    currency: ['EUR'],
    codeN: '724',
    capital: ['Madrid'],
    latlng: [40, -4],
    code: 'ES',
    inCountryList: ['UN'],
    name: 'Spain',
    paths: [],
  },
  ET: {
    callingCode: ['251'],
    codeN: '231',
    borders: ['DJ', 'ER', 'KE', 'SO', 'SS', 'SD'],
    code: 'ET',
    inCountryList: ['UN'],
    currency: ['ETB'],
    region: 'Africa',
    subregion: 'Eastern Africa',
    code3: 'ETH',
    flag: '🇪🇹',
    capital: ['Addis Ababa'],
    languages: {
      amh: 'Amharic',
    },
    name: 'Ethiopia',
    latlng: [8, 38],
    paths: [],
  },
  FI: {
    name: 'Finland',
    code: 'FI',
    currency: ['EUR'],
    languages: {
      swe: 'Swedish',
      fin: 'Finnish',
    },
    region: 'Europe',
    inCountryList: ['UN'],
    capital: ['Helsinki'],
    subregion: 'Northern Europe',
    flag: '🇫🇮',
    codeN: '246',
    latlng: [64, 26],
    code3: 'FIN',
    callingCode: ['358'],
    borders: ['NO', 'SE', 'RU'],
    paths: [],
  },
  FJ: {
    callingCode: ['679'],
    codeN: '242',
    languages: {
      hif: 'Fiji Hindi',
      eng: 'English',
      fij: 'Fijian',
    },
    code: 'FJ',
    currency: ['FJD'],
    code3: 'FJI',
    flag: '🇫🇯',
    borders: [],
    latlng: [-18, 175],
    capital: ['Suva'],
    subregion: 'Melanesia',
    name: 'Fiji',
    inCountryList: ['UN'],
    region: 'Oceania',
    paths: [],
  },
  FK: {
    codeN: '238',
    currency: ['FKP'],
    capital: ['Stanley'],
    subregion: 'South America',
    name: 'Falkland Islands',
    region: 'Americas',
    languages: {
      eng: 'English',
    },
    code: 'FK',
    latlng: [-51.75, -59],
    callingCode: ['500'],
    inCountryList: ['UN'],
    flag: '🇫🇰',
    borders: [],
    code3: 'FLK',
    paths: [],
  },
  FM: {
    code3: 'FSM',
    languages: {
      eng: 'English',
    },
    currency: ['USD'],
    codeN: '583',
    name: 'Micronesia',
    callingCode: ['691'],
    flag: '🇫🇲',
    capital: ['Palikir'],
    latlng: [6.91666666, 158.25],
    borders: [],
    subregion: 'Micronesia',
    region: 'Oceania',
    inCountryList: ['UN'],
    code: 'FM',
    paths: [],
  },
  FO: {
    borders: [],
    ' inCountryList': ['UN'],
    capital: ['Tórshavn'],
    code: 'FO',
    flag: '🇫🇴',
    ' currency': ['DKK'],
    name: 'Faroe Islands',
    subregion: 'Northern Europe',
    region: 'Europe',
    callingCode: ['298'],
    paths: [],
  },
  FR: {
    inCountryList: ['UN'],
    name: 'France',
    borders: ['AD', 'BE', 'DE', 'IT', 'LU', 'MC', 'ES', 'CH'],
    callingCode: ['33'],
    subregion: 'Western Europe',
    flag: '🇫🇷',
    code3: 'FRA',
    codeN: '250',
    latlng: [46, 2],
    capital: ['Paris'],
    code: 'FR',
    languages: {
      fra: 'French',
    },
    region: 'Europe',
    currency: ['EUR'],
    paths: [],
  },
  GA: {
    flag: '🇬🇦',
    code: 'GA',
    name: 'Gabon',
    latlng: [-1, 11.75],
    borders: ['CM', 'CG', 'GQ'],
    capital: ['Libreville'],
    currency: ['XAF'],
    callingCode: ['241'],
    codeN: '266',
    inCountryList: ['UN'],
    subregion: 'Middle Africa',
    code3: 'GAB',
    languages: {
      fra: 'French',
    },
    region: 'Africa',
    paths: [],
  },
  GB: {
    name: 'United Kingdom',
    capital: ['London'],
    languages: {
      eng: 'English',
    },
    callingCode: ['44'],
    code3: 'GBR',
    subregion: 'Northern Europe',
    codeN: '826',
    flag: '🇬🇧',
    inCountryList: ['UN'],
    code: 'GB',
    borders: ['IE'],
    latlng: [54, -2],
    region: 'Europe',
    currency: ['GBP'],
    paths: [],
  },
  GD: {
    capital: ["St. George's"],
    inCountryList: ['UN'],
    subregion: 'Caribbean',
    callingCode: ['1473'],
    flag: '🇬🇩',
    name: 'Grenada',
    latlng: [12.11666666, -61.66666666],
    codeN: '308',
    languages: {
      eng: 'English',
    },
    region: 'Americas',
    code: 'GD',
    currency: ['XCD'],
    borders: [],
    code3: 'GRD',
    paths: [],
  },
  GE: {
    inCountryList: ['UN'],
    subregion: 'Western Asia',
    capital: ['Tbilisi'],
    borders: ['AM', 'AZ', 'RU', 'TR'],
    name: 'Georgia',
    codeN: '268',
    code3: 'GEO',
    callingCode: ['995'],
    languages: {
      kat: 'Georgian',
    },
    flag: '🇬🇪',
    code: 'GE',
    currency: ['GEL'],
    region: 'Asia',
    latlng: [42, 43.5],
    paths: [],
  },
  GF: {
    currency: ['EUR'],
    region: 'Americas',
    inCountryList: ['UN'],
    codeN: '254',
    subregion: 'South America',
    borders: ['BR', 'SR'],
    capital: ['Cayenne'],
    code: 'GF',
    code3: 'GUF',
    name: 'French Guiana',
    languages: {
      fra: 'French',
    },
    callingCode: ['594'],
    latlng: [4, -53],
    flag: '🇬🇫',
    paths: [],
  },
  GG: {
    region: 'Europe',
    name: 'Guernsey',
    code: 'GG',
    flag: '🇬🇬',
    paths: [],
  },
  GH: {
    code: 'GH',
    callingCode: ['233'],
    currency: ['GHS'],
    region: 'Africa',
    latlng: [8, -2],
    codeN: '288',
    borders: ['BF', 'CI', 'TG'],
    inCountryList: ['UN'],
    subregion: 'Western Africa',
    flag: '🇬🇭',
    capital: ['Accra'],
    languages: {
      eng: 'English',
    },
    code3: 'GHA',
    name: 'Ghana',
    paths: [],
  },
  GI: {
    flag: '🇬🇮',
    region: 'Europe',
    code: 'GI',
    name: 'Gibraltar',
    paths: [],
  },
  GL: {
    flag: '🇬🇱',
    borders: [],
    currency: ['DKK'],
    inCountryList: ['UN'],
    region: 'Americas',
    subregion: 'North America',
    latlng: [72, -40],
    code3: 'GRL',
    code: 'GL',
    name: 'Greenland',
    callingCode: ['299'],
    languages: {
      kal: 'Greenlandic',
    },
    capital: ['Nuuk'],
    codeN: '304',
    paths: [],
  },
  GM: {
    latlng: [13.46666666, -16.56666666],
    codeN: '270',
    subregion: 'Western Africa',
    borders: ['SN'],
    flag: '🇬🇲',
    inCountryList: ['UN'],
    region: 'Africa',
    code: 'GM',
    capital: ['Banjul'],
    code3: 'GMB',
    name: 'Gambia',
    languages: {
      eng: 'English',
    },
    callingCode: ['220'],
    currency: ['GMD'],
    paths: [],
  },
  GN: {
    code3: 'GIN',
    codeN: '324',
    callingCode: ['224'],
    borders: ['CI', 'GW', 'LR', 'ML', 'SN', 'SL'],
    latlng: [11, -10],
    capital: ['Conakry'],
    inCountryList: ['UN'],
    languages: {
      fra: 'French',
    },
    currency: ['GNF'],
    subregion: 'Western Africa',
    name: 'Guinea',
    region: 'Africa',
    flag: '🇬🇳',
    code: 'GN',
    paths: [],
  },
  GP: {
    code: 'GP',
    flag: '🇬🇵',
    name: 'Guadeloupe',
    region: 'Americas',
    paths: [],
  },
  GQ: {
    region: 'Africa',
    inCountryList: ['UN'],
    flag: '🇬🇶',
    languages: {
      spa: 'Spanish',
      fra: 'French',
      por: 'Portuguese',
    },
    subregion: 'Middle Africa',
    name: 'Equatorial Guinea',
    callingCode: ['240'],
    capital: ['Malabo'],
    codeN: '226',
    code3: 'GNQ',
    latlng: [2, 10],
    currency: ['XAF'],
    borders: ['CM', 'GA'],
    code: 'GQ',
    paths: [],
  },
  GR: {
    currency: ['EUR'],
    region: 'Europe',
    code: 'GR',
    codeN: '300',
    capital: ['Athens'],
    inCountryList: ['UN'],
    name: 'Greece',
    subregion: 'Southern Europe',
    languages: {
      ell: 'Greek',
    },
    borders: ['AL', 'BG', 'TR', 'MK'],
    latlng: [39, 22],
    code3: 'GRC',
    callingCode: ['30'],
    flag: '🇬🇷',
    paths: [],
  },
  GT: {
    code3: 'GTM',
    currency: ['GTQ'],
    capital: ['Guatemala City'],
    flag: '🇬🇹',
    callingCode: ['502'],
    subregion: 'Central America',
    codeN: '320',
    region: 'Americas',
    code: 'GT',
    inCountryList: ['UN'],
    latlng: [15.5, -90.25],
    languages: {
      spa: 'Spanish',
    },
    name: 'Guatemala',
    borders: ['BZ', 'SV', 'HN', 'MX'],
    paths: [],
  },
  GU: {
    region: 'Oceania',
    code: 'GU',
    name: 'Guam',
    flag: '🇬🇺',
    paths: [],
  },
  GW: {
    flag: '🇬🇼',
    subregion: 'Western Africa',
    code: 'GW',
    languages: {
      pov: 'Upper Guinea Creole',
      por: 'Portuguese',
    },
    currency: ['XOF'],
    codeN: '624',
    latlng: [12, -15],
    capital: ['Bissau'],
    code3: 'GNB',
    name: 'Guinea-Bissau',
    callingCode: ['245'],
    borders: ['GN', 'SN'],
    inCountryList: ['UN'],
    region: 'Africa',
    paths: [],
  },
  GY: {
    capital: ['Georgetown'],
    flag: '🇬🇾',
    name: 'Guyana',
    callingCode: ['592'],
    latlng: [5, -59],
    currency: ['GYD'],
    code3: 'GUY',
    codeN: '328',
    inCountryList: ['UN'],
    borders: ['BR', 'SR', 'VE'],
    subregion: 'South America',
    languages: {
      eng: 'English',
    },
    region: 'Americas',
    code: 'GY',
    paths: [],
  },
  HK: {
    callingCode: '852',
    name: 'Hong Kong',
    currency: 'HKD',
    flag: '🇭🇰',
    subregion: 'Eastern Asia',
    code: 'HK',
    languages: {
      zho: 'Chinese',
      eng: 'English',
    },
    region: 'Asia',
    borders: ['CN'],
    capital: ['Hong Kong'],
    paths: [],
  },
  HN: {
    borders: ['GT', 'SV', 'NI'],
    languages: {
      spa: 'Spanish',
    },
    code3: 'HND',
    codeN: '340',
    latlng: [15, -86.5],
    subregion: 'Central America',
    flag: '🇭🇳',
    callingCode: ['504'],
    capital: ['Tegucigalpa'],
    code: 'HN',
    region: 'Americas',
    inCountryList: ['UN'],
    currency: ['HNL'],
    name: 'Honduras',
    paths: [],
  },
  HR: {
    callingCode: ['385'],
    inCountryList: ['UN'],
    currency: ['HRK'],
    latlng: [45.16666666, 15.5],
    code: 'HR',
    borders: ['BA', 'HU', 'ME', 'RS', 'SI'],
    flag: '🇭🇷',
    codeN: '191',
    name: 'Croatia',
    languages: {
      hrv: 'Croatian',
    },
    capital: ['Zagreb'],
    subregion: 'Southern Europe',
    code3: 'HRV',
    region: 'Europe',
    paths: [],
  },
  HT: {
    latlng: [19, -72.41666666],
    codeN: '332',
    region: 'Americas',
    code: 'HT',
    code3: 'HTI',
    flag: '🇭🇹',
    capital: ['Port-au-Prince'],
    name: 'Haiti',
    callingCode: ['509'],
    subregion: 'Caribbean',
    borders: ['DO'],
    currency: ['HTG', 'USD'],
    inCountryList: ['UN'],
    languages: {
      fra: 'French',
      hat: 'Haitian Creole',
    },
    paths: [],
  },
  HU: {
    inCountryList: ['UN'],
    name: 'Hungary',
    region: 'Europe',
    code: 'HU',
    flag: '🇭🇺',
    latlng: [47, 20],
    codeN: '348',
    subregion: 'Eastern Europe',
    capital: ['Budapest'],
    currency: ['HUF'],
    borders: ['AT', 'HR', 'RO', 'RS', 'SK', 'SI', 'UA'],
    languages: {
      hun: 'Hungarian',
    },
    callingCode: ['36'],
    code3: 'HUN',
    paths: [],
  },
  ID: {
    borders: ['TL', 'MY', 'PG'],
    name: 'Indonesia',
    code3: 'IDN',
    code: 'ID',
    subregion: 'South-Eastern Asia',
    inCountryList: ['UN'],
    currency: ['IDR'],
    languages: {
      ind: 'Indonesian',
    },
    capital: ['Jakarta'],
    region: 'Asia',
    codeN: '360',
    flag: '🇮🇩',
    latlng: [-5, 120],
    callingCode: ['62'],
    paths: [],
  },
  IE: {
    name: 'Ireland',
    capital: ['Dublin'],
    currency: ['EUR'],
    borders: ['GB'],
    region: 'Europe',
    flag: '🇮🇪',
    subregion: 'Northern Europe',
    codeN: '372',
    languages: {
      eng: 'English',
      gle: 'Irish',
    },
    callingCode: ['353'],
    code3: 'IRL',
    latlng: [53, -8],
    code: 'IE',
    inCountryList: ['UN'],
    paths: [],
  },
  IL: {
    callingCode: ['972'],
    latlng: [31.47, 35.13],
    subregion: 'Western Asia',
    inCountryList: ['UN'],
    name: 'Israel',
    region: 'Asia',
    borders: ['EG', 'JO', 'LB', 'PS', 'SY'],
    codeN: '376',
    languages: {
      ara: 'Arabic',
      heb: 'Hebrew',
    },
    flag: '🇮🇱',
    currency: ['ILS'],
    code3: 'ISR',
    capital: ['Jerusalem'],
    code: 'IL',
    paths: [],
  },
  IM: {
    code: 'IM',
    flag: '🇮🇲',
    capital: ['Douglas'],
    name: 'Isle of Man',
    region: 'Europe',
    paths: [],
  },
  IN: {
    languages: {
      hin: 'Hindi',
      tam: 'Tamil',
      eng: 'English',
    },
    name: 'India',
    code3: 'IND',
    capital: ['New Delhi'],
    subregion: 'Southern Asia',
    currency: ['INR'],
    region: 'Asia',
    borders: ['BD', 'BT', 'MM', 'CN', 'NP', 'PK'],
    latlng: [20, 77],
    code: 'IN',
    inCountryList: ['UN'],
    codeN: '356',
    flag: '🇮🇳',
    callingCode: ['91'],
    paths: [],
  },
  IQ: {
    subregion: 'Western Asia',
    region: 'Asia',
    capital: ['Baghdad'],
    borders: ['IR', 'JO', 'KW', 'SA', 'SY', 'TR'],
    inCountryList: ['UN'],
    codeN: '368',
    currency: ['IQD'],
    name: 'Iraq',
    latlng: [33, 44],
    code: 'IQ',
    code3: 'IRQ',
    callingCode: ['964'],
    languages: {
      ara: 'Arabic',
      arc: 'Aramaic',
      ckb: 'Sorani',
    },
    flag: '🇮🇶',
    paths: [],
  },
  IR: {
    codeN: '364',
    name: 'Iran',
    region: 'Asia',
    inCountryList: ['UN'],
    languages: {
      fas: 'Persian',
    },
    currency: ['IRR'],
    latlng: [32, 53],
    code3: 'IRN',
    callingCode: ['98'],
    code: 'IR',
    flag: '🇮🇷',
    subregion: 'Southern Asia',
    borders: ['AF', 'AM', 'AZ', 'IQ', 'PK', 'TR', 'TM'],
    capital: ['Tehran'],
    paths: [],
  },
  IS: {
    inCountryList: ['UN'],
    flag: '🇮🇸',
    subregion: 'Northern Europe',
    latlng: [65, -18],
    name: 'Iceland',
    callingCode: ['354'],
    region: 'Europe',
    capital: ['Reykjavik'],
    borders: [],
    currency: ['ISK'],
    languages: {
      isl: 'Icelandic',
    },
    codeN: '352',
    code: 'IS',
    code3: 'ISL',
    paths: [],
  },
  IT: {
    inCountryList: ['UN'],
    code3: 'ITA',
    capital: ['Rome'],
    flag: '🇮🇹',
    callingCode: ['39'],
    subregion: 'Southern Europe',
    latlng: [42.83333333, 12.83333333],
    currency: ['EUR'],
    code: 'IT',
    name: 'Italy',
    languages: {
      ita: 'Italian',
    },
    borders: ['AT', 'FR', 'SM', 'SI', 'CH', 'VA'],
    codeN: '380',
    region: 'Europe',
    paths: [],
  },
  JM: {
    inCountryList: ['UN'],
    currency: ['JMD'],
    code3: 'JAM',
    subregion: 'Caribbean',
    capital: ['Kingston'],
    codeN: '388',
    latlng: [18.25, -77.5],
    flag: '🇯🇲',
    name: 'Jamaica',
    code: 'JM',
    borders: [],
    languages: {
      jam: 'Jamaican Patois',
      eng: 'English',
    },
    region: 'Americas',
    callingCode: ['1876'],
    paths: [],
  },
  JO: {
    callingCode: ['962'],
    inCountryList: ['UN'],
    latlng: [31, 36],
    capital: ['Amman'],
    borders: ['IQ', 'IL', 'PS', 'SA', 'SY'],
    subregion: 'Western Asia',
    code3: 'JOR',
    languages: {
      ara: 'Arabic',
    },
    flag: '🇯🇴',
    name: 'Jordan',
    region: 'Asia',
    code: 'JO',
    currency: ['JOD'],
    codeN: '400',
    paths: [],
  },
  JP: {
    subregion: 'Eastern Asia',
    currency: ['JPY'],
    languages: {
      jpn: 'Japanese',
    },
    borders: [],
    latlng: [36, 138],
    region: 'Asia',
    name: 'Japan',
    codeN: '392',
    callingCode: ['81'],
    capital: ['Tokyo'],
    flag: '🇯🇵',
    code3: 'JPN',
    code: 'JP',
    inCountryList: ['UN'],
    paths: [],
  },
  KE: {
    region: 'Africa',
    capital: ['Nairobi'],
    currency: ['KES'],
    code: 'KE',
    latlng: [1, 38],
    inCountryList: ['UN'],
    flag: '🇰🇪',
    name: 'Kenya',
    subregion: 'Eastern Africa',
    borders: ['ET', 'SO', 'SS', 'TZ', 'UG'],
    codeN: '404',
    callingCode: ['254'],
    languages: {
      swa: 'Swahili',
      eng: 'English',
    },
    code3: 'KEN',
    paths: [],
  },
  KG: {
    inCountryList: ['UN'],
    region: 'Asia',
    code: 'KG',
    currency: ['KGS'],
    callingCode: ['996'],
    subregion: 'Central Asia',
    latlng: [41, 75],
    borders: ['CN', 'KZ', 'TJ', 'UZ'],
    capital: ['Bishkek'],
    languages: {
      rus: 'Russian',
      kir: 'Kyrgyz',
    },
    name: 'Kyrgyzstan',
    flag: '🇰🇬',
    code3: 'KGZ',
    codeN: '417',
    paths: [],
  },
  KH: {
    callingCode: ['855'],
    region: 'Asia',
    codeN: '116',
    borders: ['LA', 'TH', 'VN'],
    flag: '🇰🇭',
    currency: ['KHR'],
    capital: ['Phnom Penh'],
    languages: {
      khm: 'Khmer',
    },
    subregion: 'South-Eastern Asia',
    code3: 'KHM',
    code: 'KH',
    latlng: [13, 105],
    inCountryList: ['UN'],
    name: 'Cambodia',
    paths: [],
  },
  KI: {
    codeN: '296',
    flag: '🇰🇮',
    languages: {
      gil: 'Gilbertese',
      eng: 'English',
    },
    code: 'KI',
    region: 'Oceania',
    subregion: 'Micronesia',
    borders: [],
    callingCode: ['686'],
    latlng: [1.41666666, 173],
    inCountryList: ['UN'],
    capital: ['South Tarawa'],
    name: 'Kiribati',
    currency: ['AUD'],
    code3: 'KIR',
    paths: [],
  },
  KM: {
    region: 'Africa',
    languages: {
      fra: 'French',
      zdj: 'Comorian',
      ara: 'Arabic',
    },
    callingCode: ['269'],
    code3: 'COM',
    currency: ['KMF'],
    flag: '🇰🇲',
    inCountryList: ['UN'],
    latlng: [-12.16666666, 44.25],
    subregion: 'Eastern Africa',
    code: 'KM',
    capital: ['Moroni'],
    borders: [],
    codeN: '174',
    name: 'Comoros',
    paths: [],
  },
  KN: {
    borders: [],
    inCountryList: ['UN'],
    capital: ['Basseterre'],
    currency: ['XCD'],
    latlng: [17.33333333, -62.75],
    name: 'Saint Kitts and Nevis',
    languages: {
      eng: 'English',
    },
    region: 'Americas',
    codeN: '659',
    flag: '🇰🇳',
    subregion: 'Caribbean',
    callingCode: ['1869'],
    code: 'KN',
    code3: 'KNA',
    paths: [],
  },
  KP: {
    flag: '🇰🇵',
    borders: ['CN', 'KR', 'RU'],
    inCountryList: ['UN'],
    name: 'North Korea',
    code: 'KP',
    languages: {
      kor: 'Korean',
    },
    codeN: '408',
    subregion: 'Eastern Asia',
    region: 'Asia',
    latlng: [40, 127],
    code3: 'PRK',
    capital: ['Pyongyang'],
    callingCode: ['850'],
    currency: ['KPW'],
    paths: [],
  },
  KR: {
    code3: 'KOR',
    callingCode: ['82'],
    region: 'Asia',
    latlng: [37, 127.5],
    flag: '🇰🇷',
    codeN: '410',
    code: 'KR',
    capital: ['Seoul'],
    currency: ['KRW'],
    borders: ['KP'],
    subregion: 'Eastern Asia',
    languages: {
      kor: 'Korean',
    },
    name: 'South Korea',
    inCountryList: ['UN'],
    paths: [],
  },
  KW: {
    languages: {
      ara: 'Arabic',
    },
    inCountryList: ['UN'],
    latlng: [29.5, 45.75],
    code: 'KW',
    name: 'Kuwait',
    code3: 'KWT',
    flag: '🇰🇼',
    region: 'Asia',
    callingCode: ['965'],
    codeN: '414',
    currency: ['KWD'],
    capital: ['Kuwait City'],
    borders: ['IQ', 'SA'],
    subregion: 'Western Asia',
    paths: [],
  },
  KY: {
    flag: '🇰🇾',
    name: 'Cayman Islands',
    code: 'KY',
    region: 'Americas',
    paths: [],
  },
  KZ: {
    latlng: [48, 68],
    currency: ['KZT'],
    callingCode: ['76', '77'],
    code3: 'KAZ',
    subregion: 'Central Asia',
    borders: ['CN', 'KG', 'RU', 'TM', 'UZ'],
    codeN: '398',
    capital: ['Astana'],
    languages: {
      rus: 'Russian',
      kaz: 'Kazakh',
    },
    name: 'Kazakhstan',
    region: 'Asia',
    code: 'KZ',
    inCountryList: ['UN'],
    flag: '🇰🇿',
    paths: [],
  },
  LA: {
    currency: ['LAK'],
    latlng: [18, 105],
    region: 'Asia',
    code: 'LA',
    codeN: '418',
    flag: '🇱🇦',
    borders: ['MM', 'KH', 'CN', 'TH', 'VN'],
    capital: ['Vientiane'],
    callingCode: ['856'],
    code3: 'LAO',
    languages: {
      lao: 'Lao',
    },
    subregion: 'South-Eastern Asia',
    name: 'Laos',
    inCountryList: ['UN'],
    paths: [],
  },
  LB: {
    callingCode: ['961'],
    region: 'Asia',
    code: 'LB',
    capital: ['Beirut'],
    inCountryList: ['UN'],
    code3: 'LBN',
    languages: {
      ara: 'Arabic',
      fra: 'French',
    },
    flag: '🇱🇧',
    latlng: [33.83333333, 35.83333333],
    name: 'Lebanon',
    subregion: 'Western Asia',
    borders: ['IL', 'SY'],
    currency: ['LBP'],
    codeN: '422',
    paths: [],
  },
  LC: {
    currency: ['XCD'],
    inCountryList: ['UN'],
    name: 'Saint Lucia',
    languages: {
      eng: 'English',
    },
    code3: 'LCA',
    flag: '🇱🇨',
    code: 'LC',
    subregion: 'Caribbean',
    latlng: [13.88333333, -60.96666666],
    borders: [],
    codeN: '662',
    region: 'Americas',
    callingCode: ['1758'],
    capital: ['Castries'],
    paths: [],
  },
  LI: {
    code: 'LI',
    region: 'Europe',
    inCountryList: ['UN'],
    codeN: '438',
    currency: ['CHF'],
    callingCode: ['423'],
    flag: '🇱🇮',
    borders: ['AT', 'CH'],
    name: 'Liechtenstein',
    languages: {
      deu: 'German',
    },
    code3: 'LIE',
    capital: ['Vaduz'],
    subregion: 'Western Europe',
    latlng: [47.26666666, 9.53333333],
    paths: [],
  },
  LK: {
    latlng: [7, 81],
    capital: ['Colombo'],
    code: 'LK',
    inCountryList: ['UN'],
    subregion: 'Southern Asia',
    callingCode: ['94'],
    name: 'Sri Lanka',
    codeN: '144',
    languages: {
      tam: 'Tamil',
      sin: 'Sinhala',
    },
    region: 'Asia',
    borders: ['IN'],
    flag: '🇱🇰',
    code3: 'LKA',
    currency: ['LKR'],
    paths: [],
  },
  LR: {
    inCountryList: ['UN'],
    code: 'LR',
    name: 'Liberia',
    flag: '🇱🇷',
    languages: {
      eng: 'English',
    },
    subregion: 'Western Africa',
    code3: 'LBR',
    latlng: [6.5, -9.5],
    codeN: '430',
    currency: ['LRD'],
    capital: ['Monrovia'],
    borders: ['GN', 'CI', 'SL'],
    callingCode: ['231'],
    region: 'Africa',
    paths: [],
  },
  LS: {
    inCountryList: ['UN'],
    flag: '🇱🇸',
    codeN: '426',
    code3: 'LSO',
    capital: ['Maseru'],
    region: 'Africa',
    name: 'Lesotho',
    subregion: 'Southern Africa',
    latlng: [-29.5, 28.5],
    callingCode: ['266'],
    borders: ['ZA'],
    code: 'LS',
    currency: ['LSL', 'ZAR'],
    languages: {
      sot: 'Sotho',
      eng: 'English',
    },
    paths: [],
  },
  LT: {
    languages: {
      lit: 'Lithuanian',
    },
    code: 'LT',
    code3: 'LTU',
    borders: ['BY', 'LV', 'PL', 'RU'],
    inCountryList: ['UN'],
    name: 'Lithuania',
    capital: ['Vilnius'],
    flag: '🇱🇹',
    codeN: '440',
    subregion: 'Northern Europe',
    latlng: [56, 24],
    region: 'Europe',
    callingCode: ['370'],
    currency: ['EUR'],
    paths: [],
  },
  LU: {
    borders: ['BE', 'FR', 'DE'],
    capital: ['Luxembourg'],
    name: 'Luxembourg',
    currency: ['EUR'],
    languages: {
      deu: 'German',
      ltz: 'Luxembourgish',
      fra: 'French',
    },
    inCountryList: ['UN'],
    callingCode: ['352'],
    subregion: 'Western Europe',
    code3: 'LUX',
    code: 'LU',
    codeN: '442',
    latlng: [49.75, 6.16666666],
    region: 'Europe',
    flag: '🇱🇺',
    paths: [],
  },
  LV: {
    capital: ['Riga'],
    region: 'Europe',
    flag: '🇱🇻',
    borders: ['BY', 'EE', 'LT', 'RU'],
    subregion: 'Northern Europe',
    latlng: [57, 25],
    codeN: '428',
    code: 'LV',
    inCountryList: ['UN'],
    name: 'Latvia',
    languages: {
      lav: 'Latvian',
    },
    callingCode: ['371'],
    currency: ['EUR'],
    code3: 'LVA',
    paths: [],
  },
  LY: {
    languages: {
      ara: 'Arabic',
    },
    capital: ['Tripoli'],
    inCountryList: ['UN'],
    callingCode: ['218'],
    code: 'LY',
    latlng: [25, 17],
    borders: ['DZ', 'TD', 'EG', 'NE', 'SD', 'TN'],
    flag: '🇱🇾',
    currency: ['LYD'],
    region: 'Africa',
    name: 'Libya',
    code3: 'LBY',
    subregion: 'Northern Africa',
    codeN: '434',
    paths: [],
  },
  MA: {
    subregion: 'Northern Africa',
    languages: {
      ber: 'Berber',
      ara: 'Arabic',
    },
    flag: '🇲🇦',
    currency: ['MAD'],
    region: 'Africa',
    codeN: '504',
    latlng: [32, -5],
    code: 'MA',
    callingCode: ['212'],
    borders: ['DZ', 'EH', 'ES'],
    inCountryList: ['UN'],
    name: 'Morocco',
    capital: ['Rabat'],
    code3: 'MAR',
    paths: [],
  },
  MC: {
    flag: '🇲🇨',
    code3: 'MCO',
    latlng: [43.73333333, 7.4],
    languages: {
      fra: 'French',
    },
    capital: ['Monaco'],
    codeN: '492',
    subregion: 'Western Europe',
    borders: ['FR'],
    callingCode: ['377'],
    code: 'MC',
    region: 'Europe',
    name: 'Monaco',
    currency: ['EUR'],
    inCountryList: ['UN'],
    paths: [],
  },
  MD: {
    name: 'Moldova',
    inCountryList: ['UN'],
    code: 'MD',
    subregion: 'Eastern Europe',
    code3: 'MDA',
    callingCode: ['373'],
    region: 'Europe',
    latlng: [47, 29],
    borders: ['RO', 'UA'],
    capital: ['Chișinău'],
    languages: {
      ron: 'Moldavian',
    },
    currency: ['MDL'],
    codeN: '498',
    flag: '🇲🇩',
    paths: [],
  },
  ME: {
    callingCode: ['382'],
    flag: '🇲🇪',
    code: 'ME',
    languages: {
      srp: 'Montenegrin',
    },
    capital: ['Podgorica'],
    subregion: 'Southern Europe',
    code3: 'MNE',
    region: 'Europe',
    currency: ['EUR'],
    latlng: [42.5, 19.3],
    inCountryList: ['UN'],
    codeN: '499',
    borders: ['AL', 'BA', 'HR', 'RS'],
    name: 'Montenegro',
    paths: [],
  },
  MF: {
    region: 'Americas',
    name: 'Saint Martin',
    flag: '🇲🇫',
    code: 'MF',
    paths: [],
  },
  MG: {
    callingCode: ['261'],
    code3: 'MDG',
    currency: ['MGA'],
    borders: [],
    flag: '🇲🇬',
    codeN: '450',
    subregion: 'Eastern Africa',
    capital: ['Antananarivo'],
    name: 'Madagascar',
    latlng: [-20, 47],
    inCountryList: ['UN'],
    languages: {
      fra: 'French',
      mlg: 'Malagasy',
    },
    code: 'MG',
    region: 'Africa',
    paths: [],
  },
  MH: {
    region: 'Oceania',
    flag: '🇲🇭',
    code3: 'MHL',
    borders: [],
    name: 'Marshall Islands',
    languages: {
      mah: 'Marshallese',
      eng: 'English',
    },
    codeN: '584',
    subregion: 'Micronesia',
    currency: ['USD'],
    code: 'MH',
    callingCode: ['692'],
    inCountryList: ['UN'],
    capital: ['Majuro'],
    latlng: [9, 168],
    paths: [],
  },
  MK: {
    borders: ['AL', 'BG', 'GR', 'RS'],
    code: 'MK',
    flag: '🇲🇰',
    currency: ['MKD'],
    callingCode: ['389'],
    subregion: 'Southern Europe',
    languages: {
      mkd: 'Macedonian',
    },
    region: 'Europe',
    latlng: [41.83333333, 22],
    capital: ['Skopje'],
    name: 'Macedonia',
    inCountryList: ['UN'],
    codeN: '807',
    code3: 'MKD',
    paths: [],
  },
  ML: {
    callingCode: ['223'],
    borders: ['DZ', 'BF', 'GN', 'CI', 'MR', 'NE', 'SN'],
    capital: ['Bamako'],
    languages: {
      fra: 'French',
    },
    currency: ['XOF'],
    inCountryList: ['UN'],
    name: 'Mali',
    code3: 'MLI',
    region: 'Africa',
    code: 'ML',
    subregion: 'Western Africa',
    latlng: [17, -4],
    codeN: '466',
    flag: '🇲🇱',
    paths: [],
  },
  MM: {
    callingCode: ['95'],
    borders: ['BD', 'CN', 'IN', 'LA', 'TH'],
    code: 'MM',
    subregion: 'South-Eastern Asia',
    code3: 'MMR',
    codeN: '104',
    name: 'Myanmar',
    inCountryList: ['UN'],
    latlng: [22, 98],
    currency: ['MMK'],
    languages: {
      mya: 'Burmese',
    },
    capital: ['Naypyidaw'],
    flag: '🇲🇲',
    region: 'Asia',
    paths: [],
  },
  MN: {
    capital: ['Ulan Bator'],
    callingCode: ['976'],
    name: 'Mongolia',
    flag: '🇲🇳',
    codeN: '496',
    region: 'Asia',
    latlng: [46, 105],
    borders: ['CN', 'RU'],
    languages: {
      mon: 'Mongolian',
    },
    code: 'MN',
    code3: 'MNG',
    inCountryList: ['UN'],
    subregion: 'Eastern Asia',
    currency: ['MNT'],
    paths: [],
  },
  MO: {
    languages: {
      por: 'Portuguese',
      zho: 'Chinese',
    },
    code: 'MO',
    capital: ['Macau'],
    callingCode: ['853'],
    currency: ['MOP'],
    borders: ['CN'],
    flag: '🇲🇴',
    name: 'Macau',
    region: 'Asia',
    subregion: 'Eastern Asia',
    paths: [],
  },
  MQ: {
    name: 'Martinique',
    flag: '🇲🇶',
    code: 'MQ',
    region: 'Americas',
    paths: [],
  },
  MR: {
    inCountryList: ['UN'],
    latlng: [20, -12],
    region: 'Africa',
    borders: ['DZ', 'ML', 'SN', 'EH'],
    name: 'Mauritania',
    languages: {
      ara: 'Arabic',
    },
    code3: 'MRT',
    currency: ['MRO'],
    codeN: '478',
    code: 'MR',
    capital: ['Nouakchott'],
    subregion: 'Western Africa',
    flag: '🇲🇷',
    callingCode: ['222'],
    paths: [],
  },
  MT: {
    currency: ['EUR'],
    name: 'Malta',
    latlng: [35.83333333, 14.58333333],
    languages: {
      eng: 'English',
      mlt: 'Maltese',
    },
    codeN: '470',
    borders: [],
    callingCode: ['356'],
    region: 'Europe',
    code: 'MT',
    code3: 'MLT',
    capital: ['Valletta'],
    inCountryList: ['UN'],
    flag: '🇲🇹',
    subregion: 'Southern Europe',
    paths: [],
  },
  MU: {
    latlng: [-20.28333333, 57.55],
    inCountryList: ['UN'],
    currency: ['MUR'],
    region: 'Africa',
    name: 'Mauritius',
    flag: '🇲🇺',
    callingCode: ['230'],
    languages: {
      fra: 'French',
      mfe: 'Mauritian Creole',
      eng: 'English',
    },
    codeN: '480',
    capital: ['Port Louis'],
    code: 'MU',
    subregion: 'Eastern Africa',
    code3: 'MUS',
    borders: [],
    paths: [],
  },
  MV: {
    inCountryList: ['UN'],
    flag: '🇲🇻',
    latlng: [3.25, 73],
    region: 'Asia',
    capital: ['Malé'],
    code3: 'MDV',
    code: 'MV',
    codeN: '462',
    subregion: 'Southern Asia',
    borders: [],
    languages: {
      div: 'Maldivian',
    },
    currency: ['MVR'],
    callingCode: ['960'],
    name: 'Maldives',
    paths: [],
  },
  MW: {
    currency: ['MWK'],
    code3: 'MWI',
    code: 'MW',
    latlng: [-13.5, 34],
    name: 'Malawi',
    capital: ['Lilongwe'],
    subregion: 'Eastern Africa',
    callingCode: ['265'],
    flag: '🇲🇼',
    codeN: '454',
    languages: {
      eng: 'English',
      nya: 'Chewa',
    },
    inCountryList: ['UN'],
    borders: ['MZ', 'TZ', 'ZM'],
    region: 'Africa',
    paths: [],
  },
  MX: {
    region: 'Americas',
    borders: ['BZ', 'GT', 'US'],
    code: 'MX',
    inCountryList: ['UN'],
    latlng: [23, -102],
    codeN: '484',
    callingCode: ['52'],
    languages: {
      spa: 'Spanish',
    },
    capital: ['Mexico City'],
    code3: 'MEX',
    currency: ['MXN'],
    flag: '🇲🇽',
    name: 'Mexico',
    subregion: 'North America',
    paths: [],
  },
  MY: {
    subregion: 'South-Eastern Asia',
    callingCode: ['60'],
    currency: ['MYR'],
    latlng: [2.5, 112.5],
    capital: ['Kuala Lumpur'],
    inCountryList: ['UN'],
    code: 'MY',
    flag: '🇲🇾',
    codeN: '458',
    region: 'Asia',
    code3: 'MYS',
    languages: {
      msa: 'Malay',
      eng: 'English',
    },
    name: 'Malaysia',
    borders: ['BN', 'ID', 'TH'],
    paths: [],
  },
  MZ: {
    flag: '🇲🇿',
    callingCode: ['258'],
    code3: 'MOZ',
    codeN: '508',
    region: 'Africa',
    latlng: [-18.25, 35],
    borders: ['MW', 'ZA', 'SZ', 'TZ', 'ZM', 'ZW'],
    currency: ['MZN'],
    subregion: 'Eastern Africa',
    name: 'Mozambique',
    capital: ['Maputo'],
    code: 'MZ',
    inCountryList: ['UN'],
    languages: {
      por: 'Portuguese',
    },
    paths: [],
  },
  MS: {
    name: 'Montserrat',
    subregion: 'Caribbean',
    code: 'MS',
    flag: '🇲🇸',
    region: 'Americas',
    paths: [],
  },
  NA: {
    code3: 'NAM',
    flag: '🇳🇦',
    inCountryList: ['UN'],
    borders: ['AO', 'BW', 'ZA', 'ZM'],
    callingCode: ['264'],
    region: 'Africa',
    languages: {
      loz: 'Lozi',
      ndo: 'Ndonga',
      kwn: 'Kwangali',
      hgm: 'Khoekhoe',
      deu: 'German',
      tsn: 'Tswana',
      eng: 'English',
      afr: 'Afrikaans',
      her: 'Herero',
    },
    currency: ['NAD', 'ZAR'],
    codeN: '516',
    capital: ['Windhoek'],
    latlng: [-22, 17],
    code: 'NA',
    subregion: 'Southern Africa',
    name: 'Namibia',
    paths: [],
  },
  NC: {
    callingCode: ['687'],
    capital: ['Nouméa'],
    latlng: [-21.5, 165.5],
    flag: '🇳🇨',
    name: 'New Caledonia',
    languages: {
      fra: 'French',
    },
    code3: 'NCL',
    currency: ['XPF'],
    subregion: 'Melanesia',
    borders: [],
    inCountryList: ['UN'],
    region: 'Oceania',
    code: 'NC',
    codeN: '540',
    paths: [],
  },
  NE: {
    name: 'Niger',
    currency: ['XOF'],
    flag: '🇳🇪',
    capital: ['Niamey'],
    languages: {
      fra: 'French',
    },
    region: 'Africa',
    borders: ['DZ', 'BJ', 'BF', 'TD', 'LY', 'ML', 'NG'],
    code3: 'NER',
    callingCode: ['227'],
    subregion: 'Western Africa',
    inCountryList: ['UN'],
    latlng: [16, 8],
    codeN: '562',
    code: 'NE',
    paths: [],
  },
  NG: {
    flag: '🇳🇬',
    subregion: 'Western Africa',
    region: 'Africa',
    languages: {
      eng: 'English',
    },
    capital: ['Abuja'],
    currency: ['NGN'],
    name: 'Nigeria',
    borders: ['BJ', 'CM', 'TD', 'NE'],
    codeN: '566',
    code: 'NG',
    code3: 'NGA',
    inCountryList: ['UN'],
    latlng: [10, 8],
    callingCode: ['234'],
    paths: [],
  },
  NI: {
    code: 'NI',
    flag: '🇳🇮',
    languages: {
      spa: 'Spanish',
    },
    currency: ['NIO'],
    inCountryList: ['UN'],
    latlng: [13, -85],
    name: 'Nicaragua',
    code3: 'NIC',
    region: 'Americas',
    codeN: '558',
    subregion: 'Central America',
    callingCode: ['505'],
    borders: ['CR', 'HN'],
    capital: ['Managua'],
    paths: [],
  },
  NL: {
    name: 'Netherlands',
    codeN: '528',
    languages: {
      nld: 'Dutch',
    },
    flag: '🇳🇱',
    code: 'NL',
    borders: ['BE', 'DE'],
    callingCode: ['31'],
    code3: 'NLD',
    subregion: 'Western Europe',
    currency: ['EUR'],
    latlng: [52.5, 5.75],
    capital: ['Amsterdam'],
    inCountryList: ['UN'],
    region: 'Europe',
    paths: [],
  },
  NO: {
    capital: ['Oslo'],
    code: 'NO',
    code3: 'NOR',
    latlng: [62, 10],
    region: 'Europe',
    flag: '🇳🇴',
    subregion: 'Northern Europe',
    callingCode: ['47'],
    codeN: '578',
    languages: {
      nob: 'Norwegian Bokmål',
      nno: 'Norwegian Nynorsk',
      smi: 'Sami',
    },
    currency: ['NOK'],
    borders: ['FI', 'SE', 'RU'],
    inCountryList: ['UN'],
    name: 'Norway',
    paths: [],
  },
  NP: {
    languages: {
      nep: 'Nepali',
    },
    subregion: 'Southern Asia',
    capital: ['Kathmandu'],
    region: 'Asia',
    code: 'NP',
    borders: ['CN', 'IN'],
    codeN: '524',
    name: 'Nepal',
    callingCode: ['977'],
    flag: '🇳🇵',
    currency: ['NPR'],
    code3: 'NPL',
    inCountryList: ['UN'],
    latlng: [28, 84],
    paths: [],
  },
  NR: {
    borders: [],
    region: 'Oceania',
    currency: ['AUD'],
    codeN: '520',
    name: 'Nauru',
    code3: 'NRU',
    callingCode: ['674'],
    capital: ['Yaren'],
    subregion: 'Micronesia',
    inCountryList: ['UN'],
    latlng: [-0.53333333, 166.91666666],
    languages: {
      nau: 'Nauru',
      eng: 'English',
    },
    flag: '🇳🇷',
    code: 'NR',
    paths: [],
  },
  NU: {
    borders: [],
    capital: ['Alofi'],
    inCountryList: ['NewZeland'],
    callingCode: ['683'],
    languages: {
      eng: 'English',
      niu: 'Niuean',
    },
    codeN: '570',
    code3: 'NIU',
    subregion: 'Australia and New Zealand',
    latlng: [-19, -170],
    flag: '🇳🇺',
    name: 'Niue',
    code: 'NU',
    region: 'Oceania',
    currency: ['NZD'],
    paths: [],
  },
  NZ: {
    capital: ['Wellington'],
    codeN: '554',
    inCountryList: ['UN'],
    flag: '🇳🇿',
    code3: 'NZL',
    latlng: [-41, 174],
    name: 'New Zealand',
    callingCode: ['64'],
    currency: ['NZD'],
    borders: [],
    languages: {
      mri: 'Māori',
      nzs: 'New Zealand Sign Language',
      eng: 'English',
    },
    code: 'NZ',
    subregion: 'Australia and New Zealand',
    region: 'Oceania',
    paths: [],
  },
  OM: {
    codeN: '512',
    code: 'OM',
    capital: ['Muscat'],
    code3: 'OMN',
    region: 'Asia',
    latlng: [21, 57],
    inCountryList: ['UN'],
    name: 'Oman',
    languages: {
      ara: 'Arabic',
    },
    flag: '🇴🇲',
    callingCode: ['968'],
    subregion: 'Western Asia',
    currency: ['OMR'],
    borders: ['SA', 'AE', 'YE'],
    paths: [],
  },
  PA: {
    codeN: '591',
    callingCode: ['507'],
    flag: '🇵🇦',
    region: 'Americas',
    inCountryList: ['UN'],
    subregion: 'Central America',
    latlng: [9, -80],
    languages: {
      spa: 'Spanish',
    },
    capital: ['Panama City'],
    name: 'Panama',
    borders: ['CO', 'CR'],
    code: 'PA',
    currency: ['PAB', 'USD'],
    code3: 'PAN',
    paths: [],
  },
  PE: {
    currency: ['PEN'],
    languages: {
      spa: 'Spanish',
      que: 'Quechua',
      aym: 'Aymara',
    },
    name: 'Peru',
    codeN: '604',
    inCountryList: ['UN'],
    flag: '🇵🇪',
    code: 'PE',
    capital: ['Lima'],
    code3: 'PER',
    callingCode: ['51'],
    borders: ['BO', 'BR', 'CL', 'CO', 'EC'],
    subregion: 'South America',
    latlng: [-10, -76],
    region: 'Americas',
    paths: [],
  },
  PF: {
    code: 'PF',
    region: 'Oceania',
    flag: '🇵🇫',
    name: 'French Polynesia',
    paths: [],
  },
  PG: {
    region: 'Oceania',
    flag: '🇵🇬',
    languages: {
      eng: 'English',
      tpi: 'Tok Pisin',
      hmo: 'Hiri Motu',
    },
    borders: ['ID'],
    capital: ['Port Moresby'],
    callingCode: ['675'],
    name: 'Papua New Guinea',
    codeN: '598',
    currency: ['PGK'],
    subregion: 'Melanesia',
    latlng: [-6, 147],
    inCountryList: ['UN'],
    code3: 'PNG',
    code: 'PG',
    paths: [],
  },
  PH: {
    currency: ['PHP'],
    flag: '🇵🇭',
    callingCode: ['63'],
    latlng: [13, 122],
    name: 'Philippines',
    region: 'Asia',
    codeN: '608',
    languages: {
      eng: 'English',
      fil: 'Filipino',
    },
    code: 'PH',
    subregion: 'South-Eastern Asia',
    borders: [],
    capital: ['Manila'],
    code3: 'PHL',
    inCountryList: ['UN'],
    paths: [],
  },
  PK: {
    name: 'Pakistan',
    codeN: '586',
    capital: ['Islamabad'],
    inCountryList: ['UN'],
    subregion: 'Southern Asia',
    latlng: [30, 70],
    region: 'Asia',
    languages: {
      eng: 'English',
      urd: 'Urdu',
    },
    borders: ['AF', 'CN', 'IN', 'IR'],
    flag: '🇵🇰',
    callingCode: ['92'],
    currency: ['PKR'],
    code: 'PK',
    code3: 'PAK',
    paths: [],
  },
  PL: {
    languages: {
      pol: 'Polish',
    },
    codeN: '616',
    capital: ['Warsaw'],
    currency: ['PLN'],
    code: 'PL',
    callingCode: ['48'],
    inCountryList: ['UN'],
    name: 'Poland',
    region: 'Europe',
    flag: '🇵🇱',
    subregion: 'Eastern Europe',
    latlng: [52, 20],
    code3: 'POL',
    borders: ['BY', 'CZ', 'DE', 'LT', 'RU', 'SK', 'UA'],
    paths: [],
  },
  PR: {
    languages: {
      spa: 'Spanish',
      eng: 'English',
    },
    inCountryList: ['UN'],
    name: 'Puerto Rico',
    code3: 'PRI',
    callingCode: ['1787', '1939'],
    borders: [],
    codeN: '630',
    flag: '🇵🇷',
    region: 'Americas',
    capital: ['San Juan'],
    subregion: 'Caribbean',
    currency: ['USD'],
    code: 'PR',
    latlng: [18.25, -66.5],
    paths: [],
  },
  PS: {
    latlng: [31.9, 35.2],
    code3: 'PSE',
    capital: ['Ramallah'],
    flag: '🇵🇸',
    borders: ['IL', 'EG', 'JO'],
    region: 'Asia',
    code: 'PS',
    codeN: '275',
    callingCode: ['970'],
    languages: {
      ara: 'Arabic',
    },
    subregion: 'Western Asia',
    currency: ['ILS'],
    name: 'Palestine',
    inCountryList: ['Observers'],
    paths: [],
  },
  PT: {
    latlng: [39.5, -8],
    currency: ['EUR'],
    code3: 'PRT',
    callingCode: ['351'],
    region: 'Europe',
    capital: ['Lisbon'],
    borders: ['ES'],
    code: 'PT',
    codeN: '620',
    flag: '🇵🇹',
    languages: {
      por: 'Portuguese',
    },
    inCountryList: ['UN'],
    subregion: 'Southern Europe',
    name: 'Portugal',
    paths: [],
  },
  PW: {
    borders: [],
    inCountryList: ['UN'],
    latlng: [7.5, 134.5],
    name: 'Palau',
    region: 'Oceania',
    flag: '🇵🇼',
    code: 'PW',
    currency: ['USD'],
    codeN: '585',
    callingCode: ['680'],
    languages: {
      eng: 'English',
      pau: 'Palauan',
    },
    subregion: 'Micronesia',
    capital: ['Ngerulmud'],
    code3: 'PLW',
    paths: [],
  },
  PY: {
    flag: '🇵🇾',
    inCountryList: ['UN'],
    currency: ['PYG'],
    borders: ['AR', 'BO', 'BR'],
    codeN: '600',
    name: 'Paraguay',
    code3: 'PRY',
    callingCode: ['595'],
    subregion: 'South America',
    code: 'PY',
    region: 'Americas',
    capital: ['Asunción'],
    latlng: [-23, -58],
    languages: {
      spa: 'Spanish',
      grn: 'Guaraní',
    },
    paths: [],
  },
  QA: {
    subregion: 'Western Asia',
    region: 'Asia',
    callingCode: ['974'],
    name: 'Qatar',
    currency: ['QAR'],
    borders: ['SA'],
    code3: 'QAT',
    code: 'QA',
    inCountryList: ['UN'],
    capital: ['Doha'],
    flag: '🇶🇦',
    latlng: [25.5, 51.25],
    codeN: '634',
    languages: {
      ara: 'Arabic',
    },
    paths: [],
  },
  RE: {
    flag: '🇷🇪',
    code: 'RE',
    name: 'Réunion',
    region: 'Africa',
    paths: [],
  },
  RO: {
    currency: ['RON'],
    capital: ['Bucharest'],
    flag: '🇷🇴',
    latlng: [46, 25],
    languages: {
      ron: 'Romanian',
    },
    callingCode: ['40'],
    code: 'RO',
    codeN: '642',
    name: 'Romania',
    region: 'Europe',
    borders: ['BG', 'HU', 'MD', 'RS', 'UA'],
    code3: 'ROU',
    inCountryList: ['UN'],
    subregion: 'Eastern Europe',
    paths: [],
  },
  RS: {
    code3: 'SRB',
    region: 'Europe',
    languages: {
      srp: 'Serbian',
    },
    subregion: 'Southern Europe',
    name: 'Serbia',
    latlng: [44, 21],
    flag: '🇷🇸',
    capital: ['Belgrade'],
    borders: ['BA', 'BG', 'HR', 'HU', 'MK', 'ME', 'RO'],
    currency: ['RSD'],
    codeN: '688',
    code: 'RS',
    inCountryList: ['UN'],
    callingCode: ['381'],
    paths: [],
  },
  RU: {
    flag: '🇷🇺',
    languages: {
      rus: 'Russian',
    },
    code: 'RU',
    subregion: 'Eastern Europe',
    latlng: [60, 100],
    capital: ['Moscow'],
    borders: [
      'AZ',
      'BY',
      'CN',
      'EE',
      'FI',
      'GE',
      'KZ',
      'KP',
      'LV',
      'LT',
      'MN',
      'NO',
      'PL',
      'UA',
    ],
    currency: ['RUB'],
    callingCode: ['7'],
    inCountryList: ['UN'],
    name: 'Russia',
    region: 'Europe',
    code3: 'RUS',
    codeN: '643',
    paths: [],
  },
  RW: {
    latlng: [-2, 30],
    capital: ['Kigali'],
    subregion: 'Eastern Africa',
    flag: '🇷🇼',
    codeN: '646',
    code: 'RW',
    callingCode: ['250'],
    name: 'Rwanda',
    currency: ['RWF'],
    region: 'Africa',
    inCountryList: ['UN'],
    code3: 'RWA',
    languages: {
      fra: 'French',
      kin: 'Kinyarwanda',
      eng: 'English',
    },
    borders: ['BI', 'CD', 'TZ', 'UG'],
    paths: [],
  },
  SA: {
    languages: {
      ara: 'Arabic',
    },
    currency: ['SAR'],
    code: 'SA',
    latlng: [25, 45],
    capital: ['Riyadh'],
    borders: ['IQ', 'JO', 'KW', 'OM', 'QA', 'AE', 'YE'],
    inCountryList: ['UN'],
    region: 'Asia',
    codeN: '682',
    callingCode: ['966'],
    code3: 'SAU',
    name: 'Saudi Arabia',
    subregion: 'Western Asia',
    flag: '🇸🇦',
    paths: [],
  },
  SB: {
    languages: {
      eng: 'English',
    },
    capital: ['Honiara'],
    inCountryList: ['UN'],
    currency: ['SBD'],
    code: 'SB',
    borders: [],
    subregion: 'Melanesia',
    codeN: '090',
    code3: 'SLB',
    flag: '🇸🇧',
    region: 'Oceania',
    latlng: [-8, 159],
    name: 'Solomon Islands',
    callingCode: ['677'],
    paths: [],
  },
  SC: {
    capital: ['Victoria'],
    inCountryList: ['UN'],
    codeN: '690',
    code3: 'SYC',
    callingCode: ['248'],
    borders: [],
    region: 'Africa',
    flag: '🇸🇨',
    languages: {
      crs: 'Seychellois Creole',
      fra: 'French',
      eng: 'English',
    },
    currency: ['SCR'],
    latlng: [-4.58333333, 55.66666666],
    subregion: 'Eastern Africa',
    code: 'SC',
    name: 'Seychelles',
    paths: [],
  },
  SD: {
    region: 'Africa',
    code3: 'SDN',
    capital: ['Khartoum'],
    subregion: 'Northern Africa',
    currency: ['SDG'],
    languages: {
      ara: 'Arabic',
      eng: 'English',
    },
    flag: '🇸🇩',
    inCountryList: ['UN'],
    code: 'SD',
    borders: ['CF', 'TD', 'EG', 'ER', 'ET', 'LY', 'SS'],
    callingCode: ['249'],
    name: 'Sudan',
    codeN: '729',
    latlng: [15, 30],
    paths: [],
  },
  SE: {
    code3: 'SWE',
    subregion: 'Northern Europe',
    currency: ['SEK'],
    code: 'SE',
    name: 'Sweden',
    borders: ['FI', 'NO'],
    capital: ['Stockholm'],
    region: 'Europe',
    codeN: '752',
    callingCode: ['46'],
    languages: {
      swe: 'Swedish',
    },
    latlng: [62, 15],
    inCountryList: ['UN'],
    flag: '🇸🇪',
    paths: [],
  },
  SG: {
    inCountryList: ['UN'],
    name: 'Singapore',
    callingCode: ['65'],
    currency: ['SGD'],
    code3: 'SGP',
    code: 'SG',
    borders: [],
    languages: {
      zho: 'Chinese',
      eng: 'English',
      msa: 'Malay',
      tam: 'Tamil',
    },
    region: 'Asia',
    subregion: 'South-Eastern Asia',
    latlng: [1.36666666, 103.8],
    capital: ['Singapore'],
    flag: '🇸🇬',
    codeN: '702',
    paths: [],
  },
  SH: {
    region: 'Europe',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    code: 'SH',
    flag: '🇬🇧',
    paths: [],
  },
  SI: {
    borders: ['AT', 'HR', 'IT', 'HU'],
    capital: ['Ljubljana'],
    code: 'SI',
    currency: ['EUR'],
    flag: '🇸🇮',
    callingCode: ['386'],
    code3: 'SVN',
    codeN: '705',
    languages: {
      slv: 'Slovene',
    },
    inCountryList: ['UN'],
    latlng: [46.11666666, 14.81666666],
    subregion: 'Southern Europe',
    region: 'Europe',
    name: 'Slovenia',
    paths: [],
  },
  SJ: {
    name: 'Svalbard and Jan Mayen',
    subregion: 'Northern Europe',
    code: 'SJ',
    borders: [''],
    callingCode: '47',
    region: 'Europe',
    flag: '🇳🇴',
    paths: [],
  },
  SK: {
    latlng: [48.66666666, 19.5],
    languages: {
      slk: 'Slovak',
    },
    flag: '🇸🇰',
    name: 'Slovakia',
    currency: ['EUR'],
    code: 'SK',
    code3: 'SVK',
    inCountryList: ['UN'],
    codeN: '703',
    callingCode: ['421'],
    region: 'Europe',
    capital: ['Bratislava'],
    borders: ['AT', 'CZ', 'HU', 'PL', 'UA'],
    subregion: 'Central Europe',
    paths: [],
  },
  SL: {
    flag: '🇸🇱',
    currency: ['SLL'],
    latlng: [8.5, -11.5],
    code: 'SL',
    subregion: 'Western Africa',
    capital: ['Freetown'],
    region: 'Africa',
    codeN: '694',
    inCountryList: ['UN'],
    name: 'Sierra Leone',
    code3: 'SLE',
    callingCode: ['232'],
    borders: ['GN', 'LR'],
    languages: {
      eng: 'English',
    },
    paths: [],
  },
  SM: {
    subregion: 'Southern Europe',
    currency: ['EUR'],
    latlng: [43.76666666, 12.41666666],
    callingCode: ['378'],
    code: 'SM',
    inCountryList: ['UN'],
    languages: {
      ita: 'Italian',
    },
    codeN: '674',
    borders: ['IT'],
    region: 'Europe',
    flag: '🇸🇲',
    name: 'San Marino',
    code3: 'SMR',
    capital: ['City of San Marino'],
    paths: [],
  },
  SN: {
    name: 'Senegal',
    latlng: [14, -14],
    subregion: 'Western Africa',
    code3: 'SEN',
    callingCode: ['221'],
    borders: ['GM', 'GN', 'GW', 'ML', 'MR'],
    capital: ['Dakar'],
    currency: ['XOF'],
    code: 'SN',
    languages: {
      fra: 'French',
    },
    flag: '🇸🇳',
    codeN: '686',
    region: 'Africa',
    inCountryList: ['UN'],
    paths: [],
  },
  SO: {
    flag: '🇸🇴',
    languages: {
      ara: 'Arabic',
      som: 'Somali',
    },
    callingCode: ['252'],
    borders: ['DJ', 'ET', 'KE'],
    codeN: '706',
    inCountryList: ['UN'],
    code: 'SO',
    latlng: [10, 49],
    capital: ['Mogadishu'],
    subregion: 'Eastern Africa',
    code3: 'SOM',
    region: 'Africa',
    name: 'Somalia',
    currency: ['SOS'],
    paths: [],
  },
  SR: {
    code: 'SR',
    code3: 'SUR',
    region: 'Americas',
    callingCode: ['597'],
    currency: ['SRD'],
    subregion: 'South America',
    borders: ['BR', 'GF', 'GY'],
    latlng: [4, -56],
    name: 'Suriname',
    languages: {
      nld: 'Dutch',
    },
    capital: ['Paramaribo'],
    inCountryList: ['UN'],
    flag: '🇸🇷',
    codeN: '740',
    paths: [],
  },
  SS: {
    codeN: '728',
    subregion: 'Middle Africa',
    latlng: [7, 30],
    code: 'SS',
    capital: ['Juba'],
    borders: ['CF', 'CD', 'ET', 'KE', 'SD', 'UG'],
    name: 'South Sudan',
    flag: '🇸🇸',
    languages: {
      eng: 'English',
    },
    inCountryList: ['UN'],
    callingCode: ['211'],
    code3: 'SSD',
    region: 'Africa',
    currency: ['SSP'],
    paths: [],
  },
  ST: {
    inCountryList: ['UN'],
    code: 'ST',
    region: 'Africa',
    subregion: 'Middle Africa',
    codeN: '678',
    code3: 'STP',
    languages: {
      por: 'Portuguese',
    },
    currency: ['STD'],
    latlng: [1, 7],
    capital: ['São Tomé'],
    callingCode: ['239'],
    name: 'São Tomé and Príncipe',
    borders: [],
    flag: '🇸🇹',
    paths: [],
  },
  SV: {
    code: 'SV',
    name: 'El Salvador',
    capital: ['San Salvador'],
    flag: '🇸🇻',
    callingCode: ['503'],
    languages: {
      spa: 'Spanish',
    },
    subregion: 'Central America',
    code3: 'SLV',
    currency: ['SVC', 'USD'],
    codeN: '222',
    latlng: [13.83333333, -88.91666666],
    inCountryList: ['UN'],
    region: 'Americas',
    borders: ['GT', 'HN'],
    paths: [],
  },
  SX: {
    name: 'Sint Maarten',
    region: 'Americas',
    flag: '🇸🇽',
    code: 'SX',
    paths: [],
  },
  SY: {
    region: 'Asia',
    name: 'Syria',
    currency: ['SYP'],
    capital: ['Damascus'],
    code3: 'SYR',
    codeN: '760',
    inCountryList: ['UN'],
    subregion: 'Western Asia',
    latlng: [35, 38],
    borders: ['IQ', 'IL', 'JO', 'LB', 'TR'],
    code: 'SY',
    languages: {
      ara: 'Arabic',
    },
    callingCode: ['963'],
    flag: '🇸🇾',
    paths: [],
  },
  SZ: {
    flag: '🇸🇿',
    code: 'SZ',
    languages: {
      ssw: 'Swazi',
      eng: 'English',
    },
    codeN: '748',
    capital: ['Lobamba'],
    callingCode: ['268'],
    inCountryList: ['UN'],
    code3: 'SWZ',
    latlng: [-26.5, 31.5],
    currency: ['SZL'],
    region: 'Africa',
    subregion: 'Southern Africa',
    borders: ['MZ', 'ZA'],
    name: 'Swaziland',
    paths: [],
  },
  TC: {
    region: 'Americas',
    code: 'TC',
    flag: '🇹🇨',
    subregion: 'Caribbean',
    name: 'Turks and Caicos Islands',
    paths: [],
  },
  TD: {
    code3: 'TCD',
    capital: ["N'Djamena"],
    borders: ['CM', 'CF', 'LY', 'NE', 'NG', 'SD'],
    name: 'Chad',
    callingCode: ['235'],
    currency: ['XAF'],
    latlng: [15, 19],
    code: 'TD',
    codeN: '148',
    flag: '🇹🇩',
    region: 'Africa',
    subregion: 'Middle Africa',
    languages: {
      fra: 'French',
      ara: 'Arabic',
    },
    inCountryList: ['UN'],
    paths: [],
  },
  TG: {
    inCountryList: ['UN'],
    flag: '🇹🇬',
    subregion: 'Western Africa',
    code: 'TG',
    code3: 'TGO',
    borders: ['BJ', 'BF', 'GH'],
    region: 'Africa',
    languages: {
      fra: 'French',
    },
    currency: ['XOF'],
    codeN: '768',
    callingCode: ['228'],
    capital: ['Lomé'],
    latlng: [8, 1.16666666],
    name: 'Togo',
    paths: [],
  },
  TH: {
    flag: '🇹🇭',
    subregion: 'South-Eastern Asia',
    region: 'Asia',
    name: 'Thailand',
    code3: 'THA',
    codeN: '764',
    code: 'TH',
    capital: ['Bangkok'],
    languages: {
      tha: 'Thai',
    },
    borders: ['MM', 'KH', 'LA', 'MY'],
    callingCode: ['66'],
    currency: ['THB'],
    latlng: [15, 100],
    inCountryList: ['UN'],
    paths: [],
  },
  TJ: {
    capital: ['Dushanbe'],
    region: 'Asia',
    latlng: [39, 71],
    name: 'Tajikistan',
    subregion: 'Central Asia',
    languages: {
      tgk: 'Tajik',
      rus: 'Russian',
    },
    codeN: '762',
    borders: ['AF', 'CN', 'KG', 'UZ'],
    inCountryList: ['UN'],
    flag: '🇹🇯',
    code3: 'TJK',
    code: 'TJ',
    currency: ['TJS'],
    callingCode: ['992'],
    paths: [],
  },
  TL: {
    subregion: 'South-Eastern Asia',
    latlng: [-8.83333333, 125.91666666],
    callingCode: ['670'],
    borders: ['ID'],
    capital: ['Dili'],
    code3: 'TLS',
    region: 'Asia',
    code: 'TL',
    languages: {
      tet: 'Tetum',
      por: 'Portuguese',
    },
    currency: ['USD'],
    codeN: '626',
    name: 'Timor-Leste',
    flag: '🇹🇱',
    inCountryList: ['UN'],
    paths: [],
  },
  TM: {
    flag: '🇹🇲',
    region: 'Asia',
    inCountryList: ['UN'],
    code3: 'TKM',
    subregion: 'Central Asia',
    currency: ['TMT'],
    codeN: '795',
    name: 'Turkmenistan',
    latlng: [40, 60],
    languages: {
      rus: 'Russian',
      tuk: 'Turkmen',
    },
    callingCode: ['993'],
    capital: ['Ashgabat'],
    borders: ['AF', 'IR', 'KZ', 'UZ'],
    code: 'TM',
    paths: [],
  },
  TN: {
    latlng: [34, 9],
    borders: ['DZ', 'LY'],
    code3: 'TUN',
    capital: ['Tunis'],
    code: 'TN',
    inCountryList: ['UN'],
    currency: ['TND'],
    region: 'Africa',
    flag: '🇹🇳',
    name: 'Tunisia',
    subregion: 'Northern Africa',
    callingCode: ['216'],
    languages: {
      ara: 'Arabic',
    },
    codeN: '788',
    paths: [],
  },
  TO: {
    code3: 'TON',
    borders: [],
    name: 'Tonga',
    code: 'TO',
    latlng: [-20, -175],
    currency: ['TOP'],
    callingCode: ['676'],
    flag: '🇹🇴',
    subregion: 'Polynesia',
    inCountryList: ['UN'],
    region: 'Oceania',
    languages: {
      eng: 'English',
      ton: 'Tongan',
    },
    capital: ["Nuku'alofa"],
    codeN: '776',
    paths: [],
  },
  TR: {
    subregion: 'Western Asia',
    inCountryList: ['UN'],
    callingCode: ['90'],
    currency: ['TRY'],
    region: 'Asia',
    latlng: [39, 35],
    code3: 'TUR',
    borders: ['AM', 'AZ', 'BG', 'GE', 'GR', 'IR', 'IQ', 'SY'],
    flag: '🇹🇷',
    capital: ['Ankara'],
    languages: {
      tur: 'Turkish',
    },
    name: 'Turkey',
    code: 'TR',
    codeN: '792',
    paths: [],
  },
  TT: {
    callingCode: ['1868'],
    borders: [],
    code: 'TT',
    name: 'Trinidad and Tobago',
    languages: {
      eng: 'English',
    },
    capital: ['Port of Spain'],
    region: 'Americas',
    codeN: '780',
    flag: '🇹🇹',
    inCountryList: ['UN'],
    code3: 'TTO',
    currency: ['TTD'],
    latlng: [11, -61],
    subregion: 'Caribbean',
    paths: [],
  },
  TV: {
    flag: '🇹🇻',
    languages: {
      tvl: 'Tuvaluan',
      eng: 'English',
    },
    borders: [],
    code3: 'TUV',
    subregion: 'Polynesia',
    codeN: '798',
    code: 'TV',
    name: 'Tuvalu',
    currency: ['AUD'],
    callingCode: ['688'],
    inCountryList: ['UN'],
    region: 'Oceania',
    latlng: [-8, 178],
    capital: ['Funafuti'],
    paths: [],
  },
  TW: {
    subregion: 'Eastern Asia',
    latlng: [23.5, 121],
    codeN: '158',
    languages: {
      zho: 'Chinese',
    },
    capital: ['Taipei'],
    currency: ['TWD'],
    name: 'Taiwan',
    code: 'TW',
    region: 'Asia',
    callingCode: ['886'],
    inCountryList: ['UN'],
    borders: [],
    flag: '🇹🇼',
    code3: 'TWN',
    paths: [],
  },
  TZ: {
    subregion: 'Eastern Africa',
    name: 'Tanzania',
    latlng: [-6, 35],
    callingCode: ['255'],
    code: 'TZ',
    languages: {
      swa: 'Swahili',
      eng: 'English',
    },
    code3: 'TZA',
    region: 'Africa',
    codeN: '834',
    capital: ['Dodoma'],
    flag: '🇹🇿',
    currency: ['TZS'],
    borders: ['BI', 'CD', 'KE', 'MW', 'MZ', 'RW', 'UG', 'ZM'],
    inCountryList: ['UN'],
    paths: [],
  },
  UA: {
    flag: '🇺🇦',
    code3: 'UKR',
    callingCode: ['380'],
    currency: ['UAH'],
    inCountryList: ['UN'],
    code: 'UA',
    borders: ['BY', 'HU', 'MD', 'PL', 'RO', 'RU', 'SK'],
    name: 'Ukraine',
    languages: {
      ukr: 'Ukrainian',
    },
    latlng: [49, 32],
    subregion: 'Eastern Europe',
    codeN: '804',
    region: 'Europe',
    capital: ['Kyiv'],
    paths: [],
  },
  UG: {
    subregion: 'Eastern Africa',
    code: 'UG',
    latlng: [1, 32],
    languages: {
      swa: 'Swahili',
      eng: 'English',
    },
    capital: ['Kampala'],
    inCountryList: ['UN'],
    callingCode: ['256'],
    codeN: '800',
    borders: ['CD', 'KE', 'RW', 'SS', 'TZ'],
    code3: 'UGA',
    name: 'Uganda',
    currency: ['UGX'],
    region: 'Africa',
    flag: '🇺🇬',
    paths: [],
  },
  US: {
    capital: ['Washington D.C.'],
    inCountryList: ['UN'],
    code3: 'USA',
    borders: ['CA', 'MX'],
    subregion: 'North America',
    languages: {
      eng: 'English',
    },
    code: 'US',
    latlng: [38, -97],
    codeN: '840',
    callingCode: ['1'],
    region: 'Americas',
    flag: '🇺🇸',
    currency: ['USD'],
    name: 'United States',
    paths: [],
  },
  UY: {
    currency: ['UYU'],
    codeN: '858',
    capital: ['Montevideo'],
    subregion: 'South America',
    inCountryList: ['UN'],
    code: 'UY',
    flag: '🇺🇾',
    latlng: [-33, -56],
    languages: {
      spa: 'Spanish',
    },
    callingCode: ['598'],
    code3: 'URY',
    region: 'Americas',
    name: 'Uruguay',
    borders: ['AR', 'BR'],
    paths: [],
  },
  UZ: {
    currency: ['UZS'],
    inCountryList: ['UN'],
    name: 'Uzbekistan',
    codeN: '860',
    capital: ['Tashkent'],
    callingCode: ['998'],
    latlng: [41, 64],
    languages: {
      uzb: 'Uzbek',
      rus: 'Russian',
    },
    region: 'Asia',
    subregion: 'Central Asia',
    code3: 'UZB',
    borders: ['AF', 'KZ', 'KG', 'TJ', 'TM'],
    code: 'UZ',
    flag: '🇺🇿',
    paths: [],
  },
  VA: {
    region: 'Europe',
    latlng: [41.9, 12.45],
    callingCode: ['3906698', '379'],
    code3: 'VAT',
    currency: ['EUR'],
    capital: ['Vatican City'],
    inCountryList: ['Observers'],
    codeN: '336',
    languages: {
      lat: 'Latin',
      ita: 'Italian',
    },
    name: 'Vatican City',
    flag: '🇻🇦',
    code: 'VA',
    subregion: 'Southern Europe',
    borders: ['IT'],
    paths: [],
  },
  VC: {
    inCountryList: ['UN'],
    currency: ['XCD'],
    code3: 'VCT',
    codeN: '670',
    latlng: [13.25, -61.2],
    subregion: 'Caribbean',
    callingCode: ['1784'],
    name: 'Saint Vincent and the Grenadines',
    languages: {
      eng: 'English',
    },
    capital: ['Kingstown'],
    flag: '🇻🇨',
    borders: [],
    code: 'VC',
    region: 'Americas',
    paths: [],
  },
  VE: {
    region: 'Americas',
    latlng: [8, -66],
    borders: ['BR', 'CO', 'GY'],
    languages: {
      spa: 'Spanish',
    },
    currency: ['VEF'],
    code3: 'VEN',
    flag: '🇻🇪',
    codeN: '862',
    subregion: 'South America',
    name: 'Venezuela',
    callingCode: ['58'],
    inCountryList: ['UN'],
    code: 'VE',
    capital: ['Caracas'],
    paths: [],
  },
  VG: {
    name: 'British Virgin Islands',
    subregion: 'Caribbean',
    region: 'Americas',
    code: 'VG',
    flag: '🇻🇬',
    paths: [],
  },
  VN: {
    callingCode: ['84'],
    currency: ['VND'],
    borders: ['KH', 'CN', 'LA'],
    region: 'Asia',
    code3: 'VNM',
    capital: ['Hanoi'],
    flag: '🇻🇳',
    code: 'VN',
    subregion: 'South-Eastern Asia',
    languages: {
      vie: 'Vietnamese',
    },
    name: 'Vietnam',
    codeN: '704',
    inCountryList: ['UN'],
    latlng: [16.16666666, 107.83333333],
    paths: [],
  },
  VU: {
    callingCode: ['678'],
    name: 'Vanuatu',
    inCountryList: ['UN'],
    codeN: '548',
    code: 'VU',
    latlng: [-16, 167],
    flag: '🇻🇺',
    code3: 'VUT',
    capital: ['Port Vila'],
    subregion: 'Melanesia',
    languages: {
      eng: 'English',
      fra: 'French',
      bis: 'Bislama',
    },
    borders: [],
    currency: ['VUV'],
    region: 'Oceania',
    paths: [],
  },
  WS: {
    name: 'Samoa',
    code: 'WS',
    code3: 'WSM',
    callingCode: ['685'],
    inCountryList: ['UN'],
    languages: {
      smo: 'Samoan',
      eng: 'English',
    },
    currency: ['WST'],
    subregion: 'Polynesia',
    codeN: '882',
    borders: [],
    capital: ['Apia'],
    latlng: [-13.58333333, -172.33333333],
    region: 'Oceania',
    flag: '🇼🇸',
    paths: [],
  },
  YE: {
    inCountryList: ['UN'],
    latlng: [15, 48],
    region: 'Asia',
    code: 'YE',
    borders: ['OM', 'SA'],
    subregion: 'Western Asia',
    code3: 'YEM',
    flag: '🇾🇪',
    currency: ['YER'],
    callingCode: ['967'],
    codeN: '887',
    capital: ["Sana'a"],
    languages: {
      ara: 'Arabic',
    },
    name: 'Yemen',
    paths: [],
  },
  ZA: {
    flag: '🇿🇦',
    subregion: 'Southern Africa',
    name: 'South Africa',
    capital: ['Pretoria', 'Bloemfontein', 'Cape Town'],
    callingCode: ['27'],
    inCountryList: ['UN'],
    code3: 'ZAF',
    codeN: '710',
    region: 'Africa',
    borders: ['BW', 'LS', 'MZ', 'NA', 'SZ', 'ZW'],
    code: 'ZA',
    currency: ['ZAR'],
    latlng: [-29, 24],
    languages: {
      tso: 'Tsonga',
      zul: 'Zulu',
      xho: 'Xhosa',
      eng: 'English',
      nso: 'Northern Sotho',
      ssw: 'Swazi',
      tsn: 'Tswana',
      sot: 'Southern Sotho',
      nbl: 'Southern Ndebele',
      ven: 'Venda',
      afr: 'Afrikaans',
    },
    paths: [],
  },
  ZM: {
    callingCode: ['260'],
    capital: ['Lusaka'],
    region: 'Africa',
    flag: '🇿🇲',
    code3: 'ZMB',
    languages: {
      eng: 'English',
    },
    borders: ['AO', 'BW', 'CD', 'MW', 'MZ', 'NA', 'TZ', 'ZW'],
    code: 'ZM',
    subregion: 'Eastern Africa',
    currency: ['ZMW'],
    name: 'Zambia',
    latlng: [-15, 30],
    inCountryList: ['UN'],
    codeN: '894',
    paths: [],
  },
  ZW: {
    borders: ['BW', 'MZ', 'ZA', 'ZM'],
    name: 'Zimbabwe',
    currency: ['ZWL'],
    codeN: '716',
    code3: 'ZWE',
    languages: {
      xho: 'Xhosa',
      ven: 'Venda',
      zib: 'Zimbabwean Sign Language',
      nde: 'Northern Ndebele',
      tso: 'Tsonga',
      tsn: 'Tswana',
      sna: 'Shona',
      toi: 'Tonga',
      nya: 'Chewa',
      khi: 'Khoisan',
      eng: 'English',
      sot: 'Sotho',
      bwg: 'Chibarwe',
      ndc: 'Ndau',
      kck: 'Kalanga',
    },
    capital: ['Harare'],
    code: 'ZW',
    region: 'Africa',
    subregion: 'Eastern Africa',
    flag: '🇿🇼',
    inCountryList: ['UN'],
    callingCode: ['263'],
    latlng: [-20, 30],
    paths: [],
  },
};
