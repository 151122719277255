import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { CardHeader, Dialog, IconButton, InputAdornment, List, ListItem, ListItemIcon, ListItemText, Switch, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { useContext, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { selectCountries, TCountry } from 'src/features/storage/storageSlice';
import { TUser } from 'src/features/user/classes';
import { selectMe, setCountryVisitedState } from 'src/features/user/userSlice';
import { FBContext } from '../Sync/SyncHandler';

export interface CountriesListProps extends google.maps.MapOptions {
  open: boolean;
  handleClose?: () => void;
}

export const CountriesList: React.FC<CountriesListProps> = props => {
  const { open, handleClose } = props;
  const dispatch = useAppDispatch();

  const me = useAppSelector<TUser>(selectMe);
  const countries = useAppSelector<TCountry[]>(selectCountries);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [search, setSearch] = useState<string>('');
  useEffect(() => {
    if (!open) setSearch('');
  }, [open]);

  const fbContext = useContext(FBContext);

  const handleToggleCountry = (countryCode: string, visited: boolean) => () => {
    dispatch(
      setCountryVisitedState({
        db: fbContext.db,
        userId: fbContext.user?.uid,
        country: {
          code: countryCode,
          firstVisitDate: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        },
        visitedState: visited,
      }),
    );
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        PaperProps={{
          sx: { minHeight: 'calc(100% - 64px)' },
        }}
      >
        <CardHeader
          // avatar={
          //   <IconButton aria-label='settings' onClick={handleClose}>
          //     <ArrowBackIcon />
          //   </IconButton>
          // }
          // action={
          //   <IconButton disableRipple={true} aria-label='settings' onClick={handleClose}>
          //     <Button fullWidth={true}>Cities</Button>
          //   </IconButton>
          // }
          action={
            <IconButton aria-label='settings' onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          }
          title='Countries'
          subheader={`Visited ${Object.keys(me.countries).length} countries`}
          sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 100 }}
        />

        <List sx={{ paddingTop: 0 }}>
          <ListItem sx={{ paddingTop: 0 }}>
            <TextField
              fullWidth
              variant='outlined'
              value={search}
              onChange={e => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </ListItem>

          {(search.length ? countries.filter(c => c.name.toLowerCase().includes(search.toLocaleLowerCase())) : countries).map(country => (
            <ListItem key={country.code}>
              <ListItemIcon sx={{ color: 'unset' }}>{country.flag}</ListItemIcon>
              <ListItemText primary={country.name} />
              <Switch
                edge='end'
                onChange={handleToggleCountry(country.code, !me.countries[country.code])}
                checked={!!me.countries[country.code]}
                inputProps={{
                  'aria-labelledby': 'switch-list-label-wifi',
                }}
              />
            </ListItem>
          ))}
        </List>
      </Dialog>
    </>
  );
};
