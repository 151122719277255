import { FC, useEffect, useState } from 'react';
import { TCountry } from 'src/features/storage/storageSlice';

export const DEFAULT_POLYGON_OPTIONS = {
  strokeColor: '#808080',
  strokeOpacity: 0.8,
  strokeWeight: 1,
  fillColor: '#D3D3D3',
  fillOpacity: 0.9,
};

type TPolygonOptions = {
  country: TCountry;
  onClick: (countryCode: string) => void;
};

export const Polygon: FC<TPolygonOptions> = options => {
  const { country } = options;
  const [polygon, setPolygon] = useState<google.maps.Polygon>();

  useEffect(() => {
    if (!polygon) {
      setPolygon(new google.maps.Polygon());
    }

    // remove polygon from map on unmount
    return () => {
      if (polygon) polygon.setMap(null);
    };
  }, [polygon]);

  useEffect(() => {
    if (polygon) {
      google.maps.event.clearListeners(polygon, 'click');
      polygon.addListener('click', (e: google.maps.MapMouseEvent) => {
        options.onClick(country.code);

        e.domEvent.preventDefault();
        e.domEvent.stopPropagation();
      });
      polygon.setOptions({ ...options, ...DEFAULT_POLYGON_OPTIONS, paths: country.paths });
    }
  }, [polygon, options, country]);

  return null;
};
