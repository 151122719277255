export enum LoggerLevel {
  VERBOSE = 10,
  INFO = 20,
  WARN = 30,
  ERROR = 40,
}

function logIfLevel(currentLevel: LoggerLevel, levelOfMessage: LoggerLevel, func: (...data: any[]) => void, ...params: any[]) {
  if (currentLevel <= levelOfMessage) func(...params);
}

function createLogger(level: LoggerLevel) {
  return {
    verbose: (...params: any[]) => console.log(...params),
    info: (...params: any[]) => logIfLevel(level, LoggerLevel.INFO, console.debug, ...params),
    warn: (...params: any[]) => logIfLevel(level, LoggerLevel.WARN, console.warn, ...params),
    error: (...params: any[]) => logIfLevel(level, LoggerLevel.ERROR, console.error, ...params),
  };
}

export default createLogger;
