import { useEffect, useState } from 'react';
import { TVisitedCity } from 'src/features/user/classes';

const minIconWidth = 5;
const maxIconWidth = 20;

function getMarkerSVG({ zoom, city }: TMarkerProps): string {
  let iconWidth = 12;

  iconWidth = minIconWidth + (Math.min(zoom, 15) / 15) * (maxIconWidth - minIconWidth);

  if (city?.visitedDates?.length > 2) {
    const increaseWidthByVisits = (Math.min(city.visitedDates?.length, 10) / 10) * 25; // 10 - max visits to take into account, 5 - max additional size in pxl
    iconWidth += increaseWidthByVisits;
  }

  // iconWidth = 200;

  const circleColor = '#f06';
  const standColor = '#fff';
  const strokeWith = 1;
  const strokeColor = '#000';

  const iconHeight = iconWidth * 1.7;
  const circleDiameter = iconWidth - strokeWith * 2;
  const circleRadius = circleDiameter / 2;
  const linesGap = circleDiameter / 5;
  const fontSize = circleDiameter * 0.7;

  const standSvg = `
    <polygon 
      points="${[
        [iconWidth / 2, iconHeight].join(','),
        [iconWidth / 2 - linesGap, circleDiameter / 2].join(','),
        [iconWidth / 2 + linesGap, circleDiameter / 2].join(','),
      ].join(' ')}"
      fill="${standColor}" 
      stroke="${strokeColor}" 
      stroke-width="${strokeWith}"
    />
  `;

  const circleSvg = `
    <circle 
      cx="${circleRadius + strokeWith}" 
      cy="${circleRadius + strokeWith}" 
      r="${circleRadius}" 
      fill="${circleColor}" 
      stroke="${strokeColor}" 
      stroke-width="${strokeWith}"
    />
  `;

  let textSvg = '';

  if (iconWidth > 5 && city.visitedDates?.length > 2) {
    // console.log(`Here add ${city.visitedDates?.length} for ${city.fullName}`);
    textSvg = `
      <text 
        x="${circleRadius + strokeWith}" 
        y="${circleRadius + strokeWith}" 
        text-anchor="middle" 
        dominant-baseline="central"
        font-size="${fontSize}px" 
        font-family="Helvetica"
        >
          ${city.visitedDates?.length}
      </text>
    `;
  }

  return `
    <svg fill="#000000" width="${iconWidth}px" height="${iconHeight}px" version="1.1" id="Capa_1" viewBox="0 0 ${iconWidth} ${iconHeight}" xmlns="http://www.w3.org/2000/svg">
      <g>
        ${standSvg}
        ${circleSvg}
        ${textSvg}
      </g>
    </svg>
  `;
}

export interface TMarkerProps extends google.maps.MarkerOptions {
  marker: google.maps.marker.AdvancedMarkerElement;
  map?: google.maps.Map;
  city: TVisitedCity;
  zoom: number;
  onClick: (placeId: string) => any;
}

const parser = new DOMParser();

export const Marker: React.FC<TMarkerProps> = props => {
  useEffect(() => {
    props.marker.map = props.map;
    props.marker.position = props.city?.location;
    props.marker.content = props.city ? parser.parseFromString(getMarkerSVG(props), 'image/svg+xml').documentElement : null;
    props.marker.addListener('click', () => {
      props.onClick(props.city.placeId);
    });
  }, [props]);

  return null;
};
