import { parseJSON, format } from 'date-fns';

export type DateFormats = 'd MMMM y';

export const formatDate = (date: string | Date | undefined | null, formatStr: DateFormats = 'd MMMM y'): string => {
  if (date === null || date === undefined) {
    return 'unknown';
  } else if (typeof date === 'string') {
    return format(parseJSON(date), formatStr);
  } else {
    return format(date, formatStr);
  }
};

export const ordinal = (number: number): string => {
  const english_ordinal_rules = new Intl.PluralRules('en', { type: 'ordinal' }); // TODO: Add localization
  const suffixes = {
    zero: 'th',
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th',
    many: 'th',
  };

  const category = english_ordinal_rules.select(number);
  const suffix = suffixes[category];
  return number + suffix;
};
