import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { deleteDoc, doc, Firestore, setDoc } from 'firebase/firestore';

import type { RootState } from 'src/app/store';

import { TUser, TVisitedCity, TVisitedCountry } from './classes';
import { cityToFirestore, countryToFirestore } from './converters';
import { formatISO } from 'date-fns';

/*************************
 * Thunk
 *************************/

export interface SetCountryVisitedStateThunkPayload {
  db: Firestore;
  userId?: string;
  country: TVisitedCountry;
  visitedState: boolean;
}

export interface SetCityVisitedStateThunkPayload {
  db: Firestore;
  userId?: string;
  city: TVisitedCity;
  visitedState: boolean;
}

/*************************
 * State
 *************************/

/*
export interface VisitNewCityStatePayload {
  countryCode: string;
  cityFromGoogle: any;
  visitDate: string;
}

export interface VisitExistingCityStatePayload {
  existingCityId: string;
  visitDate: string;
}
*/

interface FirestoreStatePayloadAction {
  userId?: string;
  countries?: { [code: string]: TVisitedCountry };
  cities?: { [placeId: string]: TVisitedCity };
}

// Define a type for the slice state
interface UserState {
  syncing: boolean;
  errors: Array<string>;
  me: TUser;
}

// Define the initial state using that type
const initialState: UserState = {
  syncing: false,
  errors: [],
  me: {
    id: undefined,
    countries: {},
    cities: {},
    appStartCount: {},
  },
};

export const setCountryVisitedState = createAsyncThunk(
  'user/setCountryVisitedState',
  async ({ db, userId, country, visitedState }: SetCountryVisitedStateThunkPayload, { rejectWithValue, getState }) => {
    const { user } = getState() as { user: UserState };

    try {
      if (db && userId) {
        console.log('Do upload/update country in Firebase', userId, country);

        if (visitedState) {
          const countryRef = doc(db, 'user', userId, 'countries', country.code);
          await setDoc(countryRef, countryToFirestore(country));
          console.log('Country added to firestore');
        } else {
          const countryRef = doc(db, 'user', userId, 'countries', country.code);
          await deleteDoc(countryRef);
          console.log('Country removed from firestore');
        }
        return country;
      } else {
        console.log('Logged out... Do nothing with country in Firestore');
      }
    } catch (err) {
      console.error('Throwed an error', err);
      const errorString = JSON.stringify(err, Object.getOwnPropertyNames(err));
      return rejectWithValue(errorString);
    }
  },
);

export const setCityVisitedState = createAsyncThunk(
  'user/setCityVisitedState',
  async ({ db, userId, city, visitedState }: SetCityVisitedStateThunkPayload, { rejectWithValue, dispatch, getState }) => {
    const { user } = getState() as { user: UserState };

    try {
      if (db && userId) {
        console.log('Do upload/update city in Firebase', userId, city.placeId);

        if (visitedState) {
          const cityRef = doc(db, 'user', userId, 'cities', city.placeId);
          await setDoc(cityRef, cityToFirestore(city));
          console.log('City added to firestore');
        } else {
          const cityRef = doc(db, 'user', userId, 'cities', city.placeId);
          await deleteDoc(cityRef);
          console.log('City removed from firestore');
        }
        return city;
      } else {
        console.log('Logged out... Do nothing with city in Firestore');
      }
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const userSlice = createSlice({
  name: 'user',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    resetLocalUser: _ => initialState,
    incrementAppStartCount: state => {
      state.me.appStartCount = {
        ...state.me.appStartCount,
        web: (state.me.appStartCount?.web ?? 0) + 1,
      };
      // TODO: Upload to Firestore if authorized
    },

    setStateFromFirestore(state, action: PayloadAction<FirestoreStatePayloadAction>) {
      state.me.id = action.payload.userId;
      if (action.payload.countries) {
        state.me.countries = {
          ...state.me.countries,
          ...action.payload.countries,
        };
      }
      if (action.payload.cities) {
        state.me.cities = {
          ...state.me.cities,
          ...action.payload.cities,
        };
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(setCountryVisitedState.pending, (state, { meta: { arg } }) => {
        console.log('setCountryVisitedState.pending', arg);
        state.syncing = true;
        state.errors = [];

        const { country, visitedState } = arg;
        if (visitedState) state.me.countries[country.code] = country;
        else {
          delete state.me.countries[country.code];
        }
      })
      .addCase(setCountryVisitedState.rejected, (state, { payload, meta: { arg } }) => {
        console.log('setCountryVisitedState.rejected', arg);
        state.syncing = false;
        state.errors = [payload as string];
        // TODO: Check, if Firestore store task with updating remote storage, and made this after connection restored - do nothing locally. Instead, rollback local changes
      })
      .addCase(setCountryVisitedState.fulfilled, (state, payload) => {
        console.log('setCountryVisitedState.fulfilled', payload);
        state.syncing = false;
        state.errors = [];
      });

    builder
      .addCase(setCityVisitedState.pending, (state, { meta: { arg } }) => {
        console.log('setCityVisitedState.pending', arg);
        state.syncing = true;
        state.errors = [];

        const { city, visitedState } = arg;
        if (visitedState) state.me.cities[city.placeId] = { ...city };
        else {
          delete state.me.cities[city.placeId];
        }
      })
      .addCase(setCityVisitedState.rejected, (state, { payload, meta: { arg } }) => {
        console.log('setCityVisitedState.rejected', arg);
        state.syncing = false;
        state.errors = [payload as string];
        // TODO: Check, if Firestore store task with updating remote storage, and made this after connection restored - do nothing locally. Instead, rollback local changes
      })
      .addCase(setCityVisitedState.fulfilled, (state, payload) => {
        console.log('setCityVisitedState.fulfilled', payload);
        state.syncing = false;
        state.errors = [];
      });
  },
});

export const { resetLocalUser, incrementAppStartCount, setStateFromFirestore } = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectMe = (state: RootState) => state.user.me;
export const selectCities = (state: RootState) => state.user.me.cities;

export default userSlice.reducer;
