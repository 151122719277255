import { Timestamp } from 'firebase/firestore';
import { TVisitedCity, TVisitedCountry } from './classes';

export const countryToFirestore = (country: TVisitedCountry) => {
  return {
    code: country.code,
    firstVisitDate: country.firstVisitDate ? Timestamp.fromDate(new Date(country.firstVisitDate)) : null,
    updatedAt: country.updatedAt ? Timestamp.fromDate(new Date(country.updatedAt)) : null,
  };
};

export const firestoreToCountry = (country: any): TVisitedCountry => {
  try {
    return {
      code: country.code,
      firstVisitDate: (country.firstVisitDate as Timestamp) ? (country.firstVisitDate as Timestamp).toDate().toISOString() : undefined,
      updatedAt: country.updatedAt instanceof Timestamp ? (country.updatedAt as Timestamp).toDate().toISOString() : undefined,
    };
  } catch (err) {
    console.log('firestoreToCountry', err, country);
    return {
      code: country.code,
      firstVisitDate: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };
  }
};

export const cityToFirestore = (city: TVisitedCity) => {
  console.log('city', city);
  return {
    countryCode: city.countryCode,
    name: city.name,
    fullName: city.fullName,
    placeId: city.placeId,
    visitedDates: city.visitedDates.map(visitedDate => Timestamp.fromDate(new Date(visitedDate))),
    location: [city.location.lat, city.location.lng],
    updatedAt: city.updatedAt ? Timestamp.fromDate(new Date(city.updatedAt)) : null,
  };
};

export const firestoreToCity = (city: any): TVisitedCity => {
  try {
    return {
      countryCode: city.countryCode,
      name: city.name,
      fullName: city.fullName,
      placeId: city.placeId,
      visitedDates: city.visitedDates?.length
        ? city.visitedDates.map((visitedDate: any) => (visitedDate as Timestamp).toDate().toISOString()).sort()
        : (city.firstVisitDate as Timestamp)
        ? [(city.firstVisitDate as Timestamp).toDate().toISOString()]
        : [],
      location: city.location?.length === 2 ? { lat: city.location[0], lng: city.location[1] } : { lat: 0, lng: 0 }, // TODO: Handle location absense as a error
      updatedAt: city.updatedAt instanceof Timestamp ? (city.updatedAt as Timestamp).toDate().toISOString() : undefined,
    };
  } catch (err) {
    console.log('firestoreToCity', err, city);
    return {
      countryCode: city.countryCode,
      name: city.name,
      fullName: city.fullName,
      placeId: city.placeId,
      visitedDates: [],
      location: city.location?.length === 2 ? { lat: city.location[0], lng: city.location[1] } : { lat: 0, lng: 0 }, // TODO: Handle location absense as a error
      updatedAt: new Date().toISOString(),
    };
  }
};

export const geocoderResultToCity = (geocoderResult: google.maps.GeocoderResult): TVisitedCity | undefined => {
  const countryComponent = geocoderResult.address_components.find(component => component.types.includes('country'));
  const localityComponent = geocoderResult.address_components.find(component => component.types.includes('locality'));

  if (!countryComponent || !localityComponent) return;

  return {
    countryCode: countryComponent.short_name,
    name: localityComponent.long_name,
    fullName: geocoderResult.formatted_address,
    placeId: geocoderResult.place_id,
    visitedDates: [],
    location: {
      lat: geocoderResult.geometry.location.lat(),
      lng: geocoderResult.geometry.location.lng(),
    },
    updatedAt: new Date().toISOString(),
  };
};
