import { Avatar } from '@mui/material';
import { User } from 'firebase/auth';
import { FC } from 'react';

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  const nameParts = name?.split(' ').filter(part => part?.length) ?? [];
  const char1 = nameParts.length ? nameParts[0][0] : '*'; // if name empty - char1='*'
  const char2 =
    nameParts.length > 1 && nameParts[1].length // if name contains second part
      ? nameParts[1][0] // char2=first_letter_of_second_part
      : nameParts[0].length > 1
      ? nameParts[0][1] // if first part contains second char, char2=this_second_char
      : ''; // else avatar contains only one char
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${char1.toUpperCase()}${char2.toUpperCase()}`,
  };
}
export interface TAvatarProps {
  user: User | null;
}

export const TAvatar: FC<TAvatarProps> = ({ user }) => {
  const userName = user?.displayName ?? 'Unknown user'; // TODO: Translate

  let props: any = {
    alt: userName,
    // sx: { width: 148, height: 148 },
  };

  if (user?.photoURL) props.src = user?.photoURL;
  else props = { ...props, ...stringAvatar(userName) };

  return <Avatar {...props} />;
};
