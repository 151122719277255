export const polygons: {
  [countryCode: string]: Array<Array<google.maps.LatLngLiteral>>;
} = {
  AD: [
    [
      { lat: 42.523858674562, lng: 1.4026424316121 },
      { lat: 42.59523752114, lng: 1.422102140858 },
      { lat: 42.614599975785, lng: 1.4380371998939 },
      { lat: 42.646152758614, lng: 1.4983066345536 },
      { lat: 42.648694622757, lng: 1.5484268351336 },
      { lat: 42.58973961721, lng: 1.727019874073 },
      { lat: 42.517623708645, lng: 1.7236482515203 },
      { lat: 42.497561155729, lng: 1.7116632297949 },
      { lat: 42.471023529356, lng: 1.6755720746956 },
      { lat: 42.431948415279, lng: 1.5541545577351 },
      { lat: 42.437195952704, lng: 1.437087440038 },
      { lat: 42.449381957751, lng: 1.4265114882943 },
      { lat: 42.523858674562, lng: 1.4026424316121 },
    ],
  ],
  AE: [
    [
      { lat: 24.245497137951105, lng: 51.57951867046327 },
      { lat: 24.29407298430547, lng: 51.757440626844186 },
      { lat: 24.019826158132506, lng: 51.794389275932865 },
      { lat: 24.177439276622707, lng: 52.57708051942561 },
      { lat: 24.15131684009917, lng: 53.404006788960146 },
      { lat: 24.121757920828212, lng: 54.00800092958758 },
      { lat: 24.79789236093509, lng: 54.69302371604863 },
      { lat: 25.43914520924494, lng: 55.43902469261414 },
      { lat: 26.055464178973978, lng: 56.07082075381456 },
      { lat: 25.71460643157677, lng: 56.261041701080956 },
      { lat: 24.924732163995486, lng: 56.396847365144005 },
      { lat: 24.920830593357444, lng: 55.88623253766801 },
      { lat: 24.269604193615265, lng: 55.804118686756226 },
      { lat: 24.130542914317825, lng: 55.981213820220454 },
      { lat: 23.933604030853502, lng: 55.52863162620823 },
      { lat: 23.524869289640932, lng: 55.525841098864475 },
      { lat: 23.11099274341532, lng: 55.234489373602884 },
      { lat: 22.708329982997046, lng: 55.20834109886319 },
      { lat: 22.496947536707136, lng: 55.0068030129249 },
      { lat: 23.00115448657894, lng: 52.000733270074335 },
      { lat: 24.014219265228828, lng: 51.61770755392698 },
      { lat: 24.245497137951105, lng: 51.57951867046327 },
    ],
  ],
  AF: [
    [
      { lat: 35.650072333309225, lng: 61.21081709172574 },
      { lat: 35.270663967422294, lng: 62.230651483005886 },
      { lat: 35.40404083916762, lng: 62.9846623065766 },
      { lat: 35.857165635718914, lng: 63.19353844590035 },
      { lat: 36.0079574651466, lng: 63.9828959491587 },
      { lat: 36.31207326918427, lng: 64.5464791197339 },
      { lat: 37.111817735333304, lng: 64.7461051776774 },
      { lat: 37.30521678318564, lng: 65.58894778835784 },
      { lat: 37.66116404881207, lng: 65.74563073106681 },
      { lat: 37.39379018813392, lng: 66.21738488145932 },
      { lat: 37.36278432875879, lng: 66.51860680528867 },
      { lat: 37.35614390720929, lng: 67.07578209825962 },
      { lat: 37.14499400486468, lng: 67.82999962755952 },
      { lat: 37.0231151393043, lng: 68.13556237170138 },
      { lat: 37.344335842430596, lng: 68.85944583524594 },
      { lat: 37.15114350030743, lng: 69.19627282092438 },
      { lat: 37.60899669041341, lng: 69.51878543485796 },
      { lat: 37.58822276463209, lng: 70.11657840361033 },
      { lat: 37.735164699854025, lng: 70.27057417184014 },
      { lat: 38.13839590102752, lng: 70.3763041523093 },
      { lat: 38.486281643216415, lng: 70.80682050973289 },
      { lat: 38.258905341132156, lng: 71.34813113799026 },
      { lat: 37.95326508234188, lng: 71.23940392444817 },
      { lat: 37.905774441065645, lng: 71.54191775908478 },
      { lat: 37.06564484308051, lng: 71.44869347523024 },
      { lat: 36.73817129164692, lng: 71.8446382994506 },
      { lat: 36.948287665345674, lng: 72.1930408059624 },
      { lat: 37.047558091778356, lng: 72.63688968291729 },
      { lat: 37.495256862939, lng: 73.26005577992501 },
      { lat: 37.4215662704908, lng: 73.9486959166465 },
      { lat: 37.419990139305895, lng: 74.98000247589542 },
      { lat: 37.13303091078912, lng: 75.15802778514092 },
      { lat: 37.02084137628346, lng: 74.57589277537298 },
      { lat: 36.83617564548845, lng: 74.06755171091783 },
      { lat: 36.72000702569632, lng: 72.92002485544447 },
      { lat: 36.50994232842986, lng: 71.84629194528392 },
      { lat: 36.074387518857804, lng: 71.26234826038575 },
      { lat: 35.650563259416, lng: 71.49876793812109 },
      { lat: 35.153203436822864, lng: 71.6130762063507 },
      { lat: 34.733125718722235, lng: 71.11501875192162 },
      { lat: 34.34891144463215, lng: 71.15677330921346 },
      { lat: 33.98885590263851, lng: 70.8818030129884 },
      { lat: 34.02012014417511, lng: 69.9305432473596 },
      { lat: 33.35853261975839, lng: 70.3235941913716 },
      { lat: 33.105498969041236, lng: 69.68714725126485 },
      { lat: 32.5019440780883, lng: 69.26252200712256 },
      { lat: 31.90141225842444, lng: 69.31776411324255 },
      { lat: 31.620189113892064, lng: 68.92667687365767 },
      { lat: 31.713310044882018, lng: 68.55693200060932 },
      { lat: 31.58293040620963, lng: 67.79268924344478 },
      { lat: 31.30315420178142, lng: 67.68339358914747 },
      { lat: 31.304911200479353, lng: 66.93889122911847 },
      { lat: 30.73889923758645, lng: 66.38145755398602 },
      { lat: 29.887943427036177, lng: 66.34647260932442 },
      { lat: 29.472180691031905, lng: 65.0468620136161 },
      { lat: 29.560030625928093, lng: 64.35041873561852 },
      { lat: 29.340819200145972, lng: 64.14800215033125 },
      { lat: 29.468330796826162, lng: 63.55026085801117 },
      { lat: 29.31857249604431, lng: 62.54985680527278 },
      { lat: 29.829238999952604, lng: 60.874248488208785 },
      { lat: 30.735850328081234, lng: 61.781221551363444 },
      { lat: 31.37950613049267, lng: 61.699314406180825 },
      { lat: 31.548074652628753, lng: 60.94194461451113 },
      { lat: 32.18291962333443, lng: 60.863654819588966 },
      { lat: 32.98126882581157, lng: 60.536077915290775 },
      { lat: 33.52883230237625, lng: 60.963700392506006 },
      { lat: 33.676446031218006, lng: 60.52842980331158 },
      { lat: 34.40410187431986, lng: 60.80319339380745 },
      { lat: 35.650072333309225, lng: 61.21081709172574 },
    ],
  ],
  AG: [
    [
      { lat: 17.347571482946, lng: -61.866806318989 },
      { lat: 17.369607114865, lng: -61.865496607765 },
      { lat: 17.697029378831, lng: -61.849361252433 },
      { lat: 17.701344097408, lng: -61.842139282017 },
      { lat: 17.700984113961, lng: -61.828236357157 },
      { lat: 17.653268673944, lng: -61.746854211034 },
      { lat: 17.206974489796, lng: -61.673498312724 },
      { lat: 17.077983119329, lng: -61.675501951042 },
      { lat: 17.060763246497, lng: -61.682869662211 },
      { lat: 16.985530941235, lng: -61.767611236958 },
      { lat: 16.980491929126, lng: -61.785760721855 },
      { lat: 16.98091884417, lng: -61.791508856288 },
      { lat: 17.003582384136, lng: -61.854595451471 },
      { lat: 17.079331148265, lng: -61.885477027241 },
      { lat: 17.347571482946, lng: -61.866806318989 },
    ],
  ],
  AI: [
    [
      { lat: 18.28803799525966, lng: -423.29017639160156 },
      { lat: 18.254784657792403, lng: -423.25172424316406 },
      { lat: 18.25021997706561, lng: -423.1761932373047 },
      { lat: 18.14911422341221, lng: -423.185806274414 },
      { lat: 18.15563894415998, lng: -423.08555603027344 },
      { lat: 18.267825941698295, lng: -422.9056549072265 },
      { lat: 18.307595803753852, lng: -422.91046142578125 },
      { lat: 18.284126168705537, lng: -423.16314697265625 },
      { lat: 18.309551463162894, lng: -423.2469177246094 },
      { lat: 18.28803799525966, lng: -423.29017639160156 },
    ],
  ],
  AL: [
    [
      { lat: 41.855404161133606, lng: 20.590247430104906 },
      { lat: 41.51508901627533, lng: 20.463175083099202 },
      { lat: 41.08622630468522, lng: 20.605181919037364 },
      { lat: 40.84272695572588, lng: 21.0200403174764 },
      { lat: 40.58000397395397, lng: 20.999989861747224 },
      { lat: 40.43499990494303, lng: 20.674996779063633 },
      { lat: 40.11000682225938, lng: 20.615000441172754 },
      { lat: 39.62499766698397, lng: 20.15001590341052 },
      { lat: 39.69499339452341, lng: 19.980000441170144 },
      { lat: 39.91500580500605, lng: 19.960001661873207 },
      { lat: 40.250773423822466, lng: 19.406081984136733 },
      { lat: 40.72723012955356, lng: 19.319058872157143 },
      { lat: 41.40956574153546, lng: 19.40354983895429 },
      { lat: 41.71998607031276, lng: 19.540027296637106 },
      { lat: 41.877547512370654, lng: 19.37176883309496 },
      { lat: 42.19574514420782, lng: 19.304486118250793 },
      { lat: 42.688247382165564, lng: 19.738051385179627 },
      { lat: 42.50009349219084, lng: 19.801613396898688 },
      { lat: 42.58863, lng: 20.0707 },
      { lat: 42.32025950781508, lng: 20.283754510181893 },
      { lat: 42.21787, lng: 20.52295 },
      { lat: 41.855404161133606, lng: 20.590247430104906 },
    ],
  ],
  AM: [
    [
      { lat: 41.09214325618257, lng: 43.582745802592726 },
      { lat: 41.248128567055595, lng: 44.97248009621808 },
      { lat: 40.98535390885141, lng: 45.17949588397934 },
      { lat: 40.812289537105926, lng: 45.56035118997045 },
      { lat: 40.56150381119346, lng: 45.35917483905817 },
      { lat: 40.21847565364, lng: 45.89190717955509 },
      { lat: 39.89999380142518, lng: 45.61001224140293 },
      { lat: 39.628020738273065, lng: 46.034534132680676 },
      { lat: 39.464154771475535, lng: 46.48349897643246 },
      { lat: 38.770605373686294, lng: 46.50571984231797 },
      { lat: 38.74120148371222, lng: 46.14362308124881 },
      { lat: 39.31971914321974, lng: 45.73537926614301 },
      { lat: 39.47399913182713, lng: 45.73997846861698 },
      { lat: 39.471751207022436, lng: 45.298144972521456 },
      { lat: 39.740003567049555, lng: 45.00198733905674 },
      { lat: 39.71300263117705, lng: 44.79398969908195 },
      { lat: 40.00500031184228, lng: 44.4000085792887 },
      { lat: 40.253563951166186, lng: 43.65643639504094 },
      { lat: 40.74020091405876, lng: 43.75265791196841 },
      { lat: 41.09214325618257, lng: 43.582745802592726 },
    ],
  ],
  AO: [
    [
      { lat: -5.684303887559224, lng: 12.436688266660923 },
      { lat: -5.789930515163803, lng: 12.18233686692028 },
      { lat: -5.037986748884734, lng: 11.914963006242116 },
      { lat: -4.606230157086158, lng: 12.318607618873926 },
      { lat: -4.438023369976121, lng: 12.62075971848455 },
      { lat: -4.781103203961919, lng: 12.995517205465205 },
      { lat: -4.991271254092936, lng: 12.631611769265845 },
      { lat: -5.248361504744992, lng: 12.468004184629763 },
      { lat: -5.684303887559224, lng: 12.436688266660923 },
    ],
    [
      { lat: -5.877470391466218, lng: 16.326528354567046 },
      { lat: -6.622644545115094, lng: 16.57317996589614 },
      { lat: -7.222297865429979, lng: 16.86019087084523 },
      { lat: -7.545688978712476, lng: 17.08999596524717 },
      { lat: -8.068551120641656, lng: 17.472970004962292 },
      { lat: -7.987677504104866, lng: 18.13422163256905 },
      { lat: -7.847014255406477, lng: 18.464175652752687 },
      { lat: -7.98824594486014, lng: 19.01675174324967 },
      { lat: -7.738183688999725, lng: 19.166613396896082 },
      { lat: -7.155428562044278, lng: 19.417502475673217 },
      { lat: -7.11636117923166, lng: 20.037723016040218 },
      { lat: -6.943090101756951, lng: 20.09162153492062 },
      { lat: -6.939317722199689, lng: 20.601822950938327 },
      { lat: -7.299605808138665, lng: 20.51474816252653 },
      { lat: -7.290872491081316, lng: 21.728110792739756 },
      { lat: -7.920084730667114, lng: 21.746455926203367 },
      { lat: -8.305900974158305, lng: 21.94913089365204 },
      { lat: -8.908706556842986, lng: 21.801801385187957 },
      { lat: -9.523707777548566, lng: 21.875181919042404 },
      { lat: -9.89479623783653, lng: 22.208753289486424 },
      { lat: -11.084801120653779, lng: 22.155268182064333 },
      { lat: -10.99307545333569, lng: 22.402798292742432 },
      { lat: -11.017621758674338, lng: 22.83734541188477 },
      { lat: -10.867863457892483, lng: 23.456790805767465 },
      { lat: -10.926826267137542, lng: 23.912215203555746 },
      { lat: -11.237298272347118, lng: 24.017893507592614 },
      { lat: -11.722281589406336, lng: 23.90415368011824 },
      { lat: -12.191296888887308, lng: 24.079905226342902 },
      { lat: -12.565847670138822, lng: 23.930922072045377 },
      { lat: -12.911046237848552, lng: 24.016136508894704 },
      { lat: -12.898437188369357, lng: 21.933886346125945 },
      { lat: -16.080310153876894, lng: 21.887842644953878 },
      { lat: -16.898451429921835, lng: 22.56247846852429 },
      { lat: -17.523116143465952, lng: 23.215048455506093 },
      { lat: -17.93063648851971, lng: 21.377176141045595 },
      { lat: -17.789094740472237, lng: 18.95618696460363 },
      { lat: -17.309950860262006, lng: 18.26330936043422 },
      { lat: -17.353100681225712, lng: 14.209706658595051 },
      { lat: -17.423380629142656, lng: 14.058501417709039 },
      { lat: -16.97121184658874, lng: 13.462362094789967 },
      { lat: -16.941342868724078, lng: 12.814081251688407 },
      { lat: -17.111668389558062, lng: 12.215461460019384 },
      { lat: -17.3018893368245, lng: 11.734198846085148 },
      { lat: -16.67314218512921, lng: 11.64009606288161 },
      { lat: -15.79381601325069, lng: 11.778537224991567 },
      { lat: -14.878316338767931, lng: 12.123580763404448 },
      { lat: -14.449143568583892, lng: 12.175618930722266 },
      { lat: -13.547699883684402, lng: 12.500095249083017 },
      { lat: -13.137905775609935, lng: 12.738478631245442 },
      { lat: -12.483630466362513, lng: 13.312913852601838 },
      { lat: -12.038644707897191, lng: 13.633721144269828 },
      { lat: -11.297863050993143, lng: 13.738727654686926 },
      { lat: -10.731075941615842, lng: 13.686379428775297 },
      { lat: -10.373578383020728, lng: 13.38732791510216 },
      { lat: -9.766897067914115, lng: 13.120987583069875 },
      { lat: -9.166933689005488, lng: 12.875369500386569 },
      { lat: -8.959091078327575, lng: 12.929061313537801 },
      { lat: -8.562629489784342, lng: 13.236432732809874 },
      { lat: -7.596538588087753, lng: 12.933040398824316 },
      { lat: -6.927122084178805, lng: 12.72829837408392 },
      { lat: -6.294447523629372, lng: 12.227347039446443 },
      { lat: -6.100092461779653, lng: 12.322431674863566 },
      { lat: -5.965682061388478, lng: 12.735171339578699 },
      { lat: -5.984388929878108, lng: 13.02486941900699 },
      { lat: -5.864241224799557, lng: 13.375597364971895 },
      { lat: -5.877470391466218, lng: 16.326528354567046 },
    ],
  ],
  AQ: [
    [
      { lat: -85, lng: 1 },
      { lat: -67.2040323434008, lng: 1 },
      { lat: -62.59334083012023, lng: 119.17968749999999 },
      { lat: -70.61261423801925, lng: 180.5 },
      { lat: -85, lng: 180.5 },
      { lat: -85, lng: 1 },
    ],
    [
      { lat: -85, lng: -178.9 },
      { lat: -78.06198918665974, lng: -178.9 },
      { lat: -72.28906720017673, lng: -126.91406249999999 },
      { lat: -71.52490903732816, lng: -80.15625 },
      { lat: -60.93043220292333, lng: -58.71093750000001 },
      { lat: -77.31251993823142, lng: -40.078125 },
      { lat: -68.91100484562018, lng: -10 },
      { lat: -67.2040323434008, lng: 1 },
      { lat: -85, lng: 1 },
      { lat: -85, lng: -178.9 },
    ],
  ],
  AR: [
    [
      { lat: -22.075861504812348, lng: -64.96489213729458 },
      { lat: -22.798091322523547, lng: -64.37702104354227 },
      { lat: -21.993644301035957, lng: -63.98683814152247 },
      { lat: -22.034985446869456, lng: -62.846468471921554 },
      { lat: -22.249029229422405, lng: -62.6850571356579 },
      { lat: -23.8807125790383, lng: -60.84656470400994 },
      { lat: -24.032796319273245, lng: -60.02896603050399 },
      { lat: -24.771459242453275, lng: -58.80712846539495 },
      { lat: -25.16233977630904, lng: -57.77721716981796 },
      { lat: -25.60365650808167, lng: -57.63366004091114 },
      { lat: -27.12371876394712, lng: -58.61817359071972 },
      { lat: -27.395898532828426, lng: -57.60975969097615 },
      { lat: -27.54849903738625, lng: -56.48670162619299 },
      { lat: -27.38783700939082, lng: -55.6958455063982 },
      { lat: -26.621785577096087, lng: -54.78879492859505 },
      { lat: -25.739255466415486, lng: -54.625290696823555 },
      { lat: -25.54763925547725, lng: -54.13004960795441 },
      { lat: -26.124865004177437, lng: -53.62834896504873 },
      { lat: -26.92347258881611, lng: -53.648735317587885 },
      { lat: -27.474756768505767, lng: -54.49072526713553 },
      { lat: -27.88191537853342, lng: -55.1622863429846 },
      { lat: -28.852760512000852, lng: -56.2908996242391 },
      { lat: -30.216294854454244, lng: -57.62513342958291 },
      { lat: -31.016556084926165, lng: -57.87493730328191 },
      { lat: -32.04450367607619, lng: -58.14244035504075 },
      { lat: -33.040566908502015, lng: -58.13264767112142 },
      { lat: -33.263188978815435, lng: -58.34961117209883 },
      { lat: -33.90945444105755, lng: -58.42707414410438 },
      { lat: -34.43148976007011, lng: -58.49544206402654 },
      { lat: -35.28802662530789, lng: -57.225829637263644 },
      { lat: -35.977390232081504, lng: -57.362358771378744 },
      { lat: -36.41312590916658, lng: -56.73748735210546 },
      { lat: -36.901571547189334, lng: -56.78828528504834 },
      { lat: -38.183870538079915, lng: -57.74915686708343 },
      { lat: -38.720220228837206, lng: -59.231857062401865 },
      { lat: -38.928424574541154, lng: -61.23744523786561 },
      { lat: -38.82770720800437, lng: -62.33595699731015 },
      { lat: -39.424104913084875, lng: -62.12576310896293 },
      { lat: -40.17258635840032, lng: -62.330530971919444 },
      { lat: -40.67689666113674, lng: -62.14599443220524 },
      { lat: -41.02876148861209, lng: -62.745802781816984 },
      { lat: -41.166789239263665, lng: -63.77049475773253 },
      { lat: -40.802677097335135, lng: -64.73208980981971 },
      { lat: -41.06431487402888, lng: -65.11803524439159 },
      { lat: -42.05800099056932, lng: -64.97856055363584 },
      { lat: -42.359016208669495, lng: -64.30340796574248 },
      { lat: -42.04368661882451, lng: -63.75594784204235 },
      { lat: -42.563138116222355, lng: -63.45805904809589 },
      { lat: -42.87355844499964, lng: -64.3788038804563 },
      { lat: -43.495380954767796, lng: -65.1818039618397 },
      { lat: -44.501366062193696, lng: -65.32882341171013 },
      { lat: -45.03678557716979, lng: -65.5652689276616 },
      { lat: -45.03962778094584, lng: -66.50996578638936 },
      { lat: -45.5518962542552, lng: -67.29379391139244 },
      { lat: -46.30177296324254, lng: -67.58054643418009 },
      { lat: -47.03392465595381, lng: -66.59706641301726 },
      { lat: -47.236134535511894, lng: -65.64102657740145 },
      { lat: -48.13328907653114, lng: -65.98508826360074 },
      { lat: -48.697337334996945, lng: -67.16617896184766 },
      { lat: -49.86966887797042, lng: -67.81608761256646 },
      { lat: -50.26421843851887, lng: -68.72874508327317 },
      { lat: -50.7325102679478, lng: -69.1385391913478 },
      { lat: -51.771104011594105, lng: -68.81556148952353 },
      { lat: -52.3499834061277, lng: -68.14999487982041 },
      { lat: -52.29944385534626, lng: -68.57154537624135 },
      { lat: -52.14276091263725, lng: -69.49836218939609 },
      { lat: -52.009022305865926, lng: -71.91480383979635 },
      { lat: -51.42595631287241, lng: -72.32940385607404 },
      { lat: -50.677009779666356, lng: -72.30997351753237 },
      { lat: -50.74145029073431, lng: -72.97574683296463 },
      { lat: -50.37878508890987, lng: -73.32805091011448 },
      { lat: -49.31843637471296, lng: -73.41543575712004 },
      { lat: -48.87861825947679, lng: -72.64824744331494 },
      { lat: -48.244238376661826, lng: -72.33116085477195 },
      { lat: -47.73853281025353, lng: -72.44735531278027 },
      { lat: -46.8848381487918, lng: -71.91725847033021 },
      { lat: -45.56073292417713, lng: -71.55200944689125 },
      { lat: -44.97368865334144, lng: -71.65931555854533 },
      { lat: -44.784242852559416, lng: -71.22277889675973 },
      { lat: -44.40752166115169, lng: -71.32980078803621 },
      { lat: -44.20717213315611, lng: -71.79362260607195 },
      { lat: -43.78761117937833, lng: -71.46405615913051 },
      { lat: -43.40856454851742, lng: -71.91542395698391 },
      { lat: -42.25488819760139, lng: -72.14889807807853 },
      { lat: -42.051386407235995, lng: -71.74680375841547 },
      { lat: -40.83233936947073, lng: -71.91573401557756 },
      { lat: -39.80816415787807, lng: -71.68076127794646 },
      { lat: -38.916022230791114, lng: -71.41351660834904 },
      { lat: -38.55299529394074, lng: -70.81466427273472 },
      { lat: -37.5768274879472, lng: -71.11862504747543 },
      { lat: -36.65812387466234, lng: -71.1218806627098 },
      { lat: -36.005088799789945, lng: -70.36476925320167 },
      { lat: -35.16968759535944, lng: -70.38804948594908 },
      { lat: -34.193571465798286, lng: -69.81730912950147 },
      { lat: -33.27388600029985, lng: -69.81477698431921 },
      { lat: -33.09120981214803, lng: -70.07439938015364 },
      { lat: -31.365010267870286, lng: -70.53506893581945 },
      { lat: -30.336339206668313, lng: -69.91900834825192 },
      { lat: -29.36792286551855, lng: -70.01355038112987 },
      { lat: -28.459141127233693, lng: -69.65613033718314 },
      { lat: -27.52121388113613, lng: -69.00123491074828 },
      { lat: -26.89933969493579, lng: -68.2955415513704 },
      { lat: -26.506908868111267, lng: -68.59479977077268 },
      { lat: -26.185016371365233, lng: -68.38600114609736 },
      { lat: -24.518554782816878, lng: -68.41765296087613 },
      { lat: -24.02530323659091, lng: -67.32844295924414 },
      { lat: -22.98634856536283, lng: -66.98523393417764 },
      { lat: -22.7359245744764, lng: -67.10667355006362 },
      { lat: -21.832310479420684, lng: -66.27333940292485 },
      { lat: -22.075861504812348, lng: -64.96489213729458 },
    ],
    [
      { lat: -55.2, lng: -65.5 },
      { lat: -55.25, lng: -66.45 },
      { lat: -54.89681, lng: -66.95992 },
      { lat: -54.87001, lng: -67.56244 },
      { lat: -54.8695, lng: -68.63335 },
      { lat: -52.63637045887445, lng: -68.63401022758316 },
      { lat: -53.1, lng: -68.25 },
      { lat: -53.85, lng: -67.75 },
      { lat: -54.45, lng: -66.45 },
      { lat: -54.7, lng: -65.05 },
      { lat: -55.2, lng: -65.5 },
    ],
  ],
  AT: [
    [
      { lat: 48.123497015976305, lng: 16.979666782304037 },
      { lat: 47.71486562762833, lng: 16.90375410326726 },
      { lat: 47.71290192320123, lng: 16.340584344150415 },
      { lat: 47.49617096616912, lng: 16.534267612380376 },
      { lat: 46.85238597267696, lng: 16.202298211337364 },
      { lat: 46.6836107448117, lng: 16.011663852612656 },
      { lat: 46.65870270444703, lng: 15.137091912504985 },
      { lat: 46.43181732846955, lng: 14.63247155117483 },
      { lat: 46.509306138691215, lng: 13.806475457421527 },
      { lat: 46.76755910906985, lng: 12.376485223040817 },
      { lat: 47.11539317482645, lng: 12.153088006243054 },
      { lat: 46.94157949481273, lng: 11.16482791509327 },
      { lat: 46.75135854754634, lng: 11.048555942436536 },
      { lat: 46.89354625099743, lng: 10.44270145024663 },
      { lat: 46.92072805438296, lng: 9.932448357796659 },
      { lat: 47.10280996356337, lng: 9.479969516649021 },
      { lat: 47.34760122332999, lng: 9.632931756232978 },
      { lat: 47.52505809182027, lng: 9.59422610844635 },
      { lat: 47.580196845075704, lng: 9.896068149463188 },
      { lat: 47.30248769793916, lng: 10.402083774465211 },
      { lat: 47.56639923765377, lng: 10.544504021861627 },
      { lat: 47.523766181012974, lng: 11.426414015354737 },
      { lat: 47.703083401065776, lng: 12.141357456112788 },
      { lat: 47.67238760028441, lng: 12.620759718484491 },
      { lat: 47.467645575544, lng: 12.932626987365948 },
      { lat: 47.637583523135824, lng: 13.02585127122049 },
      { lat: 48.28914581968792, lng: 12.884102817443903 },
      { lat: 48.416114813829054, lng: 13.243357374737 },
      { lat: 48.87717194273715, lng: 13.595945672264437 },
      { lat: 48.5553052842072, lng: 14.33889773932472 },
      { lat: 48.964401760445824, lng: 14.901447381254057 },
      { lat: 49.039074205107575, lng: 15.253415561593982 },
      { lat: 48.73389903420793, lng: 16.02964725105022 },
      { lat: 48.78580801044511, lng: 16.49928266771877 },
      { lat: 48.5969823268506, lng: 16.960288120194576 },
      { lat: 48.47001333270947, lng: 16.879982944413 },
      { lat: 48.123497015976305, lng: 16.979666782304037 },
    ],
  ],
  AU: [
    [
      { lat: -40.79254851660589, lng: 145.39797814349484 },
      { lat: -41.13769540788334, lng: 146.36412072162372 },
      { lat: -41.00054615658068, lng: 146.90858361225085 },
      { lat: -40.80825815202269, lng: 147.68925947488415 },
      { lat: -40.87543751400213, lng: 148.28906782449602 },
      { lat: -42.06244516374644, lng: 148.35986453673584 },
      { lat: -42.407023614268624, lng: 148.0173014670731 },
      { lat: -43.211522312188485, lng: 147.9140519553538 },
      { lat: -42.93768889747386, lng: 147.564564243764 },
      { lat: -43.634597263362096, lng: 146.87034305235494 },
      { lat: -43.58085377377856, lng: 146.66332726459368 },
      { lat: -43.54974456153889, lng: 146.04837772032042 },
      { lat: -42.69377613705627, lng: 145.43192955951056 },
      { lat: -42.03360971452756, lng: 145.2950903668017 },
      { lat: -41.162551771815714, lng: 144.71807132383063 },
      { lat: -40.70397511165771, lng: 144.74375451067968 },
      { lat: -40.79254851660589, lng: 145.39797814349484 },
    ],
    [
      { lat: -13.763655694232213, lng: 143.56181115129996 },
      { lat: -14.548310642152003, lng: 143.92209923723894 },
      { lat: -14.171176039285882, lng: 144.56371382057486 },
      { lat: -14.594457696188625, lng: 144.89490807513354 },
      { lat: -14.984976495018286, lng: 145.37472374896348 },
      { lat: -15.428205254785695, lng: 145.27199100156727 },
      { lat: -16.285672295804773, lng: 145.48525963763578 },
      { lat: -16.784918308176614, lng: 145.63703331927695 },
      { lat: -16.90692636481765, lng: 145.88890425026767 },
      { lat: -17.761654554925244, lng: 146.1603088726645 },
      { lat: -18.28007252367732, lng: 146.0636739442787 },
      { lat: -18.95827402107591, lng: 146.3874784690196 },
      { lat: -19.48072275154668, lng: 147.47108157774792 },
      { lat: -19.95593922290277, lng: 148.1776017600425 },
      { lat: -20.39120981209726, lng: 148.84841352762322 },
      { lat: -20.633468926681516, lng: 148.7174654481956 },
      { lat: -21.260510756111103, lng: 149.28942020080206 },
      { lat: -22.342511895438392, lng: 149.67833703023067 },
      { lat: -22.12278370533332, lng: 150.07738244038862 },
      { lat: -22.556142266533012, lng: 150.48293908101516 },
      { lat: -22.40240488046466, lng: 150.72726525289121 },
      { lat: -23.462236830338682, lng: 150.89955447815228 },
      { lat: -24.076256198830762, lng: 151.60917524638424 },
      { lat: -24.457886651306197, lng: 152.07353966695908 },
      { lat: -25.267501316023015, lng: 152.85519738180594 },
      { lat: -26.07117319102619, lng: 153.13616214417678 },
      { lat: -26.641319268502443, lng: 153.16194868389042 },
      { lat: -27.26029957449451, lng: 153.0929089703486 },
      { lat: -28.1100668271021, lng: 153.5694690289442 },
      { lat: -28.995077406532758, lng: 153.51210818910022 },
      { lat: -29.458201592732447, lng: 153.33909549378706 },
      { lat: -30.350240166954816, lng: 153.06924116435889 },
      { lat: -30.923641859665448, lng: 153.0896016786818 },
      { lat: -31.640445651985956, lng: 152.8915775901394 },
      { lat: -32.550002536755244, lng: 152.45000247620536 },
      { lat: -33.041342054986345, lng: 151.70911746643682 },
      { lat: -33.81602345147385, lng: 151.34397179586242 },
      { lat: -34.310360202777886, lng: 151.01055545471516 },
      { lat: -35.17345997491681, lng: 150.71413943908905 },
      { lat: -35.67187916437193, lng: 150.32821984273326 },
      { lat: -36.42020558039051, lng: 150.07521203023228 },
      { lat: -37.10905242284123, lng: 149.94612430236717 },
      { lat: -37.42526051203514, lng: 149.99728397033616 },
      { lat: -37.77268116633346, lng: 149.42388227762555 },
      { lat: -37.80906137466688, lng: 148.30462243061592 },
      { lat: -38.21921721776755, lng: 147.3817330263153 },
      { lat: -38.60653207779512, lng: 146.92212283751135 },
      { lat: -39.03575652441144, lng: 146.3179219911548 },
      { lat: -38.59376799901905, lng: 145.48965213438058 },
      { lat: -38.41744801203912, lng: 144.87697635312816 },
      { lat: -37.896187839510986, lng: 145.03221235573298 },
      { lat: -38.08532358169927, lng: 144.48568240781404 },
      { lat: -38.80946542740533, lng: 143.6099735861961 },
      { lat: -38.538267510737526, lng: 142.745426873953 },
      { lat: -38.38003427505984, lng: 142.178329705982 },
      { lat: -38.30851409276788, lng: 141.6065816591047 },
      { lat: -38.019332777662555, lng: 140.63857872941324 },
      { lat: -37.40293629328511, lng: 139.99215823787435 },
      { lat: -36.64360279718828, lng: 139.80658816951407 },
      { lat: -36.13836231867067, lng: 139.57414757706525 },
      { lat: -35.73275400161178, lng: 139.0828080588341 },
      { lat: -35.612296237939404, lng: 138.12074791885632 },
      { lat: -35.127261244447894, lng: 138.44946170466503 },
      { lat: -34.38472258884593, lng: 138.2075643251067 },
      { lat: -35.07682504653103, lng: 137.71917036351616 },
      { lat: -35.26053476332862, lng: 136.82940555231474 },
      { lat: -34.70733855564409, lng: 137.3523710471085 },
      { lat: -34.130267836240776, lng: 137.50388634658836 },
      { lat: -33.640478610978334, lng: 137.89011600153768 },
      { lat: -32.90000701266811, lng: 137.81032759007914 },
      { lat: -33.752771498348636, lng: 136.99683719294038 },
      { lat: -34.09476612725619, lng: 136.37206912653167 },
      { lat: -34.89011809666049, lng: 135.98904341038437 },
      { lat: -34.47867034275261, lng: 135.20821251845413 },
      { lat: -33.94795338311498, lng: 135.23921837782916 },
      { lat: -33.22277800876314, lng: 134.6134167827746 },
      { lat: -32.848072198214766, lng: 134.08590376193914 },
      { lat: -32.61723357516696, lng: 134.27390262261704 },
      { lat: -32.011224053680195, lng: 132.99077680880984 },
      { lat: -31.98264698662277, lng: 132.2880806825049 },
      { lat: -31.495803318001048, lng: 131.32633060112093 },
      { lat: -31.590422865527483, lng: 129.5357938986397 },
      { lat: -31.948488864877856, lng: 128.24093753470223 },
      { lat: -32.28226694105105, lng: 127.10286746633831 },
      { lat: -32.21596607842061, lng: 126.14871382050116 },
      { lat: -32.728751316052836, lng: 125.08862348846561 },
      { lat: -32.95948658623607, lng: 124.22164798390494 },
      { lat: -33.483847344701715, lng: 124.02894656788854 },
      { lat: -33.89017913181273, lng: 123.65966678273072 },
      { lat: -33.91446705498984, lng: 122.81103641163364 },
      { lat: -34.003402194964224, lng: 122.18306440642286 },
      { lat: -33.82103606540613, lng: 121.2991907085026 },
      { lat: -33.930176690406626, lng: 120.58026818245814 },
      { lat: -33.976065362281815, lng: 119.89369510302824 },
      { lat: -34.50936614353397, lng: 119.2988993673488 },
      { lat: -34.464149265278536, lng: 119.007340936358 },
      { lat: -34.7468193499151, lng: 118.5057178081008 },
      { lat: -35.064732761374714, lng: 118.02497195848953 },
      { lat: -35.02545867283287, lng: 117.29550744025747 },
      { lat: -35.02509693780683, lng: 116.62510908413495 },
      { lat: -34.386427911111554, lng: 115.56434695847972 },
      { lat: -34.196517022438925, lng: 115.02680870977954 },
      { lat: -33.62342538832203, lng: 115.04861616420679 },
      { lat: -33.48725798923296, lng: 115.5451233256671 },
      { lat: -33.25957162855495, lng: 115.71467370001668 },
      { lat: -32.90036874769413, lng: 115.6793786967614 },
      { lat: -32.20506235120703, lng: 115.80164513556397 },
      { lat: -31.61243702568379, lng: 115.68961063035513 },
      { lat: -30.60159433362246, lng: 115.16090905157697 },
      { lat: -30.030724786094165, lng: 114.99704308477945 },
      { lat: -29.461095472940798, lng: 115.04003787644628 },
      { lat: -28.810230808224713, lng: 114.64197431850201 },
      { lat: -28.516398614213042, lng: 114.61649783738217 },
      { lat: -28.11807667410733, lng: 114.17357913620847 },
      { lat: -27.334765313427127, lng: 114.04888390508816 },
      { lat: -26.543134047147902, lng: 113.4774975932369 },
      { lat: -26.116545098578484, lng: 113.3389530782625 },
      { lat: -26.54902516042918, lng: 113.77835778204026 },
      { lat: -25.621278171493156, lng: 113.44096235560662 },
      { lat: -25.911234633082884, lng: 113.93690107631167 },
      { lat: -26.298446140245872, lng: 114.23285200404732 },
      { lat: -25.786281019801105, lng: 114.21616051641703 },
      { lat: -24.998938897402127, lng: 113.72125532435771 },
      { lat: -24.683971042583153, lng: 113.62534386602405 },
      { lat: -24.38476449961327, lng: 113.39352339076267 },
      { lat: -23.806350192970257, lng: 113.50204389857564 },
      { lat: -23.560215345964068, lng: 113.70699262904517 },
      { lat: -23.059987481378737, lng: 113.8434184102957 },
      { lat: -22.47547535572538, lng: 113.7365515483161 },
      { lat: -21.755881036061012, lng: 114.1497563009219 },
      { lat: -22.517488295178634, lng: 114.22530724493268 },
      { lat: -21.829519952076904, lng: 114.64776207891869 },
      { lat: -21.495173435148544, lng: 115.46016727097933 },
      { lat: -21.06868783944371, lng: 115.94737267462702 },
      { lat: -20.70168181730682, lng: 116.71161543179156 },
      { lat: -20.623598728113805, lng: 117.16631635952771 },
      { lat: -20.746898695562162, lng: 117.44154503791427 },
      { lat: -20.374208265873236, lng: 118.22955895393298 },
      { lat: -20.26331064217483, lng: 118.83608523974273 },
      { lat: -20.044202569257322, lng: 118.98780724495177 },
      { lat: -19.95294198982984, lng: 119.25249393115065 },
      { lat: -19.976506442954985, lng: 119.80522505094457 },
      { lat: -19.68370777758919, lng: 120.85622033089666 },
      { lat: -19.239755547769732, lng: 121.39985639860723 },
      { lat: -18.705317885007133, lng: 121.65513797412909 },
      { lat: -18.19764861417177, lng: 122.24166548064177 },
      { lat: -17.798603204013915, lng: 122.28662397673567 },
      { lat: -17.25496713630345, lng: 122.31277225147544 },
      { lat: -16.405199883695857, lng: 123.01257449757193 },
      { lat: -17.268558037996225, lng: 123.43378909718304 },
      { lat: -17.069035332917252, lng: 123.85934451710662 },
      { lat: -16.596506036040367, lng: 123.50324222218326 },
      { lat: -16.111316013251994, lng: 123.81707319549193 },
      { lat: -16.327943617419564, lng: 124.25828657439988 },
      { lat: -15.567059828353976, lng: 124.37972619028582 },
      { lat: -15.075100192935324, lng: 124.92615278534005 },
      { lat: -14.680395603090004, lng: 125.16727501841389 },
      { lat: -14.510070082256021, lng: 125.67008670461385 },
      { lat: -14.230655612853838, lng: 125.6857963400305 },
      { lat: -14.347340996968953, lng: 126.12514936737611 },
      { lat: -14.095986830301213, lng: 126.14282270721989 },
      { lat: -13.95279143642041, lng: 126.58258914602376 },
      { lat: -13.817967624570926, lng: 127.06586714081735 },
      { lat: -14.276906019755046, lng: 127.80463341686195 },
      { lat: -14.869169610252257, lng: 128.35968997610897 },
      { lat: -14.875990899314742, lng: 128.98554324759593 },
      { lat: -14.969783623924556, lng: 129.62147342337963 },
      { lat: -14.420669854391035, lng: 129.409600050983 },
      { lat: -13.618703301653483, lng: 129.88864057832862 },
      { lat: -13.357375583553477, lng: 130.33946577364296 },
      { lat: -13.107520033422304, lng: 130.183506300986 },
      { lat: -12.536392103732467, lng: 130.617795037967 },
      { lat: -12.183648776908115, lng: 131.22349450086003 },
      { lat: -12.302452894747162, lng: 131.73509118054952 },
      { lat: -12.114040622611014, lng: 132.57529829318312 },
      { lat: -11.603012383676685, lng: 132.55721154188106 },
      { lat: -11.273781833545101, lng: 131.82469811414367 },
      { lat: -11.128519382372644, lng: 132.35722374891142 },
      { lat: -11.376411228076847, lng: 133.01956058159644 },
      { lat: -11.786515394745138, lng: 133.55084598198906 },
      { lat: -12.042365411022175, lng: 134.393068475482 },
      { lat: -11.9411829565947, lng: 134.67863244032705 },
      { lat: -12.248606052299051, lng: 135.29849124566803 },
      { lat: -11.962266940969798, lng: 135.88269331272764 },
      { lat: -12.04934172938161, lng: 136.25838097548947 },
      { lat: -11.857208754120393, lng: 136.49247521377166 },
      { lat: -12.351958916882737, lng: 136.95162031468502 },
      { lat: -12.887223402562057, lng: 136.68512495335577 },
      { lat: -13.291229750219898, lng: 136.30540652887512 },
      { lat: -13.324509372615893, lng: 135.96175825413414 },
      { lat: -13.724278252825783, lng: 136.07761681533256 },
      { lat: -14.223989353088214, lng: 135.78383629775325 },
      { lat: -14.7154322241839, lng: 135.42866417861123 },
      { lat: -14.99774057379443, lng: 135.50018436090318 },
      { lat: -15.550264987859123, lng: 136.29517459528137 },
      { lat: -15.870762220933356, lng: 137.0653601421595 },
      { lat: -16.215082289294084, lng: 137.58047081924482 },
      { lat: -16.807604261952658, lng: 138.303217401279 },
      { lat: -16.806622409739177, lng: 138.5851640158634 },
      { lat: -17.06267913174537, lng: 139.1085429221155 },
      { lat: -17.371600843986187, lng: 139.26057498591823 },
      { lat: -17.710804945550066, lng: 140.2152453960783 },
      { lat: -17.369068698803943, lng: 140.87546349503927 },
      { lat: -16.832047214426723, lng: 141.0711104676963 },
      { lat: -16.388870131091608, lng: 141.27409549373883 },
      { lat: -15.840531508042588, lng: 141.3982222841038 },
      { lat: -15.04492115647693, lng: 141.70218305884467 },
      { lat: -14.56133310308951, lng: 141.5633801617087 },
      { lat: -14.270394789286284, lng: 141.63552046118812 },
      { lat: -13.698078301653808, lng: 141.51986860571898 },
      { lat: -12.944687595270565, lng: 141.650920038011 },
      { lat: -12.74154753993119, lng: 141.84269127824624 },
      { lat: -12.407614434461138, lng: 141.6869901877508 },
      { lat: -11.87746591557878, lng: 141.92862918514757 },
      { lat: -11.32804208745162, lng: 142.118488397388 },
      { lat: -11.042736504768143, lng: 142.14370649634637 },
      { lat: -10.668185723516643, lng: 142.51526004452498 },
      { lat: -11.157354831591519, lng: 142.79731001197408 },
      { lat: -11.784706719614931, lng: 142.8667631369743 },
      { lat: -11.905629571177911, lng: 143.1159468934857 },
      { lat: -12.325655612846191, lng: 143.1586316265588 },
      { lat: -12.834358412327433, lng: 143.5221236512999 },
      { lat: -13.400422051652598, lng: 143.5971578309877 },
      { lat: -13.763655694232213, lng: 143.56181115129996 },
    ],
  ],
  AW: [
    [
      { lat: 12.64368830107626, lng: -430.0831604003906 },
      { lat: 12.534455920308705, lng: -430.0872802734375 },
      { lat: 12.376221536966495, lng: -429.8908996582031 },
      { lat: 12.401706465516053, lng: -429.8407745361328 },
      { lat: 12.48350962924727, lng: -429.8407745361328 },
      { lat: 12.641678309810462, lng: -430.04264831542963 },
      { lat: 12.64368830107626, lng: -430.0831604003906 },
    ],
  ],
  AX: [
    [
      { lat: 60.48835098696415, lng: -340.4965209960937 },
      { lat: 60.27251459483244, lng: -340.7904052734375 },
      { lat: 60.1551760158896, lng: -340.7574462890625 },
      { lat: 59.85998841267076, lng: -339.83184814453125 },
      { lat: 59.75086102411168, lng: -338.9694213867187 },
      { lat: 60.02506916062371, lng: -338.84857177734375 },
      { lat: 60.654339553149704, lng: -338.90350341796875 },
      { lat: 60.48835098696415, lng: -340.4965209960937 },
    ],
  ],
  AZ: [
    [
      { lat: 39.7400035670496, lng: 45.0019873390568 },
      { lat: 39.471751207022436, lng: 45.29814497252144 },
      { lat: 39.473999131827156, lng: 45.739978468617004 },
      { lat: 39.3197191432198, lng: 45.73537926614309 },
      { lat: 38.74120148371222, lng: 46.14362308124881 },
      { lat: 38.874139105783115, lng: 45.457721795438744 },
      { lat: 39.33576467544643, lng: 44.95268802265028 },
      { lat: 39.713002631177034, lng: 44.793989699082005 },
      { lat: 39.7400035670496, lng: 45.0019873390568 },
    ],
    [
      { lat: 41.219732367511256, lng: 47.373315464066216 },
      { lat: 41.151416124021345, lng: 47.81566572448472 },
      { lat: 41.40581920019423, lng: 47.98728315612604 },
      { lat: 41.808869533854676, lng: 48.58435265482629 },
      { lat: 41.282286688800525, lng: 49.11026370626067 },
      { lat: 40.57292430272997, lng: 49.6189148293096 },
      { lat: 40.52615713150578, lng: 50.0848295428531 },
      { lat: 40.256561184239104, lng: 50.39282107931271 },
      { lat: 40.17610097916071, lng: 49.5692021014448 },
      { lat: 39.39948171646225, lng: 49.39525923035043 },
      { lat: 39.04921885838792, lng: 49.223228387250714 },
      { lat: 38.81548635513178, lng: 48.85653242370759 },
      { lat: 38.32024526626264, lng: 48.88324913920255 },
      { lat: 38.27037750910094, lng: 48.634375441284845 },
      { lat: 38.794014797514535, lng: 48.010744256386516 },
      { lat: 39.28876496027689, lng: 48.355529412637935 },
      { lat: 39.582235419262446, lng: 48.06009524922527 },
      { lat: 39.50836395930119, lng: 47.685079380083124 },
      { lat: 38.770605373686266, lng: 46.50571984231797 },
      { lat: 39.464154771475535, lng: 46.48349897643246 },
      { lat: 39.62802073827305, lng: 46.034534132680704 },
      { lat: 39.89999380142518, lng: 45.61001224140293 },
      { lat: 40.218475653639985, lng: 45.89190717955515 },
      { lat: 40.56150381119349, lng: 45.35917483905817 },
      { lat: 40.81228953710595, lng: 45.560351189970476 },
      { lat: 40.98535390885143, lng: 45.1794958839794 },
      { lat: 41.24812856705562, lng: 44.972480096218156 },
      { lat: 41.41145193131405, lng: 45.21742638528164 },
      { lat: 41.1238725856098, lng: 45.962600538930445 },
      { lat: 41.06444468847411, lng: 46.501637404166985 },
      { lat: 41.181672675128226, lng: 46.637908156120574 },
      { lat: 41.72280243587264, lng: 46.14543175637899 },
      { lat: 41.86067515722735, lng: 46.404950799348825 },
      { lat: 41.827137152669906, lng: 46.68607059101666 },
      { lat: 41.219732367511256, lng: 47.373315464066216 },
    ],
  ],
  BA: [
    [
      { lat: 44.86023366960916, lng: 19.00548628101012 },
      { lat: 44.863, lng: 19.36803 },
      { lat: 44.42307000000011, lng: 19.11761 },
      { lat: 44.03847, lng: 19.59976 },
      { lat: 43.56810000000013, lng: 19.454 },
      { lat: 43.52384, lng: 19.21852 },
      { lat: 43.43253, lng: 19.03165 },
      { lat: 43.20011, lng: 18.70648 },
      { lat: 42.65, lng: 18.56 },
      { lat: 43.02856252702361, lng: 17.674921502358984 },
      { lat: 43.44634064388736, lng: 17.297373488034452 },
      { lat: 43.66772247982567, lng: 16.91615644701733 },
      { lat: 44.04123973243128, lng: 16.456442905348865 },
      { lat: 44.35114329688571, lng: 16.23966027188453 },
      { lat: 44.81871165626256, lng: 15.750026075918981 },
      { lat: 45.233776760430935, lng: 15.959367303133376 },
      { lat: 45.00412669532591, lng: 16.318156772535872 },
      { lat: 45.21160757097772, lng: 16.534939406000206 },
      { lat: 45.233776760430935, lng: 17.002146030351014 },
      { lat: 45.067740383477144, lng: 17.861783481526402 },
      { lat: 45.08158966733145, lng: 18.553214145591653 },
      { lat: 44.86023366960916, lng: 19.00548628101012 },
    ],
  ],
  BB: [
    [
      { lat: 13.28500245774, lng: -59.646240112791 },
      { lat: 13.320555594458, lng: -59.587597031 },
      { lat: 13.248982398926, lng: -59.502775364625 },
      { lat: 13.146760513638, lng: -59.428861800955 },
      { lat: 13.097768839352, lng: -59.462053981321 },
      { lat: 13.05558614798, lng: -59.522239384378 },
      { lat: 13.062071852813, lng: -59.552013064635 },
      { lat: 13.113160157794, lng: -59.630864049709 },
      { lat: 13.147643461789, lng: -59.653512620904 },
      { lat: 13.173877273737, lng: -59.659097078172 },
      { lat: 13.28500245774, lng: -59.646240112791 },
    ],
  ],
  BD: [
    [
      { lat: 22.041238918541254, lng: 92.67272098182556 },
      { lat: 21.324047552978485, lng: 92.65225711463799 },
      { lat: 21.47548533780982, lng: 92.30323449093868 },
      { lat: 20.670883287025347, lng: 92.36855350135562 },
      { lat: 21.19219513598577, lng: 92.08288618364612 },
      { lat: 21.701569729086767, lng: 92.02521528520839 },
      { lat: 22.18293569588556, lng: 91.83489098507744 },
      { lat: 22.76501902922122, lng: 91.41708702999766 },
      { lat: 22.80501658781513, lng: 90.49600630082728 },
      { lat: 22.392793687422866, lng: 90.58695682166098 },
      { lat: 21.83636770272011, lng: 90.27297081905554 },
      { lat: 22.039146023033425, lng: 89.84746707556428 },
      { lat: 21.857115790285306, lng: 89.70204959509493 },
      { lat: 21.9661789006373, lng: 89.41886274613549 },
      { lat: 22.055708319582976, lng: 89.03196129756623 },
      { lat: 22.879146429937826, lng: 88.87631188350309 },
      { lat: 23.631141872649163, lng: 88.52976972855377 },
      { lat: 24.23371491138856, lng: 88.69994022009092 },
      { lat: 24.501657212821925, lng: 88.08442223506242 },
      { lat: 24.866079413344206, lng: 88.30637251175602 },
      { lat: 25.238692328384776, lng: 88.93155398962308 },
      { lat: 25.76806570078271, lng: 88.2097892598025 },
      { lat: 26.44652558034272, lng: 88.56304935094977 },
      { lat: 26.014407253518073, lng: 89.35509402868729 },
      { lat: 25.96508209889548, lng: 89.83248091019962 },
      { lat: 25.26974986419218, lng: 89.92069258012185 },
      { lat: 25.132600612889547, lng: 90.8722107279121 },
      { lat: 25.147431748957317, lng: 91.79959598182207 },
      { lat: 24.976692816664965, lng: 92.37620161333481 },
      { lat: 24.13041372323711, lng: 91.91509280799443 },
      { lat: 24.072639471934792, lng: 91.46772993364367 },
      { lat: 23.50352692310439, lng: 91.15896325069971 },
      { lat: 22.985263983649183, lng: 91.70647505083211 },
      { lat: 23.624346421802784, lng: 91.86992760617132 },
      { lat: 23.627498684172593, lng: 92.14603478390681 },
      { lat: 22.041238918541254, lng: 92.67272098182556 },
    ],
  ],
  BE: [
    [
      { lat: 51.345780951536085, lng: 3.314971144228537 },
      { lat: 51.26725861266857, lng: 4.047071160507527 },
      { lat: 51.475023708698124, lng: 4.973991326526914 },
      { lat: 51.037298488969775, lng: 5.606975945670001 },
      { lat: 50.80372101501058, lng: 6.156658155958779 },
      { lat: 50.128051662794235, lng: 6.043073357781111 },
      { lat: 50.09032786722122, lng: 5.782417433300906 },
      { lat: 49.529483547557504, lng: 5.674051954784829 },
      { lat: 49.985373033236385, lng: 4.799221632515809 },
      { lat: 49.907496649772554, lng: 4.286022983425084 },
      { lat: 50.37899241800358, lng: 3.588184441755686 },
      { lat: 50.780363267614575, lng: 3.123251580425801 },
      { lat: 50.79684804951574, lng: 2.658422071960274 },
      { lat: 51.14850617126183, lng: 2.513573032246143 },
      { lat: 51.345780951536085, lng: 3.314971144228537 },
    ],
  ],
  BF: [
    [
      { lat: 9.642460842319778, lng: -2.827496303712707 },
      { lat: 9.90032623945622, lng: -3.511898972986273 },
      { lat: 9.8623440617217, lng: -3.980449184576685 },
      { lat: 9.61083486575714, lng: -4.330246954760383 },
      { lat: 9.821984768101743, lng: -4.779883592131966 },
      { lat: 10.152713934769736, lng: -4.954653286143099 },
      { lat: 10.370736802609146, lng: -5.404341599946974 },
      { lat: 10.951269842976048, lng: -5.470564947929006 },
      { lat: 11.37514577885014, lng: -5.197842576508648 },
      { lat: 11.713858954307227, lng: -5.220941941743121 },
      { lat: 12.542645575404295, lng: -4.427166103523803 },
      { lat: 13.228443508349741, lng: -4.28040503581488 },
      { lat: 13.472485459848116, lng: -4.006390753587226 },
      { lat: 13.337661647998615, lng: -3.522802700199861 },
      { lat: 13.541266791228594, lng: -3.10370683431276 },
      { lat: 13.79815033615151, lng: -2.967694464520577 },
      { lat: 14.246417548067356, lng: -2.191824510090385 },
      { lat: 14.559008287000891, lng: -2.001035122068771 },
      { lat: 14.973815009007765, lng: -1.066363491205664 },
      { lat: 15.116157741755726, lng: -0.515854458000348 },
      { lat: 14.924308986872148, lng: -0.26625729003058 },
      { lat: 14.928908189346132, lng: 0.374892205414682 },
      { lat: 14.444234930880654, lng: 0.295646396495101 },
      { lat: 13.988733018443924, lng: 0.429927605805517 },
      { lat: 13.335749620003824, lng: 0.993045688490071 },
      { lat: 12.851825669806574, lng: 1.024103224297477 },
      { lat: 12.625017808477534, lng: 2.177107781593776 },
      { lat: 11.940150051313337, lng: 2.154473504249921 },
      { lat: 11.641150214072553, lng: 1.935985548519881 },
      { lat: 11.547719224488858, lng: 1.447178175471066 },
      { lat: 11.110510769083461, lng: 1.243469679376489 },
      { lat: 10.99733938236426, lng: 0.899563022474069 },
      { lat: 11.018681748900804, lng: 0.023802524423701 },
      { lat: 11.09834096927872, lng: -0.438701544588582 },
      { lat: 10.936929633015055, lng: -0.761575893548183 },
      { lat: 11.009819240762738, lng: -1.203357713211431 },
      { lat: 10.962690334512558, lng: -2.940409308270461 },
      { lat: 10.395334784380083, lng: -2.963896246747112 },
      { lat: 9.642460842319778, lng: -2.827496303712707 },
    ],
  ],
  BG: [
    [
      { lat: 44.23492300066128, lng: 22.65714969248299 },
      { lat: 43.82378530534713, lng: 22.944832391051847 },
      { lat: 43.89701080990471, lng: 23.33230228037632 },
      { lat: 43.74105133724785, lng: 24.100679152124172 },
      { lat: 43.68844472917472, lng: 25.569271681426926 },
      { lat: 43.94349376075126, lng: 26.065158725699746 },
      { lat: 44.175986029632405, lng: 27.242399529740908 },
      { lat: 43.81246816667521, lng: 27.970107049275075 },
      { lat: 43.70746165625813, lng: 28.558081495891997 },
      { lat: 43.293171698574184, lng: 28.03909508638472 },
      { lat: 42.577892361006214, lng: 27.67389773937805 },
      { lat: 42.00735871028779, lng: 27.99672041190539 },
      { lat: 42.14148489030134, lng: 27.135739373490477 },
      { lat: 41.82690460872456, lng: 26.117041863720797 },
      { lat: 41.32889883072778, lng: 26.106138136507212 },
      { lat: 41.23448598893053, lng: 25.197201368925445 },
      { lat: 41.583896185872035, lng: 24.49264489105803 },
      { lat: 41.30908091894385, lng: 23.692073601992348 },
      { lat: 41.33799388281115, lng: 22.952377150166452 },
      { lat: 41.99929718685026, lng: 22.88137373219743 },
      { lat: 42.32025950781509, lng: 22.380525750424592 },
      { lat: 42.46136200618804, lng: 22.54501183440962 },
      { lat: 42.580321153323936, lng: 22.43659467946128 },
      { lat: 42.898518785161144, lng: 22.60480146657133 },
      { lat: 43.211161200526966, lng: 22.986018507588483 },
      { lat: 43.64281443946099, lng: 22.50015669118028 },
      { lat: 44.00806346289995, lng: 22.410446404721597 },
      { lat: 44.23492300066128, lng: 22.65714969248299 },
    ],
  ],
  BH: [
    [
      { lat: 26.079486759594, lng: 50.441498315672 },
      { lat: 26.155368387131, lng: 50.444990826448 },
      { lat: 26.240297244012, lng: 50.494398435257 },
      { lat: 26.252673936522, lng: 50.565645056344 },
      { lat: 26.247660903832, lng: 50.58600454793 },
      { lat: 25.943018578555, lng: 50.628002395199 },
      { lat: 25.857704991003, lng: 50.610418895937 },
      { lat: 25.815491188858, lng: 50.5840692813 },
      { lat: 25.868375997347, lng: 50.508839915777 },
      { lat: 25.954651764437, lng: 50.459210656002 },
      { lat: 26.079486759594, lng: 50.441498315672 },
    ],
  ],
  BI: [
    [
      { lat: -4.499983412294092, lng: 29.339997592900346 },
      { lat: -3.293907159034063, lng: 29.276383904749053 },
      { lat: -2.839257907730158, lng: 29.024926385216787 },
      { lat: -2.917857761246097, lng: 29.632176141078588 },
      { lat: -2.348486830254238, lng: 29.938359002407942 },
      { lat: -2.413857517103458, lng: 30.469696079232985 },
      { lat: -2.807631931167535, lng: 30.527677036264464 },
      { lat: -3.034284763199686, lng: 30.7430127296247 },
      { lat: -3.35932952231557, lng: 30.75226281100495 },
      { lat: -3.568567396665365, lng: 30.505559523243566 },
      { lat: -4.090137627787243, lng: 30.116332635221173 },
      { lat: -4.452389418153281, lng: 29.753512404099922 },
      { lat: -4.499983412294092, lng: 29.339997592900346 },
    ],
  ],
  BJ: [
    [
      { lat: 6.258817246928629, lng: 2.691701694356254 },
      { lat: 6.142157701029731, lng: 1.865240512712319 },
      { lat: 6.832038072126237, lng: 1.618950636409238 },
      { lat: 9.12859039960938, lng: 1.664477573258381 },
      { lat: 9.334624335157088, lng: 1.46304284018467 },
      { lat: 9.825395412633, lng: 1.425060662450136 },
      { lat: 10.175606594275024, lng: 1.077795037448738 },
      { lat: 10.470808213742359, lng: 0.772335646171484 },
      { lat: 10.99733938236426, lng: 0.899563022474069 },
      { lat: 11.110510769083461, lng: 1.243469679376489 },
      { lat: 11.547719224488858, lng: 1.447178175471066 },
      { lat: 11.641150214072553, lng: 1.935985548519881 },
      { lat: 11.940150051313337, lng: 2.154473504249921 },
      { lat: 12.233052069543675, lng: 2.49016360841793 },
      { lat: 12.235635891158267, lng: 2.848643019226671 },
      { lat: 11.660167141155966, lng: 3.611180454125559 },
      { lat: 11.327939357951518, lng: 3.572216424177469 },
      { lat: 10.734745591673105, lng: 3.797112257511714 },
      { lat: 10.332186184119408, lng: 3.600070021182801 },
      { lat: 10.063210354040208, lng: 3.705438266625919 },
      { lat: 9.4441525333997, lng: 3.220351596702101 },
      { lat: 9.13760793704432, lng: 2.912308383810256 },
      { lat: 8.50684540448971, lng: 2.723792758809509 },
      { lat: 7.870734361192888, lng: 2.74906253420022 },
      { lat: 6.258817246928629, lng: 2.691701694356254 },
    ],
  ],
  BL: [
    [
      { lat: 17.964363928277226, lng: -422.9183578491211 },
      { lat: 17.951953252510396, lng: -422.9180145263672 },
      { lat: 17.8640738548184, lng: -422.8376770019531 },
      { lat: 17.866034464683466, lng: -422.80677795410156 },
      { lat: 17.911775884637446, lng: -422.7799987792969 },
      { lat: 17.955545906147556, lng: -422.81810760498047 },
      { lat: 17.966976591083714, lng: -422.88883209228516 },
      { lat: 17.964363928277226, lng: -422.9183578491211 },
    ],
  ],
  BN: [
    [
      { lat: 4.525873928236805, lng: 114.20401655482837 },
      { lat: 4.900011298029966, lng: 114.59996137904872 },
      { lat: 5.447729803891534, lng: 115.45071048386981 },
      { lat: 4.955227565933839, lng: 115.4057003113436 },
      { lat: 4.316636053887009, lng: 115.34746097215067 },
      { lat: 4.348313706881925, lng: 114.8695573263154 },
      { lat: 4.007636826997754, lng: 114.65959598191353 },
      { lat: 4.525873928236805, lng: 114.20401655482837 },
    ],
  ],
  BO: [
    [
      { lat: -22.03498544686945, lng: -62.846468471921554 },
      { lat: -21.99364430103595, lng: -63.98683814152247 },
      { lat: -22.79809132252354, lng: -64.37702104354226 },
      { lat: -22.075861504812327, lng: -64.9648921372946 },
      { lat: -21.83231047942072, lng: -66.27333940292485 },
      { lat: -22.735924574476414, lng: -67.1066735500636 },
      { lat: -22.872918796482175, lng: -67.82817989772273 },
      { lat: -21.494346612231865, lng: -68.21991309271128 },
      { lat: -20.372657972904463, lng: -68.75716712103375 },
      { lat: -19.40506845467143, lng: -68.44222510443092 },
      { lat: -18.981683444904107, lng: -68.96681840684187 },
      { lat: -18.260125420812674, lng: -69.10024695501949 },
      { lat: -17.580011895419332, lng: -69.59042375352405 },
      { lat: -16.50069793057127, lng: -68.9596353827533 },
      { lat: -15.660129082911654, lng: -69.38976416693471 },
      { lat: -15.323973890853019, lng: -69.16034664577495 },
      { lat: -14.953195489158832, lng: -69.33953467474701 },
      { lat: -14.453639418193283, lng: -68.9488866848366 },
      { lat: -13.602683607643007, lng: -68.92922380234954 },
      { lat: -12.899729099176653, lng: -68.88007951523997 },
      { lat: -12.561300144097173, lng: -68.66507971868961 },
      { lat: -10.951734307502194, lng: -69.52967810736496 },
      { lat: -11.03638030359628, lng: -68.78615759954948 },
      { lat: -11.01452117273682, lng: -68.27125362819326 },
      { lat: -10.712059014532485, lng: -68.04819230820539 },
      { lat: -10.30681243249961, lng: -67.17380123561074 },
      { lat: -9.931331475466862, lng: -66.64690833196279 },
      { lat: -9.76198780684639, lng: -65.33843522811642 },
      { lat: -10.511451104375432, lng: -65.44483700220539 },
      { lat: -10.895872084194679, lng: -65.32189876978302 },
      { lat: -11.566270440317153, lng: -65.40228146021303 },
      { lat: -12.461978041232191, lng: -64.3163529120316 },
      { lat: -12.627032565972433, lng: -63.19649878605057 },
      { lat: -13.000653171442686, lng: -62.80306026879638 },
      { lat: -13.198780612849724, lng: -62.127080857986385 },
      { lat: -13.489202162330052, lng: -61.71320431176078 },
      { lat: -13.479383640194598, lng: -61.08412126325565 },
      { lat: -13.775954685117659, lng: -60.503304002511136 },
      { lat: -14.354007256734555, lng: -60.45919816755003 },
      { lat: -14.64597909918364, lng: -60.26432634137736 },
      { lat: -15.077218926659322, lng: -60.251148851142936 },
      { lat: -15.093910414289596, lng: -60.542965664295146 },
      { lat: -16.258283786690082, lng: -60.158389655179036 },
      { lat: -16.299573256091293, lng: -58.24121985536669 },
      { lat: -16.877109063385276, lng: -58.38805843772404 },
      { lat: -17.271710300366017, lng: -58.28080400250226 },
      { lat: -17.55246835700777, lng: -57.734558274961 },
      { lat: -18.174187513911292, lng: -57.498371141170985 },
      { lat: -18.961839694904025, lng: -57.67600887717431 },
      { lat: -19.40000416430682, lng: -57.949997321185826 },
      { lat: -19.969995212486186, lng: -57.85380164247451 },
      { lat: -20.176700941653678, lng: -58.166392381408045 },
      { lat: -19.868399346600363, lng: -58.183471442280506 },
      { lat: -19.356906019775398, lng: -59.115042487206104 },
      { lat: -19.342746677327426, lng: -60.04356462262649 },
      { lat: -19.633736667562964, lng: -61.786326463453776 },
      { lat: -20.513734633061276, lng: -62.26596126977079 },
      { lat: -21.05163461678739, lng: -62.29117936872922 },
      { lat: -22.249029229422387, lng: -62.685057135657885 },
      { lat: -22.03498544686945, lng: -62.846468471921554 },
    ],
  ],
  BQ: [
    [
      { lat: 12.314510891208819, lng: -428.4654235839844 },
      { lat: 12.203126750176567, lng: -428.4544372558594 },
      { lat: 12.165540789425215, lng: -428.3638000488281 },
      { lat: 11.9949950990341, lng: -428.2786560058594 },
      { lat: 12.028575662342247, lng: -428.1646728515625 },
      { lat: 12.228628473803463, lng: -428.1660461425781 },
      { lat: 12.345368032463298, lng: -428.3555603027344 },
      { lat: 12.314510891208819, lng: -428.4654235839844 },
    ],
  ],
  BR: [
    [
      { lat: -30.216294854454258, lng: -57.62513342958296 },
      { lat: -28.85276051200089, lng: -56.29089962423908 },
      { lat: -27.881915378533463, lng: -55.16228634298457 },
      { lat: -27.47475676850579, lng: -54.490725267135524 },
      { lat: -26.923472588816086, lng: -53.648735317587885 },
      { lat: -26.124865004177472, lng: -53.62834896504874 },
      { lat: -25.547639255477254, lng: -54.13004960795439 },
      { lat: -25.73925546641551, lng: -54.625290696823576 },
      { lat: -25.162184747012166, lng: -54.42894609233059 },
      { lat: -24.570799655863965, lng: -54.29347632507745 },
      { lat: -24.02101409271073, lng: -54.29295956075451 },
      { lat: -23.83957813893396, lng: -54.652834235235126 },
      { lat: -24.001273695575225, lng: -55.02790178080954 },
      { lat: -23.956935316668805, lng: -55.40074723979542 },
      { lat: -23.571997572526634, lng: -55.517639329639636 },
      { lat: -22.655619398694842, lng: -55.610682745981144 },
      { lat: -22.356929620047822, lng: -55.79795813660691 },
      { lat: -22.086300144135283, lng: -56.47331743022939 },
      { lat: -22.28215382252148, lng: -56.8815095689029 },
      { lat: -22.090175876557172, lng: -57.937155727761294 },
      { lat: -20.73268767668195, lng: -57.87067399761779 },
      { lat: -20.176700941653678, lng: -58.166392381408045 },
      { lat: -19.969995212486186, lng: -57.85380164247451 },
      { lat: -19.40000416430682, lng: -57.949997321185826 },
      { lat: -18.961839694904025, lng: -57.67600887717431 },
      { lat: -18.174187513911292, lng: -57.498371141170985 },
      { lat: -17.55246835700777, lng: -57.734558274961 },
      { lat: -17.271710300366017, lng: -58.28080400250226 },
      { lat: -16.877109063385276, lng: -58.38805843772404 },
      { lat: -16.299573256091293, lng: -58.24121985536669 },
      { lat: -16.258283786690082, lng: -60.158389655179036 },
      { lat: -15.093910414289596, lng: -60.542965664295146 },
      { lat: -15.077218926659322, lng: -60.251148851142936 },
      { lat: -14.64597909918364, lng: -60.26432634137736 },
      { lat: -14.354007256734555, lng: -60.45919816755003 },
      { lat: -13.775954685117659, lng: -60.503304002511136 },
      { lat: -13.479383640194598, lng: -61.08412126325565 },
      { lat: -13.489202162330052, lng: -61.71320431176078 },
      { lat: -13.198780612849724, lng: -62.127080857986385 },
      { lat: -13.000653171442686, lng: -62.80306026879638 },
      { lat: -12.627032565972433, lng: -63.19649878605057 },
      { lat: -12.461978041232191, lng: -64.3163529120316 },
      { lat: -11.566270440317153, lng: -65.40228146021303 },
      { lat: -10.895872084194679, lng: -65.32189876978302 },
      { lat: -10.511451104375432, lng: -65.44483700220539 },
      { lat: -9.76198780684639, lng: -65.33843522811642 },
      { lat: -9.931331475466862, lng: -66.64690833196279 },
      { lat: -10.30681243249961, lng: -67.17380123561074 },
      { lat: -10.712059014532485, lng: -68.04819230820539 },
      { lat: -11.01452117273682, lng: -68.27125362819326 },
      { lat: -11.03638030359628, lng: -68.78615759954948 },
      { lat: -10.951734307502194, lng: -69.52967810736496 },
      { lat: -11.123971856331012, lng: -70.0937522040469 },
      { lat: -11.009146823778465, lng: -70.54868567572841 },
      { lat: -9.490118096558845, lng: -70.48189388699117 },
      { lat: -10.079436130415374, lng: -71.30241227892154 },
      { lat: -10.053597914269432, lng: -72.18489071316984 },
      { lat: -9.520193780152717, lng: -72.56303300646564 },
      { lat: -9.462212823121234, lng: -73.22671342639016 },
      { lat: -9.03283334720806, lng: -73.01538265653254 },
      { lat: -8.424446709835834, lng: -73.57105933296707 },
      { lat: -7.523829847853064, lng: -73.98723548042966 },
      { lat: -7.340998630404414, lng: -73.7234014553635 },
      { lat: -6.91859547285064, lng: -73.72448666044164 },
      { lat: -6.629930922068239, lng: -73.1200274319236 },
      { lat: -6.089188734566078, lng: -73.21971126981461 },
      { lat: -5.741251315944893, lng: -72.96450720894119 },
      { lat: -5.274561455916981, lng: -72.89192765978726 },
      { lat: -4.593982842633011, lng: -71.74840572781655 },
      { lat: -4.401591485210368, lng: -70.92884334988358 },
      { lat: -4.251264743673303, lng: -70.7947688463023 },
      { lat: -4.298186944194327, lng: -69.89363521999663 },
      { lat: -1.556287123219818, lng: -69.44410193548961 },
      { lat: -1.122618503426409, lng: -69.42048580593223 },
      { lat: -0.549991957200163, lng: -69.5770653957766 },
      { lat: -0.185156345219539, lng: -70.02065589057005 },
      { lat: 0.541414292804205, lng: -70.0155657619893 },
      { lat: 0.706158758950693, lng: -69.45239600287246 },
      { lat: 0.602650865070075, lng: -69.25243404811906 },
      { lat: 0.985676581217433, lng: -69.21863766140018 },
      { lat: 1.089081122233466, lng: -69.80459672715773 },
      { lat: 1.714805202639624, lng: -69.81697323269162 },
      { lat: 1.692455145673392, lng: -67.86856502955884 },
      { lat: 2.03716278727633, lng: -67.53781002467468 },
      { lat: 1.719998684084956, lng: -67.25999752467358 },
      { lat: 1.130112209473225, lng: -67.0650481838525 },
      { lat: 1.253360500489336, lng: -66.87632585312258 },
      { lat: 0.724452215982012, lng: -66.32576514348496 },
      { lat: 0.78925446207603, lng: -65.54826738143757 },
      { lat: 1.0952822941085, lng: -65.35471330428837 },
      { lat: 1.328730576987042, lng: -64.61101192895985 },
      { lat: 1.49285492594602, lng: -64.19930579289051 },
      { lat: 1.91636912679408, lng: -64.08308549666609 },
      { lat: 2.200899562993129, lng: -63.36878801131166 },
      { lat: 2.411067613124174, lng: -63.42286739770512 },
      { lat: 2.497005520025567, lng: -64.26999915226578 },
      { lat: 3.126786200366624, lng: -64.40882788761792 },
      { lat: 3.797210394705246, lng: -64.36849443221409 },
      { lat: 4.056445217297423, lng: -64.81606401229402 },
      { lat: 4.14848094320925, lng: -64.62865943058755 },
      { lat: 4.020530096854571, lng: -63.88834286157416 },
      { lat: 3.770571193858785, lng: -63.0931975978991 },
      { lat: 4.006965033377952, lng: -62.804533047116706 },
      { lat: 4.162123521334308, lng: -62.08542965355914 },
      { lat: 4.536467596856639, lng: -60.96689327660153 },
      { lat: 4.91809804933213, lng: -60.60117916527194 },
      { lat: 5.200277207861901, lng: -60.73357418480372 },
      { lat: 5.244486395687602, lng: -60.21368343773133 },
      { lat: 5.014061184098139, lng: -59.98095862490488 },
      { lat: 4.574966538914083, lng: -60.11100236676737 },
      { lat: 4.423502915866607, lng: -59.767405768458715 },
      { lat: 3.958802598481938, lng: -59.53803992373123 },
      { lat: 3.606498521332085, lng: -59.81541317405786 },
      { lat: 2.755232652188056, lng: -59.97452490908456 },
      { lat: 2.24963043864436, lng: -59.71854570172674 },
      { lat: 1.786893825686789, lng: -59.64604366722126 },
      { lat: 1.317697658692722, lng: -59.03086157900265 },
      { lat: 1.268088283692521, lng: -58.5400129868783 },
      { lat: 1.463941962078721, lng: -58.42947709820596 },
      { lat: 1.507195135907025, lng: -58.11344987652502 },
      { lat: 1.682584947105639, lng: -57.66097103537737 },
      { lat: 1.94853770589576, lng: -57.335822923396904 },
      { lat: 1.863710842288654, lng: -56.78270423036083 },
      { lat: 1.899522609866921, lng: -56.539385748914555 },
      { lat: 1.817667141116601, lng: -55.99569800477175 },
      { lat: 2.02199575439866, lng: -55.905600145070885 },
      { lat: 2.220794989425499, lng: -56.0733418442903 },
      { lat: 2.510363877773017, lng: -55.973322109589375 },
      { lat: 2.421506252447131, lng: -55.569755011606 },
      { lat: 2.523748073736613, lng: -55.09758744975514 },
      { lat: 2.311848863123785, lng: -54.52475419779971 },
      { lat: 2.105556545414629, lng: -54.08806250671724 },
      { lat: 2.376702785650082, lng: -53.77852067728892 },
      { lat: 2.334896551925951, lng: -53.55483924011354 },
      { lat: 2.053389187015981, lng: -53.4184651352953 },
      { lat: 2.124857692875636, lng: -52.939657151894956 },
      { lat: 2.504705308437053, lng: -52.55642473001842 },
      { lat: 3.241094468596245, lng: -52.249337531123956 },
      { lat: 4.156232408053029, lng: -51.65779741067888 },
      { lat: 4.203490505383954, lng: -51.31714636901086 },
      { lat: 3.650397650564031, lng: -51.069771287629656 },
      { lat: 1.901563828942457, lng: -50.508875291533656 },
      { lat: 1.736483465986069, lng: -49.97407589374506 },
      { lat: 1.046189683431223, lng: -49.947100796088705 },
      { lat: 0.222984117021682, lng: -50.699251268096916 },
      { lat: -0.078444512536819, lng: -50.38821082213214 },
      { lat: -0.235489190271821, lng: -48.62056677915631 },
      { lat: -1.237805271005001, lng: -48.58449662941659 },
      { lat: -0.5816179337628, lng: -47.824956427590635 },
      { lat: -0.941027520352776, lng: -46.566583624851226 },
      { lat: -1.551739597178134, lng: -44.905703090990414 },
      { lat: -2.137750339367976, lng: -44.417619187993665 },
      { lat: -2.691308282078524, lng: -44.58158850765578 },
      { lat: -2.383110039889793, lng: -43.418791266440195 },
      { lat: -2.912018324397116, lng: -41.47265682632825 },
      { lat: -2.873054294449041, lng: -39.97866533055404 },
      { lat: -3.700652357603395, lng: -38.50038347019657 },
      { lat: -4.820945733258917, lng: -37.2232521225352 },
      { lat: -5.109403578312153, lng: -36.45293738457639 },
      { lat: -5.149504489770648, lng: -35.59779578301047 },
      { lat: -5.464937432480247, lng: -35.23538896334756 },
      { lat: -6.738193047719711, lng: -34.89602983248683 },
      { lat: -7.343220716992966, lng: -34.729993455533034 },
      { lat: -8.996401462442286, lng: -35.12821204277422 },
      { lat: -9.649281508017815, lng: -35.636966518687714 },
      { lat: -11.040721123908801, lng: -37.046518724097 },
      { lat: -12.171194756725823, lng: -37.68361161960736 },
      { lat: -13.038118584854288, lng: -38.42387651218844 },
      { lat: -13.057652276260619, lng: -38.673887091616514 },
      { lat: -13.793369642800023, lng: -38.953275722802545 },
      { lat: -15.667053724838768, lng: -38.88229814304965 },
      { lat: -17.208406670808472, lng: -39.16109249526431 },
      { lat: -17.867746270420483, lng: -39.2673392400564 },
      { lat: -18.262295830968938, lng: -39.58352149103423 },
      { lat: -19.59911345792741, lng: -39.76082333022764 },
      { lat: -20.904511814052423, lng: -40.77474077001034 },
      { lat: -21.937316989837807, lng: -40.94475623225061 },
      { lat: -22.370675551037458, lng: -41.754164191238225 },
      { lat: -22.970070489190892, lng: -41.98828426773655 },
      { lat: -22.96769337330547, lng: -43.07470374202475 },
      { lat: -23.351959323827842, lng: -44.64781185563781 },
      { lat: -23.796841729428582, lng: -45.35213578955991 },
      { lat: -24.08896860117454, lng: -46.47209326840554 },
      { lat: -24.885199069927722, lng: -47.64897233742066 },
      { lat: -25.877024834905654, lng: -48.4954581365777 },
      { lat: -26.623697605090932, lng: -48.64100480812774 },
      { lat: -27.17591196056189, lng: -48.47473588722865 },
      { lat: -28.186134535435716, lng: -48.661520351747626 },
      { lat: -28.674115085567884, lng: -48.88845740415739 },
      { lat: -29.224469089476337, lng: -49.587329474472675 },
      { lat: -30.984465020472957, lng: -50.696874152211485 },
      { lat: -31.77769825615321, lng: -51.576226162306156 },
      { lat: -32.24536996839466, lng: -52.256081305538046 },
      { lat: -33.19657805759118, lng: -52.712099982297694 },
      { lat: -33.768377780900764, lng: -53.373661668498244 },
      { lat: -33.20200408298183, lng: -53.6505439927181 },
      { lat: -32.727666110974724, lng: -53.209588995971544 },
      { lat: -32.047242526987624, lng: -53.787951626182185 },
      { lat: -31.494511407193748, lng: -54.57245154480512 },
      { lat: -30.853878676071393, lng: -55.601510179249345 },
      { lat: -30.883075860316303, lng: -55.97324459494093 },
      { lat: -30.109686374636127, lng: -56.97602576356473 },
      { lat: -30.216294854454258, lng: -57.62513342958296 },
    ],
  ],
  BS: [
    [
      { lat: 26.58, lng: -77.82 },
      { lat: 26.42, lng: -78.91 },
      { lat: 26.79, lng: -78.98 },
      { lat: 26.87, lng: -78.51 },
      { lat: 26.84, lng: -77.85 },
      { lat: 26.58, lng: -77.82 },
    ],
    [
      { lat: 23.75975, lng: -77.53466 },
      { lat: 23.71, lng: -77.78 },
      { lat: 24.28615, lng: -78.03405 },
      { lat: 24.57564, lng: -78.40848 },
      { lat: 25.2103, lng: -78.19087 },
      { lat: 25.17, lng: -77.89 },
      { lat: 24.34, lng: -77.54 },
      { lat: 23.75975, lng: -77.53466 },
    ],
    [
      { lat: 26.59, lng: -77 },
      { lat: 25.87918, lng: -77.17255 },
      { lat: 26.00735, lng: -77.35641 },
      { lat: 26.53, lng: -77.34 },
      { lat: 26.92516, lng: -77.78802 },
      { lat: 27.04, lng: -77.79 },
      { lat: 26.59, lng: -77 },
    ],
  ],
  BT: [
    [
      { lat: 27.77174184825166, lng: 91.69665652869668 },
      { lat: 27.452614040633208, lng: 92.10371178585973 },
      { lat: 26.83831045176356, lng: 92.03348351437509 },
      { lat: 26.808648179628022, lng: 91.21751264848643 },
      { lat: 26.87572418874288, lng: 90.37327477413407 },
      { lat: 26.719402981059957, lng: 89.74452762243884 },
      { lat: 27.098966376243762, lng: 88.83564253128938 },
      { lat: 27.29931590423936, lng: 88.81424848832054 },
      { lat: 28.042758897406397, lng: 89.47581017452111 },
      { lat: 28.296438503527217, lng: 90.01582889197118 },
      { lat: 28.064953925075756, lng: 90.7305139505678 },
      { lat: 28.040614325466294, lng: 91.25885379431992 },
      { lat: 27.77174184825166, lng: 91.69665652869668 },
    ],
  ],
  BW: [
    [
      { lat: -18.53602589281899, lng: 25.649163445750162 },
      { lat: -18.714412937090536, lng: 25.85039147309473 },
      { lat: -19.29308562589494, lng: 26.164790887158485 },
      { lat: -20.391519870691, lng: 27.296504754350508 },
      { lat: -20.499058526290387, lng: 27.724747348753255 },
      { lat: -20.851801853114715, lng: 27.72722781750326 },
      { lat: -21.485975030200585, lng: 28.021370070108617 },
      { lat: -21.63945403410745, lng: 28.794656202924212 },
      { lat: -22.091312758067588, lng: 29.43218834810904 },
      { lat: -22.827753594659075, lng: 28.01723595552525 },
      { lat: -23.574323011979775, lng: 27.119409620886245 },
      { lat: -24.240690606383485, lng: 26.786406691197413 },
      { lat: -24.616326592713104, lng: 26.4857532081233 },
      { lat: -24.69637338633322, lng: 25.94165205252216 },
      { lat: -25.174845472923675, lng: 25.76584882986521 },
      { lat: -25.486816094669713, lng: 25.66466637543772 },
      { lat: -25.7196700985769, lng: 25.025170525825786 },
      { lat: -25.670215752873574, lng: 24.211266717228792 },
      { lat: -25.390129489851613, lng: 23.73356977712271 },
      { lat: -25.26868987396572, lng: 23.312096795350186 },
      { lat: -25.500458672794768, lng: 22.8242712745149 },
      { lat: -25.979447523708146, lng: 22.57953169118059 },
      { lat: -26.280256036079138, lng: 22.105968865657868 },
      { lat: -26.726533705351756, lng: 21.60589603036939 },
      { lat: -26.828542982695915, lng: 20.88960900237174 },
      { lat: -26.477453301704923, lng: 20.66647016773544 },
      { lat: -25.86813648855145, lng: 20.758609246511835 },
      { lat: -24.917961928000768, lng: 20.165725538827186 },
      { lat: -24.767790215760588, lng: 19.895767856534434 },
      { lat: -21.84915699634787, lng: 19.89545779794068 },
      { lat: -21.814327080983148, lng: 20.881134067475866 },
      { lat: -18.252218926672022, lng: 20.910641310314535 },
      { lat: -18.219146010005225, lng: 21.655040317478978 },
      { lat: -17.869038181227786, lng: 23.1968583513393 },
      { lat: -18.28126108162006, lng: 23.579005568137717 },
      { lat: -17.88934701911849, lng: 24.217364536239213 },
      { lat: -17.887124932529936, lng: 24.520705193792537 },
      { lat: -17.661815687737374, lng: 25.08444339366457 },
      { lat: -17.736539808831417, lng: 25.264225701608012 },
      { lat: -18.53602589281899, lng: 25.649163445750162 },
    ],
  ],
  BY: [
    [
      { lat: 53.91249766704114, lng: 23.48412763844985 },
      { lat: 53.905702216194754, lng: 24.450683628037037 },
      { lat: 54.28242340760253, lng: 25.536353794056993 },
      { lat: 54.84696259217509, lng: 25.7684326514798 },
      { lat: 55.16717560487167, lng: 26.58827924979039 },
      { lat: 55.615106919977634, lng: 26.494331495883753 },
      { lat: 55.783313707087686, lng: 27.10245975109453 },
      { lat: 56.16912995057881, lng: 28.176709425577993 },
      { lat: 55.918344224666356, lng: 29.229513380660308 },
      { lat: 55.670090643936184, lng: 29.371571893030673 },
      { lat: 55.78946320253041, lng: 29.896294386522356 },
      { lat: 55.55097646750341, lng: 30.873909132620007 },
      { lat: 55.08154775656404, lng: 30.971835971813135 },
      { lat: 54.81177094178432, lng: 30.757533807098717 },
      { lat: 54.157056382862436, lng: 31.38447228366374 },
      { lat: 53.97463857687212, lng: 31.79142418796224 },
      { lat: 53.79402944601202, lng: 31.731272820774507 },
      { lat: 53.618045355842035, lng: 32.405598585751164 },
      { lat: 53.35142080343212, lng: 32.69364301934604 },
      { lat: 53.1327261419729, lng: 32.304519484188226 },
      { lat: 53.1674268662569, lng: 31.49764367038293 },
      { lat: 53.07399587667321, lng: 31.305200636528014 },
      { lat: 52.74205231384636, lng: 31.54001834486226 },
      { lat: 52.101677964885454, lng: 31.785998162571587 },
      { lat: 52.04235342061438, lng: 30.927549269338982 },
      { lat: 51.822806098022376, lng: 30.619454380014844 },
      { lat: 51.31950348571566, lng: 30.555117221811457 },
      { lat: 51.41613841410147, lng: 30.157363722460897 },
      { lat: 51.368234361366895, lng: 29.254938185347925 },
      { lat: 51.602044379271476, lng: 28.99283532076353 },
      { lat: 51.42771393493484, lng: 28.61761274589225 },
      { lat: 51.57222707783907, lng: 28.24161502453657 },
      { lat: 51.59230337178447, lng: 27.454066196408434 },
      { lat: 51.83228872334793, lng: 26.337958611768556 },
      { lat: 51.91065603291855, lng: 25.327787713327005 },
      { lat: 51.888461005249184, lng: 24.553106316839518 },
      { lat: 51.61744395609446, lng: 24.00507775238421 },
      { lat: 51.57845408793023, lng: 23.52707075368437 },
      { lat: 52.02364655212473, lng: 23.508002150168693 },
      { lat: 52.486977444053664, lng: 23.199493849386187 },
      { lat: 52.69109935160657, lng: 23.799198846133375 },
      { lat: 53.089731350306074, lng: 23.80493493011778 },
      { lat: 53.470121568406555, lng: 23.527535841575002 },
      { lat: 53.91249766704114, lng: 23.48412763844985 },
    ],
  ],
  BZ: [
    [
      { lat: 17.80831899664932, lng: -89.14308041050332 },
      { lat: 17.95546763760042, lng: -89.15090938999553 },
      { lat: 18.001511338772488, lng: -89.02985734735182 },
      { lat: 17.883198147040233, lng: -88.84834387892661 },
      { lat: 18.486830552641603, lng: -88.49012285027935 },
      { lat: 18.4999822046599, lng: -88.3000310940937 },
      { lat: 18.35327281338327, lng: -88.29633622918482 },
      { lat: 18.348673610909287, lng: -88.10681291375437 },
      { lat: 18.07667470954101, lng: -88.1234785631685 },
      { lat: 17.644142971258034, lng: -88.2853549873228 },
      { lat: 17.489475409408456, lng: -88.19786678745265 },
      { lat: 17.131693630435663, lng: -88.30264075392444 },
      { lat: 17.036066392479555, lng: -88.23951799187991 },
      { lat: 16.530774237529627, lng: -88.35542822951057 },
      { lat: 16.265467434143147, lng: -88.55182451043585 },
      { lat: 16.233634751851355, lng: -88.73243364129594 },
      { lat: 15.887273464415074, lng: -88.93061275913527 },
      { lat: 15.88693756760517, lng: -89.22912167026928 },
      { lat: 17.015576687075836, lng: -89.15080603713095 },
      { lat: 17.80831899664932, lng: -89.14308041050332 },
    ],
  ],
  CA: [
    [
      { lat: 48.51001089130344, lng: -123.51000158755114 },
      { lat: 48.370846259141416, lng: -124.0128907883995 },
      { lat: 48.8250045843385, lng: -125.65501277733837 },
      { lat: 49.179995835967645, lng: -125.95499446679275 },
      { lat: 49.53000031188043, lng: -126.85000443587187 },
      { lat: 49.81499583597008, lng: -127.0299934495444 },
      { lat: 49.9949590114266, lng: -128.05933630436624 },
      { lat: 50.539137681676124, lng: -128.44458410710217 },
      { lat: 50.770648098343685, lng: -128.35841365625544 },
      { lat: 50.55257355407195, lng: -127.3085810960299 },
      { lat: 50.400903225295394, lng: -126.69500097721232 },
      { lat: 50.29501821552938, lng: -125.75500667382319 },
      { lat: 49.95000051533261, lng: -125.4150015875588 },
      { lat: 49.475274970083404, lng: -124.92076818911934 },
      { lat: 49.06248362893581, lng: -123.92250870832102 },
      { lat: 48.51001089130344, lng: -123.51000158755114 },
    ],
    [
      { lat: 73.15744700793846, lng: -86.56217851433414 },
      { lat: 72.53412588163383, lng: -85.77437130404454 },
      { lat: 73.34027822538712, lng: -84.85011247428824 },
      { lat: 73.75095083281059, lng: -82.31559017610098 },
      { lat: 72.71654368762421, lng: -80.60008765330764 },
      { lat: 72.06190664335077, lng: -80.7489416165244 },
      { lat: 72.35217316353416, lng: -78.77063859731078 },
      { lat: 72.74961660429105, lng: -77.82462398955958 },
      { lat: 72.24367849393741, lng: -75.60584469267573 },
      { lat: 71.7671442735579, lng: -74.22861609566499 },
      { lat: 71.33084015571765, lng: -74.09914079455771 },
      { lat: 71.5569245469945, lng: -72.24222571479766 },
      { lat: 70.92001251899723, lng: -71.20001542833519 },
      { lat: 70.52502370877426, lng: -68.7860542466849 },
      { lat: 70.12194753689761, lng: -67.91497046575694 },
      { lat: 69.18608734809189, lng: -66.96903337265417 },
      { lat: 68.72019847276442, lng: -68.80512285020055 },
      { lat: 68.06716339789202, lng: -66.44986609563387 },
      { lat: 67.84753856065163, lng: -64.86231441919522 },
      { lat: 66.92847321234066, lng: -63.42493445499676 },
      { lat: 66.86212067327784, lng: -61.85198137068058 },
      { lat: 66.16025136988961, lng: -62.1631768459423 },
      { lat: 64.99866852483284, lng: -63.918444383384184 },
      { lat: 65.42603261988668, lng: -65.14886023625363 },
      { lat: 66.3880410834322, lng: -66.72121904159854 },
      { lat: 66.26272573512439, lng: -68.01501603867396 },
      { lat: 65.68978913030438, lng: -68.14128740097917 },
      { lat: 65.108455105237, lng: -67.08964616562339 },
      { lat: 64.64840566675863, lng: -65.73208045109976 },
      { lat: 64.38273712834606, lng: -65.32016760930128 },
      { lat: 63.39292674422748, lng: -64.66940629744968 },
      { lat: 62.67418508569599, lng: -65.01380388045891 },
      { lat: 62.945098781986076, lng: -66.27504472519047 },
      { lat: 63.74567007105181, lng: -68.78318620469273 },
      { lat: 62.883965562584876, lng: -67.36968075221304 },
      { lat: 62.280074774822054, lng: -66.3282972886672 },
      { lat: 61.93089712182589, lng: -66.16556820338016 },
      { lat: 62.33014923771282, lng: -68.87736650254465 },
      { lat: 62.91070811629584, lng: -71.02343705919384 },
      { lat: 63.39783600529517, lng: -72.235378587519 },
      { lat: 63.67998932560885, lng: -71.8862784491713 },
      { lat: 64.19396312118383, lng: -73.37830624051838 },
      { lat: 64.67907562932379, lng: -74.8344189114226 },
      { lat: 64.38909332951798, lng: -74.81850257027673 },
      { lat: 64.22954234481679, lng: -77.70997982452005 },
      { lat: 64.57290639918014, lng: -78.55594885935417 },
      { lat: 65.30919220647479, lng: -77.89728105336192 },
      { lat: 65.32696889918316, lng: -76.0182742987972 },
      { lat: 65.45476471624089, lng: -73.95979529488272 },
      { lat: 65.8117713487294, lng: -74.29388342964964 },
      { lat: 66.31057811142672, lng: -73.94491248238265 },
      { lat: 67.28457550726387, lng: -72.65116716173941 },
      { lat: 67.72692576768239, lng: -72.92605994331609 },
      { lat: 68.06943716091291, lng: -73.31161780464575 },
      { lat: 68.55462718370129, lng: -74.84330725777681 },
      { lat: 68.89473562283027, lng: -76.86910091826674 },
      { lat: 69.14776927354742, lng: -76.22864905465735 },
      { lat: 69.76954010688328, lng: -77.28736996123712 },
      { lat: 69.82648753526891, lng: -78.1686339993266 },
      { lat: 70.16688019477542, lng: -78.95724219431673 },
      { lat: 69.87180776638891, lng: -79.49245500356366 },
      { lat: 69.74318512641435, lng: -81.30547095409176 },
      { lat: 69.9666340196444, lng: -84.94470618359847 },
      { lat: 70.26000112576537, lng: -87.06000342481789 },
      { lat: 70.41074127876081, lng: -88.6817132230015 },
      { lat: 70.76203766548099, lng: -89.51341956252304 },
      { lat: 71.21818553332133, lng: -88.46772111688075 },
      { lat: 71.22255219184996, lng: -89.8881512112875 },
      { lat: 72.2350743679608, lng: -90.20516028518202 },
      { lat: 73.12946421985237, lng: -89.43657670770494 },
      { lat: 73.53788890247121, lng: -88.40824154331281 },
      { lat: 73.80381582304521, lng: -85.82615108920092 },
      { lat: 73.15744700793846, lng: -86.56217851433414 },
    ],
    [
      { lat: 71.38360179308759, lng: -120.46 },
      { lat: 70.90164, lng: -123.09219 },
      { lat: 71.34, lng: -123.62 },
      { lat: 71.86868846301141, lng: -125.92894873747335 },
      { lat: 72.29226081179502, lng: -125.5 },
      { lat: 73.02256, lng: -124.80729 },
      { lat: 73.68000000000015, lng: -123.9399999999999 },
      { lat: 74.29275000000013, lng: -124.91775 },
      { lat: 74.44893, lng: -121.53788 },
      { lat: 74.24135, lng: -120.10978 },
      { lat: 74.18577, lng: -117.55563999999987 },
      { lat: 73.89607, lng: -116.58442 },
      { lat: 73.47519, lng: -115.51081 },
      { lat: 73.22292, lng: -116.76793999999988 },
      { lat: 72.52, lng: -119.22 },
      { lat: 71.82, lng: -120.46 },
      { lat: 71.38360179308759, lng: -120.46 },
    ],
    [
      { lat: 72.77199249947336, lng: -93.19629553910022 },
      { lat: 72.02459625923598, lng: -94.26904659704726 },
      { lat: 72.06188080513459, lng: -95.40985551632266 },
      { lat: 72.94027680123182, lng: -96.03374508338246 },
      { lat: 73.4374299180958, lng: -96.01826799191099 },
      { lat: 73.86241689726418, lng: -95.49579342322403 },
      { lat: 74.1349067247392, lng: -94.50365759965234 },
      { lat: 74.10002513294219, lng: -92.42001217321177 },
      { lat: 73.85673248971203, lng: -90.50979285354259 },
      { lat: 72.9662442084585, lng: -92.0039652168299 },
      { lat: 72.77199249947336, lng: -93.19629553910022 },
    ],
    [
      { lat: 73.42, lng: -104.5 },
      { lat: 72.76, lng: -105.38 },
      { lat: 73.46, lng: -106.94 },
      { lat: 73.6, lng: -106.6 },
      { lat: 73.64, lng: -105.26 },
      { lat: 73.42, lng: -104.5 },
    ],
    [
      { lat: 83.10632151676575, lng: -68.5 },
      { lat: 83.02801, lng: -65.82735 },
      { lat: 82.9, lng: -63.68 },
      { lat: 82.6286, lng: -61.85 },
      { lat: 82.36165, lng: -61.89388 },
      { lat: 81.92775, lng: -64.334 },
      { lat: 81.72527, lng: -66.75342 },
      { lat: 81.50141, lng: -67.65755 },
      { lat: 81.50657, lng: -65.48031 },
      { lat: 80.9, lng: -67.84 },
      { lat: 80.61683, lng: -69.4697 },
      { lat: 79.8, lng: -71.18 },
      { lat: 79.63415, lng: -73.2428 },
      { lat: 79.43016220480207, lng: -73.88 },
      { lat: 79.32309, lng: -76.90773 },
      { lat: 79.19766, lng: -75.52924 },
      { lat: 79.01907, lng: -76.22046 },
      { lat: 78.52581, lng: -75.39345 },
      { lat: 78.18296, lng: -76.34354 },
      { lat: 77.89991, lng: -77.88851 },
      { lat: 77.50859, lng: -78.36269 },
      { lat: 77.20968, lng: -79.75951 },
      { lat: 76.98336, lng: -79.61965 },
      { lat: 77.022045, lng: -77.91089 },
      { lat: 76.777955, lng: -77.88911 },
      { lat: 76.17812, lng: -80.56125 },
      { lat: 76.45403, lng: -83.17439 },
      { lat: 76.29901, lng: -86.11184 },
      { lat: 76.42, lng: -87.6 },
      { lat: 76.47239, lng: -89.49068 },
      { lat: 76.95213, lng: -89.6161 },
      { lat: 77.17833, lng: -87.76739 },
      { lat: 77.9, lng: -88.26 },
      { lat: 77.97022222222222, lng: -87.65 },
      { lat: 77.53873, lng: -84.97634 },
      { lat: 78.18, lng: -86.34 },
      { lat: 78.37181, lng: -87.96192 },
      { lat: 78.75867, lng: -87.15198 },
      { lat: 78.9969, lng: -85.37868 },
      { lat: 79.34543, lng: -85.09495 },
      { lat: 79.73624, lng: -86.50734 },
      { lat: 80.25145, lng: -86.93179 },
      { lat: 80.20836, lng: -84.19844 },
      { lat: 80.1, lng: -83.40869565217383 },
      { lat: 80.46442, lng: -81.84823 },
      { lat: 80.58, lng: -84.1 },
      { lat: 80.51627, lng: -87.59895 },
      { lat: 80.85569, lng: -89.36663 },
      { lat: 81.26, lng: -90.2 },
      { lat: 81.5531, lng: -91.36786 },
      { lat: 81.89429, lng: -91.58702 },
      { lat: 82.085, lng: -90.1 },
      { lat: 82.11751, lng: -88.93227 },
      { lat: 82.27961, lng: -86.97024 },
      { lat: 82.65227345805704, lng: -85.5 },
      { lat: 82.6, lng: -84.260005 },
      { lat: 82.32, lng: -83.18 },
      { lat: 82.86, lng: -82.42 },
      { lat: 83.02, lng: -81.1 },
      { lat: 83.13056, lng: -79.30664 },
      { lat: 83.1720588235294, lng: -76.25 },
      { lat: 83.06404, lng: -75.71878 },
      { lat: 83.23324, lng: -72.83153 },
      { lat: 83.16978075838284, lng: -70.665765 },
      { lat: 83.10632151676575, lng: -68.5 },
    ],
    [
      { lat: 62.7108, lng: -81.89825 },
      { lat: 62.15922, lng: -83.06857 },
      { lat: 62.18231, lng: -83.77462 },
      { lat: 62.4528, lng: -83.99367 },
      { lat: 62.91409, lng: -83.25048 },
      { lat: 62.90458, lng: -81.87699 },
      { lat: 62.7108, lng: -81.89825 },
    ],
    [
      { lat: 78.60197256134569, lng: -109.66314571820259 },
      { lat: 78.40691986766001, lng: -110.88131425661886 },
      { lat: 78.4079017198735, lng: -112.54209143761517 },
      { lat: 78.55055451121522, lng: -112.5258908760916 },
      { lat: 78.84999359813057, lng: -111.5000103422334 },
      { lat: 78.80444082306522, lng: -110.96366065147602 },
      { lat: 78.60197256134569, lng: -109.66314571820259 },
    ],
    [
      { lat: 73.10268498995302, lng: -76.34 },
      { lat: 72.82638549804688, lng: -76.25140380859375 },
      { lat: 72.85554504394527, lng: -77.31443786621091 },
      { lat: 72.87665557861328, lng: -78.39167022705081 },
      { lat: 72.74220275878909, lng: -79.48625183105466 },
      { lat: 72.80290222167974, lng: -79.77583312988284 },
      { lat: 73.3331832885742, lng: -80.87609863281253 },
      { lat: 73.69318389892578, lng: -80.83388519287105 },
      { lat: 73.75971984863278, lng: -80.35305786132812 },
      { lat: 73.65193176269534, lng: -78.06443786621094 },
      { lat: 73.10268498995302, lng: -76.34 },
    ],
    [
      { lat: 78.3247543403159, lng: -100.06019182005214 },
      { lat: 77.9075446642074, lng: -99.67093909381362 },
      { lat: 78.01898489044481, lng: -101.30394019245301 },
      { lat: 78.34322866486022, lng: -102.94980872273305 },
      { lat: 78.38033234324574, lng: -105.17613277873154 },
      { lat: 78.6774201524918, lng: -104.21042945027716 },
      { lat: 78.91833567983645, lng: -105.41958045125854 },
      { lat: 79.30159393992919, lng: -105.49228919149316 },
      { lat: 79.16534902619165, lng: -103.52928239623793 },
      { lat: 78.80046173777869, lng: -100.82515804726881 },
      { lat: 78.3247543403159, lng: -100.06019182005214 },
    ],
    [
      { lat: 74.97999726022445, lng: -93.61275590694049 },
      { lat: 74.59234650338686, lng: -94.15690873897384 },
      { lat: 74.66686391875177, lng: -95.60868058956561 },
      { lat: 74.92762319609658, lng: -96.82093217648458 },
      { lat: 75.37782827422335, lng: -96.2885874092298 },
      { lat: 75.6472175157609, lng: -94.85081987178913 },
      { lat: 75.29648956979597, lng: -93.97774654821794 },
      { lat: 74.97999726022445, lng: -93.61275590694049 },
    ],
    [
      { lat: 76.72, lng: -98.5 },
      { lat: 76.25656, lng: -97.735585 },
      { lat: 75.74344, lng: -97.704415 },
      { lat: 75, lng: -98.16 },
      { lat: 74.89744, lng: -99.80874 },
      { lat: 75.05736, lng: -100.88366 },
      { lat: 75.64075, lng: -100.86292 },
      { lat: 75.5638, lng: -102.50209 },
      { lat: 76.3366, lng: -102.56552 },
      { lat: 76.30537, lng: -101.48973 },
      { lat: 76.64634, lng: -99.98349 },
      { lat: 76.58859, lng: -98.57699 },
      { lat: 76.72, lng: -98.5 },
    ],
    [
      { lat: 77.64528677032621, lng: -116.19858659550734 },
      { lat: 76.87696157501055, lng: -116.33581336145838 },
      { lat: 76.53003184681913, lng: -117.10605058476878 },
      { lat: 76.4811717800871, lng: -118.04041215703813 },
      { lat: 76.05321340606199, lng: -119.89931758688569 },
      { lat: 75.9000186225328, lng: -121.4999950771265 },
      { lat: 76.11654287383568, lng: -122.85492448615896 },
      { lat: 76.11654287383568, lng: -122.8549252936032 },
      { lat: 76.86450755482835, lng: -121.15753536032825 },
      { lat: 77.51221995717464, lng: -119.10393897182104 },
      { lat: 77.4983189968881, lng: -117.57013078496597 },
      { lat: 77.64528677032621, lng: -116.19858659550734 },
    ],
    [
      { lat: 77.6970148790503, lng: -110.18693803591297 },
      { lat: 77.40922882761686, lng: -112.05119116905848 },
      { lat: 77.73220652944116, lng: -113.53427893761906 },
      { lat: 78.05105011668195, lng: -112.72458675825384 },
      { lat: 78.15295604116156, lng: -111.26444332563085 },
      { lat: 77.99632477488484, lng: -109.8544518705471 },
      { lat: 77.6970148790503, lng: -110.18693803591297 },
    ],
    [
      { lat: 54.16997549093531, lng: -133.1800040417117 },
      { lat: 54.040009315423525, lng: -132.71000788443132 },
      { lat: 54.12000438090922, lng: -131.74998958400326 },
      { lat: 52.984621487024526, lng: -132.049480347351 },
      { lat: 52.180432847698285, lng: -131.1790425218266 },
      { lat: 52.18237071390925, lng: -131.57782954982292 },
      { lat: 52.639707139692405, lng: -132.18042842677855 },
      { lat: 53.100014960332146, lng: -132.54999243231387 },
      { lat: 53.41146881775537, lng: -133.05461117875552 },
      { lat: 53.8510802272624, lng: -133.2396644827927 },
      { lat: 54.16997549093531, lng: -133.1800040417117 },
    ],
    [
      { lat: 62.158675, lng: -79.26582 },
      { lat: 61.63308, lng: -79.65752 },
      { lat: 61.7181, lng: -80.09956 },
      { lat: 62.01649, lng: -80.36215 },
      { lat: 62.085565, lng: -80.315395 },
      { lat: 62.3856, lng: -79.92939 },
      { lat: 62.36371, lng: -79.52002 },
      { lat: 62.158675, lng: -79.26582 },
    ],
    [
      { lat: 69.49766, lng: -90.5471 },
      { lat: 68.47499, lng: -90.55151 },
      { lat: 69.25873, lng: -89.21515 },
      { lat: 68.61508, lng: -88.01966 },
      { lat: 67.87338, lng: -88.31749 },
      { lat: 67.19872, lng: -87.35017 },
      { lat: 67.92146, lng: -86.30607 },
      { lat: 68.78456, lng: -85.57664 },
      { lat: 69.88211, lng: -85.52197 },
      { lat: 69.80539, lng: -84.10081 },
      { lat: 69.65826, lng: -82.62258 },
      { lat: 69.16202, lng: -81.28043 },
      { lat: 68.66567, lng: -81.2202 },
      { lat: 68.13253, lng: -81.96436 },
      { lat: 67.59716, lng: -81.25928 },
      { lat: 67.11078, lng: -81.38653 },
      { lat: 66.41154, lng: -83.34456 },
      { lat: 66.2573, lng: -84.73542 },
      { lat: 66.55833, lng: -85.76943 },
      { lat: 66.05625, lng: -86.0676 },
      { lat: 65.21297, lng: -87.03143 },
      { lat: 64.77563, lng: -87.32324 },
      { lat: 64.09897, lng: -88.48296 },
      { lat: 64.03273, lng: -89.91444 },
      { lat: 63.61017, lng: -90.70398 },
      { lat: 62.96021, lng: -90.77004 },
      { lat: 62.83508, lng: -91.93342 },
      { lat: 62.02469, lng: -93.15698 },
      { lat: 60.89865, lng: -94.24153 },
      { lat: 60.11021, lng: -94.62931 },
      { lat: 58.94882, lng: -94.6846 },
      { lat: 58.78212, lng: -93.21502 },
      { lat: 57.84571, lng: -92.76462 },
      { lat: 57.08709, lng: -92.29703 },
      { lat: 57.28468, lng: -90.89769 },
      { lat: 56.85172, lng: -89.03953 },
      { lat: 56.47162, lng: -88.03978 },
      { lat: 55.99914, lng: -87.32421 },
      { lat: 55.72383, lng: -86.07121 },
      { lat: 55.3026, lng: -85.01181 },
      { lat: 55.24489, lng: -83.36055 },
      { lat: 55.14832, lng: -82.27285 },
      { lat: 54.28227, lng: -82.4362 },
      { lat: 53.27703, lng: -82.12502 },
      { lat: 52.15788, lng: -81.40075 },
      { lat: 51.20842, lng: -79.91289 },
      { lat: 51.53393, lng: -79.14301 },
      { lat: 52.56208, lng: -78.60191 },
      { lat: 54.14145, lng: -79.12421 },
      { lat: 54.66772, lng: -79.82958 },
      { lat: 55.13645, lng: -78.22874 },
      { lat: 55.83741, lng: -77.0956 },
      { lat: 56.53423, lng: -76.54137 },
      { lat: 57.20263, lng: -76.62319 },
      { lat: 58.05209, lng: -77.30226 },
      { lat: 58.80458, lng: -78.51688 },
      { lat: 59.85261, lng: -77.33676 },
      { lat: 60.75788, lng: -77.77272 },
      { lat: 62.31964, lng: -78.10687 },
      { lat: 62.55053, lng: -77.41067 },
      { lat: 62.2784, lng: -75.69621 },
      { lat: 62.18111, lng: -74.6682 },
      { lat: 62.4438, lng: -73.83988 },
      { lat: 62.10507, lng: -72.90853 },
      { lat: 61.52535, lng: -71.67708 },
      { lat: 61.13717, lng: -71.37369 },
      { lat: 61.06141, lng: -69.59042 },
      { lat: 60.22125, lng: -69.62033 },
      { lat: 58.95736, lng: -69.2879 },
      { lat: 58.80106, lng: -68.37455 },
      { lat: 58.21206, lng: -67.64976 },
      { lat: 58.76731, lng: -66.20178 },
      { lat: 59.87071, lng: -65.24517 },
      { lat: 60.33558, lng: -64.58352 },
      { lat: 59.4426, lng: -63.80475 },
      { lat: 58.16708, lng: -62.50236 },
      { lat: 56.96745, lng: -61.39655 },
      { lat: 56.33945, lng: -61.79866 },
      { lat: 55.77548, lng: -60.46853 },
      { lat: 55.20407, lng: -59.56962 },
      { lat: 54.94549, lng: -57.97508 },
      { lat: 54.6265, lng: -57.3332 },
      { lat: 53.78032, lng: -56.93689 },
      { lat: 53.64749, lng: -56.15811 },
      { lat: 53.27036, lng: -55.75632 },
      { lat: 52.14664, lng: -55.68338 },
      { lat: 51.7707, lng: -56.40916 },
      { lat: 51.41972, lng: -57.12691 },
      { lat: 51.0643, lng: -58.77482 },
      { lat: 50.24277, lng: -60.03309 },
      { lat: 50.08046, lng: -61.72366 },
      { lat: 50.29099, lng: -63.86251 },
      { lat: 50.2982, lng: -65.36331 },
      { lat: 50.22897, lng: -66.39905 },
      { lat: 49.51156, lng: -67.23631 },
      { lat: 49.06836, lng: -68.51114 },
      { lat: 47.74488, lng: -69.95362 },
      { lat: 46.82171, lng: -71.10458 },
      { lat: 46.98606, lng: -70.25522 },
      { lat: 48.3, lng: -68.65 },
      { lat: 49.1331, lng: -66.55243 },
      { lat: 49.23278, lng: -65.05626 },
      { lat: 48.74248, lng: -64.17099 },
      { lat: 48.07085, lng: -65.11545 },
      { lat: 46.99297, lng: -64.79854 },
      { lat: 46.23849, lng: -64.47219 },
      { lat: 45.73902, lng: -63.17329 },
      { lat: 45.88377, lng: -61.52072 },
      { lat: 47.00793, lng: -60.51815 },
      { lat: 46.28264, lng: -60.4486 },
      { lat: 45.9204, lng: -59.80287 },
      { lat: 45.26525, lng: -61.03988 },
      { lat: 44.67014, lng: -63.25471 },
      { lat: 44.26553, lng: -64.24656 },
      { lat: 43.54523, lng: -65.36406 },
      { lat: 43.61867, lng: -66.1234 },
      { lat: 44.46512, lng: -66.16173 },
      { lat: 45.29204, lng: -64.42549 },
      { lat: 45.25931, lng: -66.02605 },
      { lat: 45.13753, lng: -67.13741 },
      { lat: 45.70281, lng: -67.79134 },
      { lat: 47.06636, lng: -67.79046 },
      { lat: 47.35486, lng: -68.23444 },
      { lat: 47.185, lng: -68.905 },
      { lat: 47.447781, lng: -69.237216 },
      { lat: 46.69307, lng: -69.99997 },
      { lat: 45.915, lng: -70.305 },
      { lat: 45.46, lng: -70.66 },
      { lat: 45.30524, lng: -71.08482 },
      { lat: 45.255, lng: -71.405 },
      { lat: 45.0082, lng: -71.50506 },
      { lat: 45.00738, lng: -73.34783 },
      { lat: 45.00048, lng: -74.867 },
      { lat: 44.81645, lng: -75.31821 },
      { lat: 44.09631, lng: -76.375 },
      { lat: 44.01845889375872, lng: -76.5 },
      { lat: 43.628784288093755, lng: -76.82003414580558 },
      { lat: 43.629055589363304, lng: -77.7378850979577 },
      { lat: 43.625089423184875, lng: -78.72027991404238 },
      { lat: 43.46633942318422, lng: -79.17167355011188 },
      { lat: 43.27, lng: -79.01 },
      { lat: 42.965, lng: -78.92 },
      { lat: 42.86361135514804, lng: -78.9393621487437 },
      { lat: 42.36619985612259, lng: -80.24744767934794 },
      { lat: 42.20902598730686, lng: -81.27774654816716 },
      { lat: 41.675105088867156, lng: -82.43927771679162 },
      { lat: 41.675105088867156, lng: -82.69008928092018 },
      { lat: 41.83279572200584, lng: -83.02981014680694 },
      { lat: 41.975681057292825, lng: -83.14199968131256 },
      { lat: 42.08, lng: -83.12 },
      { lat: 42.43, lng: -82.9 },
      { lat: 42.98, lng: -82.43 },
      { lat: 43.571087551439916, lng: -82.1376423815039 },
      { lat: 44.44, lng: -82.33776312543108 },
      { lat: 45.347516587905375, lng: -82.55092464875818 },
      { lat: 45.81689362241237, lng: -83.59285071484308 },
      { lat: 45.99468638771259, lng: -83.46955074739463 },
      { lat: 46.11692698829907, lng: -83.61613094759059 },
      { lat: 46.11692698829907, lng: -83.89076534700575 },
      { lat: 46.275418606138174, lng: -84.09185126416148 },
      { lat: 46.51222585711574, lng: -84.14211951367338 },
      { lat: 46.40877, lng: -84.3367 },
      { lat: 46.4396, lng: -84.6049 },
      { lat: 46.538684190449146, lng: -84.54374874544587 },
      { lat: 46.637101955749046, lng: -84.77923824739992 },
      { lat: 46.90008331968238, lng: -84.87607988151485 },
      { lat: 47.22021881773051, lng: -85.65236324740343 },
      { lat: 47.55333801939204, lng: -86.46199083122826 },
      { lat: 47.94, lng: -87.43979262330024 },
      { lat: 48.302917588893735, lng: -88.37811418328673 },
      { lat: 48.019808254582664, lng: -89.27291744663668 },
      { lat: 48.01, lng: -89.6 },
      { lat: 48.27, lng: -90.83 },
      { lat: 48.14, lng: -91.64 },
      { lat: 48.45, lng: -92.61 },
      { lat: 48.60926, lng: -93.63087 },
      { lat: 48.67074, lng: -94.32914 },
      { lat: 48.84, lng: -94.64 },
      { lat: 49.38905, lng: -94.81758 },
      { lat: 49.38425, lng: -95.15609 },
      { lat: 49, lng: -95.15906950917204 },
      { lat: 49.0007, lng: -97.22872000000481 },
      { lat: 49, lng: -100.65 },
      { lat: 48.99986, lng: -104.04826 },
      { lat: 49, lng: -107.05 },
      { lat: 49, lng: -110.05 },
      { lat: 49, lng: -113 },
      { lat: 49, lng: -116.04818 },
      { lat: 49, lng: -117.03121 },
      { lat: 49, lng: -120 },
      { lat: 49, lng: -122.84 },
      { lat: 49.0025377777778, lng: -122.97421 },
      { lat: 49.98456, lng: -124.91024 },
      { lat: 50.41656, lng: -125.62461 },
      { lat: 50.83061, lng: -127.43561 },
      { lat: 51.71583, lng: -127.99276 },
      { lat: 52.32961, lng: -127.85032 },
      { lat: 52.75538, lng: -129.12979 },
      { lat: 53.56159, lng: -129.30523 },
      { lat: 54.28757, lng: -130.51497 },
      { lat: 54.80278, lng: -130.53611 },
      { lat: 55.285, lng: -129.98 },
      { lat: 55.91583, lng: -130.00778 },
      { lat: 56.55212, lng: -131.70781 },
      { lat: 57.69289, lng: -132.73042 },
      { lat: 58.41028, lng: -133.35556 },
      { lat: 58.86111, lng: -134.27111 },
      { lat: 59.27056, lng: -134.945 },
      { lat: 59.78778, lng: -135.47583 },
      { lat: 59.46389, lng: -136.47972 },
      { lat: 58.905, lng: -137.4525 },
      { lat: 59.56211, lng: -138.34089 },
      { lat: 60, lng: -139.039 },
      { lat: 60.27682, lng: -140.013 },
      { lat: 60.30639, lng: -140.99778 },
      { lat: 66.00003, lng: -140.9925 },
      { lat: 69.712, lng: -140.986 },
      { lat: 69.47102, lng: -139.12052 },
      { lat: 68.99002, lng: -137.54636 },
      { lat: 68.89804, lng: -136.50358 },
      { lat: 69.31512, lng: -135.62576 },
      { lat: 69.62743, lng: -134.41464 },
      { lat: 69.50534, lng: -132.92925 },
      { lat: 69.94451, lng: -131.43136 },
      { lat: 70.19369, lng: -129.79471 },
      { lat: 69.77927, lng: -129.10773 },
      { lat: 70.01286, lng: -128.36156 },
      { lat: 70.48384, lng: -128.13817 },
      { lat: 70.37721, lng: -127.44712 },
      { lat: 69.48058, lng: -125.75632 },
      { lat: 70.1584, lng: -124.42483 },
      { lat: 69.39969, lng: -124.28968 },
      { lat: 69.56372, lng: -123.06108 },
      { lat: 69.85553, lng: -122.6835 },
      { lat: 69.79778, lng: -121.47226 },
      { lat: 69.37786, lng: -119.94288 },
      { lat: 69.01128, lng: -117.60268 },
      { lat: 68.84151, lng: -116.22643 },
      { lat: 68.90591, lng: -115.2469 },
      { lat: 68.3989, lng: -113.89794 },
      { lat: 67.90261, lng: -115.30489 },
      { lat: 67.68815, lng: -113.49727 },
      { lat: 67.80612, lng: -110.798 },
      { lat: 67.98104, lng: -109.94619 },
      { lat: 67.38144, lng: -108.8802 },
      { lat: 67.88736, lng: -107.79239 },
      { lat: 68.31164, lng: -108.81299 },
      { lat: 68.65392, lng: -108.16721 },
      { lat: 68.7, lng: -106.95 },
      { lat: 68.8, lng: -106.15 },
      { lat: 68.56122, lng: -105.34282 },
      { lat: 68.018, lng: -104.33791 },
      { lat: 68.09775, lng: -103.22115 },
      { lat: 67.64689, lng: -101.45433 },
      { lat: 67.80566, lng: -99.90195 },
      { lat: 67.78165, lng: -98.4432 },
      { lat: 68.40394, lng: -98.5586 },
      { lat: 68.57864, lng: -97.66948 },
      { lat: 68.23939, lng: -96.11991 },
      { lat: 67.29338, lng: -96.12588 },
      { lat: 68.0907, lng: -95.48943 },
      { lat: 68.06383, lng: -94.685 },
      { lat: 69.06903, lng: -94.23282 },
      { lat: 69.68571, lng: -95.30408 },
      { lat: 70.08976, lng: -96.47131 },
      { lat: 71.19482, lng: -96.39115 },
      { lat: 71.92053, lng: -95.2088 },
      { lat: 71.76015, lng: -93.88997 },
      { lat: 71.31869, lng: -92.87818 },
      { lat: 70.19129, lng: -91.51964 },
      { lat: 69.69997, lng: -92.40692 },
      { lat: 69.49766, lng: -90.5471 },
    ],
    [
      { lat: 76.20168, lng: -108.21141 },
      { lat: 75.84552, lng: -107.81943 },
      { lat: 76.01282, lng: -106.92893 },
      { lat: 75.9694, lng: -105.881 },
      { lat: 75.47951, lng: -105.70498 },
      { lat: 75.00527, lng: -106.31347 },
      { lat: 74.85, lng: -109.7 },
      { lat: 74.41696, lng: -112.22307 },
      { lat: 74.39427, lng: -113.74381 },
      { lat: 74.72029, lng: -113.87135 },
      { lat: 75.1625, lng: -111.79421 },
      { lat: 75.04343, lng: -116.31221 },
      { lat: 75.2222, lng: -117.7104 },
      { lat: 76.19903, lng: -116.34602 },
      { lat: 76.47887, lng: -115.40487 },
      { lat: 76.14134, lng: -112.59056 },
      { lat: 75.54919, lng: -110.81422 },
      { lat: 75.47321, lng: -109.0671 },
      { lat: 76.42982, lng: -110.49726 },
      { lat: 76.79417, lng: -109.5811 },
      { lat: 76.67832, lng: -108.54859 },
      { lat: 76.20168, lng: -108.21141 },
    ],
    [
      { lat: 77.09787832305838, lng: -94.68408586299947 },
      { lat: 76.77629588490609, lng: -93.57392106807313 },
      { lat: 76.77851797149461, lng: -91.60502315953661 },
      { lat: 76.44959747995681, lng: -90.74184587274922 },
      { lat: 76.07401317005946, lng: -90.96966142450799 },
      { lat: 75.84777374948563, lng: -89.82223792189927 },
      { lat: 75.61016551380763, lng: -89.18708289259979 },
      { lat: 75.56618886992723, lng: -87.83827633334963 },
      { lat: 75.48242137318218, lng: -86.37919226758868 },
      { lat: 75.69920400664651, lng: -84.78962521029061 },
      { lat: 75.78431509063125, lng: -82.75344458691006 },
      { lat: 75.71398346628203, lng: -81.12853084992437 },
      { lat: 75.33684886341588, lng: -80.05751095245915 },
      { lat: 74.92312734648719, lng: -79.83393286814832 },
      { lat: 74.65730377877779, lng: -80.45777075877584 },
      { lat: 74.44245901152433, lng: -81.94884253612554 },
      { lat: 74.56402781849096, lng: -83.22889360221143 },
      { lat: 74.41003205026115, lng: -86.0974523587333 },
      { lat: 74.39230703398499, lng: -88.15035030796022 },
      { lat: 74.51555532500115, lng: -89.76472205275837 },
      { lat: 74.837757880341, lng: -92.42244096552943 },
      { lat: 75.38681997344216, lng: -92.7682854886428 },
      { lat: 75.88265534128266, lng: -92.88990597204173 },
      { lat: 76.31924367950054, lng: -93.893824022176 },
      { lat: 76.44138092722247, lng: -95.96245744503582 },
      { lat: 76.75107778594761, lng: -97.12137895382949 },
      { lat: 77.16138865834515, lng: -96.74512285031236 },
      { lat: 77.09787832305838, lng: -94.68408586299947 },
    ],
    [
      { lat: 46.55001, lng: -63.6645 },
      { lat: 46.41587, lng: -62.9393 },
      { lat: 46.44314, lng: -62.01208 },
      { lat: 46.03339, lng: -62.50391 },
      { lat: 45.96818, lng: -62.87433 },
      { lat: 46.39265, lng: -64.1428 },
      { lat: 46.72747, lng: -64.39261 },
      { lat: 47.03601, lng: -64.01486 },
      { lat: 46.55001, lng: -63.6645 },
    ],
    [
      { lat: 77.5199972602345, lng: -93.84000301794399 },
      { lat: 77.4913426785287, lng: -94.29560828324526 },
      { lat: 77.5551113959769, lng: -96.16965410031008 },
      { lat: 77.83462921824362, lng: -96.43630449093612 },
      { lat: 77.82000478790499, lng: -94.42257727738638 },
      { lat: 77.63433136668033, lng: -93.72065629756588 },
      { lat: 77.5199972602345, lng: -93.84000301794399 },
    ],
    [
      { lat: 73.84389, lng: -100.35642 },
      { lat: 73.63339, lng: -99.16387 },
      { lat: 73.76, lng: -97.38 },
      { lat: 73.47, lng: -97.12 },
      { lat: 72.99052, lng: -98.05359 },
      { lat: 72.56, lng: -96.54 },
      { lat: 71.66, lng: -96.72 },
      { lat: 71.27285, lng: -98.35966 },
      { lat: 71.35639, lng: -99.32286 },
      { lat: 71.73827, lng: -100.01482 },
      { lat: 72.51, lng: -102.5 },
      { lat: 72.83, lng: -102.48 },
      { lat: 72.70588, lng: -100.43836 },
      { lat: 73.36, lng: -101.54 },
      { lat: 73.84389, lng: -100.35642 },
    ],
    [
      { lat: 67.14886, lng: -75.86588 },
      { lat: 67.09873, lng: -76.98687 },
      { lat: 67.58809, lng: -77.2364 },
      { lat: 68.14856, lng: -76.81166 },
      { lat: 68.28721, lng: -75.89521 },
      { lat: 68.01036, lng: -75.1145 },
      { lat: 67.58202, lng: -75.10333 },
      { lat: 67.44425, lng: -75.21597 },
      { lat: 67.14886, lng: -75.86588 },
    ],
    [
      { lat: 49.10506, lng: -61.806305 },
      { lat: 49.08717, lng: -62.29318 },
      { lat: 49.40069, lng: -63.58926 },
      { lat: 49.87304, lng: -64.51912 },
      { lat: 49.95718, lng: -64.17322 },
      { lat: 49.70641, lng: -62.85829 },
      { lat: 49.28855, lng: -61.835585 },
      { lat: 49.10506, lng: -61.806305 },
    ],
    [
      { lat: 79.66, lng: -87.02 },
      { lat: 79.3369, lng: -85.81435 },
      { lat: 79.0393, lng: -87.18756 },
      { lat: 78.28723, lng: -89.03535 },
      { lat: 78.21533, lng: -90.80436 },
      { lat: 78.34333, lng: -92.87669 },
      { lat: 78.75099, lng: -93.95116 },
      { lat: 79.11373, lng: -93.93574 },
      { lat: 79.3801, lng: -93.14524 },
      { lat: 79.37248, lng: -94.974 },
      { lat: 79.70502, lng: -96.07614 },
      { lat: 80.15777, lng: -96.70972 },
      { lat: 80.60233, lng: -96.01644 },
      { lat: 80.90729, lng: -95.32345 },
      { lat: 80.97727, lng: -94.29843 },
      { lat: 81.20646, lng: -94.73542 },
      { lat: 81.25739, lng: -92.40984 },
      { lat: 80.72345, lng: -91.13289 },
      { lat: 80.50932203389829, lng: -89.45 },
      { lat: 80.32, lng: -87.81 },
      { lat: 79.66, lng: -87.02 },
    ],
    [
      { lat: 78.05694122996326, lng: -95.83029496944934 },
      { lat: 77.85059723582178, lng: -97.30984290239799 },
      { lat: 78.08285696075758, lng: -98.12428931353396 },
      { lat: 78.4581053738451, lng: -98.55286780474664 },
      { lat: 78.87193024363839, lng: -98.63198442258552 },
      { lat: 78.83198436147677, lng: -97.33723141151262 },
      { lat: 78.765812689927, lng: -96.75439876990879 },
      { lat: 78.41831452098029, lng: -95.55927792029458 },
      { lat: 78.05694122996326, lng: -95.83029496944934 },
    ],
    [
      { lat: 73.12145, lng: -114.1671699999999 },
      { lat: 72.65277, lng: -114.66634 },
      { lat: 72.95540000000011, lng: -112.44101999999988 },
      { lat: 72.4504, lng: -111.05039 },
      { lat: 72.96113, lng: -109.92034999999989 },
      { lat: 72.63335, lng: -109.00654 },
      { lat: 71.65089, lng: -108.18835 },
      { lat: 72.06548, lng: -107.68599 },
      { lat: 73.08953000000011, lng: -108.39639 },
      { lat: 73.23598, lng: -107.51645 },
      { lat: 73.07601, lng: -106.52259 },
      { lat: 72.67259, lng: -105.40246 },
      { lat: 71.6984, lng: -104.77484 },
      { lat: 70.99297, lng: -104.46475999999984 },
      { lat: 70.49776, lng: -102.78537 },
      { lat: 70.02432, lng: -100.9807799999999 },
      { lat: 69.58447000000012, lng: -101.08929 },
      { lat: 69.50402, lng: -102.73116 },
      { lat: 69.11962000000011, lng: -102.09329 },
      { lat: 68.75282, lng: -102.43024 },
      { lat: 68.91, lng: -104.24 },
      { lat: 69.18000000000015, lng: -105.96 },
      { lat: 69.11922, lng: -107.12254 },
      { lat: 68.78, lng: -109 },
      { lat: 68.63005915681794, lng: -111.53414887520013 },
      { lat: 68.53554, lng: -113.3132 },
      { lat: 69.00744000000012, lng: -113.85495999999983 },
      { lat: 69.28, lng: -115.22 },
      { lat: 69.16821, lng: -116.10794 },
      { lat: 69.96000000000012, lng: -117.34 },
      { lat: 70.06655, lng: -116.67472999999988 },
      { lat: 70.2373, lng: -115.13112 },
      { lat: 70.19237, lng: -113.72141 },
      { lat: 70.36638, lng: -112.4161 },
      { lat: 70.6, lng: -114.35 },
      { lat: 70.52045, lng: -116.48684 },
      { lat: 70.54056000000014, lng: -117.9048 },
      { lat: 70.9092, lng: -118.43238 },
      { lat: 71.30918, lng: -116.11311 },
      { lat: 71.2952, lng: -117.65568 },
      { lat: 71.55859, lng: -119.40199 },
      { lat: 72.30785, lng: -118.56267 },
      { lat: 72.70594, lng: -117.86642 },
      { lat: 73.31459000000012, lng: -115.18909 },
      { lat: 73.12145, lng: -114.1671699999999 },
    ],
    [
      { lat: 69.10769035832178, lng: -95.64768120380052 },
      { lat: 68.75704035832175, lng: -96.2695212038006 },
      { lat: 69.0600303583218, lng: -97.61740120380057 },
      { lat: 68.9507003583218, lng: -98.43180120380052 },
      { lat: 69.4000303583218, lng: -99.79740120380053 },
      { lat: 69.7100303583218, lng: -98.91740120380055 },
      { lat: 70.14354035832176, lng: -98.2182612038005 },
      { lat: 69.86003035832181, lng: -97.15740120380056 },
      { lat: 69.68003035832176, lng: -96.55740120380054 },
      { lat: 69.49003035832177, lng: -96.25740120380053 },
      { lat: 69.10769035832178, lng: -95.64768120380052 },
    ],
    [
      { lat: 65.65728465439281, lng: -85.16130794954985 },
      { lat: 65.217518215589, lng: -84.97576371940596 },
      { lat: 65.37177236598018, lng: -84.4640120104195 },
      { lat: 65.10961782496355, lng: -83.88262630891975 },
      { lat: 64.76669302027469, lng: -82.78757687043877 },
      { lat: 64.45513580998696, lng: -81.64201371939254 },
      { lat: 63.979609280037145, lng: -81.55344031444425 },
      { lat: 64.05748566350101, lng: -80.81736121287886 },
      { lat: 63.725981350348604, lng: -80.10345130076661 },
      { lat: 63.41124603947497, lng: -80.99101986359568 },
      { lat: 63.65172231714524, lng: -82.54717810741701 },
      { lat: 64.10187571883972, lng: -83.10879757356506 },
      { lat: 63.56971181909802, lng: -84.10041663281388 },
      { lat: 63.05237905542409, lng: -85.52340471061902 },
      { lat: 63.637252916103556, lng: -85.86676876498237 },
      { lat: 63.541238104905226, lng: -87.22198320183674 },
      { lat: 64.03583323837071, lng: -86.35275977247127 },
      { lat: 64.82291697860826, lng: -86.22488644076513 },
      { lat: 65.73877838811705, lng: -85.88384782585487 },
      { lat: 65.65728465439281, lng: -85.16130794954985 },
    ],
    [
      { lat: 50.6870097926793, lng: -56.13403581401712 },
      { lat: 49.81230866149096, lng: -56.795881720595276 },
      { lat: 50.150117499382844, lng: -56.1431050278843 },
      { lat: 49.93581533466846, lng: -55.471492275602934 },
      { lat: 49.58712860777911, lng: -55.82240108908093 },
      { lat: 49.31301097268684, lng: -54.935142584845664 },
      { lat: 49.55669118915918, lng: -54.47377539734378 },
      { lat: 49.24913890237405, lng: -53.476549445191324 },
      { lat: 48.51678050393363, lng: -53.78601375997124 },
      { lat: 48.687803656603535, lng: -53.086133999226256 },
      { lat: 48.157164211614486, lng: -52.958648240762244 },
      { lat: 47.5355484075755, lng: -52.64809872090419 },
      { lat: 46.65549876564495, lng: -53.069158291218336 },
      { lat: 46.61829173439483, lng: -53.52145626485304 },
      { lat: 46.80706574155701, lng: -54.17893551290254 },
      { lat: 47.62520701760192, lng: -53.961868659060485 },
      { lat: 47.75227936460763, lng: -54.24048214376214 },
      { lat: 46.884993801453135, lng: -55.4007730780115 },
      { lat: 46.9197203639533, lng: -55.99748084168584 },
      { lat: 47.389562486351, lng: -55.29121904155278 },
      { lat: 47.63254507098739, lng: -56.25079871278052 },
      { lat: 47.572807115258, lng: -57.3252292547771 },
      { lat: 47.603347886742505, lng: -59.26601518414677 },
      { lat: 47.899453843774864, lng: -59.419494188053704 },
      { lat: 48.25152537697949, lng: -58.796586473207405 },
      { lat: 48.52318838153781, lng: -59.231624518456535 },
      { lat: 49.12558055276418, lng: -58.39180497906523 },
      { lat: 50.718274034215845, lng: -57.35868974468604 },
      { lat: 51.28743825947853, lng: -56.73865007183201 },
      { lat: 51.632094224649194, lng: -55.870976935435294 },
      { lat: 51.58827261006573, lng: -55.406974249886616 },
      { lat: 51.31707469339793, lng: -55.60021826844209 },
      { lat: 50.6870097926793, lng: -56.13403581401712 },
    ],
  ],
  CD: [
    [
      { lat: 3.509165961110341, lng: 30.833859897593808 },
      { lat: 2.339883327642127, lng: 30.773346795380043 },
      { lat: 2.204465236821264, lng: 31.174149204235814 },
      { lat: 1.849396470543809, lng: 30.852670118948055 },
      { lat: 1.58380544677972, lng: 30.468507521290295 },
      { lat: 1.062312730306289, lng: 30.086153598762703 },
      { lat: 0.597379868976304, lng: 29.875778842902495 },
      { lat: -0.205310153813372, lng: 29.819503208136638 },
      { lat: -0.587405694179481, lng: 29.58783776217217 },
      { lat: -1.341313164885626, lng: 29.579466180140884 },
      { lat: -1.620055840667987, lng: 29.29188683443661 },
      { lat: -2.215109958508911, lng: 29.25483483248334 },
      { lat: -2.292211195488385, lng: 29.117478875451553 },
      { lat: -2.839257907730158, lng: 29.024926385216787 },
      { lat: -3.293907159034063, lng: 29.276383904749053 },
      { lat: -4.499983412294092, lng: 29.339997592900346 },
      { lat: -5.419978936386314, lng: 29.519986606572925 },
      { lat: -5.939998874539433, lng: 29.419992710088167 },
      { lat: -6.520015150583426, lng: 29.62003217949001 },
      { lat: -7.079980970898163, lng: 30.199996779101696 },
      { lat: -8.340007419470915, lng: 30.740015496551788 },
      { lat: -8.238256524288218, lng: 30.346086053190813 },
      { lat: -8.407031752153472, lng: 29.002912225060467 },
      { lat: -8.526559340044578, lng: 28.7348665707625 },
      { lat: -9.164918308146085, lng: 28.449871046672826 },
      { lat: -9.605924981324932, lng: 28.67368167492893 },
      { lat: -10.789883721564044, lng: 28.49606977714177 },
      { lat: -11.793646742401393, lng: 28.372253045370428 },
      { lat: -11.971568698782315, lng: 28.642417433392353 },
      { lat: -12.360743910372413, lng: 29.34154788586909 },
      { lat: -12.178894545137311, lng: 29.61600141777123 },
      { lat: -13.257226657771831, lng: 29.69961388521949 },
      { lat: -13.248958428605135, lng: 28.934285922976837 },
      { lat: -12.698604424696683, lng: 28.523561639121027 },
      { lat: -12.272480564017897, lng: 28.155108676879987 },
      { lat: -12.132747491100666, lng: 27.38879886242378 },
      { lat: -11.608748467661075, lng: 27.164419793412463 },
      { lat: -11.924439792532127, lng: 26.553087599399618 },
      { lat: -11.784965101776358, lng: 25.752309604604733 },
      { lat: -11.330935967659961, lng: 25.418118116973204 },
      { lat: -11.238693536018964, lng: 24.78316979340295 },
      { lat: -11.26282642989927, lng: 24.31451622894795 },
      { lat: -10.951992689663657, lng: 24.25715538910399 },
      { lat: -10.926826267137514, lng: 23.912215203555718 },
      { lat: -10.867863457892483, lng: 23.45679080576744 },
      { lat: -11.01762175867433, lng: 22.83734541188474 },
      { lat: -10.99307545333569, lng: 22.402798292742375 },
      { lat: -11.084801120653772, lng: 22.155268182064308 },
      { lat: -9.894796237836509, lng: 22.208753289486395 },
      { lat: -9.523707777548566, lng: 21.875181919042348 },
      { lat: -8.908706556842978, lng: 21.8018013851879 },
      { lat: -8.305900974158277, lng: 21.949130893652043 },
      { lat: -7.920084730667149, lng: 21.74645592620331 },
      { lat: -7.290872491081302, lng: 21.7281107927397 },
      { lat: -7.299605808138629, lng: 20.514748162526498 },
      { lat: -6.939317722199682, lng: 20.6018229509383 },
      { lat: -6.943090101756993, lng: 20.091621534920648 },
      { lat: -7.116361179231646, lng: 20.037723016040218 },
      { lat: -7.155428562044298, lng: 19.417502475673157 },
      { lat: -7.738183688999754, lng: 19.16661339689611 },
      { lat: -7.988245944860132, lng: 19.01675174324967 },
      { lat: -7.847014255406444, lng: 18.464175652752687 },
      { lat: -7.987677504104922, lng: 18.13422163256905 },
      { lat: -8.0685511206417, lng: 17.472970004962235 },
      { lat: -7.545688978712526, lng: 17.08999596524717 },
      { lat: -7.222297865429986, lng: 16.8601908708452 },
      { lat: -6.622644545115087, lng: 16.57317996589614 },
      { lat: -5.877470391466267, lng: 16.326528354567046 },
      { lat: -5.864241224799549, lng: 13.375597364971895 },
      { lat: -5.984388929878157, lng: 13.02486941900696 },
      { lat: -5.965682061388499, lng: 12.735171339578699 },
      { lat: -6.10009246177966, lng: 12.32243167486351 },
      { lat: -5.789930515163839, lng: 12.182336866920252 },
      { lat: -5.684303887559246, lng: 12.436688266660868 },
      { lat: -5.248361504745005, lng: 12.468004184629736 },
      { lat: -4.991271254092936, lng: 12.63161176926579 },
      { lat: -4.781103203961884, lng: 12.995517205465177 },
      { lat: -4.882957452009165, lng: 13.258240187237048 },
      { lat: -4.50013844159097, lng: 13.600234816144678 },
      { lat: -4.510008640158715, lng: 14.144956088933299 },
      { lat: -4.793092136253598, lng: 14.209034864975223 },
      { lat: -4.97023894615014, lng: 14.58260379401318 },
      { lat: -4.343507175314301, lng: 15.170991652088444 },
      { lat: -3.855164890156096, lng: 15.75354007331475 },
      { lat: -3.535132744972529, lng: 16.0062895036543 },
      { lat: -2.712392266453612, lng: 15.972803175529151 },
      { lat: -1.740927015798682, lng: 16.407091912510054 },
      { lat: -1.225816338713287, lng: 16.865306837642123 },
      { lat: -0.743830254726987, lng: 17.523716261472856 },
      { lat: -0.424831638189247, lng: 17.638644646889986 },
      { lat: -0.058083998213817, lng: 17.66355268725468 },
      { lat: 0.288923244626105, lng: 17.826540154703252 },
      { lat: 0.855658677571085, lng: 17.774191928791566 },
      { lat: 1.741831976728278, lng: 17.898835483479587 },
      { lat: 2.365721543788055, lng: 18.094275750407434 },
      { lat: 2.90044342692822, lng: 18.39379235197114 },
      { lat: 3.504385891123349, lng: 18.45306521980993 },
      { lat: 4.201785183118318, lng: 18.54298221199778 },
      { lat: 4.709506130385975, lng: 18.93231245288476 },
      { lat: 5.03152781821278, lng: 19.467783644293146 },
      { lat: 4.691677761245288, lng: 20.290679152108936 },
      { lat: 4.322785549329737, lng: 20.927591180106276 },
      { lat: 4.22434194581372, lng: 21.659122755630023 },
      { lat: 4.029160061047321, lng: 22.405123732195538 },
      { lat: 4.633050848810157, lng: 22.70412356943629 },
      { lat: 4.710126247573484, lng: 22.841479526468106 },
      { lat: 4.609693101414223, lng: 23.29721398285014 },
      { lat: 5.108784084489129, lng: 24.410531040146253 },
      { lat: 4.89724660890235, lng: 24.805028924262416 },
      { lat: 4.927244777847789, lng: 25.12883344900328 },
      { lat: 5.170408229997192, lng: 25.278798455514302 },
      { lat: 5.256087754737123, lng: 25.650455356557472 },
      { lat: 5.150874538590871, lng: 26.402760857862543 },
      { lat: 5.127852688004836, lng: 27.04406538260471 },
      { lat: 5.233944403500061, lng: 27.37422610851749 },
      { lat: 4.408413397637375, lng: 27.979977247842807 },
      { lat: 4.287154649264494, lng: 28.428993768026913 },
      { lat: 4.455077215996937, lng: 28.696677687298802 },
      { lat: 4.389267279473231, lng: 29.1590784034465 },
      { lat: 4.600804755060025, lng: 29.71599531425602 },
      { lat: 4.173699042167683, lng: 29.953500197069474 },
      { lat: 3.509165961110341, lng: 30.833859897593808 },
    ],
  ],
  CF: [
    [
      { lat: 7.421924546737969, lng: 15.279460483469109 },
      { lat: 7.497087917506505, lng: 16.10623172370677 },
      { lat: 7.754307359239306, lng: 16.290561557691888 },
      { lat: 7.734773667832968, lng: 16.456184523187346 },
      { lat: 7.508327541529979, lng: 16.705988396886255 },
      { lat: 7.890914008002866, lng: 17.964929640380888 },
      { lat: 8.281303615751824, lng: 18.38955488452322 },
      { lat: 8.630894680206353, lng: 18.911021762780507 },
      { lat: 8.982914536978598, lng: 18.81200971850927 },
      { lat: 9.07484691002584, lng: 19.09400800952602 },
      { lat: 9.012706000194854, lng: 20.05968549976427 },
      { lat: 9.475985215691509, lng: 21.000868361096167 },
      { lat: 10.567055568885976, lng: 21.723821648859452 },
      { lat: 10.97188873946051, lng: 22.231129184668788 },
      { lat: 11.142395127807546, lng: 22.864165480244225 },
      { lat: 10.71446259199854, lng: 22.97754357269261 },
      { lat: 10.089255275915308, lng: 23.554304233502194 },
      { lat: 9.681218166538684, lng: 23.557249790142826 },
      { lat: 9.265067857292223, lng: 23.394779087017184 },
      { lat: 8.954285793488893, lng: 23.459012892355986 },
      { lat: 8.666318874542426, lng: 23.805813429466752 },
      { lat: 8.229187933785468, lng: 24.567369012152085 },
      { lat: 7.825104071479174, lng: 25.11493248871679 },
      { lat: 7.500085150579436, lng: 25.124130893664727 },
      { lat: 6.979315904158071, lng: 25.79664798351118 },
      { lat: 6.546603298362072, lng: 26.213418409945117 },
      { lat: 5.94671743410187, lng: 26.46590945812323 },
      { lat: 5.550953477394557, lng: 27.21340905122517 },
      { lat: 5.233944403500061, lng: 27.37422610851749 },
      { lat: 5.127852688004836, lng: 27.04406538260471 },
      { lat: 5.150874538590871, lng: 26.402760857862543 },
      { lat: 5.256087754737123, lng: 25.650455356557472 },
      { lat: 5.170408229997192, lng: 25.278798455514302 },
      { lat: 4.927244777847789, lng: 25.12883344900328 },
      { lat: 4.89724660890235, lng: 24.805028924262416 },
      { lat: 5.108784084489129, lng: 24.410531040146253 },
      { lat: 4.609693101414223, lng: 23.29721398285014 },
      { lat: 4.710126247573484, lng: 22.841479526468106 },
      { lat: 4.633050848810157, lng: 22.70412356943629 },
      { lat: 4.029160061047321, lng: 22.405123732195538 },
      { lat: 4.22434194581372, lng: 21.659122755630023 },
      { lat: 4.322785549329737, lng: 20.927591180106276 },
      { lat: 4.691677761245288, lng: 20.290679152108936 },
      { lat: 5.03152781821278, lng: 19.467783644293146 },
      { lat: 4.709506130385975, lng: 18.93231245288476 },
      { lat: 4.201785183118318, lng: 18.54298221199778 },
      { lat: 3.504385891123349, lng: 18.45306521980993 },
      { lat: 3.56019643799857, lng: 17.809900343505262 },
      { lat: 3.728196519379452, lng: 17.133042433346304 },
      { lat: 3.198254706226279, lng: 16.537058139724135 },
      { lat: 2.267639675298085, lng: 16.012852410555354 },
      { lat: 2.557389431158612, lng: 15.907380812247652 },
      { lat: 3.013537298998983, lng: 15.86273237474748 },
      { lat: 3.33530060466434, lng: 15.405395948964383 },
      { lat: 3.851367295747124, lng: 15.036219516671252 },
      { lat: 4.210389309094921, lng: 14.950953403389661 },
      { lat: 4.732605495620447, lng: 14.478372430080467 },
      { lat: 5.03059764243153, lng: 14.558935988023505 },
      { lat: 5.4517605656103, lng: 14.459407179429348 },
      { lat: 6.22695872642069, lng: 14.536560092841112 },
      { lat: 6.408498033062045, lng: 14.776545444404576 },
      { lat: 7.421924546737969, lng: 15.279460483469109 },
    ],
  ],
  CG: [
    [
      { lat: -4.781103203961884, lng: 12.995517205465177 },
      { lat: -4.438023369976136, lng: 12.620759718484491 },
      { lat: -4.606230157086188, lng: 12.318607618873926 },
      { lat: -5.037986748884791, lng: 11.91496300624209 },
      { lat: -3.978826592630547, lng: 11.093772820691925 },
      { lat: -3.426870619321051, lng: 11.855121697648114 },
      { lat: -2.765618991714241, lng: 11.478038771214303 },
      { lat: -2.514161472181982, lng: 11.820963575903193 },
      { lat: -2.391688327650243, lng: 12.495702752338161 },
      { lat: -1.948511244315135, lng: 12.575284458067642 },
      { lat: -2.428740329603514, lng: 13.109618767965628 },
      { lat: -2.4708049454891, lng: 13.99240726080771 },
      { lat: -1.998275648612214, lng: 14.299210239324564 },
      { lat: -1.333406670744971, lng: 14.425455763413593 },
      { lat: -0.552627455247048, lng: 14.316418491277743 },
      { lat: 0.038757635901149, lng: 13.843320753645655 },
      { lat: 1.196929836426619, lng: 14.276265903386957 },
      { lat: 1.395677395021153, lng: 14.026668735417218 },
      { lat: 1.31418366129688, lng: 13.282631463278818 },
      { lat: 1.83089630778332, lng: 13.003113641012078 },
      { lat: 2.267097072759015, lng: 13.075822381246752 },
      { lat: 2.227874660649491, lng: 14.33781253424658 },
      { lat: 1.964014797367184, lng: 15.146341993885244 },
      { lat: 1.727672634280295, lng: 15.940918816805064 },
      { lat: 2.267639675298085, lng: 16.012852410555354 },
      { lat: 3.198254706226279, lng: 16.537058139724135 },
      { lat: 3.728196519379452, lng: 17.133042433346304 },
      { lat: 3.56019643799857, lng: 17.809900343505262 },
      { lat: 3.504385891123349, lng: 18.45306521980993 },
      { lat: 2.90044342692822, lng: 18.39379235197114 },
      { lat: 2.365721543788055, lng: 18.094275750407434 },
      { lat: 1.741831976728278, lng: 17.898835483479587 },
      { lat: 0.855658677571085, lng: 17.774191928791566 },
      { lat: 0.288923244626105, lng: 17.826540154703252 },
      { lat: -0.058083998213817, lng: 17.66355268725468 },
      { lat: -0.424831638189247, lng: 17.638644646889986 },
      { lat: -0.743830254726987, lng: 17.523716261472856 },
      { lat: -1.225816338713287, lng: 16.865306837642123 },
      { lat: -1.740927015798682, lng: 16.407091912510054 },
      { lat: -2.712392266453612, lng: 15.972803175529151 },
      { lat: -3.535132744972529, lng: 16.0062895036543 },
      { lat: -3.855164890156096, lng: 15.75354007331475 },
      { lat: -4.343507175314301, lng: 15.170991652088444 },
      { lat: -4.97023894615014, lng: 14.58260379401318 },
      { lat: -4.793092136253598, lng: 14.209034864975223 },
      { lat: -4.510008640158715, lng: 14.144956088933299 },
      { lat: -4.50013844159097, lng: 13.600234816144678 },
      { lat: -4.882957452009165, lng: 13.258240187237048 },
      { lat: -4.781103203961884, lng: 12.995517205465177 },
    ],
  ],
  CH: [
    [
      { lat: 47.52505809182027, lng: 9.59422610844635 },
      { lat: 47.34760122332999, lng: 9.632931756232978 },
      { lat: 47.10280996356337, lng: 9.479969516649021 },
      { lat: 46.92072805438296, lng: 9.932448357796659 },
      { lat: 46.89354625099743, lng: 10.44270145024663 },
      { lat: 46.48357127540986, lng: 10.363378126678612 },
      { lat: 46.31489940040919, lng: 9.92283654139038 },
      { lat: 46.44021474871698, lng: 9.182881707403055 },
      { lat: 46.036931871111186, lng: 8.966305779667806 },
      { lat: 46.005150865251686, lng: 8.489952426801324 },
      { lat: 46.16364248309086, lng: 8.31662967289438 },
      { lat: 45.82449005795931, lng: 7.755992058959833 },
      { lat: 45.776947740250776, lng: 7.273850945676656 },
      { lat: 45.99114655210061, lng: 6.843592970414504 },
      { lat: 46.42967275652944, lng: 6.500099724970425 },
      { lat: 46.27298981382047, lng: 6.022609490593537 },
      { lat: 46.725778713561866, lng: 6.037388950229001 },
      { lat: 47.2877082383037, lng: 6.768713820023606 },
      { lat: 47.541801255882845, lng: 6.736571079138059 },
      { lat: 47.44976552997102, lng: 7.192202182655507 },
      { lat: 47.62058197691181, lng: 7.46675906742223 },
      { lat: 47.61357982033626, lng: 8.317301466514152 },
      { lat: 47.830827541691285, lng: 8.522611932009765 },
      { lat: 47.52505809182027, lng: 9.59422610844635 },
    ],
  ],
  CI: [
    [
      { lat: 4.994475816259509, lng: -2.856125047202397 },
      { lat: 4.984295559098015, lng: -3.311084357100071 },
      { lat: 5.179813340674315, lng: -4.008819545904942 },
      { lat: 5.168263658057086, lng: -4.649917364917911 },
      { lat: 4.993700669775137, lng: -5.834496222344526 },
      { lat: 4.705087795425015, lng: -6.528769090185847 },
      { lat: 4.338288479017308, lng: -7.518941209330436 },
      { lat: 4.364565944837722, lng: -7.71215938966975 },
      { lat: 5.188159084489456, lng: -7.63536821128403 },
      { lat: 5.313345241716519, lng: -7.539715135111762 },
      { lat: 5.707352199725904, lng: -7.570152553731688 },
      { lat: 6.126189683451543, lng: -7.993692592795881 },
      { lat: 6.193033148621083, lng: -8.311347622094019 },
      { lat: 6.46756419517166, lng: -8.60288021486862 },
      { lat: 6.911800645368742, lng: -8.385451626000574 },
      { lat: 7.39520783124307, lng: -8.48544552248535 },
      { lat: 7.686042792181738, lng: -8.439298468448698 },
      { lat: 7.687179673692156, lng: -8.280703497744938 },
      { lat: 8.123328762235573, lng: -8.221792364932199 },
      { lat: 8.316443589710303, lng: -8.299048631208564 },
      { lat: 8.455453192575447, lng: -8.20349890790088 },
      { lat: 8.575704250518626, lng: -7.832100389019188 },
      { lat: 9.376223863152035, lng: -8.079113735374348 },
      { lat: 9.789531968622441, lng: -8.30961646161225 },
      { lat: 10.1290202905639, lng: -8.229337124046822 },
      { lat: 10.206534939001713, lng: -8.029943610048619 },
      { lat: 10.297382106970828, lng: -7.899589809592372 },
      { lat: 10.147236232946796, lng: -7.622759161804809 },
      { lat: 10.138993841996239, lng: -6.850506557635057 },
      { lat: 10.430810655148447, lng: -6.666460944027548 },
      { lat: 10.411302801958271, lng: -6.493965013037267 },
      { lat: 10.524060777219134, lng: -6.205222947606431 },
      { lat: 10.096360785355444, lng: -6.050452032892267 },
      { lat: 10.222554633012194, lng: -5.816926235365287 },
      { lat: 10.370736802609146, lng: -5.404341599946974 },
      { lat: 10.152713934769736, lng: -4.954653286143099 },
      { lat: 9.821984768101743, lng: -4.779883592131966 },
      { lat: 9.61083486575714, lng: -4.330246954760383 },
      { lat: 9.8623440617217, lng: -3.980449184576685 },
      { lat: 9.90032623945622, lng: -3.511898972986273 },
      { lat: 9.642460842319778, lng: -2.827496303712707 },
      { lat: 8.219627793811483, lng: -2.562189500326241 },
      { lat: 7.379704901555512, lng: -2.983584967450327 },
      { lat: 6.250471503113502, lng: -3.244370083011262 },
      { lat: 5.38905121502411, lng: -2.81070146321784 },
      { lat: 4.994475816259509, lng: -2.856125047202397 },
    ],
  ],
  CK: [
    [
      { lat: -21.185692243796023, lng: -159.8284149169922 },
      { lat: -21.25866133371465, lng: -159.83871459960938 },
      { lat: -21.286815182224156, lng: -159.7309112548828 },
      { lat: -21.24330241973194, lng: -159.7137451171875 },
      { lat: -21.186332480346355, lng: -159.7295379638672 },
      { lat: -21.185692243796023, lng: -159.8284149169922 },
    ],
  ],
  CL: [
    [
      { lat: -21.494346612231837, lng: -68.21991309271124 },
      { lat: -22.87291879648218, lng: -67.82817989772266 },
      { lat: -22.7359245744764, lng: -67.10667355006362 },
      { lat: -22.98634856536283, lng: -66.98523393417764 },
      { lat: -24.02530323659091, lng: -67.32844295924414 },
      { lat: -24.518554782816878, lng: -68.41765296087613 },
      { lat: -26.185016371365233, lng: -68.38600114609736 },
      { lat: -26.506908868111267, lng: -68.59479977077268 },
      { lat: -26.89933969493579, lng: -68.2955415513704 },
      { lat: -27.52121388113613, lng: -69.00123491074828 },
      { lat: -28.459141127233693, lng: -69.65613033718314 },
      { lat: -29.36792286551855, lng: -70.01355038112987 },
      { lat: -30.336339206668313, lng: -69.91900834825192 },
      { lat: -31.365010267870286, lng: -70.53506893581945 },
      { lat: -33.09120981214803, lng: -70.07439938015364 },
      { lat: -33.27388600029985, lng: -69.81477698431921 },
      { lat: -34.193571465798286, lng: -69.81730912950147 },
      { lat: -35.16968759535944, lng: -70.38804948594908 },
      { lat: -36.005088799789945, lng: -70.36476925320167 },
      { lat: -36.65812387466234, lng: -71.1218806627098 },
      { lat: -37.5768274879472, lng: -71.11862504747543 },
      { lat: -38.55299529394074, lng: -70.81466427273472 },
      { lat: -38.916022230791114, lng: -71.41351660834904 },
      { lat: -39.80816415787807, lng: -71.68076127794646 },
      { lat: -40.83233936947073, lng: -71.91573401557756 },
      { lat: -42.051386407235995, lng: -71.74680375841547 },
      { lat: -42.25488819760139, lng: -72.14889807807853 },
      { lat: -43.40856454851742, lng: -71.91542395698391 },
      { lat: -43.78761117937833, lng: -71.46405615913051 },
      { lat: -44.20717213315611, lng: -71.79362260607195 },
      { lat: -44.40752166115169, lng: -71.32980078803621 },
      { lat: -44.784242852559416, lng: -71.22277889675973 },
      { lat: -44.97368865334144, lng: -71.65931555854533 },
      { lat: -45.56073292417713, lng: -71.55200944689125 },
      { lat: -46.8848381487918, lng: -71.91725847033021 },
      { lat: -47.73853281025353, lng: -72.44735531278027 },
      { lat: -48.244238376661826, lng: -72.33116085477195 },
      { lat: -48.87861825947679, lng: -72.64824744331494 },
      { lat: -49.31843637471296, lng: -73.41543575712004 },
      { lat: -50.37878508890987, lng: -73.32805091011448 },
      { lat: -50.74145029073431, lng: -72.97574683296463 },
      { lat: -50.677009779666356, lng: -72.30997351753237 },
      { lat: -51.42595631287241, lng: -72.32940385607404 },
      { lat: -52.009022305865926, lng: -71.91480383979635 },
      { lat: -52.14276091263725, lng: -69.49836218939609 },
      { lat: -52.29944385534626, lng: -68.57154537624135 },
      { lat: -52.29195077266393, lng: -69.46128434922664 },
      { lat: -52.53793059037325, lng: -69.94277950710614 },
      { lat: -52.899200528525725, lng: -70.84510169135453 },
      { lat: -53.83325204220135, lng: -71.00633216010525 },
      { lat: -53.85645476030039, lng: -71.42979468452094 },
      { lat: -53.531410001184454, lng: -72.55794287788486 },
      { lat: -52.83506926860725, lng: -73.70275672066288 },
      { lat: -52.8350700760515, lng: -73.70275672066288 },
      { lat: -52.26275358841903, lng: -74.94676347522515 },
      { lat: -51.629354750373224, lng: -75.2600260077785 },
      { lat: -51.04339568461569, lng: -74.9766324530898 },
      { lat: -50.37837167745156, lng: -75.4797541978835 },
      { lat: -48.6737728818718, lng: -75.60801510283196 },
      { lat: -47.71191944762316, lng: -75.18276974150213 },
      { lat: -46.9392534319951, lng: -74.1265809801047 },
      { lat: -46.64764332457203, lng: -75.64439531116545 },
      { lat: -45.76397633238098, lng: -74.69215369332306 },
      { lat: -44.103044122087894, lng: -74.35170935738427 },
      { lat: -44.454960625995625, lng: -73.2403560045152 },
      { lat: -42.383355808278985, lng: -72.71780392117978 },
      { lat: -42.11753224056957, lng: -73.38889990913825 },
      { lat: -43.365776462579745, lng: -73.70133561877486 },
      { lat: -43.22495818458441, lng: -74.33194312203258 },
      { lat: -41.794812920906836, lng: -74.01795711942717 },
      { lat: -39.942212823243125, lng: -73.67709937202997 },
      { lat: -39.258688653318515, lng: -73.21759253609068 },
      { lat: -38.28288258235107, lng: -73.50555945503706 },
      { lat: -37.156284681956016, lng: -73.58806087919109 },
      { lat: -37.12378020604435, lng: -73.1667170884993 },
      { lat: -35.508840020491036, lng: -72.55313696968173 },
      { lat: -33.90909270603153, lng: -71.86173214383257 },
      { lat: -32.41889942803083, lng: -71.43845048692992 },
      { lat: -30.92064462659252, lng: -71.66872066922244 },
      { lat: -30.095682061485004, lng: -71.37008256700773 },
      { lat: -28.861442152625912, lng: -71.48989437527646 },
      { lat: -27.6403797340012, lng: -70.90512386746158 },
      { lat: -25.70592416758721, lng: -70.72495398627598 },
      { lat: -23.628996677344542, lng: -70.40396582709505 },
      { lat: -21.393319187101223, lng: -70.09124589708067 },
      { lat: -19.756468194256186, lng: -70.16441972520599 },
      { lat: -18.347975355708883, lng: -70.37257239447774 },
      { lat: -18.092693780187034, lng: -69.85844356960581 },
      { lat: -17.58001189541929, lng: -69.590423753524 },
      { lat: -18.260125420812656, lng: -69.10024695501943 },
      { lat: -18.981683444904093, lng: -68.96681840684184 },
      { lat: -19.405068454671422, lng: -68.44222510443095 },
      { lat: -20.372657972904477, lng: -68.75716712103372 },
      { lat: -21.494346612231837, lng: -68.21991309271124 },
    ],
    [
      { lat: -52.63637045887437, lng: -68.63401022758316 },
      { lat: -54.8695, lng: -68.6333499999999 },
      { lat: -54.87001, lng: -67.56244 },
      { lat: -54.89681, lng: -66.95992 },
      { lat: -55.30124, lng: -67.29102999999989 },
      { lat: -55.61183, lng: -68.14862999999986 },
      { lat: -55.58001799908689, lng: -68.63999081081181 },
      { lat: -55.49906, lng: -69.2321 },
      { lat: -55.19843, lng: -69.95809 },
      { lat: -55.05383, lng: -71.00568 },
      { lat: -54.49514, lng: -72.2639 },
      { lat: -53.95751999999989, lng: -73.2852 },
      { lat: -52.83749, lng: -74.66253 },
      { lat: -53.04743, lng: -73.8381 },
      { lat: -53.7154, lng: -72.43418 },
      { lat: -54.07433, lng: -71.10773 },
      { lat: -53.61583, lng: -70.59177999999979 },
      { lat: -52.93123, lng: -70.26748 },
      { lat: -52.5183, lng: -69.34564999999989 },
      { lat: -52.63637045887437, lng: -68.63401022758316 },
    ],
  ],
  CM: [
    [
      { lat: 2.267097072759015, lng: 13.075822381246752 },
      { lat: 2.32161570882694, lng: 12.951333855855609 },
      { lat: 2.19281220133945, lng: 12.359380323952221 },
      { lat: 2.326757513839993, lng: 11.75166548019979 },
      { lat: 2.261050930180872, lng: 11.276449008843713 },
      { lat: 2.283866075037736, lng: 9.649158155972628 },
      { lat: 3.073404445809117, lng: 9.795195753629457 },
      { lat: 3.734526882335202, lng: 9.404366896206 },
      { lat: 3.904128933117136, lng: 8.948115675501072 },
      { lat: 4.35221527751996, lng: 8.744923943729418 },
      { lat: 4.495617377129918, lng: 8.488815545290889 },
      { lat: 4.771982937026849, lng: 8.500287713259695 },
      { lat: 5.479665839047911, lng: 8.757532993208628 },
      { lat: 6.444490668153334, lng: 9.233162876023044 },
      { lat: 6.453482367372117, lng: 9.522705926154401 },
      { lat: 7.038769639509879, lng: 10.118276808318257 },
      { lat: 7.055357774275564, lng: 10.497375115611417 },
      { lat: 6.644426784690594, lng: 11.05878787603035 },
      { lat: 6.981382961449753, lng: 11.74577436691851 },
      { lat: 7.397042344589436, lng: 11.839308709366803 },
      { lat: 7.799808457872302, lng: 12.063946160539558 },
      { lat: 8.305824082874324, lng: 12.218872104550599 },
      { lat: 8.717762762888995, lng: 12.753671502339214 },
      { lat: 9.417771714714704, lng: 12.955467970438974 },
      { lat: 9.640626328973411, lng: 13.167599724997103 },
      { lat: 10.160362046748928, lng: 13.308676385153918 },
      { lat: 10.798565985553566, lng: 13.572949659894562 },
      { lat: 11.572368882692075, lng: 14.415378859116686 },
      { lat: 11.904751695193411, lng: 14.468192172918975 },
      { lat: 12.085360826053503, lng: 14.577177768622533 },
      { lat: 12.483656927943116, lng: 14.181336297266792 },
      { lat: 12.802035427293347, lng: 14.213530714584634 },
      { lat: 12.85939626713733, lng: 14.495787387762844 },
      { lat: 12.219047756392584, lng: 14.893385857816526 },
      { lat: 11.555574042197224, lng: 14.9601518083376 },
      { lat: 10.891325181517473, lng: 14.923564894274959 },
      { lat: 9.98233673750343, lng: 15.467872755605269 },
      { lat: 9.99212942142273, lng: 14.909353875394716 },
      { lat: 9.920919297724538, lng: 14.62720055508106 },
      { lat: 10.021378282099931, lng: 14.171466098699028 },
      { lat: 9.549494940626685, lng: 13.954218377344006 },
      { lat: 8.965861314322268, lng: 14.544466586981768 },
      { lat: 8.796104234243472, lng: 14.97999555833769 },
      { lat: 8.382150173369423, lng: 15.120865512765334 },
      { lat: 7.692812404811973, lng: 15.436091749745769 },
      { lat: 7.421924546737969, lng: 15.279460483469109 },
      { lat: 6.408498033062045, lng: 14.776545444404576 },
      { lat: 6.22695872642069, lng: 14.536560092841112 },
      { lat: 5.4517605656103, lng: 14.459407179429348 },
      { lat: 5.03059764243153, lng: 14.558935988023505 },
      { lat: 4.732605495620447, lng: 14.478372430080467 },
      { lat: 4.210389309094921, lng: 14.950953403389661 },
      { lat: 3.851367295747124, lng: 15.036219516671252 },
      { lat: 3.33530060466434, lng: 15.405395948964383 },
      { lat: 3.013537298998983, lng: 15.86273237474748 },
      { lat: 2.557389431158612, lng: 15.907380812247652 },
      { lat: 2.267639675298085, lng: 16.012852410555354 },
      { lat: 1.727672634280295, lng: 15.940918816805064 },
      { lat: 1.964014797367184, lng: 15.146341993885244 },
      { lat: 2.227874660649491, lng: 14.33781253424658 },
      { lat: 2.267097072759015, lng: 13.075822381246752 },
    ],
  ],
  CN: [
    [
      { lat: 18.678395087147607, lng: 110.33918786015154 },
      { lat: 18.197700913968614, lng: 109.4752095886637 },
      { lat: 18.5076819930714, lng: 108.65520796105616 },
      { lat: 19.367887885001977, lng: 108.62621748254045 },
      { lat: 19.821038519769385, lng: 109.11905561730802 },
      { lat: 20.101253973872076, lng: 110.21159874882285 },
      { lat: 20.07753449145008, lng: 110.78655073450223 },
      { lat: 19.695929877190736, lng: 111.01005130416465 },
      { lat: 19.255879218009312, lng: 110.57064660038682 },
      { lat: 18.678395087147607, lng: 110.33918786015154 },
    ],
    [
      { lat: 49.76027049417294, lng: 127.65740726126242 },
      { lat: 50.73979726826545, lng: 127.28745568248493 },
      { lat: 51.353894151405896, lng: 126.93915652883769 },
      { lat: 51.7842554795327, lng: 126.564399041857 },
      { lat: 52.79279857035696, lng: 125.9463489116462 },
      { lat: 53.16104482686886, lng: 125.06821129771046 },
      { lat: 53.458804429734656, lng: 123.57150678924087 },
      { lat: 53.43172597921369, lng: 122.24574791879289 },
      { lat: 53.25140106873124, lng: 121.00308475147025 },
      { lat: 52.75388621684121, lng: 120.1770886577169 },
      { lat: 52.51622630473081, lng: 120.725789015792 },
      { lat: 51.96411530212454, lng: 120.738191359542 },
      { lat: 51.64356639261803, lng: 120.18204959521695 },
      { lat: 50.58290761982729, lng: 119.27936567594239 },
      { lat: 50.14288279886204, lng: 119.28846072802587 },
      { lat: 49.51098338479696, lng: 117.8792444194264 },
      { lat: 49.88853139912141, lng: 116.67880089728621 },
      { lat: 49.13459809019906, lng: 116.19180219936763 },
      { lat: 48.13538259540347, lng: 115.48528201707305 },
      { lat: 47.72654450132629, lng: 115.74283735561576 },
      { lat: 47.85341014260281, lng: 116.30895267137326 },
      { lat: 47.69770905210739, lng: 117.29550744025747 },
      { lat: 48.066730455103745, lng: 118.06414269416675 },
      { lat: 47.74706004494621, lng: 118.86657433479498 },
      { lat: 47.04805878355013, lng: 119.77282392789756 },
      { lat: 46.69267995867895, lng: 119.66326989143877 },
      { lat: 46.80541209572367, lng: 118.87432579963874 },
      { lat: 46.6727328558142, lng: 117.42170128791425 },
      { lat: 46.38820241961524, lng: 116.71786828009888 },
      { lat: 45.72723501238601, lng: 115.98509647020013 },
      { lat: 45.33981679949388, lng: 114.46033165899607 },
      { lat: 44.8088931341271, lng: 113.46390669154422 },
      { lat: 45.011645616224264, lng: 112.43606245325887 },
      { lat: 45.102079372735126, lng: 111.87330610560028 },
      { lat: 44.457441718110054, lng: 111.34837690637946 },
      { lat: 44.073175767587706, lng: 111.66773725794323 },
      { lat: 43.7431183945395, lng: 111.8295878438814 },
      { lat: 43.40683401140017, lng: 111.12968224492023 },
      { lat: 42.87123362891102, lng: 110.4121033061153 },
      { lat: 42.51944631608416, lng: 109.24359581913146 },
      { lat: 42.481515814781915, lng: 107.744772576938 },
      { lat: 42.134327704428905, lng: 106.12931562706169 },
      { lat: 41.597409572916334, lng: 104.96499393109346 },
      { lat: 41.908346666016634, lng: 104.52228193564903 },
      { lat: 41.907468166667634, lng: 103.31227827353482 },
      { lat: 42.514872951826284, lng: 101.83304039917995 },
      { lat: 42.66380442969143, lng: 100.8458655131083 },
      { lat: 42.5246914739617, lng: 99.51581749878002 },
      { lat: 42.74888967546009, lng: 97.451757440178 },
      { lat: 42.72563528092866, lng: 96.34939578652782 },
      { lat: 43.31944916439461, lng: 95.7624548685567 },
      { lat: 44.24133087826546, lng: 95.30687544147153 },
      { lat: 44.35233185482846, lng: 94.68892866412537 },
      { lat: 44.97547211362001, lng: 93.48073367714133 },
      { lat: 45.11507599545645, lng: 92.13389082231825 },
      { lat: 45.28607330991024, lng: 90.94553958533433 },
      { lat: 45.71971609148751, lng: 90.58576826371834 },
      { lat: 46.88814606382295, lng: 90.97080936072499 },
      { lat: 47.69354909930791, lng: 90.28082563676392 },
      { lat: 48.06908173277302, lng: 88.85429772334678 },
      { lat: 48.5994627956006, lng: 88.0138322285517 },
      { lat: 49.297197984405464, lng: 87.75126427607668 },
      { lat: 49.214980780629176, lng: 87.35997033076269 },
      { lat: 48.549181626980605, lng: 86.59877648310336 },
      { lat: 48.4557506373969, lng: 85.7682328633084 },
      { lat: 47.452969468773084, lng: 85.7204838398707 },
      { lat: 47.00095571551612, lng: 85.16429039911324 },
      { lat: 47.330031236350735, lng: 83.18048383986054 },
      { lat: 45.53964956316649, lng: 82.45892581576905 },
      { lat: 45.317027492853164, lng: 81.94707075391811 },
      { lat: 44.917516994804636, lng: 79.96610639844144 },
      { lat: 43.180362046881015, lng: 80.86620649610123 },
      { lat: 42.92006785742687, lng: 80.18015018099439 },
      { lat: 42.34999929459909, lng: 80.25999026888533 },
      { lat: 42.12394074153824, lng: 80.11943037305142 },
      { lat: 41.58224254003871, lng: 78.54366092317528 },
      { lat: 41.18531586360482, lng: 78.18719689322606 },
      { lat: 41.06648590754966, lng: 76.90448449087712 },
      { lat: 40.42794607193514, lng: 76.52636803579745 },
      { lat: 40.56207225194868, lng: 75.46782799673073 },
      { lat: 40.366425279291626, lng: 74.77686242055606 },
      { lat: 39.893973497063136, lng: 73.82224368682833 },
      { lat: 39.66000844986171, lng: 73.96001305531846 },
      { lat: 39.43123688410557, lng: 73.67537926625485 },
      { lat: 38.50581533462271, lng: 73.9288521666464 },
      { lat: 38.60650686294348, lng: 74.25751427602272 },
      { lat: 38.37884634048161, lng: 74.8648157083168 },
      { lat: 37.99000702570146, lng: 74.82998579295216 },
      { lat: 37.4199901393059, lng: 74.98000247589542 },
      { lat: 37.13303091078916, lng: 75.158027785141 },
      { lat: 36.66680613865188, lng: 75.89689741405019 },
      { lat: 35.89840342868786, lng: 76.19284834178572 },
      { lat: 35.4940095077878, lng: 77.83745079947462 },
      { lat: 34.32193634697577, lng: 78.91226891471322 },
      { lat: 33.5061980250324, lng: 78.81108646028574 },
      { lat: 32.99439463961372, lng: 79.20889163606856 },
      { lat: 32.48377981213776, lng: 79.17612877799556 },
      { lat: 32.618164374312734, lng: 78.45844648632604 },
      { lat: 31.515906073527063, lng: 78.73889448437401 },
      { lat: 30.88271474865474, lng: 79.72136681510712 },
      { lat: 30.18348094331341, lng: 81.11125613802928 },
      { lat: 30.422716986608666, lng: 81.52580447787481 },
      { lat: 30.115268052688204, lng: 82.32751264845089 },
      { lat: 29.463731594352208, lng: 83.33711510613719 },
      { lat: 29.320226141877633, lng: 83.89899295444675 },
      { lat: 28.83989370372469, lng: 84.23457970575018 },
      { lat: 28.642773952747365, lng: 85.01163821812307 },
      { lat: 28.203575954698742, lng: 85.82331994013154 },
      { lat: 27.974261786403538, lng: 86.95451704300065 },
      { lat: 27.876541652939565, lng: 88.12044070836994 },
      { lat: 28.086864732367548, lng: 88.73032596227856 },
      { lat: 27.2993159042394, lng: 88.8142484883206 },
      { lat: 28.04275889740638, lng: 89.47581017452116 },
      { lat: 28.29643850352718, lng: 90.01582889197121 },
      { lat: 28.06495392507573, lng: 90.73051395056783 },
      { lat: 28.04061432546636, lng: 91.2588537943199 },
      { lat: 27.771741848251636, lng: 91.6966565286967 },
      { lat: 27.896876329046457, lng: 92.50311893104364 },
      { lat: 28.640629380807244, lng: 93.41334760943268 },
      { lat: 29.27743805593996, lng: 94.56599043170296 },
      { lat: 29.03171662039217, lng: 95.40480228066465 },
      { lat: 29.452802028922523, lng: 96.11767866413103 },
      { lat: 28.830979519154376, lng: 96.58659061074755 },
      { lat: 28.411030992134457, lng: 96.24883344928784 },
      { lat: 28.261582749946342, lng: 97.32711388549004 },
      { lat: 28.335945136014374, lng: 97.91198774616944 },
      { lat: 27.747221381129183, lng: 98.24623091023338 },
      { lat: 27.508812160750658, lng: 98.68269005737054 },
      { lat: 26.743535874940235, lng: 98.71209394734458 },
      { lat: 25.918702500913493, lng: 98.67183800658924 },
      { lat: 25.083637193293043, lng: 97.72460900267916 },
      { lat: 23.89740469003306, lng: 97.60471967976203 },
      { lat: 24.063286037690002, lng: 98.6602624857558 },
      { lat: 23.142722072842588, lng: 98.89874922078283 },
      { lat: 22.9490388046126, lng: 99.53199222208744 },
      { lat: 22.118314317304563, lng: 99.24089887898722 },
      { lat: 21.742936713136455, lng: 99.98348921102158 },
      { lat: 21.558839423096654, lng: 100.41653771362738 },
      { lat: 21.84998444262902, lng: 101.15003299357826 },
      { lat: 21.436572984294067, lng: 101.18000532430759 },
      { lat: 21.201651923095177, lng: 101.27002566936002 },
      { lat: 21.174366766845058, lng: 101.80311974488292 },
      { lat: 22.31819875740957, lng: 101.65201785686158 },
      { lat: 22.46475311938934, lng: 102.17043582561355 },
      { lat: 22.7087950708877, lng: 102.70699222210018 },
      { lat: 22.703756618739224, lng: 103.50451460166053 },
      { lat: 22.81915009204692, lng: 104.4768583516645 },
      { lat: 23.352063300056976, lng: 105.32920942588666 },
      { lat: 22.9768924016179, lng: 105.81124718630522 },
      { lat: 22.794267889898382, lng: 106.7254032735485 },
      { lat: 22.218204860924743, lng: 106.56727339073537 },
      { lat: 21.811898912029896, lng: 107.04342003787266 },
      { lat: 21.552379869060104, lng: 108.05018029178301 },
      { lat: 21.715212307211836, lng: 108.52281294152444 },
      { lat: 21.39505097094752, lng: 109.86448815311834 },
      { lat: 21.008227037026725, lng: 109.62765506392466 },
      { lat: 20.282457383703445, lng: 109.88986128137358 },
      { lat: 20.34103261970639, lng: 110.44403934127169 },
      { lat: 21.397143866455338, lng: 110.78546552942416 },
      { lat: 21.55049367928152, lng: 111.84359215703248 },
      { lat: 21.616579336740603, lng: 113.60137939453125 },
      { lat: 22.216648833146053, lng: 113.50662231445312 },
      { lat: 22.23190407053973, lng: 113.60275268554688 },
      { lat: 21.74164244042824, lng: 113.69064331054688 },
      { lat: 22.32371819460966, lng: 114.13833618164062 },
      { lat: 22.328481987166487, lng: 114.19464111328125 },
      { lat: 22.23190407053973, lng: 114.29145812988281 },
      { lat: 22.668074042241667, lng: 114.76382734584624 },
      { lat: 22.782873236578098, lng: 115.89073530483515 },
      { lat: 23.624501451099718, lng: 117.28160647997086 },
      { lat: 24.54739085540024, lng: 118.65687137255455 },
      { lat: 25.740780544532623, lng: 119.58549686083958 },
      { lat: 27.0532068954494, lng: 120.39547326058234 },
      { lat: 28.135673122667196, lng: 121.12566124886646 },
      { lat: 28.225512600206688, lng: 121.68443851123847 },
      { lat: 29.01802236583482, lng: 121.93842817595308 },
      { lat: 29.83252045340315, lng: 122.09211388558911 },
      { lat: 30.142914943964257, lng: 121.50351932178475 },
      { lat: 30.676267401648712, lng: 121.26425744027333 },
      { lat: 30.9493515080951, lng: 121.89191938689036 },
      { lat: 31.692174384074683, lng: 121.90814578663007 },
      { lat: 32.46031871187719, lng: 121.22901411345025 },
      { lat: 33.376722723925134, lng: 120.62036909391661 },
      { lat: 34.36033193616862, lng: 120.22752485563375 },
      { lat: 34.90985911716046, lng: 119.1512081238586 },
      { lat: 35.609790554337735, lng: 119.6645618022461 },
      { lat: 36.11143952081113, lng: 120.6370089051146 },
      { lat: 36.65132904718045, lng: 121.10416385303303 },
      { lat: 36.930614325501836, lng: 122.51999474496584 },
      { lat: 37.45448415786068, lng: 122.35793745329849 },
      { lat: 37.48112335870718, lng: 121.71125857959798 },
      { lat: 37.87042776137799, lng: 120.82345747282368 },
      { lat: 37.15638865818508, lng: 119.70280236214208 },
      { lat: 37.44846385349874, lng: 118.91163618375353 },
      { lat: 37.8973253443859, lng: 118.8781498556284 },
      { lat: 38.061475531561044, lng: 118.0596985209897 },
      { lat: 38.7376358098841, lng: 117.53270226447708 },
      { lat: 39.204273993479696, lng: 118.04274865119793 },
      { lat: 39.25233307551109, lng: 119.02346398323304 },
      { lat: 39.898055935214195, lng: 119.63960208544908 },
      { lat: 40.5933881699176, lng: 120.76862877816198 },
      { lat: 40.94638987890333, lng: 121.64035851449356 },
      { lat: 40.42244253189603, lng: 122.16859500538104 },
      { lat: 39.75026133885951, lng: 121.37675703337268 },
      { lat: 39.36085358332414, lng: 121.5859949077225 },
      { lat: 38.8974710149629, lng: 121.05455447803288 },
      { lat: 39.17045176854464, lng: 122.13138797413094 },
      { lat: 39.637787583976255, lng: 122.86757042856104 },
      { lat: 39.92849335383414, lng: 124.26562462778534 },
      { lat: 40.569823716792456, lng: 125.07994184784062 },
      { lat: 41.10733612727637, lng: 126.18204511932946 },
      { lat: 41.816569322266155, lng: 126.8690832866499 },
      { lat: 41.503151760415946, lng: 127.34378299368305 },
      { lat: 41.466771552082534, lng: 128.20843305879075 },
      { lat: 41.994284572918, lng: 128.05221520397234 },
      { lat: 42.42498179785459, lng: 129.5966687358795 },
      { lat: 42.98538686784381, lng: 129.99426720593326 },
      { lat: 42.395009467125284, lng: 130.64001590385246 },
      { lat: 42.903014634770564, lng: 130.6338664084098 },
      { lat: 42.92998973242695, lng: 131.14468794161488 },
      { lat: 44.11151968034826, lng: 131.28855512911557 },
      { lat: 44.96795319272159, lng: 131.0252120301561 },
      { lat: 45.321161607436444, lng: 131.8834542176596 },
      { lat: 45.144066473972174, lng: 133.09712690646646 },
      { lat: 46.11692698829906, lng: 133.7696439963129 },
      { lat: 47.21246735288674, lng: 134.11236209527263 },
      { lat: 47.57843984637784, lng: 134.50081383681064 },
      { lat: 48.47822988544391, lng: 135.02631147678673 },
      { lat: 48.18344167743494, lng: 133.37359581922803 },
      { lat: 47.78896963153488, lng: 132.50667199109952 },
      { lat: 47.79013235126142, lng: 130.98728152885386 },
      { lat: 48.72968740497612, lng: 130.5822933289824 },
      { lat: 49.44060008401546, lng: 129.39781782442046 },
      { lat: 49.76027049417294, lng: 127.65740726126242 },
    ],
  ],
  CO: [
    [
      { lat: -0.15203175212045, lng: -75.37322323271385 },
      { lat: 0.084801337073202, lng: -75.80146582711659 },
      { lat: 0.416047268064119, lng: -76.29231441924097 },
      { lat: 0.256935533037435, lng: -76.57637976754938 },
      { lat: 0.395686753741117, lng: -77.4249843004304 },
      { lat: 0.825893052570961, lng: -77.66861284047044 },
      { lat: 0.809925034992773, lng: -77.85506140817952 },
      { lat: 1.380923773601822, lng: -78.85525875518871 },
      { lat: 1.691369940595251, lng: -78.99093522817103 },
      { lat: 1.766404120283056, lng: -78.61783138702371 },
      { lat: 2.267355454920477, lng: -78.66211808949785 },
      { lat: 2.629555568854215, lng: -78.42761043975732 },
      { lat: 2.696605739752926, lng: -77.93154252797149 },
      { lat: 3.325016994638247, lng: -77.51043128122501 },
      { lat: 3.849636135265357, lng: -77.12768978545526 },
      { lat: 4.087606105969428, lng: -77.49627193877703 },
      { lat: 4.667984117039452, lng: -77.3076012844794 },
      { lat: 5.582811997902496, lng: -77.53322058786573 },
      { lat: 5.84535411216136, lng: -77.31881507028675 },
      { lat: 6.691116441266303, lng: -77.47666073272228 },
      { lat: 7.223771267114785, lng: -77.88157141794525 },
      { lat: 7.709839789252142, lng: -77.75341386586139 },
      { lat: 7.638061224798735, lng: -77.43110795765699 },
      { lat: 7.935278225125444, lng: -77.24256649444008 },
      { lat: 8.524286200388218, lng: -77.47472286651133 },
      { lat: 8.67050466555807, lng: -77.35336076527385 },
      { lat: 8.638749497914716, lng: -76.83667395700357 },
      { lat: 9.336820583529487, lng: -76.08638383655786 },
      { lat: 9.443248195834599, lng: -75.67460018584005 },
      { lat: 9.774003200718738, lng: -75.66470414905618 },
      { lat: 10.618990383339309, lng: -75.48042599150335 },
      { lat: 11.083044745320322, lng: -74.90689510771197 },
      { lat: 11.102035834187587, lng: -74.27675269234489 },
      { lat: 11.310472723836865, lng: -74.1972226630477 },
      { lat: 11.22701528568548, lng: -73.41476396350029 },
      { lat: 11.731971543825523, lng: -72.62783525255963 },
      { lat: 11.955549628136326, lng: -72.23819495307892 },
      { lat: 12.437303168177309, lng: -71.75409013536864 },
      { lat: 12.376040757695293, lng: -71.3998223537917 },
      { lat: 12.112981879113505, lng: -71.13746110704588 },
      { lat: 11.776284084515808, lng: -71.3315836249503 },
      { lat: 11.60867157637712, lng: -71.97392167833829 },
      { lat: 11.10870209395324, lng: -72.22757544624294 },
      { lat: 10.821975409381778, lng: -72.61465776232521 },
      { lat: 10.450344346554772, lng: -72.9052860175347 },
      { lat: 9.736770331252444, lng: -73.02760413276957 },
      { lat: 9.151999823437606, lng: -73.30495154488005 },
      { lat: 9.085027167187334, lng: -72.7887298245004 },
      { lat: 8.625287787302682, lng: -72.6604947577681 },
      { lat: 8.405275376820029, lng: -72.43986223009796 },
      { lat: 8.002638454617895, lng: -72.36090064155596 },
      { lat: 7.632506008327354, lng: -72.47967892117885 },
      { lat: 7.423784898300481, lng: -72.44448727078807 },
      { lat: 7.340430813013682, lng: -72.19835242378188 },
      { lat: 6.991614895043538, lng: -71.96017574734864 },
      { lat: 7.087784735538719, lng: -70.67423356798152 },
      { lat: 6.96037649172311, lng: -70.09331295437242 },
      { lat: 6.099860541198836, lng: -69.38947994655712 },
      { lat: 6.206804917826858, lng: -68.98531856960236 },
      { lat: 6.153268133972475, lng: -68.26505245631823 },
      { lat: 6.267318020040647, lng: -67.69508724635502 },
      { lat: 6.095468044454023, lng: -67.34143958196557 },
      { lat: 5.556870428891969, lng: -67.52153194850275 },
      { lat: 5.221128648291668, lng: -67.74469662135522 },
      { lat: 4.503937282728899, lng: -67.82301225449355 },
      { lat: 3.839481716319994, lng: -67.62183590358127 },
      { lat: 3.542342230641722, lng: -67.33756384954368 },
      { lat: 3.31845408773718, lng: -67.30317318385345 },
      { lat: 2.820655015469569, lng: -67.8099381171237 },
      { lat: 2.600280869960869, lng: -67.44709204778631 },
      { lat: 2.250638129074062, lng: -67.18129431829307 },
      { lat: 1.253360500489336, lng: -66.87632585312258 },
      { lat: 1.130112209473225, lng: -67.0650481838525 },
      { lat: 1.719998684084956, lng: -67.25999752467358 },
      { lat: 2.03716278727633, lng: -67.53781002467468 },
      { lat: 1.692455145673392, lng: -67.86856502955884 },
      { lat: 1.714805202639624, lng: -69.81697323269162 },
      { lat: 1.089081122233466, lng: -69.80459672715773 },
      { lat: 0.985676581217433, lng: -69.21863766140018 },
      { lat: 0.602650865070075, lng: -69.25243404811906 },
      { lat: 0.706158758950693, lng: -69.45239600287246 },
      { lat: 0.541414292804205, lng: -70.0155657619893 },
      { lat: -0.185156345219539, lng: -70.02065589057005 },
      { lat: -0.549991957200163, lng: -69.5770653957766 },
      { lat: -1.122618503426409, lng: -69.42048580593223 },
      { lat: -1.556287123219818, lng: -69.44410193548961 },
      { lat: -4.298186944194327, lng: -69.89363521999663 },
      { lat: -3.766591485207825, lng: -70.39404395209499 },
      { lat: -3.742872002785859, lng: -70.69268205430971 },
      { lat: -2.725156345229699, lng: -70.04770850287485 },
      { lat: -2.256864515800743, lng: -70.81347571479196 },
      { lat: -2.342802422702128, lng: -71.41364579942979 },
      { lat: -2.169789727388938, lng: -71.7747607082854 },
      { lat: -2.434218031426454, lng: -72.32578650581365 },
      { lat: -2.308954359550953, lng: -73.07039221870724 },
      { lat: -1.260491224781134, lng: -73.6595035468346 },
      { lat: -1.002832533373848, lng: -74.12239518908906 },
      { lat: -0.530820000819887, lng: -74.44160051135597 },
      { lat: -0.05720549886486, lng: -75.10662451852008 },
      { lat: -0.15203175212045, lng: -75.37322323271385 },
    ],
  ],
  CR: [
    [
      { lat: 10.916275942108, lng: -85.912347336518 },
      { lat: 11.151840603127, lng: -85.700933620582 },
      { lat: 11.200039185412, lng: -85.62384219442 },
      { lat: 11.205295196589, lng: -85.586106094099 },
      { lat: 11.105587915811, lng: -85.239766451036 },
      { lat: 11.069174156847, lng: -85.053642430003 },
      { lat: 11.065573122787, lng: -84.864024008955 },
      { lat: 11.077960456009, lng: -84.682454412366 },
      { lat: 10.951976525866, lng: -84.156414859142 },
      { lat: 10.922117647554, lng: -83.886530198374 },
      { lat: 10.925951172187, lng: -83.6256304544 },
      { lat: 10.89502966699, lng: -83.596574492585 },
      { lat: 10.528474637976, lng: -83.42595015523 },
      { lat: 10.348186202172, lng: -83.319891702553 },
      { lat: 10.191476036892, lng: -83.18134842568 },
      { lat: 9.6131748459838, lng: -82.561521109196 },
      { lat: 9.5870031048284, lng: -82.547886631788 },
      { lat: 9.3272325400388, lng: -82.636358236594 },
      { lat: 9.134210789407, lng: -82.682988570668 },
      { lat: 8.1801320937211, lng: -82.823110256085 },
      { lat: 8.1125271072684, lng: -82.845057049958 },
      { lat: 8.0757270526077, lng: -82.882098935905 },
      { lat: 8.0710770018254, lng: -82.903662126931 },
      { lat: 8.1971647354066, lng: -83.064638139346 },
      { lat: 8.2903006465778, lng: -83.204562218288 },
      { lat: 8.3608160307704, lng: -83.357142120011 },
      { lat: 8.4380717747082, lng: -83.565075537726 },
      { lat: 8.6184945295876, lng: -83.750189548727 },
      { lat: 8.8310544724329, lng: -83.82662291005 },
      { lat: 9.0176824778849, lng: -83.914472196499 },
      { lat: 9.1844188566419, lng: -84.03590732135 },
      { lat: 9.3252925476254, lng: -84.186579524285 },
      { lat: 9.435258668492, lng: -84.361093024652 },
      { lat: 9.5103791798698, lng: -84.553198251855 },
      { lat: 9.5479639120131, lng: -84.756015651154 },
      { lat: 9.5813639579749, lng: -85.132545680888 },
      { lat: 9.9422063640997, lng: -85.685024388567 },
      { lat: 10.224456877171, lng: -85.863121671842 },
      { lat: 10.261749544167, lng: -85.873778918071 },
      { lat: 10.483879134004, lng: -85.87057592281 },
      { lat: 10.628359883404, lng: -85.878095061384 },
      { lat: 10.916275942108, lng: -85.912347336518 },
    ],
  ],
  CU: [
    [
      { lat: 23.188610744717703, lng: -82.26815121125706 },
      { lat: 23.11727142993878, lng: -81.40445716014683 },
      { lat: 23.105980129483, lng: -80.6187686835812 },
      { lat: 22.76530324959883, lng: -79.67952368846025 },
      { lat: 22.399201565027056, lng: -79.28148596873207 },
      { lat: 22.512166246017088, lng: -78.34743445505649 },
      { lat: 22.277193508385935, lng: -77.99329586456028 },
      { lat: 21.657851467367834, lng: -77.14642249216105 },
      { lat: 21.206819566324373, lng: -76.52382483590856 },
      { lat: 21.220565497314013, lng: -76.19462012399319 },
      { lat: 21.016624457274133, lng: -75.59822241891267 },
      { lat: 20.735091254148, lng: -75.67106035022806 },
      { lat: 20.693905137611385, lng: -74.9338960435845 },
      { lat: 20.28462779385974, lng: -74.17802486845126 },
      { lat: 20.05037852628068, lng: -74.29664811877724 },
      { lat: 19.92343537035569, lng: -74.96159461129294 },
      { lat: 19.873774318923196, lng: -75.63468014189459 },
      { lat: 19.95289093676206, lng: -76.323656175426 },
      { lat: 19.855480861891873, lng: -77.75548092315306 },
      { lat: 20.413353786698792, lng: -77.08510840524674 },
      { lat: 20.67310537361389, lng: -77.49265458851661 },
      { lat: 20.73994883878343, lng: -78.13729224314159 },
      { lat: 21.02861338956585, lng: -78.48282670766119 },
      { lat: 21.598113511638434, lng: -78.71986650258401 },
      { lat: 21.5591753199065, lng: -79.28499996612794 },
      { lat: 21.827324327069036, lng: -80.21747534861865 },
      { lat: 22.03707896574176, lng: -80.51753455272141 },
      { lat: 22.19205658618507, lng: -81.82094336620318 },
      { lat: 22.38710927987075, lng: -82.16999182811864 },
      { lat: 22.636964830001958, lng: -81.79500179719267 },
      { lat: 22.688150336187064, lng: -82.77589799674084 },
      { lat: 22.16851797127613, lng: -83.49445878775936 },
      { lat: 22.154565334557333, lng: -83.90880042187563 },
      { lat: 21.910575059491254, lng: -84.05215084505326 },
      { lat: 21.801227728761642, lng: -84.54703019889638 },
      { lat: 21.89602814380109, lng: -84.97491105827311 },
      { lat: 22.204949856041907, lng: -84.44706214062776 },
      { lat: 22.565754706303764, lng: -84.23035702181178 },
      { lat: 22.788118394455694, lng: -83.7782399156902 },
      { lat: 22.983041897060644, lng: -83.26754757356575 },
      { lat: 23.078746649665188, lng: -82.51043616405751 },
      { lat: 23.188610744717703, lng: -82.26815121125706 },
    ],
  ],
  CV: [
    [
      { lat: 17.104538889147, lng: -25.350531628259 },
      { lat: 17.207633380359, lng: -25.121087783732 },
      { lat: 17.207653781227, lng: -25.045960226924 },
      { lat: 17.166383562008, lng: -24.985206394161 },
      { lat: 17.086509804389, lng: -24.922136106697 },
      { lat: 16.910435245292, lng: -24.747069743523 },
      { lat: 16.77854844655, lng: -24.536697801018 },
      { lat: 16.697693011138, lng: -24.301936474476 },
      { lat: 16.617905568224, lng: -23.940980315949 },
      { lat: 16.593026466358, lng: -23.747793161783 },
      { lat: 16.602928990954, lng: -23.553262479752 },
      { lat: 16.647296919285, lng: -23.363600324381 },
      { lat: 16.724713426061, lng: -23.184863280869 },
      { lat: 16.856190554071, lng: -22.943166641267 },
      { lat: 16.858872591712, lng: -22.915166906073 },
      { lat: 16.180047838035, lng: -22.668968608222 },
      { lat: 16.094400359613, lng: -22.667149556519 },
      { lat: 16.022993596103, lng: -22.695472122596 },
      { lat: 15.902117051734, lng: -22.802926174972 },
      { lat: 15.698763938764, lng: -22.945790736388 },
      { lat: 15.468335511731, lng: -23.03887946545 },
      { lat: 15.119306688037, lng: -23.135357572321 },
      { lat: 15.103554237494, lng: -23.150132609809 },
      { lat: 14.897095010714, lng: -23.493711772208 },
      { lat: 14.910235544842, lng: -23.80702726107 },
      { lat: 14.896001084164, lng: -24.03405091318 },
      { lat: 14.834958182396, lng: -24.253176727798 },
      { lat: 14.801932487438, lng: -24.336778261894 },
      { lat: 14.797636238029, lng: -24.390840030622 },
      { lat: 14.804473735423, lng: -24.41360293896 },
      { lat: 14.870665870997, lng: -24.488495871704 },
      { lat: 14.973979176237, lng: -24.521121690189 },
      { lat: 15.148182278678, lng: -24.414305679379 },
      { lat: 15.33372951313, lng: -24.323496027132 },
      { lat: 15.533077345795, lng: -24.269323979308 },
      { lat: 15.739065602336, lng: -24.253735287218 },
      { lat: 15.944295597706, lng: -24.277289865418 },
      { lat: 16.14139588208, lng: -24.339141680698 },
      { lat: 16.323287008546, lng: -24.437069139905 },
      { lat: 16.483435812641, lng: -24.567554885106 },
      { lat: 16.616090070516, lng: -24.725912130035 },
      { lat: 16.716485107278, lng: -24.906453000055 },
      { lat: 16.861080332908, lng: -25.236789426438 },
      { lat: 16.922761462939, lng: -25.322289274395 },
      { lat: 16.957461058773, lng: -25.343934715604 },
      { lat: 17.102684621815, lng: -25.351248619656 },
      { lat: 17.104538889147, lng: -25.350531628259 },
    ],
  ],
  CW: [
    [
      { lat: 12.42316552500995, lng: -429.19189453125 },
      { lat: 12.250101701828607, lng: -429.1822814941406 },
      { lat: 11.981561702103395, lng: -428.80462646484375 },
      { lat: 12.028575662342247, lng: -428.6796569824219 },
      { lat: 12.196415362051905, lng: -428.8018798828125 },
      { lat: 12.42316552500995, lng: -429.19189453125 },
    ],
  ],
  CY: [
    [
      { lat: 35.058506374648, lng: 33.97361657078346 },
      { lat: 34.97809784600186, lng: 34.00488081232004 },
      { lat: 34.57186941175544, lng: 32.97982710137845 },
      { lat: 34.701654771456475, lng: 32.49029625827753 },
      { lat: 35.10323232679663, lng: 32.25666710788596 },
      { lat: 35.14002594658844, lng: 32.73178022637745 },
      { lat: 35.08783274997364, lng: 32.919572381326134 },
      { lat: 35.17312470147138, lng: 33.19097700372305 },
      { lat: 35.16271190036457, lng: 33.3838334490363 },
      { lat: 35.10142365166641, lng: 33.45592207208347 },
      { lat: 35.000344550103506, lng: 33.475817498515845 },
      { lat: 35.03868846286407, lng: 33.5256852556775 },
      { lat: 35.01786286065045, lng: 33.675391880027064 },
      { lat: 35.09359467217419, lng: 33.86643965021011 },
      { lat: 35.058506374648, lng: 33.97361657078346 },
    ],
    [
      { lat: 35.14002594658844, lng: 32.73178022637745 },
      { lat: 35.14550364841138, lng: 32.80247358575275 },
      { lat: 35.3867033961337, lng: 32.946960890440806 },
      { lat: 35.37321584730551, lng: 33.667227003724946 },
      { lat: 35.67159556735879, lng: 34.57647382990046 },
      { lat: 35.245755927057616, lng: 33.900804477684204 },
      { lat: 35.058506374648, lng: 33.97361657078346 },
      { lat: 35.09359467217419, lng: 33.86643965021011 },
      { lat: 35.01786286065045, lng: 33.675391880027064 },
      { lat: 35.03868846286407, lng: 33.5256852556775 },
      { lat: 35.000344550103506, lng: 33.475817498515845 },
      { lat: 35.10142365166641, lng: 33.45592207208347 },
      { lat: 35.16271190036457, lng: 33.3838334490363 },
      { lat: 35.17312470147138, lng: 33.19097700372305 },
      { lat: 35.08783274997364, lng: 32.919572381326134 },
      { lat: 35.14002594658844, lng: 32.73178022637745 },
    ],
  ],
  CZ: [
    [
      { lat: 48.5969823268506, lng: 16.960288120194576 },
      { lat: 48.78580801044511, lng: 16.49928266771877 },
      { lat: 48.73389903420793, lng: 16.02964725105022 },
      { lat: 49.039074205107575, lng: 15.253415561593982 },
      { lat: 48.964401760445824, lng: 14.901447381254057 },
      { lat: 48.5553052842072, lng: 14.33889773932472 },
      { lat: 48.87717194273715, lng: 13.595945672264437 },
      { lat: 49.30706818297324, lng: 13.031328973043431 },
      { lat: 49.547415269562734, lng: 12.521024204161192 },
      { lat: 49.96912079528057, lng: 12.415190870827445 },
      { lat: 50.266337795607285, lng: 12.240111118222558 },
      { lat: 50.484076443069085, lng: 12.966836785543194 },
      { lat: 50.73323436136435, lng: 13.338131951560285 },
      { lat: 50.92691762959429, lng: 14.056227654688172 },
      { lat: 51.117267767941414, lng: 14.307013380600637 },
      { lat: 51.002339382524276, lng: 14.570718214586066 },
      { lat: 51.10667409932158, lng: 15.01699588385867 },
      { lat: 50.78472992614321, lng: 15.490972120839727 },
      { lat: 50.69773265237984, lng: 16.23862674323857 },
      { lat: 50.42260732685791, lng: 16.176253289462267 },
      { lat: 50.21574656839354, lng: 16.719475945714436 },
      { lat: 50.47397370055603, lng: 16.868769158605655 },
      { lat: 50.36214590107641, lng: 17.55456709155112 },
      { lat: 50.049038397819956, lng: 17.64944502123899 },
      { lat: 49.98862864847075, lng: 18.392913852622172 },
      { lat: 49.49622976337764, lng: 18.853144158613617 },
      { lat: 49.49501536721878, lng: 18.554971144289482 },
      { lat: 49.31500051533004, lng: 18.399993523846177 },
      { lat: 49.271514797556435, lng: 18.170498488037964 },
      { lat: 49.04398346617531, lng: 18.104972771891852 },
      { lat: 48.996492824899086, lng: 17.913511590250465 },
      { lat: 48.90347524677371, lng: 17.88648481616181 },
      { lat: 48.80001902932537, lng: 17.545006951577108 },
      { lat: 48.81696889911711, lng: 17.101984897538898 },
      { lat: 48.5969823268506, lng: 16.960288120194576 },
    ],
  ],
  DE: [
    [
      { lat: 54.983104153048025, lng: 9.921906365609232 },
      { lat: 54.596641954153256, lng: 9.9395797054529 },
      { lat: 54.363607082733154, lng: 10.950112338920519 },
      { lat: 54.00869334575258, lng: 10.939466993868448 },
      { lat: 54.19648550070116, lng: 11.956252475643282 },
      { lat: 54.47037059184799, lng: 12.518440382546714 },
      { lat: 54.0755109727059, lng: 13.647467075259499 },
      { lat: 53.75702912049103, lng: 14.119686313542559 },
      { lat: 53.248171291713106, lng: 14.353315463934168 },
      { lat: 52.98126251892535, lng: 14.074521111719434 },
      { lat: 52.624850165408304, lng: 14.4375997250022 },
      { lat: 52.089947414755216, lng: 14.685026482815713 },
      { lat: 51.745188096719964, lng: 14.607098422919648 },
      { lat: 51.10667409932171, lng: 15.016995883858781 },
      { lat: 51.00233938252438, lng: 14.570718214586122 },
      { lat: 51.11726776794137, lng: 14.307013380600665 },
      { lat: 50.92691762959435, lng: 14.056227654688314 },
      { lat: 50.73323436136428, lng: 13.338131951560397 },
      { lat: 50.48407644306917, lng: 12.96683678554325 },
      { lat: 50.26633779560723, lng: 12.240111118222671 },
      { lat: 49.96912079528062, lng: 12.415190870827473 },
      { lat: 49.54741526956275, lng: 12.521024204161336 },
      { lat: 49.30706818297324, lng: 13.031328973043514 },
      { lat: 48.877171942737164, lng: 13.595945672264577 },
      { lat: 48.41611481382903, lng: 13.243357374737116 },
      { lat: 48.28914581968786, lng: 12.884102817443873 },
      { lat: 47.63758352313595, lng: 13.025851271220517 },
      { lat: 47.467645575544, lng: 12.932626987366064 },
      { lat: 47.672387600284424, lng: 12.620759718484521 },
      { lat: 47.70308340106578, lng: 12.141357456112871 },
      { lat: 47.52376618101306, lng: 11.426414015354851 },
      { lat: 47.5663992376538, lng: 10.544504021861597 },
      { lat: 47.30248769793916, lng: 10.402083774465325 },
      { lat: 47.580196845075704, lng: 9.896068149463188 },
      { lat: 47.5250580918202, lng: 9.594226108446376 },
      { lat: 47.83082754169135, lng: 8.522611932009795 },
      { lat: 47.61357982033627, lng: 8.317301466514095 },
      { lat: 47.62058197691192, lng: 7.466759067422288 },
      { lat: 48.33301911070373, lng: 7.593676385131062 },
      { lat: 49.01778351500343, lng: 8.099278598674855 },
      { lat: 49.20195831969164, lng: 6.658229607783709 },
      { lat: 49.463802802114515, lng: 6.186320428094177 },
      { lat: 49.90222565367873, lng: 6.242751092156993 },
      { lat: 50.128051662794235, lng: 6.043073357781111 },
      { lat: 50.80372101501058, lng: 6.156658155958779 },
      { lat: 51.851615709025054, lng: 5.988658074577813 },
      { lat: 51.852029120483394, lng: 6.589396599970826 },
      { lat: 52.22844025329755, lng: 6.842869500362383 },
      { lat: 53.14404328064489, lng: 7.092053256873896 },
      { lat: 53.48216217713064, lng: 6.905139601274129 },
      { lat: 53.69393219666267, lng: 7.100424838905268 },
      { lat: 53.74829580343379, lng: 7.936239454793962 },
      { lat: 53.52779246684429, lng: 8.121706170289485 },
      { lat: 54.020785630908904, lng: 8.800734490604668 },
      { lat: 54.39564647075405, lng: 8.572117954145368 },
      { lat: 54.96274363872516, lng: 8.526229282270208 },
      { lat: 54.83086538351631, lng: 9.282048780971136 },
      { lat: 54.983104153048025, lng: 9.921906365609232 },
    ],
  ],
  DJ: [
    [
      { lat: 12.699638576707116, lng: 43.08122602720015 },
      { lat: 12.390148423711025, lng: 43.31785241066467 },
      { lat: 11.974928290245884, lng: 43.286381463398925 },
      { lat: 11.735640570518342, lng: 42.715873650896526 },
      { lat: 11.462039699748857, lng: 43.14530480324214 },
      { lat: 10.92687856693442, lng: 42.77685184100096 },
      { lat: 11.105110000000195, lng: 42.55493000000013 },
      { lat: 11.0342, lng: 42.31414000000012 },
      { lat: 11.050910000000101, lng: 41.755570000000205 },
      { lat: 11.355110000000138, lng: 41.73959000000019 },
      { lat: 11.6312, lng: 41.66176000000013 },
      { lat: 12.100000000000136, lng: 42.000000000000114 },
      { lat: 12.542230000000131, lng: 42.35156000000012 },
      { lat: 12.455415757695675, lng: 42.77964236834475 },
      { lat: 12.699638576707116, lng: 43.08122602720015 },
    ],
  ],
  DK: [
    [
      { lat: 55.609990953180784, lng: 12.69000613775563 },
      { lat: 54.80001455343793, lng: 12.089991082414741 },
      { lat: 55.364863796604254, lng: 11.043543328504228 },
      { lat: 55.77995473898875, lng: 10.903913608451631 },
      { lat: 56.111407375708836, lng: 12.370904168353292 },
      { lat: 55.609990953180784, lng: 12.69000613775563 },
    ],
    [
      { lat: 56.458621324277914, lng: 10.912181837618363 },
      { lat: 56.08138336854722, lng: 10.667803989309988 },
      { lat: 56.19000722922473, lng: 10.369992710011985 },
      { lat: 55.469999498102055, lng: 9.649984978889307 },
      { lat: 54.98310415304806, lng: 9.921906365609175 },
      { lat: 54.83086538351616, lng: 9.282048780971136 },
      { lat: 54.96274363872499, lng: 8.526229282270236 },
      { lat: 55.517722683323626, lng: 8.120310906617588 },
      { lat: 56.540011705137594, lng: 8.08997684086225 },
      { lat: 56.8099693874303, lng: 8.256581658571264 },
      { lat: 57.110002753316905, lng: 8.543437534223386 },
      { lat: 57.17206614849948, lng: 9.42446902836761 },
      { lat: 57.447940782289656, lng: 9.775558709358563 },
      { lat: 57.73001658795485, lng: 10.580005730846153 },
      { lat: 57.215732733786155, lng: 10.546105991262692 },
      { lat: 56.89001618105047, lng: 10.250000034230226 },
      { lat: 56.609981594460834, lng: 10.369992710011985 },
      { lat: 56.458621324277914, lng: 10.912181837618363 },
    ],
  ],
  DM: [
    [
      { lat: 15.632697235984, lng: -61.466328229554 },
      { lat: 15.609245030058, lng: -61.346203922888 },
      { lat: 15.594547993737, lng: -61.321460194858 },
      { lat: 15.529596665144, lng: -61.275169179502 },
      { lat: 15.344077039817, lng: -61.241628047249 },
      { lat: 15.233464294131, lng: -61.304536158719 },
      { lat: 15.224871454899, lng: -61.342476356596 },
      { lat: 15.268144788814, lng: -61.398844461679 },
      { lat: 15.373743205499, lng: -61.444861585767 },
      { lat: 15.517810318342, lng: -61.485236746515 },
      { lat: 15.630631049815, lng: -61.467614686019 },
      { lat: 15.632697235984, lng: -61.466328229554 },
    ],
  ],
  DO: [
    [
      { lat: 18.710454399093, lng: -71.956293701412 },
      { lat: 18.938299528058, lng: -71.851901527588 },
      { lat: 19.18396546204, lng: -71.802309415016 },
      { lat: 19.742038466361, lng: -71.755336220163 },
      { lat: 19.874828045595, lng: -71.656515693155 },
      { lat: 19.914470153801, lng: -71.538241155077 },
      { lat: 19.916836922931, lng: -70.851308007043 },
      { lat: 19.663179808408, lng: -69.89306645948 },
      { lat: 18.907303823646, lng: -68.648243869392 },
      { lat: 18.608702551941, lng: -68.326793048211 },
      { lat: 18.505222448883, lng: -68.355070854565 },
      { lat: 18.488640507363, lng: -68.365650704248 },
      { lat: 18.233282173061, lng: -68.660122915583 },
      { lat: 18.225218976137, lng: -68.705444355152 },
      { lat: 18.325708264794, lng: -68.982403172619 },
      { lat: 18.373676340101, lng: -69.16023392099 },
      { lat: 18.39101744457, lng: -69.343602395409 },
      { lat: 18.393277793669, lng: -69.576640643345 },
      { lat: 18.367486726797, lng: -69.823154237705 },
      { lat: 18.286670323975, lng: -70.057467789104 },
      { lat: 18.246581801923, lng: -70.141296094867 },
      { lat: 18.182237834667, lng: -70.569841027641 },
      { lat: 18.127707811814, lng: -70.78320048399 },
      { lat: 18.031405225739, lng: -70.981244905574 },
      { lat: 17.897260948527, lng: -71.15589052933 },
      { lat: 17.660462565367, lng: -71.406951741602 },
      { lat: 17.645285433364, lng: -71.493338831646 },
      { lat: 17.707072397517, lng: -71.5839407775 },
      { lat: 17.837928463172, lng: -71.696782138869 },
      { lat: 18.064028894918, lng: -71.811116671553 },
      { lat: 18.475241750483, lng: -71.981834013499 },
      { lat: 18.597926435826, lng: -72.006920123424 },
      { lat: 18.642606390937, lng: -71.99741217253 },
      { lat: 18.710454399093, lng: -71.956293701412 },
    ],
  ],
  DZ: [
    [
      { lat: 23.47166840259645, lng: 11.999505649471613 },
      { lat: 21.565660712159143, lng: 8.572893100629784 },
      { lat: 19.601206976799716, lng: 5.677565952180686 },
      { lat: 19.155265204337, lng: 4.267419467800039 },
      { lat: 19.057364203360038, lng: 3.158133172222705 },
      { lat: 19.69357859952144, lng: 3.1466610042539 },
      { lat: 19.856230170160114, lng: 2.683588494486429 },
      { lat: 20.142233384679486, lng: 2.06099083823392 },
      { lat: 20.610809434486043, lng: 1.823227573259032 },
      { lat: 22.792665920497384, lng: -1.550054897457613 },
      { lat: 24.974574082941, lng: -4.92333736817423 },
      { lat: 27.395744126896005, lng: -8.68439978680905 },
      { lat: 27.589479071558227, lng: -8.665124477564191 },
      { lat: 27.656425889592356, lng: -8.665589565454809 },
      { lat: 28.84128896739658, lng: -8.674116176782974 },
      { lat: 29.57922842052453, lng: -7.059227667661928 },
      { lat: 29.731699734001694, lng: -6.060632290053774 },
      { lat: 30.000443020135588, lng: -5.242129278982787 },
      { lat: 30.501187649043842, lng: -4.859646165374471 },
      { lat: 30.896951605751152, lng: -3.690441046554696 },
      { lat: 31.63729401298067, lng: -3.647497931320146 },
      { lat: 31.724497992473214, lng: -3.068980271812648 },
      { lat: 32.09434621838615, lng: -2.616604783529567 },
      { lat: 32.2628889023061, lng: -1.30789913573787 },
      { lat: 32.65152151135713, lng: -1.124551153966308 },
      { lat: 32.86401500094131, lng: -1.388049282222568 },
      { lat: 33.919712836231994, lng: -1.733454555661467 },
      { lat: 34.527918606091205, lng: -1.792985805661687 },
      { lat: 35.16839630791668, lng: -2.169913702798624 },
      { lat: 35.7148487411871, lng: -1.208602871089056 },
      { lat: 35.888662421200806, lng: -0.127454392894606 },
      { lat: 36.30127289483528, lng: 0.503876580415209 },
      { lat: 36.605647081034405, lng: 1.466918572606545 },
      { lat: 36.78390493422522, lng: 3.161698846050825 },
      { lat: 36.86503693292346, lng: 4.81575809084913 },
      { lat: 36.71651886651662, lng: 5.320120070017793 },
      { lat: 37.11065501560674, lng: 6.261819695672613 },
      { lat: 37.118380642234364, lng: 7.33038496260397 },
      { lat: 36.885707505840216, lng: 7.737078484741004 },
      { lat: 36.94642731378316, lng: 8.420964389691676 },
      { lat: 36.433176988260286, lng: 8.217824334352315 },
      { lat: 35.47987600355594, lng: 8.376367628623768 },
      { lat: 34.65514598239379, lng: 8.140981479534304 },
      { lat: 34.09737641045146, lng: 7.524481642292244 },
      { lat: 33.34411489514896, lng: 7.612641635782182 },
      { lat: 32.74833730725595, lng: 8.430472853233368 },
      { lat: 32.50628489840082, lng: 8.439102817426118 },
      { lat: 32.10269196220129, lng: 9.055602654668148 },
      { lat: 30.307556057246188, lng: 9.482139926805274 },
      { lat: 29.42463837332339, lng: 9.805634392952411 },
      { lat: 28.959989732371014, lng: 9.859997999723447 },
      { lat: 28.1441738957792, lng: 9.683884718472767 },
      { lat: 27.68825857188415, lng: 9.756128370816782 },
      { lat: 27.14095347748092, lng: 9.629056023811074 },
      { lat: 26.512206325785698, lng: 9.716285841519749 },
      { lat: 26.094324856057455, lng: 9.319410841518163 },
      { lat: 25.36545461679674, lng: 9.910692579801776 },
      { lat: 24.936953640232517, lng: 9.94826134607797 },
      { lat: 24.379313259370917, lng: 10.303846876678362 },
      { lat: 24.56253205006175, lng: 10.771363559622927 },
      { lat: 24.097909247325518, lng: 11.560669386449005 },
      { lat: 23.47166840259645, lng: 11.999505649471613 },
    ],
  ],
  EC: [
    [
      { lat: -3.404856459164713, lng: -80.30256059438722 },
      { lat: -2.65751189535964, lng: -79.77029334178093 },
      { lat: -2.220794366061014, lng: -79.98655921092241 },
      { lat: -2.685158786635788, lng: -80.36878394236925 },
      { lat: -2.246942640800704, lng: -80.96776546906436 },
      { lat: -1.965047702648533, lng: -80.76480628123804 },
      { lat: -1.057454522306358, lng: -80.9336590237517 },
      { lat: -0.906662692878683, lng: -80.58337032746127 },
      { lat: -0.283703301600141, lng: -80.39932471385376 },
      { lat: 0.360340074053468, lng: -80.02089820018037 },
      { lat: 0.768428859862397, lng: -80.09060970734211 },
      { lat: 0.982937730305963, lng: -79.54276201039978 },
      { lat: 1.380923773601822, lng: -78.85525875518871 },
      { lat: 0.809925034992773, lng: -77.85506140817952 },
      { lat: 0.825893052570961, lng: -77.66861284047044 },
      { lat: 0.395686753741117, lng: -77.4249843004304 },
      { lat: 0.256935533037435, lng: -76.57637976754938 },
      { lat: 0.416047268064119, lng: -76.29231441924097 },
      { lat: 0.084801337073202, lng: -75.80146582711659 },
      { lat: -0.15203175212045, lng: -75.37322323271385 },
      { lat: -0.911416924649529, lng: -75.23372270374193 },
      { lat: -1.56160979574588, lng: -75.54499569365204 },
      { lat: -2.608677666843818, lng: -76.63539425322672 },
      { lat: -3.003020521663103, lng: -77.83790483265861 },
      { lat: -3.873096612161376, lng: -78.45068396677564 },
      { lat: -4.547784112164074, lng: -78.63989722361234 },
      { lat: -4.959128513207389, lng: -79.20528906931771 },
      { lat: -4.454198093283494, lng: -79.62497921417618 },
      { lat: -4.346090996928893, lng: -80.02890804718561 },
      { lat: -4.425724379090674, lng: -80.44224199087216 },
      { lat: -4.059286797708999, lng: -80.46929460317695 },
      { lat: -3.821161797708044, lng: -80.18401485870967 },
      { lat: -3.404856459164713, lng: -80.30256059438722 },
    ],
    [
      { lat: 0.39550467153201946, lng: -89.9285888671875 },
      { lat: 0.9008417889908868, lng: -90.791015625 },
      { lat: -0.21972602392080884, lng: -92.10937499999999 },
      { lat: -1.0656123898763876, lng: -91.549072265625 },
      { lat: -1.4280747713669302, lng: -90.439453125 },
      { lat: -1.439057660807751, lng: -89.53857421875 },
      { lat: -0.7250783020332547, lng: -89.18701171875 },
      { lat: 0.39550467153201946, lng: -89.9285888671875 },
    ],
  ],
  EE: [
    [
      { lat: 57.79342357037698, lng: 24.312862583114622 },
      { lat: 58.38341339785328, lng: 24.42892785004216 },
      { lat: 58.25737457949341, lng: 24.061198357853186 },
      { lat: 58.612753404364625, lng: 23.42656009287668 },
      { lat: 59.18724030215338, lng: 23.339795363058645 },
      { lat: 59.46585378685502, lng: 24.604214308376182 },
      { lat: 59.61109039981134, lng: 25.86418908051664 },
      { lat: 59.445803331125774, lng: 26.949135776484525 },
      { lat: 59.47538808861287, lng: 27.981114129353244 },
      { lat: 59.30082510033092, lng: 28.13169925305175 },
      { lat: 58.72458120384424, lng: 27.42016645682494 },
      { lat: 57.79189911562437, lng: 27.71668582531572 },
      { lat: 57.47452830670383, lng: 27.28818484875151 },
      { lat: 57.47638865826633, lng: 26.463532342237787 },
      { lat: 57.84752879498657, lng: 25.602809685984365 },
      { lat: 57.97015696881519, lng: 25.16459354014927 },
      { lat: 57.79342357037698, lng: 24.312862583114622 },
    ],
  ],
  EG: [
    [
      { lat: 29.50133, lng: 34.9226 },
      { lat: 29.09942, lng: 34.64174 },
      { lat: 28.34399, lng: 34.42655 },
      { lat: 27.8233, lng: 34.15451 },
      { lat: 27.6487, lng: 33.92136 },
      { lat: 27.97136, lng: 33.58811 },
      { lat: 28.41765, lng: 33.13676 },
      { lat: 29.85108, lng: 32.42323 },
      { lat: 29.76043, lng: 32.32046 },
      { lat: 28.70523, lng: 32.73482 },
      { lat: 27.69989, lng: 33.34876 },
      { lat: 26.14227, lng: 34.10455 },
      { lat: 25.59856, lng: 34.47387 },
      { lat: 25.03375, lng: 34.79507 },
      { lat: 23.92671, lng: 35.69241 },
      { lat: 23.75237, lng: 35.49372 },
      { lat: 23.10244, lng: 35.52598 },
      { lat: 22.20485, lng: 36.69069 },
      { lat: 22, lng: 36.86623 },
      { lat: 22, lng: 32.9 },
      { lat: 22, lng: 29.02 },
      { lat: 22, lng: 25 },
      { lat: 25.682499996361, lng: 25 },
      { lat: 29.23865452953346, lng: 25 },
      { lat: 30.04419, lng: 24.70007 },
      { lat: 30.6616, lng: 24.95762 },
      { lat: 31.08929, lng: 24.80287 },
      { lat: 31.56915, lng: 25.16482 },
      { lat: 31.58568, lng: 26.49533 },
      { lat: 31.32126, lng: 27.45762 },
      { lat: 31.02577, lng: 28.45048 },
      { lat: 30.87005, lng: 28.91353 },
      { lat: 31.18686, lng: 29.68342 },
      { lat: 31.4734, lng: 30.09503 },
      { lat: 31.55586, lng: 30.97693 },
      { lat: 31.4296, lng: 31.68796 },
      { lat: 30.9336, lng: 31.96041 },
      { lat: 31.26034, lng: 32.19247 },
      { lat: 31.02407, lng: 32.99392 },
      { lat: 30.96746, lng: 33.7734 },
      { lat: 31.21936, lng: 34.26544 },
      { lat: 29.50133, lng: 34.9226 },
    ],
  ],
  ER: [
    [
      { lat: 12.542230000000131, lng: 42.35156000000012 },
      { lat: 12.86582, lng: 42.00975 },
      { lat: 13.452090000000112, lng: 41.59856 },
      { lat: 13.773319810435224, lng: 41.15519371924984 },
      { lat: 14.118640000000141, lng: 40.8966 },
      { lat: 14.519579169162284, lng: 40.026218702969175 },
      { lat: 14.53155, lng: 39.34061 },
      { lat: 14.74064, lng: 39.0994 },
      { lat: 14.50547, lng: 38.51295 },
      { lat: 14.959430000000168, lng: 37.90607000000011 },
      { lat: 14.2131, lng: 37.59377 },
      { lat: 14.42211, lng: 36.42951 },
      { lat: 14.82248057704106, lng: 36.32318891779812 },
      { lat: 16.29187409104429, lng: 36.75386030451858 },
      { lat: 16.95655, lng: 36.852530000000115 },
      { lat: 17.263140000000135, lng: 37.16747 },
      { lat: 17.42754, lng: 37.90400000000011 },
      { lat: 17.99830739997031, lng: 38.410089959473225 },
      { lat: 16.84062612555169, lng: 38.990622999840014 },
      { lat: 15.922723496967249, lng: 39.26611006038803 },
      { lat: 15.435647284400318, lng: 39.814293654140215 },
      { lat: 14.491079616753211, lng: 41.17927493669765 },
      { lat: 13.921036892141558, lng: 41.73495161313235 },
      { lat: 13.343992010954423, lng: 42.27683068214486 },
      { lat: 13.000421250861905, lng: 42.58957645037526 },
      { lat: 12.699638576707116, lng: 43.08122602720015 },
      { lat: 12.455415757695675, lng: 42.77964236834475 },
      { lat: 12.542230000000131, lng: 42.35156000000012 },
    ],
  ],
  ES: [
    [
      { lat: 41.88057058365967, lng: -9.034817674180204 },
      { lat: 42.59277517350627, lng: -8.984433152695601 },
      { lat: 43.0266246608127, lng: -9.392883673530605 },
      { lat: 43.748337714200986, lng: -7.97818966310831 },
      { lat: 43.567909450853925, lng: -6.75449174643677 },
      { lat: 43.574239813809676, lng: -5.411886359061555 },
      { lat: 43.40344920508504, lng: -4.347842779955727 },
      { lat: 43.4559007838613, lng: -3.5175317041060907 },
      { lat: 43.42280202897834, lng: -1.9013512841777356 },
      { lat: 43.03401439063043, lng: -1.502770961910528 },
      { lat: 42.57954600683954, lng: 0.33804690919055247 },
      { lat: 42.7957343613326, lng: 0.7015906103638372 },
      { lat: 42.34338471126569, lng: 1.8267932470871528 },
      { lat: 42.47301504166986, lng: 2.985998976258429 },
      { lat: 41.892120266276905, lng: 3.039484083680577 },
      { lat: 41.22608856868309, lng: 2.091841668312213 },
      { lat: 41.01473196060934, lng: 0.8105245296351313 },
      { lat: 40.678318386389236, lng: 0.7213310074994297 },
      { lat: 40.12393362076202, lng: 0.1066915218198119 },
      { lat: 39.30997813573272, lng: -0.2787113102128842 },
      { lat: 38.73851430923303, lng: 0.11129072429389453 },
      { lat: 38.29236583104115, lng: -0.46712358234913154 },
      { lat: 37.642353827457825, lng: -0.6833894514905978 },
      { lat: 37.443063666324214, lng: -1.4383821272748492 },
      { lat: 36.67414419203728, lng: -2.1464526025381474 },
      { lat: 36.65889964451118, lng: -3.4157808089233868 },
      { lat: 36.677839056946155, lng: -4.368900926114748 },
      { lat: 36.32470815687964, lng: -4.995219285492226 },
      { lat: 36.15212713877348, lng: -5.335483942830706 },
      { lat: 36.15511130646112, lng: -5.345472110911487 },
      { lat: 36.1545651709996, lng: -5.3709416111355495 },
      { lat: 36.07609249853697, lng: -5.386003383800244 },
      { lat: 35.97352791637043, lng: -5.603752814139625 },
      { lat: 36.02981659600606, lng: -5.866432257500946 },
      { lat: 36.367677110330334, lng: -6.236693894872133 },
      { lat: 36.94291331638732, lng: -6.520190802425418 },
      { lat: 37.09778758396607, lng: -7.4537255517781205 },
      { lat: 37.42890432387623, lng: -7.53710547528101 },
      { lat: 37.803894354802225, lng: -7.166507941099894 },
      { lat: 38.07576406508977, lng: -7.029281175148753 },
      { lat: 38.37305858006492, lng: -7.374092169616347 },
      { lat: 39.03007274022378, lng: -7.098036668313171 },
      { lat: 39.62957103124181, lng: -7.498632371439726 },
      { lat: 39.71189158788277, lng: -7.066591559263543 },
      { lat: 40.184524237624245, lng: -7.026413133156666 },
      { lat: 40.33087189387483, lng: -6.864019944679399 },
      { lat: 41.11108266861753, lng: -6.851126674822581 },
      { lat: 41.381815497394655, lng: -6.389087693700958 },
      { lat: 41.883386949219584, lng: -6.66860551596767 },
      { lat: 41.91834605566505, lng: -7.251308966490797 },
      { lat: 41.79207469335983, lng: -7.422512986673837 },
      { lat: 41.790886135417125, lng: -8.013174607769884 },
      { lat: 42.28046865495034, lng: -8.263856980817764 },
      { lat: 42.13468943945496, lng: -8.671945766626777 },
      { lat: 41.88057058365967, lng: -9.034817674180204 },
    ],
  ],
  ET: [
    [
      { lat: 14.959430000000168, lng: 37.90607000000011 },
      { lat: 14.50547, lng: 38.51295 },
      { lat: 14.74064, lng: 39.0994 },
      { lat: 14.53155, lng: 39.34061 },
      { lat: 14.51959, lng: 40.02625000000012 },
      { lat: 14.118640000000141, lng: 40.8966 },
      { lat: 13.77333, lng: 41.1552 },
      { lat: 13.452090000000112, lng: 41.59856 },
      { lat: 12.86582, lng: 42.00975 },
      { lat: 12.542230000000131, lng: 42.35156000000012 },
      { lat: 12.100000000000136, lng: 42.000000000000114 },
      { lat: 11.6312, lng: 41.66176000000013 },
      { lat: 11.355110000000138, lng: 41.73959000000019 },
      { lat: 11.050910000000101, lng: 41.755570000000205 },
      { lat: 11.0342, lng: 42.31414000000012 },
      { lat: 11.105110000000195, lng: 42.55493000000013 },
      { lat: 10.92687856693442, lng: 42.77685184100096 },
      { lat: 10.57258000000013, lng: 42.55876 },
      { lat: 10.021940000000143, lng: 42.92812 },
      { lat: 9.540480000000173, lng: 43.29699000000011 },
      { lat: 9.18358000000012, lng: 43.67875 },
      { lat: 7.99688, lng: 46.94834 },
      { lat: 8.003, lng: 47.78942 },
      { lat: 5.001620000000116, lng: 44.9636 },
      { lat: 4.95755, lng: 43.66087 },
      { lat: 4.252590000000225, lng: 42.76967000000013 },
      { lat: 4.234130000000164, lng: 42.12861 },
      { lat: 3.918911920483765, lng: 41.85508309264412 },
      { lat: 3.91909, lng: 41.17180000000013 },
      { lat: 4.257020000000125, lng: 40.76848000000012 },
      { lat: 3.838790000000131, lng: 39.85494000000011 },
      { lat: 3.422060000000215, lng: 39.55938425876593 },
      { lat: 3.50074, lng: 38.89251 },
      { lat: 3.61607, lng: 38.67114 },
      { lat: 3.58851, lng: 38.436970000000144 },
      { lat: 3.598605, lng: 38.12091500000014 },
      { lat: 4.447864127672858, lng: 36.85509323800824 },
      { lat: 4.447864127672858, lng: 36.15907863285565 },
      { lat: 4.776965663462022, lng: 35.81744766235362 },
      { lat: 5.338232082790853, lng: 35.81744766235362 },
      { lat: 5.506, lng: 35.298007118233095 },
      { lat: 6.59422000000012, lng: 34.70702 },
      { lat: 6.82607, lng: 34.25032 },
      { lat: 7.22595, lng: 34.07510000000019 },
      { lat: 7.71334, lng: 33.568290000000104 },
      { lat: 7.784970000000102, lng: 32.954180000000235 },
      { lat: 8.35458, lng: 33.29480000000012 },
      { lat: 8.37916, lng: 33.82550000000015 },
      { lat: 8.684560000000147, lng: 33.97498 },
      { lat: 9.58358, lng: 33.96162 },
      { lat: 10.63009, lng: 34.25745 },
      { lat: 10.910170000000107, lng: 34.73115000000013 },
      { lat: 11.318960000000118, lng: 34.83163000000013 },
      { lat: 12.08286, lng: 35.26049 },
      { lat: 12.57828, lng: 35.863630000000164 },
      { lat: 13.563330000000121, lng: 36.27022 },
      { lat: 14.42211, lng: 36.42951 },
      { lat: 14.2131, lng: 37.59377 },
      { lat: 14.959430000000168, lng: 37.90607000000011 },
    ],
  ],
  FI: [
    [
      { lat: 69.06477692328666, lng: 28.591929559043194 },
      { lat: 68.36461294216404, lng: 28.445943637818658 },
      { lat: 67.69829702419266, lng: 29.977426385220607 },
      { lat: 66.94428620062193, lng: 29.054588657352326 },
      { lat: 65.80598, lng: 30.21765 },
      { lat: 64.94867157659048, lng: 29.544429559046986 },
      { lat: 64.20445343693909, lng: 30.44468468600371 },
      { lat: 63.55281362573855, lng: 30.035872430142714 },
      { lat: 62.86768748641288, lng: 31.51609215671112 },
      { lat: 62.35769277612441, lng: 31.139991082490894 },
      { lat: 61.78002777774969, lng: 30.211107212044446 },
      { lat: 60.503516547275844, lng: 28.069997592895277 },
      { lat: 60.4239606797625, lng: 26.25517296723697 },
      { lat: 60.05731639265165, lng: 24.496623976344523 },
      { lat: 59.846373196036225, lng: 22.869694858499457 },
      { lat: 60.39192129174154, lng: 22.290763787533592 },
      { lat: 60.72016998965952, lng: 21.322244093519316 },
      { lat: 61.7053294948718, lng: 21.544866163832694 },
      { lat: 62.60739329695874, lng: 21.05921105315369 },
      { lat: 63.18973501245587, lng: 21.536029493910803 },
      { lat: 63.81781037053129, lng: 22.442744174903993 },
      { lat: 64.90234365504082, lng: 24.730511508897536 },
      { lat: 65.11142650009373, lng: 25.398067661243942 },
      { lat: 65.53434642197045, lng: 25.294043003040404 },
      { lat: 66.00692739527962, lng: 23.903378533633802 },
      { lat: 66.39605093043743, lng: 23.565879754335583 },
      { lat: 67.93600861273525, lng: 23.53947309743444 },
      { lat: 68.6168456081807, lng: 21.978534783626117 },
      { lat: 69.10624726020087, lng: 20.645592889089528 },
      { lat: 69.37044302029307, lng: 21.244936150810673 },
      { lat: 68.84174144151491, lng: 22.356237827247412 },
      { lat: 68.89124746365054, lng: 23.66204959483076 },
      { lat: 68.64955678982146, lng: 24.735679152126725 },
      { lat: 69.09211375596904, lng: 25.68921268077636 },
      { lat: 69.82529897732614, lng: 26.17962202322624 },
      { lat: 70.16419302029625, lng: 27.732292107867863 },
      { lat: 69.76649119737799, lng: 29.01557295097197 },
      { lat: 69.06477692328666, lng: 28.591929559043194 },
    ],
  ],
  FJ: [
    [
      { lat: -17.33992, lng: 178.3736 },
      { lat: -17.62846, lng: 178.71806 },
      { lat: -18.15059, lng: 178.55271 },
      { lat: -18.28799, lng: 177.93266 },
      { lat: -18.16432, lng: 177.38146 },
      { lat: -17.72465, lng: 177.28504 },
      { lat: -17.38114, lng: 177.67087 },
      { lat: -17.50481, lng: 178.12557 },
      { lat: -17.33992, lng: 178.3736 },
    ],
    [
      { lat: -16.80135407694685, lng: 179.36414266196428 },
      { lat: -17.01204167436802, lng: 178.7250593629971 },
      { lat: -16.63915, lng: 178.59683859511708 },
      { lat: -16.43398427754742, lng: 179.09660936299716 },
      { lat: -16.379054277547397, lng: 179.41350936299713 },
      { lat: -16.06713266364244, lng: 180.00000000000014 },
      { lat: -16.55521656663916, lng: 180.00000000000014 },
      { lat: -16.80135407694685, lng: 179.36414266196428 },
    ],
    [
      { lat: -16.50178313564936, lng: -179.91736938476524 },
      { lat: -16.55521656663916, lng: -180 },
      { lat: -16.06713266364244, lng: -180 },
      { lat: -16.02088225674123, lng: -179.79332010904858 },
      { lat: -16.50178313564936, lng: -179.91736938476524 },
    ],
  ],
  FK: [
    [
      { lat: -51.85, lng: -61.2 },
      { lat: -51.25, lng: -60 },
      { lat: -51.5, lng: -59.15 },
      { lat: -51.1, lng: -58.55 },
      { lat: -51.55, lng: -57.75 },
      { lat: -51.9, lng: -58.05 },
      { lat: -52.2, lng: -59.4 },
      { lat: -51.85, lng: -59.85 },
      { lat: -52.3, lng: -60.7 },
      { lat: -51.85, lng: -61.2 },
    ],
  ],
  FM: [
    [
      { lat: 9.4153045914901, lng: 138.05906536741 },
      { lat: 9.4233469609068, lng: 138.05376271004 },
      { lat: 9.453204826468, lng: 138.04878829019 },
      { lat: 9.5085344105219, lng: 138.06189738799 },
      { lat: 9.5554738818036, lng: 138.09391532976 },
      { lat: 9.6025161565494, lng: 138.15942191624 },
      { lat: 9.605584516695, lng: 138.1730856063 },
      { lat: 9.6026678414956, lng: 138.18913906566 },
      { lat: 9.5920785159455, lng: 138.20450178454 },
      { lat: 9.5764770429233, lng: 138.21444039643 },
      { lat: 9.5303659320546, lng: 138.22257458579 },
      { lat: 9.5170578406974, lng: 138.21909910826 },
      { lat: 9.4942057450759, lng: 138.20245005471 },
      { lat: 9.4267196606571, lng: 138.1047467364 },
      { lat: 9.4149656495169, lng: 138.06128558709 },
      { lat: 9.4153045914901, lng: 138.05906536741 },
    ],
    [
      { lat: 5.3026834218719, lng: 162.90805814969 },
      { lat: 5.3178387950005, lng: 162.90702915799 },
      { lat: 5.320448769923, lng: 162.90795550942 },
      { lat: 5.3352165665527, lng: 162.92171058348 },
      { lat: 5.3488250924, lng: 162.95041061414 },
      { lat: 5.3493305830308, lng: 162.9652170465 },
      { lat: 5.3415885672234, lng: 162.98629562031 },
      { lat: 5.3312830280483, lng: 162.99729309814 },
      { lat: 5.3145566199847, lng: 163.00463566857 },
      { lat: 5.2667082004611, lng: 163.00641531745 },
      { lat: 5.2658871873009, lng: 163.00562673942 },
      { lat: 5.2647394936426, lng: 163.00297342399 },
      { lat: 5.2643922538669, lng: 162.97291824246 },
      { lat: 5.2811738019075, lng: 162.92511544908 },
      { lat: 5.2916466803952, lng: 162.9134542003 },
      { lat: 5.3026834218719, lng: 162.90805814969 },
    ],
    [
      { lat: 7.3426992540392, lng: 151.55535179229 },
      { lat: 7.370320917114, lng: 151.56570488447 },
      { lat: 7.3919365845436, lng: 151.58617144282 },
      { lat: 7.4761882040668, lng: 151.79392636721 },
      { lat: 7.4752291768804, lng: 151.89708545782 },
      { lat: 7.4733720291984, lng: 151.90156791613 },
      { lat: 7.3406402811229, lng: 151.73788506848 },
      { lat: 7.3281627715023, lng: 151.7079928579 },
      { lat: 7.3188853021049, lng: 151.5991410718 },
      { lat: 7.328400877627, lng: 151.56904992917 },
      { lat: 7.3382755008858, lng: 151.55754228316 },
      { lat: 7.3426992540392, lng: 151.55535179229 },
    ],
    [
      { lat: 6.9544355080049, lng: 158.12602081522 },
      { lat: 6.9664172580207, lng: 158.13820641402 },
      { lat: 6.9928232969941, lng: 158.20191444921 },
      { lat: 6.9921163588885, lng: 158.22488646032 },
      { lat: 6.9560483912567, lng: 158.31595120051 },
      { lat: 6.8918879658293, lng: 158.34471396505 },
      { lat: 6.8784110451328, lng: 158.34452756979 },
      { lat: 6.7864034912765, lng: 158.30796136359 },
      { lat: 6.7753821930785, lng: 158.2805561042 },
      { lat: 6.7743538483679, lng: 158.20478669578 },
      { lat: 6.7783791405779, lng: 158.19363644133 },
      { lat: 6.805701261906, lng: 158.16488460749 },
      { lat: 6.8852290479308, lng: 158.12958533408 },
      { lat: 6.9544355080049, lng: 158.12602081522 },
    ],
  ],
  FO: [
    [
      { lat: 62.12443624549497, lng: -7.80029296875 },
      { lat: 62.055011560944784, lng: -7.673950195312499 },
      { lat: 61.96219803129951, lng: -7.05322265625 },
      { lat: 61.72632204638334, lng: -6.910400390625 },
      { lat: 61.601171646037486, lng: -7.124633789062499 },
      { lat: 61.36514522233485, lng: -6.756591796875 },
      { lat: 61.38356706035979, lng: -6.591796875 },
      { lat: 61.57241989982641, lng: -6.65771484375 },
      { lat: 61.96219803129951, lng: -6.520385742187499 },
      { lat: 62.35980451003884, lng: -6.17431640625 },
      { lat: 62.44632426765645, lng: -6.5753173828125 },
      { lat: 62.35980451003884, lng: -7.327880859374999 },
      { lat: 62.12443624549497, lng: -7.80029296875 },
    ],
  ],
  FR: [
    [
      { lat: 42.15249197037957, lng: 9.560016310269134 },
      { lat: 41.38000682226445, lng: 9.229752231491773 },
      { lat: 41.58361196549444, lng: 8.77572309737536 },
      { lat: 42.25651662858308, lng: 8.54421268070783 },
      { lat: 42.62812185319396, lng: 8.746009148807588 },
      { lat: 43.00998484961474, lng: 9.390000848028905 },
      { lat: 42.15249197037957, lng: 9.560016310269134 },
    ],
    [
      { lat: 50.37899241800358, lng: 3.588184441755715 },
      { lat: 49.907496649772554, lng: 4.28602298342514 },
      { lat: 49.98537303323633, lng: 4.799221632515753 },
      { lat: 49.52948354755745, lng: 5.674051954784885 },
      { lat: 49.44266714130717, lng: 5.897759230176376 },
      { lat: 49.46380280211446, lng: 6.186320428094206 },
      { lat: 49.20195831969155, lng: 6.658229607783539 },
      { lat: 49.01778351500337, lng: 8.099278598674772 },
      { lat: 48.33301911070373, lng: 7.593676385131062 },
      { lat: 47.620581976911865, lng: 7.46675906742223 },
      { lat: 47.44976552997099, lng: 7.192202182655535 },
      { lat: 47.54180125588289, lng: 6.736571079138088 },
      { lat: 47.28770823830368, lng: 6.768713820023634 },
      { lat: 46.72577871356191, lng: 6.037388950228972 },
      { lat: 46.272989813820516, lng: 6.022609490593567 },
      { lat: 46.42967275652944, lng: 6.500099724970454 },
      { lat: 45.99114655210067, lng: 6.843592970414562 },
      { lat: 45.70857982032867, lng: 6.802355177445662 },
      { lat: 45.333098863295874, lng: 7.096652459347837 },
      { lat: 45.02851797136759, lng: 6.749955275101711 },
      { lat: 44.25476675066139, lng: 7.007562290076663 },
      { lat: 44.12790110938482, lng: 7.549596388386163 },
      { lat: 43.69384491634918, lng: 7.435184767291843 },
      { lat: 43.12889232031836, lng: 6.529245232783068 },
      { lat: 43.39965098731158, lng: 4.556962517931396 },
      { lat: 43.075200507167125, lng: 3.10041059735272 },
      { lat: 42.47301504166989, lng: 2.985998976258486 },
      { lat: 42.34338471126566, lng: 1.826793247087181 },
      { lat: 42.79573436133265, lng: 0.701590610363922 },
      { lat: 42.579546006839564, lng: 0.338046909190581 },
      { lat: 43.03401439063049, lng: -1.502770961910471 },
      { lat: 43.42280202897834, lng: -1.901351284177735 },
      { lat: 44.02261037859017, lng: -1.384225226232957 },
      { lat: 46.014917710954876, lng: -1.193797573237362 },
      { lat: 47.06436269793821, lng: -2.225724249673789 },
      { lat: 47.570326646507965, lng: -2.963276129559574 },
      { lat: 47.95495433205642, lng: -4.491554938159481 },
      { lat: 48.68416046812695, lng: -4.592349819344747 },
      { lat: 48.901692409859635, lng: -3.295813971357745 },
      { lat: 48.644421291694584, lng: -1.616510789384932 },
      { lat: 49.776341864615766, lng: -1.933494025063254 },
      { lat: 49.347375800160876, lng: -0.98946895995536 },
      { lat: 50.12717316344526, lng: 1.338761020522753 },
      { lat: 50.946606350297515, lng: 1.6390010921385 },
      { lat: 51.14850617126185, lng: 2.513573032246171 },
      { lat: 50.79684804951566, lng: 2.658422071960331 },
      { lat: 50.78036326761452, lng: 3.123251580425716 },
      { lat: 50.37899241800358, lng: 3.588184441755715 },
    ],
  ],
  GA: [
    [
      { lat: -3.978826592630547, lng: 11.093772820691925 },
      { lat: -2.969482517105681, lng: 10.06613528813574 },
      { lat: -2.144313246269043, lng: 9.40524539555497 },
      { lat: -1.111301364754496, lng: 8.79799563969317 },
      { lat: -0.779073581550037, lng: 8.830086704146423 },
      { lat: -0.459351494960217, lng: 9.048419630579588 },
      { lat: 0.268666083167687, lng: 9.29135053878369 },
      { lat: 1.010119533691494, lng: 9.492888624721985 },
      { lat: 1.067893784993799, lng: 9.830284051155644 },
      { lat: 1.057661851400013, lng: 11.285078973036462 },
      { lat: 2.261050930180872, lng: 11.276449008843713 },
      { lat: 2.326757513839993, lng: 11.75166548019979 },
      { lat: 2.19281220133945, lng: 12.359380323952221 },
      { lat: 2.32161570882694, lng: 12.951333855855609 },
      { lat: 2.267097072759015, lng: 13.075822381246752 },
      { lat: 1.83089630778332, lng: 13.003113641012078 },
      { lat: 1.31418366129688, lng: 13.282631463278818 },
      { lat: 1.395677395021153, lng: 14.026668735417218 },
      { lat: 1.196929836426619, lng: 14.276265903386957 },
      { lat: 0.038757635901149, lng: 13.843320753645655 },
      { lat: -0.552627455247048, lng: 14.316418491277743 },
      { lat: -1.333406670744971, lng: 14.425455763413593 },
      { lat: -1.998275648612214, lng: 14.299210239324564 },
      { lat: -2.4708049454891, lng: 13.99240726080771 },
      { lat: -2.428740329603514, lng: 13.109618767965628 },
      { lat: -1.948511244315135, lng: 12.575284458067642 },
      { lat: -2.391688327650243, lng: 12.495702752338161 },
      { lat: -2.514161472181982, lng: 11.820963575903193 },
      { lat: -2.765618991714241, lng: 11.478038771214303 },
      { lat: -3.426870619321051, lng: 11.855121697648114 },
      { lat: -3.978826592630547, lng: 11.093772820691925 },
    ],
  ],
  GB: [
    [
      { lat: 54.55460317648385, lng: -5.661948614921897 },
      { lat: 53.86756500916334, lng: -6.197884894220977 },
      { lat: 54.073702297575636, lng: -6.953730231137996 },
      { lat: 54.05995636658599, lng: -7.572167934591079 },
      { lat: 54.595840969452695, lng: -7.366030646178785 },
      { lat: 55.1316222194549, lng: -7.572167934591079 },
      { lat: 55.1728600124238, lng: -6.733847011736145 },
      { lat: 54.55460317648385, lng: -5.661948614921897 },
    ],
    [
      { lat: 58.63500010846633, lng: -3.005004848635281 },
      { lat: 57.55302480735525, lng: -4.073828497728016 },
      { lat: 57.69001902936095, lng: -3.055001796877661 },
      { lat: 57.68479970969951, lng: -1.959280564776918 },
      { lat: 56.87001740175353, lng: -2.219988165689301 },
      { lat: 55.973793036515474, lng: -3.119003058271118 },
      { lat: 55.90999848085127, lng: -2.085009324543023 },
      { lat: 55.80490285035023, lng: -2.005675679673857 },
      { lat: 54.62498647726539, lng: -1.11499101399221 },
      { lat: 54.46437612570216, lng: -0.4304849918542 },
      { lat: 53.32501414653103, lng: 0.184981316742039 },
      { lat: 52.92999949809197, lng: 0.469976840831777 },
      { lat: 52.739520168664, lng: 1.681530795914739 },
      { lat: 52.09999848083601, lng: 1.559987827164377 },
      { lat: 51.806760565795685, lng: 1.050561557630914 },
      { lat: 51.28942780212196, lng: 1.449865349950301 },
      { lat: 50.765738837275876, lng: 0.550333693045502 },
      { lat: 50.77498891865622, lng: -0.78751746255864 },
      { lat: 50.50001862243124, lng: -2.489997524414377 },
      { lat: 50.696879991247016, lng: -2.956273972984036 },
      { lat: 50.22835561787272, lng: -3.617448085942328 },
      { lat: 50.34183706318566, lng: -4.542507900399244 },
      { lat: 49.95999990498108, lng: -5.245023159191135 },
      { lat: 50.15967763935682, lng: -5.776566941745301 },
      { lat: 51.21000112568916, lng: -4.309989793301838 },
      { lat: 51.42600861266925, lng: -3.414850633142123 },
      { lat: 51.42684816740609, lng: -3.422719467108323 },
      { lat: 51.593466091510976, lng: -4.984367234710874 },
      { lat: 51.99140045837458, lng: -5.267295701508885 },
      { lat: 52.301355699261364, lng: -4.222346564134853 },
      { lat: 52.840004991255626, lng: -4.770013393564113 },
      { lat: 53.49500377055517, lng: -4.579999152026915 },
      { lat: 53.404547400669685, lng: -3.093830673788659 },
      { lat: 53.404440822963544, lng: -3.092079637047106 },
      { lat: 53.984999701546684, lng: -2.945008510744344 },
      { lat: 54.600936773292574, lng: -3.614700825433034 },
      { lat: 54.615012925833014, lng: -3.63000545898933 },
      { lat: 54.790971177786844, lng: -4.844169073903004 },
      { lat: 55.06160065369937, lng: -5.082526617849226 },
      { lat: 55.50847260194348, lng: -4.719112107756644 },
      { lat: 55.78398550070752, lng: -5.047980922862109 },
      { lat: 55.31114614523682, lng: -5.586397670911139 },
      { lat: 56.275014960344805, lng: -5.644998745130181 },
      { lat: 56.78500967063354, lng: -6.149980841486354 },
      { lat: 57.81884837506465, lng: -5.786824713555291 },
      { lat: 58.63001333275005, lng: -5.009998745127575 },
      { lat: 58.55084503847917, lng: -4.211494513353557 },
      { lat: 58.63500010846633, lng: -3.005004848635281 },
    ],
  ],
  GD: [
    [
      { lat: 12.038916508195, lng: -61.789477610679 },
      { lat: 12.190731438316, lng: -61.721464422914 },
      { lat: 12.239612307041, lng: -61.669586166606 },
      { lat: 12.235948754045, lng: -61.618133982303 },
      { lat: 12.228497399874, lng: -61.602972418922 },
      { lat: 12.226443666731, lng: -61.601231134684 },
      { lat: 12.223408072537, lng: -61.600316993924 },
      { lat: 12.061528418973, lng: -61.619410468627 },
      { lat: 12.014061653723, lng: -61.688147017112 },
      { lat: 11.998261418983, lng: -61.757396616575 },
      { lat: 12.002129299173, lng: -61.784461255082 },
      { lat: 12.003561978859, lng: -61.786789148488 },
      { lat: 12.005803438782, lng: -61.788353602454 },
      { lat: 12.024462033298, lng: -61.792128368556 },
      { lat: 12.038916508195, lng: -61.789477610679 },
    ],
  ],
  GE: [
    [
      { lat: 41.53565623632757, lng: 41.55408410011065 },
      { lat: 41.96294281673292, lng: 41.70317060727271 },
      { lat: 42.64512339941794, lng: 41.45347008643839 },
      { lat: 43.013628038091284, lng: 40.87546919125379 },
      { lat: 43.128633938156845, lng: 40.32139448422032 },
      { lat: 43.43499766699922, lng: 39.955008579270924 },
      { lat: 43.55310415300231, lng: 40.07696495947977 },
      { lat: 43.38215851498079, lng: 40.922184686045625 },
      { lat: 43.22030792904263, lng: 42.39439456560882 },
      { lat: 42.74082815202249, lng: 43.75601688006739 },
      { lat: 42.55497386328477, lng: 43.931199985536836 },
      { lat: 42.71199270280363, lng: 44.537622918481986 },
      { lat: 42.50278066666998, lng: 45.47027916848572 },
      { lat: 42.09244395605636, lng: 45.77641035338277 },
      { lat: 41.860675157227305, lng: 46.404950799348825 },
      { lat: 41.72280243587258, lng: 46.14543175637902 },
      { lat: 41.181672675128226, lng: 46.63790815612058 },
      { lat: 41.06444468847411, lng: 46.50163740416693 },
      { lat: 41.123872585609774, lng: 45.96260053893039 },
      { lat: 41.41145193131405, lng: 45.217426385281584 },
      { lat: 41.248128567055595, lng: 44.97248009621808 },
      { lat: 41.09214325618257, lng: 43.582745802592726 },
      { lat: 41.58317271581994, lng: 42.61954878110449 },
      { lat: 41.53565623632757, lng: 41.55408410011065 },
    ],
  ],
  GF: [
    [
      { lat: 2.504705, lng: -52.556425 },
      { lat: 2.124858, lng: -52.939657 },
      { lat: 2.053389, lng: -53.418465 },
      { lat: 2.334897, lng: -53.554839 },
      { lat: 2.376703, lng: -53.778521 },
      { lat: 2.105557, lng: -54.088063 },
      { lat: 2.311849, lng: -54.524754 },
      { lat: 2.738748, lng: -54.27123 },
      { lat: 3.194172, lng: -54.184284 },
      { lat: 3.62257, lng: -54.011504 },
      { lat: 4.212611, lng: -54.399542 },
      { lat: 4.896756, lng: -54.478633 },
      { lat: 5.756548, lng: -53.958045 },
      { lat: 5.646529, lng: -53.618453 },
      { lat: 5.409851, lng: -52.882141 },
      { lat: 4.565768, lng: -51.823343 },
      { lat: 4.156232, lng: -51.657797 },
      { lat: 3.241094, lng: -52.249338 },
      { lat: 2.504705, lng: -52.556425 },
    ],
  ],
  GG: [
    [
      { lat: 49.52877389852215, lng: -2.5872802734374996 },
      { lat: 49.47035517151213, lng: -2.7060699462890625 },
      { lat: 49.408739392190896, lng: -2.69439697265625 },
      { lat: 49.4029310169727, lng: -2.5275421142578125 },
      { lat: 49.49756646112032, lng: -2.470550537109375 },
      { lat: 49.53055657928003, lng: -2.487030029296875 },
      { lat: 49.52877389852215, lng: -2.5872802734374996 },
    ],
  ],
  GH: [
    [
      { lat: 5.928837388528876, lng: 1.060121697604927 },
      { lat: 5.343472601742675, lng: -0.507637905265938 },
      { lat: 5.000547797053812, lng: -1.063624640294194 },
      { lat: 4.710462144383371, lng: -1.964706590167594 },
      { lat: 4.994475816259509, lng: -2.856125047202397 },
      { lat: 5.38905121502411, lng: -2.81070146321784 },
      { lat: 6.250471503113502, lng: -3.244370083011262 },
      { lat: 7.379704901555512, lng: -2.983584967450327 },
      { lat: 8.219627793811483, lng: -2.562189500326241 },
      { lat: 9.642460842319778, lng: -2.827496303712707 },
      { lat: 10.395334784380083, lng: -2.963896246747112 },
      { lat: 10.962690334512558, lng: -2.940409308270461 },
      { lat: 11.009819240762738, lng: -1.203357713211431 },
      { lat: 10.936929633015055, lng: -0.761575893548183 },
      { lat: 11.09834096927872, lng: -0.438701544588582 },
      { lat: 11.018681748900804, lng: 0.023802524423701 },
      { lat: 10.706917832883931, lng: -0.049784715159944 },
      { lat: 10.19121287682718, lng: 0.367579990245389 },
      { lat: 9.465003973829482, lng: 0.365900506195885 },
      { lat: 8.677222601756014, lng: 0.461191847342121 },
      { lat: 8.31246450442383, lng: 0.712029249686878 },
      { lat: 7.411744289576475, lng: 0.490957472342245 },
      { lat: 6.914358628767189, lng: 0.570384148774849 },
      { lat: 6.279978745952149, lng: 0.836931186536333 },
      { lat: 5.928837388528876, lng: 1.060121697604927 },
    ],
  ],
  GI: [
    [
      { lat: 36.15520202548466, lng: -365.34544944763184 },
      { lat: 36.15485551685543, lng: -365.36261558532715 },
      { lat: 36.14376643227369, lng: -365.37214279174805 },
      { lat: 36.11048977271466, lng: -365.35343170166016 },
      { lat: 36.106398543140195, lng: -365.3470802307129 },
      { lat: 36.109588333654656, lng: -365.3408145904541 },
      { lat: 36.15104382087541, lng: -365.3333473205566 },
      { lat: 36.15520202548466, lng: -365.34544944763184 },
    ],
  ],
  GL: [
    [
      { lat: 82.62796, lng: -46.76379 },
      { lat: 83.22516, lng: -43.40644 },
      { lat: 83.18018, lng: -39.89753 },
      { lat: 83.54905, lng: -38.62214 },
      { lat: 83.64513, lng: -35.08787 },
      { lat: 83.51966, lng: -27.10046 },
      { lat: 82.72669, lng: -20.84539 },
      { lat: 82.34165, lng: -22.69182 },
      { lat: 82.29765, lng: -26.51753 },
      { lat: 82.2, lng: -31.9 },
      { lat: 82.02154, lng: -31.39646 },
      { lat: 82.13178, lng: -27.85666 },
      { lat: 81.78697, lng: -24.84448 },
      { lat: 82.09317, lng: -22.90328 },
      { lat: 81.73449, lng: -22.07175 },
      { lat: 81.15271, lng: -23.16961 },
      { lat: 81.52462, lng: -20.62363 },
      { lat: 81.91245, lng: -15.76818 },
      { lat: 81.71885, lng: -12.77018 },
      { lat: 81.29154, lng: -12.20855 },
      { lat: 80.58004, lng: -16.28533 },
      { lat: 80.35, lng: -16.85 },
      { lat: 80.17708, lng: -20.04624 },
      { lat: 80.12912, lng: -17.73035 },
      { lat: 79.4, lng: -18.9 },
      { lat: 78.75128, lng: -19.70499 },
      { lat: 77.63859, lng: -19.67353 },
      { lat: 76.98565, lng: -18.47285 },
      { lat: 76.94434, lng: -20.03503 },
      { lat: 76.62795, lng: -21.67944 },
      { lat: 76.09808, lng: -19.83407 },
      { lat: 75.24838, lng: -19.59896 },
      { lat: 75.15585, lng: -20.66818 },
      { lat: 74.29561, lng: -19.37281 },
      { lat: 74.22382, lng: -21.59422 },
      { lat: 73.81713, lng: -20.43454 },
      { lat: 73.46436, lng: -20.76234 },
      { lat: 73.30955, lng: -22.17221 },
      { lat: 73.30663, lng: -23.56593 },
      { lat: 72.62928, lng: -22.31311 },
      { lat: 72.18409, lng: -22.29954 },
      { lat: 72.59788, lng: -24.27834 },
      { lat: 72.3302, lng: -24.79296 },
      { lat: 72.08016, lng: -23.44296 },
      { lat: 71.46898, lng: -22.13281 },
      { lat: 70.66369, lng: -21.75356 },
      { lat: 70.471, lng: -23.53603 },
      { lat: 70.85649, lng: -24.30702 },
      { lat: 71.43094, lng: -25.54341 },
      { lat: 70.75226, lng: -25.20135 },
      { lat: 70.22646, lng: -26.36276 },
      { lat: 70.18401, lng: -23.72742 },
      { lat: 70.12946, lng: -22.34902 },
      { lat: 69.2588, lng: -25.02927 },
      { lat: 68.47046, lng: -27.74737 },
      { lat: 68.12503, lng: -30.67371 },
      { lat: 68.12078, lng: -31.77665 },
      { lat: 67.73547, lng: -32.81105 },
      { lat: 66.67974, lng: -34.20196 },
      { lat: 65.9789, lng: -36.35284 },
      { lat: 65.93768, lng: -37.04378 },
      { lat: 65.69213, lng: -38.37505 },
      { lat: 65.45848, lng: -39.81222 },
      { lat: 64.83997, lng: -40.66899 },
      { lat: 64.13902, lng: -40.68281 },
      { lat: 63.48246, lng: -41.1887 },
      { lat: 62.68233, lng: -42.81938 },
      { lat: 61.90093, lng: -42.41666 },
      { lat: 61.07404, lng: -42.86619 },
      { lat: 60.09772, lng: -43.3784 },
      { lat: 60.03676, lng: -44.7875 },
      { lat: 60.85328, lng: -46.26364 },
      { lat: 60.85843, lng: -48.26294 },
      { lat: 61.40681, lng: -49.23308 },
      { lat: 62.38336, lng: -49.90039 },
      { lat: 63.62691, lng: -51.63325 },
      { lat: 64.27842, lng: -52.14014 },
      { lat: 65.1767, lng: -52.27659 },
      { lat: 66.09957, lng: -53.66166 },
      { lat: 66.8365, lng: -53.30161 },
      { lat: 67.18899, lng: -53.96911 },
      { lat: 68.35759, lng: -52.9804 },
      { lat: 68.72958, lng: -51.47536 },
      { lat: 69.14781, lng: -51.08041 },
      { lat: 69.9291, lng: -50.87122 },
      { lat: 69.574925, lng: -52.013585 },
      { lat: 69.42616, lng: -52.55792 },
      { lat: 69.283625, lng: -53.45629 },
      { lat: 69.61003, lng: -54.68336 },
      { lat: 70.28932, lng: -54.75001 },
      { lat: 70.821315, lng: -54.35884 },
      { lat: 70.835755, lng: -53.431315 },
      { lat: 70.56978, lng: -51.39014 },
      { lat: 71.20485, lng: -53.10937 },
      { lat: 71.54719, lng: -54.00422 },
      { lat: 71.40653696727257, lng: -55 },
      { lat: 71.65444, lng: -55.83468 },
      { lat: 72.58625, lng: -54.71819 },
      { lat: 72.95861, lng: -55.32634 },
      { lat: 73.64977, lng: -56.12003 },
      { lat: 74.71026, lng: -57.32363 },
      { lat: 75.09861, lng: -58.59679 },
      { lat: 75.51727, lng: -58.58516 },
      { lat: 76.10238, lng: -61.26861 },
      { lat: 76.1752, lng: -63.39165 },
      { lat: 76.13486, lng: -66.06427 },
      { lat: 76.06141, lng: -68.50438 },
      { lat: 76.37975, lng: -69.66485 },
      { lat: 77.00857, lng: -71.40257 },
      { lat: 77.32312, lng: -68.77671 },
      { lat: 77.37595, lng: -66.76397 },
      { lat: 77.63595, lng: -71.04293 },
      { lat: 78.04419, lng: -73.297 },
      { lat: 78.43271, lng: -73.15938 },
      { lat: 78.91388, lng: -69.37345 },
      { lat: 79.39436, lng: -65.7107 },
      { lat: 79.75814, lng: -65.3239 },
      { lat: 80.11721, lng: -68.02298 },
      { lat: 80.51582, lng: -67.15129 },
      { lat: 81.21396, lng: -63.68925 },
      { lat: 81.3211, lng: -62.23444 },
      { lat: 81.77042, lng: -62.65116 },
      { lat: 82.03363, lng: -60.28249 },
      { lat: 82.19074, lng: -57.20744 },
      { lat: 82.19962, lng: -54.13442 },
      { lat: 81.88833, lng: -53.04328 },
      { lat: 82.43883, lng: -50.39061 },
      { lat: 82.06481, lng: -48.00386 },
      { lat: 81.985945, lng: -46.59984 },
      { lat: 81.6607, lng: -44.523 },
      { lat: 82.19979, lng: -46.9007 },
      { lat: 82.62796, lng: -46.76379 },
    ],
  ],
  GM: [
    [
      { lat: 13.15139394780256, lng: -16.841524624081273 },
      { lat: 13.594958604379853, lng: -16.713728807023468 },
      { lat: 13.62358734786956, lng: -15.62459632003994 },
      { lat: 13.86036876063092, lng: -15.39877031092446 },
      { lat: 13.876491807505984, lng: -15.08173539881382 },
      { lat: 13.630356960499784, lng: -14.687030808968487 },
      { lat: 13.625680243377372, lng: -14.376713833055788 },
      { lat: 13.79406789800045, lng: -14.046992356817482 },
      { lat: 13.505041612192002, lng: -13.844963344772408 },
      { lat: 13.280585028532242, lng: -14.277701788784553 },
      { lat: 13.298206691943777, lng: -14.712197231494626 },
      { lat: 13.509511623585238, lng: -15.141163295949466 },
      { lat: 13.278569647672867, lng: -15.511812506562935 },
      { lat: 13.270353094938455, lng: -15.691000535534995 },
      { lat: 13.130284125211332, lng: -15.931295945692211 },
      { lat: 13.15139394780256, lng: -16.841524624081273 },
    ],
  ],
  GN: [
    [
      { lat: 7.686042792181738, lng: -8.439298468448698 },
      { lat: 7.71167430259851, lng: -8.722123582382123 },
      { lat: 7.309037380396375, lng: -8.926064622422004 },
      { lat: 7.313920803247953, lng: -9.208786383490844 },
      { lat: 7.526905218938907, lng: -9.40334815106975 },
      { lat: 7.928534450711353, lng: -9.337279832384581 },
      { lat: 8.541055202666925, lng: -9.755342169625834 },
      { lat: 8.42850393313523, lng: -10.016566534861255 },
      { lat: 8.406205552601293, lng: -10.23009355309128 },
      { lat: 8.348896389189605, lng: -10.505477260774668 },
      { lat: 8.715540676300435, lng: -10.494315151399633 },
      { lat: 8.977178452994194, lng: -10.654770473665891 },
      { lat: 9.267910061068278, lng: -10.622395188835041 },
      { lat: 9.688246161330369, lng: -10.8391519840833 },
      { lat: 10.045872911006285, lng: -11.117481248407328 },
      { lat: 10.046983954300558, lng: -11.917277390988659 },
      { lat: 9.858571682164381, lng: -12.150338100625005 },
      { lat: 9.835834051955956, lng: -12.425928514037565 },
      { lat: 9.62018830000197, lng: -12.59671912276221 },
      { lat: 9.342711696810767, lng: -12.71195756677308 },
      { lat: 8.903048610871508, lng: -13.246550258832515 },
      { lat: 9.49474376061346, lng: -13.685153977909792 },
      { lat: 9.886166897008252, lng: -14.074044969122282 },
      { lat: 10.015719712763966, lng: -14.33007585291237 },
      { lat: 10.214467271358515, lng: -14.579698859098258 },
      { lat: 10.656300767454042, lng: -14.693231980843505 },
      { lat: 10.876571560098139, lng: -14.839553798877944 },
      { lat: 11.040411688679526, lng: -15.130311245168171 },
      { lat: 11.527823798056488, lng: -14.685687221728898 },
      { lat: 11.509271958863692, lng: -14.382191534878729 },
      { lat: 11.677117010947697, lng: -14.121406419317779 },
      { lat: 11.678718980348748, lng: -13.900799729863776 },
      { lat: 11.811269029177412, lng: -13.743160773157411 },
      { lat: 12.142644151249044, lng: -13.828271857142125 },
      { lat: 12.24718557377551, lng: -13.718743658899513 },
      { lat: 12.586182969610194, lng: -13.700476040084325 },
      { lat: 12.575873521367967, lng: -13.217818162478238 },
      { lat: 12.332089952031057, lng: -12.499050665730564 },
      { lat: 12.354440008997285, lng: -12.278599005573438 },
      { lat: 12.465647691289405, lng: -12.203564825885634 },
      { lat: 12.386582749882834, lng: -11.65830095055793 },
      { lat: 12.442987575729418, lng: -11.51394283695059 },
      { lat: 12.076834214725338, lng: -11.456168585648271 },
      { lat: 12.077971096235771, lng: -11.29757361494451 },
      { lat: 12.211244615116515, lng: -11.036555955438258 },
      { lat: 12.17788747807211, lng: -10.870829637078215 },
      { lat: 11.92397532800598, lng: -10.593223842806282 },
      { lat: 11.844083563682744, lng: -10.165213792348837 },
      { lat: 12.060478623904972, lng: -9.890992804392013 },
      { lat: 12.194243068892476, lng: -9.567911749703214 },
      { lat: 12.334286200403454, lng: -9.327616339546012 },
      { lat: 12.308060411015331, lng: -9.127473517279583 },
      { lat: 12.088358059126437, lng: -8.90526485842453 },
      { lat: 11.812560939984706, lng: -8.786099005559464 },
      { lat: 11.393645941610629, lng: -8.376304897484914 },
      { lat: 11.136245632364805, lng: -8.581305304386774 },
      { lat: 10.810890814655183, lng: -8.620321010767128 },
      { lat: 10.909256903522762, lng: -8.407310756860028 },
      { lat: 10.792597357623846, lng: -8.282357143578281 },
      { lat: 10.494811916541934, lng: -8.33537716310974 },
      { lat: 10.206534939001713, lng: -8.029943610048619 },
      { lat: 10.1290202905639, lng: -8.229337124046822 },
      { lat: 9.789531968622441, lng: -8.30961646161225 },
      { lat: 9.376223863152035, lng: -8.079113735374348 },
      { lat: 8.575704250518626, lng: -7.832100389019188 },
      { lat: 8.455453192575447, lng: -8.20349890790088 },
      { lat: 8.316443589710303, lng: -8.299048631208564 },
      { lat: 8.123328762235573, lng: -8.221792364932199 },
      { lat: 7.687179673692156, lng: -8.280703497744938 },
      { lat: 7.686042792181738, lng: -8.439298468448698 },
    ],
  ],
  GP: [
    [
      { lat: 16.57039022046052, lng: -61.48223876953125 },
      { lat: 16.358356454087513, lng: -61.854400634765625 },
      { lat: 15.966610400903196, lng: -61.837921142578125 },
      { lat: 15.800182150387888, lng: -61.65527343749999 },
      { lat: 15.855673509998681, lng: -61.15264892578125 },
      { lat: 16.374168198186904, lng: -60.991973876953125 },
      { lat: 16.57039022046052, lng: -61.48223876953125 },
    ],
  ],
  GQ: [
    [
      { lat: 1.010119533691494, lng: 9.492888624721985 },
      { lat: 1.160911363119183, lng: 9.305613234096256 },
      { lat: 2.283866075037736, lng: 9.649158155972628 },
      { lat: 2.261050930180872, lng: 11.276449008843713 },
      { lat: 1.057661851400013, lng: 11.285078973036462 },
      { lat: 1.067893784993799, lng: 9.830284051155644 },
      { lat: 1.010119533691494, lng: 9.492888624721985 },
    ],
  ],
  GR: [
    [
      { lat: 41.562114569661105, lng: 26.604195590936282 },
      { lat: 40.93626129817426, lng: 26.29460208507578 },
      { lat: 40.824123440100834, lng: 26.056942172965506 },
      { lat: 40.85254547786147, lng: 25.447677036244187 },
      { lat: 40.94706167252323, lng: 24.92584842296094 },
      { lat: 40.687129218095116, lng: 23.714811232200816 },
      { lat: 40.1249929876241, lng: 24.407998894964066 },
      { lat: 39.96200552017558, lng: 23.899967889102584 },
      { lat: 39.96099782974579, lng: 23.3429993018608 },
      { lat: 40.476005153966554, lng: 22.81398766448896 },
      { lat: 40.25656118423919, lng: 22.62629886240478 },
      { lat: 39.65931081802577, lng: 22.849747755634805 },
      { lat: 39.19001129816726, lng: 23.3500272966526 },
      { lat: 38.97090322524966, lng: 22.973099399515547 },
      { lat: 38.51000112563847, lng: 23.530016310324953 },
      { lat: 38.21999298761645, lng: 24.025024855248944 },
      { lat: 37.655014553369426, lng: 24.040011020613605 },
      { lat: 37.92001129816222, lng: 23.115002882589152 },
      { lat: 37.409990749657396, lng: 23.409971958111072 },
      { lat: 37.30501007745656, lng: 22.774971958108633 },
      { lat: 36.422505804992056, lng: 23.15422529469862 },
      { lat: 36.41000010837746, lng: 22.490028110451107 },
      { lat: 36.8449864771942, lng: 21.670026482843696 },
      { lat: 37.644989325504696, lng: 21.295010613701574 },
      { lat: 38.31032339126273, lng: 21.120034213961333 },
      { lat: 38.769985256498785, lng: 20.730032179454582 },
      { lat: 39.340234686839636, lng: 20.217712029712857 },
      { lat: 39.62499766698403, lng: 20.15001590341052 },
      { lat: 40.11000682225943, lng: 20.615000441172782 },
      { lat: 40.434999904943055, lng: 20.674996779063633 },
      { lat: 40.58000397395397, lng: 20.99998986174728 },
      { lat: 40.84272695572588, lng: 21.02004031747643 },
      { lat: 40.93127452245798, lng: 21.674160597426976 },
      { lat: 41.14986583105269, lng: 22.05537763844427 },
      { lat: 41.130487168943205, lng: 22.597308383889015 },
      { lat: 41.3048, lng: 22.76177 },
      { lat: 41.33799388281122, lng: 22.952377150166566 },
      { lat: 41.30908091894386, lng: 23.692073601992462 },
      { lat: 41.58389618587205, lng: 24.49264489105803 },
      { lat: 41.23448598893066, lng: 25.197201368925533 },
      { lat: 41.32889883072784, lng: 26.106138136507184 },
      { lat: 41.82690460872473, lng: 26.117041863720914 },
      { lat: 41.562114569661105, lng: 26.604195590936282 },
    ],
    [
      { lat: 35.70500438083553, lng: 23.699980096133004 },
      { lat: 35.368022365860156, lng: 24.24666507334868 },
      { lat: 35.42499563246198, lng: 25.02501549652888 },
      { lat: 35.35401805270908, lng: 25.769207797964185 },
      { lat: 35.179997666966216, lng: 25.745023227651586 },
      { lat: 35.29999034274792, lng: 26.290002882601723 },
      { lat: 35.004995429009796, lng: 26.16499759288766 },
      { lat: 34.91998769788961, lng: 24.724982130642303 },
      { lat: 35.08499054619759, lng: 24.735007358506945 },
      { lat: 35.27999156345098, lng: 23.51497846852811 },
      { lat: 35.70500438083553, lng: 23.699980096133004 },
    ],
  ],
  GT: [
    [
      { lat: 14.53099505897, lng: -92.235318639444 },
      { lat: 14.784899760277, lng: -92.215321534773 },
      { lat: 14.93001541868, lng: -92.213583661259 },
      { lat: 15.19868686959, lng: -92.228280018591 },
      { lat: 15.321193173489, lng: -92.203573394814 },
      { lat: 16.012677268995, lng: -91.8042160132 },
      { lat: 16.194539571688, lng: -91.646980134445 },
      { lat: 16.386022079216, lng: -91.514330480551 },
      { lat: 16.601317903826, lng: -91.425396857703 },
      { lat: 16.830594312266, lng: -91.384240935892 },
      { lat: 17.063380066793, lng: -91.392742339129 },
      { lat: 17.252462727894, lng: -91.420075480519 },
      { lat: 17.253096580639, lng: -91.419924483243 },
      { lat: 17.766236485813, lng: -91.045490842256 },
      { lat: 17.827817131754, lng: -90.929564152707 },
      { lat: 17.843247839171, lng: -89.321724618152 },
      { lat: 17.789646907962, lng: -89.149982825152 },
      { lat: 16.957695192567, lng: -89.165125600919 },
      { lat: 16.735475590268, lng: -89.146352806596 },
      { lat: 16.521727768194, lng: -89.082745904369 },
      { lat: 16.325399228788, lng: -88.976967484703 },
      { lat: 16.154708301805, lng: -88.833445441455 },
      { lat: 16.016800124311, lng: -88.658187619853 },
      { lat: 15.774352576189, lng: -88.275076433682 },
      { lat: 15.727837767654, lng: -88.246902059522 },
      { lat: 15.674386994432, lng: -88.270724005151 },
      { lat: 15.25820786354, lng: -88.768085969276 },
      { lat: 15.127538288727, lng: -88.899664695072 },
      { lat: 14.976455728281, lng: -89.007189384298 },
      { lat: 14.809333005514, lng: -89.087547920862 },
      { lat: 14.597162784303, lng: -89.168144745576 },
      { lat: 14.255343241868, lng: -89.492472287735 },
      { lat: 13.743409298763, lng: -90.05892247862 },
      { lat: 13.728873264609, lng: -90.093697595439 },
      { lat: 13.729001367496, lng: -90.12313535617 },
      { lat: 13.828827073659, lng: -90.389958530118 },
      { lat: 13.874488427825, lng: -90.547035153073 },
      { lat: 13.89612920322, lng: -90.709176145987 },
      { lat: 13.923517992229, lng: -91.184332193251 },
      { lat: 14.227650657703, lng: -91.862506502547 },
      { lat: 14.461076427022, lng: -92.172891053676 },
      { lat: 14.53099505897, lng: -92.235318639444 },
    ],
  ],
  GU: [
    [
      { lat: 13.69869468588793, lng: 144.85198974609375 },
      { lat: 13.451065987933186, lng: 144.591064453125 },
      { lat: 13.22590881874347, lng: 144.6185302734375 },
      { lat: 13.206523502739042, lng: 144.72084045410156 },
      { lat: 13.607282399161136, lng: 145.0044250488281 },
      { lat: 13.69869468588793, lng: 144.85198974609375 },
    ],
  ],
  GW: [
    [
      { lat: 11.040411688679526, lng: -15.130311245168171 },
      { lat: 11.458474025920795, lng: -15.664180467175527 },
      { lat: 11.52459402103824, lng: -16.085214199273565 },
      { lat: 11.806514797406548, lng: -16.314786749730203 },
      { lat: 11.95870189050612, lng: -16.30894731288123 },
      { lat: 12.170911159712702, lng: -16.613838263403277 },
      { lat: 12.384851589401052, lng: -16.677451951554573 },
      { lat: 12.547761542201187, lng: -16.147716844130585 },
      { lat: 12.515567124883345, lng: -15.816574266004254 },
      { lat: 12.628170070847347, lng: -15.548476935274008 },
      { lat: 12.586182969610194, lng: -13.700476040084325 },
      { lat: 12.24718557377551, lng: -13.718743658899513 },
      { lat: 12.142644151249044, lng: -13.828271857142125 },
      { lat: 11.811269029177412, lng: -13.743160773157411 },
      { lat: 11.678718980348748, lng: -13.900799729863776 },
      { lat: 11.677117010947697, lng: -14.121406419317779 },
      { lat: 11.509271958863692, lng: -14.382191534878729 },
      { lat: 11.527823798056488, lng: -14.685687221728898 },
      { lat: 11.040411688679526, lng: -15.130311245168171 },
    ],
  ],
  GY: [
    [
      { lat: 8.367034816924047, lng: -59.758284878159195 },
      { lat: 7.999201971870492, lng: -59.101684129458654 },
      { lat: 7.347691351750697, lng: -58.482962205628056 },
      { lat: 6.832787380394463, lng: -58.45487606467743 },
      { lat: 6.809093736188643, lng: -58.07810319683737 },
      { lat: 6.321268215353356, lng: -57.542218593970645 },
      { lat: 5.973149929219161, lng: -57.14743648947688 },
      { lat: 5.073566595882227, lng: -57.307245856339506 },
      { lat: 4.812626451024414, lng: -57.91428890647214 },
      { lat: 4.57680105226045, lng: -57.86020952007869 },
      { lat: 4.060863552258382, lng: -58.04469438336068 },
      { lat: 3.334654649260685, lng: -57.60156897645786 },
      { lat: 3.333491929534119, lng: -57.2814334784097 },
      { lat: 2.768926906745406, lng: -57.150097825739905 },
      { lat: 1.899522609866921, lng: -56.539385748914555 },
      { lat: 1.863710842288654, lng: -56.78270423036083 },
      { lat: 1.94853770589576, lng: -57.335822923396904 },
      { lat: 1.682584947105639, lng: -57.66097103537737 },
      { lat: 1.507195135907025, lng: -58.11344987652502 },
      { lat: 1.463941962078721, lng: -58.42947709820596 },
      { lat: 1.268088283692521, lng: -58.5400129868783 },
      { lat: 1.317697658692722, lng: -59.03086157900265 },
      { lat: 1.786893825686789, lng: -59.64604366722126 },
      { lat: 2.24963043864436, lng: -59.71854570172674 },
      { lat: 2.755232652188056, lng: -59.97452490908456 },
      { lat: 3.606498521332085, lng: -59.81541317405786 },
      { lat: 3.958802598481938, lng: -59.53803992373123 },
      { lat: 4.423502915866607, lng: -59.767405768458715 },
      { lat: 4.574966538914083, lng: -60.11100236676737 },
      { lat: 5.014061184098139, lng: -59.98095862490488 },
      { lat: 5.244486395687602, lng: -60.21368343773133 },
      { lat: 5.200277207861901, lng: -60.73357418480372 },
      { lat: 5.959068101419618, lng: -61.410302903881956 },
      { lat: 6.234296779806144, lng: -61.13941504580795 },
      { lat: 6.696077378766319, lng: -61.15933631045648 },
      { lat: 6.856584377464883, lng: -60.54399919294098 },
      { lat: 7.043911444522919, lng: -60.29566809756239 },
      { lat: 7.414999904810855, lng: -60.637972785063766 },
      { lat: 7.779602972846178, lng: -60.55058793805819 },
      { lat: 8.367034816924047, lng: -59.758284878159195 },
    ],
  ],
  HK: [
    [
      { lat: 22.32340060265609, lng: 114.13799285888672 },
      { lat: 22.222687563055, lng: 114.06177520751953 },
      { lat: 22.157519125359865, lng: 114.11087036132811 },
      { lat: 22.157201156469572, lng: 114.3021011352539 },
      { lat: 22.22713698713604, lng: 114.29077148437499 },
      { lat: 22.327846824223613, lng: 114.19498443603516 },
      { lat: 22.32340060265609, lng: 114.13799285888672 },
    ],
  ],
  HN: [
    [
      { lat: 12.984685777229004, lng: -87.31665442579549 },
      { lat: 13.297534898323931, lng: -87.48940873894713 },
      { lat: 13.384480495655168, lng: -87.79311113152653 },
      { lat: 13.785050360565606, lng: -87.72350297722932 },
      { lat: 13.893312486217097, lng: -87.85951534702161 },
      { lat: 13.96462596277979, lng: -88.06534257684012 },
      { lat: 13.845485948130943, lng: -88.50399797234962 },
      { lat: 13.980154730683523, lng: -88.54123084181595 },
      { lat: 14.140506700085211, lng: -88.84307288283276 },
      { lat: 14.340029405164215, lng: -89.05851192905766 },
      { lat: 14.424132798719086, lng: -89.35332597528281 },
      { lat: 14.678019110569153, lng: -89.14553504103719 },
      { lat: 14.874286200413678, lng: -89.22522009963124 },
      { lat: 15.066419175674866, lng: -89.15481096063353 },
      { lat: 15.34624705653539, lng: -88.6806796943556 },
      { lat: 15.72772247971403, lng: -88.22502275262195 },
      { lat: 15.688655096901359, lng: -88.12115312371537 },
      { lat: 15.864458319558196, lng: -87.90181250685241 },
      { lat: 15.8787985295192, lng: -87.61568010125234 },
      { lat: 15.797278957578783, lng: -87.52292090528846 },
      { lat: 15.84694000901129, lng: -87.36776241733213 },
      { lat: 15.756712958229569, lng: -86.90319129102818 },
      { lat: 15.78283539475319, lng: -86.44094560417739 },
      { lat: 15.893448798073962, lng: -86.11923397494434 },
      { lat: 16.00540578863439, lng: -86.00195431185784 },
      { lat: 15.953651841693953, lng: -85.68331743034628 },
      { lat: 15.885749009662446, lng: -85.44400387240256 },
      { lat: 15.90915843349063, lng: -85.18244361035721 },
      { lat: 15.995923163308701, lng: -84.98372188997882 },
      { lat: 15.857223619037427, lng: -84.52697974316715 },
      { lat: 15.835157782448732, lng: -84.36825558138258 },
      { lat: 15.648244126849136, lng: -84.06305457226682 },
      { lat: 15.42407176356687, lng: -83.77397661002612 },
      { lat: 15.270902818253774, lng: -83.41038123242036 },
      { lat: 14.99582916916421, lng: -83.14721900097413 },
      { lat: 15.016267198135663, lng: -83.48998877636602 },
      { lat: 14.880073960830371, lng: -83.62858496777288 },
      { lat: 14.749435939996486, lng: -83.97572140169359 },
      { lat: 14.74876414637663, lng: -84.22834164095241 },
      { lat: 14.621614284722511, lng: -84.4493359036486 },
      { lat: 14.666805324761867, lng: -84.64958207877963 },
      { lat: 14.81958669683263, lng: -84.8200367906943 },
      { lat: 14.790492865452336, lng: -84.92450069857233 },
      { lat: 14.551541042534723, lng: -85.05278744173688 },
      { lat: 14.560196844943619, lng: -85.14875057650288 },
      { lat: 14.35436961512505, lng: -85.16536454948482 },
      { lat: 14.079011745657908, lng: -85.51441301140028 },
      { lat: 13.960078436738002, lng: -85.69866533073696 },
      { lat: 13.836054999237604, lng: -85.8012947252685 },
      { lat: 14.038187364147234, lng: -86.09626380079061 },
      { lat: 13.771356106008225, lng: -86.31214209668985 },
      { lat: 13.778487453664468, lng: -86.52070817741992 },
      { lat: 13.75484548589094, lng: -86.75508663607962 },
      { lat: 13.263092556201398, lng: -86.73382178419149 },
      { lat: 13.254204209847217, lng: -86.88055701368438 },
      { lat: 13.025794379117258, lng: -87.00576900912743 },
      { lat: 12.984685777229004, lng: -87.31665442579549 },
    ],
  ],
  HR: [
    [
      { lat: 45.908877671891844, lng: 18.829838087650046 },
      { lat: 45.52151113543209, lng: 19.072768995854176 },
      { lat: 45.236515611342384, lng: 19.39047570158459 },
      { lat: 44.86023366960916, lng: 19.00548628101012 },
      { lat: 45.08158966733145, lng: 18.553214145591653 },
      { lat: 45.067740383477144, lng: 17.861783481526402 },
      { lat: 45.233776760430935, lng: 17.002146030351014 },
      { lat: 45.21160757097772, lng: 16.534939406000206 },
      { lat: 45.00412669532591, lng: 16.318156772535872 },
      { lat: 45.233776760430935, lng: 15.959367303133376 },
      { lat: 44.81871165626256, lng: 15.750026075918981 },
      { lat: 44.35114329688571, lng: 16.23966027188453 },
      { lat: 44.04123973243128, lng: 16.456442905348865 },
      { lat: 43.66772247982567, lng: 16.91615644701733 },
      { lat: 43.44634064388736, lng: 17.297373488034452 },
      { lat: 43.02856252702361, lng: 17.674921502358984 },
      { lat: 42.65, lng: 18.56 },
      { lat: 42.47999136002932, lng: 18.450016310304818 },
      { lat: 42.849994615239154, lng: 17.509970330483327 },
      { lat: 43.20999848080038, lng: 16.930005730871642 },
      { lat: 43.50721548112722, lng: 16.015384555737683 },
      { lat: 44.243191229827914, lng: 15.174453973052096 },
      { lat: 44.31791535092208, lng: 15.376250441151795 },
      { lat: 44.73848399512946, lng: 14.920309279040508 },
      { lat: 45.07606028907611, lng: 14.901602410550877 },
      { lat: 45.233776760430935, lng: 14.258747592839995 },
      { lat: 44.80212352149687, lng: 13.952254672917034 },
      { lat: 45.13693512631596, lng: 13.656975538801191 },
      { lat: 45.48414907488501, lng: 13.67940311041582 },
      { lat: 45.500323798192426, lng: 13.715059848697251 },
      { lat: 45.46616567644742, lng: 14.4119682145855 },
      { lat: 45.63494090431282, lng: 14.595109490627918 },
      { lat: 45.471695054702764, lng: 14.935243767972963 },
      { lat: 45.452316392593325, lng: 15.327674594797427 },
      { lat: 45.731782538427694, lng: 15.323953891672431 },
      { lat: 45.8341535507979, lng: 15.671529575267641 },
      { lat: 46.23810822202353, lng: 15.768732944408612 },
      { lat: 46.50375092221981, lng: 16.564808383864943 },
      { lat: 46.38063182228444, lng: 16.882515089595415 },
      { lat: 45.9517691106941, lng: 17.630066359129557 },
      { lat: 45.75948110613615, lng: 18.45606245288286 },
      { lat: 45.908877671891844, lng: 18.829838087650046 },
    ],
  ],
  HT: [
    [
      { lat: 19.915683905511912, lng: -73.18979061551762 },
      { lat: 19.871500555902358, lng: -72.57967281766362 },
      { lat: 19.714455878167357, lng: -71.71236141629296 },
      { lat: 19.169837958243306, lng: -71.62487321642283 },
      { lat: 18.78541697842405, lng: -71.70130265978248 },
      { lat: 18.61690013272026, lng: -71.94511206733556 },
      { lat: 18.31666006110447, lng: -71.68773759630587 },
      { lat: 18.04499705654609, lng: -71.70830481635805 },
      { lat: 18.21496084235406, lng: -72.37247616238935 },
      { lat: 18.14561107021836, lng: -72.84441118029488 },
      { lat: 18.217906398994696, lng: -73.45455481636503 },
      { lat: 18.030992743395004, lng: -73.92243323433566 },
      { lat: 18.342549953682706, lng: -74.45803361682478 },
      { lat: 18.66490753831941, lng: -74.36992529976713 },
      { lat: 18.526052964751145, lng: -73.44954220243272 },
      { lat: 18.445799465401862, lng: -72.69493709989064 },
      { lat: 18.668421535715254, lng: -72.334881557897 },
      { lat: 19.10162506761803, lng: -72.79164954292489 },
      { lat: 19.48359141690341, lng: -72.78410478381028 },
      { lat: 19.639550889560283, lng: -73.41502234566175 },
      { lat: 19.915683905511912, lng: -73.18979061551762 },
    ],
  ],
  HU: [
    [
      { lat: 46.85238597267696, lng: 16.202298211337364 },
      { lat: 47.49617096616912, lng: 16.534267612380376 },
      { lat: 47.71290192320123, lng: 16.340584344150415 },
      { lat: 47.71486562762833, lng: 16.90375410326726 },
      { lat: 48.123497015976305, lng: 16.979666782304037 },
      { lat: 47.86746613218621, lng: 17.48847293464982 },
      { lat: 47.758428860050365, lng: 17.857132602620027 },
      { lat: 47.880953681014404, lng: 18.696512892336926 },
      { lat: 48.081768296900634, lng: 18.77702477384767 },
      { lat: 48.11137889260387, lng: 19.17436486173989 },
      { lat: 48.26661489520866, lng: 19.661363559658497 },
      { lat: 48.202691148463614, lng: 19.769470656013112 },
      { lat: 48.32756724709692, lng: 20.239054396249347 },
      { lat: 48.56285004332181, lng: 20.473562045989866 },
      { lat: 48.623854071642384, lng: 20.801293979584926 },
      { lat: 48.31997081155002, lng: 21.872236362401736 },
      { lat: 48.42226430927179, lng: 22.08560835133485 },
      { lat: 48.15023956968735, lng: 22.640819939878753 },
      { lat: 47.88219391538941, lng: 22.710531447040495 },
      { lat: 47.6724392767167, lng: 22.099767693782834 },
      { lat: 46.99423777931816, lng: 21.62651492685387 },
      { lat: 46.3160879583519, lng: 21.02195234547125 },
      { lat: 46.127468980486555, lng: 20.220192498462836 },
      { lat: 46.17172984474454, lng: 19.596044549241583 },
      { lat: 45.90887767189193, lng: 18.82983808764996 },
      { lat: 45.759481106136136, lng: 18.45606245288286 },
      { lat: 45.95176911069419, lng: 17.630066359129557 },
      { lat: 46.38063182228444, lng: 16.8825150895953 },
      { lat: 46.50375092221983, lng: 16.564808383864857 },
      { lat: 46.8413272161665, lng: 16.370504998447416 },
      { lat: 46.85238597267696, lng: 16.202298211337364 },
    ],
  ],
  ID: [
    [
      { lat: -5.852355645372413, lng: 105.81765506390936 },
      { lat: -5.873284600450646, lng: 104.71038414919151 },
      { lat: -5.037314955264975, lng: 103.86821333213074 },
      { lat: -4.220258884298204, lng: 102.58426069540693 },
      { lat: -3.614146009946765, lng: 102.15617313030103 },
      { lat: -2.799777113459172, lng: 101.39911339722508 },
      { lat: -2.05026213949786, lng: 100.90250288290017 },
      { lat: -0.650347588710957, lng: 100.14198082886062 },
      { lat: 0.183141587724663, lng: 99.26373986206025 },
      { lat: 1.042882391764536, lng: 98.97001102091333 },
      { lat: 1.823506577965617, lng: 98.60135135294311 },
      { lat: 2.453183905442117, lng: 97.6995976094499 },
      { lat: 3.30879059489861, lng: 97.1769421732499 },
      { lat: 3.868859768077911, lng: 96.42401655475734 },
      { lat: 4.970782172053673, lng: 95.38087609251347 },
      { lat: 5.479820868344817, lng: 95.29302615761733 },
      { lat: 5.439513251157109, lng: 95.93686282754176 },
      { lat: 5.246320909034011, lng: 97.4848820332771 },
      { lat: 4.268370266126368, lng: 98.36916914265569 },
      { lat: 3.590349636240916, lng: 99.14255862833582 },
      { lat: 3.174328518075157, lng: 99.69399783732243 },
      { lat: 2.099381211755798, lng: 100.64143354696168 },
      { lat: 2.083697414555189, lng: 101.65801232300734 },
      { lat: 1.398700466310217, lng: 102.49827111207324 },
      { lat: 0.561361395668854, lng: 103.07684044801303 },
      { lat: 0.104541734208667, lng: 103.83839603069835 },
      { lat: -0.711945896002845, lng: 103.43764529827497 },
      { lat: -1.059211521004229, lng: 104.01078860882402 },
      { lat: -1.084843031421016, lng: 104.3699914896849 },
      { lat: -1.782371514496716, lng: 104.53949018760218 },
      { lat: -2.340425306816655, lng: 104.88789269411402 },
      { lat: -2.42884368246807, lng: 105.622111444117 },
      { lat: -3.06177662517895, lng: 106.10859337771271 },
      { lat: -4.305524997579724, lng: 105.85744591677414 },
      { lat: -5.852355645372413, lng: 105.81765506390936 },
    ],
    [
      { lat: -10.140000909061442, lng: 124.43595014861941 },
      { lat: -10.359987481327963, lng: 123.57998172413673 },
      { lat: -10.239994805546175, lng: 123.45998904835503 },
      { lat: -9.90001555749798, lng: 123.55000939340746 },
      { lat: -9.290026950724695, lng: 123.98000898650811 },
      { lat: -8.892790215697048, lng: 124.96868248911622 },
      { lat: -9.089987481322837, lng: 125.07001997284064 },
      { lat: -9.393173109579322, lng: 125.08852013560109 },
      { lat: -10.140000909061442, lng: 124.43595014861941 },
    ],
    [
      { lat: 1.419836127117605, lng: 125.24050052297159 },
      { lat: 0.427881171058971, lng: 124.43703535369737 },
      { lat: 0.235593166500877, lng: 123.68550499887672 },
      { lat: 0.431136786293337, lng: 122.72308312387288 },
      { lat: 0.381217352699451, lng: 121.0567248881891 },
      { lat: 0.23724681233422, lng: 120.18308312386276 },
      { lat: -0.519657891444851, lng: 120.04086958219548 },
      { lat: -1.408905938323372, lng: 120.93590538949073 },
      { lat: -0.955962009285116, lng: 121.4758207540762 },
      { lat: -0.615672702643081, lng: 123.34056481332848 },
      { lat: -1.076213067228338, lng: 123.2583992859845 },
      { lat: -0.930950616055881, lng: 122.82271528533161 },
      { lat: -1.516858005381124, lng: 122.38852990121539 },
      { lat: -1.904482924002423, lng: 121.50827355355548 },
      { lat: -3.186058444840882, lng: 122.4545723816843 },
      { lat: -3.529500013852697, lng: 122.27189619353257 },
      { lat: -4.683693129091708, lng: 123.17096276254657 },
      { lat: -5.340603936385961, lng: 123.16233279835379 },
      { lat: -5.634591159694494, lng: 122.62851525277871 },
      { lat: -5.282933037948283, lng: 122.23639448454806 },
      { lat: -4.46417164471579, lng: 122.71956912647707 },
      { lat: -4.8513314754465, lng: 121.73823367725439 },
      { lat: -4.574552504091216, lng: 121.48946333220127 },
      { lat: -4.188477878438674, lng: 121.61917117725388 },
      { lat: -3.602105401222829, lng: 120.89818159391771 },
      { lat: -2.62764291749491, lng: 120.97238895068877 },
      { lat: -2.931603692235726, lng: 120.30545291552991 },
      { lat: -4.097579034037224, lng: 120.39004723519176 },
      { lat: -5.528241062037779, lng: 120.43071658740539 },
      { lat: -5.673400160345651, lng: 119.79654341031952 },
      { lat: -5.379878024927805, lng: 119.36690555224496 },
      { lat: -4.459417412944958, lng: 119.65360639860013 },
      { lat: -3.49441171632651, lng: 119.49883548388597 },
      { lat: -3.487021986508765, lng: 119.078344354327 },
      { lat: -2.801999200047689, lng: 118.7677689962529 },
      { lat: -2.147103773612798, lng: 119.18097374885869 },
      { lat: -1.353147067880471, lng: 119.32339399625508 },
      { lat: 0.154254462073496, lng: 119.82599897672586 },
      { lat: 0.566477362465804, lng: 120.03570193896637 },
      { lat: 1.309222723796836, lng: 120.8857792501677 },
      { lat: 1.013943589681077, lng: 121.666816847827 },
      { lat: 0.875192368977466, lng: 122.92756676645185 },
      { lat: 0.917101955566139, lng: 124.07752241424285 },
      { lat: 1.643259182131558, lng: 125.06598921112183 },
      { lat: 1.419836127117605, lng: 125.24050052297159 },
    ],
    [
      { lat: 1.827640692548911, lng: 117.87562706916603 },
      { lat: 0.902219143066048, lng: 118.99674726773819 },
      { lat: 0.784241848143722, lng: 117.81185835171779 },
      { lat: 0.102474676917026, lng: 117.47833865770608 },
      { lat: -0.803723239753211, lng: 117.52164350796662 },
      { lat: -1.487660821136231, lng: 116.56004845587952 },
      { lat: -2.483517347832901, lng: 116.53379682827519 },
      { lat: -4.012726332214015, lng: 116.14808393764864 },
      { lat: -3.657037448749008, lng: 116.0008577820491 },
      { lat: -4.106984144714417, lng: 114.86480309454454 },
      { lat: -3.495703627133821, lng: 114.46865156459509 },
      { lat: -3.43916961020652, lng: 113.75567182826413 },
      { lat: -3.118775729996855, lng: 113.25699425664757 },
      { lat: -3.478392022316072, lng: 112.06812625534067 },
      { lat: -2.994442233902632, lng: 111.70329064336002 },
      { lat: -3.049425957861189, lng: 111.04824018762824 },
      { lat: -2.934032484553484, lng: 110.223846063276 },
      { lat: -1.592874037282414, lng: 110.07093550012436 },
      { lat: -1.314906507984489, lng: 109.57194786991406 },
      { lat: -0.459506524257051, lng: 109.09187381392253 },
      { lat: 0.415375474444346, lng: 108.95265750532816 },
      { lat: 1.341933905437642, lng: 109.06913618371404 },
      { lat: 2.006466986494985, lng: 109.66326012577375 },
      { lat: 1.338135687664192, lng: 109.83022667850886 },
      { lat: 0.773131415200993, lng: 110.51406090702713 },
      { lat: 0.976478176269509, lng: 111.15913781132659 },
      { lat: 0.904441229654651, lng: 111.79754845586044 },
      { lat: 1.410120957846758, lng: 112.38025190638368 },
      { lat: 1.497790025229946, lng: 112.8598091980522 },
      { lat: 1.217548732911041, lng: 113.80584964401956 },
      { lat: 1.430688177898887, lng: 114.6213554220175 },
      { lat: 2.821481838386219, lng: 115.13403730678523 },
      { lat: 3.169238389494396, lng: 115.51907840379201 },
      { lat: 4.306559149590157, lng: 115.86551720587677 },
      { lat: 4.306094061699469, lng: 117.01521447150637 },
      { lat: 4.137551377779488, lng: 117.88203494677019 },
      { lat: 3.234428208830579, lng: 117.31323245653354 },
      { lat: 2.287690131027361, lng: 118.04832970588538 },
      { lat: 1.827640692548911, lng: 117.87562706916603 },
    ],
    [
      { lat: -8.095681247594925, lng: 117.90001834520777 },
      { lat: -8.362383314653329, lng: 118.2606164897405 },
      { lat: -8.28068287519983, lng: 118.87845991422215 },
      { lat: -8.705824883665073, lng: 119.12650678922309 },
      { lat: -8.906639499551261, lng: 117.9704016459893 },
      { lat: -9.040894870645559, lng: 117.27773074754903 },
      { lat: -9.03293670007264, lng: 116.74014082241663 },
      { lat: -8.457157891476541, lng: 117.08373742072533 },
      { lat: -8.449303073768192, lng: 117.63202436734215 },
      { lat: -8.095681247594925, lng: 117.90001834520777 },
    ],
    [
      { lat: -3.45906503663889, lng: 127.24921512258892 },
      { lat: -3.79098276124958, lng: 126.87492272349888 },
      { lat: -3.607376397316556, lng: 126.18380211802733 },
      { lat: -3.177273451351325, lng: 125.98903364471929 },
      { lat: -3.12931772218441, lng: 127.00065148326499 },
      { lat: -3.45906503663889, lng: 127.24921512258892 },
    ],
    [
      { lat: -8.094234307490737, lng: 122.90353722543611 },
      { lat: -8.64980763106064, lng: 122.75698286345632 },
      { lat: -8.933666273639943, lng: 121.25449059457013 },
      { lat: -8.810417982623875, lng: 119.9243909038096 },
      { lat: -8.444858900591072, lng: 119.92092858284613 },
      { lat: -8.236964613480865, lng: 120.71509199430757 },
      { lat: -8.536739597206022, lng: 121.34166873584658 },
      { lat: -8.460620212440162, lng: 122.00736453663043 },
      { lat: -8.094234307490737, lng: 122.90353722543611 },
    ],
    [
      { lat: -6.214400730009287, lng: 134.72462446506668 },
      { lat: -6.895237725454706, lng: 134.21013390516893 },
      { lat: -6.142467136259014, lng: 134.112775506731 },
      { lat: -5.783057549669039, lng: 134.2903357280858 },
      { lat: -5.445042006047899, lng: 134.49962527886788 },
      { lat: -5.73758228925216, lng: 134.72700158095213 },
      { lat: -6.214400730009287, lng: 134.72462446506668 },
    ],
    [
      { lat: -6.777673841990676, lng: 108.62347863162894 },
      { lat: -6.877357679881683, lng: 110.53922732955331 },
      { lat: -6.465186455921752, lng: 110.75957563684594 },
      { lat: -6.946035658397591, lng: 112.61481123255638 },
      { lat: -7.59421314863458, lng: 112.97876834518812 },
      { lat: -7.776527601760279, lng: 114.47893517462117 },
      { lat: -8.370806573116866, lng: 115.70552697150109 },
      { lat: -8.751816908404834, lng: 114.56451134649652 },
      { lat: -8.348947442257426, lng: 113.4647335144609 },
      { lat: -8.376180922075164, lng: 112.55967247930103 },
      { lat: -8.302128594600957, lng: 111.52206139531248 },
      { lat: -8.122604668819022, lng: 110.58614953007432 },
      { lat: -7.740664157749761, lng: 109.4276672709552 },
      { lat: -7.641600437046221, lng: 108.69365522668133 },
      { lat: -7.766657403192581, lng: 108.27776329959632 },
      { lat: -7.354899590690947, lng: 106.45410200401615 },
      { lat: -6.924899997590202, lng: 106.28062422081231 },
      { lat: -6.85141611087117, lng: 105.36548628135554 },
      { lat: -5.8959188777945, lng: 106.05164594932707 },
      { lat: -5.954985039904059, lng: 107.2650085795402 },
      { lat: -6.345762220895239, lng: 108.0720910990747 },
      { lat: -6.421984958525768, lng: 108.48684614464926 },
      { lat: -6.777673841990676, lng: 108.62347863162894 },
    ],
    [
      { lat: 1.132385972494106, lng: 128.68824873262074 },
      { lat: 0.258485826006179, lng: 128.63595218314137 },
      { lat: 0.356412665199286, lng: 128.12016971243617 },
      { lat: -0.252077325037533, lng: 127.96803429576887 },
      { lat: -0.780003757331286, lng: 128.37999881399972 },
      { lat: -0.899996433112975, lng: 128.10001590384232 },
      { lat: -0.266598402511505, lng: 127.69647464407504 },
      { lat: 1.011721503092573, lng: 127.39949018769377 },
      { lat: 1.810690822757181, lng: 127.60051150930907 },
      { lat: 2.174596258956555, lng: 127.93237755748751 },
      { lat: 1.628531398928331, lng: 128.00415612194084 },
      { lat: 1.540810655112864, lng: 128.59455936087548 },
      { lat: 1.132385972494106, lng: 128.68824873262074 },
    ],
    [
      { lat: -3.09376433676762, lng: 130.4713440288518 },
      { lat: -3.858472181822762, lng: 130.8348360535928 },
      { lat: -3.446300957862817, lng: 129.99054650280814 },
      { lat: -3.362636813982249, lng: 129.15524865124243 },
      { lat: -3.428679294451257, lng: 128.59068362845366 },
      { lat: -3.393435967628193, lng: 127.89889122936236 },
      { lat: -2.843650404474914, lng: 128.1358793478528 },
      { lat: -2.802154229344552, lng: 129.37099775606092 },
      { lat: -3.09376433676762, lng: 130.4713440288518 },
    ],
    [
      { lat: -1.151867364103595, lng: 134.1433679546478 },
      { lat: -2.769184665542383, lng: 134.42262739475305 },
      { lat: -3.367752780779114, lng: 135.4576029806947 },
      { lat: -2.30704233155609, lng: 136.2933142437188 },
      { lat: -1.703513278819372, lng: 137.44073774632753 },
      { lat: -1.70268645590265, lng: 138.3297274110448 },
      { lat: -2.051295668143638, lng: 139.18492068904297 },
      { lat: -2.409051608900284, lng: 139.92668419816042 },
      { lat: -2.600151055515624, lng: 141.00021040259188 },
      { lat: -5.859021905138022, lng: 141.01705691951904 },
      { lat: -9.117892754760419, lng: 141.0338517600139 },
      { lat: -8.297167657100957, lng: 140.14341515519257 },
      { lat: -8.096042982620942, lng: 139.12776655492812 },
      { lat: -8.380935153846096, lng: 138.88147667862498 },
      { lat: -8.411682631059762, lng: 137.61447391169284 },
      { lat: -7.597882175327356, lng: 138.0390991558352 },
      { lat: -7.320224704623072, lng: 138.6686214540148 },
      { lat: -6.232849216337484, lng: 138.40791385310237 },
      { lat: -5.393365573756, lng: 137.92783979711086 },
      { lat: -4.546543877789048, lng: 135.98925011611348 },
      { lat: -4.462931410340772, lng: 135.16459760959972 },
      { lat: -3.538853448097527, lng: 133.6628804871979 },
      { lat: -4.024818617370315, lng: 133.3677047059468 },
      { lat: -4.112978610860281, lng: 132.98395551974735 },
      { lat: -3.74628264731713, lng: 132.756940952689 },
      { lat: -3.311787204607072, lng: 132.75378869031923 },
      { lat: -2.820551039240456, lng: 131.9898043153162 },
      { lat: -2.460417982598443, lng: 133.0668445171435 },
      { lat: -2.47984832114021, lng: 133.78003095920351 },
      { lat: -2.214541517753688, lng: 133.69621178602614 },
      { lat: -2.212526136894326, lng: 132.23237348849423 },
      { lat: -1.617161960459597, lng: 131.8362219585447 },
      { lat: -1.432522067880797, lng: 130.94283979708283 },
      { lat: -0.937720228686075, lng: 130.51955814018007 },
      { lat: -0.695461114101818, lng: 131.86753787651364 },
      { lat: -0.369537855636977, lng: 132.3801164084168 },
      { lat: -0.780210463060442, lng: 133.98554813042844 },
      { lat: -1.151867364103595, lng: 134.1433679546478 },
    ],
    [
      { lat: -10.239581394087864, lng: 120.71560875863044 },
      { lat: -10.258649997603525, lng: 120.2950142762069 },
      { lat: -9.557969252158031, lng: 118.96780846565471 },
      { lat: -9.361340427287516, lng: 119.90030968636161 },
      { lat: -9.665921319215798, lng: 120.42575564990543 },
      { lat: -9.969675388227456, lng: 120.77550174365675 },
      { lat: -10.239581394087864, lng: 120.71560875863044 },
    ],
  ],
  IE: [
    [
      { lat: 53.86756500916336, lng: -6.197884894220991 },
      { lat: 53.15316417094435, lng: -6.03298539877761 },
      { lat: 52.260117906292336, lng: -6.788856573910849 },
      { lat: 51.669301255899356, lng: -8.56161658368356 },
      { lat: 51.82045482035307, lng: -9.977085740590269 },
      { lat: 52.86462881124268, lng: -9.16628251793078 },
      { lat: 53.8813626165853, lng: -9.688524542672454 },
      { lat: 54.66451894796863, lng: -8.327987433292009 },
      { lat: 55.13162221945487, lng: -7.572167934591064 },
      { lat: 54.59584096945272, lng: -7.366030646178785 },
      { lat: 54.059956366586, lng: -7.572167934591064 },
      { lat: 54.073702297575636, lng: -6.953730231138067 },
      { lat: 53.86756500916336, lng: -6.197884894220991 },
    ],
  ],
  IL: [
    [
      { lat: 32.709192409794866, lng: 35.71991824722275 },
      { lat: 32.393992011030576, lng: 35.54566531753454 },
      { lat: 32.53251068778894, lng: 35.183930291491436 },
      { lat: 31.86658234305972, lng: 34.97464074070933 },
      { lat: 31.754341132121766, lng: 35.22589155451242 },
      { lat: 31.616778469360806, lng: 34.970506626125996 },
      { lat: 31.353435370401414, lng: 34.92740848159457 },
      { lat: 31.48908600516758, lng: 35.397560662586045 },
      { lat: 31.100065822874356, lng: 35.420918409981965 },
      { lat: 29.501326198844524, lng: 34.92260257339142 },
      { lat: 31.219360866820153, lng: 34.26543338393568 },
      { lat: 31.548823960896996, lng: 34.55637169773891 },
      { lat: 31.60553884533732, lng: 34.48810713068136 },
      { lat: 32.07292633720117, lng: 34.752587111151165 },
      { lat: 32.82737641044638, lng: 34.95541710789677 },
      { lat: 33.080539252244265, lng: 35.098457472480675 },
      { lat: 33.09090037691878, lng: 35.126052687324545 },
      { lat: 33.08904002535628, lng: 35.460709262846706 },
      { lat: 33.26427480725802, lng: 35.55279666519081 },
      { lat: 33.2774264592763, lng: 35.82110070165024 },
      { lat: 32.86812327730851, lng: 35.836396925608625 },
      { lat: 32.71601369885738, lng: 35.700797967274745 },
      { lat: 32.709192409794866, lng: 35.71991824722275 },
    ],
  ],
  IM: [
    [
      { lat: 54.4029457476126, lng: -4.85321044921875 },
      { lat: 54.21546706469533, lng: -5.05645751953125 },
      { lat: 53.954469396648996, lng: -5.01800537109375 },
      { lat: 53.9560855309879, lng: -4.6197509765625 },
      { lat: 54.0932281163235, lng: -4.199523925781249 },
      { lat: 54.35335505466622, lng: -4.0155029296875 },
      { lat: 54.54976567389827, lng: -4.31488037109375 },
      { lat: 54.4029457476126, lng: -4.85321044921875 },
    ],
  ],
  IN: [
    [
      { lat: 35.494009507787766, lng: 77.83745079947457 },
      { lat: 34.32193634697579, lng: 78.91226891471322 },
      { lat: 33.50619802503242, lng: 78.81108646028574 },
      { lat: 32.994394639613716, lng: 79.20889163606857 },
      { lat: 32.48377981213771, lng: 79.17612877799553 },
      { lat: 32.61816437431273, lng: 78.45844648632601 },
      { lat: 31.515906073527063, lng: 78.73889448437401 },
      { lat: 30.882714748654728, lng: 79.7213668151071 },
      { lat: 30.183480943313402, lng: 81.11125613802932 },
      { lat: 29.72986522065534, lng: 80.4767212259174 },
      { lat: 28.79447011974014, lng: 80.08842451367627 },
      { lat: 28.416095282499043, lng: 81.05720258985203 },
      { lat: 27.925479234319994, lng: 81.99998742058497 },
      { lat: 27.36450572357556, lng: 83.30424889519955 },
      { lat: 27.234901231387536, lng: 84.6750179381738 },
      { lat: 26.72619843190634, lng: 85.25177859898338 },
      { lat: 26.63098460540857, lng: 86.02439293817918 },
      { lat: 26.397898057556077, lng: 87.22747195836628 },
      { lat: 26.41461538340249, lng: 88.06023766474982 },
      { lat: 26.81040517832595, lng: 88.17480431514092 },
      { lat: 27.445818589786825, lng: 88.04313276566123 },
      { lat: 27.876541652939594, lng: 88.12044070836987 },
      { lat: 28.086864732367516, lng: 88.73032596227856 },
      { lat: 27.29931590423936, lng: 88.81424848832054 },
      { lat: 27.098966376243762, lng: 88.83564253128938 },
      { lat: 26.719402981059957, lng: 89.74452762243884 },
      { lat: 26.87572418874288, lng: 90.37327477413407 },
      { lat: 26.808648179628022, lng: 91.21751264848643 },
      { lat: 26.83831045176356, lng: 92.03348351437509 },
      { lat: 27.452614040633208, lng: 92.10371178585973 },
      { lat: 27.77174184825166, lng: 91.69665652869668 },
      { lat: 27.89687632904645, lng: 92.50311893104364 },
      { lat: 28.640629380807226, lng: 93.41334760943268 },
      { lat: 29.277438055939985, lng: 94.56599043170294 },
      { lat: 29.03171662039213, lng: 95.40480228066464 },
      { lat: 29.452802028922466, lng: 96.11767866413103 },
      { lat: 28.830979519154344, lng: 96.58659061074749 },
      { lat: 28.41103099213444, lng: 96.24883344928779 },
      { lat: 28.26158274994634, lng: 97.32711388549004 },
      { lat: 27.88253611908544, lng: 97.40256147663612 },
      { lat: 27.69905894623315, lng: 97.0519885599681 },
      { lat: 27.083773505149964, lng: 97.1339990580153 },
      { lat: 27.264589341739224, lng: 96.41936567585097 },
      { lat: 26.5735720891323, lng: 95.12476769407496 },
      { lat: 26.001307277932085, lng: 95.1551534362626 },
      { lat: 25.162495428970402, lng: 94.60324913938538 },
      { lat: 24.675238348890332, lng: 94.55265791217164 },
      { lat: 23.85074087167348, lng: 94.10674197792505 },
      { lat: 24.078556423432204, lng: 93.3251876159428 },
      { lat: 23.043658352139005, lng: 93.28632693885928 },
      { lat: 22.70311066333557, lng: 93.06029422401463 },
      { lat: 22.278459580977103, lng: 93.16612755734836 },
      { lat: 22.041238918541254, lng: 92.67272098182556 },
      { lat: 23.627498684172593, lng: 92.14603478390681 },
      { lat: 23.624346421802784, lng: 91.86992760617132 },
      { lat: 22.985263983649183, lng: 91.70647505083211 },
      { lat: 23.50352692310439, lng: 91.15896325069971 },
      { lat: 24.072639471934792, lng: 91.46772993364367 },
      { lat: 24.13041372323711, lng: 91.91509280799443 },
      { lat: 24.976692816664965, lng: 92.37620161333481 },
      { lat: 25.147431748957317, lng: 91.79959598182207 },
      { lat: 25.132600612889547, lng: 90.8722107279121 },
      { lat: 25.26974986419218, lng: 89.92069258012185 },
      { lat: 25.96508209889548, lng: 89.83248091019962 },
      { lat: 26.014407253518073, lng: 89.35509402868729 },
      { lat: 26.44652558034272, lng: 88.56304935094977 },
      { lat: 25.76806570078271, lng: 88.2097892598025 },
      { lat: 25.238692328384776, lng: 88.93155398962308 },
      { lat: 24.866079413344206, lng: 88.30637251175602 },
      { lat: 24.501657212821925, lng: 88.08442223506242 },
      { lat: 24.23371491138856, lng: 88.69994022009092 },
      { lat: 23.631141872649163, lng: 88.52976972855377 },
      { lat: 22.879146429937826, lng: 88.87631188350309 },
      { lat: 22.055708319582976, lng: 89.03196129756623 },
      { lat: 21.690588487224748, lng: 88.88876590368542 },
      { lat: 21.703171698487807, lng: 88.20849734899521 },
      { lat: 21.49556163175521, lng: 86.97570438024027 },
      { lat: 20.743307806882413, lng: 87.03316857294887 },
      { lat: 20.151638495356607, lng: 86.49935102737378 },
      { lat: 19.4785788029711, lng: 85.0602657409097 },
      { lat: 18.302009792549725, lng: 83.94100589390001 },
      { lat: 17.67122142177898, lng: 83.18921715691785 },
      { lat: 17.016636053937813, lng: 82.19279218946592 },
      { lat: 16.556664130107848, lng: 82.19124189649719 },
      { lat: 16.310219224507904, lng: 81.69271935417748 },
      { lat: 15.951972357644491, lng: 80.79199913933014 },
      { lat: 15.899184882058348, lng: 80.32489586784388 },
      { lat: 15.136414903214147, lng: 80.02506920768644 },
      { lat: 13.835770778859981, lng: 80.2332735533904 },
      { lat: 13.006260687710833, lng: 80.28629357292186 },
      { lat: 12.056215318240888, lng: 79.8625468281285 },
      { lat: 10.35727509199711, lng: 79.85799930208682 },
      { lat: 10.30885427493962, lng: 79.340511509116 },
      { lat: 9.546135972527722, lng: 78.88534549348918 },
      { lat: 9.216543687370148, lng: 79.18971967968828 },
      { lat: 8.933046779816934, lng: 78.2779407083305 },
      { lat: 8.252959092639742, lng: 77.94116539908435 },
      { lat: 7.965534776232333, lng: 77.53989790233794 },
      { lat: 8.89927623131419, lng: 76.59297895702167 },
      { lat: 10.299630031775521, lng: 76.13006147655108 },
      { lat: 11.308250637248307, lng: 75.74646731964849 },
      { lat: 11.781245022015824, lng: 75.39610110870957 },
      { lat: 12.741935736537897, lng: 74.86481570831681 },
      { lat: 13.99258291264968, lng: 74.61671715688354 },
      { lat: 14.617221787977696, lng: 74.44385949086723 },
      { lat: 15.99065216721496, lng: 73.5341992532334 },
      { lat: 17.928570054592498, lng: 73.11990929554943 },
      { lat: 19.208233547436166, lng: 72.82090945830865 },
      { lat: 20.419503282141534, lng: 72.8244751321368 },
      { lat: 21.356009426351008, lng: 72.6305334817454 },
      { lat: 20.757441311114235, lng: 71.17527347197395 },
      { lat: 20.877330634031384, lng: 70.4704586119451 },
      { lat: 22.0892980005727, lng: 69.16413008003883 },
      { lat: 22.450774644454338, lng: 69.64492760608239 },
      { lat: 22.84317963306269, lng: 69.34959679553435 },
      { lat: 23.69196503345671, lng: 68.1766451353734 },
      { lat: 24.35913361256094, lng: 68.84259931831878 },
      { lat: 24.3565239527302, lng: 71.04324018746823 },
      { lat: 25.21510203704352, lng: 70.84469933460284 },
      { lat: 25.72222870533983, lng: 70.28287316272558 },
      { lat: 26.491871649678842, lng: 70.16892662952202 },
      { lat: 26.940965684511372, lng: 69.51439293811312 },
      { lat: 27.989196275335868, lng: 70.61649620960193 },
      { lat: 27.913180243434525, lng: 71.77766564320032 },
      { lat: 28.961591701772054, lng: 72.8237516620847 },
      { lat: 29.97641347911987, lng: 73.45063846221743 },
      { lat: 30.979814764931177, lng: 74.42138024282026 },
      { lat: 31.69263947196528, lng: 74.40592898956501 },
      { lat: 32.2711054550405, lng: 75.25864179881322 },
      { lat: 32.7648996038055, lng: 74.45155927927871 },
      { lat: 33.44147329358685, lng: 74.10429365427734 },
      { lat: 34.31769887952785, lng: 73.74994835805195 },
      { lat: 34.74888703057125, lng: 74.24020267120497 },
      { lat: 34.50492259372132, lng: 75.75706098826834 },
      { lat: 34.65354401299274, lng: 76.87172163280403 },
      { lat: 35.494009507787766, lng: 77.83745079947457 },
    ],
  ],
  IQ: [
    [
      { lat: 35.97754588474282, lng: 45.4206181170532 },
      { lat: 35.67738332777549, lng: 46.0763403664048 },
      { lat: 35.09325877536429, lng: 46.15178795755093 },
      { lat: 34.748137722303014, lng: 45.64845950702809 },
      { lat: 33.967797756479584, lng: 45.41669070819904 },
      { lat: 33.017287299119005, lng: 46.10936160663932 },
      { lat: 32.469155381799105, lng: 47.3346614927119 },
      { lat: 31.70917593029867, lng: 47.8492037290421 },
      { lat: 30.98485321707963, lng: 47.68528608581227 },
      { lat: 30.985137437457244, lng: 48.004698113808324 },
      { lat: 30.452456773392598, lng: 48.0145683123761 },
      { lat: 29.926778265903522, lng: 48.567971225789755 },
      { lat: 29.9758192001485, lng: 47.974519077349896 },
      { lat: 30.05906993257072, lng: 47.30262210469096 },
      { lat: 29.09902517345229, lng: 46.568713413281756 },
      { lat: 29.178891099559383, lng: 44.70949873228474 },
      { lat: 31.190008653278365, lng: 41.889980910007836 },
      { lat: 31.889991766887935, lng: 40.399994337736246 },
      { lat: 32.16100881604267, lng: 39.19546837744497 },
      { lat: 33.378686428352225, lng: 38.792340529136084 },
      { lat: 34.41937226006212, lng: 41.006158888519934 },
      { lat: 35.628316555314356, lng: 41.383965285005814 },
      { lat: 36.35881460219227, lng: 41.289707472505455 },
      { lat: 36.605853786763575, lng: 41.83706424334096 },
      { lat: 37.2298725449041, lng: 42.34959109881177 },
      { lat: 37.385263576805755, lng: 42.77912560402182 },
      { lat: 37.25622752537295, lng: 43.9422587420473 },
      { lat: 37.0015143906063, lng: 44.29345177590286 },
      { lat: 37.170444647768434, lng: 44.772699008977696 },
      { lat: 35.97754588474282, lng: 45.4206181170532 },
    ],
  ],
  IR: [
    [
      { lat: 37.19891836196126, lng: 53.92159793479556 },
      { lat: 37.392420762678185, lng: 54.800303989486565 },
      { lat: 37.96411713312317, lng: 55.51157840355191 },
      { lat: 37.93512665460742, lng: 56.18037479027333 },
      { lat: 38.121394354803485, lng: 56.61936608259282 },
      { lat: 38.02922943781094, lng: 57.33043379092898 },
      { lat: 37.522309475243794, lng: 58.4361544126782 },
      { lat: 37.412987982730336, lng: 59.23476199731681 },
      { lat: 36.52738312432837, lng: 60.37763797388387 },
      { lat: 36.49159719496624, lng: 61.123070509694145 },
      { lat: 35.650072333309225, lng: 61.21081709172574 },
      { lat: 34.40410187431986, lng: 60.80319339380745 },
      { lat: 33.676446031218006, lng: 60.52842980331158 },
      { lat: 33.52883230237625, lng: 60.963700392506006 },
      { lat: 32.98126882581157, lng: 60.536077915290775 },
      { lat: 32.18291962333443, lng: 60.863654819588966 },
      { lat: 31.548074652628753, lng: 60.94194461451113 },
      { lat: 31.37950613049267, lng: 61.699314406180825 },
      { lat: 30.735850328081234, lng: 61.781221551363444 },
      { lat: 29.829238999952604, lng: 60.874248488208785 },
      { lat: 29.303276272085924, lng: 61.36930870956494 },
      { lat: 28.699333807890795, lng: 61.77186811711863 },
      { lat: 28.25964488373539, lng: 62.72783043808598 },
      { lat: 27.378923448184985, lng: 62.755425652929866 },
      { lat: 27.21704702403071, lng: 63.233897739520295 },
      { lat: 26.756532497661667, lng: 63.31663170761959 },
      { lat: 26.239974880472104, lng: 61.87418745305655 },
      { lat: 25.0782370061185, lng: 61.49736290878419 },
      { lat: 25.380156561783778, lng: 59.61613406763084 },
      { lat: 25.60996165618573, lng: 58.5257613462723 },
      { lat: 25.73990204518364, lng: 57.397251417882394 },
      { lat: 26.96610626882136, lng: 56.970765822177555 },
      { lat: 27.143304755150197, lng: 56.492138706290206 },
      { lat: 26.96463349050104, lng: 55.72371015811006 },
      { lat: 26.480657863871514, lng: 54.71508955263727 },
      { lat: 26.81236888275305, lng: 53.49309695823135 },
      { lat: 27.580849107365495, lng: 52.48359785340961 },
      { lat: 27.865689602158298, lng: 51.52076256694741 },
      { lat: 28.814520575469384, lng: 50.85294803243954 },
      { lat: 30.147772528599717, lng: 50.115008579311585 },
      { lat: 29.985715236932407, lng: 49.576850213423995 },
      { lat: 30.317090359004037, lng: 48.94133344909855 },
      { lat: 29.926778265903522, lng: 48.567971225789755 },
      { lat: 30.452456773392598, lng: 48.0145683123761 },
      { lat: 30.985137437457244, lng: 48.004698113808324 },
      { lat: 30.98485321707963, lng: 47.68528608581227 },
      { lat: 31.70917593029867, lng: 47.8492037290421 },
      { lat: 32.469155381799105, lng: 47.3346614927119 },
      { lat: 33.017287299119005, lng: 46.10936160663932 },
      { lat: 33.967797756479584, lng: 45.41669070819904 },
      { lat: 34.748137722303014, lng: 45.64845950702809 },
      { lat: 35.09325877536429, lng: 46.15178795755093 },
      { lat: 35.67738332777549, lng: 46.0763403664048 },
      { lat: 35.97754588474282, lng: 45.4206181170532 },
      { lat: 37.17045, lng: 44.77267 },
      { lat: 37.97158437758935, lng: 44.22575564960053 },
      { lat: 38.28128123631454, lng: 44.421402622257546 },
      { lat: 39.4281362981681, lng: 44.10922529478234 },
      { lat: 39.71300263117705, lng: 44.79398969908195 },
      { lat: 39.33576467544637, lng: 44.95268802265031 },
      { lat: 38.87413910578306, lng: 45.45772179543877 },
      { lat: 38.74120148371222, lng: 46.14362308124881 },
      { lat: 38.770605373686294, lng: 46.50571984231797 },
      { lat: 39.508363959301214, lng: 47.685079380083096 },
      { lat: 39.58223541926246, lng: 48.06009524922524 },
      { lat: 39.28876496027691, lng: 48.35552941263788 },
      { lat: 38.79401479751452, lng: 48.01074425638648 },
      { lat: 38.27037750910097, lng: 48.63437544128481 },
      { lat: 38.32024526626262, lng: 48.88324913920249 },
      { lat: 37.58287425388988, lng: 49.19961225769334 },
      { lat: 37.37456655532134, lng: 50.14777143738462 },
      { lat: 36.8728142359834, lng: 50.84235436381971 },
      { lat: 36.7004216578577, lng: 52.264024692601424 },
      { lat: 36.965030829408235, lng: 53.82578982932642 },
      { lat: 37.19891836196126, lng: 53.92159793479556 },
    ],
  ],
  IS: [
    [
      { lat: 66.45589223903141, lng: -14.508695441129236 },
      { lat: 65.8087482774403, lng: -14.739637417041605 },
      { lat: 65.12667104761987, lng: -13.60973222497981 },
      { lat: 64.36408193628868, lng: -14.909833746794902 },
      { lat: 63.67874909123385, lng: -17.794438035543422 },
      { lat: 63.49638296167582, lng: -18.656245896874992 },
      { lat: 63.64363495549153, lng: -19.97275468594276 },
      { lat: 63.960178941495386, lng: -22.762971971110158 },
      { lat: 64.40211579045551, lng: -21.778484259517683 },
      { lat: 64.89112986923348, lng: -23.95504391121911 },
      { lat: 65.0849681667603, lng: -22.184402635170358 },
      { lat: 65.37859365504272, lng: -22.227423265053332 },
      { lat: 65.61118927678847, lng: -24.326184047939336 },
      { lat: 66.26251902939522, lng: -23.65051469572309 },
      { lat: 66.41046865504687, lng: -22.134922451250883 },
      { lat: 65.73211212835143, lng: -20.57628373867955 },
      { lat: 66.27660085719477, lng: -19.05684160000159 },
      { lat: 65.99385325790978, lng: -17.79862382655905 },
      { lat: 66.52679230413587, lng: -16.167818976292125 },
      { lat: 66.45589223903141, lng: -14.508695441129236 },
    ],
  ],
  IT: [
    [
      { lat: 38.23115509699147, lng: 15.520376010813834 },
      { lat: 37.44404551853782, lng: 15.160242954171736 },
      { lat: 37.1342194687318, lng: 15.309897902089006 },
      { lat: 36.6199872909954, lng: 15.099988234119449 },
      { lat: 36.996630967754754, lng: 14.335228712632016 },
      { lat: 37.10453135838019, lng: 13.826732618879928 },
      { lat: 37.61294993748381, lng: 12.431003859108813 },
      { lat: 38.12638113051968, lng: 12.570943637755136 },
      { lat: 38.03496552179536, lng: 13.741156447004585 },
      { lat: 38.143873602850505, lng: 14.76124922044616 },
      { lat: 38.23115509699147, lng: 15.520376010813834 },
    ],
    [
      { lat: 41.20999136002422, lng: 9.210011834356266 },
      { lat: 40.5000088567661, lng: 9.809975213264977 },
      { lat: 39.177376410471794, lng: 9.669518670295673 },
      { lat: 39.240473334300134, lng: 9.21481774255949 },
      { lat: 38.90661774347847, lng: 8.80693566247973 },
      { lat: 39.17184703221662, lng: 8.428302443077115 },
      { lat: 40.378310858718805, lng: 8.38825320805094 },
      { lat: 40.95000722916379, lng: 8.15999840661766 },
      { lat: 40.89998444270523, lng: 8.709990675500109 },
      { lat: 41.20999136002422, lng: 9.210011834356266 },
    ],
    [
      { lat: 46.76755910906987, lng: 12.376485223040843 },
      { lat: 46.50930613869119, lng: 13.806475457421556 },
      { lat: 46.016778062517375, lng: 13.698109978905478 },
      { lat: 45.591015936864665, lng: 13.937630242578335 },
      { lat: 45.73669179949541, lng: 13.141606479554298 },
      { lat: 45.38177806251485, lng: 12.328581170306306 },
      { lat: 44.88537425391908, lng: 12.383874952858605 },
      { lat: 44.600482082694015, lng: 12.261453484759159 },
      { lat: 44.091365871754476, lng: 12.589237094786483 },
      { lat: 43.5877273626379, lng: 13.526905958722494 },
      { lat: 42.76100779883248, lng: 14.029820997787027 },
      { lat: 41.955139675456905, lng: 15.142569614327956 },
      { lat: 41.96131500911574, lng: 15.926191033601896 },
      { lat: 41.74029490820342, lng: 16.169897088290412 },
      { lat: 41.5410822617182, lng: 15.889345737377797 },
      { lat: 41.179605617836586, lng: 16.785001661860576 },
      { lat: 40.87714345963224, lng: 17.519168735431208 },
      { lat: 40.35562490494266, lng: 18.376687452882575 },
      { lat: 40.168866278639825, lng: 18.4802470231954 },
      { lat: 39.81077444107325, lng: 18.293385044028096 },
      { lat: 40.2776710068303, lng: 17.738380161213286 },
      { lat: 40.44223460546385, lng: 16.869595981522338 },
      { lat: 39.79540070246648, lng: 16.448743116937322 },
      { lat: 39.42469981542072, lng: 17.1714896989715 },
      { lat: 38.9028712021373, lng: 17.05284061042934 },
      { lat: 38.8435724960824, lng: 16.635088331781844 },
      { lat: 37.98589874933418, lng: 16.100960727613057 },
      { lat: 37.90884918878703, lng: 15.684086948314501 },
      { lat: 38.214592800441864, lng: 15.687962680736321 },
      { lat: 38.750942491199226, lng: 15.891981235424707 },
      { lat: 38.96454702407769, lng: 16.109332309644312 },
      { lat: 39.544072374014945, lng: 15.718813510814641 },
      { lat: 40.04835683853517, lng: 15.413612501698822 },
      { lat: 40.17294871679093, lng: 14.998495721098237 },
      { lat: 40.604550279292624, lng: 14.70326826341477 },
      { lat: 40.78634796809544, lng: 14.060671827865264 },
      { lat: 41.188287258461656, lng: 13.627985060285397 },
      { lat: 41.25308950455562, lng: 12.88808190273042 },
      { lat: 41.70453481705741, lng: 12.10668257004491 },
      { lat: 42.35542531998967, lng: 11.191906365614187 },
      { lat: 42.931462510747224, lng: 10.511947869517797 },
      { lat: 43.920006822274615, lng: 10.200028924204048 },
      { lat: 44.03627879493132, lng: 9.702488234097814 },
      { lat: 44.36633616797954, lng: 8.88894616052687 },
      { lat: 44.23122813575242, lng: 8.428560825238577 },
      { lat: 43.76714793555524, lng: 7.850766635783201 },
      { lat: 43.69384491634918, lng: 7.435184767291843 },
      { lat: 44.12790110938482, lng: 7.549596388386163 },
      { lat: 44.25476675066139, lng: 7.007562290076663 },
      { lat: 45.02851797136759, lng: 6.749955275101711 },
      { lat: 45.333098863295874, lng: 7.096652459347837 },
      { lat: 45.70857982032867, lng: 6.802355177445662 },
      { lat: 45.99114655210067, lng: 6.843592970414562 },
      { lat: 45.77694774025076, lng: 7.273850945676685 },
      { lat: 45.82449005795928, lng: 7.755992058959833 },
      { lat: 46.163642483090854, lng: 8.31662967289438 },
      { lat: 46.00515086525175, lng: 8.489952426801295 },
      { lat: 46.036931871111165, lng: 8.966305779667834 },
      { lat: 46.44021474871698, lng: 9.182881707403112 },
      { lat: 46.31489940040919, lng: 9.922836541390353 },
      { lat: 46.483571275409844, lng: 10.363378126678668 },
      { lat: 46.893546250997446, lng: 10.442701450246602 },
      { lat: 46.7513585475464, lng: 11.048555942436508 },
      { lat: 46.94157949481274, lng: 11.164827915093326 },
      { lat: 47.11539317482644, lng: 12.153088006243081 },
      { lat: 46.76755910906987, lng: 12.376485223040843 },
    ],
  ],
  JM: [
    [
      { lat: 18.254248204426, lng: -78.336539567826 },
      { lat: 18.401633127287, lng: -78.292553195603 },
      { lat: 18.472609554815, lng: -78.183833169514 },
      { lat: 18.54110035038, lng: -77.8556092774 },
      { lat: 18.493252147997, lng: -77.325084274463 },
      { lat: 18.372098511156, lng: -76.799381513961 },
      { lat: 18.151112237109, lng: -76.331974659964 },
      { lat: 18.115473403176, lng: -76.298812230869 },
      { lat: 17.952533603667, lng: -76.209302252611 },
      { lat: 17.918224498105, lng: -76.203371865033 },
      { lat: 17.875853875118, lng: -76.277672326191 },
      { lat: 17.717800838096, lng: -77.285894473754 },
      { lat: 17.832281703451, lng: -77.69800867275 },
      { lat: 18.043160471788, lng: -77.997386365077 },
      { lat: 18.149460923915, lng: -78.185480558988 },
      { lat: 18.184502615477, lng: -78.2649871752 },
      { lat: 18.254248204426, lng: -78.336539567826 },
    ],
  ],
  JO: [
    [
      { lat: 32.393992011030576, lng: 35.54566531753454 },
      { lat: 32.709192409794866, lng: 35.71991824722275 },
      { lat: 32.312937526980775, lng: 36.834062127435544 },
      { lat: 33.378686428352225, lng: 38.792340529136084 },
      { lat: 32.16100881604267, lng: 39.19546837744497 },
      { lat: 32.01021698661498, lng: 39.00488569515255 },
      { lat: 31.508412990844743, lng: 37.00216556168101 },
      { lat: 30.50849986421313, lng: 37.998848911294374 },
      { lat: 30.3386652694859, lng: 37.66811974462638 },
      { lat: 30.003776150018403, lng: 37.503581984209035 },
      { lat: 29.86528331147619, lng: 36.74052778498725 },
      { lat: 29.5052536076987, lng: 36.50121422704358 },
      { lat: 29.197494615184457, lng: 36.06894087092206 },
      { lat: 29.35655467377884, lng: 34.95603722508426 },
      { lat: 29.501326198844524, lng: 34.92260257339142 },
      { lat: 31.100065822874356, lng: 35.420918409981965 },
      { lat: 31.48908600516758, lng: 35.397560662586045 },
      { lat: 31.78250478772084, lng: 35.5452519060762 },
      { lat: 32.393992011030576, lng: 35.54566531753454 },
    ],
  ],
  JP: [
    [
      { lat: 44.17409983985373, lng: 143.9101619813795 },
      { lat: 43.960882880217525, lng: 144.61342654843963 },
      { lat: 44.38473297787544, lng: 145.3208252300831 },
      { lat: 43.262088324550604, lng: 145.54313724180278 },
      { lat: 42.98835826270056, lng: 144.0596618999999 },
      { lat: 41.9952147486992, lng: 143.18384972551732 },
      { lat: 42.67879059505608, lng: 141.61149092017249 },
      { lat: 41.58459381770799, lng: 141.06728641170665 },
      { lat: 41.569555975911044, lng: 139.95510623592108 },
      { lat: 42.5637588567744, lng: 139.81754357315995 },
      { lat: 43.33327261003265, lng: 140.31208703019323 },
      { lat: 43.388824774746496, lng: 141.38054894426003 },
      { lat: 44.77212535255148, lng: 141.67195234595394 },
      { lat: 45.55148346616135, lng: 141.967644891528 },
      { lat: 44.510358384776964, lng: 143.14287031470982 },
      { lat: 44.17409983985373, lng: 143.9101619813795 },
    ],
    [
      { lat: 34.14923371025642, lng: 134.63842817600388 },
      { lat: 33.80633474378368, lng: 134.7663790223585 },
      { lat: 33.20117788342964, lng: 134.20341596897086 },
      { lat: 33.5219851750976, lng: 133.7929500672765 },
      { lat: 33.28957042086495, lng: 133.28026818250888 },
      { lat: 32.70456736910478, lng: 133.01485802625788 },
      { lat: 32.98938202568137, lng: 132.3631148621927 },
      { lat: 33.46364248304007, lng: 132.37117638563018 },
      { lat: 34.06029857028204, lng: 132.9243725933148 },
      { lat: 33.9446208765967, lng: 133.49296837782222 },
      { lat: 34.36493113864262, lng: 133.90410607313638 },
      { lat: 34.14923371025642, lng: 134.63842817600388 },
    ],
    [
      { lat: 37.14207428644016, lng: 140.9763875673053 },
      { lat: 36.343983466124534, lng: 140.59976972876214 },
      { lat: 35.84287710219024, lng: 140.77407433488264 },
      { lat: 35.13811391859365, lng: 140.25327925024513 },
      { lat: 34.66760000257611, lng: 138.97552778539622 },
      { lat: 34.60628591566186, lng: 137.21759891169123 },
      { lat: 33.46480520276663, lng: 135.7929830262689 },
      { lat: 33.84907115328906, lng: 135.12098270074543 },
      { lat: 34.59654490817482, lng: 135.07943484918272 },
      { lat: 34.37593821872076, lng: 133.340316196832 },
      { lat: 33.90493337659652, lng: 132.15677086805132 },
      { lat: 33.88576142021628, lng: 130.98614464734348 },
      { lat: 33.149992377244615, lng: 132.00003624891005 },
      { lat: 31.450354519164843, lng: 131.33279015515737 },
      { lat: 31.029579169228242, lng: 130.68631798718596 },
      { lat: 31.418237616495418, lng: 130.20241987520498 },
      { lat: 32.319474595665724, lng: 130.44767622286216 },
      { lat: 32.61030955660439, lng: 129.8146916037189 },
      { lat: 33.29605581311759, lng: 129.40846316947258 },
      { lat: 33.6041507024417, lng: 130.35393517468466 },
      { lat: 34.232742824840045, lng: 130.87845096244715 },
      { lat: 34.74971385348791, lng: 131.88422936414392 },
      { lat: 35.43339305270942, lng: 132.61767296766251 },
      { lat: 35.73161774346582, lng: 134.6083008159778 },
      { lat: 35.527134100886826, lng: 135.67753787652893 },
      { lat: 37.30498423924038, lng: 136.72383060114245 },
      { lat: 36.827390651998826, lng: 137.3906116070045 },
      { lat: 37.82748464614346, lng: 138.85760216690628 },
      { lat: 38.21596222589764, lng: 139.4264046571429 },
      { lat: 39.438807481436385, lng: 140.0547900738121 },
      { lat: 40.563312486323696, lng: 139.88337934789988 },
      { lat: 41.19500519465956, lng: 140.30578250545372 },
      { lat: 41.37855988216029, lng: 141.3689734234267 },
      { lat: 39.99161611587868, lng: 141.91426313697048 },
      { lat: 39.180864569651504, lng: 141.884600864835 },
      { lat: 38.17400096287658, lng: 140.9594893739458 },
      { lat: 37.14207428644016, lng: 140.9763875673053 },
    ],
  ],
  KE: [
    [
      { lat: -0.85829, lng: 40.993 },
      { lat: -1.68325, lng: 41.58513 },
      { lat: -2.08255, lng: 40.88477 },
      { lat: -2.49979, lng: 40.63785 },
      { lat: -2.57309, lng: 40.26304 },
      { lat: -3.27768, lng: 40.12119 },
      { lat: -3.68116, lng: 39.80006 },
      { lat: -4.34653, lng: 39.60489 },
      { lat: -4.67677, lng: 39.20222 },
      { lat: -3.67712, lng: 37.7669 },
      { lat: -3.09699, lng: 37.69869 },
      { lat: -1.05982, lng: 34.07262 },
      { lat: -0.95, lng: 33.90371119710453 },
      { lat: 0.109813537861896, lng: 33.89356896966694 },
      { lat: 0.515, lng: 34.18 },
      { lat: 1.17694, lng: 34.6721 },
      { lat: 1.90584, lng: 35.03599 },
      { lat: 3.05374, lng: 34.59607 },
      { lat: 3.5556, lng: 34.47913 },
      { lat: 4.249884947362048, lng: 34.005 },
      { lat: 4.847122742081988, lng: 34.62019626785388 },
      { lat: 5.506, lng: 35.298007118232974 },
      { lat: 5.338232082790797, lng: 35.817447662353516 },
      { lat: 4.77696566346189, lng: 35.817447662353516 },
      { lat: 4.447864127672769, lng: 36.159078632855646 },
      { lat: 4.447864127672769, lng: 36.85509323800812 },
      { lat: 3.598605, lng: 38.120915 },
      { lat: 3.58851, lng: 38.43697 },
      { lat: 3.61607, lng: 38.67114 },
      { lat: 3.50074, lng: 38.89251 },
      { lat: 3.42206, lng: 39.55938425876585 },
      { lat: 3.83879, lng: 39.85494 },
      { lat: 4.25702, lng: 40.76848 },
      { lat: 3.91909, lng: 41.1718 },
      { lat: 3.918911920483727, lng: 41.85508309264397 },
      { lat: 2.78452, lng: 40.98105 },
      { lat: -0.85829, lng: 40.993 },
    ],
  ],
  KG: [
    [
      { lat: 42.26615428320549, lng: 70.96231489449914 },
      { lat: 42.70429291439214, lng: 71.18628055205212 },
      { lat: 42.84539541276509, lng: 71.8446382994506 },
      { lat: 42.50089447689132, lng: 73.48975752146237 },
      { lat: 43.09127187760982, lng: 73.64530358266092 },
      { lat: 43.29833934180337, lng: 74.21286583852256 },
      { lat: 42.87789988867668, lng: 75.636964959622 },
      { lat: 42.98802236589067, lng: 76.00035363149846 },
      { lat: 42.96068553320826, lng: 77.6583919615832 },
      { lat: 42.85609243424952, lng: 79.14217736197978 },
      { lat: 42.49668284765953, lng: 79.64364546094012 },
      { lat: 42.34999929459906, lng: 80.2599902688853 },
      { lat: 42.12394074153825, lng: 80.11943037305139 },
      { lat: 41.58224254003869, lng: 78.54366092317531 },
      { lat: 41.18531586360481, lng: 78.18719689322597 },
      { lat: 41.06648590754964, lng: 76.90448449087708 },
      { lat: 40.42794607193512, lng: 76.52636803579745 },
      { lat: 40.56207225194867, lng: 75.4678279967307 },
      { lat: 40.36642527929163, lng: 74.77686242055606 },
      { lat: 39.893973497063186, lng: 73.8222436868283 },
      { lat: 39.660008449861735, lng: 73.96001305531843 },
      { lat: 39.4312368841056, lng: 73.6753792662548 },
      { lat: 39.27946320246437, lng: 71.784693637992 },
      { lat: 39.6041979029865, lng: 70.54916181832562 },
      { lat: 39.5266832545487, lng: 69.46488691597753 },
      { lat: 40.10321137141298, lng: 69.55960981636852 },
      { lat: 39.93575389257117, lng: 70.64801883329997 },
      { lat: 40.24436554621823, lng: 71.01419803252017 },
      { lat: 40.14584442805378, lng: 71.77487511585656 },
      { lat: 40.866033026689465, lng: 73.05541710804917 },
      { lat: 41.392900092121266, lng: 71.87011478057047 },
      { lat: 41.14358714452912, lng: 71.1578585142916 },
      { lat: 41.51999827734314, lng: 70.42002241402821 },
      { lat: 42.16771067968946, lng: 71.25924767444822 },
      { lat: 42.26615428320549, lng: 70.96231489449914 },
    ],
  ],
  KH: [
    [
      { lat: 10.632555446815928, lng: 103.4972799011397 },
      { lat: 11.153660590047165, lng: 103.09068973186724 },
      { lat: 12.186594956913282, lng: 102.5849324890267 },
      { lat: 13.394247341358223, lng: 102.348099399833 },
      { lat: 14.225721136934467, lng: 102.98842207236163 },
      { lat: 14.416743068901367, lng: 104.28141808473661 },
      { lat: 14.273211778210694, lng: 105.21877689007887 },
      { lat: 13.881091009979954, lng: 106.04394616091552 },
      { lat: 14.570583807834282, lng: 106.49637332563087 },
      { lat: 14.202440904186972, lng: 107.38272749230109 },
      { lat: 13.535530707244206, lng: 107.61454796756243 },
      { lat: 12.337205918827946, lng: 107.49140302941089 },
      { lat: 11.567614650921227, lng: 105.81052371625313 },
      { lat: 10.961811835163587, lng: 106.24967003786946 },
      { lat: 10.889309800658097, lng: 105.19991499229235 },
      { lat: 10.48654368737523, lng: 104.33433475140347 },
      { lat: 10.632555446815928, lng: 103.4972799011397 },
    ],
  ],
  KI: [
    [
      { lat: 1.8908125193492, lng: -157.58825651142 },
      { lat: 1.9195831555659, lng: -157.57908685142 },
      { lat: 2.0443057771937, lng: -157.46219988934 },
      { lat: 2.0426371001532, lng: -157.434413284 },
      { lat: 2.0012775540594, lng: -157.35079358237 },
      { lat: 1.7926162582652, lng: -157.175137306 },
      { lat: 1.7533231034778, lng: -157.16129706616 },
      { lat: 1.739513947001, lng: -157.16201298277 },
      { lat: 1.7381313027135, lng: -157.16270116139 },
      { lat: 1.7290766728051, lng: -157.19414517314 },
      { lat: 1.7631391878089, lng: -157.43014429617 },
      { lat: 1.797425165419, lng: -157.49692131365 },
      { lat: 1.8574405047954, lng: -157.56832361554 },
      { lat: 1.8908125193492, lng: -157.58825651142 },
    ],
    [
      { lat: -0.84202920652656, lng: 169.5110665334 },
      { lat: -0.8391416916049, lng: 169.51295819413 },
      { lat: -0.83217300599979, lng: 169.52314236841 },
      { lat: -0.82974316897053, lng: 169.53411347405 },
      { lat: -0.83031926906447, lng: 169.53731056288 },
      { lat: -0.8320846557062, lng: 169.54003759025 },
      { lat: -0.84563225360703, lng: 169.54930669172 },
      { lat: -0.89061948940168, lng: 169.55970125309 },
      { lat: -0.89663810174322, lng: 169.55106241227 },
      { lat: -0.89530932849946, lng: 169.54187956152 },
      { lat: -0.88575937797553, lng: 169.52562909883 },
      { lat: -0.87581184989158, lng: 169.51818086509 },
      { lat: -0.84202920652656, lng: 169.5110665334 },
    ],
    [
      { lat: -11.453117349595, lng: -151.83043144728 },
      { lat: -11.448111301575, lng: -151.83184032391 },
      { lat: -11.409548273233, lng: -151.82820150659 },
      { lat: -11.400235958593, lng: -151.81816927604 },
      { lat: -11.390699509858, lng: -151.79386804724 },
      { lat: -11.390803402121, lng: -151.79139368393 },
      { lat: -11.391898165764, lng: -151.78917225196 },
      { lat: -11.407042367607, lng: -151.77649427917 },
      { lat: -11.43215532403, lng: -151.76840828751 },
      { lat: -11.451951517748, lng: -151.77034025257 },
      { lat: -11.461665376287, lng: -151.77555590415 },
      { lat: -11.468815207754, lng: -151.78438526182 },
      { lat: -11.469634688379, lng: -151.78732222909 },
      { lat: -11.46669473492, lng: -151.80792108021 },
      { lat: -11.453117349595, lng: -151.83043144728 },
    ],
    [
      { lat: 3.904783249601, lng: -159.40972285774 },
      { lat: 3.9223090529382, lng: -159.38434936211 },
      { lat: 3.9331710717822, lng: -159.33671956682 },
      { lat: 3.9305463784261, lng: -159.32307176904 },
      { lat: 3.9140465165622, lng: -159.2991288473 },
      { lat: 3.8340772186554, lng: -159.24190098749 },
      { lat: 3.7992996943542, lng: -159.24833093877 },
      { lat: 3.7865501022906, lng: -159.25589859914 },
      { lat: 3.780448350351, lng: -159.27813681667 },
      { lat: 3.7852379486478, lng: -159.31878250971 },
      { lat: 3.8192741235195, lng: -159.37847332663 },
      { lat: 3.8533528284049, lng: -159.40442229075 },
      { lat: 3.8851927087289, lng: -159.4131326907 },
      { lat: 3.904783249601, lng: -159.40972285774 },
    ],
    [
      { lat: -4.4913547728415, lng: -172.225680784 },
      { lat: -4.2849972175156, lng: -171.97291902012 },
      { lat: -4.1239638166308, lng: -171.81302658968 },
      { lat: -3.9333127344876, lng: -171.68994324235 },
      { lat: -3.7213076060068, lng: -171.60900393787 },
      { lat: -3.4971376419887, lng: -171.57371693255 },
      { lat: -3.2705193293371, lng: -171.58561171642 },
      { lat: -3.0512752768781, lng: -171.64417271858 },
      { lat: -2.8433039886872, lng: -171.72371373575 },
      { lat: -2.7756551252333, lng: -171.72736883917 },
      { lat: -2.7503578230668, lng: -171.67620230671 },
      { lat: -2.7779174236306, lng: -171.60762907928 },
      { lat: -3.1118333644632, lng: -171.10593006549 },
      { lat: -3.1506021357294, lng: -171.09955434354 },
      { lat: -3.5005299735136, lng: -171.23940849475 },
      { lat: -3.719229426538, lng: -171.30129564867 },
      { lat: -3.9460086741176, lng: -171.31648259836 },
      { lat: -4.1710071861061, lng: -171.28430900396 },
      { lat: -4.3989840837815, lng: -171.2269505305 },
      { lat: -4.4312327298779, lng: -171.24693234463 },
      { lat: -4.475163736932, lng: -171.33904829723 },
      { lat: -4.4559351914922, lng: -171.5692777806 },
      { lat: -4.4666002820268, lng: -171.83687499778 },
      { lat: -4.5217317293789, lng: -172.17211751524 },
      { lat: -4.5032262035759, lng: -172.2212254228 },
      { lat: -4.4913547728415, lng: -172.225680784 },
    ],
    [
      { lat: -5.6096821327281, lng: -155.92633795315 },
      { lat: -5.576358020256, lng: -155.87336577109 },
      { lat: -5.5730554826988, lng: -155.86156434575 },
      { lat: -5.6204986279728, lng: -155.86594062521 },
      { lat: -5.6163341436066, lng: -155.9226820903 },
      { lat: -5.6148740542186, lng: -155.92453216538 },
      { lat: -5.6096821327281, lng: -155.92633795315 },
    ],
    [
      { lat: -4.6579799725087, lng: -174.5567705557 },
      { lat: -4.6495088943917, lng: -174.55121324575 },
      { lat: -4.64814759563, lng: -174.5491121728 },
      { lat: -4.6477496954617, lng: -174.54664046948 },
      { lat: -4.6553200519001, lng: -174.5176793707 },
      { lat: -4.6822092408228, lng: -174.48021599612 },
      { lat: -4.6976811084352, lng: -174.48319083222 },
      { lat: -4.7068308328117, lng: -174.48914336855 },
      { lat: -4.7090697751455, lng: -174.49240989326 },
      { lat: -4.7109517775953, lng: -174.50149381747 },
      { lat: -4.710046899761, lng: -174.50618847156 },
      { lat: -4.6767446595905, lng: -174.55609576324 },
      { lat: -4.6665827264734, lng: -174.55832475811 },
      { lat: -4.6579799725087, lng: -174.5567705557 },
    ],
    [
      { lat: 2.107308953509, lng: 172.93971328836 },
      { lat: 2.0766645387511, lng: 172.95005404254 },
      { lat: 1.7660554646265, lng: 173.09023614547 },
      { lat: 1.3407209692702, lng: 173.17696911715 },
      { lat: 0.84498572467523, lng: 173.07333707851 },
      { lat: 0.84495729734813, lng: 173.04419643518 },
      { lat: 0.88080759995267, lng: 172.98384043268 },
      { lat: 0.96149080678045, lng: 172.94285012385 },
      { lat: 1.7882921559626, lng: 172.90064519722 },
      { lat: 2.107308953509, lng: 172.93971328836 },
    ],
    [
      { lat: -4.1077131538664, lng: -155.01544770479 },
      { lat: -4.1019516196885, lng: -155.01678171337 },
      { lat: -4.0867743791214, lng: -155.01601132615 },
      { lat: -4.0298470903908, lng: -154.98075432771 },
      { lat: -4.0260019653884, lng: -154.93989043128 },
      { lat: -4.0322487247753, lng: -154.93707183606 },
      { lat: -4.0946252867407, lng: -154.98521068556 },
      { lat: -4.1077131538664, lng: -155.01544770479 },
    ],
    [
      { lat: -0.58558782500303, lng: 174.37261104367 },
      { lat: -0.58550945526135, lng: 174.38311175207 },
      { lat: -0.61036811355557, lng: 174.43452299326 },
      { lat: -0.79457059505544, lng: 174.60879124504 },
      { lat: -1.1312558708644, lng: 174.76543643684 },
      { lat: -1.2555963609739, lng: 174.76725004938 },
      { lat: -1.2669485747575, lng: 174.7404401564 },
      { lat: -1.1165963853556, lng: 174.61017731018 },
      { lat: -0.78599035121037, lng: 174.42606748469 },
      { lat: -0.58558782500303, lng: 174.37261104367 },
    ],
    [
      { lat: 3.028627189751, lng: 172.73684142798 },
      { lat: 3.091151587364, lng: 172.80315480834 },
      { lat: 3.1500600925623, lng: 172.90806072892 },
      { lat: 3.1077164774298, lng: 172.96485006168 },
      { lat: 3.0645489723881, lng: 172.98268372177 },
      { lat: 2.9131650370633, lng: 172.9249995156 },
      { lat: 2.714850204881, lng: 172.87106113801 },
      { lat: 2.7327230066734, lng: 172.86512659018 },
      { lat: 3.028627189751, lng: 172.73684142798 },
    ],
  ],
  KM: [
    [
      { lat: -11.380575406844, lng: 43.354258656988 },
      { lat: -11.420399233591, lng: 43.405602968876 },
      { lat: -11.533708718857, lng: 43.474616598922 },
      { lat: -11.699820613386, lng: 43.598908467987 },
      { lat: -11.83937394001, lng: 43.752422308562 },
      { lat: -11.947313061426, lng: 43.929596731908 },
      { lat: -12.019727636962, lng: 44.124013190753 },
      { lat: -12.053994283586, lng: 44.328628505794 },
      { lat: -12.060906938275, lng: 44.426354704124 },
      { lat: -12.079271313655, lng: 44.46908264024 },
      { lat: -12.088038631539, lng: 44.477483573707 },
      { lat: -12.22274920459, lng: 44.528359360471 },
      { lat: -12.311915999362, lng: 44.527516323002 },
      { lat: -12.341295747518, lng: 44.515106931081 },
      { lat: -12.353558029168, lng: 44.503000688938 },
      { lat: -12.363980907265, lng: 44.476865298174 },
      { lat: -12.377114795072, lng: 43.802106413532 },
      { lat: -12.358613258215, lng: 43.698707692039 },
      { lat: -12.088301768763, lng: 43.508184277322 },
      { lat: -11.962121749251, lng: 43.404251559096 },
      { lat: -11.813015312831, lng: 43.261371913927 },
      { lat: -11.751454316052, lng: 43.227952357225 },
      { lat: -11.670075983608, lng: 43.219403672055 },
      { lat: -11.481598414026, lng: 43.246117929167 },
      { lat: -11.408969310616, lng: 43.293919245451 },
      { lat: -11.380575406844, lng: 43.354258656988 },
    ],
  ],
  KN: [
    [
      { lat: 17.355045926286, lng: -62.83928690541 },
      { lat: 17.395555082421, lng: -62.827357980266 },
      { lat: 17.405887815826, lng: -62.806409589853 },
      { lat: 17.372180282856, lng: -62.72213343698 },
      { lat: 17.219148577435, lng: -62.556225528048 },
      { lat: 17.166789652593, lng: -62.528976499632 },
      { lat: 17.11723435528, lng: -62.526564910902 },
      { lat: 17.115438664258, lng: -62.528293257237 },
      { lat: 17.092921004547, lng: -62.581276898556 },
      { lat: 17.09291817049, lng: -62.583968105923 },
      { lat: 17.105446476247, lng: -62.613681261608 },
      { lat: 17.196255980621, lng: -62.711650768972 },
      { lat: 17.355045926286, lng: -62.83928690541 },
    ],
  ],
  KP: [
    [
      { lat: 42.39500946712528, lng: 130.6400159038524 },
      { lat: 42.22000722916885, lng: 130.78000735893113 },
      { lat: 42.28000356705971, lng: 130.40003055228902 },
      { lat: 41.94136790625105, lng: 129.96594852103726 },
      { lat: 41.60110443782523, lng: 129.66736209525482 },
      { lat: 40.88282786718433, lng: 129.70518924369247 },
      { lat: 40.66180776627199, lng: 129.18811486218 },
      { lat: 40.485436102859815, lng: 129.01039961152821 },
      { lat: 40.18984691015031, lng: 128.63336836152672 },
      { lat: 40.02541250259756, lng: 127.96741417858135 },
      { lat: 39.7568500839767, lng: 127.53343550019417 },
      { lat: 39.32393077245153, lng: 127.5021195822253 },
      { lat: 39.213472398427655, lng: 127.38543419811029 },
      { lat: 39.05089834243742, lng: 127.78334272675772 },
      { lat: 38.61224294692785, lng: 128.34971642467661 },
      { lat: 38.37039724380189, lng: 128.20574588431145 },
      { lat: 38.30453563084589, lng: 127.780035435091 },
      { lat: 38.2561148137884, lng: 127.07330854706737 },
      { lat: 37.80477285415118, lng: 126.68371992401892 },
      { lat: 37.84037791600028, lng: 126.23733890188176 },
      { lat: 37.74968577732804, lng: 126.17475874237624 },
      { lat: 37.940010077459014, lng: 125.6891036316972 },
      { lat: 37.75208873142962, lng: 125.56843916229569 },
      { lat: 37.669070542952724, lng: 125.2753304383362 },
      { lat: 37.85722443292744, lng: 125.24008711151315 },
      { lat: 37.94882090916478, lng: 124.98103315643398 },
      { lat: 38.10834605564979, lng: 124.71216067921938 },
      { lat: 38.54847422947968, lng: 124.98599409393398 },
      { lat: 38.66585724543067, lng: 125.2219486837787 },
      { lat: 38.84855927179859, lng: 125.13285851450752 },
      { lat: 39.387957872061165, lng: 125.3865897970606 },
      { lat: 39.5513845891842, lng: 125.3211157573468 },
      { lat: 39.66034434667162, lng: 124.7374821310424 },
      { lat: 39.928493353834156, lng: 124.26562462778531 },
      { lat: 40.56982371679245, lng: 125.07994184784063 },
      { lat: 41.10733612727637, lng: 126.18204511932943 },
      { lat: 41.81656932226619, lng: 126.86908328664985 },
      { lat: 41.50315176041597, lng: 127.34378299368302 },
      { lat: 41.46677155208249, lng: 128.20843305879066 },
      { lat: 41.99428457291795, lng: 128.0522152039723 },
      { lat: 42.42498179785456, lng: 129.59666873587952 },
      { lat: 42.985386867843786, lng: 129.99426720593323 },
      { lat: 42.39500946712528, lng: 130.6400159038524 },
    ],
  ],
  KR: [
    [
      { lat: 38.61224294692785, lng: 128.34971642467661 },
      { lat: 37.43239248305595, lng: 129.21291954968007 },
      { lat: 36.78418915460282, lng: 129.46044966035817 },
      { lat: 35.63214061130395, lng: 129.4683044780665 },
      { lat: 35.082484239231434, lng: 129.0913765809296 },
      { lat: 34.89037710218639, lng: 128.1858504578791 },
      { lat: 34.47567373304412, lng: 127.38651940318839 },
      { lat: 34.39004588473648, lng: 126.48574751190874 },
      { lat: 34.934560451795946, lng: 126.37391971242913 },
      { lat: 35.6845405136479, lng: 126.5592313986278 },
      { lat: 36.72548472751926, lng: 126.11739790253229 },
      { lat: 36.893924058574626, lng: 126.86014326386339 },
      { lat: 37.74968577732804, lng: 126.17475874237624 },
      { lat: 37.84037791600028, lng: 126.23733890188176 },
      { lat: 37.80477285415118, lng: 126.68371992401892 },
      { lat: 38.2561148137884, lng: 127.07330854706737 },
      { lat: 38.30453563084589, lng: 127.780035435091 },
      { lat: 38.37039724380189, lng: 128.20574588431145 },
      { lat: 38.61224294692785, lng: 128.34971642467661 },
    ],
  ],
  KW: [
    [
      { lat: 29.9758192001485, lng: 47.974519077349896 },
      { lat: 29.534476630159766, lng: 48.18318851094448 },
      { lat: 29.306299343375002, lng: 48.09394331237642 },
      { lat: 28.55200429942667, lng: 48.416094191283946 },
      { lat: 28.526062730416143, lng: 47.708850538937384 },
      { lat: 29.002519436147224, lng: 47.45982181172283 },
      { lat: 29.09902517345229, lng: 46.568713413281756 },
      { lat: 30.05906993257072, lng: 47.30262210469096 },
      { lat: 29.9758192001485, lng: 47.974519077349896 },
    ],
  ],
  KY: [
    [
      { lat: 19.417382820241254, lng: -441.47735595703125 },
      { lat: 19.2489223284628, lng: -441.4471435546875 },
      { lat: 19.238549860797406, lng: -441.12030029296875 },
      { lat: 19.329286698998818, lng: -441.04888916015625 },
      { lat: 19.396658607621543, lng: -441.0791015625 },
      { lat: 19.417382820241254, lng: -441.47735595703125 },
    ],
    [
      { lat: 19.665866618043196, lng: -440.1617431640625 },
      { lat: 19.629653250428277, lng: -440.09033203125 },
      { lat: 19.668452974374233, lng: -439.97222900390625 },
      { lat: 19.65034760400336, lng: -439.81842041015625 },
      { lat: 19.784795749944877, lng: -439.68658447265625 },
      { lat: 19.79254892024401, lng: -439.74700927734375 },
      { lat: 19.735683578629445, lng: -439.8870849609375 },
      { lat: 19.735683578629445, lng: -440.08209228515625 },
      { lat: 19.665866618043196, lng: -440.1617431640625 },
    ],
  ],
  KZ: [
    [
      { lat: 42.26615428320554, lng: 70.96231489449929 },
      { lat: 42.081307684897524, lng: 70.3889648782208 },
      { lat: 41.38424428971234, lng: 69.07002729683524 },
      { lat: 40.66868073176687, lng: 68.63248294462005 },
      { lat: 40.6623245305949, lng: 68.25989586779565 },
      { lat: 41.135990708982206, lng: 67.98585574735182 },
      { lat: 41.168443508461564, lng: 66.7140470722166 },
      { lat: 41.987644151368556, lng: 66.51064863471572 },
      { lat: 41.99464630794404, lng: 66.02339155463562 },
      { lat: 42.99766002051308, lng: 66.0980123228652 },
      { lat: 43.728080552742654, lng: 64.90082441595933 },
      { lat: 43.650074978198006, lng: 63.185786981056594 },
      { lat: 43.50447663021566, lng: 62.01330040878628 },
      { lat: 44.40581696225058, lng: 61.0583199400325 },
      { lat: 44.784036770194746, lng: 60.23997195825847 },
      { lat: 45.50001373959873, lng: 58.6899890480958 },
      { lat: 45.586804307632974, lng: 58.50312706892844 },
      { lat: 44.99585846615918, lng: 55.92891727074118 },
      { lat: 41.30864166926938, lng: 55.968191359283026 },
      { lat: 41.25985911718584, lng: 55.45525109235381 },
      { lat: 42.04397146256662, lng: 54.75534549339267 },
      { lat: 42.32410940202084, lng: 54.07941775901497 },
      { lat: 42.11603424739758, lng: 52.94429324729174 },
      { lat: 41.78331553808647, lng: 52.50245975119628 },
      { lat: 42.027150783855575, lng: 52.44633914572722 },
      { lat: 42.44389537207337, lng: 52.692112257707265 },
      { lat: 42.7922978785852, lng: 52.50142622255032 },
      { lat: 43.132974758469345, lng: 51.342427199108215 },
      { lat: 44.03103363705378, lng: 50.89129194520024 },
      { lat: 44.284015611338475, lng: 50.339129266161365 },
      { lat: 44.609835516938915, lng: 50.305642938036264 },
      { lat: 44.51485423438646, lng: 51.278503452363225 },
      { lat: 45.2459982366679, lng: 51.316899041556034 },
      { lat: 45.40839142514511, lng: 52.16738976421573 },
      { lat: 45.25904653582177, lng: 53.0408764992452 },
      { lat: 46.234645901059935, lng: 53.220865512917726 },
      { lat: 46.853006089864486, lng: 53.042736850807785 },
      { lat: 46.80463694923924, lng: 52.04202273947561 },
      { lat: 47.048704738953916, lng: 51.191945428274266 },
      { lat: 46.60898997658222, lng: 50.03408328634248 },
      { lat: 46.399330000000134, lng: 49.10116000000011 },
      { lat: 46.56103424741547, lng: 48.593241001180495 },
      { lat: 47.07562816017793, lng: 48.694733514201744 },
      { lat: 47.74375275327952, lng: 48.05725304544927 },
      { lat: 47.715847479841955, lng: 47.31523115417024 },
      { lat: 48.39415233010493, lng: 46.46644575377627 },
      { lat: 49.152038886097614, lng: 47.043671502476506 },
      { lat: 49.35600576435377, lng: 46.75159630716274 },
      { lat: 50.454698391311126, lng: 47.5494804217493 },
      { lat: 49.87475962991567, lng: 48.57784142435752 },
      { lat: 50.60512848571284, lng: 48.70238162618102 },
      { lat: 51.6927623561599, lng: 50.76664839051215 },
      { lat: 51.718652248738124, lng: 52.32872358583097 },
      { lat: 51.02623973245932, lng: 54.532878452376224 },
      { lat: 50.62171662047853, lng: 55.716940545479815 },
      { lat: 51.04355133727705, lng: 56.777961053296565 },
      { lat: 51.06365346943858, lng: 58.36329064314674 },
      { lat: 50.545442206415714, lng: 59.6422823423706 },
      { lat: 50.842194118851864, lng: 59.93280724471549 },
      { lat: 50.79907013610426, lng: 61.337424350840934 },
      { lat: 51.272658799843214, lng: 61.58800337102417 },
      { lat: 51.9604204372157, lng: 59.96753380721554 },
      { lat: 52.44754832621504, lng: 60.92726850774027 },
      { lat: 52.71998647725775, lng: 60.73999311711459 },
      { lat: 52.97999644633427, lng: 61.6999861998006 },
      { lat: 53.66499339457914, lng: 60.97806644068316 },
      { lat: 54.00626455343479, lng: 61.436591424409066 },
      { lat: 54.35422781027211, lng: 65.17853356309593 },
      { lat: 54.60126699484345, lng: 65.666875848254 },
      { lat: 54.97039175070432, lng: 68.16910037625883 },
      { lat: 55.38525014914353, lng: 69.06816694527288 },
      { lat: 55.169733588270105, lng: 70.86526655465514 },
      { lat: 54.133285224008254, lng: 71.18013105660941 },
      { lat: 54.376655381886735, lng: 72.22415001820218 },
      { lat: 54.035616766976595, lng: 73.5085160663844 },
      { lat: 53.489810289109755, lng: 73.42567874542043 },
      { lat: 53.54686107036008, lng: 74.38484500519007 },
      { lat: 54.49052440044193, lng: 76.89110029491343 },
      { lat: 54.177003485727134, lng: 76.52517947785473 },
      { lat: 53.404414984747575, lng: 77.80091556184425 },
      { lat: 50.86475088154725, lng: 80.03555952344169 },
      { lat: 51.38833649352847, lng: 80.56844689323549 },
      { lat: 50.81219594990637, lng: 81.94598554883993 },
      { lat: 51.069182847693924, lng: 83.38300377801238 },
      { lat: 50.88924551045358, lng: 83.93511478061885 },
      { lat: 50.311399644565824, lng: 84.41637739455308 },
      { lat: 50.11730296487763, lng: 85.11555952346203 },
      { lat: 49.69285858824816, lng: 85.54126997268247 },
      { lat: 49.82667470966817, lng: 86.82935672398963 },
      { lat: 49.21498078062916, lng: 87.35997033076268 },
      { lat: 48.54918162698061, lng: 86.59877648310339 },
      { lat: 48.45575063739698, lng: 85.7682328633083 },
      { lat: 47.45296946877312, lng: 85.72048383987072 },
      { lat: 47.00095571551611, lng: 85.16429039911338 },
      { lat: 47.330031236350855, lng: 83.18048383986047 },
      { lat: 45.539649563166506, lng: 82.45892581576913 },
      { lat: 45.31702749285324, lng: 81.94707075391813 },
      { lat: 44.91751699480466, lng: 79.96610639844141 },
      { lat: 43.18036204688104, lng: 80.86620649610137 },
      { lat: 42.92006785742694, lng: 80.1801501809943 },
      { lat: 42.349999294599115, lng: 80.25999026888536 },
      { lat: 42.496682847659656, lng: 79.64364546094015 },
      { lat: 42.856092434249604, lng: 79.1421773619798 },
      { lat: 42.960685533208334, lng: 77.6583919615832 },
      { lat: 42.98802236589063, lng: 76.00035363149857 },
      { lat: 42.87789988867678, lng: 75.6369649596221 },
      { lat: 43.29833934180351, lng: 74.21286583852259 },
      { lat: 43.09127187760987, lng: 73.64530358266092 },
      { lat: 42.50089447689129, lng: 73.48975752146237 },
      { lat: 42.845395412765185, lng: 71.84463829945065 },
      { lat: 42.70429291439223, lng: 71.18628055205227 },
      { lat: 42.26615428320554, lng: 70.96231489449929 },
    ],
  ],
  LA: [
    [
      { lat: 14.273211778210694, lng: 105.21877689007887 },
      { lat: 14.723933620660416, lng: 105.54433841351769 },
      { lat: 15.570316066952858, lng: 105.58903852745016 },
      { lat: 16.44186493577145, lng: 104.7793205098688 },
      { lat: 17.42885895433008, lng: 104.7169470560925 },
      { lat: 18.24095408779688, lng: 103.95647667848529 },
      { lat: 18.309632066312773, lng: 103.20019209189373 },
      { lat: 17.9616946476916, lng: 102.9987056823877 },
      { lat: 17.932781683824288, lng: 102.41300499879162 },
      { lat: 18.109101670804165, lng: 102.11359175009248 },
      { lat: 17.51249725999449, lng: 101.05954756063517 },
      { lat: 18.408928330961615, lng: 101.03593143107777 },
      { lat: 19.462584947176765, lng: 101.2820146016517 },
      { lat: 19.508344427971224, lng: 100.60629357300316 },
      { lat: 20.109237982661128, lng: 100.54888105672688 },
      { lat: 20.417849636308187, lng: 100.11598758341783 },
      { lat: 20.786121731036232, lng: 100.32910119018952 },
      { lat: 21.436572984294024, lng: 101.18000532430754 },
      { lat: 21.201651923095184, lng: 101.27002566935997 },
      { lat: 21.17436676684507, lng: 101.80311974488292 },
      { lat: 22.318198757409547, lng: 101.65201785686152 },
      { lat: 22.464753119389304, lng: 102.17043582561358 },
      { lat: 21.675137233969465, lng: 102.75489627483466 },
      { lat: 20.766562201413745, lng: 103.20386111858645 },
      { lat: 20.75873322192153, lng: 104.43500044150805 },
      { lat: 19.886641750563882, lng: 104.8225736836971 },
      { lat: 19.62466807706022, lng: 104.18338789267894 },
      { lat: 19.265180975821806, lng: 103.8965320170267 },
      { lat: 18.66697459561108, lng: 105.09459842328152 },
      { lat: 17.48531545660896, lng: 105.92576216026403 },
      { lat: 16.604283962464805, lng: 106.55600792849569 },
      { lat: 15.90853831630318, lng: 107.3127059265456 },
      { lat: 15.202173163305558, lng: 107.5645251811039 },
      { lat: 14.202440904186972, lng: 107.38272749230109 },
      { lat: 14.570583807834282, lng: 106.49637332563087 },
      { lat: 13.881091009979954, lng: 106.04394616091552 },
      { lat: 14.273211778210694, lng: 105.21877689007887 },
    ],
  ],
  LB: [
    [
      { lat: 33.2774264592763, lng: 35.82110070165024 },
      { lat: 33.26427480725802, lng: 35.55279666519081 },
      { lat: 33.08904002535628, lng: 35.460709262846706 },
      { lat: 33.09090037691878, lng: 35.126052687324545 },
      { lat: 33.90545014091944, lng: 35.48220665868013 },
      { lat: 34.61005829521913, lng: 35.9795923194894 },
      { lat: 34.644914048800004, lng: 35.99840254084364 },
      { lat: 34.59393524834407, lng: 36.4481942075121 },
      { lat: 34.201788641897174, lng: 36.61175011571589 },
      { lat: 33.82491242119255, lng: 36.066460402172055 },
      { lat: 33.2774264592763, lng: 35.82110070165024 },
    ],
  ],
  LC: [
    [
      { lat: 13.712701316136, lng: -60.959223759247 },
      { lat: 13.73543026463, lng: -61.023848947232 },
      { lat: 13.746681314271, lng: -61.03984991098 },
      { lat: 13.791982180122, lng: -61.0702097987 },
      { lat: 13.867173326412, lng: -61.083232250713 },
      { lat: 13.892147466585, lng: -61.077970288124 },
      { lat: 14.093304503492, lng: -60.927605223453 },
      { lat: 14.099472720614, lng: -60.916352099502 },
      { lat: 14.099933108099, lng: -60.912014106692 },
      { lat: 14.031072075654, lng: -60.880839249581 },
      { lat: 13.892479716369, lng: -60.873823780033 },
      { lat: 13.785662052183, lng: -60.903299328079 },
      { lat: 13.712701316136, lng: -60.959223759247 },
    ],
  ],
  LI: [
    [
      { lat: 47.074679117588, lng: 9.4727990045667 },
      { lat: 47.093626911504, lng: 9.4642208435672 },
      { lat: 47.105765494027, lng: 9.4630388063532 },
      { lat: 47.198958557194, lng: 9.4830204702483 },
      { lat: 47.276014606356, lng: 9.5331745046406 },
      { lat: 47.274551689876, lng: 9.5351081073442 },
      { lat: 47.148134371864, lng: 9.6085483629314 },
      { lat: 47.108676106103, lng: 9.615325313035 },
      { lat: 47.091116562085, lng: 9.6107170379216 },
      { lat: 47.056095427312, lng: 9.5832318549344 },
      { lat: 47.052866029212, lng: 9.5441783254099 },
      { lat: 47.074679117588, lng: 9.4727990045667 },
    ],
  ],
  LK: [
    [
      { lat: 7.523055324733164, lng: 81.7879590188914 },
      { lat: 6.481775214051921, lng: 81.63732221876059 },
      { lat: 6.197141424988288, lng: 81.21801964714433 },
      { lat: 5.968369859232155, lng: 80.34835696810441 },
      { lat: 6.76346344647493, lng: 79.87246870312853 },
      { lat: 8.200843410673386, lng: 79.69516686393513 },
      { lat: 9.824077663609557, lng: 80.14780073437964 },
      { lat: 9.268426825391188, lng: 80.83881798698656 },
      { lat: 8.56420624433369, lng: 81.30431928907177 },
      { lat: 7.523055324733164, lng: 81.7879590188914 },
    ],
  ],
  LR: [
    [
      { lat: 4.364565944837722, lng: -7.71215938966975 },
      { lat: 4.355755113131963, lng: -7.974107224957251 },
      { lat: 4.8324185245922, lng: -9.004793667018674 },
      { lat: 5.593560695819207, lng: -9.913420376006684 },
      { lat: 6.140710760925558, lng: -10.765383876986643 },
      { lat: 6.785916856305747, lng: -11.438779466182055 },
      { lat: 7.105845648624737, lng: -11.19980180504828 },
      { lat: 7.396706447779536, lng: -11.146704270868383 },
      { lat: 7.939464016141087, lng: -10.69559485517648 },
      { lat: 8.406205552601293, lng: -10.23009355309128 },
      { lat: 8.42850393313523, lng: -10.016566534861255 },
      { lat: 8.541055202666925, lng: -9.755342169625834 },
      { lat: 7.928534450711353, lng: -9.337279832384581 },
      { lat: 7.526905218938907, lng: -9.40334815106975 },
      { lat: 7.313920803247953, lng: -9.208786383490844 },
      { lat: 7.309037380396375, lng: -8.926064622422004 },
      { lat: 7.71167430259851, lng: -8.722123582382123 },
      { lat: 7.686042792181738, lng: -8.439298468448698 },
      { lat: 7.39520783124307, lng: -8.48544552248535 },
      { lat: 6.911800645368742, lng: -8.385451626000574 },
      { lat: 6.46756419517166, lng: -8.60288021486862 },
      { lat: 6.193033148621083, lng: -8.311347622094019 },
      { lat: 6.126189683451543, lng: -7.993692592795881 },
      { lat: 5.707352199725904, lng: -7.570152553731688 },
      { lat: 5.313345241716519, lng: -7.539715135111762 },
      { lat: 5.188159084489456, lng: -7.63536821128403 },
      { lat: 4.364565944837722, lng: -7.71215938966975 },
    ],
  ],
  LS: [
    [
      { lat: -28.955596612261715, lng: 28.97826256685724 },
      { lat: -29.257386976846252, lng: 29.325166456832587 },
      { lat: -29.74376555757737, lng: 29.018415154748023 },
      { lat: -30.070050551068253, lng: 28.84839969250774 },
      { lat: -30.2262167294543, lng: 28.29106937023991 },
      { lat: -30.54573211031495, lng: 28.107204624145425 },
      { lat: -30.64510588961222, lng: 27.749397006956485 },
      { lat: -29.875953871379984, lng: 26.999261915807637 },
      { lat: -29.24271087007536, lng: 27.532511020627478 },
      { lat: -28.851468601193588, lng: 28.07433841320778 },
      { lat: -28.64750172293757, lng: 28.541700066855498 },
      { lat: -28.955596612261715, lng: 28.97826256685724 },
    ],
  ],
  LT: [
    [
      { lat: 54.327536932993326, lng: 22.731098667092652 },
      { lat: 54.582740993866736, lng: 22.65105187347254 },
      { lat: 54.85657440858138, lng: 22.75776370615526 },
      { lat: 55.015298570365864, lng: 22.315723504330577 },
      { lat: 55.190481675835315, lng: 21.268448927503467 },
      { lat: 56.03107636171106, lng: 21.055800408622414 },
      { lat: 56.33780182557948, lng: 22.201156853939494 },
      { lat: 56.273671373105266, lng: 23.878263787539964 },
      { lat: 56.37252838807963, lng: 24.860684441840757 },
      { lat: 56.16453074810484, lng: 25.000934279080894 },
      { lat: 56.10029694276603, lng: 25.533046502390334 },
      { lat: 55.615106919977634, lng: 26.494331495883753 },
      { lat: 55.16717560487167, lng: 26.58827924979039 },
      { lat: 54.84696259217509, lng: 25.7684326514798 },
      { lat: 54.28242340760253, lng: 25.536353794056993 },
      { lat: 53.905702216194754, lng: 24.450683628037037 },
      { lat: 53.91249766704114, lng: 23.48412763844985 },
      { lat: 54.22056671814914, lng: 23.24398725758951 },
      { lat: 54.327536932993326, lng: 22.731098667092652 },
    ],
  ],
  LU: [
    [
      { lat: 50.128051662794235, lng: 6.043073357781111 },
      { lat: 49.90222565367873, lng: 6.242751092156993 },
      { lat: 49.463802802114515, lng: 6.186320428094177 },
      { lat: 49.44266714130703, lng: 5.897759230176405 },
      { lat: 49.529483547557504, lng: 5.674051954784829 },
      { lat: 50.09032786722122, lng: 5.782417433300906 },
      { lat: 50.128051662794235, lng: 6.043073357781111 },
    ],
  ],
  LV: [
    [
      { lat: 56.03107636171106, lng: 21.055800408622414 },
      { lat: 56.78387278912293, lng: 21.09042361825797 },
      { lat: 57.41187063254993, lng: 21.581866489353672 },
      { lat: 57.75337433535076, lng: 22.524341261492875 },
      { lat: 57.00623647727487, lng: 23.318452996522097 },
      { lat: 57.02569265403277, lng: 24.12072960785343 },
      { lat: 57.79342357037698, lng: 24.312862583114622 },
      { lat: 57.97015696881519, lng: 25.16459354014927 },
      { lat: 57.84752879498657, lng: 25.602809685984365 },
      { lat: 57.47638865826633, lng: 26.463532342237787 },
      { lat: 57.47452830670383, lng: 27.28818484875151 },
      { lat: 57.24425812441123, lng: 27.77001590344093 },
      { lat: 56.75932648378429, lng: 27.855282016722526 },
      { lat: 56.16912995057881, lng: 28.176709425577993 },
      { lat: 55.783313707087686, lng: 27.10245975109453 },
      { lat: 55.615106919977634, lng: 26.494331495883753 },
      { lat: 56.10029694276603, lng: 25.533046502390334 },
      { lat: 56.16453074810484, lng: 25.000934279080894 },
      { lat: 56.37252838807963, lng: 24.860684441840757 },
      { lat: 56.273671373105266, lng: 23.878263787539964 },
      { lat: 56.33780182557948, lng: 22.201156853939494 },
      { lat: 56.03107636171106, lng: 21.055800408622414 },
    ],
  ],
  LY: [
    [
      { lat: 22.862950000000126, lng: 14.8513 },
      { lat: 22.49128896737113, lng: 14.143870883855243 },
      { lat: 23.04050608976928, lng: 13.581424594790462 },
      { lat: 23.471668402596432, lng: 11.9995056494717 },
      { lat: 24.097909247325617, lng: 11.560669386449035 },
      { lat: 24.562532050061748, lng: 10.771363559622955 },
      { lat: 24.379313259370974, lng: 10.303846876678449 },
      { lat: 24.936953640232616, lng: 9.948261346078027 },
      { lat: 25.365454616796796, lng: 9.910692579801776 },
      { lat: 26.094324856057483, lng: 9.31941084151822 },
      { lat: 26.51220632578565, lng: 9.716285841519664 },
      { lat: 27.140953477481048, lng: 9.629056023811074 },
      { lat: 27.688258571884205, lng: 9.756128370816782 },
      { lat: 28.144173895779314, lng: 9.683884718472882 },
      { lat: 28.95998973237107, lng: 9.859997999723475 },
      { lat: 29.424638373323376, lng: 9.805634392952356 },
      { lat: 30.307556057246188, lng: 9.482139926805417 },
      { lat: 30.539324856075382, lng: 9.970017124072967 },
      { lat: 30.961831366493524, lng: 10.056575148161699 },
      { lat: 31.376069647745283, lng: 9.950225050505196 },
      { lat: 31.761420803345683, lng: 10.636901482799487 },
      { lat: 32.081814683555365, lng: 10.944789666394513 },
      { lat: 32.36890310315283, lng: 11.432253452203781 },
      { lat: 33.13699575452324, lng: 11.48878746913101 },
      { lat: 32.79278, lng: 12.66331 },
      { lat: 32.87882, lng: 13.08326 },
      { lat: 32.71196, lng: 13.91868 },
      { lat: 32.26508, lng: 15.24563 },
      { lat: 31.37626, lng: 15.71394 },
      { lat: 31.18218, lng: 16.61162 },
      { lat: 30.76357, lng: 18.02109 },
      { lat: 30.26639, lng: 19.08641 },
      { lat: 30.52582, lng: 19.57404 },
      { lat: 30.98576, lng: 20.05335 },
      { lat: 31.75179000000014, lng: 19.82033 },
      { lat: 32.2382, lng: 20.13397 },
      { lat: 32.7068, lng: 20.85452 },
      { lat: 32.8432, lng: 21.54298 },
      { lat: 32.63858, lng: 22.89576 },
      { lat: 32.19149, lng: 23.2368 },
      { lat: 32.18726, lng: 23.609130000000107 },
      { lat: 32.01667, lng: 23.9275 },
      { lat: 31.89936, lng: 24.92114 },
      { lat: 31.56915, lng: 25.16482 },
      { lat: 31.08929, lng: 24.80287 },
      { lat: 30.6616, lng: 24.95762 },
      { lat: 30.04419, lng: 24.70007 },
      { lat: 29.23865452953356, lng: 25.00000000000011 },
      { lat: 25.682499996361003, lng: 25.00000000000011 },
      { lat: 22, lng: 25.00000000000011 },
      { lat: 20.00304, lng: 25.00000000000011 },
      { lat: 20, lng: 23.850000000000136 },
      { lat: 19.580470000000105, lng: 23.837660000000138 },
      { lat: 21.49509, lng: 19.84926 },
      { lat: 23.40972, lng: 15.86085 },
      { lat: 22.862950000000126, lng: 14.8513 },
    ],
  ],
  MA: [
    [
      { lat: 27.120696316022503, lng: -8.794883999049077 },
      { lat: 27.656425889592356, lng: -8.817828334986672 },
      { lat: 27.656425889592356, lng: -8.665589565454809 },
      { lat: 27.589479071558227, lng: -8.665124477564191 },
      { lat: 27.395744126896005, lng: -8.68439978680905 },
      { lat: 25.881056219988906, lng: -8.6872936670174 },
      { lat: 25.933352769468268, lng: -11.96941891117116 },
      { lat: 23.374594224536168, lng: -11.937224493853321 },
      { lat: 23.284832261645178, lng: -12.874221564169575 },
      { lat: 22.771220201096256, lng: -13.118754441774712 },
      { lat: 21.32707062426756, lng: -12.929101935263532 },
      { lat: 21.33332347257488, lng: -16.845193650773993 },
      { lat: 20.999752102130827, lng: -17.063423224342568 },
      { lat: 21.42231028898148, lng: -17.020428432675743 },
      { lat: 21.420734157796577, lng: -17.00296179856109 },
      { lat: 21.500600083903663, lng: -14.750954555713534 },
      { lat: 21.8609398462749, lng: -14.630832688851072 },
      { lat: 22.31016307218816, lng: -14.221167771857251 },
      { lat: 23.691009019459305, lng: -13.891110398809047 },
      { lat: 24.7701162785782, lng: -12.50096269372537 },
      { lat: 26.030866197203043, lng: -12.030758836301615 },
      { lat: 26.104091701760623, lng: -11.718219773800357 },
      { lat: 26.883423977154365, lng: -11.392554897496979 },
      { lat: 26.990807603456886, lng: -10.551262579785273 },
      { lat: 26.860944729107405, lng: -10.189424200877582 },
      { lat: 26.860944729107405, lng: -9.735343390328879 },
      { lat: 27.088476060488517, lng: -9.413037482124466 },
      { lat: 27.120696316022503, lng: -8.794883999049077 },
    ],
    [
      { lat: 35.75518219659085, lng: -5.193863491222032 },
      { lat: 35.33071198174565, lng: -4.591006232105143 },
      { lat: 35.39985504815198, lng: -3.640056525070008 },
      { lat: 35.17909332940112, lng: -2.604305792644112 },
      { lat: 35.16839630791671, lng: -2.169913702798624 },
      { lat: 34.527918606091305, lng: -1.792985805661658 },
      { lat: 33.91971283623212, lng: -1.73345455566141 },
      { lat: 32.86401500094137, lng: -1.388049282222596 },
      { lat: 32.6515215113572, lng: -1.124551153966195 },
      { lat: 32.26288890230603, lng: -1.30789913573787 },
      { lat: 32.094346218386164, lng: -2.616604783529567 },
      { lat: 31.72449799247329, lng: -3.068980271812649 },
      { lat: 31.63729401298082, lng: -3.647497931320146 },
      { lat: 30.896951605751152, lng: -3.690441046554667 },
      { lat: 30.50118764904388, lng: -4.859646165374443 },
      { lat: 30.000443020135574, lng: -5.242129278982787 },
      { lat: 29.731699734001808, lng: -6.060632290053746 },
      { lat: 29.579228420524657, lng: -7.059227667661901 },
      { lat: 28.84128896739665, lng: -8.674116176782832 },
      { lat: 27.65642588959247, lng: -8.665589565454836 },
      { lat: 27.65642588959247, lng: -8.817809007940525 },
      { lat: 27.65642588959247, lng: -8.817828334986643 },
      { lat: 27.12069631602256, lng: -8.794883999049034 },
      { lat: 27.088476060488546, lng: -9.413037482124508 },
      { lat: 26.860944729107416, lng: -9.735343390328751 },
      { lat: 26.860944729107416, lng: -10.189424200877452 },
      { lat: 26.990807603456886, lng: -10.55126257978526 },
      { lat: 26.883423977154393, lng: -11.392554897496948 },
      { lat: 26.104091701760808, lng: -11.718219773800342 },
      { lat: 26.03086619720312, lng: -12.030758836301658 },
      { lat: 24.770116278578143, lng: -12.50096269372537 },
      { lat: 23.691009019459386, lng: -13.891110398809047 },
      { lat: 22.310163072188345, lng: -14.221167771857154 },
      { lat: 21.860939846274874, lng: -14.630832688850946 },
      { lat: 21.500600083903805, lng: -14.750954555713404 },
      { lat: 21.420734157796687, lng: -17.00296179856107 },
      { lat: 21.422310288981635, lng: -17.020428432675768 },
      { lat: 21.885744533774954, lng: -16.973247849993186 },
      { lat: 22.15823436125009, lng: -16.58913692876763 },
      { lat: 22.679339504481277, lng: -16.261921759495664 },
      { lat: 23.017768459560898, lng: -16.3264139469959 },
      { lat: 23.723358466074103, lng: -15.982610642958063 },
      { lat: 24.35913361256104, lng: -15.426003790742186 },
      { lat: 24.52026072844697, lng: -15.089331834360733 },
      { lat: 25.103532619725314, lng: -14.824645148161691 },
      { lat: 25.63626496022229, lng: -14.800925665739667 },
      { lat: 26.254418443297652, lng: -14.439939947964831 },
      { lat: 26.618892320252286, lng: -13.773804897506464 },
      { lat: 27.640147813420494, lng: -13.139941779014292 },
      { lat: 27.654147671719812, lng: -13.121613369914712 },
      { lat: 28.038185533148663, lng: -12.618836635783111 },
      { lat: 28.148643907172584, lng: -11.688919236690765 },
      { lat: 28.83214223888092, lng: -10.900956997104402 },
      { lat: 29.098585923777787, lng: -10.399592251008642 },
      { lat: 29.933573716749862, lng: -9.564811163765626 },
      { lat: 31.17773550060906, lng: -9.814718390329174 },
      { lat: 32.038096421836485, lng: -9.434793260119363 },
      { lat: 32.564679266890636, lng: -9.300692918321829 },
      { lat: 33.2402452662424, lng: -8.65747636558504 },
      { lat: 33.69706492770251, lng: -7.654178432638218 },
      { lat: 34.11047638603745, lng: -6.91254411460136 },
      { lat: 35.145865383437524, lng: -6.244342006851411 },
      { lat: 35.75998810479399, lng: -5.929994269219833 },
      { lat: 35.75518219659085, lng: -5.193863491222032 },
    ],
  ],
  MC: [
    [
      { lat: 43.775547495174, lng: 7.4143330492452 },
      { lat: 43.764223099974, lng: 7.4421469112947 },
      { lat: 43.755021320053, lng: 7.4455707178001 },
      { lat: 43.748746121678, lng: 7.4452789439987 },
      { lat: 43.746258989281, lng: 7.4440611656947 },
      { lat: 43.743378292479, lng: 7.4406866907487 },
      { lat: 43.723601255546, lng: 7.3769113717893 },
      { lat: 43.723758554617, lng: 7.3753061141196 },
      { lat: 43.72530979939, lng: 7.3724195740615 },
      { lat: 43.727853276672, lng: 7.3703533704973 },
      { lat: 43.731633588983, lng: 7.369238505868 },
      { lat: 43.75646452492, lng: 7.3719178899218 },
      { lat: 43.75752721073, lng: 7.3724973154889 },
      { lat: 43.767469739876, lng: 7.3848350247896 },
      { lat: 43.775547495174, lng: 7.4143330492452 },
    ],
  ],
  MD: [
    [
      { lat: 48.22072622333347, lng: 26.619336785597795 },
      { lat: 48.368210761094495, lng: 26.857823520624805 },
      { lat: 48.467119452501116, lng: 27.522537469195154 },
      { lat: 48.15556224221342, lng: 28.259546746541844 },
      { lat: 48.1181485052341, lng: 28.670891147585163 },
      { lat: 47.84909516050646, lng: 29.12269819511303 },
      { lat: 47.51022695575249, lng: 29.05086795422733 },
      { lat: 47.34664520933257, lng: 29.41513512545274 },
      { lat: 46.928582872091326, lng: 29.559674106573112 },
      { lat: 46.67436066343146, lng: 29.908851759569302 },
      { lat: 46.52532583270169, lng: 29.838210076626297 },
      { lat: 46.42393667254503, lng: 30.02465864433537 },
      { lat: 46.34998769793536, lng: 29.75997195813639 },
      { lat: 46.3792623968287, lng: 29.170653924279886 },
      { lat: 46.517677720722496, lng: 29.072106967899295 },
      { lat: 46.43788930926383, lng: 28.862972446414062 },
      { lat: 46.2588304713725, lng: 28.93371748222162 },
      { lat: 45.93998688413164, lng: 28.659987420371575 },
      { lat: 45.5969070501459, lng: 28.485269402792767 },
      { lat: 45.48828318946837, lng: 28.233553501099042 },
      { lat: 45.944586086605625, lng: 28.0544429867754 },
      { lat: 46.37156260841722, lng: 28.160017937947714 },
      { lat: 46.810476386088254, lng: 28.128030226359044 },
      { lat: 47.40511709247083, lng: 27.551166212684848 },
      { lat: 47.82677094175638, lng: 27.233872918412743 },
      { lat: 48.123264472030996, lng: 26.924176059687568 },
      { lat: 48.22072622333347, lng: 26.619336785597795 },
    ],
  ],
  ME: [
    [
      { lat: 42.50009349219084, lng: 19.801613396898688 },
      { lat: 42.688247382165564, lng: 19.738051385179627 },
      { lat: 42.19574, lng: 19.3044900000001 },
      { lat: 41.87755, lng: 19.37177000000014 },
      { lat: 41.95502, lng: 19.16246 },
      { lat: 42.28151, lng: 18.88214 },
      { lat: 42.48, lng: 18.45 },
      { lat: 42.65, lng: 18.56 },
      { lat: 43.20011, lng: 18.70648 },
      { lat: 43.43253, lng: 19.03165 },
      { lat: 43.52384, lng: 19.21852 },
      { lat: 43.35229, lng: 19.48389 },
      { lat: 43.21377997027054, lng: 19.63 },
      { lat: 43.10604, lng: 19.95857 },
      { lat: 42.89852, lng: 20.3398 },
      { lat: 42.81275000000011, lng: 20.25758 },
      { lat: 42.58863, lng: 20.0707 },
      { lat: 42.50009349219084, lng: 19.801613396898688 },
    ],
  ],
  MF: [
    [
      { lat: 18.053091156166193, lng: -423.1416893005371 },
      { lat: 18.05407041612386, lng: -423.11121940612793 },
      { lat: 18.048031559484514, lng: -423.08478355407715 },
      { lat: 18.06329151312102, lng: -423.06461334228516 },
      { lat: 18.05435603258386, lng: -423.0093812942505 },
      { lat: 18.11844477451179, lng: -422.99869537353516 },
      { lat: 18.13818477052255, lng: -423.0398941040039 },
      { lat: 18.08662770171876, lng: -423.10461044311523 },
      { lat: 18.079447666411518, lng: -423.1469249725342 },
      { lat: 18.062557107201975, lng: -423.15834045410156 },
      { lat: 18.053091156166193, lng: -423.1416893005371 },
    ],
  ],
  MG: [
    [
      { lat: -12.469832858940554, lng: 49.54351891459575 },
      { lat: -12.895284925999555, lng: 49.80898074727909 },
      { lat: -13.555761407121985, lng: 50.056510857957164 },
      { lat: -14.758788750876795, lng: 50.21743126811407 },
      { lat: -15.226512139550541, lng: 50.47653689962553 },
      { lat: -15.706069431219126, lng: 50.377111443895956 },
      { lat: -16.000263360256767, lng: 50.20027469259318 },
      { lat: -15.414252618066916, lng: 49.86060550313868 },
      { lat: -15.710203545802479, lng: 49.67260664246086 },
      { lat: -16.451036879138776, lng: 49.863344354050156 },
      { lat: -16.875042006093597, lng: 49.77456424337271 },
      { lat: -17.106035658438273, lng: 49.49861209493412 },
      { lat: -17.953064060134366, lng: 49.435618523970305 },
      { lat: -19.118781019774445, lng: 49.041792433473944 },
      { lat: -20.496888116134127, lng: 48.54854088724801 },
      { lat: -22.391501153251085, lng: 47.93074913919867 },
      { lat: -23.781958916928517, lng: 47.54772342305131 },
      { lat: -24.941629733990453, lng: 47.095761346226595 },
      { lat: -25.178462823184105, lng: 46.282477654817086 },
      { lat: -25.60143442149309, lng: 45.409507684110444 },
      { lat: -25.34610116953894, lng: 44.83357384621755 },
      { lat: -24.988345228782308, lng: 44.03972049334976 },
      { lat: -24.460677178649988, lng: 43.76376834491117 },
      { lat: -23.574116306250602, lng: 43.697777540874455 },
      { lat: -22.776903985283873, lng: 43.345654331237625 },
      { lat: -22.057413018484123, lng: 43.254187046081 },
      { lat: -21.336475111580185, lng: 43.43329756040464 },
      { lat: -21.163307386970125, lng: 43.893682895692926 },
      { lat: -20.830459486578174, lng: 43.896370070172104 },
      { lat: -20.07236622485639, lng: 44.37432539243966 },
      { lat: -19.435454196859048, lng: 44.46439741392439 },
      { lat: -18.961994724200906, lng: 44.23242190936617 },
      { lat: -18.33138722094317, lng: 44.04297610858415 },
      { lat: -17.409944756746782, lng: 43.96308434426091 },
      { lat: -16.850495700754955, lng: 44.31246870298628 },
      { lat: -16.216219170804507, lng: 44.4465173683514 },
      { lat: -16.1793738745804, lng: 44.94493655780653 },
      { lat: -15.97437346767854, lng: 45.50273196796499 },
      { lat: -15.793454278224685, lng: 45.87299360533626 },
      { lat: -15.780018405828798, lng: 46.31224327981721 },
      { lat: -15.210182386946313, lng: 46.882182651564285 },
      { lat: -14.594302666891764, lng: 47.70512983581235 },
      { lat: -14.091232598530375, lng: 48.005214878131255 },
      { lat: -13.663868503476586, lng: 47.869047479042166 },
      { lat: -13.784067884987486, lng: 48.29382775248138 },
      { lat: -13.089174899958664, lng: 48.84506025573878 },
      { lat: -12.48786793381042, lng: 48.86350874206698 },
      { lat: -12.04055673589197, lng: 49.194651320193316 },
      { lat: -12.469832858940554, lng: 49.54351891459575 },
    ],
  ],
  MH: [
    [
      { lat: 7.306938996985, lng: 168.64447124749 },
      { lat: 7.3345053911376, lng: 168.6409316229 },
      { lat: 7.3579935717589, lng: 168.65362677287 },
      { lat: 7.3703465644837, lng: 168.67928783893 },
      { lat: 7.372573224663, lng: 168.72889486756 },
      { lat: 7.3412871863189, lng: 168.82179529023 },
      { lat: 7.1714484939567, lng: 168.90435368122 },
      { lat: 7.284999299427, lng: 168.66480828826 },
      { lat: 7.306938996985, lng: 168.64447124749 },
    ],
    [
      { lat: 11.112181227516, lng: 166.87866000685 },
      { lat: 11.118406149122, lng: 166.83949724556 },
      { lat: 11.12896228491, lng: 166.82252884995 },
      { lat: 11.140606847825, lng: 166.81448118129 },
      { lat: 11.154719549705, lng: 166.81171065968 },
      { lat: 11.157702632451, lng: 166.81236302311 },
      { lat: 11.181636710972, lng: 166.8301526375 },
      { lat: 11.197730638333, lng: 166.85847328433 },
      { lat: 11.201976968763, lng: 166.89623780334 },
      { lat: 11.194297127563, lng: 166.92221423027 },
      { lat: 11.186372365721, lng: 166.93193505319 },
      { lat: 11.178530146741, lng: 166.93621419076 },
      { lat: 11.165016764318, lng: 166.93779934241 },
      { lat: 11.126988083459, lng: 166.90670451809 },
      { lat: 11.112181227516, lng: 166.87866000685 },
    ],
    [
      { lat: 7.036510407697, lng: 170.93861372394 },
      { lat: 7.188806722207, lng: 171.02644953838 },
      { lat: 7.2082207372481, lng: 171.05200119835 },
      { lat: 7.2112544433447, lng: 171.06463924484 },
      { lat: 7.1462008823707, lng: 171.45567054824 },
      { lat: 6.9936527601137, lng: 171.73813918912 },
      { lat: 6.9897807671191, lng: 171.74019873904 },
      { lat: 6.9843261036463, lng: 171.73718956162 },
      { lat: 6.9727189879851, lng: 171.71461104613 },
      { lat: 7.0371900762431, lng: 170.99400421109 },
      { lat: 7.036510407697, lng: 170.93861372394 },
    ],
    [
      { lat: 6.5174574428119, lng: 169.51124674135 },
      { lat: 6.4774977584785, lng: 169.59857160308 },
      { lat: 6.4281774532104, lng: 169.80710128926 },
      { lat: 6.424341964936, lng: 169.91559002476 },
      { lat: 6.417797202475, lng: 169.91163546761 },
      { lat: 6.2095904651146, lng: 169.83786192658 },
      { lat: 5.9716203087924, lng: 169.77987239001 },
      { lat: 5.7899377451139, lng: 169.66402524369 },
      { lat: 5.7710285512698, lng: 169.61069452201 },
      { lat: 5.7735670687496, lng: 169.57393374151 },
      { lat: 5.774663999316, lng: 169.57187418761 },
      { lat: 6.1601108081664, lng: 169.57349080203 },
      { lat: 6.3646791273496, lng: 169.55499527795 },
      { lat: 6.5174574428119, lng: 169.51124674135 },
    ],
  ],
  MK: [
    [
      { lat: 41.85541, lng: 20.59023 },
      { lat: 41.84711, lng: 20.71731000000011 },
      { lat: 42.05186, lng: 20.76216 },
      { lat: 42.2068, lng: 21.35270000000014 },
      { lat: 42.24522439706186, lng: 21.57663598940212 },
      { lat: 42.30364, lng: 21.917080000000112 },
      { lat: 42.32025950781508, lng: 22.38052575042468 },
      { lat: 41.999297186850356, lng: 22.881373732197346 },
      { lat: 41.33799388281119, lng: 22.952377150166512 },
      { lat: 41.3048, lng: 22.76177 },
      { lat: 41.130487168943205, lng: 22.597308383889015 },
      { lat: 41.14986583105269, lng: 22.05537763844427 },
      { lat: 40.93127452245795, lng: 21.674160597426976 },
      { lat: 40.84272695572588, lng: 21.0200403174764 },
      { lat: 41.08622, lng: 20.60518 },
      { lat: 41.5150900000001, lng: 20.46315 },
      { lat: 41.85541, lng: 20.59023 },
    ],
  ],
  ML: [
    [
      { lat: 14.616834214735505, lng: -12.170750291380301 },
      { lat: 14.79909699142894, lng: -11.834207526079467 },
      { lat: 15.388208319556298, lng: -11.666078253617854 },
      { lat: 15.411256008358478, lng: -11.349095017939504 },
      { lat: 15.132745876521426, lng: -10.650791388379417 },
      { lat: 15.330485744686273, lng: -10.086846482778212 },
      { lat: 15.264107367407362, lng: -9.700255092802706 },
      { lat: 15.486496893775437, lng: -9.55023840985939 },
      { lat: 15.501689764869257, lng: -5.537744309908447 },
      { lat: 16.20185374599184, lng: -5.315277268891933 },
      { lat: 16.325102037007966, lng: -5.488522508150438 },
      { lat: 20.64083344164763, lng: -5.971128709324248 },
      { lat: 24.956590684503425, lng: -6.453786586930335 },
      { lat: 24.974574082941, lng: -4.92333736817423 },
      { lat: 22.792665920497384, lng: -1.550054897457613 },
      { lat: 20.610809434486043, lng: 1.823227573259032 },
      { lat: 20.142233384679486, lng: 2.06099083823392 },
      { lat: 19.856230170160114, lng: 2.683588494486429 },
      { lat: 19.69357859952144, lng: 3.1466610042539 },
      { lat: 19.057364203360038, lng: 3.158133172222705 },
      { lat: 19.155265204337, lng: 4.267419467800039 },
      { lat: 16.852227484601215, lng: 4.270209995143801 },
      { lat: 16.184283759012615, lng: 3.723421665063483 },
      { lat: 15.568119818580454, lng: 3.638258904646477 },
      { lat: 15.409524847876696, lng: 2.749992709981484 },
      { lat: 15.323561102759172, lng: 1.385528191746858 },
      { lat: 14.968182277887948, lng: 1.01578331869851 },
      { lat: 14.928908189346132, lng: 0.374892205414682 },
      { lat: 14.924308986872148, lng: -0.26625729003058 },
      { lat: 15.116157741755726, lng: -0.515854458000348 },
      { lat: 14.973815009007765, lng: -1.066363491205664 },
      { lat: 14.559008287000891, lng: -2.001035122068771 },
      { lat: 14.246417548067356, lng: -2.191824510090385 },
      { lat: 13.79815033615151, lng: -2.967694464520577 },
      { lat: 13.541266791228594, lng: -3.10370683431276 },
      { lat: 13.337661647998615, lng: -3.522802700199861 },
      { lat: 13.472485459848116, lng: -4.006390753587226 },
      { lat: 13.228443508349741, lng: -4.28040503581488 },
      { lat: 12.542645575404295, lng: -4.427166103523803 },
      { lat: 11.713858954307227, lng: -5.220941941743121 },
      { lat: 11.37514577885014, lng: -5.197842576508648 },
      { lat: 10.951269842976048, lng: -5.470564947929006 },
      { lat: 10.370736802609146, lng: -5.404341599946974 },
      { lat: 10.222554633012194, lng: -5.816926235365287 },
      { lat: 10.096360785355444, lng: -6.050452032892267 },
      { lat: 10.524060777219134, lng: -6.205222947606431 },
      { lat: 10.411302801958271, lng: -6.493965013037267 },
      { lat: 10.430810655148447, lng: -6.666460944027548 },
      { lat: 10.138993841996239, lng: -6.850506557635057 },
      { lat: 10.147236232946796, lng: -7.622759161804809 },
      { lat: 10.297382106970828, lng: -7.899589809592372 },
      { lat: 10.206534939001713, lng: -8.029943610048619 },
      { lat: 10.494811916541934, lng: -8.33537716310974 },
      { lat: 10.792597357623846, lng: -8.282357143578281 },
      { lat: 10.909256903522762, lng: -8.407310756860028 },
      { lat: 10.810890814655183, lng: -8.620321010767128 },
      { lat: 11.136245632364805, lng: -8.581305304386774 },
      { lat: 11.393645941610629, lng: -8.376304897484914 },
      { lat: 11.812560939984706, lng: -8.786099005559464 },
      { lat: 12.088358059126437, lng: -8.90526485842453 },
      { lat: 12.308060411015331, lng: -9.127473517279583 },
      { lat: 12.334286200403454, lng: -9.327616339546012 },
      { lat: 12.194243068892476, lng: -9.567911749703214 },
      { lat: 12.060478623904972, lng: -9.890992804392013 },
      { lat: 11.844083563682744, lng: -10.165213792348837 },
      { lat: 11.92397532800598, lng: -10.593223842806282 },
      { lat: 12.17788747807211, lng: -10.870829637078215 },
      { lat: 12.211244615116515, lng: -11.036555955438258 },
      { lat: 12.077971096235771, lng: -11.29757361494451 },
      { lat: 12.076834214725338, lng: -11.456168585648271 },
      { lat: 12.442987575729418, lng: -11.51394283695059 },
      { lat: 12.754518947800975, lng: -11.467899135778524 },
      { lat: 13.141213690641067, lng: -11.55339779300543 },
      { lat: 13.422075100147394, lng: -11.927716030311615 },
      { lat: 13.994727484589788, lng: -12.12488745772126 },
      { lat: 14.616834214735505, lng: -12.170750291380301 },
    ],
  ],
  MM: [
    [
      { lat: 20.186597601802063, lng: 99.54330936075931 },
      { lat: 19.752980658440947, lng: 98.95967573445488 },
      { lat: 19.708203029860044, lng: 98.25372399291561 },
      { lat: 18.627080389881755, lng: 97.7977828308044 },
      { lat: 18.445437730375815, lng: 97.37589643757354 },
      { lat: 17.567946071843664, lng: 97.85912275593486 },
      { lat: 16.83783559820793, lng: 98.49376102091135 },
      { lat: 16.177824204976115, lng: 98.90334842325676 },
      { lat: 15.308497422746084, lng: 98.53737592976572 },
      { lat: 15.12370250087035, lng: 98.1920740091914 },
      { lat: 14.622027696180835, lng: 98.43081912637987 },
      { lat: 13.827502549693278, lng: 99.09775516153876 },
      { lat: 13.269293728076464, lng: 99.21201175333609 },
      { lat: 12.80474843998867, lng: 99.19635379435167 },
      { lat: 11.892762762901697, lng: 99.58728600463972 },
      { lat: 10.960545762572437, lng: 99.03812055867398 },
      { lat: 9.932959906448545, lng: 98.55355065307305 },
      { lat: 10.67526601810515, lng: 98.45717410684871 },
      { lat: 11.441291612183749, lng: 98.76454552612077 },
      { lat: 12.032986761925683, lng: 98.42833865762985 },
      { lat: 13.122377631070677, lng: 98.50957400919268 },
      { lat: 13.640459703012851, lng: 98.1036039571077 },
      { lat: 14.837285874892642, lng: 97.77773237507517 },
      { lat: 16.10056793869977, lng: 97.59707156778276 },
      { lat: 16.928734442609336, lng: 97.1645398294998 },
      { lat: 16.42724050543285, lng: 96.505768670643 },
      { lat: 15.7143899601826, lng: 95.3693522481124 },
      { lat: 15.80345429123764, lng: 94.80840457558412 },
      { lat: 16.037936102762018, lng: 94.18880415240454 },
      { lat: 17.277240301985728, lng: 94.53348595579135 },
      { lat: 18.2135139022499, lng: 94.32481652219674 },
      { lat: 19.36649262133002, lng: 93.54098839719364 },
      { lat: 19.726961574781996, lng: 93.66325483599621 },
      { lat: 19.855144965081976, lng: 93.07827762245219 },
      { lat: 20.670883287025347, lng: 92.36855350135562 },
      { lat: 21.47548533780982, lng: 92.30323449093868 },
      { lat: 21.324047552978485, lng: 92.65225711463799 },
      { lat: 22.041238918541254, lng: 92.67272098182556 },
      { lat: 22.278459580977103, lng: 93.16612755734836 },
      { lat: 22.70311066333557, lng: 93.06029422401463 },
      { lat: 23.043658352139005, lng: 93.28632693885928 },
      { lat: 24.078556423432204, lng: 93.3251876159428 },
      { lat: 23.85074087167348, lng: 94.10674197792505 },
      { lat: 24.675238348890332, lng: 94.55265791217164 },
      { lat: 25.162495428970402, lng: 94.60324913938538 },
      { lat: 26.001307277932085, lng: 95.1551534362626 },
      { lat: 26.5735720891323, lng: 95.12476769407496 },
      { lat: 27.264589341739224, lng: 96.41936567585097 },
      { lat: 27.083773505149964, lng: 97.1339990580153 },
      { lat: 27.69905894623315, lng: 97.0519885599681 },
      { lat: 27.88253611908544, lng: 97.40256147663612 },
      { lat: 28.26158274994634, lng: 97.32711388549004 },
      { lat: 28.335945136014345, lng: 97.91198774616944 },
      { lat: 27.74722138112918, lng: 98.2462309102333 },
      { lat: 27.50881216075062, lng: 98.68269005737046 },
      { lat: 26.743535874940264, lng: 98.71209394734451 },
      { lat: 25.918702500913525, lng: 98.67183800658916 },
      { lat: 25.083637193293, lng: 97.72460900267914 },
      { lat: 23.897404690033042, lng: 97.60471967976198 },
      { lat: 24.063286037689966, lng: 98.66026248575577 },
      { lat: 23.14272207284253, lng: 98.89874922078276 },
      { lat: 22.94903880461258, lng: 99.5319922220874 },
      { lat: 22.11831431730458, lng: 99.24089887898725 },
      { lat: 21.7429367131364, lng: 99.98348921102149 },
      { lat: 21.558839423096614, lng: 100.41653771362738 },
      { lat: 21.84998444262902, lng: 101.15003299357825 },
      { lat: 21.436572984294024, lng: 101.18000532430754 },
      { lat: 20.786121731036232, lng: 100.32910119018952 },
      { lat: 20.417849636308187, lng: 100.11598758341783 },
      { lat: 20.186597601802063, lng: 99.54330936075931 },
    ],
  ],
  MN: [
    [
      { lat: 49.29719798440548, lng: 87.7512642760767 },
      { lat: 49.47052073831242, lng: 88.80556684769552 },
      { lat: 50.33181183532109, lng: 90.71366743364067 },
      { lat: 50.80217072204172, lng: 92.23471154171968 },
      { lat: 50.49529022887643, lng: 93.10421919146269 },
      { lat: 50.48053660745709, lng: 94.14756635943561 },
      { lat: 50.01343333597085, lng: 94.81594933469873 },
      { lat: 49.977466539095715, lng: 95.81402794798399 },
      { lat: 49.72606069599574, lng: 97.25972781778141 },
      { lat: 50.422400621128745, lng: 98.23176150919156 },
      { lat: 51.01099518493318, lng: 97.8257397806743 },
      { lat: 52.047366034546684, lng: 98.86149051310034 },
      { lat: 51.63400625264399, lng: 99.98173221232354 },
      { lat: 51.51685578063832, lng: 100.88948042196262 },
      { lat: 51.259920559283124, lng: 102.06522260946733 },
      { lat: 50.51056061461868, lng: 102.25590864462431 },
      { lat: 50.089966132195116, lng: 103.67654544476022 },
      { lat: 50.275329494826074, lng: 104.6215523620817 },
      { lat: 50.406019192092224, lng: 105.88659142458675 },
      { lat: 50.27429596618023, lng: 106.88880415245534 },
      { lat: 49.793705145865815, lng: 107.86817589725094 },
      { lat: 49.28254771585074, lng: 108.47516727095127 },
      { lat: 49.29296051695755, lng: 109.40244917199666 },
      { lat: 49.13012807880587, lng: 110.66201053267876 },
      { lat: 49.37796824807769, lng: 111.58123091028662 },
      { lat: 49.54356537535699, lng: 112.89773969935439 },
      { lat: 50.24830272073741, lng: 114.36245649623527 },
      { lat: 50.140247300815126, lng: 114.96210981655018 },
      { lat: 49.805177313834605, lng: 115.48569542853141 },
      { lat: 49.88853139912139, lng: 116.67880089728618 },
      { lat: 49.134598090199106, lng: 116.19180219936757 },
      { lat: 48.13538259540344, lng: 115.48528201707305 },
      { lat: 47.72654450132629, lng: 115.74283735561578 },
      { lat: 47.85341014260284, lng: 116.30895267137323 },
      { lat: 47.69770905210743, lng: 117.29550744025741 },
      { lat: 48.06673045510368, lng: 118.06414269416672 },
      { lat: 47.74706004494617, lng: 118.86657433479495 },
      { lat: 47.048058783550125, lng: 119.7728239278975 },
      { lat: 46.69267995867892, lng: 119.66326989143874 },
      { lat: 46.80541209572365, lng: 118.87432579963873 },
      { lat: 46.67273285581426, lng: 117.42170128791419 },
      { lat: 46.38820241961521, lng: 116.71786828009886 },
      { lat: 45.727235012386004, lng: 115.98509647020008 },
      { lat: 45.339816799493825, lng: 114.46033165899607 },
      { lat: 44.80889313412711, lng: 113.46390669154417 },
      { lat: 45.01164561622429, lng: 112.43606245325881 },
      { lat: 45.10207937273506, lng: 111.87330610560029 },
      { lat: 44.45744171811009, lng: 111.34837690637946 },
      { lat: 44.07317576758771, lng: 111.66773725794323 },
      { lat: 43.743118394539515, lng: 111.82958784388137 },
      { lat: 43.40683401140015, lng: 111.12968224492022 },
      { lat: 42.87123362891103, lng: 110.41210330611528 },
      { lat: 42.5194463160841, lng: 109.24359581913146 },
      { lat: 42.48151581478187, lng: 107.74477257693795 },
      { lat: 42.13432770442891, lng: 106.12931562706169 },
      { lat: 41.59740957291635, lng: 104.96499393109347 },
      { lat: 41.908346666016556, lng: 104.52228193564899 },
      { lat: 41.9074681666676, lng: 103.31227827353482 },
      { lat: 42.51487295182628, lng: 101.83304039917994 },
      { lat: 42.66380442969145, lng: 100.84586551310827 },
      { lat: 42.52469147396172, lng: 99.51581749878004 },
      { lat: 42.74888967546002, lng: 97.45175744017801 },
      { lat: 42.725635280928685, lng: 96.34939578652781 },
      { lat: 43.319449164394605, lng: 95.76245486855669 },
      { lat: 44.24133087826547, lng: 95.30687544147153 },
      { lat: 44.352331854828414, lng: 94.68892866412533 },
      { lat: 44.975472113619965, lng: 93.4807336771413 },
      { lat: 45.11507599545646, lng: 92.13389082231822 },
      { lat: 45.28607330991028, lng: 90.9455395853343 },
      { lat: 45.71971609148753, lng: 90.58576826371828 },
      { lat: 46.88814606382293, lng: 90.97080936072501 },
      { lat: 47.69354909930793, lng: 90.28082563676392 },
      { lat: 48.06908173277296, lng: 88.85429772334676 },
      { lat: 48.599462795600616, lng: 88.01383222855173 },
      { lat: 49.29719798440548, lng: 87.7512642760767 },
    ],
  ],
  MO: [
    [
      { lat: 22.216648833146053, lng: 113.50696563720703 },
      { lat: 22.067823457478664, lng: 113.52996826171875 },
      { lat: 22.08723054631197, lng: 113.62850189208984 },
      { lat: 22.23190407053973, lng: 113.60240936279297 },
      { lat: 22.216648833146053, lng: 113.50696563720703 },
    ],
  ],
  MQ: [
    [
      { lat: 14.900994720775014, lng: -61.22955322265625 },
      { lat: 14.707814052692818, lng: -61.25839233398437 },
      { lat: 14.433350271733675, lng: -61.10458374023438 },
      { lat: 14.355534767611692, lng: -60.85327148437499 },
      { lat: 14.481223229221587, lng: -60.774307250976555 },
      { lat: 14.795463725821401, lng: -60.86082458496093 },
      { lat: 14.901658274480909, lng: -61.06475830078124 },
      { lat: 14.900994720775014, lng: -61.22955322265625 },
    ],
  ],
  MR: [
    [
      { lat: 14.616834214735505, lng: -12.170750291380301 },
      { lat: 15.303691514542946, lng: -12.830658331747516 },
      { lat: 16.03938304286619, lng: -13.43573767745306 },
      { lat: 16.304302273010492, lng: -14.099521450242179 },
      { lat: 16.59826365810281, lng: -14.577347581428981 },
      { lat: 16.587282416240782, lng: -15.135737270558817 },
      { lat: 16.369337063049812, lng: -15.62366614425869 },
      { lat: 16.455662543193384, lng: -16.12069007004193 },
      { lat: 16.13503611903846, lng: -16.463098110407884 },
      { lat: 16.67389211676196, lng: -16.549707810929064 },
      { lat: 17.166962795474873, lng: -16.270551723688357 },
      { lat: 18.108481553616656, lng: -16.14634741867485 },
      { lat: 19.096715806550307, lng: -16.256883307347167 },
      { lat: 19.593817246981985, lng: -16.37765112961327 },
      { lat: 20.0925206568147, lng: -16.277838100641517 },
      { lat: 20.567866319251493, lng: -16.536323614965468 },
      { lat: 20.999752102130827, lng: -17.063423224342568 },
      { lat: 21.33332347257488, lng: -16.845193650773993 },
      { lat: 21.32707062426756, lng: -12.929101935263532 },
      { lat: 22.771220201096256, lng: -13.118754441774712 },
      { lat: 23.284832261645178, lng: -12.874221564169575 },
      { lat: 23.374594224536168, lng: -11.937224493853321 },
      { lat: 25.933352769468268, lng: -11.96941891117116 },
      { lat: 25.881056219988906, lng: -8.6872936670174 },
      { lat: 27.395744126896005, lng: -8.68439978680905 },
      { lat: 24.974574082941, lng: -4.92333736817423 },
      { lat: 24.956590684503425, lng: -6.453786586930335 },
      { lat: 20.64083344164763, lng: -5.971128709324248 },
      { lat: 16.325102037007966, lng: -5.488522508150438 },
      { lat: 16.20185374599184, lng: -5.315277268891933 },
      { lat: 15.501689764869257, lng: -5.537744309908447 },
      { lat: 15.486496893775437, lng: -9.55023840985939 },
      { lat: 15.264107367407362, lng: -9.700255092802706 },
      { lat: 15.330485744686273, lng: -10.086846482778212 },
      { lat: 15.132745876521426, lng: -10.650791388379417 },
      { lat: 15.411256008358478, lng: -11.349095017939504 },
      { lat: 15.388208319556298, lng: -11.666078253617854 },
      { lat: 14.79909699142894, lng: -11.834207526079467 },
      { lat: 14.616834214735505, lng: -12.170750291380301 },
    ],
  ],
  MS: [
    [
      { lat: 16.834775542012125, lng: -62.1990966796875 },
      { lat: 16.822945238563165, lng: -62.22587585449218 },
      { lat: 16.726303488284394, lng: -62.260894775390625 },
      { lat: 16.667110864702863, lng: -62.20527648925781 },
      { lat: 16.665795264844235, lng: -62.14485168457031 },
      { lat: 16.6986825466767, lng: -62.127685546875 },
      { lat: 16.76509757574794, lng: -62.137298583984375 },
      { lat: 16.824917007109164, lng: -62.17987060546874 },
      { lat: 16.834775542012125, lng: -62.1990966796875 },
    ],
  ],
  MT: [
    [
      { lat: 36.037446921077, lng: 14.190198144295 },
      { lat: 36.07330889381, lng: 14.218819107846 },
      { lat: 36.068344279707, lng: 14.290487314213 },
      { lat: 35.969392691436, lng: 14.461721093254 },
      { lat: 35.84782448852, lng: 14.57448273733 },
      { lat: 35.813624995802, lng: 14.539036196534 },
      { lat: 35.809956201871, lng: 14.529788964237 },
      { lat: 35.810495169251, lng: 14.449322281331 },
      { lat: 35.843000749463, lng: 14.372708464471 },
      { lat: 35.945057005973, lng: 14.261122042701 },
      { lat: 36.037446921077, lng: 14.190198144295 },
    ],
  ],
  MU: [
    [
      { lat: -20.319846043746, lng: 57.338622244053 },
      { lat: -20.183085876035, lng: 57.40069389754 },
      { lat: -19.991435083132, lng: 57.588924392067 },
      { lat: -19.98633069809, lng: 57.604544262392 },
      { lat: -19.99164385186, lng: 57.66757064171 },
      { lat: -20.120735813841, lng: 57.764491566483 },
      { lat: -20.230681640166, lng: 57.799540628729 },
      { lat: -20.326105314168, lng: 57.789148154164 },
      { lat: -20.426206530951, lng: 57.733355869978 },
      { lat: -20.481543001803, lng: 57.672949423835 },
      { lat: -20.511587981805, lng: 57.590219229586 },
      { lat: -20.50730709033, lng: 57.408476424951 },
      { lat: -20.463975154974, lng: 57.330761412211 },
      { lat: -20.445498430593, lng: 57.316731923211 },
      { lat: -20.44100051306, lng: 57.315557774833 },
      { lat: -20.319846043746, lng: 57.338622244053 },
    ],
  ],
  MV: [
    [
      { lat: 6.5118147063479, lng: 73.36669921875 },
      { lat: 5.222246513227375, lng: 74.564208984375 },
      { lat: 0.09887690404683164, lng: 74.53125 },
      { lat: -1.2962761196418089, lng: 73.135986328125 },
      { lat: -0.5163504323777461, lng: 71.96044921875 },
      { lat: 5.222246513227375, lng: 71.91650390625 },
      { lat: 6.5118147063479, lng: 73.36669921875 },
    ],
  ],
  MW: [
    [
      { lat: -11.520020033415925, lng: 34.55998904799935 },
      { lat: -12.280025323132504, lng: 34.28000613784198 },
      { lat: -13.579997653866876, lng: 34.55998904799935 },
      { lat: -13.565424899960568, lng: 34.907151320136165 },
      { lat: -13.887834161029566, lng: 35.26795617039801 },
      { lat: -14.611045830954332, lng: 35.68684533055594 },
      { lat: -15.896858819240725, lng: 35.77190473810836 },
      { lat: -16.10744028083011, lng: 35.33906294123164 },
      { lat: -16.801299737213093, lng: 35.033810255683534 },
      { lat: -16.183559665596043, lng: 34.38129194513405 },
      { lat: -15.478641452702595, lng: 34.307291294092096 },
      { lat: -15.013708591372612, lng: 34.51766604995231 },
      { lat: -14.613009535381424, lng: 34.45963341648854 },
      { lat: -14.359950046448121, lng: 34.064825473778626 },
      { lat: -14.451830743063072, lng: 33.789700148256685 },
      { lat: -13.971860039936153, lng: 33.214024692525214 },
      { lat: -13.712857761289275, lng: 32.68816531752313 },
      { lat: -12.783870537978272, lng: 32.991764357237884 },
      { lat: -12.435778090060218, lng: 33.306422153463075 },
      { lat: -11.607198174692314, lng: 33.11428917820191 },
      { lat: -10.796549981329697, lng: 33.315310499817286 },
      { lat: -10.525558770391115, lng: 33.48568769708359 },
      { lat: -9.6767216935648, lng: 33.2313879737753 },
      { lat: -9.230599053589058, lng: 32.75937544122132 },
      { lat: -9.417150974162723, lng: 33.73972903823045 },
      { lat: -9.693673841980294, lng: 33.94083772409653 },
      { lat: -10.159999688358404, lng: 34.28000613784198 },
      { lat: -11.520020033415925, lng: 34.55998904799935 },
    ],
  ],
  MX: [
    [
      { lat: 25.869997463478395, lng: -97.14000830767071 },
      { lat: 24.992144069920297, lng: -97.52807247596655 },
      { lat: 24.272343044526735, lng: -97.70294552284223 },
      { lat: 22.932579860927657, lng: -97.77604183631905 },
      { lat: 22.44421173755336, lng: -97.87236670611111 },
      { lat: 21.898689480064263, lng: -97.69904395220419 },
      { lat: 21.411018988525825, lng: -97.38895952023677 },
      { lat: 20.635433254473128, lng: -97.18933346229329 },
      { lat: 19.890930894444068, lng: -96.52557552772032 },
      { lat: 19.320371405509547, lng: -96.29212724484177 },
      { lat: 18.82802419684873, lng: -95.90088497595995 },
      { lat: 18.562717393462208, lng: -94.83906348344271 },
      { lat: 18.144370835843347, lng: -94.4257295397562 },
      { lat: 18.423836981677937, lng: -93.5486512926824 },
      { lat: 18.52483856859226, lng: -92.7861138577835 },
      { lat: 18.704569200103432, lng: -92.0373481920904 },
      { lat: 18.87608327888023, lng: -91.40790340855926 },
      { lat: 19.28412038825678, lng: -90.77186987991087 },
      { lat: 19.8674181177513, lng: -90.53358985061305 },
      { lat: 20.707521877520435, lng: -90.45147599970124 },
      { lat: 20.99985545499555, lng: -90.27861833368489 },
      { lat: 21.26172577563449, lng: -89.60132117385149 },
      { lat: 21.49367544197662, lng: -88.54386633986284 },
      { lat: 21.458845526611977, lng: -87.65841651075772 },
      { lat: 21.543543199138295, lng: -87.05189022494807 },
      { lat: 21.331514797444754, lng: -86.81198238803296 },
      { lat: 20.849864610268355, lng: -86.84590796583262 },
      { lat: 20.25540477139873, lng: -87.38329118523586 },
      { lat: 19.64655304613592, lng: -87.62105445021075 },
      { lat: 19.47240346931227, lng: -87.43675045444176 },
      { lat: 19.04013011319074, lng: -87.58656043165593 },
      { lat: 18.25981598558343, lng: -87.83719112827151 },
      { lat: 18.51664785407405, lng: -88.09066402866318 },
      { lat: 18.49998220466, lng: -88.30003109409364 },
      { lat: 18.48683055264172, lng: -88.4901228502793 },
      { lat: 17.883198147040332, lng: -88.84834387892658 },
      { lat: 18.00151133877256, lng: -89.02985734735176 },
      { lat: 17.955467637600407, lng: -89.15090938999549 },
      { lat: 17.808318996649405, lng: -89.14308041050333 },
      { lat: 17.81932607672752, lng: -90.0679335192309 },
      { lat: 17.817594916245696, lng: -91.00151994501596 },
      { lat: 17.25465770107428, lng: -91.00226925328417 },
      { lat: 17.252177232324186, lng: -91.45392127151511 },
      { lat: 16.91847667079952, lng: -91.0816700915006 },
      { lat: 16.687483018454767, lng: -90.71182186558764 },
      { lat: 16.47077789963879, lng: -90.60084672724093 },
      { lat: 16.41010976812811, lng: -90.438866950222 },
      { lat: 16.069562079324726, lng: -90.46447262242265 },
      { lat: 16.066564846251765, lng: -91.74796017125595 },
      { lat: 15.251446641495873, lng: -92.2292486234063 },
      { lat: 15.064584662328512, lng: -92.08721594925203 },
      { lat: 14.83010285080411, lng: -92.20322953974727 },
      { lat: 14.538828640190957, lng: -92.22775000686983 },
      { lat: 15.615429592343672, lng: -93.35946387406176 },
      { lat: 15.940164292865914, lng: -93.87516883011851 },
      { lat: 16.200975246642884, lng: -94.69165646033014 },
      { lat: 16.128318182840644, lng: -95.25022701697304 },
      { lat: 15.752087917539596, lng: -96.05338212765331 },
      { lat: 15.65351512294279, lng: -96.55743404822829 },
      { lat: 15.917064927631316, lng: -97.26359249549665 },
      { lat: 16.107311713113912, lng: -98.01302995480961 },
      { lat: 16.566043402568763, lng: -98.94767574745651 },
      { lat: 16.70616404872817, lng: -99.69739742714705 },
      { lat: 17.17107107184205, lng: -100.82949886758131 },
      { lat: 17.649026394109626, lng: -101.66608862995446 },
      { lat: 17.916090196193977, lng: -101.91852800170022 },
      { lat: 17.975750637275098, lng: -102.47813208698891 },
      { lat: 18.29229462327885, lng: -103.50098954955808 },
      { lat: 18.74857168220001, lng: -103.91752743204682 },
      { lat: 19.316133938061682, lng: -104.9920096504755 },
      { lat: 19.946767279535436, lng: -105.49303849976144 },
      { lat: 20.434101874264115, lng: -105.73139604370766 },
      { lat: 20.531718654863425, lng: -105.39777299683135 },
      { lat: 20.81689504646613, lng: -105.50066077352443 },
      { lat: 21.07628489835514, lng: -105.27075232625793 },
      { lat: 21.42210358325235, lng: -105.26581722697402 },
      { lat: 21.871145941652568, lng: -105.6031609769754 },
      { lat: 22.269080308516152, lng: -105.69341386597313 },
      { lat: 22.773752346278627, lng: -106.02871639689897 },
      { lat: 23.767774359628902, lng: -106.90998043498837 },
      { lat: 24.54891531015295, lng: -107.91544877809139 },
      { lat: 25.17231395110593, lng: -108.40190487347098 },
      { lat: 25.58060944264406, lng: -109.26019873740665 },
      { lat: 25.824883938087677, lng: -109.44408932171734 },
      { lat: 26.442934068298428, lng: -109.29164384645627 },
      { lat: 26.676175645447927, lng: -109.80145768923182 },
      { lat: 27.16211497650454, lng: -110.3917317370857 },
      { lat: 27.859876003525528, lng: -110.64101884646163 },
      { lat: 27.941240546169066, lng: -111.17891883018785 },
      { lat: 28.46795258230395, lng: -111.75960689985163 },
      { lat: 28.95440867768349, lng: -112.2282346260904 },
      { lat: 29.266844387320074, lng: -112.27182369672869 },
      { lat: 30.021113593052345, lng: -112.80959448937398 },
      { lat: 30.78688080496943, lng: -113.16381059451868 },
      { lat: 31.17096588797892, lng: -113.14866939985717 },
      { lat: 31.567608344035193, lng: -113.87188106978186 },
      { lat: 31.52404511161313, lng: -114.2057366606035 },
      { lat: 31.799532172161147, lng: -114.77645117883503 },
      { lat: 31.3934846054276, lng: -114.93669979537212 },
      { lat: 30.913617255165267, lng: -114.77123185917351 },
      { lat: 30.162681179315992, lng: -114.67389929895177 },
      { lat: 29.75043244070741, lng: -114.33097449426292 },
      { lat: 29.061611436473015, lng: -113.58887508833544 },
      { lat: 28.82617361095123, lng: -113.42405310754054 },
      { lat: 28.7547826197399, lng: -113.27196936730553 },
      { lat: 28.411289374295958, lng: -113.14003943566439 },
      { lat: 28.42519033458251, lng: -112.9622983467965 },
      { lat: 27.780216783147523, lng: -112.76158708377488 },
      { lat: 27.52581370697476, lng: -112.45791052941166 },
      { lat: 27.17172679291076, lng: -112.2449519519368 },
      { lat: 26.662817287700477, lng: -111.6164890206192 },
      { lat: 25.732589830014433, lng: -111.28467464887302 },
      { lat: 25.294606228124564, lng: -110.98781938357239 },
      { lat: 24.82600434010186, lng: -110.71000688357134 },
      { lat: 24.298594672131117, lng: -110.65504899782887 },
      { lat: 24.265547593680424, lng: -110.17285620811343 },
      { lat: 23.811182562754198, lng: -109.77184709352855 },
      { lat: 23.36467234953625, lng: -109.4091043770557 },
      { lat: 23.1855876734287, lng: -109.43339230023292 },
      { lat: 22.818271592698068, lng: -109.85421932660171 },
      { lat: 22.823077500901206, lng: -110.03139197471444 },
      { lat: 23.43097321216669, lng: -110.29507097048366 },
      { lat: 24.00096426034599, lng: -110.94950130902805 },
      { lat: 24.484423122652515, lng: -111.67056840701268 },
      { lat: 24.738412787367167, lng: -112.18203589562147 },
      { lat: 25.47012523040405, lng: -112.14898881717085 },
      { lat: 26.012004299416613, lng: -112.3007108223797 },
      { lat: 26.32195954030317, lng: -112.77729671919155 },
      { lat: 26.768185533143424, lng: -113.46467078332194 },
      { lat: 26.639459540304472, lng: -113.59672990604383 },
      { lat: 26.90006378835244, lng: -113.84893673384424 },
      { lat: 27.142090358991368, lng: -114.46574662968003 },
      { lat: 27.72272675222291, lng: -115.055142178185 },
      { lat: 27.798200181585116, lng: -114.98225257043741 },
      { lat: 27.74148529714489, lng: -114.57036556685495 },
      { lat: 28.115002549750553, lng: -114.19932878299925 },
      { lat: 28.566111965442303, lng: -114.16201839888463 },
      { lat: 29.279479275015486, lng: -114.93184221073663 },
      { lat: 29.556361599235398, lng: -115.518653937627 },
      { lat: 30.180793768834178, lng: -115.88736528202958 },
      { lat: 30.83646434175358, lng: -116.25835038945293 },
      { lat: 31.635743720012044, lng: -116.72152625208498 },
      { lat: 32.53534, lng: -117.12775999999985 },
      { lat: 32.61239000000012, lng: -115.99135 },
      { lat: 32.72083, lng: -114.72139 },
      { lat: 32.52528, lng: -114.815 },
      { lat: 32.03914, lng: -113.30498 },
      { lat: 31.33472, lng: -111.02361 },
      { lat: 31.341940000000136, lng: -109.035 },
      { lat: 31.34222, lng: -108.24194 },
      { lat: 31.75485371816637, lng: -108.24 },
      { lat: 31.75452, lng: -106.50759 },
      { lat: 31.39995, lng: -106.1429 },
      { lat: 31.08383, lng: -105.63159 },
      { lat: 30.64402, lng: -105.03737 },
      { lat: 30.12173, lng: -104.70575 },
      { lat: 29.57196, lng: -104.4569699999999 },
      { lat: 29.27, lng: -103.94 },
      { lat: 28.97, lng: -103.11 },
      { lat: 29.76, lng: -102.48 },
      { lat: 29.7793, lng: -101.6624 },
      { lat: 29.380710000000132, lng: -100.9576 },
      { lat: 28.696120000000118, lng: -100.45584 },
      { lat: 28.110000000000127, lng: -100.11 },
      { lat: 27.54, lng: -99.52 },
      { lat: 26.84, lng: -99.3 },
      { lat: 26.37, lng: -99.02 },
      { lat: 26.06, lng: -98.24 },
      { lat: 25.84, lng: -97.53 },
      { lat: 25.869997463478395, lng: -97.14000830767071 },
    ],
  ],
  MY: [
    [
      { lat: 4.478202419447541, lng: 118.61832075406485 },
      { lat: 4.137551377779488, lng: 117.88203494677019 },
      { lat: 4.306094061699469, lng: 117.01521447150637 },
      { lat: 4.306559149590157, lng: 115.86551720587677 },
      { lat: 3.169238389494396, lng: 115.51907840379201 },
      { lat: 2.821481838386219, lng: 115.13403730678523 },
      { lat: 1.430688177898887, lng: 114.6213554220175 },
      { lat: 1.217548732911041, lng: 113.80584964401956 },
      { lat: 1.497790025229946, lng: 112.8598091980522 },
      { lat: 1.410120957846758, lng: 112.38025190638368 },
      { lat: 0.904441229654651, lng: 111.79754845586044 },
      { lat: 0.976478176269509, lng: 111.15913781132659 },
      { lat: 0.773131415200993, lng: 110.51406090702713 },
      { lat: 1.338135687664192, lng: 109.83022667850886 },
      { lat: 2.006466986494985, lng: 109.66326012577375 },
      { lat: 1.663774725751395, lng: 110.39613528853707 },
      { lat: 1.850636704918784, lng: 111.1688529805975 },
      { lat: 2.697303371588873, lng: 111.3700810079421 },
      { lat: 2.885896511238073, lng: 111.79692833867287 },
      { lat: 3.102394924324869, lng: 112.99561486211527 },
      { lat: 3.893509426281128, lng: 113.71293541875873 },
      { lat: 4.52587392823682, lng: 114.20401655482843 },
      { lat: 4.00763682699781, lng: 114.65959598191355 },
      { lat: 4.348313706881952, lng: 114.8695573263154 },
      { lat: 4.316636053887009, lng: 115.34746097215069 },
      { lat: 4.955227565933825, lng: 115.40570031134362 },
      { lat: 5.447729803891561, lng: 115.45071048386981 },
      { lat: 6.143191229675621, lng: 116.22074100145099 },
      { lat: 6.924771429873998, lng: 116.72510298061978 },
      { lat: 6.928052883324567, lng: 117.12962609260049 },
      { lat: 6.422166449403306, lng: 117.64339318244633 },
      { lat: 5.987490139180181, lng: 117.68907514859237 },
      { lat: 5.708695786965464, lng: 118.3476912781522 },
      { lat: 5.407835598162251, lng: 119.18190392463994 },
      { lat: 5.016128241389865, lng: 119.11069380094172 },
      { lat: 4.96651886638962, lng: 118.43972700406411 },
      { lat: 4.478202419447541, lng: 118.61832075406485 },
    ],
    [
      { lat: 6.204867051615892, lng: 101.07551557821333 },
      { lat: 5.691384182147715, lng: 101.15421878459384 },
      { lat: 5.810808417174228, lng: 101.81428185425804 },
      { lat: 6.221636053894656, lng: 102.14118696493645 },
      { lat: 6.12820506431096, lng: 102.37114708863524 },
      { lat: 5.524495144061078, lng: 102.9617053568667 },
      { lat: 4.855001125503748, lng: 103.38121463421217 },
      { lat: 4.181605536308382, lng: 103.4385754740562 },
      { lat: 3.726697902842971, lng: 103.33212202353488 },
      { lat: 3.38286876058902, lng: 103.42942874554055 },
      { lat: 2.791018581550205, lng: 103.50244754436889 },
      { lat: 2.515454006353763, lng: 103.85467410687036 },
      { lat: 1.631141058759056, lng: 104.24793175661151 },
      { lat: 1.293048000489534, lng: 104.22881147666354 },
      { lat: 1.226333726400682, lng: 103.51970747275443 },
      { lat: 1.967115383304744, lng: 102.57361535035479 },
      { lat: 2.760813706875624, lng: 101.39063846232918 },
      { lat: 3.270291652841181, lng: 101.27353966675585 },
      { lat: 3.93913971599487, lng: 100.6954354187067 },
      { lat: 4.76728038168828, lng: 100.55740766805509 },
      { lat: 5.31249258058368, lng: 100.19670617065773 },
      { lat: 6.040561835143876, lng: 100.30626020711652 },
      { lat: 6.46448944745029, lng: 100.08575687052709 },
      { lat: 6.642824815289572, lng: 100.25959638875692 },
      { lat: 6.204867051615892, lng: 101.07551557821333 },
    ],
  ],
  MZ: [
    [
      { lat: -11.520020033415925, lng: 34.55998904799935 },
      { lat: -11.439146416879147, lng: 35.31239790216904 },
      { lat: -11.720938002166733, lng: 36.51408165868426 },
      { lat: -11.594537448780805, lng: 36.775150994622805 },
      { lat: -11.56875090906716, lng: 37.47128421402661 },
      { lat: -11.268769219612835, lng: 37.82764489111139 },
      { lat: -11.285202325081656, lng: 38.42755659358775 },
      { lat: -10.896853936408226, lng: 39.521029900883775 },
      { lat: -10.317096042525698, lng: 40.31658857601719 },
      { lat: -10.765440769089993, lng: 40.47838748552303 },
      { lat: -11.761710707245015, lng: 40.437253045418686 },
      { lat: -12.639176527561027, lng: 40.56081139502857 },
      { lat: -14.201975192931862, lng: 40.59962039567975 },
      { lat: -14.691764418194241, lng: 40.775475294768995 },
      { lat: -15.406294447493972, lng: 40.4772506040126 },
      { lat: -16.10077402106446, lng: 40.08926395036522 },
      { lat: -16.72089120856694, lng: 39.45255862809705 },
      { lat: -17.101023044505958, lng: 38.53835086442152 },
      { lat: -17.586368096591237, lng: 37.41113284683888 },
      { lat: -18.65968759529345, lng: 36.28127933120936 },
      { lat: -18.842260430580634, lng: 35.89649661636406 },
      { lat: -19.552811374593894, lng: 35.198399692533144 },
      { lat: -19.784011732667736, lng: 34.78638349787005 },
      { lat: -20.49704314543101, lng: 34.70189253107284 },
      { lat: -21.25436126066841, lng: 35.176127150215365 },
      { lat: -21.840837090748877, lng: 35.37342776870574 },
      { lat: -22.14, lng: 35.385848253705404 },
      { lat: -22.09, lng: 35.562545536369086 },
      { lat: -23.070787855727758, lng: 35.533934767404304 },
      { lat: -23.5353589820317, lng: 35.37177412287238 },
      { lat: -23.706563002214683, lng: 35.60747033055563 },
      { lat: -24.12260995859655, lng: 35.45874555841962 },
      { lat: -24.478350518493805, lng: 35.04073489761066 },
      { lat: -24.81631438568266, lng: 34.21582400893547 },
      { lat: -25.357573337507738, lng: 33.01321007663901 },
      { lat: -25.72731821055609, lng: 32.574632195777866 },
      { lat: -26.148584486599443, lng: 32.66036339695009 },
      { lat: -26.215867201443466, lng: 32.91595503106569 },
      { lat: -26.742191664336197, lng: 32.830120477028885 },
      { lat: -26.73382008230491, lng: 32.07166548028107 },
      { lat: -26.291779880480227, lng: 31.98577924981197 },
      { lat: -25.84333180105135, lng: 31.837777947728064 },
      { lat: -25.484283949487413, lng: 31.75240848158188 },
      { lat: -24.369416599222536, lng: 31.93058882012425 },
      { lat: -23.658969008073864, lng: 31.670397983534652 },
      { lat: -22.2515096981724, lng: 31.191409132621285 },
      { lat: -21.116488539313693, lng: 32.244988234188014 },
      { lat: -20.395292250248307, lng: 32.50869306817344 },
      { lat: -20.304290052982317, lng: 32.65974327976258 },
      { lat: -19.715592136313298, lng: 32.772707960752626 },
      { lat: -19.419382826416275, lng: 32.61199425632489 },
      { lat: -18.672089939043495, lng: 32.65488569512715 },
      { lat: -17.97905730557718, lng: 32.84986087416439 },
      { lat: -16.713398125884616, lng: 32.847638787575846 },
      { lat: -16.392074069893752, lng: 32.32823896661022 },
      { lat: -16.319417006091378, lng: 31.8520406430406 },
      { lat: -16.071990248277885, lng: 31.636498243951195 },
      { lat: -15.860943698797872, lng: 31.173063999157677 },
      { lat: -15.880839125230244, lng: 30.338954705534544 },
      { lat: -15.507786960515212, lng: 30.274255812305107 },
      { lat: -14.796099134991527, lng: 30.17948123548183 },
      { lat: -13.971860039936153, lng: 33.214024692525214 },
      { lat: -14.451830743063072, lng: 33.789700148256685 },
      { lat: -14.359950046448121, lng: 34.064825473778626 },
      { lat: -14.613009535381424, lng: 34.45963341648854 },
      { lat: -15.013708591372612, lng: 34.51766604995231 },
      { lat: -15.478641452702595, lng: 34.307291294092096 },
      { lat: -16.183559665596043, lng: 34.38129194513405 },
      { lat: -16.801299737213093, lng: 35.033810255683534 },
      { lat: -16.10744028083011, lng: 35.33906294123164 },
      { lat: -15.896858819240725, lng: 35.77190473810836 },
      { lat: -14.611045830954332, lng: 35.68684533055594 },
      { lat: -13.887834161029566, lng: 35.26795617039801 },
      { lat: -13.565424899960568, lng: 34.907151320136165 },
      { lat: -13.579997653866876, lng: 34.55998904799935 },
      { lat: -12.280025323132504, lng: 34.28000613784198 },
      { lat: -11.520020033415925, lng: 34.55998904799935 },
    ],
  ],
  NA: [
    [
      { lat: -28.576705010697697, lng: 16.344976840895242 },
      { lat: -27.8212472470228, lng: 15.601818068105814 },
      { lat: -27.090955905874047, lng: 15.21047244635946 },
      { lat: -26.117371921495156, lng: 14.989710727608552 },
      { lat: -25.39292001719538, lng: 14.743214145576331 },
      { lat: -23.853014011329847, lng: 14.408144158595833 },
      { lat: -22.65665292734069, lng: 14.385716586981149 },
      { lat: -22.111208184499954, lng: 14.257714064194175 },
      { lat: -21.699036960539978, lng: 13.86864220546866 },
      { lat: -20.872834161057504, lng: 13.35249799973744 },
      { lat: -19.673165785401665, lng: 12.826845330464492 },
      { lat: -19.0453488094877, lng: 12.608564080463621 },
      { lat: -18.069129327061916, lng: 11.794918654028066 },
      { lat: -17.301889336824473, lng: 11.734198846085121 },
      { lat: -17.111668389558083, lng: 12.215461460019355 },
      { lat: -16.94134286872407, lng: 12.814081251688407 },
      { lat: -16.971211846588773, lng: 13.462362094789967 },
      { lat: -17.423380629142663, lng: 14.05850141770901 },
      { lat: -17.35310068122572, lng: 14.209706658595024 },
      { lat: -17.309950860262006, lng: 18.26330936043416 },
      { lat: -17.789094740472258, lng: 18.956186964603603 },
      { lat: -17.930636488519696, lng: 21.377176141045567 },
      { lat: -17.52311614346598, lng: 23.215048455506064 },
      { lat: -17.295843194246324, lng: 24.033861525170778 },
      { lat: -17.353410739819473, lng: 24.682349074001507 },
      { lat: -17.57882333747662, lng: 25.07695031098226 },
      { lat: -17.661815687737374, lng: 25.08444339366457 },
      { lat: -17.887124932529936, lng: 24.520705193792537 },
      { lat: -17.88934701911849, lng: 24.217364536239213 },
      { lat: -18.28126108162006, lng: 23.579005568137717 },
      { lat: -17.869038181227786, lng: 23.1968583513393 },
      { lat: -18.219146010005225, lng: 21.655040317478978 },
      { lat: -18.252218926672022, lng: 20.910641310314535 },
      { lat: -21.814327080983148, lng: 20.881134067475866 },
      { lat: -21.84915699634787, lng: 19.89545779794068 },
      { lat: -24.767790215760588, lng: 19.895767856534434 },
      { lat: -28.461104831660776, lng: 19.894734327888614 },
      { lat: -28.972443129188864, lng: 19.002127312911085 },
      { lat: -29.04546192801728, lng: 18.464899122804752 },
      { lat: -28.85637786226132, lng: 17.83615197110953 },
      { lat: -28.78351409272978, lng: 17.387497185951503 },
      { lat: -28.35594329194681, lng: 17.218928663815404 },
      { lat: -28.082161553664466, lng: 16.824017368240902 },
      { lat: -28.576705010697697, lng: 16.344976840895242 },
    ],
  ],
  NC: [
    [
      { lat: -21.08000497811563, lng: 165.77998986232637 },
      { lat: -21.700018812753523, lng: 166.59999148993384 },
      { lat: -22.159990736583488, lng: 167.1200114280869 },
      { lat: -22.39997608814695, lng: 166.74003462144478 },
      { lat: -22.12970834726045, lng: 166.18973229396866 },
      { lat: -21.679606621998232, lng: 165.47437544175222 },
      { lat: -21.14981983814195, lng: 164.82981530177568 },
      { lat: -20.444746595951628, lng: 164.16799523341365 },
      { lat: -20.105645847252354, lng: 164.029605747736 },
      { lat: -20.1200118954295, lng: 164.45996707586272 },
      { lat: -20.45999114347773, lng: 165.02003624904205 },
      { lat: -20.80002206795826, lng: 165.46000939357512 },
      { lat: -21.08000497811563, lng: 165.77998986232637 },
    ],
  ],
  NE: [
    [
      { lat: 11.940150051313424, lng: 2.15447350424995 },
      { lat: 12.625017808477537, lng: 2.177107781593918 },
      { lat: 12.851825669806601, lng: 1.024103224297619 },
      { lat: 13.335749620003865, lng: 0.993045688490156 },
      { lat: 13.988733018443893, lng: 0.429927605805517 },
      { lat: 14.444234930880667, lng: 0.295646396495215 },
      { lat: 14.928908189346147, lng: 0.374892205414767 },
      { lat: 14.968182277887989, lng: 1.015783318698482 },
      { lat: 15.32356110275924, lng: 1.385528191746971 },
      { lat: 15.409524847876753, lng: 2.749992709981541 },
      { lat: 15.568119818580442, lng: 3.638258904646591 },
      { lat: 16.184283759012658, lng: 3.723421665063597 },
      { lat: 16.852227484601315, lng: 4.270209995143887 },
      { lat: 19.155265204337127, lng: 4.267419467800096 },
      { lat: 19.6012069767998, lng: 5.677565952180714 },
      { lat: 21.565660712159225, lng: 8.57289310062987 },
      { lat: 23.471668402596432, lng: 11.9995056494717 },
      { lat: 23.04050608976928, lng: 13.581424594790462 },
      { lat: 22.49128896737113, lng: 14.143870883855243 },
      { lat: 22.862950000000126, lng: 14.8513 },
      { lat: 21.30851878507491, lng: 15.096887648181848 },
      { lat: 21.048457139565983, lng: 15.471076694407316 },
      { lat: 20.730414537025638, lng: 15.487148064850146 },
      { lat: 20.387618923417506, lng: 15.903246697664313 },
      { lat: 19.957180080642384, lng: 15.685740594147774 },
      { lat: 17.927949937405003, lng: 15.30044111497972 },
      { lat: 16.627305813050782, lng: 15.247731154041846 },
      { lat: 15.684365953021143, lng: 13.972201775781684 },
      { lat: 14.367133693901222, lng: 13.540393507550789 },
      { lat: 13.996691189016929, lng: 13.956698846094127 },
      { lat: 13.353448798063766, lng: 13.95447675950561 },
      { lat: 13.330426947477859, lng: 14.595781284247607 },
      { lat: 12.859396267137356, lng: 14.495787387762903 },
      { lat: 12.802035427293333, lng: 14.21353071458475 },
      { lat: 12.483656927943171, lng: 14.18133629726691 },
      { lat: 12.461565253138303, lng: 13.995352817448293 },
      { lat: 13.556356309457954, lng: 13.318701613018561 },
      { lat: 13.596147162322495, lng: 13.083987257548813 },
      { lat: 13.037189032437539, lng: 12.30207116054055 },
      { lat: 13.32898000737356, lng: 11.527803175511508 },
      { lat: 13.387322699431195, lng: 10.989593133191532 },
      { lat: 13.246917832894042, lng: 10.701031935273818 },
      { lat: 13.277251898649467, lng: 10.114814487354748 },
      { lat: 12.851102199754564, lng: 9.52492801274309 },
      { lat: 12.826659247280418, lng: 9.014933302454438 },
      { lat: 13.343526923063735, lng: 7.804671258178871 },
      { lat: 13.098038031461215, lng: 7.330746697630047 },
      { lat: 13.115091254117601, lng: 6.820441928747812 },
      { lat: 13.492768459522722, lng: 6.445426059605722 },
      { lat: 13.865923977102225, lng: 5.443058302440135 },
      { lat: 13.747481594289411, lng: 4.368343540066007 },
      { lat: 13.531215725147945, lng: 4.107945997747379 },
      { lat: 12.956108710171577, lng: 3.967282749048934 },
      { lat: 12.55290334721417, lng: 3.680633579125925 },
      { lat: 11.660167141155966, lng: 3.611180454125587 },
      { lat: 12.23563589115821, lng: 2.848643019226586 },
      { lat: 12.233052069543588, lng: 2.490163608418015 },
      { lat: 11.940150051313424, lng: 2.15447350424995 },
    ],
  ],
  NG: [
    [
      { lat: 4.771982937026849, lng: 8.500287713259695 },
      { lat: 4.412108262546241, lng: 7.46210818851594 },
      { lat: 4.464689032403228, lng: 7.082596469764439 },
      { lat: 4.240594183769517, lng: 6.6980721370806 },
      { lat: 4.262453314628985, lng: 5.898172641634687 },
      { lat: 4.887970689305959, lng: 5.362804803090881 },
      { lat: 5.611802476418234, lng: 5.033574252959368 },
      { lat: 6.270651149923467, lng: 4.325607130560683 },
      { lat: 6.258300482605719, lng: 3.574180128604553 },
      { lat: 6.258817246928629, lng: 2.691701694356254 },
      { lat: 7.870734361192888, lng: 2.74906253420022 },
      { lat: 8.50684540448971, lng: 2.723792758809509 },
      { lat: 9.13760793704432, lng: 2.912308383810256 },
      { lat: 9.4441525333997, lng: 3.220351596702101 },
      { lat: 10.063210354040208, lng: 3.705438266625919 },
      { lat: 10.332186184119408, lng: 3.600070021182801 },
      { lat: 10.734745591673105, lng: 3.797112257511714 },
      { lat: 11.327939357951518, lng: 3.572216424177469 },
      { lat: 11.660167141155966, lng: 3.611180454125559 },
      { lat: 12.552903347214226, lng: 3.680633579125811 },
      { lat: 12.956108710171575, lng: 3.967282749048849 },
      { lat: 13.531215725147831, lng: 4.107945997747322 },
      { lat: 13.747481594289324, lng: 4.368343540066064 },
      { lat: 13.865923977102298, lng: 5.443058302440164 },
      { lat: 13.492768459522678, lng: 6.445426059605637 },
      { lat: 13.115091254117518, lng: 6.820441928747754 },
      { lat: 13.0980380314612, lng: 7.330746697630018 },
      { lat: 13.343526923063745, lng: 7.804671258178786 },
      { lat: 12.82665924728043, lng: 9.014933302454466 },
      { lat: 12.851102199754479, lng: 9.524928012742945 },
      { lat: 13.27725189864941, lng: 10.114814487354693 },
      { lat: 13.246917832894084, lng: 10.701031935273704 },
      { lat: 13.38732269943111, lng: 10.989593133191535 },
      { lat: 13.328980007373588, lng: 11.527803175511394 },
      { lat: 13.037189032437524, lng: 12.302071160540523 },
      { lat: 13.596147162322566, lng: 13.08398725754887 },
      { lat: 13.556356309457826, lng: 13.318701613018561 },
      { lat: 12.461565253138346, lng: 13.99535281744835 },
      { lat: 12.483656927943116, lng: 14.181336297266792 },
      { lat: 12.085360826053503, lng: 14.577177768622533 },
      { lat: 11.904751695193411, lng: 14.468192172918975 },
      { lat: 11.572368882692075, lng: 14.415378859116686 },
      { lat: 10.798565985553566, lng: 13.572949659894562 },
      { lat: 10.160362046748928, lng: 13.308676385153918 },
      { lat: 9.640626328973411, lng: 13.167599724997103 },
      { lat: 9.417771714714704, lng: 12.955467970438974 },
      { lat: 8.717762762888995, lng: 12.753671502339214 },
      { lat: 8.305824082874324, lng: 12.218872104550599 },
      { lat: 7.799808457872302, lng: 12.063946160539558 },
      { lat: 7.397042344589436, lng: 11.839308709366803 },
      { lat: 6.981382961449753, lng: 11.74577436691851 },
      { lat: 6.644426784690594, lng: 11.05878787603035 },
      { lat: 7.055357774275564, lng: 10.497375115611417 },
      { lat: 7.038769639509879, lng: 10.118276808318257 },
      { lat: 6.453482367372117, lng: 9.522705926154401 },
      { lat: 6.444490668153334, lng: 9.233162876023044 },
      { lat: 5.479665839047911, lng: 8.757532993208628 },
      { lat: 4.771982937026849, lng: 8.500287713259695 },
    ],
  ],
  NI: [
    [
      { lat: 11.088444932494824, lng: -85.7125404528073 },
      { lat: 11.403438625529944, lng: -86.05848832878526 },
      { lat: 11.806876532432597, lng: -86.52584998243296 },
      { lat: 12.143961900272487, lng: -86.74599158399633 },
      { lat: 12.458257961471656, lng: -87.16751624220116 },
      { lat: 12.909909979702633, lng: -87.66849341505471 },
      { lat: 13.064551703336065, lng: -87.5574666002756 },
      { lat: 12.914018256069838, lng: -87.39238623731923 },
      { lat: 12.984685777228975, lng: -87.31665442579549 },
      { lat: 13.025794379117157, lng: -87.00576900912756 },
      { lat: 13.254204209847245, lng: -86.88055701368437 },
      { lat: 13.263092556201443, lng: -86.7338217841916 },
      { lat: 13.754845485890913, lng: -86.7550866360797 },
      { lat: 13.77848745366444, lng: -86.5207081774199 },
      { lat: 13.77135610600817, lng: -86.31214209668993 },
      { lat: 14.038187364147248, lng: -86.0962638007906 },
      { lat: 13.83605499923759, lng: -85.80129472526859 },
      { lat: 13.960078436738087, lng: -85.69866533073693 },
      { lat: 14.079011745657837, lng: -85.51441301140025 },
      { lat: 14.354369615125078, lng: -85.1653645494848 },
      { lat: 14.560196844943619, lng: -85.14875057650296 },
      { lat: 14.551541042534723, lng: -85.05278744173692 },
      { lat: 14.790492865452352, lng: -84.9245006985724 },
      { lat: 14.819586696832669, lng: -84.82003679069435 },
      { lat: 14.666805324761754, lng: -84.64958207877962 },
      { lat: 14.621614284722495, lng: -84.4493359036486 },
      { lat: 14.748764146376658, lng: -84.22834164095241 },
      { lat: 14.749435939996461, lng: -83.97572140169359 },
      { lat: 14.880073960830302, lng: -83.62858496777292 },
      { lat: 15.016267198135536, lng: -83.48998877636612 },
      { lat: 14.99582916916411, lng: -83.14721900097413 },
      { lat: 14.899866034398102, lng: -83.23323442252394 },
      { lat: 14.6766238468972, lng: -83.2841615465476 },
      { lat: 14.31070302983845, lng: -83.18212643098728 },
      { lat: 13.970077826386557, lng: -83.41249996614445 },
      { lat: 13.567699286345883, lng: -83.51983191601468 },
      { lat: 13.127054348193086, lng: -83.55220720084554 },
      { lat: 12.869292303921227, lng: -83.49851538769427 },
      { lat: 12.419087225794428, lng: -83.47332312695198 },
      { lat: 12.320850328007566, lng: -83.62610449902292 },
      { lat: 11.893124497927726, lng: -83.71961300325506 },
      { lat: 11.629032090700118, lng: -83.65085751009072 },
      { lat: 11.373311265503787, lng: -83.8554703437504 },
      { lat: 11.103043524617274, lng: -83.80893571647155 },
      { lat: 10.938764146361422, lng: -83.65561174186158 },
      { lat: 10.726839097532446, lng: -83.89505449088595 },
      { lat: 10.793450018756674, lng: -84.19017859570485 },
      { lat: 10.999225572142905, lng: -84.35593075228104 },
      { lat: 11.082657172078143, lng: -84.67306901725627 },
      { lat: 10.952303371621896, lng: -84.90300330273895 },
      { lat: 11.217119248901597, lng: -85.56185197624418 },
      { lat: 11.088444932494824, lng: -85.7125404528073 },
    ],
  ],
  NL: [
    [
      { lat: 53.510403347378144, lng: 6.074182570020923 },
      { lat: 53.48216217713064, lng: 6.905139601274129 },
      { lat: 53.14404328064489, lng: 7.092053256873896 },
      { lat: 52.22844025329755, lng: 6.842869500362383 },
      { lat: 51.852029120483394, lng: 6.589396599970826 },
      { lat: 51.851615709025054, lng: 5.988658074577813 },
      { lat: 50.80372101501058, lng: 6.156658155958779 },
      { lat: 51.037298488969775, lng: 5.606975945670001 },
      { lat: 51.475023708698124, lng: 4.973991326526914 },
      { lat: 51.26725861266857, lng: 4.047071160507527 },
      { lat: 51.34575511331991, lng: 3.314971144228537 },
      { lat: 51.62054454203195, lng: 3.830288527043137 },
      { lat: 53.09179840759776, lng: 4.705997348661185 },
      { lat: 53.510403347378144, lng: 6.074182570020923 },
    ],
  ],
  NO: [
    [
      { lat: 71.18547435168051, lng: 28.165547316202915 },
      { lat: 70.45378774685992, lng: 31.29341840996548 },
      { lat: 70.1862588568849, lng: 30.005435011522792 },
      { lat: 69.55808014594486, lng: 31.10107872897512 },
      { lat: 69.15691600206307, lng: 29.399580519332886 },
      { lat: 69.0647769232867, lng: 28.591929559043194 },
      { lat: 69.76649119737797, lng: 29.01557295097197 },
      { lat: 70.16419302029628, lng: 27.73229210786789 },
      { lat: 69.82529897732616, lng: 26.1796220232263 },
      { lat: 69.09211375596902, lng: 25.68921268077639 },
      { lat: 68.64955678982145, lng: 24.73567915212672 },
      { lat: 68.89124746365053, lng: 23.662049594830762 },
      { lat: 68.84174144151494, lng: 22.356237827247412 },
      { lat: 69.37044302029312, lng: 21.24493615081073 },
      { lat: 69.10624726020085, lng: 20.64559288908958 },
      { lat: 69.06513865831272, lng: 20.025268995857914 },
      { lat: 68.40719432237262, lng: 19.878559604581255 },
      { lat: 68.56739126247734, lng: 17.99386844246439 },
      { lat: 68.01055186631623, lng: 17.729181756265348 },
      { lat: 68.01393667263139, lng: 16.76887861498554 },
      { lat: 67.3024555528369, lng: 16.108712192456835 },
      { lat: 66.19386688909543, lng: 15.108411492583059 },
      { lat: 64.78702769638147, lng: 13.55568973150909 },
      { lat: 64.44542064071611, lng: 13.919905226302205 },
      { lat: 64.04911408146967, lng: 13.57191613124877 },
      { lat: 64.06621898055835, lng: 12.57993533697393 },
      { lat: 63.128317572676984, lng: 11.93056928879423 },
      { lat: 61.800362453856565, lng: 11.992064243221535 },
      { lat: 61.29357168237009, lng: 12.631146681375242 },
      { lat: 60.11793284773006, lng: 12.3003658382749 },
      { lat: 59.432393296946, lng: 11.468271925511175 },
      { lat: 58.856149400459394, lng: 11.027368605196926 },
      { lat: 59.46980703392538, lng: 10.356556837616097 },
      { lat: 58.31328847923328, lng: 8.382000359743643 },
      { lat: 58.07888418235728, lng: 7.048748406613299 },
      { lat: 58.58815542259367, lng: 5.665835402050419 },
      { lat: 59.66323191999382, lng: 5.308234490590735 },
      { lat: 61.970998033284275, lng: 4.992078077829007 },
      { lat: 62.614472968182696, lng: 5.912900424837885 },
      { lat: 63.45400828719647, lng: 8.553411085655766 },
      { lat: 64.48603831649748, lng: 10.527709181366788 },
      { lat: 65.87972585719316, lng: 12.358346795306375 },
      { lat: 67.81064158799515, lng: 14.761145867581604 },
      { lat: 68.56320547146169, lng: 16.43592736172897 },
      { lat: 69.81744415961782, lng: 19.184028354578516 },
      { lat: 70.25516937934606, lng: 21.378416375420613 },
      { lat: 70.20207184516626, lng: 23.023742303161583 },
      { lat: 71.03049673123724, lng: 24.546543409938522 },
      { lat: 70.98626170519537, lng: 26.370049676221807 },
      { lat: 71.18547435168051, lng: 28.165547316202915 },
    ],
  ],
  NP: [
    [
      { lat: 27.876541652939594, lng: 88.12044070836987 },
      { lat: 27.445818589786825, lng: 88.04313276566123 },
      { lat: 26.81040517832595, lng: 88.17480431514092 },
      { lat: 26.41461538340249, lng: 88.06023766474982 },
      { lat: 26.397898057556077, lng: 87.22747195836628 },
      { lat: 26.63098460540857, lng: 86.02439293817918 },
      { lat: 26.72619843190634, lng: 85.25177859898338 },
      { lat: 27.234901231387536, lng: 84.6750179381738 },
      { lat: 27.36450572357556, lng: 83.30424889519955 },
      { lat: 27.925479234319994, lng: 81.99998742058497 },
      { lat: 28.416095282499043, lng: 81.05720258985203 },
      { lat: 28.79447011974014, lng: 80.08842451367627 },
      { lat: 29.72986522065534, lng: 80.4767212259174 },
      { lat: 30.183480943313402, lng: 81.11125613802932 },
      { lat: 30.42271698660863, lng: 81.52580447787474 },
      { lat: 30.115268052688133, lng: 82.32751264845088 },
      { lat: 29.463731594352193, lng: 83.33711510613719 },
      { lat: 29.320226141877658, lng: 83.89899295444673 },
      { lat: 28.839893703724698, lng: 84.23457970575015 },
      { lat: 28.642773952747344, lng: 85.01163821812304 },
      { lat: 28.203575954698703, lng: 85.82331994013151 },
      { lat: 27.974261786403517, lng: 86.9545170430006 },
      { lat: 27.876541652939594, lng: 88.12044070836987 },
    ],
  ],
  NR: [
    [
      { lat: -0.4912479832482, lng: 166.90865903496 },
      { lat: -0.48128419486616, lng: 166.93493410499 },
      { lat: -0.48144525005834, lng: 166.94023255044 },
      { lat: -0.49010444860919, lng: 166.95916543692 },
      { lat: -0.50131145977217, lng: 166.96301439839 },
      { lat: -0.5359130704372, lng: 166.96023844391 },
      { lat: -0.55577253704927, lng: 166.94267950338 },
      { lat: -0.55684519475431, lng: 166.94035776032 },
      { lat: -0.55708960003327, lng: 166.92734738017 },
      { lat: -0.55087589084608, lng: 166.91164539483 },
      { lat: -0.52941767592473, lng: 166.90302441238 },
      { lat: -0.50064012611387, lng: 166.90426164619 },
      { lat: -0.4912479832482, lng: 166.90865903496 },
    ],
  ],
  NU: [
    [
      { lat: -18.906286495910905, lng: -169.85137939453125 },
      { lat: -19.046541312042145, lng: -170.01068115234375 },
      { lat: -19.197053439464852, lng: -169.87335205078122 },
      { lat: -19.067309748918014, lng: -169.73327636718747 },
      { lat: -18.906286495910905, lng: -169.85137939453125 },
    ],
  ],
  NZ: [
    [
      { lat: -40.919052422856424, lng: 173.02037479074076 },
      { lat: -41.331998793300784, lng: 173.24723432850206 },
      { lat: -40.92670053483562, lng: 173.95840538970288 },
      { lat: -41.34915536882167, lng: 174.24758670480813 },
      { lat: -41.770008233406756, lng: 174.2485168805895 },
      { lat: -42.233184096038826, lng: 173.8764465680879 },
      { lat: -42.970038344088564, lng: 173.22273969959568 },
      { lat: -43.372287693048506, lng: 172.71124637277077 },
      { lat: -43.85334360125358, lng: 173.0801127464702 },
      { lat: -43.865694268571346, lng: 172.30858361235252 },
      { lat: -44.24251881284372, lng: 171.45292524646365 },
      { lat: -44.89710418068489, lng: 171.18513797432726 },
      { lat: -45.90892872495971, lng: 170.61669721911662 },
      { lat: -46.3557748349876, lng: 169.8314221540093 },
      { lat: -46.641235446967855, lng: 169.33233117093428 },
      { lat: -46.61994475686359, lng: 168.41135379462858 },
      { lat: -46.29019744240921, lng: 167.76374474514685 },
      { lat: -46.21991749449224, lng: 166.67688602118423 },
      { lat: -45.85270476662622, lng: 166.5091443219647 },
      { lat: -45.11094125750867, lng: 167.04642418850327 },
      { lat: -44.12397307716613, lng: 168.3037634625969 },
      { lat: -43.93581918719142, lng: 168.94940880765157 },
      { lat: -43.55532561622634, lng: 169.66781456937318 },
      { lat: -43.03168832781283, lng: 170.52491987536618 },
      { lat: -42.51275359473778, lng: 171.125089960004 },
      { lat: -41.767424411792135, lng: 171.56971398344322 },
      { lat: -41.51441659929115, lng: 171.94870893787194 },
      { lat: -40.95610442480968, lng: 172.09722700427878 },
      { lat: -40.493962090823466, lng: 172.798579543344 },
      { lat: -40.919052422856424, lng: 173.02037479074076 },
    ],
    [
      { lat: -36.156397393540544, lng: 174.61200890533055 },
      { lat: -37.20909799575826, lng: 175.3366158389272 },
      { lat: -36.52619394302113, lng: 175.35759647043753 },
      { lat: -36.79894215265769, lng: 175.8088867536425 },
      { lat: -37.55538176854606, lng: 175.9584900251275 },
      { lat: -37.8812533505787, lng: 176.76319542877658 },
      { lat: -37.961248467766495, lng: 177.4388131045605 },
      { lat: -37.57982472102013, lng: 178.0103544457087 },
      { lat: -37.6953732236248, lng: 178.51709354076283 },
      { lat: -38.58281259537309, lng: 178.27473107331386 },
      { lat: -39.166342868812976, lng: 177.97046023997936 },
      { lat: -39.145775648760846, lng: 177.20699262929915 },
      { lat: -39.44973642350158, lng: 176.93998050364704 },
      { lat: -39.87994272233148, lng: 177.0329464053401 },
      { lat: -40.065977878582174, lng: 176.88582360260526 },
      { lat: -40.60480803808959, lng: 176.50801720611938 },
      { lat: -41.28962411882151, lng: 176.0124402204403 },
      { lat: -41.68830779395324, lng: 175.239567499083 },
      { lat: -41.42589487077508, lng: 175.0678983910094 },
      { lat: -41.28182097754545, lng: 174.65097293527847 },
      { lat: -40.459235528323404, lng: 175.22763024322367 },
      { lat: -39.90893320084723, lng: 174.90015669179 },
      { lat: -39.50885426204351, lng: 173.82404666574402 },
      { lat: -39.14660247167746, lng: 173.85226199777534 },
      { lat: -38.797683200842755, lng: 174.5748018740804 },
      { lat: -38.027807712558385, lng: 174.74347374908106 },
      { lat: -37.38112883885796, lng: 174.69701663645063 },
      { lat: -36.71109221776144, lng: 174.29202843657922 },
      { lat: -36.53482390721389, lng: 174.31900353423555 },
      { lat: -36.121980889634116, lng: 173.84099653553582 },
      { lat: -35.23712533950034, lng: 173.0541711774596 },
      { lat: -34.52910654066939, lng: 172.63600548735374 },
      { lat: -34.45066171645034, lng: 173.00704227120949 },
      { lat: -35.006183363587965, lng: 173.55129845610747 },
      { lat: -35.26549570082862, lng: 174.3293904971263 },
      { lat: -36.156397393540544, lng: 174.61200890533055 },
    ],
  ],
  OM: [
    [
      { lat: 21.114034532144302, lng: 58.86114139184659 },
      { lat: 20.42898590746711, lng: 58.48798587426696 },
      { lat: 20.48143748624335, lng: 58.034318475176605 },
      { lat: 20.24300242764863, lng: 57.82637251163411 },
      { lat: 19.736004950433113, lng: 57.665762160070955 },
      { lat: 19.06757029873765, lng: 57.788700392493375 },
      { lat: 18.944709580963803, lng: 57.69439090356068 },
      { lat: 18.947991034414258, lng: 57.234263950433814 },
      { lat: 18.57426707607948, lng: 56.609650913321985 },
      { lat: 18.087113348863937, lng: 56.512189162019496 },
      { lat: 17.87606679938395, lng: 56.28352094912801 },
      { lat: 17.88412832282154, lng: 55.6614917336307 },
      { lat: 17.632309068263197, lng: 55.2699394061552 },
      { lat: 17.228354397037663, lng: 55.274900343655105 },
      { lat: 16.950696926333364, lng: 54.79100223167413 },
      { lat: 17.044980577049984, lng: 54.239252964093765 },
      { lat: 16.707662665264678, lng: 53.570508253804604 },
      { lat: 16.65105113368898, lng: 53.10857262554751 },
      { lat: 17.349742336491232, lng: 52.78218427919207 },
      { lat: 19.000003363516072, lng: 52.00000980002224 },
      { lat: 19.99999400479612, lng: 54.99998172386242 },
      { lat: 22.00000112557231, lng: 55.66665937685988 },
      { lat: 22.70832998299701, lng: 55.2083410988632 },
      { lat: 23.11099274341535, lng: 55.234489373602884 },
      { lat: 23.524869289640918, lng: 55.5258410988645 },
      { lat: 23.933604030853502, lng: 55.52863162620829 },
      { lat: 24.130542914317854, lng: 55.98121382022052 },
      { lat: 24.269604193615294, lng: 55.80411868675625 },
      { lat: 24.920830593357493, lng: 55.886232537668064 },
      { lat: 24.924732163995515, lng: 56.396847365144 },
      { lat: 24.241673081961494, lng: 56.84514041527606 },
      { lat: 23.87859446867884, lng: 57.40345258975744 },
      { lat: 23.74793060962884, lng: 58.13694786970834 },
      { lat: 23.565667832935418, lng: 58.72921146020544 },
      { lat: 22.99239533130546, lng: 59.18050174341036 },
      { lat: 22.6602709009656, lng: 59.45009769067703 },
      { lat: 22.533611965418203, lng: 59.80806033716286 },
      { lat: 22.31052480721419, lng: 59.8061483091681 },
      { lat: 21.714540513592084, lng: 59.44219119653641 },
      { lat: 21.433885809814882, lng: 59.282407667889885 },
      { lat: 21.114034532144302, lng: 58.86114139184659 },
    ],
    [
      { lat: 25.89599070892126, lng: 56.39142133975341 },
      { lat: 25.71460643157675, lng: 56.26104170108093 },
      { lat: 26.05546417897395, lng: 56.07082075381456 },
      { lat: 26.395934353128947, lng: 56.36201744977936 },
      { lat: 26.309117946878672, lng: 56.48567915225382 },
      { lat: 25.89599070892126, lng: 56.39142133975341 },
    ],
  ],
  PA: [
    [
      { lat: 7.223771267114785, lng: -77.88157141794525 },
      { lat: 7.512254950384161, lng: -78.21493608266012 },
      { lat: 8.052041123888927, lng: -78.42916073272607 },
      { lat: 8.319182440621773, lng: -78.18209570993864 },
      { lat: 8.38770538984079, lng: -78.4354652574657 },
      { lat: 8.718124497915028, lng: -78.62212053090394 },
      { lat: 8.996092027213022, lng: -79.12030717641375 },
      { lat: 8.932374986197146, lng: -79.55787736684519 },
      { lat: 8.5845150822244, lng: -79.76057817251004 },
      { lat: 8.333315944853595, lng: -80.16448116730334 },
      { lat: 8.298408514840432, lng: -80.38265906443961 },
      { lat: 8.09030752200107, lng: -80.4806892564973 },
      { lat: 7.547524115423371, lng: -80.00368994822716 },
      { lat: 7.419754136581715, lng: -80.276670701809 },
      { lat: 7.271571966984764, lng: -80.42115800649708 },
      { lat: 7.220541490096537, lng: -80.8864009264208 },
      { lat: 7.817921047390596, lng: -81.05954281281473 },
      { lat: 7.647905585150339, lng: -81.18971574575795 },
      { lat: 7.706610012233909, lng: -81.51951473664468 },
      { lat: 8.108962714058435, lng: -81.72131120474445 },
      { lat: 8.175392767769635, lng: -82.13144120962892 },
      { lat: 8.29236237226229, lng: -82.39093441438257 },
      { lat: 8.290863755725823, lng: -82.82008134635042 },
      { lat: 8.073822740099956, lng: -82.85095801464482 },
      { lat: 8.225027980985985, lng: -82.96578304719736 },
      { lat: 8.42351715741907, lng: -82.91317643912421 },
      { lat: 8.62629547773237, lng: -82.82977067740516 },
      { lat: 8.807266343618522, lng: -82.86865719270477 },
      { lat: 8.925708726431495, lng: -82.71918311230053 },
      { lat: 9.074330145702916, lng: -82.92715491405916 },
      { lat: 9.476812038608173, lng: -82.93289099804358 },
      { lat: 9.566134751824677, lng: -82.54619625520348 },
      { lat: 9.20744863528678, lng: -82.18712256542341 },
      { lat: 8.9955752628901, lng: -82.20758643261095 },
      { lat: 8.950616766796173, lng: -81.80856686066929 },
      { lat: 9.031955471223583, lng: -81.71415401887204 },
      { lat: 8.786234035675719, lng: -81.43928707551154 },
      { lat: 8.858503526235905, lng: -80.94730160187676 },
      { lat: 9.111072089062432, lng: -80.52190121125008 },
      { lat: 9.31276520429762, lng: -79.91459977895599 },
      { lat: 9.611610012241526, lng: -79.57330278188431 },
      { lat: 9.552931423374105, lng: -79.02119177927793 },
      { lat: 9.454565334506526, lng: -79.05845048696037 },
      { lat: 9.420458889193881, lng: -78.50088762074719 },
      { lat: 9.2477304142583, lng: -78.05592770049802 },
      { lat: 8.946844387238869, lng: -77.72951351592641 },
      { lat: 8.67050466555807, lng: -77.35336076527385 },
      { lat: 8.524286200388218, lng: -77.47472286651133 },
      { lat: 7.935278225125444, lng: -77.24256649444008 },
      { lat: 7.638061224798735, lng: -77.43110795765699 },
      { lat: 7.709839789252142, lng: -77.75341386586139 },
      { lat: 7.223771267114785, lng: -77.88157141794525 },
    ],
  ],
  PE: [
    [
      { lat: -17.580011895419332, lng: -69.59042375352405 },
      { lat: -18.092693780187012, lng: -69.85844356960587 },
      { lat: -18.34797535570887, lng: -70.37257239447771 },
      { lat: -17.773798516513857, lng: -71.37525021023691 },
      { lat: -17.363487644116383, lng: -71.46204077827112 },
      { lat: -16.359362888252996, lng: -73.44452958850042 },
      { lat: -15.265682875227782, lng: -75.23788265654144 },
      { lat: -14.649286390850321, lng: -76.00920508492995 },
      { lat: -13.82318694423243, lng: -76.42346920439775 },
      { lat: -13.535039157772943, lng: -76.25924150257416 },
      { lat: -12.22271615972082, lng: -77.10619238962184 },
      { lat: -10.377712497604065, lng: -78.09215287953464 },
      { lat: -8.386567884965892, lng: -79.03695309112695 },
      { lat: -7.93083342858386, lng: -79.44592037628485 },
      { lat: -7.194340915560083, lng: -79.76057817251004 },
      { lat: -6.541667575713717, lng: -80.53748165558608 },
      { lat: -6.136834405139183, lng: -81.24999630402642 },
      { lat: -5.690556735866565, lng: -80.92634680858244 },
      { lat: -4.736764825055459, lng: -81.41094255239946 },
      { lat: -4.036394138203697, lng: -81.09966956248937 },
      { lat: -3.404856459164713, lng: -80.30256059438722 },
      { lat: -3.821161797708044, lng: -80.18401485870967 },
      { lat: -4.059286797708999, lng: -80.46929460317695 },
      { lat: -4.425724379090674, lng: -80.44224199087216 },
      { lat: -4.346090996928893, lng: -80.02890804718561 },
      { lat: -4.454198093283494, lng: -79.62497921417618 },
      { lat: -4.959128513207389, lng: -79.20528906931771 },
      { lat: -4.547784112164074, lng: -78.63989722361234 },
      { lat: -3.873096612161376, lng: -78.45068396677564 },
      { lat: -3.003020521663103, lng: -77.83790483265861 },
      { lat: -2.608677666843818, lng: -76.63539425322672 },
      { lat: -1.56160979574588, lng: -75.54499569365204 },
      { lat: -0.911416924649529, lng: -75.23372270374193 },
      { lat: -0.15203175212045, lng: -75.37322323271385 },
      { lat: -0.05720549886486, lng: -75.10662451852008 },
      { lat: -0.530820000819887, lng: -74.44160051135597 },
      { lat: -1.002832533373848, lng: -74.12239518908906 },
      { lat: -1.260491224781134, lng: -73.6595035468346 },
      { lat: -2.308954359550953, lng: -73.07039221870724 },
      { lat: -2.434218031426454, lng: -72.32578650581365 },
      { lat: -2.169789727388938, lng: -71.7747607082854 },
      { lat: -2.342802422702128, lng: -71.41364579942979 },
      { lat: -2.256864515800743, lng: -70.81347571479196 },
      { lat: -2.725156345229699, lng: -70.04770850287485 },
      { lat: -3.742872002785859, lng: -70.69268205430971 },
      { lat: -3.766591485207825, lng: -70.39404395209499 },
      { lat: -4.298186944194327, lng: -69.89363521999663 },
      { lat: -4.251264743673303, lng: -70.7947688463023 },
      { lat: -4.401591485210368, lng: -70.92884334988358 },
      { lat: -4.593982842633011, lng: -71.74840572781655 },
      { lat: -5.274561455916981, lng: -72.89192765978726 },
      { lat: -5.741251315944893, lng: -72.96450720894119 },
      { lat: -6.089188734566078, lng: -73.21971126981461 },
      { lat: -6.629930922068239, lng: -73.1200274319236 },
      { lat: -6.91859547285064, lng: -73.72448666044164 },
      { lat: -7.340998630404414, lng: -73.7234014553635 },
      { lat: -7.523829847853064, lng: -73.98723548042966 },
      { lat: -8.424446709835834, lng: -73.57105933296707 },
      { lat: -9.03283334720806, lng: -73.01538265653254 },
      { lat: -9.462212823121234, lng: -73.22671342639016 },
      { lat: -9.520193780152717, lng: -72.56303300646564 },
      { lat: -10.053597914269432, lng: -72.18489071316984 },
      { lat: -10.079436130415374, lng: -71.30241227892154 },
      { lat: -9.490118096558845, lng: -70.48189388699117 },
      { lat: -11.009146823778465, lng: -70.54868567572841 },
      { lat: -11.123971856331012, lng: -70.0937522040469 },
      { lat: -10.951734307502194, lng: -69.52967810736496 },
      { lat: -12.561300144097173, lng: -68.66507971868961 },
      { lat: -12.899729099176653, lng: -68.88007951523997 },
      { lat: -13.602683607643007, lng: -68.92922380234954 },
      { lat: -14.453639418193283, lng: -68.9488866848366 },
      { lat: -14.953195489158832, lng: -69.33953467474701 },
      { lat: -15.323973890853019, lng: -69.16034664577495 },
      { lat: -15.660129082911654, lng: -69.38976416693471 },
      { lat: -16.50069793057127, lng: -68.9596353827533 },
      { lat: -17.580011895419332, lng: -69.59042375352405 },
    ],
  ],
  PF: [
    [
      { lat: -16.20939985957443, lng: -152.369384765625 },
      { lat: -16.583552354072005, lng: -152.435302734375 },
      { lat: -17.030525041643052, lng: -151.5948486328125 },
      { lat: -16.988502065373595, lng: -151.01806640625 },
      { lat: -16.699340234594537, lng: -150.853271484375 },
      { lat: -16.409739933377747, lng: -151.0455322265625 },
      { lat: -16.051092539626506, lng: -151.842041015625 },
      { lat: -16.20939985957443, lng: -152.369384765625 },
    ],
    [
      { lat: -17.675427818339383, lng: -150.99609375 },
      { lat: -17.889886818625325, lng: -150.64453125 },
      { lat: -17.978733095556155, lng: -149.051513671875 },
      { lat: -17.82714499951342, lng: -148.9910888671875 },
      { lat: -16.720385051693988, lng: -149.5513916015625 },
      { lat: -17.675427818339383, lng: -150.99609375 },
    ],
  ],
  PG: [
    [
      { lat: -4.499983412294114, lng: 153.14003787659877 },
      { lat: -4.766427097190999, lng: 152.8272921083683 },
      { lat: -4.176127211120928, lng: 152.638673130503 },
      { lat: -3.789742526874562, lng: 152.40602583232496 },
      { lat: -3.462062269711822, lng: 151.95323693258356 },
      { lat: -3.035421644710112, lng: 151.38427941305005 },
      { lat: -2.741486097833956, lng: 150.66204959533886 },
      { lat: -2.500002129734028, lng: 150.93996544820456 },
      { lat: -2.779985039891386, lng: 151.4799841656545 },
      { lat: -2.999971612157907, lng: 151.82001509013512 },
      { lat: -3.240008640153661, lng: 152.2399894553711 },
      { lat: -3.659983005389648, lng: 152.64001671774253 },
      { lat: -3.980015150573294, lng: 153.01999352438466 },
      { lat: -4.499983412294114, lng: 153.14003787659877 },
    ],
    [
      { lat: -7.38802418378998, lng: 147.19187381407494 },
      { lat: -8.044108168167611, lng: 148.0846358583494 },
      { lat: -9.104663588093757, lng: 148.7341052593936 },
      { lat: -9.07143564213007, lng: 149.30683515848446 },
      { lat: -9.514406019736027, lng: 149.26663089416135 },
      { lat: -9.684318129111702, lng: 150.03872846903434 },
      { lat: -9.872937106977005, lng: 149.73879845601226 },
      { lat: -10.293686618697421, lng: 150.80162763895916 },
      { lat: -10.582712904505868, lng: 150.69057498596388 },
      { lat: -10.652476088099931, lng: 150.02839318257585 },
      { lat: -10.393267103723943, lng: 149.782310012002 },
      { lat: -10.280922539921363, lng: 148.92313764871722 },
      { lat: -10.130440769087471, lng: 147.91301842670802 },
      { lat: -9.492443536012019, lng: 147.13544315001226 },
      { lat: -8.942554619994155, lng: 146.56788089415062 },
      { lat: -8.06741423913131, lng: 146.04848107318494 },
      { lat: -7.630128269077473, lng: 144.74416792213802 },
      { lat: -7.915330498896281, lng: 143.8970878440097 },
      { lat: -8.245491224809056, lng: 143.2863757671843 },
      { lat: -8.983068942910947, lng: 143.4139132020807 },
      { lat: -9.326820570516503, lng: 142.62843143124422 },
      { lat: -9.159595635620036, lng: 142.06825890520022 },
      { lat: -9.117892754760419, lng: 141.0338517600139 },
      { lat: -5.859021905138022, lng: 141.01705691951904 },
      { lat: -2.600151055515624, lng: 141.00021040259188 },
      { lat: -3.289152927263217, lng: 142.7352466167915 },
      { lat: -3.861417738463401, lng: 144.58397098203326 },
      { lat: -4.373737888205028, lng: 145.27317955951 },
      { lat: -4.876497897972683, lng: 145.82978641172568 },
      { lat: -5.465609226100014, lng: 145.98192182839298 },
      { lat: -6.083659356310804, lng: 147.6480733583476 },
      { lat: -6.614014580922315, lng: 147.8911076194162 },
      { lat: -6.721656589386257, lng: 146.9709053895949 },
      { lat: -7.38802418378998, lng: 147.19187381407494 },
    ],
    [
      { lat: -5.478063246282346, lng: 151.9827958518545 },
      { lat: -5.56028045005874, lng: 151.45910688700866 },
      { lat: -5.840728448106702, lng: 151.3013904156539 },
      { lat: -6.083762709175389, lng: 150.7544470562767 },
      { lat: -6.317753594592986, lng: 150.24119673075384 },
      { lat: -6.316513360218053, lng: 149.70996300679332 },
      { lat: -6.026040134305433, lng: 148.89006473205046 },
      { lat: -5.74714242922613, lng: 148.31893680236075 },
      { lat: -5.437755629094724, lng: 148.4018257997569 },
      { lat: -5.583741550319217, lng: 149.29841190002082 },
      { lat: -5.505503431829339, lng: 149.84556196512725 },
      { lat: -5.026101169457675, lng: 149.9962504416903 },
      { lat: -5.001348158389789, lng: 150.13975589416495 },
      { lat: -5.532220147324281, lng: 150.23690758687349 },
      { lat: -5.455842380396888, lng: 150.8074670758081 },
      { lat: -5.113692722192368, lng: 151.089672072554 },
      { lat: -4.757073662946169, lng: 151.64788089417087 },
      { lat: -4.16780730552189, lng: 151.53786176982155 },
      { lat: -4.14879037843852, lng: 152.13679162008438 },
      { lat: -4.312966403829762, lng: 152.33874311748102 },
      { lat: -4.86766122805075, lng: 152.31869266175178 },
      { lat: -5.478063246282346, lng: 151.9827958518545 },
    ],
    [
      { lat: -6.81999684003776, lng: 155.88002566957843 },
      { lat: -6.919990736522493, lng: 155.5999910829888 },
      { lat: -6.535931491729301, lng: 155.16699425681512 },
      { lat: -5.900828138862209, lng: 154.72919152243836 },
      { lat: -5.139117526880014, lng: 154.51411421123967 },
      { lat: -5.042430922061839, lng: 154.65250369691736 },
      { lat: -5.339983819198494, lng: 154.7599906760844 },
      { lat: -5.566791680527487, lng: 155.06291792217937 },
      { lat: -6.200654799019659, lng: 155.54774620994172 },
      { lat: -6.540013929880388, lng: 156.01996544822478 },
      { lat: -6.81999684003776, lng: 155.88002566957843 },
    ],
  ],
  PH: [
    [
      { lat: 8.414706325713354, lng: 126.37681359263748 },
      { lat: 7.750354112168978, lng: 126.4785128113879 },
      { lat: 7.189380601424574, lng: 126.53742394420063 },
      { lat: 6.27429433840004, lng: 126.19677290253256 },
      { lat: 7.293715318221857, lng: 125.83142052622911 },
      { lat: 6.786485297060992, lng: 125.3638521668523 },
      { lat: 6.049656887227258, lng: 125.68316084198372 },
      { lat: 5.58100332277229, lng: 125.39651167206064 },
      { lat: 6.161355495626182, lng: 124.21978763234236 },
      { lat: 6.885135606306122, lng: 123.93871951710695 },
      { lat: 7.360610459823661, lng: 124.24366214406135 },
      { lat: 7.833527329942754, lng: 123.61021243702757 },
      { lat: 7.418875637232787, lng: 123.2960714051252 },
      { lat: 7.457374579290217, lng: 122.82550581267542 },
      { lat: 6.899424139834849, lng: 122.08549930225577 },
      { lat: 7.192119452336072, lng: 121.91992801319263 },
      { lat: 8.034962063016508, lng: 122.31235884001714 },
      { lat: 8.316236883981174, lng: 122.94239790251966 },
      { lat: 8.693009751821194, lng: 123.48768761606352 },
      { lat: 8.240324204944386, lng: 123.84115441293984 },
      { lat: 8.514157619659017, lng: 124.60146976125023 },
      { lat: 8.96040945071546, lng: 124.76461225799564 },
      { lat: 8.986996975129642, lng: 125.47139082245157 },
      { lat: 9.760334784377548, lng: 125.41211795461278 },
      { lat: 9.28607432701885, lng: 126.22271447154318 },
      { lat: 8.782487494334575, lng: 126.3066369975851 },
      { lat: 8.414706325713354, lng: 126.37681359263748 },
    ],
    [
      { lat: 11.89175507247198, lng: 121.88354780485913 },
      { lat: 11.582187404827508, lng: 122.48382124236147 },
      { lat: 11.58366018314787, lng: 123.12021650603597 },
      { lat: 11.16593374271649, lng: 123.10083784392647 },
      { lat: 10.741308498574227, lng: 122.6377136577267 },
      { lat: 10.441016750526087, lng: 122.00261030485957 },
      { lat: 10.905691229694623, lng: 121.96736697803655 },
      { lat: 11.41584096928004, lng: 122.03837039600555 },
      { lat: 11.89175507247198, lng: 121.88354780485913 },
    ],
    [
      { lat: 9.31638255455809, lng: 118.50458092659035 },
      { lat: 8.367499904814665, lng: 117.1742745301007 },
      { lat: 9.066888739452935, lng: 117.66447716682138 },
      { lat: 9.684499619989225, lng: 118.38691369026175 },
      { lat: 10.376292019080509, lng: 118.98734215706108 },
      { lat: 11.369668077027214, lng: 119.51149620979756 },
      { lat: 10.554291490109875, lng: 119.68967654833992 },
      { lat: 10.003653265823871, lng: 119.029458449379 },
      { lat: 9.31638255455809, lng: 118.50458092659035 },
    ],
    [
      { lat: 13.06959015548452, lng: 121.52739383350351 },
      { lat: 12.205560207564403, lng: 121.26219038298157 },
      { lat: 12.70449616134242, lng: 120.83389611214656 },
      { lat: 13.46641347905387, lng: 120.3234363139675 },
      { lat: 13.429697373910443, lng: 121.18012820850217 },
      { lat: 13.06959015548452, lng: 121.52739383350351 },
    ],
    [
      { lat: 12.162694606978349, lng: 125.50255171112352 },
      { lat: 11.046121934447768, lng: 125.78346479706218 },
      { lat: 11.31145457605038, lng: 125.01188398651229 },
      { lat: 10.975816148314706, lng: 125.03276126515814 },
      { lat: 10.358722032101312, lng: 125.27744917206027 },
      { lat: 10.134678859899893, lng: 124.80181928924573 },
      { lat: 10.837995103392302, lng: 124.76016808481849 },
      { lat: 10.889929917845635, lng: 124.45910119028606 },
      { lat: 11.495370998577227, lng: 124.30252160044172 },
      { lat: 11.415582587118593, lng: 124.8910128113816 },
      { lat: 11.79418996830499, lng: 124.87799035044398 },
      { lat: 12.557760931849685, lng: 124.26676150929572 },
      { lat: 12.535720933477194, lng: 125.22711632700785 },
      { lat: 12.162694606978349, lng: 125.50255171112352 },
    ],
    [
      { lat: 10.278778591345812, lng: 123.98243777882583 },
      { lat: 9.950090643753299, lng: 123.62318322153278 },
      { lat: 9.318268744336676, lng: 123.30992068897936 },
      { lat: 9.0221886255204, lng: 122.99588300994164 },
      { lat: 9.713360907424203, lng: 122.38005496631948 },
      { lat: 9.981044826696104, lng: 122.5860889018671 },
      { lat: 10.261156927934238, lng: 122.83708133350873 },
      { lat: 10.881868394408029, lng: 122.94741051645192 },
      { lat: 10.940624497923949, lng: 123.49884972543848 },
      { lat: 10.267383938025446, lng: 123.33777428598475 },
      { lat: 11.23272553145371, lng: 124.07793582570125 },
      { lat: 10.278778591345812, lng: 123.98243777882583 },
    ],
    [
      { lat: 18.504064642811016, lng: 121.32130822152358 },
      { lat: 18.218552354398383, lng: 121.9376013530364 },
      { lat: 18.478949896717097, lng: 122.24600630095429 },
      { lat: 18.224882717354177, lng: 122.336956821788 },
      { lat: 17.810282701076375, lng: 122.1742794129332 },
      { lat: 17.093504746971973, lng: 122.51565392465336 },
      { lat: 16.262444362854126, lng: 122.2523108256939 },
      { lat: 15.931017564350128, lng: 121.66278608610828 },
      { lat: 15.124813544164622, lng: 121.5050696147534 },
      { lat: 14.328376369682246, lng: 121.72882856657728 },
      { lat: 14.218202216035976, lng: 122.25892540902734 },
      { lat: 14.33654124598442, lng: 122.70127566944566 },
      { lat: 13.78213064214107, lng: 123.95029503794026 },
      { lat: 13.237771104378467, lng: 123.85510704965863 },
      { lat: 12.997527370653472, lng: 124.1812886902849 },
      { lat: 12.536676947474575, lng: 124.07741906137825 },
      { lat: 13.027525539598981, lng: 123.29803510955227 },
      { lat: 13.552919826710408, lng: 122.92865197152993 },
      { lat: 13.185836289925135, lng: 122.67135501514869 },
      { lat: 13.784481919810347, lng: 122.03464969288055 },
      { lat: 13.63668732345556, lng: 121.1263847189186 },
      { lat: 13.857655747935652, lng: 120.62863732308331 },
      { lat: 14.271015529838323, lng: 120.67938357959385 },
      { lat: 14.525392767795083, lng: 120.99181928923055 },
      { lat: 14.756670640517285, lng: 120.69333621631272 },
      { lat: 14.396279201713822, lng: 120.564145135583 },
      { lat: 14.970869452367097, lng: 120.0704285014664 },
      { lat: 15.406346747290739, lng: 119.92092858284613 },
      { lat: 16.363704331929966, lng: 119.88377322802826 },
      { lat: 16.03462881109533, lng: 120.28648766487882 },
      { lat: 17.59908112229951, lng: 120.39004723519176 },
      { lat: 18.50522736253754, lng: 120.71586714079191 },
      { lat: 18.504064642811016, lng: 121.32130822152358 },
    ],
  ],
  PK: [
    [
      { lat: 37.13303091078912, lng: 75.15802778514092 },
      { lat: 36.666806138651836, lng: 75.89689741405013 },
      { lat: 35.89840342868782, lng: 76.19284834178569 },
      { lat: 35.494009507787766, lng: 77.83745079947457 },
      { lat: 34.65354401299274, lng: 76.87172163280403 },
      { lat: 34.50492259372132, lng: 75.75706098826834 },
      { lat: 34.74888703057125, lng: 74.24020267120497 },
      { lat: 34.31769887952785, lng: 73.74994835805195 },
      { lat: 33.44147329358685, lng: 74.10429365427734 },
      { lat: 32.7648996038055, lng: 74.45155927927871 },
      { lat: 32.2711054550405, lng: 75.25864179881322 },
      { lat: 31.69263947196528, lng: 74.40592898956501 },
      { lat: 30.979814764931177, lng: 74.42138024282026 },
      { lat: 29.97641347911987, lng: 73.45063846221743 },
      { lat: 28.961591701772054, lng: 72.8237516620847 },
      { lat: 27.913180243434525, lng: 71.77766564320032 },
      { lat: 27.989196275335868, lng: 70.61649620960193 },
      { lat: 26.940965684511372, lng: 69.51439293811312 },
      { lat: 26.491871649678842, lng: 70.16892662952202 },
      { lat: 25.72222870533983, lng: 70.28287316272558 },
      { lat: 25.21510203704352, lng: 70.84469933460284 },
      { lat: 24.3565239527302, lng: 71.04324018746823 },
      { lat: 24.35913361256094, lng: 68.84259931831878 },
      { lat: 23.69196503345671, lng: 68.1766451353734 },
      { lat: 23.94484365487699, lng: 67.44366661974547 },
      { lat: 24.663611151624647, lng: 67.14544192898907 },
      { lat: 25.425140896093847, lng: 66.37282758979326 },
      { lat: 25.23703868255143, lng: 64.53040774929113 },
      { lat: 25.21840932871021, lng: 62.9057007180346 },
      { lat: 25.0782370061185, lng: 61.49736290878419 },
      { lat: 26.239974880472104, lng: 61.87418745305655 },
      { lat: 26.756532497661667, lng: 63.31663170761959 },
      { lat: 27.21704702403071, lng: 63.233897739520295 },
      { lat: 27.378923448184985, lng: 62.755425652929866 },
      { lat: 28.25964488373539, lng: 62.72783043808598 },
      { lat: 28.699333807890795, lng: 61.77186811711863 },
      { lat: 29.303276272085924, lng: 61.36930870956494 },
      { lat: 29.829238999952604, lng: 60.874248488208785 },
      { lat: 29.31857249604431, lng: 62.54985680527278 },
      { lat: 29.468330796826162, lng: 63.55026085801117 },
      { lat: 29.340819200145972, lng: 64.14800215033125 },
      { lat: 29.560030625928093, lng: 64.35041873561852 },
      { lat: 29.472180691031905, lng: 65.0468620136161 },
      { lat: 29.887943427036177, lng: 66.34647260932442 },
      { lat: 30.73889923758645, lng: 66.38145755398602 },
      { lat: 31.304911200479353, lng: 66.93889122911847 },
      { lat: 31.30315420178142, lng: 67.68339358914747 },
      { lat: 31.58293040620963, lng: 67.79268924344478 },
      { lat: 31.713310044882018, lng: 68.55693200060932 },
      { lat: 31.620189113892064, lng: 68.92667687365767 },
      { lat: 31.90141225842444, lng: 69.31776411324255 },
      { lat: 32.5019440780883, lng: 69.26252200712256 },
      { lat: 33.105498969041236, lng: 69.68714725126485 },
      { lat: 33.35853261975839, lng: 70.3235941913716 },
      { lat: 34.02012014417511, lng: 69.9305432473596 },
      { lat: 33.98885590263851, lng: 70.8818030129884 },
      { lat: 34.34891144463215, lng: 71.15677330921346 },
      { lat: 34.733125718722235, lng: 71.11501875192162 },
      { lat: 35.153203436822864, lng: 71.6130762063507 },
      { lat: 35.650563259416, lng: 71.49876793812109 },
      { lat: 36.074387518857804, lng: 71.26234826038575 },
      { lat: 36.50994232842986, lng: 71.84629194528392 },
      { lat: 36.72000702569632, lng: 72.92002485544447 },
      { lat: 36.83617564548845, lng: 74.06755171091783 },
      { lat: 37.02084137628346, lng: 74.57589277537298 },
      { lat: 37.13303091078912, lng: 75.15802778514092 },
    ],
  ],
  PL: [
    [
      { lat: 51.10667409932158, lng: 15.01699588385867 },
      { lat: 51.745188096719964, lng: 14.607098422919535 },
      { lat: 52.0899474147552, lng: 14.685026482815688 },
      { lat: 52.62485016540838, lng: 14.4375997250022 },
      { lat: 52.98126251892543, lng: 14.074521111719491 },
      { lat: 53.24817129171297, lng: 14.353315463934138 },
      { lat: 53.75702912049103, lng: 14.119686313542587 },
      { lat: 54.05070628520575, lng: 14.802900424873458 },
      { lat: 54.513158677785725, lng: 16.36347700365573 },
      { lat: 54.85153595643291, lng: 17.622831658608675 },
      { lat: 54.68260569927078, lng: 18.62085859546164 },
      { lat: 54.43871877706929, lng: 18.696254510175464 },
      { lat: 54.42608388937393, lng: 19.660640089606403 },
      { lat: 54.31252492941253, lng: 20.892244500418624 },
      { lat: 54.327536932993326, lng: 22.731098667092652 },
      { lat: 54.22056671814914, lng: 23.24398725758951 },
      { lat: 53.91249766704114, lng: 23.48412763844985 },
      { lat: 53.470121568406555, lng: 23.527535841575002 },
      { lat: 53.089731350306074, lng: 23.80493493011778 },
      { lat: 52.69109935160657, lng: 23.799198846133375 },
      { lat: 52.486977444053664, lng: 23.199493849386187 },
      { lat: 52.02364655212473, lng: 23.508002150168693 },
      { lat: 51.57845408793023, lng: 23.52707075368437 },
      { lat: 50.70540660257518, lng: 24.029985792748903 },
      { lat: 50.42488108987875, lng: 23.922757195743262 },
      { lat: 50.30850576435745, lng: 23.426508416444392 },
      { lat: 49.47677358661974, lng: 22.518450148211603 },
      { lat: 49.02739533140962, lng: 22.776418898212626 },
      { lat: 49.085738023467144, lng: 22.558137648211755 },
      { lat: 49.47010732685409, lng: 21.607808058364213 },
      { lat: 49.32877228453583, lng: 20.887955356538413 },
      { lat: 49.43145335549977, lng: 20.41583947111985 },
      { lat: 49.21712535256923, lng: 19.825022820726872 },
      { lat: 49.571574001659194, lng: 19.320712517990472 },
      { lat: 49.435845852244576, lng: 18.909574822676316 },
      { lat: 49.49622976337764, lng: 18.853144158613617 },
      { lat: 49.98862864847075, lng: 18.392913852622172 },
      { lat: 50.049038397819956, lng: 17.64944502123899 },
      { lat: 50.36214590107641, lng: 17.55456709155112 },
      { lat: 50.47397370055603, lng: 16.868769158605655 },
      { lat: 50.21574656839354, lng: 16.719475945714436 },
      { lat: 50.42260732685791, lng: 16.176253289462267 },
      { lat: 50.69773265237984, lng: 16.23862674323857 },
      { lat: 50.78472992614321, lng: 15.490972120839727 },
      { lat: 51.10667409932158, lng: 15.01699588385867 },
    ],
  ],
  PR: [
    [
      { lat: 18.514761664295364, lng: -66.28243445500821 },
      { lat: 18.426679185453878, lng: -65.7713028632093 },
      { lat: 18.228034979723915, lng: -65.59100379094295 },
      { lat: 17.97590566657186, lng: -65.84716386581377 },
      { lat: 17.981822618069273, lng: -66.59993445500949 },
      { lat: 17.946553453030077, lng: -67.18416236028527 },
      { lat: 18.374460150622937, lng: -67.24242753769435 },
      { lat: 18.52060110114435, lng: -67.10067908391774 },
      { lat: 18.514761664295364, lng: -66.28243445500821 },
    ],
  ],
  PS: [
    [
      { lat: 32.393992011030576, lng: 35.54566531753454 },
      { lat: 31.78250478772084, lng: 35.5452519060762 },
      { lat: 31.48908600516758, lng: 35.397560662586045 },
      { lat: 31.353435370401414, lng: 34.92740848159457 },
      { lat: 31.616778469360806, lng: 34.970506626125996 },
      { lat: 31.754341132121766, lng: 35.22589155451242 },
      { lat: 31.86658234305972, lng: 34.97464074070933 },
      { lat: 32.53251068778894, lng: 35.183930291491436 },
      { lat: 32.393992011030576, lng: 35.54566531753454 },
    ],
  ],
  PT: [
    [
      { lat: 41.88057058365967, lng: -9.034817674180246 },
      { lat: 42.13468943945496, lng: -8.67194576662672 },
      { lat: 42.28046865495034, lng: -8.263856980817792 },
      { lat: 41.790886135417125, lng: -8.013174607769912 },
      { lat: 41.79207469335983, lng: -7.422512986673795 },
      { lat: 41.91834605566505, lng: -7.251308966490824 },
      { lat: 41.883386949219584, lng: -6.668605515967656 },
      { lat: 41.381815497394655, lng: -6.389087693700915 },
      { lat: 41.11108266861753, lng: -6.851126674822552 },
      { lat: 40.33087189387483, lng: -6.864019944679385 },
      { lat: 40.184524237624245, lng: -7.026413133156595 },
      { lat: 39.71189158788277, lng: -7.066591559263529 },
      { lat: 39.62957103124181, lng: -7.498632371439725 },
      { lat: 39.03007274022378, lng: -7.098036668313128 },
      { lat: 38.37305858006492, lng: -7.374092169616318 },
      { lat: 38.07576406508977, lng: -7.029281175148796 },
      { lat: 37.803894354802225, lng: -7.166507941099865 },
      { lat: 37.42890432387623, lng: -7.537105475281024 },
      { lat: 37.09778758396607, lng: -7.453725551778092 },
      { lat: 36.83826854099627, lng: -7.855613165711985 },
      { lat: 36.97888011326246, lng: -8.382816127953689 },
      { lat: 36.86880931248078, lng: -8.898856980820327 },
      { lat: 37.65134552667661, lng: -8.746101446965554 },
      { lat: 38.26624339451761, lng: -8.839997524439879 },
      { lat: 38.3584858261586, lng: -9.287463751655224 },
      { lat: 38.73742910415491, lng: -9.526570603869715 },
      { lat: 39.39206614842837, lng: -9.446988898140232 },
      { lat: 39.75509308527877, lng: -9.048305223008427 },
      { lat: 40.15930613866581, lng: -8.977353481471681 },
      { lat: 40.76063894303019, lng: -8.768684047877102 },
      { lat: 41.18433401139126, lng: -8.79085323733031 },
      { lat: 41.54345937760364, lng: -8.99078935386757 },
      { lat: 41.88057058365967, lng: -9.034817674180246 },
    ],
  ],
  PW: [
    [
      { lat: 3.0242129891797, lng: 131.12457570337 },
      { lat: 3.0302234863321, lng: 131.12537340086 },
      { lat: 3.0567980499976, lng: 131.14253328825 },
      { lat: 3.0723266965663, lng: 131.18849375971 },
      { lat: 3.072290241153, lng: 131.18886330777 },
      { lat: 3.0658145884332, lng: 131.2007027234 },
      { lat: 3.0629152332635, lng: 131.20302084104 },
      { lat: 3.0603736199298, lng: 131.20374659716 },
      { lat: 3.0463109555546, lng: 131.20203058802 },
      { lat: 3.031705516787, lng: 131.19349052253 },
      { lat: 3.0111836695835, lng: 131.16498631852 },
      { lat: 3.011571462203, lng: 131.14104089177 },
      { lat: 3.0177681376514, lng: 131.12753801928 },
      { lat: 3.0195613244152, lng: 131.12597809355 },
      { lat: 3.0242129891797, lng: 131.12457570337 },
    ],
    [
      { lat: 7.4673212069331, lng: 134.49165567864 },
      { lat: 7.5387291162266, lng: 134.49854966447 },
      { lat: 7.5964473032909, lng: 134.52828121775 },
      { lat: 7.7069648156694, lng: 134.63556014151 },
      { lat: 7.7071583765573, lng: 134.64331978152 },
      { lat: 7.6976853908508, lng: 134.66952547928 },
      { lat: 7.6959947574579, lng: 134.67129171445 },
      { lat: 7.6895415321861, lng: 134.67394889652 },
      { lat: 7.6390844124507, lng: 134.67303611818 },
      { lat: 7.3548387511071, lng: 134.57233492726 },
      { lat: 7.3553713597617, lng: 134.53311964563 },
      { lat: 7.3711310201118, lng: 134.51975035529 },
      { lat: 7.4673212069331, lng: 134.49165567864 },
    ],
  ],
  PY: [
    [
      { lat: -22.249029229422387, lng: -62.685057135657885 },
      { lat: -21.05163461678739, lng: -62.29117936872922 },
      { lat: -20.513734633061276, lng: -62.26596126977079 },
      { lat: -19.633736667562964, lng: -61.786326463453776 },
      { lat: -19.342746677327426, lng: -60.04356462262649 },
      { lat: -19.356906019775398, lng: -59.115042487206104 },
      { lat: -19.868399346600363, lng: -58.183471442280506 },
      { lat: -20.176700941653678, lng: -58.166392381408045 },
      { lat: -20.73268767668195, lng: -57.87067399761779 },
      { lat: -22.090175876557172, lng: -57.937155727761294 },
      { lat: -22.28215382252148, lng: -56.8815095689029 },
      { lat: -22.086300144135283, lng: -56.47331743022939 },
      { lat: -22.356929620047822, lng: -55.79795813660691 },
      { lat: -22.655619398694842, lng: -55.610682745981144 },
      { lat: -23.571997572526634, lng: -55.517639329639636 },
      { lat: -23.956935316668805, lng: -55.40074723979542 },
      { lat: -24.001273695575225, lng: -55.02790178080954 },
      { lat: -23.83957813893396, lng: -54.652834235235126 },
      { lat: -24.02101409271073, lng: -54.29295956075451 },
      { lat: -24.570799655863965, lng: -54.29347632507745 },
      { lat: -25.162184747012166, lng: -54.42894609233059 },
      { lat: -25.73925546641551, lng: -54.625290696823576 },
      { lat: -26.621785577096134, lng: -54.78879492859505 },
      { lat: -27.38783700939086, lng: -55.69584550639816 },
      { lat: -27.548499037386293, lng: -56.48670162619299 },
      { lat: -27.395898532828387, lng: -57.60975969097615 },
      { lat: -27.123718763947096, lng: -58.61817359071974 },
      { lat: -25.60365650808164, lng: -57.633660040911124 },
      { lat: -25.16233977630904, lng: -57.77721716981794 },
      { lat: -24.77145924245331, lng: -58.80712846539498 },
      { lat: -24.032796319273274, lng: -60.02896603050402 },
      { lat: -23.880712579038292, lng: -60.84656470400991 },
      { lat: -22.249029229422387, lng: -62.685057135657885 },
    ],
  ],
  QA: [
    [
      { lat: 24.754742539971378, lng: 50.81010827006958 },
      { lat: 25.482424221289396, lng: 50.74391076030369 },
      { lat: 26.00699168548419, lng: 51.01335167827349 },
      { lat: 26.11458201751587, lng: 51.28646162293606 },
      { lat: 25.80111277923338, lng: 51.58907881043726 },
      { lat: 25.21567047779874, lng: 51.60670047384881 },
      { lat: 24.62738597258806, lng: 51.38960778179063 },
      { lat: 24.556330878186724, lng: 51.11241539897702 },
      { lat: 24.754742539971378, lng: 50.81010827006958 },
    ],
  ],
  RE: [
    [
      { lat: -20.890890528372438, lng: 55.248870849609375 },
      { lat: -21.022982546427425, lng: 55.169219970703125 },
      { lat: -21.238182425982302, lng: 55.19874572753906 },
      { lat: -21.424946712203017, lng: 55.52215576171875 },
      { lat: -21.38019613028663, lng: 55.843505859375 },
      { lat: -21.15239612375494, lng: 55.88401794433594 },
      { lat: -20.899229877849546, lng: 55.745315551757805 },
      { lat: -20.804904106750552, lng: 55.49949645996094 },
      { lat: -20.890890528372438, lng: 55.248870849609375 },
    ],
  ],
  RO: [
    [
      { lat: 47.88219391538941, lng: 22.710531447040495 },
      { lat: 48.09634105080695, lng: 23.142236362406802 },
      { lat: 47.985598456405455, lng: 23.76095828623741 },
      { lat: 47.98187775328042, lng: 24.40205610525038 },
      { lat: 47.73752574318831, lng: 24.866317172960578 },
      { lat: 47.89105642352747, lng: 25.20774336111299 },
      { lat: 47.987148749374214, lng: 25.9459411964024 },
      { lat: 48.22088125263035, lng: 26.19745039236693 },
      { lat: 48.22072622333347, lng: 26.619336785597795 },
      { lat: 48.123264472030996, lng: 26.924176059687568 },
      { lat: 47.82677094175638, lng: 27.233872918412743 },
      { lat: 47.40511709247083, lng: 27.551166212684848 },
      { lat: 46.810476386088254, lng: 28.128030226359044 },
      { lat: 46.37156260841722, lng: 28.160017937947714 },
      { lat: 45.944586086605625, lng: 28.0544429867754 },
      { lat: 45.48828318946837, lng: 28.233553501099042 },
      { lat: 45.304030870131704, lng: 28.679779493939378 },
      { lat: 45.46492544207245, lng: 29.149724969201653 },
      { lat: 45.293308010431126, lng: 29.603289015427432 },
      { lat: 45.03539093686239, lng: 29.626543409958767 },
      { lat: 44.82021027279904, lng: 29.141611769331835 },
      { lat: 44.913873806328056, lng: 28.837857700320203 },
      { lat: 43.70746165625813, lng: 28.558081495891997 },
      { lat: 43.81246816667521, lng: 27.970107049275075 },
      { lat: 44.175986029632405, lng: 27.242399529740908 },
      { lat: 43.94349376075126, lng: 26.065158725699746 },
      { lat: 43.68844472917472, lng: 25.569271681426926 },
      { lat: 43.74105133724785, lng: 24.100679152124172 },
      { lat: 43.89701080990471, lng: 23.33230228037632 },
      { lat: 43.82378530534713, lng: 22.944832391051847 },
      { lat: 44.23492300066128, lng: 22.65714969248299 },
      { lat: 44.40922760678177, lng: 22.4740084164406 },
      { lat: 44.57800283464702, lng: 22.705725538837356 },
      { lat: 44.7025171982543, lng: 22.459022251075936 },
      { lat: 44.47842234962059, lng: 22.14508792490281 },
      { lat: 44.7689472519655, lng: 21.562022739353605 },
      { lat: 45.18117015235778, lng: 21.483526238702233 },
      { lat: 45.416375433934235, lng: 20.874312778413355 },
      { lat: 45.734573065771436, lng: 20.762174920339987 },
      { lat: 46.127468980486555, lng: 20.220192498462836 },
      { lat: 46.3160879583519, lng: 21.02195234547125 },
      { lat: 46.99423777931816, lng: 21.62651492685387 },
      { lat: 47.6724392767167, lng: 22.099767693782834 },
      { lat: 47.88219391538941, lng: 22.710531447040495 },
    ],
  ],
  RS: [
    [
      { lat: 45.41637543393432, lng: 20.87431277841341 },
      { lat: 45.18117015235788, lng: 21.48352623870221 },
      { lat: 44.76894725196564, lng: 21.562022739353722 },
      { lat: 44.47842234962059, lng: 22.145087924902896 },
      { lat: 44.70251719825444, lng: 22.459022251075965 },
      { lat: 44.57800283464701, lng: 22.70572553883744 },
      { lat: 44.40922760678177, lng: 22.474008416440654 },
      { lat: 44.234923000661354, lng: 22.657149692483074 },
      { lat: 44.008063462900054, lng: 22.410446404721597 },
      { lat: 43.642814439461006, lng: 22.500156691180223 },
      { lat: 43.2111612005271, lng: 22.986018507588483 },
      { lat: 42.898518785161116, lng: 22.60480146657136 },
      { lat: 42.58032115332394, lng: 22.43659467946139 },
      { lat: 42.46136200618804, lng: 22.54501183440965 },
      { lat: 42.32025950781508, lng: 22.38052575042468 },
      { lat: 42.30364, lng: 21.917080000000112 },
      { lat: 42.24522439706186, lng: 21.57663598940212 },
      { lat: 42.3202500000001, lng: 21.54332 },
      { lat: 42.43922, lng: 21.66292 },
      { lat: 42.6827, lng: 21.77505 },
      { lat: 42.67717, lng: 21.63302 },
      { lat: 42.86255, lng: 21.43866 },
      { lat: 42.90959, lng: 21.27421 },
      { lat: 43.06868500000012, lng: 21.143395 },
      { lat: 43.13094, lng: 20.95651 },
      { lat: 43.27205, lng: 20.81448 },
      { lat: 43.21671, lng: 20.63508 },
      { lat: 42.88469, lng: 20.49679 },
      { lat: 42.81275000000011, lng: 20.25758 },
      { lat: 42.89852, lng: 20.3398 },
      { lat: 43.10604, lng: 19.95857 },
      { lat: 43.21377997027054, lng: 19.63 },
      { lat: 43.35229, lng: 19.48389 },
      { lat: 43.52384, lng: 19.21852 },
      { lat: 43.56810000000013, lng: 19.454 },
      { lat: 44.03847, lng: 19.59976 },
      { lat: 44.42307000000011, lng: 19.11761 },
      { lat: 44.863, lng: 19.36803 },
      { lat: 44.86023, lng: 19.00548 },
      { lat: 45.236515611342384, lng: 19.39047570158459 },
      { lat: 45.52151113543209, lng: 19.072768995854176 },
      { lat: 45.90888, lng: 18.82982 },
      { lat: 46.171729844744554, lng: 19.59604454924164 },
      { lat: 46.12746898048658, lng: 20.220192498462893 },
      { lat: 45.734573065771485, lng: 20.762174920339987 },
      { lat: 45.41637543393432, lng: 20.87431277841341 },
    ],
    [
      { lat: 42.05186, lng: 20.76216 },
      { lat: 41.84711, lng: 20.71731000000011 },
      { lat: 41.85541, lng: 20.59023 },
      { lat: 42.21787, lng: 20.52295 },
      { lat: 42.3202500000001, lng: 20.28374 },
      { lat: 42.58863, lng: 20.0707 },
      { lat: 42.81275000000011, lng: 20.25758 },
      { lat: 42.88469, lng: 20.49679 },
      { lat: 43.21671, lng: 20.63508 },
      { lat: 43.27205, lng: 20.81448 },
      { lat: 43.13094, lng: 20.95651 },
      { lat: 43.06868500000012, lng: 21.143395 },
      { lat: 42.90959, lng: 21.27421 },
      { lat: 42.86255, lng: 21.43866 },
      { lat: 42.67717, lng: 21.63302 },
      { lat: 42.6827, lng: 21.77505 },
      { lat: 42.43922, lng: 21.66292 },
      { lat: 42.3202500000001, lng: 21.54332 },
      { lat: 42.24522439706186, lng: 21.57663598940212 },
      { lat: 42.2068, lng: 21.35270000000014 },
      { lat: 42.05186, lng: 20.76216 },
    ],
  ],
  RU: [
    [
      { lat: 70.83219920854668, lng: 180.00000000000014 },
      { lat: 70.78114, lng: 178.9034250000001 },
      { lat: 71.0988, lng: 178.7253 },
      { lat: 71.51571433642826, lng: 180.00000000000014 },
      { lat: 70.83219920854668, lng: 180.00000000000014 },
    ],
    [
      { lat: 50.74760040954151, lng: 143.64800744036287 },
      { lat: 48.976390692737596, lng: 144.65414757708564 },
      { lat: 49.30655141865037, lng: 143.17392785051723 },
      { lat: 47.861575018904915, lng: 142.5586682476501 },
      { lat: 46.83672801369249, lng: 143.53349246640406 },
      { lat: 46.13790761980948, lng: 143.5052771343726 },
      { lat: 46.74076487892657, lng: 142.74770063697392 },
      { lat: 45.96675527605879, lng: 142.0920300640545 },
      { lat: 46.80592886004655, lng: 141.90692508358504 },
      { lat: 47.780132961612935, lng: 142.0184428244709 },
      { lat: 48.85918854429956, lng: 141.90444461483506 },
      { lat: 49.61516307229746, lng: 142.13580000220568 },
      { lat: 50.95234243428192, lng: 142.1799833518153 },
      { lat: 51.93543488220254, lng: 141.59407596249002 },
      { lat: 53.30196645772878, lng: 141.68254601457366 },
      { lat: 53.762145087287905, lng: 142.60693403541075 },
      { lat: 54.22547597921687, lng: 142.2097489768154 },
      { lat: 54.36588084575388, lng: 142.654786411713 },
      { lat: 53.704577541714734, lng: 142.91461551327657 },
      { lat: 52.74076040303905, lng: 143.26084760963207 },
      { lat: 51.75666026468875, lng: 143.23526777564766 },
      { lat: 50.74760040954151, lng: 143.64800744036287 },
    ],
    [
      { lat: 78.88094, lng: 99.93976 },
      { lat: 78.7562, lng: 97.75794 },
      { lat: 79.044745, lng: 94.97259 },
      { lat: 79.4265, lng: 93.31288 },
      { lat: 80.14379, lng: 92.5454 },
      { lat: 80.34146, lng: 91.18107 },
      { lat: 81.0246, lng: 93.77766 },
      { lat: 81.2504, lng: 95.940895 },
      { lat: 80.746975, lng: 97.88385 },
      { lat: 79.780135, lng: 100.186655 },
      { lat: 78.88094, lng: 99.93976 },
    ],
    [
      { lat: 66.58435, lng: -175.01425 },
      { lat: 66.33556, lng: -174.33983 },
      { lat: 67.06219, lng: -174.57182 },
      { lat: 66.91308, lng: -171.85731 },
      { lat: 65.97724, lng: -169.89958 },
      { lat: 65.54139, lng: -170.89107 },
      { lat: 65.43791, lng: -172.53025 },
      { lat: 64.46079, lng: -172.555 },
      { lat: 64.25269, lng: -172.95533 },
      { lat: 64.2826, lng: -173.89184 },
      { lat: 64.63125, lng: -174.65392 },
      { lat: 64.92288, lng: -175.98353 },
      { lat: 65.35667, lng: -176.20716 },
      { lat: 65.52024, lng: -177.22266 },
      { lat: 65.39052, lng: -178.35993 },
      { lat: 65.74044, lng: -178.90332 },
      { lat: 66.11211, lng: -178.68611 },
      { lat: 65.87456, lng: -179.88377 },
      { lat: 65.40411, lng: -179.43268 },
      { lat: 64.97970870219837, lng: -180 },
      { lat: 68.96363636363635, lng: -180 },
      { lat: 68.2, lng: -177.55 },
      { lat: 67.20589, lng: -174.92825 },
      { lat: 66.58435, lng: -175.01425 },
    ],
    [
      { lat: 70.89302, lng: -178.69378 },
      { lat: 70.83219920854668, lng: -180 },
      { lat: 71.51571433642826, lng: -180 },
      { lat: 71.55762, lng: -179.871875 },
      { lat: 71.55553, lng: -179.02433 },
      { lat: 71.26948, lng: -177.577945 },
      { lat: 71.13277, lng: -177.663575 },
      { lat: 70.89302, lng: -178.69378 },
    ],
    [
      { lat: 75.562625, lng: 145.086285 },
      { lat: 74.82, lng: 144.3 },
      { lat: 74.84768, lng: 140.61381 },
      { lat: 74.61148, lng: 138.95544 },
      { lat: 75.26167, lng: 136.97439 },
      { lat: 75.94917, lng: 137.51176 },
      { lat: 76.13676, lng: 138.831075 },
      { lat: 76.09289, lng: 141.471615 },
      { lat: 75.562625, lng: 145.086285 },
    ],
    [
      { lat: 76.97419, lng: 106.97013000000013 },
      { lat: 76.48, lng: 107.24000000000015 },
      { lat: 76.72335000000015, lng: 108.1538 },
      { lat: 76.71, lng: 111.07726000000017 },
      { lat: 76.22224, lng: 113.33151 },
      { lat: 75.84764, lng: 114.13417 },
      { lat: 75.32779000000014, lng: 113.88539 },
      { lat: 75.03186, lng: 112.77918 },
      { lat: 74.47673, lng: 110.1512500000002 },
      { lat: 74.18, lng: 109.4 },
      { lat: 74.04, lng: 110.64 },
      { lat: 73.78774000000011, lng: 112.11919 },
      { lat: 73.97693000000015, lng: 113.01954000000026 },
      { lat: 73.33505000000011, lng: 113.52958000000032 },
      { lat: 73.59488, lng: 113.96881 },
      { lat: 73.75285, lng: 115.56782 },
      { lat: 73.58772, lng: 118.77633000000023 },
      { lat: 73.12, lng: 119.02 },
      { lat: 72.97122, lng: 123.20066000000011 },
      { lat: 73.73503000000011, lng: 123.25777000000018 },
      { lat: 73.56, lng: 125.38000000000018 },
      { lat: 73.56549, lng: 126.97644 },
      { lat: 73.03871, lng: 128.59126 },
      { lat: 72.39872, lng: 129.05157 },
      { lat: 71.98, lng: 128.46000000000012 },
      { lat: 71.19304, lng: 129.7159900000002 },
      { lat: 70.78699000000012, lng: 131.28858000000028 },
      { lat: 71.83630000000011, lng: 132.25350000000017 },
      { lat: 71.38642000000016, lng: 133.85766000000032 },
      { lat: 71.65525000000014, lng: 135.56193 },
      { lat: 71.34763, lng: 137.49755 },
      { lat: 71.62803, lng: 138.23409000000018 },
      { lat: 71.48783000000014, lng: 139.86983000000012 },
      { lat: 72.4161900000001, lng: 139.14791 },
      { lat: 72.84941000000013, lng: 140.46817 },
      { lat: 72.2, lng: 149.5 },
      { lat: 71.60643, lng: 150.3511800000002 },
      { lat: 70.84222, lng: 152.96890000000022 },
      { lat: 71.03141, lng: 157.00688 },
      { lat: 70.86672, lng: 158.99779 },
      { lat: 70.45324, lng: 159.83031000000025 },
      { lat: 69.72198, lng: 159.70866 },
      { lat: 69.4372800000001, lng: 160.94053000000034 },
      { lat: 69.64204, lng: 162.27907000000013 },
      { lat: 69.66823, lng: 164.05248000000014 },
      { lat: 69.47199, lng: 165.94037000000023 },
      { lat: 69.58269, lng: 167.83567 },
      { lat: 68.6938, lng: 169.5776300000002 },
      { lat: 69.01363, lng: 170.81688000000028 },
      { lat: 69.65276, lng: 170.0082000000002 },
      { lat: 70.09703, lng: 170.4534500000003 },
      { lat: 69.81743, lng: 173.64391000000026 },
      { lat: 69.87725000000023, lng: 175.72403000000023 },
      { lat: 69.4, lng: 178.6 },
      { lat: 68.96363636363657, lng: 180.00000000000014 },
      { lat: 64.97970870219848, lng: 180.00000000000014 },
      { lat: 64.97433, lng: 179.99281 },
      { lat: 64.53493, lng: 178.70720000000026 },
      { lat: 64.60821, lng: 177.41128000000018 },
      { lat: 64.07593, lng: 178.31300000000024 },
      { lat: 63.251970000000135, lng: 178.9082500000002 },
      { lat: 62.982620000000104, lng: 179.37034 },
      { lat: 62.56894, lng: 179.48636 },
      { lat: 62.30410000000015, lng: 179.22825000000014 },
      { lat: 62.5219, lng: 177.3643 },
      { lat: 61.76915, lng: 174.56929000000022 },
      { lat: 61.65261, lng: 173.68013 },
      { lat: 60.95, lng: 172.15 },
      { lat: 60.33618, lng: 170.6985000000001 },
      { lat: 59.88177, lng: 170.3308500000003 },
      { lat: 60.57355, lng: 168.90046 },
      { lat: 59.788550000000214, lng: 166.29498000000032 },
      { lat: 60.16, lng: 165.84000000000023 },
      { lat: 59.7316, lng: 164.87674 },
      { lat: 59.86871, lng: 163.53929000000014 },
      { lat: 59.21101, lng: 163.21711000000025 },
      { lat: 58.24328, lng: 162.0173300000001 },
      { lat: 57.83912, lng: 162.05297 },
      { lat: 57.61503000000011, lng: 163.19191 },
      { lat: 56.159240000000125, lng: 163.05794000000017 },
      { lat: 56.12219, lng: 162.12958000000023 },
      { lat: 55.285680000000156, lng: 161.70146 },
      { lat: 54.85514, lng: 162.11749000000017 },
      { lat: 54.34433, lng: 160.36877000000032 },
      { lat: 53.20257, lng: 160.02173000000022 },
      { lat: 52.958680000000236, lng: 158.5309400000002 },
      { lat: 51.94269, lng: 158.23118 },
      { lat: 51.01105, lng: 156.7897900000003 },
      { lat: 51.7, lng: 156.42000000000016 },
      { lat: 53.15895, lng: 155.99182 },
      { lat: 55.38103000000012, lng: 155.43366000000012 },
      { lat: 56.767920000000146, lng: 155.91442000000032 },
      { lat: 57.3647, lng: 156.75815 },
      { lat: 57.83204, lng: 156.8103500000001 },
      { lat: 58.05575, lng: 158.3643300000002 },
      { lat: 59.31477000000012, lng: 160.15064000000012 },
      { lat: 60.34300000000013, lng: 161.87204 },
      { lat: 61.1409, lng: 163.66969 },
      { lat: 62.55061, lng: 164.47355000000013 },
      { lat: 62.46627, lng: 163.2584200000002 },
      { lat: 61.6425, lng: 162.65791 },
      { lat: 60.54423, lng: 160.1214800000001 },
      { lat: 61.77396, lng: 159.30232 },
      { lat: 61.43442, lng: 156.7206800000001 },
      { lat: 59.75818000000013, lng: 154.21806000000035 },
      { lat: 59.14495, lng: 155.04375 },
      { lat: 58.88385, lng: 152.81185 },
      { lat: 58.78089, lng: 151.26573000000025 },
      { lat: 59.50396, lng: 151.33815000000013 },
      { lat: 59.65573000000014, lng: 149.78371 },
      { lat: 59.16448, lng: 148.54481 },
      { lat: 59.33637, lng: 145.48722 },
      { lat: 59.03998, lng: 142.19782000000018 },
      { lat: 57.08805, lng: 138.95848000000032 },
      { lat: 54.72959, lng: 135.12619 },
      { lat: 54.603550000000126, lng: 136.70171 },
      { lat: 53.97732, lng: 137.19342 },
      { lat: 53.755010000000254, lng: 138.1647 },
      { lat: 54.25455000000011, lng: 138.80463 },
      { lat: 54.18968000000018, lng: 139.90151 },
      { lat: 53.08957000000012, lng: 141.34531 },
      { lat: 52.23877, lng: 141.37923 },
      { lat: 51.2396700000001, lng: 140.5974200000002 },
      { lat: 50.04553000000013, lng: 140.51308 },
      { lat: 48.44671000000017, lng: 140.06193000000022 },
      { lat: 46.99965, lng: 138.5547200000002 },
      { lat: 46.30795, lng: 138.21971 },
      { lat: 45.14350000000019, lng: 136.86232 },
      { lat: 43.989, lng: 135.5153500000002 },
      { lat: 43.39821, lng: 134.86939000000027 },
      { lat: 42.81147, lng: 133.53687000000028 },
      { lat: 42.79849, lng: 132.90627000000015 },
      { lat: 43.28456000000011, lng: 132.27807000000027 },
      { lat: 42.55274, lng: 130.93587000000014 },
      { lat: 42.22000000000019, lng: 130.78 },
      { lat: 42.395, lng: 130.64000000000019 },
      { lat: 42.90301463477056, lng: 130.6338664084098 },
      { lat: 42.92998973242695, lng: 131.144687941615 },
      { lat: 44.111519680348266, lng: 131.28855512911562 },
      { lat: 44.96796, lng: 131.02519000000026 },
      { lat: 45.32116160743652, lng: 131.8834542176596 },
      { lat: 45.14409, lng: 133.09712000000022 },
      { lat: 46.116926988299156, lng: 133.7696439963132 },
      { lat: 47.21248000000014, lng: 134.1123500000002 },
      { lat: 47.578450000000146, lng: 134.50081 },
      { lat: 48.47822988544391, lng: 135.0263114767868 },
      { lat: 48.18344167743484, lng: 133.37359581922803 },
      { lat: 47.78896, lng: 132.50669000000013 },
      { lat: 47.79013, lng: 130.98726000000013 },
      { lat: 48.729687404976204, lng: 130.58229332898267 },
      { lat: 49.440600084015614, lng: 129.3978178244205 },
      { lat: 49.76027, lng: 127.65740000000038 },
      { lat: 50.73979726826545, lng: 127.28745568248493 },
      { lat: 51.3538941514059, lng: 126.93915652883786 },
      { lat: 51.7842554795327, lng: 126.56439904185699 },
      { lat: 52.79279857035695, lng: 125.94634891164647 },
      { lat: 53.161044826868924, lng: 125.06821129771045 },
      { lat: 53.4588, lng: 123.57147 },
      { lat: 53.43172597921369, lng: 122.24574791879307 },
      { lat: 53.25140106873124, lng: 121.00308475147037 },
      { lat: 52.75388621684121, lng: 120.1770886577169 },
      { lat: 52.51622630473091, lng: 120.725789015792 },
      { lat: 51.96411, lng: 120.7382 },
      { lat: 51.64355, lng: 120.18208000000018 },
      { lat: 50.58292, lng: 119.27939 },
      { lat: 50.14288279886196, lng: 119.28846072802585 },
      { lat: 49.51098338479704, lng: 117.8792444194265 },
      { lat: 49.888531399121405, lng: 116.67880089728621 },
      { lat: 49.80517731383475, lng: 115.48569542853144 },
      { lat: 50.14024730081513, lng: 114.96210981655038 },
      { lat: 50.248302720737485, lng: 114.36245649623534 },
      { lat: 49.54356537535699, lng: 112.89773969935439 },
      { lat: 49.37796824807767, lng: 111.58123091028668 },
      { lat: 49.13012807880585, lng: 110.66201053267886 },
      { lat: 49.29296051695769, lng: 109.40244917199672 },
      { lat: 49.28254771585071, lng: 108.47516727095127 },
      { lat: 49.79370514586588, lng: 107.86817589725112 },
      { lat: 50.27429596618029, lng: 106.88880415245532 },
      { lat: 50.406019192092174, lng: 105.8865914245869 },
      { lat: 50.275320000000164, lng: 104.62158 },
      { lat: 50.089966132195144, lng: 103.67654544476036 },
      { lat: 50.51056000000011, lng: 102.25589000000011 },
      { lat: 51.25991, lng: 102.06521 },
      { lat: 51.51685578063842, lng: 100.88948042196265 },
      { lat: 51.63400625264395, lng: 99.98173221232356 },
      { lat: 52.04736603454671, lng: 98.8614905131005 },
      { lat: 51.01099518493325, lng: 97.82573978067452 },
      { lat: 50.42240062112873, lng: 98.23176150919173 },
      { lat: 49.72605, lng: 97.25976000000023 },
      { lat: 49.977460000000114, lng: 95.81402000000017 },
      { lat: 50.01343333597088, lng: 94.81594933469879 },
      { lat: 50.48053660745716, lng: 94.14756635943561 },
      { lat: 50.49529, lng: 93.10421 },
      { lat: 50.80217072204175, lng: 92.23471154171969 },
      { lat: 50.331811835321105, lng: 90.71366743364078 },
      { lat: 49.47052073831247, lng: 88.80556684769559 },
      { lat: 49.29719798440556, lng: 87.75126427607685 },
      { lat: 49.21498078062916, lng: 87.35997033076269 },
      { lat: 49.82667470966813, lng: 86.82935672398966 },
      { lat: 49.69285858824816, lng: 85.5412699726825 },
      { lat: 50.11730296487763, lng: 85.11555952346211 },
      { lat: 50.311399644565824, lng: 84.41637739455304 },
      { lat: 50.88924551045358, lng: 83.93511478061893 },
      { lat: 51.069182847693895, lng: 83.38300377801247 },
      { lat: 50.81219594990633, lng: 81.94598554883994 },
      { lat: 51.38833649352844, lng: 80.56844689323546 },
      { lat: 50.864750881547224, lng: 80.03555952344172 },
      { lat: 53.40441498474754, lng: 77.80091556184433 },
      { lat: 54.177003485727134, lng: 76.52517947785478 },
      { lat: 54.49052440044193, lng: 76.89110029491346 },
      { lat: 53.54685000000011, lng: 74.38482000000013 },
      { lat: 53.489810289109755, lng: 73.42567874542053 },
      { lat: 54.035616766976595, lng: 73.50851606638437 },
      { lat: 54.37665538188679, lng: 72.22415001820221 },
      { lat: 54.133285224008254, lng: 71.1801310566095 },
      { lat: 55.169733588270105, lng: 70.86526655465516 },
      { lat: 55.3852501491435, lng: 69.06816694527289 },
      { lat: 54.97039175070438, lng: 68.1691003762589 },
      { lat: 54.601250000000164, lng: 65.6668700000001 },
      { lat: 54.35422781027208, lng: 65.17853356309595 },
      { lat: 54.00625, lng: 61.43660000000013 },
      { lat: 53.66499339457914, lng: 60.97806644068325 },
      { lat: 52.97999644633427, lng: 61.699986199800634 },
      { lat: 52.71998647725775, lng: 60.739993117114544 },
      { lat: 52.447548326215006, lng: 60.92726850774025 },
      { lat: 51.960420437215674, lng: 59.96753380721557 },
      { lat: 51.272658799843185, lng: 61.58800337102414 },
      { lat: 50.79907013610426, lng: 61.33742435084101 },
      { lat: 50.842194118851836, lng: 59.93280724471557 },
      { lat: 50.545442206415714, lng: 59.64228234237057 },
      { lat: 51.06364, lng: 58.36332000000013 },
      { lat: 51.04355, lng: 56.77798 },
      { lat: 50.62171000000015, lng: 55.71694000000011 },
      { lat: 51.02623973245937, lng: 54.532878452376195 },
      { lat: 51.718652248738096, lng: 52.32872358583106 },
      { lat: 51.69276235615987, lng: 50.76664839051219 },
      { lat: 50.60512848571284, lng: 48.702381626181044 },
      { lat: 49.874759629915644, lng: 48.577841424357615 },
      { lat: 50.454698391311126, lng: 47.549480421749394 },
      { lat: 49.35600576435374, lng: 46.75159630716277 },
      { lat: 49.152038886097586, lng: 47.0436715024766 },
      { lat: 48.39415233010493, lng: 46.46644575377629 },
      { lat: 47.71585, lng: 47.31524000000016 },
      { lat: 47.74377, lng: 48.05725 },
      { lat: 47.0756281601779, lng: 48.694733514201886 },
      { lat: 46.561040000000105, lng: 48.593250000000154 },
      { lat: 46.39933, lng: 49.101160000000135 },
      { lat: 45.80629, lng: 48.64541000000011 },
      { lat: 45.64149000000012, lng: 47.67591 },
      { lat: 44.6092000000001, lng: 46.68201 },
      { lat: 43.66016000000013, lng: 47.59094 },
      { lat: 42.98658, lng: 47.49252 },
      { lat: 41.80888, lng: 48.58437000000018 },
      { lat: 41.4058192001944, lng: 47.98728315612604 },
      { lat: 41.151416124021345, lng: 47.81566572448466 },
      { lat: 41.21973236751114, lng: 47.373315464066394 },
      { lat: 41.827137152669906, lng: 46.686070591016716 },
      { lat: 41.860675157227426, lng: 46.40495079934894 },
      { lat: 42.09244000000024, lng: 45.7764 },
      { lat: 42.50278066667005, lng: 45.470279168485916 },
      { lat: 42.711992702803684, lng: 44.53762291848207 },
      { lat: 42.5549600000001, lng: 43.93121000000011 },
      { lat: 42.74083, lng: 43.755990000000196 },
      { lat: 43.2203, lng: 42.39440000000016 },
      { lat: 43.38215000000014, lng: 40.92219000000014 },
      { lat: 43.553104153002494, lng: 40.07696495947985 },
      { lat: 43.434997666999294, lng: 39.955008579271095 },
      { lat: 44.28, lng: 38.68 },
      { lat: 44.65721, lng: 37.53912000000011 },
      { lat: 45.24469, lng: 36.67546000000013 },
      { lat: 45.4045100000001, lng: 37.40317 },
      { lat: 46.24087, lng: 38.23295 },
      { lat: 46.63657, lng: 37.67372 },
      { lat: 47.04475000000013, lng: 39.14767 },
      { lat: 47.26336, lng: 39.12120000000013 },
      { lat: 47.10218984637598, lng: 38.22353803889948 },
      { lat: 47.54640045835697, lng: 38.25511233902981 },
      { lat: 47.82562000000024, lng: 38.77057 },
      { lat: 47.89893707945208, lng: 39.738277622238996 },
      { lat: 48.23241, lng: 39.89562000000015 },
      { lat: 48.783820000000134, lng: 39.67465 },
      { lat: 49.30742991799937, lng: 40.08078901546949 },
      { lat: 49.60105, lng: 40.069040000000115 },
      { lat: 49.92646190042373, lng: 38.59498823421356 },
      { lat: 49.91566152607473, lng: 38.010631137857075 },
      { lat: 50.38395335550368, lng: 37.39345950699524 },
      { lat: 50.225590928745135, lng: 36.626167840325394 },
      { lat: 50.57719737405915, lng: 35.35611616388812 },
      { lat: 50.77394, lng: 35.37791 },
      { lat: 51.2075723333715, lng: 35.02218305841794 },
      { lat: 51.255993150428935, lng: 34.2248157081544 },
      { lat: 51.566413479206204, lng: 34.14197838719061 },
      { lat: 51.768881740925906, lng: 34.39173058445723 },
      { lat: 52.33507457133166, lng: 33.75269982273588 },
      { lat: 52.23846548116216, lng: 32.71576053236716 },
      { lat: 52.28869497334977, lng: 32.41205813978777 },
      { lat: 52.061250000000115, lng: 32.15944000000022 },
      { lat: 52.10168, lng: 31.78597 },
      { lat: 52.74205231384644, lng: 31.54001834486226 },
      { lat: 53.0739958766733, lng: 31.30520063652798 },
      { lat: 53.16743000000014, lng: 31.49764 },
      { lat: 53.132726141972846, lng: 32.304519484188376 },
      { lat: 53.35142080343214, lng: 32.693643019346126 },
      { lat: 53.618045355842014, lng: 32.405598585751164 },
      { lat: 53.79402944601202, lng: 31.731272820774592 },
      { lat: 53.974638576872195, lng: 31.791424187962406 },
      { lat: 54.15705638286238, lng: 31.384472283663822 },
      { lat: 54.811770941784395, lng: 30.75753380709878 },
      { lat: 55.081547756564134, lng: 30.971835971813245 },
      { lat: 55.55097646750351, lng: 30.87390913262007 },
      { lat: 55.7894632025305, lng: 29.89629438652244 },
      { lat: 55.67009064393628, lng: 29.37157189303079 },
      { lat: 55.91834422466641, lng: 29.22951338066039 },
      { lat: 56.16912995057879, lng: 28.17670942557794 },
      { lat: 56.75932648378438, lng: 27.855282016722526 },
      { lat: 57.244258124411196, lng: 27.770015903440992 },
      { lat: 57.47452830670392, lng: 27.288184848751655 },
      { lat: 57.79189911562445, lng: 27.71668582531578 },
      { lat: 58.72457000000014, lng: 27.420150000000202 },
      { lat: 59.300825100331, lng: 28.131699253051863 },
      { lat: 59.47537, lng: 27.98112 },
      { lat: 60.02805000000012, lng: 29.1177 },
      { lat: 60.50352000000015, lng: 28.07 },
      { lat: 61.78002777774969, lng: 30.211107212044652 },
      { lat: 62.357692776124445, lng: 31.139991082491036 },
      { lat: 62.867687486412905, lng: 31.51609215671127 },
      { lat: 63.552813625738565, lng: 30.035872430142803 },
      { lat: 64.20445343693908, lng: 30.444684686003736 },
      { lat: 64.94867157659056, lng: 29.544429559047018 },
      { lat: 65.80598, lng: 30.21765 },
      { lat: 66.94428620062203, lng: 29.054588657352383 },
      { lat: 67.69829702419275, lng: 29.977426385220696 },
      { lat: 68.36461294216399, lng: 28.44594363781877 },
      { lat: 69.0647769232867, lng: 28.591929559043365 },
      { lat: 69.15692000000018, lng: 29.39955 },
      { lat: 69.55811, lng: 31.10108000000011 },
      { lat: 69.90595000000025, lng: 32.13272000000026 },
      { lat: 69.30142000000012, lng: 33.77547 },
      { lat: 69.06342, lng: 36.51396 },
      { lat: 67.9324, lng: 40.292340000000166 },
      { lat: 67.4571300000001, lng: 41.059870000000124 },
      { lat: 66.79158000000012, lng: 41.12595000000019 },
      { lat: 66.26618000000013, lng: 40.01583 },
      { lat: 65.9995300000001, lng: 38.38295 },
      { lat: 66.75961, lng: 33.918710000000175 },
      { lat: 66.63253, lng: 33.18444 },
      { lat: 65.90015000000014, lng: 34.81477 },
      { lat: 65.4362128770482, lng: 34.87857425307877 },
      { lat: 64.41437000000016, lng: 34.94391000000015 },
      { lat: 64.10945, lng: 36.23129 },
      { lat: 63.84983000000011, lng: 37.01273000000012 },
      { lat: 64.33471, lng: 37.14197000000016 },
      { lat: 64.76446, lng: 36.539579035089815 },
      { lat: 65.14322000000013, lng: 37.17604000000014 },
      { lat: 64.52079000000018, lng: 39.59345 },
      { lat: 64.76446, lng: 40.43560000000011 },
      { lat: 65.49682, lng: 39.76260000000016 },
      { lat: 66.47623, lng: 42.0930900000001 },
      { lat: 66.41858, lng: 43.01604000000012 },
      { lat: 66.06908, lng: 43.94975000000014 },
      { lat: 66.75634000000014, lng: 44.53226 },
      { lat: 67.35245, lng: 43.69839 },
      { lat: 67.95051, lng: 44.18795000000014 },
      { lat: 68.57079, lng: 43.45282 },
      { lat: 68.25, lng: 46.25000000000014 },
      { lat: 67.68997, lng: 46.82134000000016 },
      { lat: 67.56652, lng: 45.55517 },
      { lat: 67.01005000000019, lng: 45.5620200000001 },
      { lat: 66.6676700000001, lng: 46.34915000000015 },
      { lat: 66.88455000000016, lng: 47.894160000000255 },
      { lat: 67.52238, lng: 48.13876 },
      { lat: 67.99867000000013, lng: 50.22766000000016 },
      { lat: 68.85738000000012, lng: 53.71743000000018 },
      { lat: 68.80815, lng: 54.47171 },
      { lat: 68.20131, lng: 53.48582000000013 },
      { lat: 68.09702, lng: 54.72628 },
      { lat: 68.43866, lng: 55.44268000000014 },
      { lat: 68.46628, lng: 57.317020000000156 },
      { lat: 68.88082, lng: 58.80200000000021 },
      { lat: 68.2784400000001, lng: 59.94142000000019 },
      { lat: 68.94069, lng: 61.07784000000018 },
      { lat: 69.52, lng: 60.03 },
      { lat: 69.85, lng: 60.55 },
      { lat: 69.54739, lng: 63.50400000000016 },
      { lat: 69.23483500000013, lng: 64.888115 },
      { lat: 68.09233000000017, lng: 68.51216000000014 },
      { lat: 68.61563000000012, lng: 69.18068 },
      { lat: 69.14436, lng: 68.16444 },
      { lat: 69.35649, lng: 68.13522 },
      { lat: 69.45461000000012, lng: 66.93008000000012 },
      { lat: 69.92873, lng: 67.25976 },
      { lat: 70.70889000000014, lng: 66.72492000000014 },
      { lat: 71.02897000000024, lng: 66.69466 },
      { lat: 71.93450000000024, lng: 68.54006000000011 },
      { lat: 72.84336000000016, lng: 69.19636000000011 },
      { lat: 73.04000000000013, lng: 69.94 },
      { lat: 72.7762900000001, lng: 72.58754 },
      { lat: 72.22006, lng: 72.79603 },
      { lat: 71.40898, lng: 71.8481100000001 },
      { lat: 71.09019, lng: 72.47011 },
      { lat: 70.39114, lng: 72.79188 },
      { lat: 69.02085, lng: 72.56470000000022 },
      { lat: 68.4079, lng: 73.66787 },
      { lat: 67.7404, lng: 73.2387 },
      { lat: 66.32000000000016, lng: 71.28000000000011 },
      { lat: 66.17267000000018, lng: 72.42301000000018 },
      { lat: 66.53267, lng: 72.82077 },
      { lat: 66.78946000000013, lng: 73.92099000000016 },
      { lat: 67.28429, lng: 74.1865100000002 },
      { lat: 67.76047000000017, lng: 75.052 },
      { lat: 68.32899, lng: 74.46926000000016 },
      { lat: 68.98918, lng: 74.93584000000013 },
      { lat: 69.07146, lng: 73.84236 },
      { lat: 69.62763, lng: 73.60187000000022 },
      { lat: 70.63175, lng: 74.3998 },
      { lat: 71.44717000000026, lng: 73.1011 },
      { lat: 72.12119, lng: 74.89082000000022 },
      { lat: 72.83227, lng: 74.65926 },
      { lat: 72.85497000000011, lng: 75.15801000000019 },
      { lat: 72.30056000000013, lng: 75.68351 },
      { lat: 71.33556, lng: 75.28898000000012 },
      { lat: 71.15287000000015, lng: 76.35911 },
      { lat: 71.87401, lng: 75.90313000000017 },
      { lat: 72.26717, lng: 77.57665000000011 },
      { lat: 72.32011, lng: 79.65202000000014 },
      { lat: 71.75, lng: 81.5 },
      { lat: 72.58285000000012, lng: 80.61071000000013 },
      { lat: 73.6482, lng: 80.51109 },
      { lat: 73.85000000000011, lng: 82.25 },
      { lat: 73.80591000000018, lng: 84.65526 },
      { lat: 73.93688, lng: 86.82230000000024 },
      { lat: 74.45967000000014, lng: 86.00956 },
      { lat: 75.11643, lng: 87.16682000000017 },
      { lat: 75.14393, lng: 88.31571000000011 },
      { lat: 75.64, lng: 90.26 },
      { lat: 75.77333, lng: 92.90058 },
      { lat: 76.0472, lng: 93.23421000000016 },
      { lat: 76.14, lng: 95.86000000000016 },
      { lat: 75.91548, lng: 96.67821 },
      { lat: 76.44689, lng: 98.92254000000023 },
      { lat: 76.43028, lng: 100.75967000000023 },
      { lat: 76.86189, lng: 101.03532 },
      { lat: 77.2875400000002, lng: 101.99084000000013 },
      { lat: 77.69792, lng: 104.3516000000001 },
      { lat: 77.37389, lng: 106.06664000000013 },
      { lat: 77.1274, lng: 104.70500000000024 },
      { lat: 76.97419, lng: 106.97013000000013 },
    ],
    [
      { lat: 78.30689, lng: 105.07547 },
      { lat: 77.921, lng: 99.43814 },
      { lat: 79.23399, lng: 101.2649 },
      { lat: 79.34641, lng: 102.08635 },
      { lat: 79.28129, lng: 102.837815 },
      { lat: 78.71334, lng: 105.37243 },
      { lat: 78.30689, lng: 105.07547 },
    ],
    [
      { lat: 73.21244, lng: 143.60385 },
      { lat: 73.20544, lng: 142.08763 },
      { lat: 73.31692, lng: 140.038155 },
      { lat: 73.36983, lng: 139.86312 },
      { lat: 73.76506, lng: 140.81171 },
      { lat: 73.85758, lng: 142.06207 },
      { lat: 73.47525, lng: 143.48283 },
      { lat: 73.21244, lng: 143.60385 },
    ],
    [
      { lat: 54.327536932993326, lng: 22.731098667092652 },
      { lat: 54.312524929412575, lng: 20.892244500418652 },
      { lat: 54.426083889373984, lng: 19.660640089606403 },
      { lat: 54.8661603867715, lng: 19.888481479581344 },
      { lat: 55.19048167583528, lng: 21.2684489275035 },
      { lat: 55.0152985703659, lng: 22.315723504330606 },
      { lat: 54.85657440858142, lng: 22.757763706155288 },
      { lat: 54.58274099386671, lng: 22.651051873472568 },
      { lat: 54.327536932993326, lng: 22.731098667092652 },
    ],
    [
      { lat: 75.08406, lng: 150.73167 },
      { lat: 74.68892, lng: 149.575925 },
      { lat: 74.778355, lng: 147.977465 },
      { lat: 75.17298, lng: 146.11919 },
      { lat: 75.49682, lng: 146.358485 },
      { lat: 75.345845, lng: 148.22223 },
      { lat: 75.08406, lng: 150.73167 },
    ],
    [
      { lat: 80.54728017854093, lng: 51.13618655783128 },
      { lat: 80.4154277615482, lng: 49.79368452332071 },
      { lat: 80.3395667589437, lng: 48.89441124857754 },
      { lat: 80.17546824820084, lng: 48.754936557821765 },
      { lat: 80.01018117951533, lng: 47.586119012244154 },
      { lat: 80.24724681265437, lng: 46.502825962109654 },
      { lat: 80.55942414012945, lng: 47.07245527526291 },
      { lat: 80.58980988231718, lng: 44.846958042181114 },
      { lat: 80.77191762971364, lng: 46.79913862487123 },
      { lat: 80.78400991486996, lng: 48.318477410684665 },
      { lat: 80.51456899690015, lng: 48.522806023966695 },
      { lat: 80.75398590770843, lng: 49.09718956889091 },
      { lat: 80.91888540315182, lng: 50.03976769389462 },
      { lat: 80.6997256538019, lng: 51.52293297710369 },
      { lat: 80.54728017854093, lng: 51.13618655783128 },
    ],
    [
      { lat: 70.72046397570216, lng: 57.5356925799924 },
      { lat: 70.63274323188668, lng: 56.94497928246395 },
      { lat: 70.76265778266847, lng: 53.6773751157842 },
      { lat: 71.2066616889202, lng: 53.41201663596539 },
      { lat: 71.47475901965049, lng: 51.60189456564572 },
      { lat: 72.01488108996514, lng: 51.45575361512422 },
      { lat: 72.22944163684096, lng: 52.47827518088357 },
      { lat: 72.77473135038485, lng: 52.444168735570855 },
      { lat: 73.62754751249759, lng: 54.42761355979766 },
      { lat: 73.74981395130015, lng: 53.50828982932515 },
      { lat: 74.62748647734533, lng: 55.90245893740766 },
      { lat: 75.08141225859717, lng: 55.631932814359715 },
      { lat: 75.60939036732321, lng: 57.86864383324885 },
      { lat: 76.25188345000814, lng: 61.170044386647504 },
      { lat: 76.43905548776928, lng: 64.49836836127022 },
      { lat: 76.80978221303124, lng: 66.2109770038551 },
      { lat: 76.93969676381292, lng: 68.15705976753483 },
      { lat: 76.54481130645462, lng: 68.85221113472512 },
      { lat: 76.23364166940911, lng: 68.18057254422766 },
      { lat: 75.73775462513623, lng: 64.637326287703 },
      { lat: 75.2608845079468, lng: 61.58350752141476 },
      { lat: 74.30905630156283, lng: 58.47708214705338 },
      { lat: 73.33304352486624, lng: 56.98678551618801 },
      { lat: 72.37126760526598, lng: 55.419335971910954 },
      { lat: 71.54059479439033, lng: 55.622837762276305 },
      { lat: 70.72046397570216, lng: 57.5356925799924 },
    ],
  ],
  RW: [
    [
      { lat: -1.134659112150416, lng: 30.41910485201924 },
      { lat: -1.698914076345389, lng: 30.81613488131771 },
      { lat: -2.287250257988369, lng: 30.75830895358311 },
      { lat: -2.413857517103458, lng: 30.469696079232985 },
      { lat: -2.348486830254238, lng: 29.938359002407942 },
      { lat: -2.917857761246097, lng: 29.632176141078588 },
      { lat: -2.839257907730158, lng: 29.024926385216787 },
      { lat: -2.292211195488385, lng: 29.117478875451553 },
      { lat: -2.215109958508911, lng: 29.25483483248334 },
      { lat: -1.620055840667987, lng: 29.29188683443661 },
      { lat: -1.341313164885626, lng: 29.579466180140884 },
      { lat: -1.443322442229785, lng: 29.82151858899601 },
      { lat: -1.134659112150416, lng: 30.41910485201924 },
    ],
  ],
  SA: [
    [
      { lat: 16.347891343648683, lng: 42.77933230975097 },
      { lat: 16.774635321514964, lng: 42.649572788266084 },
      { lat: 17.075805568912003, lng: 42.347989129410706 },
      { lat: 17.474721787989125, lng: 42.270887892431226 },
      { lat: 17.833046169500975, lng: 41.75438195167396 },
      { lat: 18.671599636301206, lng: 41.22139122901558 },
      { lat: 19.486485297111756, lng: 40.93934126156654 },
      { lat: 20.17463450772649, lng: 40.247652215339826 },
      { lat: 20.338862209550054, lng: 39.80168460466095 },
      { lat: 21.29190481209293, lng: 39.139399448408284 },
      { lat: 21.986875311770195, lng: 39.023695916506796 },
      { lat: 22.57965566659027, lng: 39.06632897314759 },
      { lat: 23.688451036060854, lng: 38.49277225114008 },
      { lat: 24.07868561451293, lng: 38.02386030452362 },
      { lat: 24.285494696545015, lng: 37.483634881344386 },
      { lat: 24.85848297779731, lng: 37.154817742671185 },
      { lat: 25.084541530858104, lng: 37.209491408036 },
      { lat: 25.602959499610176, lng: 36.93162723160259 },
      { lat: 25.82622752532722, lng: 36.63960371272122 },
      { lat: 26.57013560638488, lng: 36.249136590323815 },
      { lat: 27.37652049408342, lng: 35.64018151219639 },
      { lat: 28.06335195567472, lng: 35.13018680190788 },
      { lat: 28.058546047471566, lng: 34.63233605320798 },
      { lat: 28.6074272730597, lng: 34.787778761541944 },
      { lat: 28.957483425404845, lng: 34.832220493312946 },
      { lat: 29.35655467377884, lng: 34.95603722508426 },
      { lat: 29.197494615184457, lng: 36.06894087092206 },
      { lat: 29.5052536076987, lng: 36.50121422704358 },
      { lat: 29.86528331147619, lng: 36.74052778498725 },
      { lat: 30.003776150018403, lng: 37.503581984209035 },
      { lat: 30.3386652694859, lng: 37.66811974462638 },
      { lat: 30.50849986421313, lng: 37.998848911294374 },
      { lat: 31.508412990844743, lng: 37.00216556168101 },
      { lat: 32.01021698661498, lng: 39.00488569515255 },
      { lat: 32.16100881604267, lng: 39.19546837744497 },
      { lat: 31.889991766887935, lng: 40.399994337736246 },
      { lat: 31.190008653278365, lng: 41.889980910007836 },
      { lat: 29.178891099559383, lng: 44.70949873228474 },
      { lat: 29.09902517345229, lng: 46.568713413281756 },
      { lat: 29.002519436147224, lng: 47.45982181172283 },
      { lat: 28.526062730416143, lng: 47.708850538937384 },
      { lat: 28.55200429942667, lng: 48.416094191283946 },
      { lat: 27.689627997339883, lng: 48.80759484232718 },
      { lat: 27.46121816660981, lng: 49.29955447774582 },
      { lat: 27.10999929453808, lng: 49.470913527225655 },
      { lat: 26.689663194275997, lng: 50.15242231629088 },
      { lat: 26.277026882425375, lng: 50.212935418504685 },
      { lat: 25.943972276304248, lng: 50.11330325704594 },
      { lat: 25.608049628190926, lng: 50.239858839728754 },
      { lat: 25.3278083358721, lng: 50.52738650900073 },
      { lat: 24.99989553476402, lng: 50.66055667501689 },
      { lat: 24.754742539971378, lng: 50.81010827006958 },
      { lat: 24.556330878186724, lng: 51.11241539897702 },
      { lat: 24.62738597258806, lng: 51.38960778179063 },
      { lat: 24.245497137951105, lng: 51.57951867046327 },
      { lat: 24.014219265228828, lng: 51.61770755392698 },
      { lat: 23.00115448657894, lng: 52.000733270074335 },
      { lat: 22.496947536707136, lng: 55.0068030129249 },
      { lat: 22.708329982997046, lng: 55.20834109886319 },
      { lat: 22.00000112557234, lng: 55.666659376859826 },
      { lat: 19.999994004796108, lng: 54.99998172386236 },
      { lat: 19.000003363516058, lng: 52.00000980002224 },
      { lat: 18.616667588774945, lng: 49.11667158386487 },
      { lat: 18.166669216377315, lng: 48.18334354024134 },
      { lat: 17.116681626854884, lng: 47.46669477721763 },
      { lat: 16.949999294497445, lng: 47.000004917189756 },
      { lat: 17.283338120996177, lng: 46.74999433776165 },
      { lat: 17.233315334537636, lng: 46.366658563020536 },
      { lat: 17.333335069238558, lng: 45.39999922056875 },
      { lat: 17.43332896572333, lng: 45.21665123879718 },
      { lat: 17.410358791569593, lng: 44.06261315285508 },
      { lat: 17.31997671149111, lng: 43.79151858905192 },
      { lat: 17.57998668056767, lng: 43.380794305196105 },
      { lat: 17.088440456607373, lng: 43.11579756040335 },
      { lat: 16.66688996018641, lng: 43.21837527850275 },
      { lat: 16.347891343648683, lng: 42.77933230975097 },
    ],
  ],
  SB: [
    [
      { lat: -8.337320244991716, lng: 159.8750272971986 },
      { lat: -8.538289890174866, lng: 159.917401971678 },
      { lat: -8.114181410355398, lng: 159.1336771995394 },
      { lat: -7.754823500197715, lng: 158.58611372297472 },
      { lat: -7.421872246941149, lng: 158.21114953026486 },
      { lat: -7.320017998893917, lng: 158.35997765526545 },
      { lat: -7.560003350457392, lng: 158.82000125552773 },
      { lat: -8.020026950719569, lng: 159.64000288313517 },
      { lat: -8.337320244991716, lng: 159.8750272971986 },
    ],
    [
      { lat: -7.34781991946693, lng: 157.5384257346893 },
      { lat: -7.404767347852555, lng: 157.33941979393327 },
      { lat: -7.176874281445392, lng: 156.9020304710148 },
      { lat: -6.765943291860395, lng: 156.49135786359133 },
      { lat: -6.59933847415148, lng: 156.54282759015396 },
      { lat: -7.021638278840655, lng: 157.1400004417189 },
      { lat: -7.34781991946693, lng: 157.5384257346893 },
    ],
    [
      { lat: -9.599982191611375, lng: 161.67998172428915 },
      { lat: -9.784312025596435, lng: 161.52939660059053 },
      { lat: -8.91754322676492, lng: 160.78825320866056 },
      { lat: -8.320008640173967, lng: 160.57999718652437 },
      { lat: -8.320008640173967, lng: 160.92002811100494 },
      { lat: -9.120011488484451, lng: 161.28000613835 },
      { lat: -9.599982191611375, lng: 161.67998172428915 },
    ],
    [
      { lat: -9.872937106977005, lng: 160.85222863183796 },
      { lat: -9.895209649294841, lng: 160.46258833235729 },
      { lat: -9.794027194867368, lng: 159.8494474632142 },
      { lat: -9.63997975020527, lng: 159.64000288313517 },
      { lat: -9.242949720906779, lng: 159.70294477766666 },
      { lat: -9.400304457235533, lng: 160.36295617089846 },
      { lat: -9.610162448772812, lng: 160.6885176943372 },
      { lat: -9.872937106977005, lng: 160.85222863183796 },
    ],
    [
      { lat: -10.482719008021135, lng: 162.11902469304087 },
      { lat: -10.82636728276212, lng: 162.39864586817222 },
      { lat: -10.820011081590224, lng: 161.70003218001838 },
      { lat: -10.204751478723125, lng: 161.31979699121476 },
      { lat: -10.446700534713656, lng: 161.917383254238 },
      { lat: -10.482719008021135, lng: 162.11902469304087 },
    ],
  ],
  SC: [
    [
      { lat: -4.665402991759, lng: 55.398930327255 },
      { lat: -4.6500878768572, lng: 55.388136936232 },
      { lat: -4.6097122547039, lng: 55.376869555513 },
      { lat: -4.6070660320726, lng: 55.377240065296 },
      { lat: -4.6041018760388, lng: 55.379058877444 },
      { lat: -4.5519009581485, lng: 55.453651391031 },
      { lat: -4.5514665740908, lng: 55.455743683774 },
      { lat: -4.5520503520578, lng: 55.458626844112 },
      { lat: -4.5565806915511, lng: 55.465188682738 },
      { lat: -4.6932572397122, lng: 55.552972589214 },
      { lat: -4.7008542524543, lng: 55.554562903839 },
      { lat: -4.7622455285767, lng: 55.546253288544 },
      { lat: -4.778310629227, lng: 55.517826386985 },
      { lat: -4.7741921710656, lng: 55.507974079659 },
      { lat: -4.665402991759, lng: 55.398930327255 },
    ],
  ],
  SD: [
    [
      { lat: 9.464285229420625, lng: 33.963392794971185 },
      { lat: 9.484060845715362, lng: 33.82496348090751 },
      { lat: 9.981914637215993, lng: 33.842130853028145 },
      { lat: 10.325262079630193, lng: 33.72195924818311 },
      { lat: 10.720111638406593, lng: 33.20693808456178 },
      { lat: 11.441141267476496, lng: 33.086766479716744 },
      { lat: 12.179338268667093, lng: 33.20693808456178 },
      { lat: 12.248007757149992, lng: 32.743419037302544 },
      { lat: 12.02483191958072, lng: 32.67474954881965 },
      { lat: 11.973329803218519, lng: 32.073891524594785 },
      { lat: 11.68148447716652, lng: 32.31423473428475 },
      { lat: 11.080626452941488, lng: 32.400071594888345 },
      { lat: 10.531270545078826, lng: 31.850715687025517 },
      { lat: 9.810240916008695, lng: 31.35286189552488 },
      { lat: 9.70723668328452, lng: 30.83784073190338 },
      { lat: 10.290927335388687, lng: 29.99663949798855 },
      { lat: 10.084918869940225, lng: 29.61895731133285 },
      { lat: 9.793073543888056, lng: 29.515953078608614 },
      { lat: 9.60423245056029, lng: 29.000931914987174 },
      { lat: 9.398223985111654, lng: 28.966597170745782 },
      { lat: 9.398223985111654, lng: 27.970889587744352 },
      { lat: 9.60423245056029, lng: 27.833550610778783 },
      { lat: 9.638567194801624, lng: 27.112520981708883 },
      { lat: 9.466893473594496, lng: 26.752006167173818 },
      { lat: 9.552730334198088, lng: 26.477328213242515 },
      { lat: 10.136420986302424, lng: 25.962307049621018 },
      { lat: 10.411098940233728, lng: 25.790633328413946 },
      { lat: 10.273759963267992, lng: 25.069603699343986 },
      { lat: 9.810240916008695, lng: 24.794925745412684 },
      { lat: 8.91753756573172, lng: 24.53741516360202 },
      { lat: 8.728696472403897, lng: 24.19406772118765 },
      { lat: 8.619729712933065, lng: 23.886979580860665 },
      { lat: 8.666318874542526, lng: 23.805813429466752 },
      { lat: 8.95428579348902, lng: 23.459012892355986 },
      { lat: 9.26506785729225, lng: 23.394779087017298 },
      { lat: 9.68121816653877, lng: 23.55724979014292 },
      { lat: 10.08925527591532, lng: 23.554304233502194 },
      { lat: 10.71446259199854, lng: 22.97754357269275 },
      { lat: 11.142395127807617, lng: 22.864165480244253 },
      { lat: 11.384610000000123, lng: 22.87622 },
      { lat: 11.67936, lng: 22.50869 },
      { lat: 12.26024, lng: 22.49762 },
      { lat: 12.64605, lng: 22.28801 },
      { lat: 12.588180000000136, lng: 21.93681 },
      { lat: 12.95546, lng: 22.03759 },
      { lat: 13.37232, lng: 22.29658 },
      { lat: 13.78648, lng: 22.18329 },
      { lat: 14.09318, lng: 22.51202 },
      { lat: 14.32682, lng: 22.30351 },
      { lat: 14.944290000000137, lng: 22.56795000000011 },
      { lat: 15.68072, lng: 23.024590000000103 },
      { lat: 15.61084, lng: 23.886890000000108 },
      { lat: 19.580470000000105, lng: 23.837660000000138 },
      { lat: 20, lng: 23.850000000000136 },
      { lat: 20.00304, lng: 25.00000000000011 },
      { lat: 22, lng: 25.00000000000011 },
      { lat: 22, lng: 29.02 },
      { lat: 22, lng: 32.9 },
      { lat: 22, lng: 36.86623 },
      { lat: 21.01885, lng: 37.1887200000001 },
      { lat: 20.83744000000013, lng: 36.96941 },
      { lat: 19.80796, lng: 37.11470000000014 },
      { lat: 18.61409, lng: 37.4817900000001 },
      { lat: 18.36786, lng: 37.86276 },
      { lat: 17.99830739997031, lng: 38.410089959473225 },
      { lat: 17.42754, lng: 37.90400000000011 },
      { lat: 17.263140000000135, lng: 37.16747 },
      { lat: 16.95655, lng: 36.852530000000115 },
      { lat: 16.29186, lng: 36.75389 },
      { lat: 14.82249, lng: 36.32322 },
      { lat: 14.42211, lng: 36.42951 },
      { lat: 13.563330000000121, lng: 36.27022 },
      { lat: 12.57828, lng: 35.86363 },
      { lat: 12.08286, lng: 35.26049 },
      { lat: 11.318960000000118, lng: 34.83163000000013 },
      { lat: 10.910170000000107, lng: 34.73115000000013 },
      { lat: 10.63009, lng: 34.25745 },
      { lat: 9.58358, lng: 33.96162 },
      { lat: 9.464285229420625, lng: 33.963392794971185 },
    ],
  ],
  SE: [
    [
      { lat: 65.72374054632017, lng: 22.183173455501926 },
      { lat: 65.02600535751527, lng: 21.21351687997722 },
      { lat: 64.41358795842429, lng: 21.369631381930958 },
      { lat: 63.60955434839504, lng: 19.77887576669022 },
      { lat: 62.74940013289681, lng: 17.84777916837521 },
      { lat: 61.34116567651097, lng: 17.119554884518124 },
      { lat: 60.63658336042741, lng: 17.83134606290639 },
      { lat: 60.081914374422595, lng: 18.78772179533209 },
      { lat: 58.9537661810587, lng: 17.86922488777634 },
      { lat: 58.71982697207339, lng: 16.829185011470088 },
      { lat: 57.041118069071885, lng: 16.447709588291474 },
      { lat: 56.10430186626866, lng: 15.879785597403783 },
      { lat: 56.200885118222175, lng: 14.666681349352075 },
      { lat: 55.40778107362265, lng: 14.100721062891465 },
      { lat: 55.36173737245058, lng: 12.942910597392057 },
      { lat: 56.30708018658197, lng: 12.625100538797028 },
      { lat: 57.44181712506307, lng: 11.787942335668674 },
      { lat: 58.85614940045936, lng: 11.027368605196866 },
      { lat: 59.43239329694604, lng: 11.468271925511146 },
      { lat: 60.11793284773003, lng: 12.3003658382749 },
      { lat: 61.293571682370136, lng: 12.631146681375183 },
      { lat: 61.80036245385656, lng: 11.992064243221563 },
      { lat: 63.12831757267698, lng: 11.93056928879423 },
      { lat: 64.06621898055833, lng: 12.579935336973932 },
      { lat: 64.04911408146971, lng: 13.571916131248711 },
      { lat: 64.44542064071608, lng: 13.919905226302204 },
      { lat: 64.78702769638151, lng: 13.55568973150909 },
      { lat: 66.19386688909547, lng: 15.108411492583002 },
      { lat: 67.30245555283689, lng: 16.108712192456778 },
      { lat: 68.01393667263139, lng: 16.768878614985482 },
      { lat: 68.01055186631628, lng: 17.729181756265348 },
      { lat: 68.56739126247736, lng: 17.993868442464333 },
      { lat: 68.40719432237258, lng: 19.878559604581255 },
      { lat: 69.0651386583127, lng: 20.025268995857886 },
      { lat: 69.10624726020087, lng: 20.645592889089528 },
      { lat: 68.6168456081807, lng: 21.978534783626117 },
      { lat: 67.93600861273525, lng: 23.53947309743444 },
      { lat: 66.39605093043743, lng: 23.565879754335583 },
      { lat: 66.00692739527962, lng: 23.903378533633802 },
      { lat: 65.72374054632017, lng: 22.183173455501926 },
    ],
  ],
  SG: [
    [
      { lat: 1.3165798855286, lng: 103.64726841923 },
      { lat: 1.4006873582413, lng: 103.67880644598 },
      { lat: 1.4095257149484, lng: 103.68664260707 },
      { lat: 1.4403643813505, lng: 103.76427814142 },
      { lat: 1.43217244462, lng: 103.89776305857 },
      { lat: 1.394916300574, lng: 103.97437992609 },
      { lat: 1.3657087640602, lng: 104.00108405447 },
      { lat: 1.3364054406079, lng: 103.98557052502 },
      { lat: 1.2568934159259, lng: 103.81480223257 },
      { lat: 1.3165798855286, lng: 103.64726841923 },
    ],
  ],
  SH: [
    [
      { lat: -7.873584515554523, lng: -14.383163452148436 },
      { lat: -7.93071504097261, lng: -14.456634521484375 },
      { lat: -8.000757111634481, lng: -14.41680908203125 },
      { lat: -7.99599735084519, lng: -14.343338012695312 },
      { lat: -7.949756788933743, lng: -14.28497314453125 },
      { lat: -7.894669320210427, lng: -14.31793212890625 },
      { lat: -7.873584515554523, lng: -14.383163452148436 },
    ],
    [
      { lat: -15.880771733784396, lng: -5.71014404296875 },
      { lat: -15.91379094700381, lng: -5.766448974609375 },
      { lat: -15.996975282733024, lng: -5.818634033203125 },
      { lat: -16.044493660422905, lng: -5.75958251953125 },
      { lat: -16.029975356905204, lng: -5.684051513671875 },
      { lat: -15.969251008328742, lng: -5.618133544921875 },
      { lat: -15.8847343254536, lng: -5.629119873046875 },
      { lat: -15.880771733784396, lng: -5.71014404296875 },
    ],
    [
      { lat: -37.239075302021824, lng: -12.7935791015625 },
      { lat: -37.42688834526726, lng: -12.7166748046875 },
      { lat: -37.45741810262937, lng: -12.403564453125 },
      { lat: -37.12090636165327, lng: -12.1343994140625 },
      { lat: -37.033254689972345, lng: -12.161865234375 },
      { lat: -36.97183825093164, lng: -12.3980712890625 },
      { lat: -37.239075302021824, lng: -12.7935791015625 },
    ],
    [
      { lat: -40.25752074821523, lng: -10.03875732421875 },
      { lat: -40.31513750307457, lng: -10.040130615234375 },
      { lat: -40.37898227049008, lng: -9.941253662109375 },
      { lat: -40.38944285970334, lng: -9.878082275390625 },
      { lat: -40.33188951824971, lng: -9.849243164062498 },
      { lat: -40.255424668122075, lng: -9.909667968749998 },
      { lat: -40.25752074821523, lng: -10.03875732421875 },
    ],
  ],
  SI: [
    [
      { lat: 46.509306138691215, lng: 13.806475457421527 },
      { lat: 46.43181732846955, lng: 14.63247155117483 },
      { lat: 46.65870270444703, lng: 15.137091912504985 },
      { lat: 46.6836107448117, lng: 16.011663852612656 },
      { lat: 46.85238597267696, lng: 16.202298211337364 },
      { lat: 46.8413272161665, lng: 16.370504998447416 },
      { lat: 46.50375092221983, lng: 16.564808383864857 },
      { lat: 46.23810822202345, lng: 15.768732944408551 },
      { lat: 45.83415355079788, lng: 15.671529575267556 },
      { lat: 45.73178253842768, lng: 15.323953891672403 },
      { lat: 45.45231639259323, lng: 15.327674594797427 },
      { lat: 45.471695054702685, lng: 14.935243767972935 },
      { lat: 45.634940904312714, lng: 14.595109490627804 },
      { lat: 45.46616567644746, lng: 14.411968214585414 },
      { lat: 45.50032379819237, lng: 13.715059848697221 },
      { lat: 45.59101593686462, lng: 13.937630242578306 },
      { lat: 46.01677806251735, lng: 13.698109978905478 },
      { lat: 46.509306138691215, lng: 13.806475457421527 },
    ],
  ],
  SJ: [
    [
      { lat: 79.52065743513272, lng: 7.0751953125 },
      { lat: 69.96043926902489, lng: -10.8984375 },
      { lat: 74.77584300649235, lng: 25.839843749999996 },
      { lat: 80.37170673927024, lng: 35.77148437499999 },
      { lat: 81.14071532658583, lng: 20.9619140625 },
      { lat: 79.52065743513272, lng: 7.0751953125 },
    ],
  ],
  SK: [
    [
      { lat: 49.49622976337764, lng: 18.853144158613617 },
      { lat: 49.435845852244576, lng: 18.909574822676316 },
      { lat: 49.571574001659194, lng: 19.320712517990472 },
      { lat: 49.21712535256923, lng: 19.825022820726872 },
      { lat: 49.43145335549977, lng: 20.41583947111985 },
      { lat: 49.32877228453583, lng: 20.887955356538413 },
      { lat: 49.47010732685409, lng: 21.607808058364213 },
      { lat: 49.085738023467144, lng: 22.558137648211755 },
      { lat: 48.82539215758067, lng: 22.28084191253356 },
      { lat: 48.42226430927179, lng: 22.08560835133485 },
      { lat: 48.31997081155002, lng: 21.872236362401736 },
      { lat: 48.623854071642384, lng: 20.801293979584926 },
      { lat: 48.56285004332181, lng: 20.473562045989866 },
      { lat: 48.32756724709692, lng: 20.239054396249347 },
      { lat: 48.202691148463614, lng: 19.769470656013112 },
      { lat: 48.26661489520866, lng: 19.661363559658497 },
      { lat: 48.11137889260387, lng: 19.17436486173989 },
      { lat: 48.081768296900634, lng: 18.77702477384767 },
      { lat: 47.880953681014404, lng: 18.696512892336926 },
      { lat: 47.758428860050365, lng: 17.857132602620027 },
      { lat: 47.86746613218621, lng: 17.48847293464982 },
      { lat: 48.123497015976305, lng: 16.979666782304037 },
      { lat: 48.47001333270947, lng: 16.879982944413 },
      { lat: 48.5969823268506, lng: 16.960288120194576 },
      { lat: 48.81696889911711, lng: 17.101984897538898 },
      { lat: 48.80001902932537, lng: 17.545006951577108 },
      { lat: 48.90347524677371, lng: 17.88648481616181 },
      { lat: 48.996492824899086, lng: 17.913511590250465 },
      { lat: 49.04398346617531, lng: 18.104972771891852 },
      { lat: 49.271514797556435, lng: 18.170498488037964 },
      { lat: 49.31500051533004, lng: 18.399993523846177 },
      { lat: 49.49501536721878, lng: 18.554971144289482 },
      { lat: 49.49622976337764, lng: 18.853144158613617 },
    ],
  ],
  SL: [
    [
      { lat: 6.785916856305747, lng: -11.438779466182055 },
      { lat: 6.860098374860726, lng: -11.70819454593574 },
      { lat: 7.26294200279203, lng: -12.428098924193819 },
      { lat: 7.798645738145738, lng: -12.949049038128194 },
      { lat: 8.163946438016978, lng: -13.124025437868482 },
      { lat: 8.903048610871508, lng: -13.246550258832515 },
      { lat: 9.342711696810767, lng: -12.71195756677308 },
      { lat: 9.62018830000197, lng: -12.59671912276221 },
      { lat: 9.835834051955956, lng: -12.425928514037565 },
      { lat: 9.858571682164381, lng: -12.150338100625005 },
      { lat: 10.046983954300558, lng: -11.917277390988659 },
      { lat: 10.045872911006285, lng: -11.117481248407328 },
      { lat: 9.688246161330369, lng: -10.8391519840833 },
      { lat: 9.267910061068278, lng: -10.622395188835041 },
      { lat: 8.977178452994194, lng: -10.654770473665891 },
      { lat: 8.715540676300435, lng: -10.494315151399633 },
      { lat: 8.348896389189605, lng: -10.505477260774668 },
      { lat: 8.406205552601293, lng: -10.23009355309128 },
      { lat: 7.939464016141087, lng: -10.69559485517648 },
      { lat: 7.396706447779536, lng: -11.146704270868383 },
      { lat: 7.105845648624737, lng: -11.19980180504828 },
      { lat: 6.785916856305747, lng: -11.438779466182055 },
    ],
  ],
  SM: [
    [
      { lat: 43.940114584113, lng: 12.389927531676 },
      { lat: 43.943544529609, lng: 12.391191565006 },
      { lat: 43.992385150939, lng: 12.435586015584 },
      { lat: 43.999171064035, lng: 12.498997406617 },
      { lat: 43.997639535595, lng: 12.504264094206 },
      { lat: 43.965309973203, lng: 12.520812093247 },
      { lat: 43.899201642241, lng: 12.497444720027 },
      { lat: 43.894078214951, lng: 12.49309494719 },
      { lat: 43.887274048152, lng: 12.432461780912 },
      { lat: 43.889911909267, lng: 12.424000583801 },
      { lat: 43.905077983921, lng: 12.406990924622 },
      { lat: 43.940114584113, lng: 12.389927531676 },
    ],
  ],
  SN: [
    [
      { lat: 13.594958604379853, lng: -16.713728807023468 },
      { lat: 14.373515733289224, lng: -17.126106736712615 },
      { lat: 14.729540513564071, lng: -17.62504269049066 },
      { lat: 14.919477240452863, lng: -17.18517289882223 },
      { lat: 15.621527411354108, lng: -16.700706346085923 },
      { lat: 16.13503611903846, lng: -16.463098110407884 },
      { lat: 16.455662543193384, lng: -16.12069007004193 },
      { lat: 16.369337063049812, lng: -15.62366614425869 },
      { lat: 16.587282416240782, lng: -15.135737270558817 },
      { lat: 16.59826365810281, lng: -14.577347581428981 },
      { lat: 16.304302273010492, lng: -14.099521450242179 },
      { lat: 16.03938304286619, lng: -13.43573767745306 },
      { lat: 15.303691514542946, lng: -12.830658331747516 },
      { lat: 14.616834214735505, lng: -12.170750291380301 },
      { lat: 13.994727484589788, lng: -12.12488745772126 },
      { lat: 13.422075100147394, lng: -11.927716030311615 },
      { lat: 13.141213690641067, lng: -11.55339779300543 },
      { lat: 12.754518947800975, lng: -11.467899135778524 },
      { lat: 12.442987575729418, lng: -11.51394283695059 },
      { lat: 12.386582749882834, lng: -11.65830095055793 },
      { lat: 12.465647691289405, lng: -12.203564825885634 },
      { lat: 12.354440008997285, lng: -12.278599005573438 },
      { lat: 12.332089952031057, lng: -12.499050665730564 },
      { lat: 12.575873521367967, lng: -13.217818162478238 },
      { lat: 12.586182969610194, lng: -13.700476040084325 },
      { lat: 12.628170070847347, lng: -15.548476935274008 },
      { lat: 12.515567124883345, lng: -15.816574266004254 },
      { lat: 12.547761542201187, lng: -16.147716844130585 },
      { lat: 12.384851589401052, lng: -16.677451951554573 },
      { lat: 13.15139394780256, lng: -16.841524624081273 },
      { lat: 13.130284125211332, lng: -15.931295945692211 },
      { lat: 13.270353094938455, lng: -15.691000535534995 },
      { lat: 13.278569647672867, lng: -15.511812506562935 },
      { lat: 13.509511623585238, lng: -15.141163295949466 },
      { lat: 13.298206691943777, lng: -14.712197231494626 },
      { lat: 13.280585028532242, lng: -14.277701788784553 },
      { lat: 13.505041612192002, lng: -13.844963344772408 },
      { lat: 13.79406789800045, lng: -14.046992356817482 },
      { lat: 13.625680243377372, lng: -14.376713833055788 },
      { lat: 13.630356960499784, lng: -14.687030808968487 },
      { lat: 13.876491807505984, lng: -15.08173539881382 },
      { lat: 13.86036876063092, lng: -15.39877031092446 },
      { lat: 13.62358734786956, lng: -15.62459632003994 },
      { lat: 13.594958604379853, lng: -16.713728807023468 },
    ],
  ],
  SO: [
    [
      { lat: 9.451748968946674, lng: 48.938129510296505 },
      { lat: 8.83762624758998, lng: 48.48673587422701 },
      { lat: 8.003, lng: 47.78942 },
      { lat: 7.996876532417388, lng: 46.94832848489796 },
      { lat: 9.18358000000012, lng: 43.67875 },
      { lat: 9.540477403191744, lng: 43.29697513201876 },
      { lat: 10.021940000000143, lng: 42.92812 },
      { lat: 10.57258000000013, lng: 42.55876 },
      { lat: 10.92687856693442, lng: 42.77685184100096 },
      { lat: 11.462039699748857, lng: 43.14530480324214 },
      { lat: 11.27770986576388, lng: 43.470659620951665 },
      { lat: 10.864169216348158, lng: 43.66666832863484 },
      { lat: 10.445538438351605, lng: 44.11780358254282 },
      { lat: 10.442205308468942, lng: 44.614259067570856 },
      { lat: 10.698029486529776, lng: 45.55694054543915 },
      { lat: 10.816549383991173, lng: 46.645401238803004 },
      { lat: 11.12722809492999, lng: 47.525657586462785 },
      { lat: 11.193063869669743, lng: 48.02159630716778 },
      { lat: 11.375481675660126, lng: 48.37878380716927 },
      { lat: 11.41062164961852, lng: 48.94820641459347 },
      { lat: 11.394266058798166, lng: 48.94200524271844 },
      { lat: 10.982327378783452, lng: 48.93849124532261 },
      { lat: 9.973500067581483, lng: 48.93823286316109 },
      { lat: 9.451748968946674, lng: 48.938129510296505 },
    ],
    [
      { lat: 11.5789, lng: 49.72862 },
      { lat: 11.43033, lng: 49.26776 },
      { lat: 11.410617281697963, lng: 48.94820475850974 },
      { lat: 11.394266058798138, lng: 48.94200524271835 },
      { lat: 10.982327378783467, lng: 48.938491245322496 },
      { lat: 9.973500067581512, lng: 48.93823286316103 },
      { lat: 9.451748968946617, lng: 48.93812951029645 },
      { lat: 8.837626247589995, lng: 48.48673587422695 },
      { lat: 8.003, lng: 47.78942 },
      { lat: 5.00162, lng: 44.9636 },
      { lat: 4.95755, lng: 43.66087 },
      { lat: 4.25259, lng: 42.76967 },
      { lat: 4.23413, lng: 42.12861 },
      { lat: 3.918911920483727, lng: 41.85508309264397 },
      { lat: 2.78452, lng: 40.98105 },
      { lat: -0.85829, lng: 40.993 },
      { lat: -1.68325, lng: 41.58513 },
      { lat: -1.44647, lng: 41.81095 },
      { lat: -0.91916, lng: 42.04157 },
      { lat: 0.2922, lng: 43.13597 },
      { lat: 1.05283, lng: 44.06815 },
      { lat: 2.04576, lng: 45.56399 },
      { lat: 2.85529, lng: 46.56476 },
      { lat: 4.2194, lng: 47.74079 },
      { lat: 5.33911, lng: 48.59455 },
      { lat: 6.80466, lng: 49.4527 },
      { lat: 8.08173, lng: 50.07092 },
      { lat: 9.19874, lng: 50.55239 },
      { lat: 10.27972, lng: 50.83418 },
      { lat: 10.6409, lng: 51.04531 },
      { lat: 11.16651, lng: 51.04153 },
      { lat: 11.74815, lng: 51.13387 },
      { lat: 12.02464, lng: 51.1112 },
      { lat: 12.0219, lng: 50.73202 },
      { lat: 11.67957, lng: 50.25878 },
      { lat: 11.5789, lng: 49.72862 },
    ],
  ],
  SR: [
    [
      { lat: 5.973149929219161, lng: -57.14743648947688 },
      { lat: 5.772877915872002, lng: -55.9493184067898 },
      { lat: 5.953125311706059, lng: -55.841779751190415 },
      { lat: 6.025291449401664, lng: -55.033250291551774 },
      { lat: 5.756548163267765, lng: -53.958044603070896 },
      { lat: 4.896755682795586, lng: -54.47863298197923 },
      { lat: 4.212611395683466, lng: -54.399542202356514 },
      { lat: 3.620037746592558, lng: -54.00693050801901 },
      { lat: 3.189779771330421, lng: -54.181726040246275 },
      { lat: 2.732391669115046, lng: -54.2697051662232 },
      { lat: 2.311848863123785, lng: -54.52475419779971 },
      { lat: 2.523748073736613, lng: -55.09758744975514 },
      { lat: 2.421506252447131, lng: -55.569755011606 },
      { lat: 2.510363877773017, lng: -55.973322109589375 },
      { lat: 2.220794989425499, lng: -56.0733418442903 },
      { lat: 2.02199575439866, lng: -55.905600145070885 },
      { lat: 1.817667141116601, lng: -55.99569800477175 },
      { lat: 1.899522609866921, lng: -56.539385748914555 },
      { lat: 2.768926906745406, lng: -57.150097825739905 },
      { lat: 3.333491929534119, lng: -57.2814334784097 },
      { lat: 3.334654649260685, lng: -57.60156897645786 },
      { lat: 4.060863552258382, lng: -58.04469438336068 },
      { lat: 4.57680105226045, lng: -57.86020952007869 },
      { lat: 4.812626451024414, lng: -57.91428890647214 },
      { lat: 5.073566595882227, lng: -57.307245856339506 },
      { lat: 5.973149929219161, lng: -57.14743648947688 },
    ],
  ],
  SS: [
    [
      { lat: 9.464285229420625, lng: 33.963392794971185 },
      { lat: 8.68456, lng: 33.97498 },
      { lat: 8.37916, lng: 33.82550000000015 },
      { lat: 8.35458, lng: 33.29480000000012 },
      { lat: 7.784970000000102, lng: 32.95418 },
      { lat: 7.71334, lng: 33.568290000000104 },
      { lat: 7.22595, lng: 34.0751 },
      { lat: 6.82607, lng: 34.25032 },
      { lat: 6.59422000000012, lng: 34.70702 },
      { lat: 5.506, lng: 35.298007118233095 },
      { lat: 4.847122742082036, lng: 34.62019626785394 },
      { lat: 4.249884947362147, lng: 34.005 },
      { lat: 3.79, lng: 33.3900000000001 },
      { lat: 3.79232, lng: 32.68642 },
      { lat: 3.55827, lng: 31.881450000000143 },
      { lat: 3.7819, lng: 31.24556 },
      { lat: 3.50917, lng: 30.83385 },
      { lat: 4.1737, lng: 29.95349 },
      { lat: 4.600804755060153, lng: 29.71599531425602 },
      { lat: 4.389267279473245, lng: 29.159078403446642 },
      { lat: 4.455077215996994, lng: 28.696677687298802 },
      { lat: 4.287154649264608, lng: 28.428993768027 },
      { lat: 4.408413397637388, lng: 27.979977247842953 },
      { lat: 5.233944403500175, lng: 27.374226108517632 },
      { lat: 5.550953477394614, lng: 27.213409051225256 },
      { lat: 5.946717434101856, lng: 26.465909458123292 },
      { lat: 6.546603298362129, lng: 26.21341840994512 },
      { lat: 6.97931590415817, lng: 25.796647983511264 },
      { lat: 7.500085150579424, lng: 25.124130893664812 },
      { lat: 7.825104071479245, lng: 25.114932488716875 },
      { lat: 8.229187933785454, lng: 24.5673690121522 },
      { lat: 8.619729712933065, lng: 23.886979580860665 },
      { lat: 8.728696472403897, lng: 24.19406772118765 },
      { lat: 8.91753756573172, lng: 24.53741516360202 },
      { lat: 9.810240916008695, lng: 24.794925745412684 },
      { lat: 10.273759963267992, lng: 25.069603699343986 },
      { lat: 10.411098940233728, lng: 25.790633328413946 },
      { lat: 10.136420986302424, lng: 25.962307049621018 },
      { lat: 9.552730334198088, lng: 26.477328213242515 },
      { lat: 9.466893473594496, lng: 26.752006167173818 },
      { lat: 9.638567194801624, lng: 27.112520981708883 },
      { lat: 9.60423245056029, lng: 27.833550610778783 },
      { lat: 9.398223985111654, lng: 27.970889587744352 },
      { lat: 9.398223985111654, lng: 28.966597170745782 },
      { lat: 9.60423245056029, lng: 29.000931914987174 },
      { lat: 9.793073543888056, lng: 29.515953078608614 },
      { lat: 10.084918869940225, lng: 29.61895731133285 },
      { lat: 10.290927335388687, lng: 29.99663949798855 },
      { lat: 9.70723668328452, lng: 30.83784073190338 },
      { lat: 9.810240916008695, lng: 31.35286189552488 },
      { lat: 10.531270545078826, lng: 31.850715687025517 },
      { lat: 11.080626452941488, lng: 32.400071594888345 },
      { lat: 11.68148447716652, lng: 32.31423473428475 },
      { lat: 11.973329803218519, lng: 32.073891524594785 },
      { lat: 12.02483191958072, lng: 32.67474954881965 },
      { lat: 12.248007757149992, lng: 32.743419037302544 },
      { lat: 12.179338268667093, lng: 33.20693808456178 },
      { lat: 11.441141267476496, lng: 33.086766479716744 },
      { lat: 10.720111638406593, lng: 33.20693808456178 },
      { lat: 10.325262079630193, lng: 33.72195924818311 },
      { lat: 9.981914637215993, lng: 33.842130853028145 },
      { lat: 9.484060845715362, lng: 33.82496348090751 },
      { lat: 9.464285229420625, lng: 33.963392794971185 },
    ],
  ],
  ST: [
    [
      { lat: 0.25000335827195, lng: 6.454239754937 },
      { lat: 0.34812466947243, lng: 6.497072693437 },
      { lat: 0.35763037686089, lng: 6.5051682630426 },
      { lat: 0.53498997928901, lng: 6.7802466360046 },
      { lat: 0.17362208875735, lng: 6.7316553111644 },
      { lat: 0.076373974731235, lng: 6.6263677033953 },
      { lat: 0.056105279369805, lng: 6.5877243967828 },
      { lat: 0.073684746199039, lng: 6.5263216801603 },
      { lat: 0.12144375331323, lng: 6.4832218844789 },
      { lat: 0.25000335827195, lng: 6.454239754937 },
    ],
    [
      { lat: 1.4553705292754, lng: 7.311425927555 },
      { lat: 1.6251775074349, lng: 7.3413835244269 },
      { lat: 1.7119697107934, lng: 7.4167576232794 },
      { lat: 1.7119615804073, lng: 7.4204220936731 },
      { lat: 1.7068383751146, lng: 7.4322891640835 },
      { lat: 1.614760044588, lng: 7.461398977896 },
      { lat: 1.5943456762873, lng: 7.4583409721431 },
      { lat: 1.5348414225869, lng: 7.4199555318299 },
      { lat: 1.4553705292754, lng: 7.311425927555 },
    ],
  ],
  SV: [
    [
      { lat: 13.384480495655055, lng: -87.79311113152657 },
      { lat: 13.149016831917137, lng: -87.90411210808952 },
      { lat: 13.163951320849492, lng: -88.48330156121682 },
      { lat: 13.259733588102478, lng: -88.8432279121297 },
      { lat: 13.458532823129303, lng: -89.2567427233293 },
      { lat: 13.520622056527998, lng: -89.81239356154767 },
      { lat: 13.735337632700734, lng: -90.09555457229098 },
      { lat: 13.881969509328924, lng: -90.0646779039966 },
      { lat: 14.134228013561694, lng: -89.72193396682073 },
      { lat: 14.244815578666305, lng: -89.53421932652051 },
      { lat: 14.362586167859488, lng: -89.58734269891654 },
      { lat: 14.424132798719116, lng: -89.35332597528279 },
      { lat: 14.340029405164085, lng: -89.05851192905766 },
      { lat: 14.140506700085169, lng: -88.84307288283284 },
      { lat: 13.980154730683479, lng: -88.541230841816 },
      { lat: 13.845485948130857, lng: -88.50399797234971 },
      { lat: 13.964625962779778, lng: -88.06534257684012 },
      { lat: 13.893312486216983, lng: -87.8595153470216 },
      { lat: 13.785050360565506, lng: -87.72350297722939 },
      { lat: 13.384480495655055, lng: -87.79311113152657 },
    ],
  ],
  SX: [
    [
      { lat: 18.05284634032438, lng: -423.1416893005371 },
      { lat: 18.026077752680155, lng: -423.11954498291016 },
      { lat: 17.998652052118008, lng: -423.035945892334 },
      { lat: 18.05415202087407, lng: -423.00933837890625 },
      { lat: 18.062965110869357, lng: -423.0644416809082 },
      { lat: 18.04778673659716, lng: -423.0846977233887 },
      { lat: 18.05382560164588, lng: -423.11113357543945 },
      { lat: 18.05284634032438, lng: -423.1416893005371 },
    ],
  ],
  SY: [
    [
      { lat: 33.378686428352225, lng: 38.792340529136084 },
      { lat: 32.312937526980775, lng: 36.834062127435544 },
      { lat: 32.709192409794866, lng: 35.71991824722275 },
      { lat: 32.71601369885738, lng: 35.700797967274745 },
      { lat: 32.86812327730851, lng: 35.836396925608625 },
      { lat: 33.2774264592763, lng: 35.82110070165024 },
      { lat: 33.82491242119255, lng: 36.066460402172055 },
      { lat: 34.201788641897174, lng: 36.61175011571589 },
      { lat: 34.59393524834407, lng: 36.4481942075121 },
      { lat: 34.644914048800004, lng: 35.99840254084364 },
      { lat: 35.410009467097325, lng: 35.905023227692226 },
      { lat: 35.82153473565367, lng: 36.149762811026534 },
      { lat: 36.04061697035506, lng: 36.417550083163036 },
      { lat: 36.25969920505646, lng: 36.6853890317318 },
      { lat: 36.81752045343109, lng: 36.7394942563414 },
      { lat: 36.62303620050062, lng: 37.06676110204583 },
      { lat: 36.90121043552777, lng: 38.1677274920242 },
      { lat: 36.71292735447234, lng: 38.6998913917659 },
      { lat: 36.71605377862599, lng: 39.52258019385255 },
      { lat: 37.09127635349729, lng: 40.67325931169569 },
      { lat: 37.074352321921694, lng: 41.21208947120305 },
      { lat: 37.2298725449041, lng: 42.34959109881177 },
      { lat: 36.605853786763575, lng: 41.83706424334096 },
      { lat: 36.35881460219227, lng: 41.289707472505455 },
      { lat: 35.628316555314356, lng: 41.383965285005814 },
      { lat: 34.41937226006212, lng: 41.006158888519934 },
      { lat: 33.378686428352225, lng: 38.792340529136084 },
    ],
  ],
  SZ: [
    [
      { lat: -26.73382008230491, lng: 32.07166548028107 },
      { lat: -27.177927341421277, lng: 31.868060337051077 },
      { lat: -27.285879408478998, lng: 31.282773064913325 },
      { lat: -26.74384531016953, lng: 30.68596194837448 },
      { lat: -26.398078301704608, lng: 30.676608514129637 },
      { lat: -26.022649021104147, lng: 30.949666782359913 },
      { lat: -25.731452325139443, lng: 31.04407962415715 },
      { lat: -25.66019052500895, lng: 31.333157586397906 },
      { lat: -25.84333180105135, lng: 31.837777947728064 },
      { lat: -26.291779880480227, lng: 31.98577924981197 },
      { lat: -26.73382008230491, lng: 32.07166548028107 },
    ],
  ],
  TC: [
    [
      { lat: 21.605088514045914, lng: -72.50839233398438 },
      { lat: 21.62934584655107, lng: -72.3944091796875 },
      { lat: 21.788831884851852, lng: -72.0208740234375 },
      { lat: 21.60764210909204, lng: -71.5869140625 },
      { lat: 21.344385808373335, lng: -71.7681884765625 },
      { lat: 21.25866133371466, lng: -71.75582885742188 },
      { lat: 21.25866133371466, lng: -71.23672485351562 },
      { lat: 21.341827593336323, lng: -71.04995727539062 },
      { lat: 21.55272988873275, lng: -71.08154296875 },
      { lat: 21.992715207965624, lng: -71.98379516601562 },
      { lat: 21.88571280949585, lng: -72.35183715820312 },
      { lat: 21.739091217718574, lng: -72.47817993164061 },
      { lat: 21.605088514045914, lng: -72.50839233398438 },
    ],
  ],
  TD: [
    [
      { lat: 12.859396267137356, lng: 14.495787387762903 },
      { lat: 13.330426947477859, lng: 14.595781284247607 },
      { lat: 13.353448798063766, lng: 13.95447675950561 },
      { lat: 13.996691189016929, lng: 13.956698846094127 },
      { lat: 14.367133693901222, lng: 13.540393507550789 },
      { lat: 15.68437, lng: 13.97217 },
      { lat: 16.627305813050782, lng: 15.247731154041846 },
      { lat: 17.927949937405003, lng: 15.30044111497972 },
      { lat: 19.957180080642384, lng: 15.685740594147774 },
      { lat: 20.387618923417506, lng: 15.903246697664313 },
      { lat: 20.730414537025638, lng: 15.487148064850146 },
      { lat: 21.04845, lng: 15.47106 },
      { lat: 21.30851878507491, lng: 15.096887648181848 },
      { lat: 22.862950000000126, lng: 14.8513 },
      { lat: 23.40972, lng: 15.86085 },
      { lat: 21.49509, lng: 19.84926 },
      { lat: 19.580470000000105, lng: 23.837660000000138 },
      { lat: 15.61084, lng: 23.886890000000108 },
      { lat: 15.68072, lng: 23.024590000000103 },
      { lat: 14.944290000000137, lng: 22.56795000000011 },
      { lat: 14.32682, lng: 22.30351 },
      { lat: 14.09318, lng: 22.51202 },
      { lat: 13.78648, lng: 22.18329 },
      { lat: 13.37232, lng: 22.29658 },
      { lat: 12.95546, lng: 22.03759 },
      { lat: 12.588180000000136, lng: 21.93681 },
      { lat: 12.64605, lng: 22.28801 },
      { lat: 12.26024, lng: 22.49762 },
      { lat: 11.67936, lng: 22.50869 },
      { lat: 11.384610000000123, lng: 22.87622 },
      { lat: 11.142395127807617, lng: 22.864165480244253 },
      { lat: 10.97188873946061, lng: 22.23112918466876 },
      { lat: 10.567055568885962, lng: 21.72382164885954 },
      { lat: 9.47598521569148, lng: 21.00086836109631 },
      { lat: 9.01270600019484, lng: 20.05968549976427 },
      { lat: 9.07484691002577, lng: 19.09400800952608 },
      { lat: 8.982914536978626, lng: 18.81200971850927 },
      { lat: 8.630894680206438, lng: 18.911021762780592 },
      { lat: 8.281303615751881, lng: 18.389554884523303 },
      { lat: 7.890914008002994, lng: 17.964929640380888 },
      { lat: 7.508327541529979, lng: 16.70598839688637 },
      { lat: 7.734773667832939, lng: 16.456184523187403 },
      { lat: 7.754307359239418, lng: 16.290561557691888 },
      { lat: 7.497087917506462, lng: 16.106231723706742 },
      { lat: 7.421924546738012, lng: 15.279460483469164 },
      { lat: 7.692812404811889, lng: 15.43609174974574 },
      { lat: 8.382150173369437, lng: 15.120865512765306 },
      { lat: 8.796104234243444, lng: 14.97999555833769 },
      { lat: 8.96586131432224, lng: 14.544466586981855 },
      { lat: 9.549494940626685, lng: 13.954218377344091 },
      { lat: 10.021378282100045, lng: 14.171466098699113 },
      { lat: 9.920919297724595, lng: 14.62720055508106 },
      { lat: 9.99212942142276, lng: 14.9093538753948 },
      { lat: 9.982336737503543, lng: 15.467872755605244 },
      { lat: 10.891325181517516, lng: 14.923564894275046 },
      { lat: 11.555574042197236, lng: 14.960151808337683 },
      { lat: 12.21905, lng: 14.89336 },
      { lat: 12.859396267137356, lng: 14.495787387762903 },
    ],
  ],
  TG: [
    [
      { lat: 6.142157701029731, lng: 1.865240512712319 },
      { lat: 5.928837388528876, lng: 1.060121697604927 },
      { lat: 6.279978745952149, lng: 0.836931186536333 },
      { lat: 6.914358628767189, lng: 0.570384148774849 },
      { lat: 7.411744289576475, lng: 0.490957472342245 },
      { lat: 8.31246450442383, lng: 0.712029249686878 },
      { lat: 8.677222601756014, lng: 0.461191847342121 },
      { lat: 9.465003973829482, lng: 0.365900506195885 },
      { lat: 10.19121287682718, lng: 0.367579990245389 },
      { lat: 10.706917832883931, lng: -0.049784715159944 },
      { lat: 11.018681748900804, lng: 0.023802524423701 },
      { lat: 10.99733938236426, lng: 0.899563022474069 },
      { lat: 10.470808213742359, lng: 0.772335646171484 },
      { lat: 10.175606594275024, lng: 1.077795037448738 },
      { lat: 9.825395412633, lng: 1.425060662450136 },
      { lat: 9.334624335157088, lng: 1.46304284018467 },
      { lat: 9.12859039960938, lng: 1.664477573258381 },
      { lat: 6.832038072126237, lng: 1.618950636409238 },
      { lat: 6.142157701029731, lng: 1.865240512712319 },
    ],
  ],
  TH: [
    [
      { lat: 12.186594956913282, lng: 102.5849324890267 },
      { lat: 12.645740057826572, lng: 101.68715783081996 },
      { lat: 12.627084865769206, lng: 100.83180952352487 },
      { lat: 13.412721665902566, lng: 100.9784672383692 },
      { lat: 13.406856390837433, lng: 100.0977974792511 },
      { lat: 12.307001044153354, lng: 100.01873253784456 },
      { lat: 10.846366685423547, lng: 99.47892052612363 },
      { lat: 9.963061428258554, lng: 99.15377241414316 },
      { lat: 9.239255479362427, lng: 99.22239871622676 },
      { lat: 9.20786204674512, lng: 99.87383182169813 },
      { lat: 8.295152899606052, lng: 100.27964684448622 },
      { lat: 7.429572658717177, lng: 100.45927412313276 },
      { lat: 6.856868597842478, lng: 101.01732791545273 },
      { lat: 6.74062246340192, lng: 101.62307905477806 },
      { lat: 6.221636053894628, lng: 102.14118696493638 },
      { lat: 5.810808417174242, lng: 101.81428185425798 },
      { lat: 5.691384182147715, lng: 101.15421878459387 },
      { lat: 6.204867051615921, lng: 101.07551557821336 },
      { lat: 6.642824815289543, lng: 100.25959638875696 },
      { lat: 6.46448944745029, lng: 100.0857568705271 },
      { lat: 6.848212795433597, lng: 99.69069054565574 },
      { lat: 7.34345388430276, lng: 99.51964155476963 },
      { lat: 7.907993068875327, lng: 98.9882528015123 },
      { lat: 8.382305202666288, lng: 98.503786248776 },
      { lat: 7.794511623562386, lng: 98.339661899817 },
      { lat: 8.350007432483878, lng: 98.15000939330581 },
      { lat: 8.973922837759801, lng: 98.25915001830624 },
      { lat: 9.932959906448545, lng: 98.55355065307305 },
      { lat: 10.960545762572437, lng: 99.03812055867398 },
      { lat: 11.892762762901697, lng: 99.58728600463972 },
      { lat: 12.80474843998867, lng: 99.19635379435167 },
      { lat: 13.269293728076464, lng: 99.21201175333609 },
      { lat: 13.827502549693278, lng: 99.09775516153876 },
      { lat: 14.622027696180835, lng: 98.43081912637987 },
      { lat: 15.12370250087035, lng: 98.1920740091914 },
      { lat: 15.308497422746084, lng: 98.53737592976572 },
      { lat: 16.177824204976115, lng: 98.90334842325676 },
      { lat: 16.83783559820793, lng: 98.49376102091135 },
      { lat: 17.567946071843664, lng: 97.85912275593486 },
      { lat: 18.445437730375815, lng: 97.37589643757354 },
      { lat: 18.627080389881755, lng: 97.7977828308044 },
      { lat: 19.708203029860044, lng: 98.25372399291561 },
      { lat: 19.752980658440947, lng: 98.95967573445488 },
      { lat: 20.186597601802063, lng: 99.54330936075931 },
      { lat: 20.417849636308187, lng: 100.11598758341783 },
      { lat: 20.109237982661128, lng: 100.54888105672688 },
      { lat: 19.508344427971224, lng: 100.60629357300316 },
      { lat: 19.462584947176765, lng: 101.2820146016517 },
      { lat: 18.408928330961615, lng: 101.03593143107777 },
      { lat: 17.51249725999449, lng: 101.05954756063517 },
      { lat: 18.109101670804165, lng: 102.11359175009248 },
      { lat: 17.932781683824288, lng: 102.41300499879162 },
      { lat: 17.9616946476916, lng: 102.9987056823877 },
      { lat: 18.309632066312773, lng: 103.20019209189373 },
      { lat: 18.24095408779688, lng: 103.95647667848529 },
      { lat: 17.42885895433008, lng: 104.7169470560925 },
      { lat: 16.44186493577145, lng: 104.7793205098688 },
      { lat: 15.570316066952858, lng: 105.58903852745016 },
      { lat: 14.723933620660416, lng: 105.54433841351769 },
      { lat: 14.273211778210694, lng: 105.21877689007887 },
      { lat: 14.416743068901367, lng: 104.28141808473661 },
      { lat: 14.225721136934467, lng: 102.98842207236163 },
      { lat: 13.394247341358223, lng: 102.348099399833 },
      { lat: 12.186594956913282, lng: 102.5849324890267 },
    ],
  ],
  TJ: [
    [
      { lat: 40.24436554621823, lng: 71.01419803252017 },
      { lat: 39.93575389257117, lng: 70.64801883329997 },
      { lat: 40.10321137141298, lng: 69.55960981636852 },
      { lat: 39.5266832545487, lng: 69.46488691597753 },
      { lat: 39.6041979029865, lng: 70.54916181832562 },
      { lat: 39.27946320246437, lng: 71.784693637992 },
      { lat: 39.4312368841056, lng: 73.6753792662548 },
      { lat: 38.50581533462274, lng: 73.92885216664644 },
      { lat: 38.60650686294345, lng: 74.25751427602273 },
      { lat: 38.3788463404816, lng: 74.86481570831681 },
      { lat: 37.9900070257014, lng: 74.8299857929521 },
      { lat: 37.419990139305895, lng: 74.98000247589542 },
      { lat: 37.4215662704908, lng: 73.9486959166465 },
      { lat: 37.495256862939, lng: 73.26005577992501 },
      { lat: 37.047558091778356, lng: 72.63688968291729 },
      { lat: 36.948287665345674, lng: 72.1930408059624 },
      { lat: 36.73817129164692, lng: 71.8446382994506 },
      { lat: 37.06564484308051, lng: 71.44869347523024 },
      { lat: 37.905774441065645, lng: 71.54191775908478 },
      { lat: 37.95326508234188, lng: 71.23940392444817 },
      { lat: 38.258905341132156, lng: 71.34813113799026 },
      { lat: 38.486281643216415, lng: 70.80682050973289 },
      { lat: 38.13839590102752, lng: 70.3763041523093 },
      { lat: 37.735164699854025, lng: 70.27057417184014 },
      { lat: 37.58822276463209, lng: 70.11657840361033 },
      { lat: 37.60899669041341, lng: 69.51878543485796 },
      { lat: 37.15114350030743, lng: 69.19627282092438 },
      { lat: 37.344335842430596, lng: 68.85944583524594 },
      { lat: 37.0231151393043, lng: 68.13556237170138 },
      { lat: 37.14499400486468, lng: 67.82999962755952 },
      { lat: 38.157025254868735, lng: 68.39203250516596 },
      { lat: 38.901553453113905, lng: 68.17602501818592 },
      { lat: 39.140143541005486, lng: 67.44221967964131 },
      { lat: 39.58047842056453, lng: 67.70142866401736 },
      { lat: 39.53345286717894, lng: 68.53641645698941 },
      { lat: 40.08615814875666, lng: 69.0116329283455 },
      { lat: 40.72782440852485, lng: 69.32949466337283 },
      { lat: 40.96021332454141, lng: 70.66662234892505 },
      { lat: 40.49649485937029, lng: 70.45815962105962 },
      { lat: 40.21852733007229, lng: 70.60140669137269 },
      { lat: 40.24436554621823, lng: 71.01419803252017 },
    ],
  ],
  TL: [
    [
      { lat: -8.892790215697083, lng: 124.96868248911622 },
      { lat: -8.65688730228468, lng: 125.08624637258026 },
      { lat: -8.432094821815035, lng: 125.94707238169826 },
      { lat: -8.398246758663852, lng: 126.64470421763855 },
      { lat: -8.273344821814398, lng: 126.95724328013982 },
      { lat: -8.397316582882603, lng: 127.33592817597464 },
      { lat: -8.668256117388893, lng: 126.96799197805655 },
      { lat: -9.106007175333351, lng: 125.9258850444586 },
      { lat: -9.393173109579294, lng: 125.08852013560109 },
      { lat: -9.089987481322872, lng: 125.07001997284061 },
      { lat: -8.892790215697083, lng: 124.96868248911622 },
    ],
  ],
  TM: [
    [
      { lat: 35.650072333309225, lng: 61.21081709172574 },
      { lat: 36.49159719496624, lng: 61.123070509694145 },
      { lat: 36.52738312432837, lng: 60.37763797388387 },
      { lat: 37.412987982730336, lng: 59.23476199731681 },
      { lat: 37.522309475243794, lng: 58.4361544126782 },
      { lat: 38.02922943781094, lng: 57.33043379092898 },
      { lat: 38.121394354803485, lng: 56.61936608259282 },
      { lat: 37.93512665460742, lng: 56.18037479027333 },
      { lat: 37.96411713312317, lng: 55.51157840355191 },
      { lat: 37.392420762678185, lng: 54.800303989486565 },
      { lat: 37.19891836196126, lng: 53.92159793479556 },
      { lat: 37.90613617609169, lng: 53.73551110211252 },
      { lat: 38.95209300389536, lng: 53.880928582581845 },
      { lat: 39.29057363540713, lng: 53.101027866432894 },
      { lat: 39.97528636327445, lng: 53.35780805849123 },
      { lat: 40.03362905533197, lng: 52.69397260926982 },
      { lat: 40.87652334244473, lng: 52.915251092343624 },
      { lat: 40.63103445084218, lng: 53.858139275941134 },
      { lat: 40.95101491959346, lng: 54.73684533063215 },
      { lat: 41.55121084244742, lng: 54.008310988181314 },
      { lat: 42.12319143327003, lng: 53.72171349469059 },
      { lat: 41.86811656347733, lng: 52.916749708880076 },
      { lat: 41.13537059179471, lng: 52.81468875510361 },
      { lat: 41.78331553808637, lng: 52.50245975119615 },
      { lat: 42.11603424739759, lng: 52.944293247291654 },
      { lat: 42.32410940202083, lng: 54.07941775901495 },
      { lat: 42.043971462566574, lng: 54.75534549339263 },
      { lat: 41.25985911718584, lng: 55.45525109235377 },
      { lat: 41.30864166926936, lng: 55.96819135928291 },
      { lat: 41.32231008561057, lng: 57.0963912290791 },
      { lat: 41.826026109375604, lng: 56.932215203687804 },
      { lat: 42.17055288346552, lng: 57.78652998233708 },
      { lat: 42.75155101172305, lng: 58.62901085799146 },
      { lat: 42.22308197689021, lng: 59.97642215356978 },
      { lat: 41.425146185871405, lng: 60.08334069198167 },
      { lat: 41.22032664648255, lng: 60.46595299667069 },
      { lat: 41.266370347654615, lng: 61.54717898951356 },
      { lat: 41.084856879229406, lng: 61.88271406438469 },
      { lat: 40.05388621679039, lng: 62.374260288345 },
      { lat: 39.36325653742564, lng: 63.51801476426103 },
      { lat: 38.892406724598246, lng: 64.17022301621677 },
      { lat: 38.40269501398429, lng: 65.2159989765074 },
      { lat: 37.97468496352687, lng: 66.54615034370022 },
      { lat: 37.36278432875879, lng: 66.51860680528867 },
      { lat: 37.39379018813392, lng: 66.21738488145932 },
      { lat: 37.66116404881207, lng: 65.74563073106681 },
      { lat: 37.30521678318564, lng: 65.58894778835784 },
      { lat: 37.111817735333304, lng: 64.7461051776774 },
      { lat: 36.31207326918427, lng: 64.5464791197339 },
      { lat: 36.0079574651466, lng: 63.9828959491587 },
      { lat: 35.857165635718914, lng: 63.19353844590035 },
      { lat: 35.40404083916762, lng: 62.9846623065766 },
      { lat: 35.270663967422294, lng: 62.230651483005886 },
      { lat: 35.650072333309225, lng: 61.21081709172574 },
    ],
  ],
  TN: [
    [
      { lat: 30.307556057246188, lng: 9.482139926805274 },
      { lat: 32.10269196220129, lng: 9.055602654668148 },
      { lat: 32.50628489840082, lng: 8.439102817426118 },
      { lat: 32.74833730725595, lng: 8.430472853233368 },
      { lat: 33.34411489514896, lng: 7.612641635782182 },
      { lat: 34.09737641045146, lng: 7.524481642292244 },
      { lat: 34.65514598239379, lng: 8.140981479534304 },
      { lat: 35.47987600355594, lng: 8.376367628623768 },
      { lat: 36.433176988260286, lng: 8.217824334352315 },
      { lat: 36.94642731378316, lng: 8.420964389691676 },
      { lat: 37.349994411766545, lng: 9.509993523810607 },
      { lat: 37.230001735984814, lng: 10.210002475636317 },
      { lat: 36.724037787415085, lng: 10.18065026209453 },
      { lat: 37.09210317641396, lng: 11.028867221733348 },
      { lat: 36.899996039368915, lng: 11.100025668999251 },
      { lat: 36.410000108377375, lng: 10.600004510143094 },
      { lat: 35.94744436293281, lng: 10.593286573945136 },
      { lat: 35.698984076473494, lng: 10.939518670300687 },
      { lat: 34.83350718844919, lng: 10.807847120821009 },
      { lat: 34.3307730168977, lng: 10.149592726287125 },
      { lat: 33.78574168551532, lng: 10.339658644256616 },
      { lat: 33.76874013929128, lng: 10.856836378633687 },
      { lat: 33.293342800422195, lng: 11.108500603895122 },
      { lat: 33.13699575452314, lng: 11.48878746913101 },
      { lat: 32.368903103152874, lng: 11.432253452203696 },
      { lat: 32.081814683555365, lng: 10.944789666394456 },
      { lat: 31.761420803345754, lng: 10.636901482799487 },
      { lat: 31.376069647745254, lng: 9.950225050505082 },
      { lat: 30.9618313664936, lng: 10.056575148161755 },
      { lat: 30.539324856075243, lng: 9.970017124072854 },
      { lat: 30.307556057246188, lng: 9.482139926805274 },
    ],
  ],
  TO: [
    [
      { lat: -21.150950213369, lng: -175.3754354114 },
      { lat: -21.087218894429, lng: -175.36327791534 },
      { lat: -21.039324555771, lng: -175.32892916571 },
      { lat: -21.062685822199, lng: -175.135098912 },
      { lat: -21.084101175613, lng: -175.08773526603 },
      { lat: -21.302137673688, lng: -174.87663394338 },
      { lat: -21.337366466921, lng: -174.86332209583 },
      { lat: -21.360222906542, lng: -174.86274596149 },
      { lat: -21.443036797566, lng: -174.88344797045 },
      { lat: -21.473728267037, lng: -174.94256277761 },
      { lat: -21.47389428897, lng: -174.94402144094 },
      { lat: -21.471502495932, lng: -174.95376877832 },
      { lat: -21.237250847579, lng: -175.30291371122 },
      { lat: -21.150950213369, lng: -175.3754354114 },
    ],
    [
      { lat: -18.638042050321, lng: -174.103096191 },
      { lat: -18.606697657499, lng: -174.09698378008 },
      { lat: -18.592714140562, lng: -174.08790198841 },
      { lat: -18.544174779982, lng: -174.0144685568 },
      { lat: -18.53337843556, lng: -173.94640559477 },
      { lat: -18.54588892723, lng: -173.92200514727 },
      { lat: -18.625807145932, lng: -173.90045779343 },
      { lat: -18.646942964254, lng: -173.90460031745 },
      { lat: -18.672849145066, lng: -173.91684021586 },
      { lat: -18.719886569911, lng: -173.9649928886 },
      { lat: -18.726413090122, lng: -173.9819753089 },
      { lat: -18.66823521367, lng: -174.08052507331 },
      { lat: -18.638042050321, lng: -174.103096191 },
    ],
  ],
  TR: [
    [
      { lat: 41.335358384764305, lng: 36.91312706884215 },
      { lat: 40.94858612727572, lng: 38.34766482926452 },
      { lat: 41.102762763018575, lng: 39.51260664242025 },
      { lat: 41.01367259374734, lng: 40.373432651538245 },
      { lat: 41.53565623632761, lng: 41.554084100110714 },
      { lat: 41.58317271581993, lng: 42.619548781104555 },
      { lat: 41.09214325618257, lng: 43.58274580259271 },
      { lat: 40.74020091405882, lng: 43.7526579119685 },
      { lat: 40.25356395116617, lng: 43.65643639504096 },
      { lat: 40.00500031184231, lng: 44.400008579288766 },
      { lat: 39.713002631177034, lng: 44.793989699082005 },
      { lat: 39.428136298168056, lng: 44.109225294782355 },
      { lat: 38.28128123631453, lng: 44.4214026222576 },
      { lat: 37.97158437758935, lng: 44.22575564960053 },
      { lat: 37.17044464776845, lng: 44.77269900897775 },
      { lat: 37.00151439060635, lng: 44.29345177590286 },
      { lat: 37.256227525372935, lng: 43.94225874204736 },
      { lat: 37.38526357680581, lng: 42.77912560402186 },
      { lat: 37.22987254490411, lng: 42.34959109881177 },
      { lat: 37.07435232192173, lng: 41.21208947120303 },
      { lat: 37.09127635349736, lng: 40.673259311695716 },
      { lat: 36.71605377862602, lng: 39.52258019385252 },
      { lat: 36.71292735447233, lng: 38.69989139176593 },
      { lat: 36.90121043552779, lng: 38.16772749202416 },
      { lat: 36.62303620050062, lng: 37.06676110204583 },
      { lat: 36.817520453431115, lng: 36.739494256341374 },
      { lat: 36.259699205056506, lng: 36.68538903173183 },
      { lat: 36.0406169703551, lng: 36.41755008316309 },
      { lat: 35.82153473565367, lng: 36.14976281102659 },
      { lat: 36.274995429014915, lng: 35.782084995269855 },
      { lat: 36.650605577128374, lng: 36.160821567537056 },
      { lat: 36.56544281671134, lng: 35.55093631362834 },
      { lat: 36.795532131490916, lng: 34.714553256984374 },
      { lat: 36.21996002862397, lng: 34.02689497247647 },
      { lat: 36.1075637883892, lng: 32.5091581560641 },
      { lat: 36.64427521417261, lng: 31.699595167779567 },
      { lat: 36.677864895162315, lng: 30.62162479017107 },
      { lat: 36.26298065850698, lng: 30.39109622571712 },
      { lat: 36.144357408181, lng: 29.699975620245567 },
      { lat: 36.67683136651644, lng: 28.73290286633539 },
      { lat: 36.658822129862756, lng: 27.64118655773737 },
      { lat: 37.65336090753601, lng: 27.048767937943296 },
      { lat: 38.208133246405396, lng: 26.318218214633045 },
      { lat: 38.98576019953356, lng: 26.804700148228733 },
      { lat: 39.463612168936464, lng: 26.17078535330438 },
      { lat: 40.42001373957831, lng: 27.280019972449395 },
      { lat: 40.46001129817221, lng: 28.819977654747216 },
      { lat: 41.21999074967269, lng: 29.24000369641558 },
      { lat: 41.08762156835706, lng: 31.145933872204434 },
      { lat: 41.73626414648464, lng: 32.34797936374579 },
      { lat: 42.018960069337304, lng: 33.51328291192752 },
      { lat: 42.04022492122544, lng: 35.16770389175187 },
      { lat: 41.335358384764305, lng: 36.91312706884215 },
    ],
    [
      { lat: 40.690565700842455, lng: 27.192376743282406 },
      { lat: 40.15199392349649, lng: 26.35800906749779 },
      { lat: 40.61775360774317, lng: 26.04335127127254 },
      { lat: 40.82412344010075, lng: 26.056942172965336 },
      { lat: 40.93626129817417, lng: 26.29460208507569 },
      { lat: 41.56211456966102, lng: 26.604195590936282 },
      { lat: 41.82690460872456, lng: 26.117041863720825 },
      { lat: 42.14148489030131, lng: 27.135739373490505 },
      { lat: 42.00735871028777, lng: 27.996720411905414 },
      { lat: 41.622886054036286, lng: 28.115524529744444 },
      { lat: 41.29993419042819, lng: 28.988442824018787 },
      { lat: 41.05496206314854, lng: 28.80643842948675 },
      { lat: 40.99982330989312, lng: 27.61901736828412 },
      { lat: 40.690565700842455, lng: 27.192376743282406 },
    ],
  ],
  TT: [
    [
      { lat: 10.76, lng: -61.68 },
      { lat: 10.89, lng: -61.105 },
      { lat: 10.855, lng: -60.895 },
      { lat: 10.11, lng: -60.935 },
      { lat: 10, lng: -61.77 },
      { lat: 10.09, lng: -61.95 },
      { lat: 10.365, lng: -61.66 },
      { lat: 10.76, lng: -61.68 },
    ],
  ],
  TV: [
    [
      { lat: -5.583184111989688, lng: 176.00097656249997 },
      { lat: -6.839169626342808, lng: 176.0284423828125 },
      { lat: -9.23767076398516, lng: 178.1817626953125 },
      { lat: -9.573667720630601, lng: 180.0164794921875 },
      { lat: -8.15168052401392, lng: 180.032958984375 },
      { lat: -5.741708497977726, lng: 177.56103515624997 },
      { lat: -5.583184111989688, lng: 176.00097656249997 },
    ],
  ],
  TW: [
    [
      { lat: 24.3942735865194, lng: 121.77781782438993 },
      { lat: 22.790857245367167, lng: 121.17563235889274 },
      { lat: 21.970571397382113, lng: 120.74707970589621 },
      { lat: 22.81486094816674, lng: 120.22008344938367 },
      { lat: 23.556262722258236, lng: 120.1061885926124 },
      { lat: 24.538450832613737, lng: 120.69467980355225 },
      { lat: 25.295458889257386, lng: 121.49504438688876 },
      { lat: 24.997595933527034, lng: 121.95124393116144 },
      { lat: 24.3942735865194, lng: 121.77781782438993 },
    ],
  ],
  TZ: [
    [
      { lat: -0.95, lng: 33.9037111971046 },
      { lat: -1.05982, lng: 34.07262 },
      { lat: -3.09699, lng: 37.69869 },
      { lat: -3.67712, lng: 37.7669 },
      { lat: -4.67677, lng: 39.20222 },
      { lat: -5.90895, lng: 38.74054 },
      { lat: -6.47566, lng: 38.79977 },
      { lat: -6.839999999999861, lng: 39.44 },
      { lat: -7.1, lng: 39.470000000000134 },
      { lat: -7.7039, lng: 39.19469 },
      { lat: -8.00781, lng: 39.25203 },
      { lat: -8.48551, lng: 39.18652 },
      { lat: -9.112369999999883, lng: 39.53574 },
      { lat: -10.0984, lng: 39.9496 },
      { lat: -10.317099999999868, lng: 40.31659 },
      { lat: -10.89688, lng: 39.521 },
      { lat: -11.285202325081627, lng: 38.42755659358778 },
      { lat: -11.26879, lng: 37.82764 },
      { lat: -11.56876, lng: 37.47129 },
      { lat: -11.594537448780784, lng: 36.77515099462289 },
      { lat: -11.720938002166747, lng: 36.514081658684404 },
      { lat: -11.439146416879169, lng: 35.31239790216915 },
      { lat: -11.520020033415847, lng: 34.559989047999466 },
      { lat: -10.16, lng: 34.28 },
      { lat: -9.693673841980285, lng: 33.940837724096525 },
      { lat: -9.41715, lng: 33.73972 },
      { lat: -9.230599053589003, lng: 32.75937544122138 },
      { lat: -8.930358981973257, lng: 32.19186486179194 },
      { lat: -8.762048841998647, lng: 31.556348097466635 },
      { lat: -8.594578747317314, lng: 31.15775133695007 },
      { lat: -8.34, lng: 30.74 },
      { lat: -7.08, lng: 30.2 },
      { lat: -6.52, lng: 29.62 },
      { lat: -5.939998874539299, lng: 29.419992710088305 },
      { lat: -5.419978936386258, lng: 29.51998660657307 },
      { lat: -4.499983412294114, lng: 29.33999759290037 },
      { lat: -4.452389418153302, lng: 29.753512404099865 },
      { lat: -4.09012, lng: 30.11632 },
      { lat: -3.56858, lng: 30.50554 },
      { lat: -3.35931, lng: 30.75224 },
      { lat: -3.03431, lng: 30.74301 },
      { lat: -2.80762, lng: 30.52766 },
      { lat: -2.41383, lng: 30.46967 },
      { lat: -2.287250257988376, lng: 30.758308953583136 },
      { lat: -1.698914076345375, lng: 30.81613488131785 },
      { lat: -1.134659112150416, lng: 30.4191048520193 },
      { lat: -1.01455, lng: 30.769860000000108 },
      { lat: -1.02736, lng: 31.86617 },
      { lat: -0.95, lng: 33.9037111971046 },
    ],
  ],
  UA: [
    [
      { lat: 52.101677964885454, lng: 31.785998162571587 },
      { lat: 52.04235342061438, lng: 30.927549269338982 },
      { lat: 51.822806098022376, lng: 30.619454380014844 },
      { lat: 51.31950348571566, lng: 30.555117221811457 },
      { lat: 51.41613841410147, lng: 30.157363722460897 },
      { lat: 51.368234361366895, lng: 29.254938185347925 },
      { lat: 51.602044379271476, lng: 28.99283532076353 },
      { lat: 51.42771393493484, lng: 28.61761274589225 },
      { lat: 51.57222707783907, lng: 28.24161502453657 },
      { lat: 51.59230337178447, lng: 27.454066196408434 },
      { lat: 51.83228872334793, lng: 26.337958611768556 },
      { lat: 51.91065603291855, lng: 25.327787713327005 },
      { lat: 51.888461005249184, lng: 24.553106316839518 },
      { lat: 51.61744395609446, lng: 24.00507775238421 },
      { lat: 51.57845408793023, lng: 23.52707075368437 },
      { lat: 50.70540660257518, lng: 24.029985792748903 },
      { lat: 50.42488108987875, lng: 23.922757195743262 },
      { lat: 50.30850576435745, lng: 23.426508416444392 },
      { lat: 49.47677358661974, lng: 22.518450148211603 },
      { lat: 49.02739533140962, lng: 22.776418898212626 },
      { lat: 49.085738023467144, lng: 22.558137648211755 },
      { lat: 48.82539215758067, lng: 22.28084191253356 },
      { lat: 48.42226430927179, lng: 22.08560835133485 },
      { lat: 48.15023956968735, lng: 22.640819939878753 },
      { lat: 47.88219391538941, lng: 22.710531447040495 },
      { lat: 48.09634105080695, lng: 23.142236362406802 },
      { lat: 47.985598456405455, lng: 23.76095828623741 },
      { lat: 47.98187775328042, lng: 24.40205610525038 },
      { lat: 47.73752574318831, lng: 24.866317172960578 },
      { lat: 47.89105642352747, lng: 25.20774336111299 },
      { lat: 47.987148749374214, lng: 25.9459411964024 },
      { lat: 48.22088125263035, lng: 26.19745039236693 },
      { lat: 48.22072622333347, lng: 26.619336785597795 },
      { lat: 48.368210761094495, lng: 26.857823520624805 },
      { lat: 48.467119452501116, lng: 27.522537469195154 },
      { lat: 48.15556224221342, lng: 28.259546746541844 },
      { lat: 48.1181485052341, lng: 28.670891147585163 },
      { lat: 47.84909516050646, lng: 29.12269819511303 },
      { lat: 47.51022695575249, lng: 29.05086795422733 },
      { lat: 47.34664520933257, lng: 29.41513512545274 },
      { lat: 46.928582872091326, lng: 29.559674106573112 },
      { lat: 46.67436066343146, lng: 29.908851759569302 },
      { lat: 46.52532583270169, lng: 29.838210076626297 },
      { lat: 46.42393667254503, lng: 30.02465864433537 },
      { lat: 46.34998769793536, lng: 29.75997195813639 },
      { lat: 46.3792623968287, lng: 29.170653924279886 },
      { lat: 46.517677720722496, lng: 29.072106967899295 },
      { lat: 46.43788930926383, lng: 28.862972446414062 },
      { lat: 46.2588304713725, lng: 28.93371748222162 },
      { lat: 45.93998688413164, lng: 28.659987420371575 },
      { lat: 45.5969070501459, lng: 28.485269402792767 },
      { lat: 45.48828318946837, lng: 28.233553501099042 },
      { lat: 45.304030870131704, lng: 28.679779493939378 },
      { lat: 45.46492544207245, lng: 29.149724969201653 },
      { lat: 45.293308010431126, lng: 29.603289015427432 },
      { lat: 45.90529985724799, lng: 30.311279296875 },
      { lat: 46.27863122156088, lng: 30.7177734375 },
      { lat: 46.53619267489863, lng: 30.816650390624996 },
      { lat: 46.51351558059737, lng: 31.5087890625 },
      { lat: 46.195042108660154, lng: 31.739501953125 },
      { lat: 45.98932892799953, lng: 32.54150390625 },
      { lat: 46.080598456397844, lng: 33.29856733575471 },
      { lat: 45.85156850848024, lng: 33.58816206231839 },
      { lat: 45.75985868785574, lng: 33.035888671875 },
      { lat: 45.519185695978905, lng: 32.630804477679135 },
      { lat: 45.32746613217608, lng: 32.4541744321055 },
      { lat: 45.03477081967489, lng: 33.54692426934946 },
      { lat: 44.56487702084489, lng: 33.326420932760044 },
      { lat: 44.36147858334407, lng: 33.882511020652885 },
      { lat: 44.939996242851606, lng: 35.23999922052812 },
      { lat: 45.113215643893966, lng: 36.33471276219916 },
      { lat: 45.46998973243706, lng: 36.52999799983016 },
      { lat: 45.40999339454619, lng: 35.51000857925317 },
      { lat: 45.65121898048466, lng: 35.020787794745985 },
      { lat: 46.27319651954964, lng: 34.96234174982388 },
      { lat: 46.64596446388707, lng: 35.82368452326483 },
      { lat: 46.698700263040934, lng: 36.75985477066439 },
      { lat: 47.022220567404204, lng: 37.42513715998999 },
      { lat: 47.10218984637588, lng: 38.22353803889942 },
      { lat: 47.546400458356814, lng: 38.25511233902975 },
      { lat: 47.825608222029814, lng: 38.7705847511412 },
      { lat: 47.89893707945198, lng: 39.738277622238826 },
      { lat: 48.23240509703143, lng: 39.89563235856758 },
      { lat: 48.78381846780187, lng: 39.67466393408753 },
      { lat: 49.307429917999286, lng: 40.08078901546935 },
      { lat: 49.6010554062817, lng: 40.06905846533911 },
      { lat: 49.92646190042363, lng: 38.59498823421342 },
      { lat: 49.91566152607463, lng: 38.010631137856905 },
      { lat: 50.38395335550359, lng: 37.39345950699507 },
      { lat: 50.225590928745135, lng: 36.62616784032534 },
      { lat: 50.577197374059054, lng: 35.35611616388795 },
      { lat: 50.77395539001034, lng: 35.37792361831512 },
      { lat: 51.20757233337145, lng: 35.02218305841788 },
      { lat: 51.25599315042895, lng: 34.22481570815427 },
      { lat: 51.56641347920623, lng: 34.14197838719039 },
      { lat: 51.76888174092579, lng: 34.39173058445701 },
      { lat: 52.335074571331695, lng: 33.7526998227357 },
      { lat: 52.23846548116205, lng: 32.71576053236697 },
      { lat: 52.28869497334975, lng: 32.41205813978763 },
      { lat: 52.06126699483322, lng: 32.15941206231267 },
      { lat: 52.101677964885454, lng: 31.785998162571587 },
    ],
  ],
  UG: [
    [
      { lat: -1.02736, lng: 31.86617 },
      { lat: -1.01455, lng: 30.769860000000108 },
      { lat: -1.134659112150416, lng: 30.4191048520193 },
      { lat: -1.443322442229771, lng: 29.821518588996124 },
      { lat: -1.341313164885605, lng: 29.579466180141022 },
      { lat: -0.587405694179381, lng: 29.58783776217217 },
      { lat: -0.2053, lng: 29.8195 },
      { lat: 0.597379868976361, lng: 29.875778842902434 },
      { lat: 1.062312730306417, lng: 30.08615359876279 },
      { lat: 1.583805446779706, lng: 30.46850752129029 },
      { lat: 1.849396470543752, lng: 30.852670118948136 },
      { lat: 2.204465236821306, lng: 31.17414920423596 },
      { lat: 2.339890000000139, lng: 30.77332 },
      { lat: 3.50917, lng: 30.83385 },
      { lat: 3.7819, lng: 31.24556 },
      { lat: 3.55827, lng: 31.88145 },
      { lat: 3.79232, lng: 32.68642 },
      { lat: 3.79, lng: 33.3900000000001 },
      { lat: 4.249884947362147, lng: 34.005 },
      { lat: 3.5556, lng: 34.47913 },
      { lat: 3.053740000000118, lng: 34.59607 },
      { lat: 1.90584, lng: 35.03599 },
      { lat: 1.17694, lng: 34.6721 },
      { lat: 0.515, lng: 34.18 },
      { lat: 0.109813537861839, lng: 33.893568969666994 },
      { lat: -0.95, lng: 33.9037111971046 },
      { lat: -1.02736, lng: 31.86617 },
    ],
  ],
  US: [
    [
      { lat: 21.982, lng: -159.34512 },
      { lat: 21.88299, lng: -159.46372 },
      { lat: 22.06533, lng: -159.80051 },
      { lat: 22.1382, lng: -159.74877 },
      { lat: 22.23618, lng: -159.5962 },
      { lat: 22.21494, lng: -159.36569 },
      { lat: 21.982, lng: -159.34512 },
    ],
    [
      { lat: 59.90998688418755, lng: -165.57916419173358 },
      { lat: 59.754440822988975, lng: -166.19277014876727 },
      { lat: 59.94140615502096, lng: -166.848337368822 },
      { lat: 60.21306915957938, lng: -167.45527706609008 },
      { lat: 60.38416982689778, lng: -166.46779212142462 },
      { lat: 60.293606879306246, lng: -165.67442969466367 },
      { lat: 59.90998688418755, lng: -165.57916419173358 },
    ],
    [
      { lat: 21.17684, lng: -156.75824 },
      { lat: 21.06873, lng: -156.78933 },
      { lat: 21.09777, lng: -157.32521 },
      { lat: 21.21958, lng: -157.25027 },
      { lat: 21.17684, lng: -156.75824 },
    ],
    [
      { lat: 63.78251536727592, lng: -171.7316568675394 },
      { lat: 63.592191067144995, lng: -171.1144335602452 },
      { lat: 63.69497549097352, lng: -170.4911124339407 },
      { lat: 63.431115627691156, lng: -169.68250545965358 },
      { lat: 63.2975062120006, lng: -168.6894394603007 },
      { lat: 63.18859813094545, lng: -168.7719408844546 },
      { lat: 62.9769314642779, lng: -169.52943986720504 },
      { lat: 63.194437567794466, lng: -170.29055620021597 },
      { lat: 63.37582184513897, lng: -170.67138566799088 },
      { lat: 63.317789211675084, lng: -171.55306311753867 },
      { lat: 63.405845852300494, lng: -171.7911106028912 },
      { lat: 63.78251536727592, lng: -171.7316568675394 },
    ],
    [
      { lat: 20.64397, lng: -156.07926 },
      { lat: 20.57241, lng: -156.41445 },
      { lat: 20.783, lng: -156.58673 },
      { lat: 20.8643, lng: -156.70167 },
      { lat: 20.92676, lng: -156.71055 },
      { lat: 21.01249, lng: -156.61258 },
      { lat: 20.91745, lng: -156.25711 },
      { lat: 20.76404, lng: -155.99566 },
      { lat: 20.64397, lng: -156.07926 },
    ],
    [
      { lat: 49.38905, lng: -94.81758 },
      { lat: 48.84000000000012, lng: -94.63999999999987 },
      { lat: 48.67074000000011, lng: -94.32914 },
      { lat: 48.60926, lng: -93.63087 },
      { lat: 48.45, lng: -92.61 },
      { lat: 48.14, lng: -91.64 },
      { lat: 48.27, lng: -90.82999999999986 },
      { lat: 48.010000000000105, lng: -89.6 },
      { lat: 48.01980825458284, lng: -89.27291744663668 },
      { lat: 48.30291758889382, lng: -88.37811418328653 },
      { lat: 47.94, lng: -87.43979262330024 },
      { lat: 47.55333801939204, lng: -86.46199083122815 },
      { lat: 47.22021881773051, lng: -85.65236324740323 },
      { lat: 46.90008331968238, lng: -84.87607988151485 },
      { lat: 46.63710195574913, lng: -84.77923824739983 },
      { lat: 46.53868419044923, lng: -84.54374874544567 },
      { lat: 46.4396, lng: -84.6049 },
      { lat: 46.40877000000011, lng: -84.3367 },
      { lat: 46.51222585711574, lng: -84.1421195136733 },
      { lat: 46.27541860613826, lng: -84.09185126416148 },
      { lat: 46.116926988299156, lng: -83.89076534700567 },
      { lat: 46.116926988299156, lng: -83.6161309475905 },
      { lat: 45.99468638771259, lng: -83.46955074739463 },
      { lat: 45.81689362241255, lng: -83.59285071484308 },
      { lat: 45.34751658790545, lng: -82.55092464875818 },
      { lat: 44.44, lng: -82.33776312543108 },
      { lat: 43.57108755144, lng: -82.13764238150397 },
      { lat: 42.9800000000001, lng: -82.43 },
      { lat: 42.43000000000015, lng: -82.89999999999989 },
      { lat: 42.08, lng: -83.11999999999989 },
      { lat: 41.975681057293, lng: -83.14199968131256 },
      { lat: 41.83279572200601, lng: -83.02981014680694 },
      { lat: 41.675105088867326, lng: -82.69008928092018 },
      { lat: 41.675105088867326, lng: -82.43927771679162 },
      { lat: 42.20902598730686, lng: -81.27774654816707 },
      { lat: 42.36619985612267, lng: -80.24744767934784 },
      { lat: 42.86361135514812, lng: -78.9393621487437 },
      { lat: 42.965, lng: -78.92 },
      { lat: 43.27, lng: -79.00999999999988 },
      { lat: 43.46633942318431, lng: -79.17167355011188 },
      { lat: 43.62508942318496, lng: -78.72027991404238 },
      { lat: 43.62905558936339, lng: -77.73788509795762 },
      { lat: 43.628784288093755, lng: -76.82003414580558 },
      { lat: 44.018458893758606, lng: -76.5 },
      { lat: 44.09631, lng: -76.375 },
      { lat: 44.816450000000174, lng: -75.31821 },
      { lat: 45.000480000000124, lng: -74.867 },
      { lat: 45.00738, lng: -73.34783 },
      { lat: 45.0082000000001, lng: -71.50505999999987 },
      { lat: 45.25500000000014, lng: -71.405 },
      { lat: 45.30524000000017, lng: -71.08482 },
      { lat: 45.46, lng: -70.6599999999998 },
      { lat: 45.915, lng: -70.305 },
      { lat: 46.69307, lng: -69.99997 },
      { lat: 47.447781, lng: -69.237216 },
      { lat: 47.185, lng: -68.905 },
      { lat: 47.35486, lng: -68.23444 },
      { lat: 47.06636, lng: -67.79046 },
      { lat: 45.70281000000014, lng: -67.79134 },
      { lat: 45.13753, lng: -67.13741 },
      { lat: 44.80970000000016, lng: -66.96466 },
      { lat: 44.3252, lng: -68.03252 },
      { lat: 43.98, lng: -69.05999999999989 },
      { lat: 43.684050000000155, lng: -70.11617 },
      { lat: 43.09023834896405, lng: -70.645475633411 },
      { lat: 42.8653, lng: -70.81489 },
      { lat: 42.335, lng: -70.825 },
      { lat: 41.805, lng: -70.495 },
      { lat: 41.78, lng: -70.08 },
      { lat: 42.145, lng: -70.185 },
      { lat: 41.92283000000012, lng: -69.88497 },
      { lat: 41.63717000000017, lng: -69.96503 },
      { lat: 41.475, lng: -70.64 },
      { lat: 41.49445000000017, lng: -71.12039 },
      { lat: 41.32, lng: -71.85999999999984 },
      { lat: 41.27, lng: -72.295 },
      { lat: 41.22065, lng: -72.87643 },
      { lat: 40.93110235165449, lng: -73.71 },
      { lat: 41.11948000000015, lng: -72.24126 },
      { lat: 40.93, lng: -71.94499999999982 },
      { lat: 40.63, lng: -73.345 },
      { lat: 40.628, lng: -73.982 },
      { lat: 40.75075, lng: -73.952325 },
      { lat: 40.47351, lng: -74.25671 },
      { lat: 40.42763, lng: -73.96244 },
      { lat: 39.70926, lng: -74.17838 },
      { lat: 38.93954, lng: -74.90604 },
      { lat: 39.1964, lng: -74.98041 },
      { lat: 39.248450000000105, lng: -75.20002 },
      { lat: 39.4985, lng: -75.52805 },
      { lat: 38.96, lng: -75.32 },
      { lat: 38.78203223017928, lng: -75.0718347647898 },
      { lat: 38.40412000000012, lng: -75.05673 },
      { lat: 38.01551, lng: -75.37747 },
      { lat: 37.21689, lng: -75.94023 },
      { lat: 37.2566, lng: -76.03127 },
      { lat: 37.93705000000011, lng: -75.72204999999978 },
      { lat: 38.319215, lng: -76.23287 },
      { lat: 39.15, lng: -76.35 },
      { lat: 38.71761500000011, lng: -76.542725 },
      { lat: 38.08326, lng: -76.32933 },
      { lat: 38.23999176691339, lng: -76.98999793161354 },
      { lat: 37.917945, lng: -76.30162 },
      { lat: 36.96640000000011, lng: -76.25874 },
      { lat: 36.89726, lng: -75.9718 },
      { lat: 36.55125, lng: -75.86803999999984 },
      { lat: 35.55074000000013, lng: -75.72749 },
      { lat: 34.80854000000013, lng: -76.36318 },
      { lat: 34.51201, lng: -77.39763499999988 },
      { lat: 33.92547, lng: -78.05496 },
      { lat: 33.86133000000012, lng: -78.55434999999983 },
      { lat: 33.49395, lng: -79.06067 },
      { lat: 33.15839, lng: -79.20357 },
      { lat: 32.509355, lng: -80.301325 },
      { lat: 32.0333, lng: -80.86498 },
      { lat: 31.44049, lng: -81.33629 },
      { lat: 30.72999000000013, lng: -81.49042 },
      { lat: 30.035520000000105, lng: -81.31371 },
      { lat: 29.180000000000117, lng: -80.98 },
      { lat: 28.47213, lng: -80.53558499999988 },
      { lat: 28.040000000000106, lng: -80.5299999999998 },
      { lat: 26.880000000000138, lng: -80.05653928497756 },
      { lat: 26.205765, lng: -80.088015 },
      { lat: 25.816775, lng: -80.13155999999987 },
      { lat: 25.20616, lng: -80.38103 },
      { lat: 25.08, lng: -80.67999999999988 },
      { lat: 25.201260000000133, lng: -81.17213 },
      { lat: 25.64, lng: -81.33 },
      { lat: 25.87, lng: -81.70999999999981 },
      { lat: 26.730000000000132, lng: -82.24 },
      { lat: 27.49504, lng: -82.70515 },
      { lat: 27.88624, lng: -82.85526 },
      { lat: 28.550000000000153, lng: -82.65 },
      { lat: 29.100000000000136, lng: -82.92999999999988 },
      { lat: 29.93656, lng: -83.70959 },
      { lat: 30.090000000000117, lng: -84.1 },
      { lat: 29.63615, lng: -85.10882 },
      { lat: 29.68612000000013, lng: -85.28784 },
      { lat: 30.152610000000124, lng: -85.7731 },
      { lat: 30.40000000000012, lng: -86.39999999999988 },
      { lat: 30.27433, lng: -87.53036 },
      { lat: 30.3849, lng: -88.41782 },
      { lat: 30.31598, lng: -89.18048999999984 },
      { lat: 30.15999400483685, lng: -89.59383117841978 },
      { lat: 29.89419, lng: -89.413735 },
      { lat: 29.48864, lng: -89.43 },
      { lat: 29.29108, lng: -89.21767 },
      { lat: 29.15961, lng: -89.40823 },
      { lat: 29.307140000000143, lng: -89.77928 },
      { lat: 29.11743, lng: -90.15463 },
      { lat: 29.148535000000123, lng: -90.880225 },
      { lat: 29.67700000000013, lng: -91.62678499999987 },
      { lat: 29.5523, lng: -92.49906 },
      { lat: 29.78375, lng: -93.22637 },
      { lat: 29.71363, lng: -93.84842 },
      { lat: 29.480000000000132, lng: -94.69 },
      { lat: 28.73863, lng: -95.60026 },
      { lat: 28.30748, lng: -96.59404 },
      { lat: 27.83, lng: -97.13999999999982 },
      { lat: 27.38, lng: -97.37 },
      { lat: 26.69, lng: -97.37999999999987 },
      { lat: 26.21000000000012, lng: -97.33 },
      { lat: 25.87, lng: -97.13999999999982 },
      { lat: 25.84, lng: -97.52999999999989 },
      { lat: 26.060000000000116, lng: -98.24 },
      { lat: 26.37, lng: -99.01999999999988 },
      { lat: 26.84, lng: -99.3 },
      { lat: 27.54, lng: -99.51999999999987 },
      { lat: 28.110000000000127, lng: -100.11 },
      { lat: 28.696120000000118, lng: -100.45584 },
      { lat: 29.380710000000132, lng: -100.9576 },
      { lat: 29.779300000000116, lng: -101.6624 },
      { lat: 29.76, lng: -102.48 },
      { lat: 28.97, lng: -103.11 },
      { lat: 29.27, lng: -103.94 },
      { lat: 29.57196, lng: -104.45696999999984 },
      { lat: 30.12173, lng: -104.70575 },
      { lat: 30.64402, lng: -105.03737 },
      { lat: 31.08383000000012, lng: -105.63159 },
      { lat: 31.39995, lng: -106.1429 },
      { lat: 31.75452, lng: -106.50758999999982 },
      { lat: 31.7548537181664, lng: -108.24 },
      { lat: 31.34222, lng: -108.24194 },
      { lat: 31.34194000000016, lng: -109.035 },
      { lat: 31.33472, lng: -111.02361 },
      { lat: 32.03914, lng: -113.30498 },
      { lat: 32.52528, lng: -114.815 },
      { lat: 32.72083, lng: -114.72138999999986 },
      { lat: 32.61239000000014, lng: -115.9913499999999 },
      { lat: 32.53534, lng: -117.12775999999978 },
      { lat: 33.04622461520389, lng: -117.29593769127388 },
      { lat: 33.621236431201396, lng: -117.944 },
      { lat: 33.740909223124504, lng: -118.41060227589749 },
      { lat: 34.02778157757575, lng: -118.51989482279971 },
      { lat: 34.078, lng: -119.081 },
      { lat: 34.3484771782843, lng: -119.43884064201669 },
      { lat: 34.44711, lng: -120.36778 },
      { lat: 34.60855, lng: -120.62286 },
      { lat: 35.15686000000011, lng: -120.74433 },
      { lat: 36.16153, lng: -121.71456999999988 },
      { lat: 37.551760000000115, lng: -122.54747 },
      { lat: 37.78339000000013, lng: -122.51201 },
      { lat: 38.11371000000011, lng: -122.95319 },
      { lat: 38.95166000000012, lng: -123.7272 },
      { lat: 39.76699000000013, lng: -123.86517 },
      { lat: 40.3132, lng: -124.39807 },
      { lat: 41.142020000000116, lng: -124.17886 },
      { lat: 41.99964000000014, lng: -124.2137 },
      { lat: 42.7659900000001, lng: -124.53284 },
      { lat: 43.70838, lng: -124.14214 },
      { lat: 44.615895, lng: -124.020535 },
      { lat: 45.52341, lng: -123.89893 },
      { lat: 46.86475, lng: -124.079635 },
      { lat: 47.72017000000011, lng: -124.39567 },
      { lat: 48.18443298339855, lng: -124.68721008300783 },
      { lat: 48.3797149658204, lng: -124.56610107421876 },
      { lat: 48.04, lng: -123.12 },
      { lat: 47.096, lng: -122.58736 },
      { lat: 47.36, lng: -122.34 },
      { lat: 48.18, lng: -122.5 },
      { lat: 49.000000000000114, lng: -122.84 },
      { lat: 49.000000000000114, lng: -120 },
      { lat: 49.000000000000114, lng: -117.03121 },
      { lat: 49.000000000000114, lng: -116.04818 },
      { lat: 49.000000000000114, lng: -113 },
      { lat: 49.000000000000114, lng: -110.04999999999983 },
      { lat: 49.000000000000114, lng: -107.05 },
      { lat: 48.99986, lng: -104.04826 },
      { lat: 49.000000000000114, lng: -100.65 },
      { lat: 49.00070000000011, lng: -97.22872000000471 },
      { lat: 49.000000000000114, lng: -95.15906950917196 },
      { lat: 49.38425, lng: -95.15609 },
      { lat: 49.38905, lng: -94.81758 },
    ],
    [
      { lat: 71.1477763943237, lng: -155.06779029032424 },
      { lat: 70.6964085964702, lng: -154.34416520894123 },
      { lat: 70.8899885118357, lng: -153.90000627339262 },
      { lat: 70.82999217394485, lng: -152.2100060699353 },
      { lat: 70.60000621202985, lng: -152.27000240782615 },
      { lat: 70.43001658800571, lng: -150.73999243874454 },
      { lat: 70.53001048449045, lng: -149.72000301816752 },
      { lat: 70.2140349392418, lng: -147.61336157935708 },
      { lat: 70.12000967068676, lng: -145.6899898002253 },
      { lat: 69.9899917670405, lng: -144.92001095907642 },
      { lat: 70.15251414659832, lng: -143.5894461804252 },
      { lat: 69.85193817817265, lng: -142.07251034871342 },
      { lat: 69.71199839952638, lng: -140.98598752156073 },
      { lat: 69.71199839952638, lng: -140.9859883290049 },
      { lat: 66.00002859156868, lng: -140.9924987520294 },
      { lat: 60.30639679629861, lng: -140.99776974812312 },
      { lat: 60.27683787702759, lng: -140.0129978161531 },
      { lat: 60.000007229240026, lng: -139.03900042031586 },
      { lat: 59.56211000000016, lng: -138.34089 },
      { lat: 58.905000000000115, lng: -137.4525 },
      { lat: 59.46389, lng: -136.4797200000001 },
      { lat: 59.78778, lng: -135.47583 },
      { lat: 59.27056000000013, lng: -134.945 },
      { lat: 58.86111, lng: -134.27111 },
      { lat: 58.410285142645165, lng: -133.35554888220722 },
      { lat: 57.69289000000011, lng: -132.73042 },
      { lat: 56.55212, lng: -131.70780999999988 },
      { lat: 55.91583, lng: -130.00778 },
      { lat: 55.28499787049722, lng: -129.9799942633583 },
      { lat: 54.8027534043494, lng: -130.53611018946725 },
      { lat: 55.17890615500204, lng: -131.08581823797215 },
      { lat: 55.49777558045906, lng: -131.9672114671423 },
      { lat: 56.36999624289746, lng: -132.25001074285947 },
      { lat: 57.17888743756214, lng: -133.53918108435641 },
      { lat: 58.1230675319669, lng: -134.07806292029605 },
      { lat: 58.18771474876393, lng: -135.03821103227907 },
      { lat: 58.21220937767046, lng: -136.62806230995466 },
      { lat: 58.49999542910379, lng: -137.80000627968604 },
      { lat: 59.53776154238915, lng: -139.867787041413 },
      { lat: 59.727517401765084, lng: -140.82527381713305 },
      { lat: 60.08444651960499, lng: -142.57444353556446 },
      { lat: 59.9991804063234, lng: -143.9588809948799 },
      { lat: 60.45860972761429, lng: -145.92555681682785 },
      { lat: 60.88465607364463, lng: -147.11437394914668 },
      { lat: 60.672989406977166, lng: -148.22430620012767 },
      { lat: 59.97832896589363, lng: -148.01806555885076 },
      { lat: 59.914172675203304, lng: -148.5708225168609 },
      { lat: 59.70565827090556, lng: -149.72785783587585 },
      { lat: 59.36821116803949, lng: -150.60824337461645 },
      { lat: 59.15582103131999, lng: -151.71639278868332 },
      { lat: 59.744984035879604, lng: -151.85943315326716 },
      { lat: 60.72580272077939, lng: -151.4097190012472 },
      { lat: 61.03358755150986, lng: -150.34694149473253 },
      { lat: 61.284424953854455, lng: -150.62111080625698 },
      { lat: 60.72719798445129, lng: -151.89583919981686 },
      { lat: 60.06165721296429, lng: -152.5783298410956 },
      { lat: 59.35027944603428, lng: -154.01917212625762 },
      { lat: 58.8647276882198, lng: -153.28751135965317 },
      { lat: 58.14637360293054, lng: -154.2324924387585 },
      { lat: 57.72779450136633, lng: -155.30749142151024 },
      { lat: 57.42277435976365, lng: -156.3083347239231 },
      { lat: 56.979984849670636, lng: -156.55609737854633 },
      { lat: 56.46360809999419, lng: -158.11721655986776 },
      { lat: 55.99415355083855, lng: -158.43332129619716 },
      { lat: 55.56668610292012, lng: -159.60332739971744 },
      { lat: 55.643580634170576, lng: -160.2897196116342 },
      { lat: 55.364734605523495, lng: -161.2230476552578 },
      { lat: 55.02418691672011, lng: -162.23776607974108 },
      { lat: 54.68973704692717, lng: -163.06944658104638 },
      { lat: 54.40417308208217, lng: -164.7855692210272 },
      { lat: 54.57222483989534, lng: -164.94222632552004 },
      { lat: 55.03943146424612, lng: -163.84833960676568 },
      { lat: 55.348043117893205, lng: -162.87000139061593 },
      { lat: 55.89498647727043, lng: -161.80417497459604 },
      { lat: 56.00805451112504, lng: -160.56360470278116 },
      { lat: 56.41805532492876, lng: -160.0705598622845 },
      { lat: 57.01667511659787, lng: -158.68444291891944 },
      { lat: 57.21692129172888, lng: -158.46109737855394 },
      { lat: 57.57000051536306, lng: -157.7227703521839 },
      { lat: 58.32832632103023, lng: -157.55027442119356 },
      { lat: 58.91888458926172, lng: -157.041674974577 },
      { lat: 58.61580231386984, lng: -158.19473120830548 },
      { lat: 58.78778148053732, lng: -158.5172179840231 },
      { lat: 58.424186102931685, lng: -159.05860612692874 },
      { lat: 58.93139028587634, lng: -159.71166704001735 },
      { lat: 58.57254914004164, lng: -159.9812888255002 },
      { lat: 59.07112335879364, lng: -160.35527116599653 },
      { lat: 58.670837714260756, lng: -161.35500342511506 },
      { lat: 58.67166453717738, lng: -161.96889360252635 },
      { lat: 59.26692536074745, lng: -162.05498653872468 },
      { lat: 59.6336213242906, lng: -161.87417070213536 },
      { lat: 59.98972361921391, lng: -162.5180590484921 },
      { lat: 59.79805573184339, lng: -163.81834143782015 },
      { lat: 60.26748444278265, lng: -164.66221757714646 },
      { lat: 60.50749563256241, lng: -165.34638770247483 },
      { lat: 61.07389516869751, lng: -165.35083187565186 },
      { lat: 61.500019029376226, lng: -166.12137915755596 },
      { lat: 62.074996853271806, lng: -165.73445187077053 },
      { lat: 62.63307648380793, lng: -164.91917863671785 },
      { lat: 63.14637848576305, lng: -164.56250790103934 },
      { lat: 63.21944896102377, lng: -163.75333248599702 },
      { lat: 63.05945872664802, lng: -163.0672244944579 },
      { lat: 63.54193573674117, lng: -162.26055538638172 },
      { lat: 63.455816962326764, lng: -161.5344498362486 },
      { lat: 63.766108100023274, lng: -160.77250668032113 },
      { lat: 64.22279857040277, lng: -160.95833513084256 },
      { lat: 64.40278758407531, lng: -161.5180684072122 },
      { lat: 64.78860382756642, lng: -160.77777767641476 },
      { lat: 64.77723501246234, lng: -161.39192623598763 },
      { lat: 64.55944468856822, lng: -162.45305009666885 },
      { lat: 64.33860545516882, lng: -162.7577860178941 },
      { lat: 64.5591604681905, lng: -163.5463942128843 },
      { lat: 64.44694509546885, lng: -164.96082984114517 },
      { lat: 64.68667206487072, lng: -166.42528825586447 },
      { lat: 65.08889557561453, lng: -166.84500423893905 },
      { lat: 65.66999705673675, lng: -168.11056006576717 },
      { lat: 66.0883177761394, lng: -166.70527116602196 },
      { lat: 66.5766600612975, lng: -164.4747096425755 },
      { lat: 66.5766600612975, lng: -163.65251176659564 },
      { lat: 66.07720734319668, lng: -163.78860165103617 },
      { lat: 66.11611969671242, lng: -161.67777442121016 },
      { lat: 66.73556509059512, lng: -162.48971452538 },
      { lat: 67.1163945583701, lng: -163.71971696679108 },
      { lat: 67.6163382025778, lng: -164.4309913808565 },
      { lat: 68.04277212185025, lng: -165.39028683170676 },
      { lat: 68.35887685817968, lng: -166.76444068099602 },
      { lat: 68.88303091091618, lng: -166.20470740462662 },
      { lat: 68.91553538682774, lng: -164.4308105133435 },
      { lat: 69.3711148139129, lng: -163.16861365461452 },
      { lat: 69.85806183539927, lng: -162.93056616926202 },
      { lat: 70.33332998318764, lng: -161.90889726463553 },
      { lat: 70.44768992784958, lng: -160.9347965159337 },
      { lat: 70.89164215766894, lng: -159.03917578838715 },
      { lat: 70.82472117785105, lng: -158.11972286683397 },
      { lat: 71.35776357694175, lng: -156.58082455139805 },
      { lat: 71.1477763943237, lng: -155.06779029032424 },
    ],
    [
      { lat: 19.08348, lng: -155.54211 },
      { lat: 18.91619, lng: -155.68817 },
      { lat: 19.05939, lng: -155.93665 },
      { lat: 19.33888, lng: -155.90806 },
      { lat: 19.70294, lng: -156.07347 },
      { lat: 19.81422, lng: -156.02368 },
      { lat: 19.97729, lng: -155.85008 },
      { lat: 20.17395, lng: -155.91907 },
      { lat: 20.26721, lng: -155.86108 },
      { lat: 20.2487, lng: -155.78505 },
      { lat: 20.07975, lng: -155.40214 },
      { lat: 19.99302, lng: -155.22452 },
      { lat: 19.8591, lng: -155.06226 },
      { lat: 19.50871, lng: -154.80741 },
      { lat: 19.45328, lng: -154.83147 },
      { lat: 19.23972, lng: -155.22217 },
      { lat: 19.08348, lng: -155.54211 },
    ],
    [
      { lat: 57.11584219016589, lng: -153.0063140533369 },
      { lat: 56.73467682558106, lng: -154.0050902984581 },
      { lat: 56.9927489284467, lng: -154.5164027577701 },
      { lat: 57.46119578717249, lng: -154.67099280497115 },
      { lat: 57.81657461204377, lng: -153.76277950744148 },
      { lat: 57.968968410872435, lng: -153.2287294179211 },
      { lat: 57.901427313866975, lng: -152.56479061583514 },
      { lat: 57.59105866152199, lng: -152.1411472239063 },
      { lat: 57.11584219016589, lng: -153.0063140533369 },
    ],
    [
      { lat: 21.32217, lng: -157.65283 },
      { lat: 21.26442, lng: -157.70703 },
      { lat: 21.27729, lng: -157.7786 },
      { lat: 21.31244, lng: -158.12667 },
      { lat: 21.53919, lng: -158.2538 },
      { lat: 21.57912, lng: -158.29265 },
      { lat: 21.71696, lng: -158.0252 },
      { lat: 21.65272, lng: -157.94161 },
      { lat: 21.32217, lng: -157.65283 },
    ],
  ],
  UY: [
    [
      { lat: -30.216294854454258, lng: -57.62513342958296 },
      { lat: -30.109686374636127, lng: -56.97602576356473 },
      { lat: -30.883075860316303, lng: -55.97324459494093 },
      { lat: -30.853878676071393, lng: -55.601510179249345 },
      { lat: -31.494511407193748, lng: -54.57245154480512 },
      { lat: -32.047242526987624, lng: -53.787951626182185 },
      { lat: -32.727666110974724, lng: -53.209588995971544 },
      { lat: -33.20200408298183, lng: -53.6505439927181 },
      { lat: -33.768377780900764, lng: -53.373661668498244 },
      { lat: -34.396814874002224, lng: -53.806425950726535 },
      { lat: -34.952646579733624, lng: -54.93586605489773 },
      { lat: -34.75265878676407, lng: -55.67408972840329 },
      { lat: -34.85983570733742, lng: -56.21529700379607 },
      { lat: -34.430456231424245, lng: -57.139685024633096 },
      { lat: -34.4625472958775, lng: -57.8178606838155 },
      { lat: -33.90945444105757, lng: -58.42707414410439 },
      { lat: -33.26318897881541, lng: -58.34961117209887 },
      { lat: -33.040566908502015, lng: -58.13264767112144 },
      { lat: -32.044503676076154, lng: -58.14244035504076 },
      { lat: -31.016556084926208, lng: -57.87493730328188 },
      { lat: -30.216294854454258, lng: -57.62513342958296 },
    ],
  ],
  UZ: [
    [
      { lat: 37.36278432875879, lng: 66.51860680528867 },
      { lat: 37.97468496352687, lng: 66.54615034370022 },
      { lat: 38.40269501398429, lng: 65.2159989765074 },
      { lat: 38.892406724598246, lng: 64.17022301621677 },
      { lat: 39.36325653742564, lng: 63.51801476426103 },
      { lat: 40.05388621679039, lng: 62.374260288345 },
      { lat: 41.084856879229406, lng: 61.88271406438469 },
      { lat: 41.266370347654615, lng: 61.54717898951356 },
      { lat: 41.22032664648255, lng: 60.46595299667069 },
      { lat: 41.425146185871405, lng: 60.08334069198167 },
      { lat: 42.22308197689021, lng: 59.97642215356978 },
      { lat: 42.75155101172305, lng: 58.62901085799146 },
      { lat: 42.17055288346552, lng: 57.78652998233708 },
      { lat: 41.826026109375604, lng: 56.932215203687804 },
      { lat: 41.32231008561057, lng: 57.0963912290791 },
      { lat: 41.30864166926936, lng: 55.96819135928291 },
      { lat: 44.99585846615911, lng: 55.928917270741096 },
      { lat: 45.586804307632825, lng: 58.50312706892847 },
      { lat: 45.50001373959862, lng: 58.689989048095896 },
      { lat: 44.78403677019473, lng: 60.23997195825833 },
      { lat: 44.40581696225051, lng: 61.05831994003245 },
      { lat: 43.50447663021565, lng: 62.01330040878625 },
      { lat: 43.650074978198006, lng: 63.18578698105657 },
      { lat: 43.72808055274258, lng: 64.90082441595928 },
      { lat: 42.99766002051309, lng: 66.09801232286509 },
      { lat: 41.99464630794398, lng: 66.02339155463562 },
      { lat: 41.987644151368436, lng: 66.51064863471572 },
      { lat: 41.1684435084615, lng: 66.7140470722165 },
      { lat: 41.13599070898222, lng: 67.98585574735182 },
      { lat: 40.6623245305949, lng: 68.25989586779562 },
      { lat: 40.66868073176681, lng: 68.63248294462001 },
      { lat: 41.38424428971237, lng: 69.07002729683532 },
      { lat: 42.08130768489745, lng: 70.3889648782208 },
      { lat: 42.26615428320549, lng: 70.96231489449914 },
      { lat: 42.16771067968946, lng: 71.25924767444822 },
      { lat: 41.51999827734314, lng: 70.42002241402821 },
      { lat: 41.14358714452912, lng: 71.1578585142916 },
      { lat: 41.392900092121266, lng: 71.87011478057047 },
      { lat: 40.866033026689465, lng: 73.05541710804917 },
      { lat: 40.14584442805378, lng: 71.77487511585656 },
      { lat: 40.24436554621823, lng: 71.01419803252017 },
      { lat: 40.21852733007229, lng: 70.60140669137269 },
      { lat: 40.49649485937029, lng: 70.45815962105962 },
      { lat: 40.96021332454141, lng: 70.66662234892505 },
      { lat: 40.72782440852485, lng: 69.32949466337283 },
      { lat: 40.08615814875666, lng: 69.0116329283455 },
      { lat: 39.53345286717894, lng: 68.53641645698941 },
      { lat: 39.58047842056453, lng: 67.70142866401736 },
      { lat: 39.140143541005486, lng: 67.44221967964131 },
      { lat: 38.901553453113905, lng: 68.17602501818592 },
      { lat: 38.157025254868735, lng: 68.39203250516596 },
      { lat: 37.14499400486468, lng: 67.82999962755952 },
      { lat: 37.35614390720929, lng: 67.07578209825962 },
      { lat: 37.36278432875879, lng: 66.51860680528867 },
    ],
  ],
  VA: [
    [
      { lat: 41.886903688789, lng: 12.426829925066 },
      { lat: 41.904935414649, lng: 12.446999613681 },
      { lat: 41.901855473924, lng: 12.447924748347 },
      { lat: 41.895911408836, lng: 12.447345741152 },
      { lat: 41.892961910068, lng: 12.445770064619 },
      { lat: 41.890842096168, lng: 12.443183814717 },
      { lat: 41.886692468428, lng: 12.429432053994 },
      { lat: 41.886903688789, lng: 12.426829925066 },
    ],
  ],
  VC: [
    [
      { lat: 12.712825431812, lng: -61.344056360989 },
      { lat: 12.739473128353, lng: -61.354179000676 },
      { lat: 13.278307547862, lng: -61.285732375834 },
      { lat: 13.345010302526, lng: -61.210827011374 },
      { lat: 13.36356792066, lng: -61.151574076426 },
      { lat: 13.36117280286, lng: -61.131567000626 },
      { lat: 13.355883796244, lng: -61.127716266156 },
      { lat: 13.295140739868, lng: -61.112848226661 },
      { lat: 13.13615323463, lng: -61.133404911737 },
      { lat: 12.95508821725, lng: -61.209033016703 },
      { lat: 12.714048128909, lng: -61.335099062004 },
      { lat: 12.712825431812, lng: -61.344056360989 },
    ],
  ],
  VE: [
    [
      { lat: 11.776284084515808, lng: -71.3315836249503 },
      { lat: 11.53999359786121, lng: -71.36000566271082 },
      { lat: 11.423282375530022, lng: -71.94704993354651 },
      { lat: 10.969459947142795, lng: -71.62086829292019 },
      { lat: 10.446494452349027, lng: -71.63306393094108 },
      { lat: 9.865651353388373, lng: -72.07417395698451 },
      { lat: 9.072263088411248, lng: -71.69564409044654 },
      { lat: 9.137194525585983, lng: -71.26455929226773 },
      { lat: 9.859992784052407, lng: -71.03999935574339 },
      { lat: 10.211935126176215, lng: -71.35008378771079 },
      { lat: 10.968969021036015, lng: -71.40062333849224 },
      { lat: 11.37548167566004, lng: -70.1552988349065 },
      { lat: 11.846822414594214, lng: -70.29384334988103 },
      { lat: 12.162307033736099, lng: -69.94324459499683 },
      { lat: 11.459610907431212, lng: -69.58430009629747 },
      { lat: 11.443384507691563, lng: -68.88299923366445 },
      { lat: 10.885744126829946, lng: -68.23327145045873 },
      { lat: 10.554653225135922, lng: -68.19412655299763 },
      { lat: 10.54586823164631, lng: -67.29624854192633 },
      { lat: 10.648626817258688, lng: -66.227864142508 },
      { lat: 10.200798855017323, lng: -65.65523759628175 },
      { lat: 10.0772146671913, lng: -64.89045223657817 },
      { lat: 10.38959870039568, lng: -64.32947872583374 },
      { lat: 10.64141795495398, lng: -64.31800655786495 },
      { lat: 10.7017243514386, lng: -63.07932247582874 },
      { lat: 10.715625311725104, lng: -61.880946010980196 },
      { lat: 10.420268662960906, lng: -62.73011898461641 },
      { lat: 9.94820445397464, lng: -62.388511928950976 },
      { lat: 9.873066921422264, lng: -61.58876746280193 },
      { lat: 9.38133982994894, lng: -60.83059668643172 },
      { lat: 8.580174261911878, lng: -60.67125240745973 },
      { lat: 8.602756862823426, lng: -60.15009558779618 },
      { lat: 8.367034816924047, lng: -59.758284878159195 },
      { lat: 7.779602972846178, lng: -60.55058793805819 },
      { lat: 7.414999904810855, lng: -60.637972785063766 },
      { lat: 7.043911444522919, lng: -60.29566809756239 },
      { lat: 6.856584377464883, lng: -60.54399919294098 },
      { lat: 6.696077378766319, lng: -61.15933631045648 },
      { lat: 6.234296779806144, lng: -61.13941504580795 },
      { lat: 5.959068101419618, lng: -61.410302903881956 },
      { lat: 5.200277207861901, lng: -60.73357418480372 },
      { lat: 4.91809804933213, lng: -60.60117916527194 },
      { lat: 4.536467596856639, lng: -60.96689327660153 },
      { lat: 4.162123521334308, lng: -62.08542965355914 },
      { lat: 4.006965033377952, lng: -62.804533047116706 },
      { lat: 3.770571193858785, lng: -63.0931975978991 },
      { lat: 4.020530096854571, lng: -63.88834286157416 },
      { lat: 4.14848094320925, lng: -64.62865943058755 },
      { lat: 4.056445217297423, lng: -64.81606401229402 },
      { lat: 3.797210394705246, lng: -64.36849443221409 },
      { lat: 3.126786200366624, lng: -64.40882788761792 },
      { lat: 2.497005520025567, lng: -64.26999915226578 },
      { lat: 2.411067613124174, lng: -63.42286739770512 },
      { lat: 2.200899562993129, lng: -63.36878801131166 },
      { lat: 1.91636912679408, lng: -64.08308549666609 },
      { lat: 1.49285492594602, lng: -64.19930579289051 },
      { lat: 1.328730576987042, lng: -64.61101192895985 },
      { lat: 1.0952822941085, lng: -65.35471330428837 },
      { lat: 0.78925446207603, lng: -65.54826738143757 },
      { lat: 0.724452215982012, lng: -66.32576514348496 },
      { lat: 1.253360500489336, lng: -66.87632585312258 },
      { lat: 2.250638129074062, lng: -67.18129431829307 },
      { lat: 2.600280869960869, lng: -67.44709204778631 },
      { lat: 2.820655015469569, lng: -67.8099381171237 },
      { lat: 3.31845408773718, lng: -67.30317318385345 },
      { lat: 3.542342230641722, lng: -67.33756384954368 },
      { lat: 3.839481716319994, lng: -67.62183590358127 },
      { lat: 4.503937282728899, lng: -67.82301225449355 },
      { lat: 5.221128648291668, lng: -67.74469662135522 },
      { lat: 5.556870428891969, lng: -67.52153194850275 },
      { lat: 6.095468044454023, lng: -67.34143958196557 },
      { lat: 6.267318020040647, lng: -67.69508724635502 },
      { lat: 6.153268133972475, lng: -68.26505245631823 },
      { lat: 6.206804917826858, lng: -68.98531856960236 },
      { lat: 6.099860541198836, lng: -69.38947994655712 },
      { lat: 6.96037649172311, lng: -70.09331295437242 },
      { lat: 7.087784735538719, lng: -70.67423356798152 },
      { lat: 6.991614895043538, lng: -71.96017574734864 },
      { lat: 7.340430813013682, lng: -72.19835242378188 },
      { lat: 7.423784898300481, lng: -72.44448727078807 },
      { lat: 7.632506008327354, lng: -72.47967892117885 },
      { lat: 8.002638454617895, lng: -72.36090064155596 },
      { lat: 8.405275376820029, lng: -72.43986223009796 },
      { lat: 8.625287787302682, lng: -72.6604947577681 },
      { lat: 9.085027167187334, lng: -72.7887298245004 },
      { lat: 9.151999823437606, lng: -73.30495154488005 },
      { lat: 9.736770331252444, lng: -73.02760413276957 },
      { lat: 10.450344346554772, lng: -72.9052860175347 },
      { lat: 10.821975409381778, lng: -72.61465776232521 },
      { lat: 11.10870209395324, lng: -72.22757544624294 },
      { lat: 11.60867157637712, lng: -71.97392167833829 },
      { lat: 11.776284084515808, lng: -71.3315836249503 },
    ],
  ],
  VG: [
    [
      { lat: 18.43466763338112, lng: -64.86808776855469 },
      { lat: 18.419684546193967, lng: -64.85641479492188 },
      { lat: 18.377985612444007, lng: -64.76028442382811 },
      { lat: 18.369514283594988, lng: -64.66896057128906 },
      { lat: 18.34540130240112, lng: -64.64286804199219 },
      { lat: 18.288689957771272, lng: -64.63737487792969 },
      { lat: 18.315418308852806, lng: -64.55635070800781 },
      { lat: 18.500447458475094, lng: -64.30435180664062 },
      { lat: 18.542116654448996, lng: -64.34829711914062 },
      { lat: 18.505005521137843, lng: -64.58244323730469 },
      { lat: 18.46072206529225, lng: -64.78706359863281 },
      { lat: 18.46397859132042, lng: -64.83238220214844 },
      { lat: 18.43466763338112, lng: -64.86808776855469 },
    ],
  ],
  VN: [
    [
      { lat: 21.55237986906012, lng: 108.05018029178294 },
      { lat: 20.69685069425202, lng: 106.7150679870901 },
      { lat: 19.752050482659698, lng: 105.88168216351903 },
      { lat: 19.05816518806057, lng: 105.66200564984631 },
      { lat: 18.004120998603227, lng: 106.426816847766 },
      { lat: 16.69745656988705, lng: 107.36195356651974 },
      { lat: 16.07974233648615, lng: 108.26949507042963 },
      { lat: 15.27669057867044, lng: 108.87710656131748 },
      { lat: 13.426028347217722, lng: 109.33526981001721 },
      { lat: 11.666859239137764, lng: 109.20013593957398 },
      { lat: 11.008320624226272, lng: 108.36612999881545 },
      { lat: 10.364483954301832, lng: 107.22092858279524 },
      { lat: 9.53083974856932, lng: 106.40511274620343 },
      { lat: 8.599759629750494, lng: 105.15826378786511 },
      { lat: 9.241038316276502, lng: 104.79518517458237 },
      { lat: 9.918490505406808, lng: 105.0762016133856 },
      { lat: 10.48654368737523, lng: 104.33433475140347 },
      { lat: 10.889309800658097, lng: 105.19991499229235 },
      { lat: 10.961811835163587, lng: 106.24967003786946 },
      { lat: 11.567614650921227, lng: 105.81052371625313 },
      { lat: 12.337205918827946, lng: 107.49140302941089 },
      { lat: 13.535530707244206, lng: 107.61454796756243 },
      { lat: 14.202440904186972, lng: 107.38272749230109 },
      { lat: 15.202173163305558, lng: 107.5645251811039 },
      { lat: 15.90853831630318, lng: 107.3127059265456 },
      { lat: 16.604283962464805, lng: 106.55600792849569 },
      { lat: 17.48531545660896, lng: 105.92576216026403 },
      { lat: 18.66697459561108, lng: 105.09459842328152 },
      { lat: 19.265180975821806, lng: 103.8965320170267 },
      { lat: 19.62466807706022, lng: 104.18338789267894 },
      { lat: 19.886641750563882, lng: 104.8225736836971 },
      { lat: 20.75873322192153, lng: 104.43500044150805 },
      { lat: 20.766562201413745, lng: 103.20386111858645 },
      { lat: 21.675137233969465, lng: 102.75489627483466 },
      { lat: 22.464753119389304, lng: 102.17043582561358 },
      { lat: 22.708795070887675, lng: 102.7069922221001 },
      { lat: 22.70375661873921, lng: 103.50451460166056 },
      { lat: 22.819150092046968, lng: 104.47685835166448 },
      { lat: 23.352063300056912, lng: 105.32920942588663 },
      { lat: 22.976892401617903, lng: 105.81124718630521 },
      { lat: 22.79426788989842, lng: 106.72540327354845 },
      { lat: 22.21820486092477, lng: 106.56727339073532 },
      { lat: 21.811898912029914, lng: 107.04342003787262 },
      { lat: 21.55237986906012, lng: 108.05018029178294 },
    ],
  ],
  VU: [
    [
      { lat: -16.466333103097156, lng: 167.8448767438451 },
      { lat: -16.597849623279966, lng: 167.5151811058229 },
      { lat: -16.15999521247096, lng: 167.18000776597782 },
      { lat: -15.891846205308452, lng: 167.21680138576963 },
      { lat: -16.466333103097156, lng: 167.8448767438451 },
    ],
    [
      { lat: -14.933920179913954, lng: 167.10771243720149 },
      { lat: -15.740020847234874, lng: 167.27002811103026 },
      { lat: -15.614602146062495, lng: 167.00120731024796 },
      { lat: -15.668810723536723, lng: 166.79315799384088 },
      { lat: -15.392703545801195, lng: 166.64985924709558 },
      { lat: -14.626497084209603, lng: 166.62913699774649 },
      { lat: -14.933920179913954, lng: 167.10771243720149 },
    ],
  ],
  WS: [
    [
      { lat: -13.525489505953, lng: -172.7552446572 },
      { lat: -13.478080237778, lng: -172.64978988119 },
      { lat: -13.45398947007, lng: -172.34364629195 },
      { lat: -13.463934036599, lng: -172.31157729788 },
      { lat: -13.617525466423, lng: -172.11634609488 },
      { lat: -13.718002176515, lng: -171.96389776355 },
      { lat: -13.791622976562, lng: -171.79681682269 },
      { lat: -13.860147728247, lng: -171.59752838231 },
      { lat: -13.945533152072, lng: -171.47340851961 },
      { lat: -13.978720782253, lng: -171.44866750521 },
      { lat: -14.043074707331, lng: -171.44638415657 },
      { lat: -14.05401579537, lng: -171.45067538353 },
      { lat: -14.0643597408, lng: -171.47531559201 },
      { lat: -14.072933169969, lng: -171.65822722651 },
      { lat: -14.032576527714, lng: -171.89506513008 },
      { lat: -13.808383054179, lng: -172.52584864536 },
      { lat: -13.723875919288, lng: -172.62093025857 },
      { lat: -13.525489505953, lng: -172.7552446572 },
    ],
  ],
  YE: [
    [
      { lat: 16.65105113368895, lng: 53.10857262554751 },
      { lat: 16.382411200419654, lng: 52.38520592632588 },
      { lat: 15.93843313238402, lng: 52.19172936382509 },
      { lat: 15.597420355689948, lng: 52.1681649107 },
      { lat: 15.175249742081492, lng: 51.172515089732485 },
      { lat: 14.70876658778275, lng: 49.57457645040315 },
      { lat: 14.00320241948566, lng: 48.67923058451416 },
      { lat: 13.948089504446372, lng: 48.23894738138742 },
      { lat: 14.007233181204427, lng: 47.938914015500785 },
      { lat: 13.592219753468383, lng: 47.354453566279716 },
      { lat: 13.39969920496502, lng: 46.717076450391744 },
      { lat: 13.347764390511685, lng: 45.87759280781027 },
      { lat: 13.290946153206763, lng: 45.62505008319987 },
      { lat: 13.026905422411433, lng: 45.406458774605255 },
      { lat: 12.95393830001531, lng: 45.14435591002086 },
      { lat: 12.699586900274708, lng: 44.98953331887441 },
      { lat: 12.721652736863346, lng: 44.49457645038285 },
      { lat: 12.585950425664876, lng: 44.175112745954486 },
      { lat: 12.636800035040084, lng: 43.48295861183713 },
      { lat: 13.220950425667425, lng: 43.22287112811213 },
      { lat: 13.767583726450852, lng: 43.25144819516953 },
      { lat: 14.06263031662131, lng: 43.08794396339806 },
      { lat: 14.802249253798749, lng: 42.89224531430872 },
      { lat: 15.213335272680595, lng: 42.60487267433362 },
      { lat: 15.261962795467255, lng: 42.80501549660005 },
      { lat: 15.718885809791999, lng: 42.70243777850066 },
      { lat: 15.911742255105265, lng: 42.823670688657415 },
      { lat: 16.347891343648683, lng: 42.77933230975097 },
      { lat: 16.66688996018641, lng: 43.21837527850275 },
      { lat: 17.088440456607373, lng: 43.11579756040335 },
      { lat: 17.57998668056767, lng: 43.380794305196105 },
      { lat: 17.31997671149111, lng: 43.79151858905192 },
      { lat: 17.410358791569593, lng: 44.06261315285508 },
      { lat: 17.43332896572333, lng: 45.21665123879718 },
      { lat: 17.333335069238558, lng: 45.39999922056875 },
      { lat: 17.233315334537636, lng: 46.366658563020536 },
      { lat: 17.283338120996177, lng: 46.74999433776165 },
      { lat: 16.949999294497445, lng: 47.000004917189756 },
      { lat: 17.116681626854884, lng: 47.46669477721763 },
      { lat: 18.166669216377315, lng: 48.18334354024134 },
      { lat: 18.616667588774945, lng: 49.11667158386487 },
      { lat: 19.000003363516058, lng: 52.00000980002224 },
      { lat: 17.349742336491232, lng: 52.78218427919205 },
      { lat: 16.65105113368895, lng: 53.10857262554751 },
    ],
  ],
  ZA: [
    [
      { lat: -29.257386976846252, lng: 31.521001417778876 },
      { lat: -29.401977634398914, lng: 31.325561150851 },
      { lat: -29.90995696382804, lng: 30.901762729625343 },
      { lat: -30.42377573010613, lng: 30.622813348113823 },
      { lat: -31.140269463832958, lng: 30.05571618014278 },
      { lat: -32.17204111097249, lng: 28.925552605919535 },
      { lat: -32.771952813448856, lng: 28.2197558936771 },
      { lat: -33.2269637997788, lng: 27.464608188595975 },
      { lat: -33.61495045342619, lng: 26.419452345492825 },
      { lat: -33.6670402971764, lng: 25.90966434093349 },
      { lat: -33.944646091448334, lng: 25.780628289500697 },
      { lat: -33.796851495093584, lng: 25.172861769315972 },
      { lat: -33.98717579522455, lng: 24.677853224392123 },
      { lat: -33.794474379208154, lng: 23.594043409934642 },
      { lat: -33.91643075941698, lng: 22.988188917744733 },
      { lat: -33.864082533505304, lng: 22.574157342222236 },
      { lat: -34.258838799782936, lng: 21.542799106541025 },
      { lat: -34.417175388325234, lng: 20.689052768647002 },
      { lat: -34.79513681410799, lng: 20.071261020597632 },
      { lat: -34.81916635512371, lng: 19.61640506356457 },
      { lat: -34.46259897230979, lng: 19.193278435958717 },
      { lat: -34.444305515278465, lng: 18.85531456876987 },
      { lat: -33.99787281670896, lng: 18.42464318204938 },
      { lat: -34.13652068454807, lng: 18.377410922934615 },
      { lat: -33.86775156019802, lng: 18.244499139079917 },
      { lat: -33.28143075941444, lng: 18.250080193767445 },
      { lat: -32.61129078545343, lng: 17.92519046394844 },
      { lat: -32.42913136162456, lng: 18.247909783611192 },
      { lat: -31.66163298922567, lng: 18.22176150887148 },
      { lat: -30.725721123987547, lng: 17.56691775886887 },
      { lat: -29.87864104585916, lng: 17.064416131262703 },
      { lat: -29.875953871379984, lng: 17.062917514726223 },
      { lat: -28.576705010697697, lng: 16.344976840895242 },
      { lat: -28.082161553664466, lng: 16.824017368240902 },
      { lat: -28.35594329194681, lng: 17.218928663815404 },
      { lat: -28.78351409272978, lng: 17.387497185951503 },
      { lat: -28.85637786226132, lng: 17.83615197110953 },
      { lat: -29.04546192801728, lng: 18.464899122804752 },
      { lat: -28.972443129188864, lng: 19.002127312911085 },
      { lat: -28.461104831660776, lng: 19.894734327888614 },
      { lat: -24.767790215760588, lng: 19.895767856534434 },
      { lat: -24.917961928000768, lng: 20.165725538827186 },
      { lat: -25.86813648855145, lng: 20.758609246511835 },
      { lat: -26.477453301704923, lng: 20.66647016773544 },
      { lat: -26.828542982695915, lng: 20.88960900237174 },
      { lat: -26.726533705351756, lng: 21.60589603036939 },
      { lat: -26.280256036079138, lng: 22.105968865657868 },
      { lat: -25.979447523708146, lng: 22.57953169118059 },
      { lat: -25.500458672794768, lng: 22.8242712745149 },
      { lat: -25.26868987396572, lng: 23.312096795350186 },
      { lat: -25.390129489851613, lng: 23.73356977712271 },
      { lat: -25.670215752873574, lng: 24.211266717228792 },
      { lat: -25.7196700985769, lng: 25.025170525825786 },
      { lat: -25.486816094669713, lng: 25.66466637543772 },
      { lat: -25.174845472923675, lng: 25.76584882986521 },
      { lat: -24.69637338633322, lng: 25.94165205252216 },
      { lat: -24.616326592713104, lng: 26.4857532081233 },
      { lat: -24.240690606383485, lng: 26.786406691197413 },
      { lat: -23.574323011979775, lng: 27.119409620886245 },
      { lat: -22.827753594659075, lng: 28.01723595552525 },
      { lat: -22.091312758067588, lng: 29.43218834810904 },
      { lat: -22.102216485281176, lng: 29.839036899542972 },
      { lat: -22.27161183033393, lng: 30.322883335091774 },
      { lat: -22.151567478119915, lng: 30.65986535006709 },
      { lat: -22.2515096981724, lng: 31.191409132621285 },
      { lat: -23.658969008073864, lng: 31.670397983534652 },
      { lat: -24.369416599222536, lng: 31.93058882012425 },
      { lat: -25.484283949487413, lng: 31.75240848158188 },
      { lat: -25.84333180105135, lng: 31.837777947728064 },
      { lat: -25.66019052500895, lng: 31.333157586397906 },
      { lat: -25.731452325139443, lng: 31.04407962415715 },
      { lat: -26.022649021104147, lng: 30.949666782359913 },
      { lat: -26.398078301704608, lng: 30.676608514129637 },
      { lat: -26.74384531016953, lng: 30.68596194837448 },
      { lat: -27.285879408478998, lng: 31.282773064913325 },
      { lat: -27.177927341421277, lng: 31.868060337051077 },
      { lat: -26.73382008230491, lng: 32.07166548028107 },
      { lat: -26.742191664336197, lng: 32.830120477028885 },
      { lat: -27.470157566031816, lng: 32.580264926897684 },
      { lat: -28.301011244420557, lng: 32.46213260267845 },
      { lat: -28.752404880490072, lng: 32.20338870619304 },
      { lat: -29.257386976846252, lng: 31.521001417778876 },
    ],
  ],
  ZM: [
    [
      { lat: -9.230599053589058, lng: 32.75937544122132 },
      { lat: -9.6767216935648, lng: 33.2313879737753 },
      { lat: -10.525558770391115, lng: 33.48568769708359 },
      { lat: -10.796549981329697, lng: 33.315310499817286 },
      { lat: -11.607198174692314, lng: 33.11428917820191 },
      { lat: -12.435778090060218, lng: 33.306422153463075 },
      { lat: -12.783870537978272, lng: 32.991764357237884 },
      { lat: -13.712857761289275, lng: 32.68816531752313 },
      { lat: -13.971860039936153, lng: 33.214024692525214 },
      { lat: -14.796099134991527, lng: 30.17948123548183 },
      { lat: -15.507786960515212, lng: 30.274255812305107 },
      { lat: -15.644677829656388, lng: 29.516834344203147 },
      { lat: -16.04305144619444, lng: 28.947463413211263 },
      { lat: -16.389748630440614, lng: 28.825868768028496 },
      { lat: -16.468400160388846, lng: 28.467906121542683 },
      { lat: -17.290830580314008, lng: 27.598243442502756 },
      { lat: -17.938026218337434, lng: 27.04442711763073 },
      { lat: -17.961228936436484, lng: 26.70677330903564 },
      { lat: -17.8460421688579, lng: 26.381935255648926 },
      { lat: -17.736539808831417, lng: 25.264225701608012 },
      { lat: -17.661815687737374, lng: 25.08444339366457 },
      { lat: -17.57882333747662, lng: 25.07695031098226 },
      { lat: -17.353410739819473, lng: 24.682349074001507 },
      { lat: -17.295843194246324, lng: 24.033861525170778 },
      { lat: -17.52311614346598, lng: 23.215048455506064 },
      { lat: -16.898451429921813, lng: 22.56247846852426 },
      { lat: -16.08031015387688, lng: 21.887842644953874 },
      { lat: -12.898437188369359, lng: 21.933886346125917 },
      { lat: -12.911046237848574, lng: 24.016136508894675 },
      { lat: -12.565847670138854, lng: 23.930922072045377 },
      { lat: -12.191296888887365, lng: 24.079905226342845 },
      { lat: -11.722281589406322, lng: 23.904153680118185 },
      { lat: -11.23729827234709, lng: 24.017893507592586 },
      { lat: -10.926826267137514, lng: 23.912215203555718 },
      { lat: -10.951992689663657, lng: 24.25715538910399 },
      { lat: -11.26282642989927, lng: 24.31451622894795 },
      { lat: -11.238693536018964, lng: 24.78316979340295 },
      { lat: -11.330935967659961, lng: 25.418118116973204 },
      { lat: -11.784965101776358, lng: 25.752309604604733 },
      { lat: -11.924439792532127, lng: 26.553087599399618 },
      { lat: -11.608748467661075, lng: 27.164419793412463 },
      { lat: -12.132747491100666, lng: 27.38879886242378 },
      { lat: -12.272480564017897, lng: 28.155108676879987 },
      { lat: -12.698604424696683, lng: 28.523561639121027 },
      { lat: -13.248958428605135, lng: 28.934285922976837 },
      { lat: -13.257226657771831, lng: 29.69961388521949 },
      { lat: -12.178894545137311, lng: 29.61600141777123 },
      { lat: -12.360743910372413, lng: 29.34154788586909 },
      { lat: -11.971568698782315, lng: 28.642417433392353 },
      { lat: -11.793646742401393, lng: 28.372253045370428 },
      { lat: -10.789883721564044, lng: 28.49606977714177 },
      { lat: -9.605924981324932, lng: 28.67368167492893 },
      { lat: -9.164918308146085, lng: 28.449871046672826 },
      { lat: -8.526559340044578, lng: 28.7348665707625 },
      { lat: -8.407031752153472, lng: 29.002912225060467 },
      { lat: -8.238256524288218, lng: 30.346086053190813 },
      { lat: -8.340007419470915, lng: 30.740015496551788 },
      { lat: -8.594578747317366, lng: 31.15775133695005 },
      { lat: -8.762048841998642, lng: 31.556348097466497 },
      { lat: -8.930358981973278, lng: 32.19186486179197 },
      { lat: -9.230599053589058, lng: 32.75937544122132 },
    ],
  ],
  ZW: [
    [
      { lat: -22.2515096981724, lng: 31.191409132621285 },
      { lat: -22.151567478119915, lng: 30.65986535006709 },
      { lat: -22.27161183033393, lng: 30.322883335091774 },
      { lat: -22.102216485281176, lng: 29.839036899542972 },
      { lat: -22.091312758067588, lng: 29.43218834810904 },
      { lat: -21.63945403410745, lng: 28.794656202924212 },
      { lat: -21.485975030200585, lng: 28.021370070108617 },
      { lat: -20.851801853114715, lng: 27.72722781750326 },
      { lat: -20.499058526290387, lng: 27.724747348753255 },
      { lat: -20.391519870691, lng: 27.296504754350508 },
      { lat: -19.29308562589494, lng: 26.164790887158485 },
      { lat: -18.714412937090536, lng: 25.85039147309473 },
      { lat: -18.53602589281899, lng: 25.649163445750162 },
      { lat: -17.736539808831417, lng: 25.264225701608012 },
      { lat: -17.8460421688579, lng: 26.381935255648926 },
      { lat: -17.961228936436484, lng: 26.70677330903564 },
      { lat: -17.938026218337434, lng: 27.04442711763073 },
      { lat: -17.290830580314008, lng: 27.598243442502756 },
      { lat: -16.468400160388846, lng: 28.467906121542683 },
      { lat: -16.389748630440614, lng: 28.825868768028496 },
      { lat: -16.04305144619444, lng: 28.947463413211263 },
      { lat: -15.644677829656388, lng: 29.516834344203147 },
      { lat: -15.507786960515212, lng: 30.274255812305107 },
      { lat: -15.880839125230244, lng: 30.338954705534544 },
      { lat: -15.860943698797872, lng: 31.173063999157677 },
      { lat: -16.071990248277885, lng: 31.636498243951195 },
      { lat: -16.319417006091378, lng: 31.8520406430406 },
      { lat: -16.392074069893752, lng: 32.32823896661022 },
      { lat: -16.713398125884616, lng: 32.847638787575846 },
      { lat: -17.97905730557718, lng: 32.84986087416439 },
      { lat: -18.672089939043495, lng: 32.65488569512715 },
      { lat: -19.419382826416275, lng: 32.61199425632489 },
      { lat: -19.715592136313298, lng: 32.772707960752626 },
      { lat: -20.304290052982317, lng: 32.65974327976258 },
      { lat: -20.395292250248307, lng: 32.50869306817344 },
      { lat: -21.116488539313693, lng: 32.244988234188014 },
      { lat: -22.2515096981724, lng: 31.191409132621285 },
    ],
  ],
};
