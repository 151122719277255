import { useContext, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  Snackbar,
  Typography,
} from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { TCountry, selectCountries } from 'src/features/storage/storageSlice';
import { formatDate } from 'src/app/helpers';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { TUser } from 'src/features/user/classes';
import { selectMe, setCountryVisitedState } from 'src/features/user/userSlice';
import { FBContext } from 'src/components/Sync/SyncHandler';

import './CountryPopup.scss';

export interface CountryPopupProps {
  open: boolean;
  handleClose: () => void;
  countryCode?: string;
}

enum CountryPopupMode {
  Country = 'country',
  EditFirstVisitDate = 'editFirstVisitDate',
}

export const CountryPopup: React.FC<CountryPopupProps> = props => {
  const { open, handleClose, countryCode } = props;

  const dispatch = useAppDispatch();

  const fbContext = useContext(FBContext);

  const me = useAppSelector<TUser>(selectMe);
  const countries = useAppSelector<Array<TCountry>>(selectCountries);
  const visitedCountry = countryCode && me.countries[countryCode];

  const [removeCountryDialogOpened, setRemoveCountryDialogOpened] = useState<boolean>(false);
  const [mode, setMode] = useState<CountryPopupMode>(CountryPopupMode.Country);

  const notChangingCountryProps = useMemo(() => {
    if (!countryCode) return;
    const selectedCountry = countries.find(country => country.code === countryCode);
    if (!selectedCountry) return console.warn('No country in storage by code', countryCode);

    return { flag: selectedCountry.flag, name: selectedCountry.name };
  }, [countryCode]);

  useEffect(() => {
    setMode(CountryPopupMode.Country);
  }, [countryCode, open]);

  const updateCountryFirstVisitDate = (firstVisitDate?: string) => {
    if (visitedCountry)
      dispatch(
        setCountryVisitedState({
          db: fbContext.db,
          userId: fbContext.user?.uid,
          country: {
            ...visitedCountry,
            firstVisitDate,
            updatedAt: new Date().toISOString(),
          },
          visitedState: true,
        }),
      );
  };

  const removeCountryVisit = () => {
    if (visitedCountry) {
      setRemoveCountryDialogOpened(false);
      handleClose();
      dispatch(
        setCountryVisitedState({
          db: fbContext.db,
          userId: fbContext.user?.uid,
          country: visitedCountry!,
          visitedState: false,
        }),
      );
    }
  };

  return (
    <>
      {visitedCountry && (
        <Snackbar
          className='menuBottom'
          open={open}
          autoHideDuration={null}
          onClose={handleClose}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        >
          <Card>
            <CardContent>
              <Typography variant='h5' component='div' gutterBottom style={{ display: 'flex', justifyContent: 'space-between' }}>
                {notChangingCountryProps?.flag}&nbsp;
                {notChangingCountryProps?.name}
                <IconButton
                  aria-label='delete'
                  size='small'
                  onClick={() => {
                    if (mode === CountryPopupMode.Country) handleClose();
                    else setMode(CountryPopupMode.Country);
                  }}
                  style={{ marginLeft: '10px' }}
                >
                  {mode === CountryPopupMode.Country ? <CloseIcon fontSize='inherit' /> : <ArrowBackIcon fontSize='inherit' />}
                </IconButton>
              </Typography>
              {mode === CountryPopupMode.Country ? (
                <Typography sx={{ mb: 1.5 }} color='text.secondary' className='dateGridItem'>
                  First visit on{' '}
                  <Link style={{ textDecorationStyle: 'dashed' }} onClick={() => setMode(CountryPopupMode.EditFirstVisitDate)}>
                    {formatDate(visitedCountry.firstVisitDate)}
                  </Link>
                </Typography>
              ) : mode === CountryPopupMode.EditFirstVisitDate ? (
                <DateCalendar
                  value={visitedCountry.firstVisitDate ? new Date(visitedCountry.firstVisitDate) : new Date()}
                  onChange={(newDate, selectionState) => {
                    if (selectionState === 'partial') return;
                    updateCountryFirstVisitDate(newDate ? newDate.toISOString() : undefined);
                    setMode(CountryPopupMode.Country);
                  }}
                />
              ) : (
                <></>
              )}
            </CardContent>
            <CardActions style={{ justifyContent: 'right' }}>
              <Button
                color='error'
                size='small'
                onClick={() => {
                  setRemoveCountryDialogOpened(true);
                  handleClose();
                }}
              >
                Remove visit
              </Button>
            </CardActions>
          </Card>
        </Snackbar>
      )}

      <Dialog
        open={removeCountryDialogOpened}
        onClose={() => setRemoveCountryDialogOpened(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>This will also delete all visited cities. Are you sure?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRemoveCountryDialogOpened(false)}>No, close</Button>
          <Button color='error' onClick={() => removeCountryVisit()} autoFocus>
            Yes, remove
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
