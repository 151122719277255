import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Snackbar,
  Typography,
} from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { formatDate, ordinal } from 'src/app/helpers';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { TUser, TVisitedCity, TVisitedCountry } from 'src/features/user/classes';
import { selectMe, setCityVisitedState } from 'src/features/user/userSlice';
import { FBContext } from 'src/components/Sync/SyncHandler';

import './CityPopup.scss';
import { TCountry, selectCountries } from 'src/features/storage/storageSlice';
import { isValid, parse, parseISO } from 'date-fns';

enum CityPopupMode {
  City = 'city',
  EditFirstVisitDate = 'editFirstVisitDate',
}

const ContentModeCity: React.FC<{
  selectedCity: TVisitedCity;
  handleStartSetDate: (date: string | null) => void;
  handleStartDeleteDate: (date: string | null) => void;
  handleClose: () => void;
}> = ({ selectedCity, handleStartSetDate, handleStartDeleteDate, handleClose }) => {
  // TODO: Figure out with Unknown date in Firebase. In this case we pass null
  const visitedDates = selectedCity.visitedDates?.length ? selectedCity.visitedDates : [null];

  return (
    <>
      <CardContent>
        <List
          subheader={
            <ListSubheader component='div' id='nested-list-subheader'>
              Visits
            </ListSubheader>
          }
        >
          {visitedDates.map((visitDate, indx) => (
            <ListItem
              key={`dateListItem_${indx}`}
              secondaryAction={
                <IconButton edge='end' aria-label='delete' size='small' onClick={() => handleStartDeleteDate(visitDate)}>
                  <DeleteIcon fontSize='inherit' />
                </IconButton>
              }
            >
              <ListItemText
                primary={
                  <>
                    <span>{ordinal(indx + 1)}</span>&nbsp;
                    <Link style={{ textDecorationStyle: 'dashed' }} onClick={() => handleStartSetDate(visitDate)}>
                      {formatDate(visitDate)}
                    </Link>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
      <CardActions style={{ justifyContent: 'right' }}>
        <Button color='success' size='small' onClick={handleClose}>
          Add visit
        </Button>
      </CardActions>
    </>
  );
};

const ContentModeEditDate: React.FC<{ initialDate: Date; setDate: (date: Date) => void }> = ({ initialDate, setDate }) => {
  return (
    <CardContent>
      <DateCalendar
        value={initialDate}
        onChange={(newDate, selectionState) => {
          if (selectionState === 'partial' || newDate === null) return;
          setDate(newDate);
        }}
      />
    </CardContent>
  );
};

export const CityPopup: React.FC<{
  open: boolean;
  handleClose: () => void;
  placeId?: string;
}> = props => {
  const { open, handleClose, placeId } = props;

  const dispatch = useAppDispatch();

  const fbContext = useContext(FBContext);

  const countries = useAppSelector<TCountry[]>(selectCountries);
  const me = useAppSelector<TUser>(selectMe);
  const selectedCity = placeId && me.cities[placeId];

  const [removeCityDialogOpened, setRemoveCityDialogOpened] = useState<boolean>(false);
  const [mode, setMode] = useState<CityPopupMode>(CityPopupMode.City);

  const [editingDate, setEditingDate] = useState<Date>(new Date());

  useEffect(() => {
    setMode(CityPopupMode.City);
  }, [placeId, open]);

  const country = useMemo(() => {
    if (!placeId) return;
    if (!selectedCity) return console.warn('No me.city by placeId when placeId changed', placeId);

    return countries.find(country => country.code === selectedCity.countryCode);
  }, [placeId]);

  const handleStartDeleteDate = (dateStr: string | null) => {
    if (!placeId) return console.warn('No placeId when in handleSetDate', placeId);
    if (!selectedCity) return console.warn('No me.city by placeId when placeId changed', placeId);

    if (selectedCity.visitedDates?.length > 1) {
      const visitedDates = selectedCity.visitedDates.filter(visitedDate => visitedDate !== dateStr);

      dispatch(
        setCityVisitedState({
          db: fbContext.db,
          userId: fbContext.user?.uid,
          city: { ...selectedCity, visitedDates: visitedDates.sort() },
          visitedState: true,
        }),
      );
    } else if (selectedCity.visitedDates?.length === 1) {
      const date = parseISO(dateStr ?? '');
      setEditingDate(isValid(date) ? date : new Date());
      setRemoveCityDialogOpened(true);
    } else {
      console.warn("Can't delete visited date for city with empty visitedDates array", placeId);
    }
  };

  const removeCityLastVisitDate = () => {
    if (!placeId) return console.warn('No placeId when in handleSetDate', placeId);
    if (!selectedCity) return console.warn('No me.city by placeId when placeId changed', placeId);
    if (selectedCity.visitedDates?.length !== 1)
      return console.warn('Trying to remove last visit from city, but there is more than one visit', placeId, selectedCity);

    setRemoveCityDialogOpened(false);
    handleClose();
    dispatch(
      setCityVisitedState({
        db: fbContext.db,
        userId: fbContext.user?.uid,
        city: selectedCity,
        visitedState: false,
      }),
    );
  };

  const handleStartSetDate = (dateStr: string | null) => {
    const date = parseISO(dateStr ?? '');
    setEditingDate(isValid(date) ? date : new Date());
    setMode(CityPopupMode.EditFirstVisitDate);
  };

  const handleSetDate = (newDate: Date) => {
    if (!placeId) return console.warn('No placeId when in handleSetDate', placeId);
    if (!selectedCity) return console.warn('No me.city by placeId when placeId changed', placeId);

    const indx = selectedCity.visitedDates.findIndex(visitedDate => visitedDate === editingDate.toISOString()) ?? -1;

    const visitedDates = [...selectedCity.visitedDates];

    if (indx === -1) visitedDates.push(newDate.toISOString());
    else visitedDates[indx] = newDate.toISOString();

    dispatch(
      setCityVisitedState({
        db: fbContext.db,
        userId: fbContext.user?.uid,
        city: { ...selectedCity, visitedDates: visitedDates.sort() },
        visitedState: true,
      }),
    );

    setMode(CityPopupMode.City);
  };

  return (
    <>
      {selectedCity && (
        <Snackbar
          className='menuBottom'
          open={open}
          autoHideDuration={null}
          onClose={handleClose}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        >
          <Card>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: 'transparent' }}>
                  <big>{country?.flag}</big>
                </Avatar>
              }
              action={
                <IconButton
                  aria-label='delete'
                  size='small'
                  onClick={() => {
                    if (mode === CityPopupMode.City) handleClose();
                    else setMode(CityPopupMode.City);
                  }}
                >
                  {mode === CityPopupMode.City ? <CloseIcon fontSize='inherit' /> : <ArrowBackIcon fontSize='inherit' />}
                </IconButton>
              }
              title={selectedCity?.name}
              subheader={country?.name}
              sx={{ backgroundColor: '#f8f4fa' }}
            />
            {mode === CityPopupMode.City ? (
              <ContentModeCity
                selectedCity={selectedCity}
                handleStartSetDate={handleStartSetDate}
                handleStartDeleteDate={handleStartDeleteDate}
                handleClose={handleClose}
              />
            ) : mode === CityPopupMode.EditFirstVisitDate ? (
              <ContentModeEditDate initialDate={editingDate} setDate={handleSetDate} />
            ) : (
              <></>
            )}
          </Card>
        </Snackbar>
      )}

      <Dialog
        open={removeCityDialogOpened}
        onClose={() => setRemoveCityDialogOpened(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Delete city from map?</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            You are trying to delete the last visit. This will completely remove the city from the map. Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRemoveCityDialogOpened(false)}>No, close</Button>
          <Button color='error' onClick={() => removeCityLastVisitDate()} autoFocus>
            Yes, remove
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
