import * as React from 'react';
import { Children, cloneElement, FC, isValidElement, useEffect, useRef, useState } from 'react';

export interface GMapProps extends google.maps.MapOptions {
  style: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;

  children?: React.ReactNode;
}

async function initMap(ref: HTMLDivElement, options: google.maps.MapOptions): Promise<google.maps.Map> {
  console.log('initMap', google);
  const { Map } = (await google.maps.importLibrary('maps')) as google.maps.MapsLibrary;
  return new Map(ref, options);
}

export const GMap: FC<GMapProps> = ({ onClick, onIdle, children, style, center, zoom, ...options }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && !map) {
      initMap(ref.current, { center, zoom, mapId: import.meta.env.VITE_GMAP_MAP_ID, ...options } as google.maps.MapOptions).then(newMap =>
        setMap(newMap),
      );
    }
  }, [ref, map, center, zoom, options]);

  useEffect(() => {
    if (map) {
      ['click', 'idle'].forEach(eventName => google.maps.event.clearListeners(map, eventName));

      if (onClick) {
        map.addListener('click', onClick);
      }

      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div id='map' ref={ref} style={style} />
      {Children.map(children, child => {
        if (isValidElement(child)) {
          return cloneElement(child as React.ReactElement<any>, { map });
        }
      })}
    </>
  );
};
