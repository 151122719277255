import { useMemo, useState } from 'react';
import { EmailAuthProvider, FacebookAuthProvider, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import { FC } from 'react';
import { Button, Card, CardActions, CardContent, CardHeader, Collapse, IconButton, IconButtonProps, Popover } from '@mui/material';
import { StyledFirebaseAuth } from 'src/components/Profile/StyledFirebaseAuth';
import * as firebaseui from 'firebaseui';
import { useAuth, useSigninCheck, useUser } from 'reactfire';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { resetLocalUser, selectMe } from 'src/features/user/userSlice';
// import { useTheme } from '@mui/material/styles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ShareIcon from '@mui/icons-material/Share';
import { styled } from '@mui/material/styles';

import { TAvatar } from './TAvatar';
import { getShareText } from 'src/pages/Map/Map';
import { TUser } from 'src/features/user/classes';
import { selectCountries, TCountry } from 'src/features/storage/storageSlice';

export interface ProfileProps {
  open: boolean;
  handleClose?: () => void;
  anchorEl: HTMLElement | null;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const provider = new OAuthProvider('apple.com');
provider.addScope('email');
provider.addScope('name');

export const Profile: FC<ProfileProps> = ({ open, handleClose, anchorEl }) => {
  const auth = useAuth();
  const { status, data: signInCheckResult } = useSigninCheck();
  const user = useUser();
  const dispatch = useAppDispatch();

  const me = useAppSelector<TUser>(selectMe);
  const countries = useAppSelector<TCountry[]>(selectCountries);

  const [expanded, setExpanded] = useState<boolean>(false);

  const uiConfig: firebaseui.auth.Config = useMemo(() => {
    return {
      signInFlow: 'popup',
      signInOptions: [GoogleAuthProvider.PROVIDER_ID, EmailAuthProvider.PROVIDER_ID, FacebookAuthProvider.PROVIDER_ID, provider.providerId],
      callbacks: {
        signInFailure: (error: firebaseui.auth.AuthUIError) => {
          console.error(error); // TODO: Implement error handling
        },
      },
    };
  }, []);

  const doLogout = () => {
    if (
      window.confirm(
        // TODO: Translate
        'Are you sure you want to get out and start over?\nAll data is safely stored on the server and you can always return to them by logging into your account again.',
      )
    ) {
      auth.signOut();
      dispatch(resetLocalUser());
      setExpanded(false);
      handleClose && handleClose();
    }
  };

  if (status === 'loading') {
    return <span>loading...</span>;
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const shareText = getShareText(Object.keys(me.countries).length, countries.length);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{ style: { marginTop: '10px', backgroundColor: 'yellow' } }}
      marginThreshold={0}
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      {signInCheckResult?.signedIn ? (
        <Card sx={{ minWidth: 300 }}>
          <CardHeader avatar={<TAvatar user={user?.data} />} title={user?.data?.displayName} />
          <CardContent>
            {shareText[0]}
            <br />
            {shareText[1]}
          </CardContent>
          <CardActions disableSpacing>
            <IconButton aria-label='share'>
              <ShareIcon />
            </IconButton>
            <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label='show more'>
              <ExpandMoreIcon color='warning' />
            </ExpandMore>
          </CardActions>

          <Collapse in={expanded} timeout='auto' unmountOnExit>
            <CardContent style={{ paddingBottom: 16 }}>
              <Button size='small' color='error' onClick={doLogout} fullWidth>
                Logout and Reset All Countries
              </Button>
            </CardContent>
          </Collapse>
        </Card>
      ) : (
        <>
          <Card sx={{ minWidth: 300 }}>
            <CardHeader title='Welcome to Trevelo' sx={{ textAlign: 'center' }} />
            <CardContent>
              <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
            </CardContent>
          </Card>
        </>
      )}
    </Popover>
  );
};
