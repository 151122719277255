import { User } from 'firebase/auth';
import { collection, doc, Firestore, getDoc, getDocs, setDoc, Timestamp } from 'firebase/firestore';
import { createContext, FC, useEffect } from 'react';
import { useFirestore, useSigninCheck } from 'reactfire';

import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { TUser, TVisitedCity, TVisitedCountry } from 'src/features/user/classes';
import { cityToFirestore, countryToFirestore, firestoreToCity, firestoreToCountry } from 'src/features/user/converters';
import { incrementAppStartCount, selectMe, setStateFromFirestore } from 'src/features/user/userSlice';
import createLogger, { LoggerLevel } from 'src/logger';

import './SyncHandler.scss';
import { isAfter, isBefore, parseISO } from 'date-fns';

export interface SyncHandlerProps {
  children?: React.ReactNode;
}

export interface IFBContext {
  db: Firestore;
  user?: User;
}

const logger = createLogger(LoggerLevel.VERBOSE);

export const FBContext = createContext<IFBContext>({} as IFBContext);

export const SyncHandler: FC<SyncHandlerProps> = ({ children }) => {
  const dispatch = useAppDispatch();

  const { status, data: signInCheckResult } = useSigninCheck();

  const me = useAppSelector<TUser>(selectMe);

  const db = useFirestore();

  useEffect(() => {
    const syncUser = async (db: Firestore, user: User) => {
      const docRef = doc(db, 'user', user.uid);
      let docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        await setDoc(doc(db, 'user', user.uid), {
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          providerID: user.providerId,
          appStartCount: me.appStartCount,
          updatedAt: Timestamp.fromDate(new Date()),
        });
        docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
          logger.error("Can't create new user on server");
          return <span>Something went wrong. Please try again later</span>; // TODO: Handle error
        }
      } else {
        dispatch(incrementAppStartCount());
      }

      logger.info('===== START Processing Countries =====');
      logger.info('Fetching countries from Firestore');
      const countriesSnapshot = await getDocs(collection(db, 'user', user.uid, 'countries'));
      const countriesFromFirestore = countriesSnapshot.docs.map(countryDoc => firestoreToCountry(countryDoc.data()));

      logger.info('Start upload to Firebase local visited contries');
      let addedCountries = 0,
        skippedCountries = 0;
      for (const [countryCode, country] of Object.entries(me.countries)) {
        const existingCountryInFirestore = countriesFromFirestore.find(someRemoteCountry => someRemoteCountry.code === countryCode);

        if (!existingCountryInFirestore || (country.firstVisitDate && existingCountryInFirestore.firstVisitDate !== country.firstVisitDate)) {
          const countryRef = doc(db, 'user', user.uid, 'countries', countryCode);
          await setDoc(countryRef, countryToFirestore(country));
          addedCountries++;
        } else {
          skippedCountries++;
        }
      }
      logger.info(`\t... added: ${addedCountries}, skipped: ${skippedCountries}`);

      logger.info('Start create local visited contries from Firebase contries');
      const countriesToAddLocal: TVisitedCountry[] = [];
      addedCountries = 0;
      skippedCountries = 0;
      for (const countryFromFirestore of countriesFromFirestore) {
        const existingLocalCountry = me.countries[countryFromFirestore.code];

        if (
          !existingLocalCountry ||
          (countryFromFirestore.firstVisitDate && existingLocalCountry.firstVisitDate !== countryFromFirestore.firstVisitDate)
        ) {
          countriesToAddLocal.push(countryFromFirestore);
          addedCountries++;
        } else {
          skippedCountries++;
        }
      }

      dispatch(
        setStateFromFirestore({
          userId: user.uid,
          countries: countriesToAddLocal.reduce(
            (obj, country) => ({
              ...obj,
              [country.code]: country,
            }),
            {},
          ),
        }),
      );
      logger.info(`\t... added: ${addedCountries}, skipped: ${skippedCountries}`);
      logger.info('===== FINISH Processing Cities =====');

      logger.info('===== START Processing Cities =====');
      logger.info('Fetching cities from Firestore');
      const citiesSnapshot = await getDocs(collection(db, 'user', user.uid, 'cities'));
      const citiesFromFirestore = citiesSnapshot.docs.map(cityDoc => firestoreToCity(cityDoc.data()));

      logger.info('Start upload to Firebase local visited cities');
      let addedCities = 0,
        skippedCities = 0;
      for (const [cityPlaceId, city] of Object.entries(me.cities)) {
        const existingCityInFirestore = citiesFromFirestore.find(someRemoteCity => someRemoteCity.placeId === cityPlaceId);

        if (
          !existingCityInFirestore ||
          !existingCityInFirestore.updatedAt ||
          (city.updatedAt && isAfter(parseISO(city.updatedAt), parseISO(existingCityInFirestore.updatedAt)))
        ) {
          const cityRef = doc(db, 'user', user.uid, 'cities', cityPlaceId);
          await setDoc(cityRef, cityToFirestore(city));
          addedCities++;
        } else {
          skippedCities++;
        }
      }
      logger.info(`\t... added: ${addedCities}, skipped: ${skippedCities}`);

      logger.info('Start create local visited cities from Firebase cities');
      const citiesToAddLocal: TVisitedCity[] = [];
      addedCities = 0;
      skippedCities = 0;
      for (const cityFromFirestore of citiesFromFirestore) {
        const existingLocalCity = me.cities[cityFromFirestore.placeId];

        if (
          !existingLocalCity ||
          !existingLocalCity.updatedAt ||
          (cityFromFirestore.updatedAt && isAfter(parseISO(cityFromFirestore.updatedAt), parseISO(existingLocalCity.updatedAt)))
        ) {
          citiesToAddLocal.push(cityFromFirestore);
          addedCities++;
        } else {
          skippedCities++;
        }
      }

      dispatch(
        setStateFromFirestore({
          userId: user.uid,
          cities: citiesToAddLocal.reduce(
            (obj, city) => ({
              ...obj,
              [city.placeId]: city,
            }),
            {},
          ),
        }),
      );
      logger.info(`\t... added: ${addedCities}, skipped: ${skippedCities}`);
      logger.info('===== FINISH Processing Cities =====');
    };

    if (status === 'loading') return;

    if (db && signInCheckResult.signedIn && signInCheckResult?.user) {
      logger.info('Do sync with Firebase', signInCheckResult.user.uid);
      syncUser(db, signInCheckResult.user).catch(err => {
        console.log('HERE');
        logger.error(err);
      });
    } else {
      logger.info('Logged out');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, signInCheckResult?.signedIn, signInCheckResult?.user]);

  useEffect(() => {
    logger.info('Countries changed', { meCountries: me.countries });
  }, [me.countries]);

  if (status === 'loading')
    return (
      <div className='splashScreen'>
        <img src='/img/Globe.png' />
      </div>
    );

  return <FBContext.Provider value={{ db, user: signInCheckResult?.user ?? undefined }}>{children}</FBContext.Provider>;
};
