// import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from './app/App';
import * as serviceWorkerRegistration from './app/serviceWorkerRegistration';
import reportWebVitals from './app/reportWebVitals';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import React, { StrictMode } from 'react';
import { FirebaseAppProvider } from 'reactfire';
import { Routes, Route, BrowserRouter, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import { createBrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import './index.css';

Sentry.init({
  dsn: 'https://040b3598f0424e5983100ff65cc1e8dc@o261098.ingest.sentry.io/1458549',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: 1.0,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

let persistor = persistStore(store);

// TODO: Make Trevelo icons (favicons, logos, etc...) without sqare corners - just the globe with gradient background
/*
Это приложение создано обычным create-react-app
на базе шаблона cra-template-pwa 
с использованием технологий Progressive Web Apps,
что позволяет приложению работать в оффлайне.

Про Progressive Web Apps
https://create-react-app.dev/docs/making-a-progressive-web-app/
*/

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = createRoot(rootElement);
root.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <FirebaseAppProvider firebaseConfig={firebaseConfig}>
          <BrowserRouter>
            <SentryRoutes>
              <Route path='/' element={<App />} />
            </SentryRoutes>
          </BrowserRouter>
        </FirebaseAppProvider>
      </PersistGate>
    </Provider>
  </StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
