import React, { FC, Fragment, useContext, useEffect, useState } from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';

import Button from '@mui/material/Button';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PersonIcon from '@mui/icons-material/Person';
import IosShareIcon from '@mui/icons-material/IosShare';
// import PublicIcon from '@mui/icons-material/Public';

import { GMap } from 'src/components/GMap/GMap';
// import { Marker } from "/src/views/MapControls/Marker";
import { Polygon } from 'src/components/MapControls/Polygon';
import { loadStorage, selectCountries, TCountry } from 'src/features/storage/storageSlice';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { selectCities, selectMe, setCityVisitedState, setCountryVisitedState } from 'src/features/user/userSlice';
import { Card, CardContent } from '@mui/material';
import { CountriesList } from 'src/components/Lists/Countries';
import { RootState } from 'src/app/store';
import { setCenter, setZoom } from 'src/features/settings/settingsSlice';
import { Profile } from 'src/components/Profile/Profile';
import { TUser, TVisitedCity } from 'src/features/user/classes';
import { FBContext } from 'src/components/Sync/SyncHandler';
import pointsWithinPolygon from '@turf/points-within-polygon';
import { point, multiPolygon } from '@turf/helpers';
// import { useSigninCheck } from 'reactfire';
import { CountryPopup } from 'src/components/CountryPopup/CountryPopup';
import { Marker } from 'src/components/MapControls/Marker';

import './Map.scss';
import { geocoderResultToCity } from 'src/features/user/converters';
import { CityPopup } from 'src/components/CityPopup/CityPopup';

export function getShareText(visitedCountriesCount: number, totalCountries: number): string[] {
  return [`I visited ${visitedCountriesCount} countries.`, `I've seen ${Math.round((visitedCountriesCount / totalCountries) * 100)}% of the world.`];
}

async function initGeocoder(): Promise<google.maps.Geocoder> {
  const { Geocoder } = (await window.google.maps.importLibrary('geocoding')) as google.maps.GeocodingLibrary;
  return new Geocoder();
}

async function initMarkerLib(): Promise<google.maps.MarkerLibrary> {
  return (await google.maps.importLibrary('marker')) as google.maps.MarkerLibrary;
}

const GMAP_API_KEY = import.meta.env.VITE_GMAP_API_KEY;

export const Map: FC = props => {
  const countries = useAppSelector<TCountry[]>(selectCountries);
  const cities = useAppSelector<{ [placeId: string]: TVisitedCity }>(selectCities);
  const [markers, setMarkers] = useState<{ [placeId: string]: google.maps.marker.AdvancedMarkerElement }>({});
  const [markerLib, setMarkerLib] = useState<google.maps.MarkerLibrary>();
  const me = useAppSelector<TUser>(selectMe);

  const { zoom, center } = useAppSelector<RootState>(state => state.settings.map);

  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [snackbarCountryOpened, setSnackbarCountryOpened] = useState<boolean>(false);
  const [snackbarCityOpened, setSnackbarCityOpened] = useState<boolean>(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState<string | undefined>();
  const [selectedCityPlaceId, setSelectedCityPlaceId] = useState<string | undefined>();

  const [openCountries, setOpenCountries] = useState(false);
  const [openAuth, setOpenAuth] = useState(false);

  const [geocoder, setGeocoder] = useState<google.maps.Geocoder>();

  const fbContext = useContext(FBContext);

  useEffect(() => {
    if (!geocoder && window.google) {
      initGeocoder().then(newGeocoder => {
        setGeocoder(newGeocoder);
        console.log('Geocoder initialized', newGeocoder);
      });
    }
    if (!markerLib && window.google) {
      initMarkerLib().then(markerLib => {
        setMarkerLib(markerLib);
        console.log('markerLib initialized', markerLib);
      });
    }
  }, [geocoder, markerLib, window.google]);

  useEffect(() => {
    dispatch(loadStorage());
  }, [dispatch]);

  useEffect(() => {
    setSnackbarCountryOpened(false);
    setSnackbarCityOpened(false);
  }, [me.id]);

  useEffect(() => {
    console.log('useEffect cities');
    const newMarkers = Object.assign({}, markers);

    const citiesPlacesId = Object.keys(cities);
    const markersPlacesId = Object.keys(newMarkers);
    const citiesToRemove = markersPlacesId.filter(placeId => !citiesPlacesId.includes(placeId));
    const citiesToAdd = citiesPlacesId.filter(placeId => !markersPlacesId.includes(placeId));

    for (const placeId of citiesToRemove) {
      newMarkers[placeId].map = null;
      delete newMarkers[placeId];
    }
    if (markerLib)
      for (const placeId of citiesToAdd) {
        newMarkers[placeId] = new markerLib.AdvancedMarkerElement({
          collisionBehavior: google.maps.CollisionBehavior.OPTIONAL_AND_HIDES_LOWER_PRIORITY,
        });
      }
    setMarkers(newMarkers);
  }, [cities]);

  const onMapClick = (e: google.maps.MapMouseEvent) => {
    console.log('onMapClick', e);
    const clickPoint = e.latLng && [e.latLng?.lng(), e.latLng?.lat()];
    if (!clickPoint) return;

    const placeId = (e as google.maps.IconMouseEvent).placeId;
    if (placeId) {
      e.stop();
      onCityClick(placeId);
      return;
    }

    const country = countries.find(country => {
      const points = pointsWithinPolygon(
        point(clickPoint),
        multiPolygon(country.paths.map(polygon => [polygon.map(point => [point.lng, point.lat])])),
      );
      return points.features.length && country;
    });

    setSnackbarCityOpened(false);
    if (!country) setSnackbarCountryOpened(false);
    else {
      setSelectedCountryCode(country.code);
      setSnackbarCountryOpened(true);
    }
  };

  const onCityClick = (placeId: string) => {
    console.log('onCityClick', placeId);
    const processCityClick = (city: TVisitedCity) => {
      dispatch(
        setCityVisitedState({
          db: fbContext.db,
          userId: fbContext.user?.uid,
          city,
          visitedState: true,
        }),
      ).then(() => {
        console.log('HERE11');
        setSnackbarCountryOpened(false);
        setSnackbarCityOpened(true);
      });
    };

    const city = cities[placeId];
    if (!city && geocoder) {
      geocoder.geocode(
        {
          placeId,
        },
        (result, status) => {
          if (status === google.maps.GeocoderStatus.OK && result?.length === 1) {
            const city = geocoderResultToCity(result[0]);
            if (!city) {
              console.log('Can\t create city from geocoder result', result);
              return;
            }

            processCityClick({
              ...city,
              visitedDates: [new Date().toISOString()],
              updatedAt: new Date().toISOString(),
            });
          }
        },
      );
    } else {
      processCityClick({
        ...city,
        visitedDates: [...(city.visitedDates ?? []), new Date().toISOString()].sort(),
        updatedAt: new Date().toISOString(),
      });
    }
  };

  const onCityMarkerClick = (placeId: string) => {
    setSnackbarCountryOpened(false);
    setSelectedCityPlaceId(placeId);
    setSnackbarCityOpened(true);
  };

  const onCountryPolygonClick = (countryCode: string) => {
    setSnackbarCityOpened(false);
    setSelectedCountryCode(countryCode);
    setSnackbarCountryOpened(true);
    dispatch(
      setCountryVisitedState({
        db: fbContext.db,
        userId: fbContext.user?.uid,
        country: {
          code: countryCode,
          firstVisitDate: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        },
        visitedState: true,
      }),
    );
  };

  const handleCloseStackbarCountry = (event?: React.SyntheticEvent | Event, reason?: string | 'clickaway') => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarCountryOpened(false);
  };

  const handleCloseStackbarCity = (event?: React.SyntheticEvent | Event, reason?: string | 'clickaway') => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarCityOpened(false);
  };

  const onIdle = (m: google.maps.Map) => {
    dispatch(setZoom(m.getZoom()!));
    dispatch(setCenter(m.getCenter()!.toJSON()));
    setSnackbarCountryOpened(false);
    setSnackbarCityOpened(false);
  };

  const shareText = getShareText(Object.keys(me.countries).length, countries.length);

  const sharePanel = (
    <Fragment>
      <Button className='buttonMenu' variant='contained'>
        <IosShareIcon />
      </Button>
      <CardContent id='sharingPannel'>
        <div>{shareText[0]}</div>
        <div>{shareText[1]}</div>
      </CardContent>
    </Fragment>
  );

  const render = (status: Status) => {
    return <h1>{status}</h1>;
  };

  // const buttonProfileRef = useRef();

  // console.log('cities', cities, Object.values(cities ?? {}));

  return (
    <Wrapper apiKey={GMAP_API_KEY} render={render}>
      <GMap center={center} onClick={onMapClick} onIdle={onIdle} zoom={zoom} disableDefaultUI={true} style={{ flexGrow: '1', height: '100%' }}>
        <Button
          className='buttonMenu mapMenu menuTop menuRight'
          variant='contained'
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(e.currentTarget);
            setOpenAuth(!openAuth);
          }}
        >
          <PersonIcon />
        </Button>
        <Button className='buttonMenu mapMenu menuTop menuLeft' variant='contained' onClick={() => setOpenCountries(!openCountries)}>
          <FormatListBulletedIcon />
        </Button>
        <Card className='mapMenu menuBottom menuLeft' sx={{ display: 'flex' }}>
          {sharePanel}
        </Card>
        {/* <Button id='bottomRight' className='buttonMenu' variant='contained' startIcon={<PublicIcon />} sx={{ width: 'unset' }}>
          Trips
        </Button> */}

        {countries
          .filter(country => !me.countries[country.code])
          .map(country => (
            <Polygon key={country.code} country={country} onClick={onCountryPolygonClick} />
          ))}

        {Object.entries(markers ?? {}).map(([placeId, marker]) => (
          <Marker key={`marker_${placeId}`} marker={marker} city={cities[placeId]} zoom={zoom} onClick={onCityMarkerClick} />
        ))}
      </GMap>
      <CountriesList open={openCountries} handleClose={() => setOpenCountries(false)} />
      <Profile anchorEl={anchorEl} open={openAuth} handleClose={() => setOpenAuth(false)} />
      <CountryPopup open={snackbarCountryOpened} handleClose={handleCloseStackbarCountry} countryCode={selectedCountryCode} />
      <CityPopup open={snackbarCityOpened} handleClose={handleCloseStackbarCity} placeId={selectedCityPlaceId} />
    </Wrapper>
  );
};
