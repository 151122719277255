import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'src/app/store';

// Define a type for the slice state
interface SettingsState {
  map: {
    zoom: number;
    center: google.maps.LatLngLiteral;
  };
}

// Define the initial state using that type
const initialState: SettingsState = {
  map: {
    zoom: 3,
    center: {
      lat: 0,
      lng: 0,
    },
  },
};

export const settingsSlice = createSlice({
  name: 'counter',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setZoom(state, action: PayloadAction<number>) {
      state.map.zoom = action.payload;
    },
    setCenter(state, action: PayloadAction<google.maps.LatLngLiteral>) {
      state.map.center = action.payload;
    },
  },
});

export const { setZoom, setCenter } = settingsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getZoom = (state: RootState) => state.settings.map.zoom;
export const getCenter = (state: RootState) => state.settings.map.center;

export default settingsSlice.reducer;
