import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'src/app/store';

import { countries } from './data/countries';
import { polygons } from './data/polygons';

export interface TCountry {
  code: string;
  name: string;
  flag: string;
  paths: Array<Array<google.maps.LatLngLiteral>>;
  [others: string]: any; // Any other property
}

// Define a type for the slice state
interface StorageState {
  countries: Array<TCountry>;
}

// Define the initial state using that type
const initialState: StorageState = {
  countries: [],
};

export const storageSlice = createSlice({
  name: 'storage',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    loadStorage: state => {
      if (state.countries.length === 0) {
        state.countries = Object.entries(countries).map(([countryCode, country]) => {
          return { ...country, paths: polygons[countryCode] };
        });
      }
    },
  },
});

export const { loadStorage } = storageSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCountries = (state: RootState) => state.storage.countries;

export default storageSlice.reducer;
