import 'reflect-metadata';
import { Map } from 'src/pages/Map/Map';
import { AuthProvider, FirestoreProvider, useFirebaseApp } from 'reactfire';
import { getAuth } from 'firebase/auth';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getFirestore } from 'firebase/firestore';

import getUserLocale from 'get-user-locale';
import localeEnUS from 'date-fns/locale/en-US';

import { SyncHandler } from 'src/components/Sync/SyncHandler';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';

import './App.css';

function App() {
  const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`
  const auth = getAuth(app);
  const firestore = getFirestore(useFirebaseApp());

  const [locale, setLocale] = useState<Locale>(localeEnUS);
  // useEffect(() => {
  //   const userLocale = getUserLocale() ?? 'en-US';
  //   import(`date-fns/locale/${userLocale}/index.js`).then(dateFnsLocale => {
  //     setLocale(dateFnsLocale);
  //   });
  // }, []);

  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestore}>
        <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDateFns}>
          <SyncHandler>
            <div className='App'>
              <Map />
            </div>
          </SyncHandler>
        </LocalizationProvider>
      </FirestoreProvider>
    </AuthProvider>
  );
}

export default App;
